import {createReducer} from 'redux-create-reducer';
import moment from 'moment';
import AT from '../actionTypes';

const initialState = {
  referralConditions: [],
  selectedReferralCondition: undefined,
  pageType: 'new' /** new/update */,
  loading: false,
  statusMessage: undefined,
};

export default createReducer(initialState, {
  [`${AT.REFREST_STATE_TAB}_SUCCEEDED`](state, action) {
    return Object.assign({}, state, {
      referralConditions: [],
      selectedReferralCondition: undefined,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SET_ADMIN_REFERRAL_CONDITION}_SUCCEEDED`](state, action) {
    return {
      ...state,
      ...action?.payload,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_LIST}_SUCCEEDED`](state, action) {
    return {
      ...state,
      referralConditions: action?.payload,
      loading: false,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_GET}_SUCCEEDED`](state, action) {
    return {
      ...state,
      selectedReferralCondition: action?.payload,
      pageType: 'update',
      loading: false,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_POST}_SUCCEEDED`](state, action) {
    const {message, conditions} = action?.payload || {};
    return {
      ...state,
      loading: false,
      referralConditions: conditions,
      pageType: 'update',
      statusMessage: message,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_UPDATE}_SUCCEEDED`](state, action) {
    const {message, conditions} = action?.payload || {};
    return {
      ...state,
      loading: false,
      referralConditions: conditions,
      statusMessage: message,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_LIST}_STARTED`](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_GET}_STARTED`](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_POST}_STARTED`](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_UPDATE}_STARTED`](state, action) {
    return {
      ...state,
      loading: true,
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_OPEN_NEW_PAGE}`](state, action) {
    return {
      ...state,
      pageType: 'new',
    };
  },
  [`${AT.ADMIN_REFERRAL_CONDITION_CLEAR_STATUS_MESSAGE}`](state, action) {
    return {
      ...state,
      statusMessage: undefined,
    };
  },
});

import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { useWindowSize } from "../../../../common/utils";
import { setGlobalLoading, setInventoryData } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import ReturnCreditFilter from "./ReturnCreditFilter";
import ReturnCreditSidebar from "./ReturnCreditSidebar";

const filterDataByOption = (data, option) => {
  const { search, unit, purchase_number, user, requestedDate, amount } = option;
  let filteredData = data;
  if (!search && !unit && !purchase_number && !user && !amount && (!requestedDate || !moment(requestedDate).isValid())) {
    return filteredData;
  }

  if (search) {
    filteredData = filteredData?.filter((d) => d?.name && d?.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }
  if (unit) {
    filteredData = filteredData?.filter((d) => d.unit && d.unit.toLowerCase() === unit.toLowerCase());
  }
  if (user) {
    filteredData = filteredData?.filter((d) => d.added_user && d.added_user === user);
  }
  if (amount) {
    filteredData = filteredData?.filter((d) => d.purchase_number * d.cost >= +amount);
  }
  if (purchase_number) {
    filteredData = filteredData?.filter((d) => d.purchase_number && d.purchase_number >= purchase_number);
  }
  if (requestedDate && moment(requestedDate).isValid()) {
    filteredData = filteredData?.filter((d) => d.requested_date && moment(d.requested_date).diff(moment(requestedDate)) >= 0);
  }
  return filteredData;
};

const ReturnCredit = ({ data, title }) => {
  const returnCredits = useSelector((state) => state.inventory.returnCredits);
  const returnCredit = useSelector((state) => state.inventory.returnCredit);
  const loading = useSelector((state) => state.inventory.loadingAPI);
  const filterOption = useSelector((state) => state.inventory.orderFilter);

  const dispatch = useDispatch();

  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const tableRows = useMemo(() => {
    if (returnCredits && returnCredits.length) {
      return filterDataByOption(returnCredits, filterOption);
    }
    return [];
  }, [returnCredits, filterOption]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("returnCredit", rowData[index]));
    handleSidebar();
  };

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <ReturnCreditFilter />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="return_credit"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={["Name", "Purchase Number", "Unit", "Requested Date", "Amount"]}
                widths={["44%", "130px", "130px", "calc(28% - 130px)", "calc(28% - 130px)"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "purchase_number" },
                  { sortable: false, key: "unit" },
                  { sortable: false, key: "requested_date" },
                  { sortable: false, key: "amount" },
                ]}
                rowData={rowData?.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {row?.shipped ? (
                          <Icon type="car" theme="twoTone" />
                        ) : row?.credit_received ? (
                          <Icon type="dollar" theme="twoTone" twoToneColor="#F1C21B" />
                        ) : row?.received ? (
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                        ) : row?.type === "narcotic" ? (
                          <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE" />
                        ) : row?.type === "angiographic" ? (
                          <Icon type="tag" theme="twoTone" twoToneColor="#6929C4" />
                        ) : (
                          <Icon type="shop" theme="twoTone" twoToneColor="#9F1853" />
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>{row?.name || ""}</p>
                      </div>
                    ),
                    purchase_number: row?.purchase_number,
                    unit: row?.unit,
                    requested_date: row?.requested_date && moment(row?.requested_date).format("MM/DD/YYYY"),
                    amount: "$" + Math.round(row?.purchase_number * row?.cost * 100) / 100,
                  };
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {returnCredit && activeIndex >= 0 && (
        <ReturnCreditSidebar ref={sidebarRef} resource={StaticResource} isShow={isSidebar} handleCloseSidebar={handleCloseSidebar} />
      )}
    </div>
  );
};

export default ReturnCredit;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ReplaceN from "../../../../helpers/ReplaceN";
import { setDetailUltrasoundProps } from "../../../../store/actions";

let timer = null;

const Addendum = ({ resource, onChangeAddendum, ...restProps }) => {
  const [detailL, setDetailL] = useState("");
  const [detailR, setDetailR] = useState("");
  const [first, setFirst] = useState(false);
  const dispatch = useDispatch();

  const detail_ultrasound_props = useSelector((state) => state.common.detail_ultrasound_props);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);

  useEffect(() => {
    if (!first) {
      setDetailL(ReplaceN(detail_ultrasound_props?.left_addendum));
      setDetailR(ReplaceN(detail_ultrasound_props?.right_addendum));
      setFirst(true);
    }
  }, [detail_ultrasound_props, first]);

  const onchangeValue = async (value, type) => {
    if (type == "right") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        onChangeAddendum &&
          onChangeAddendum({
            left_addendum: detailL || "",
            right_addendum: value || "",
            disease_id: detail_ultrasound_props?.disease_id,
          });
      }, 200);
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          right_addendum: value,
        })
      );
      setDetailR(value);
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        onChangeAddendum &&
          onChangeAddendum({
            left_addendum: value || "",
            right_addendum: detailR || "",
            disease_id: detail_ultrasound_props?.disease_id,
          });
      }, 200);
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          ...detail_ultrasound_props,
          left_addendum: value,
        })
      );
      setDetailL(value);
    }
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo">
        <EditableFormInput
          label={"Left Addendum Details"}
          value={detailL}
          type={"area"}
          fullWidth
          handleChange={(value) => {
            onchangeValue(value, "left");
          }}
          minRows={12}
          disabled={is_locked}
        />
      </div>
      <div className="resourceInfo">
        <EditableFormInput
          label={"Right Addendum Details"}
          value={detailR}
          type={"area"}
          handleChange={(value) => {
            onchangeValue(value, "right");
          }}
          fullWidth
          minRows={12}
          disabled={is_locked}
        />
      </div>
    </div>
  );
};

export default Addendum;

import { Input, Tabs } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SearchSVG } from "../../../../common/components/icons/SVGIcon";
import { StaticResource } from "../../constant";
import { setPCPNull } from "../../../../store/actions";
import ActiveTable from "./ActivePatient";
import ActiveSidebar from "./ActiveSidebarNew";
import InActiveTable from "./InactivePatient";
import InactiveSidebar from "./InActiveSidebarNew";

const { TabPane } = Tabs;
const { Search } = Input;

const PatientComponent = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [activeData, setData] = useState(null);
  const [search, setSearch] = useState(undefined);
  const [propsListTable, setPropsListTable] = useState({});
  const [deleteListPatient, setDeleteListPatient] = useState({});
  const [addProps, setAddProps] = useState({});
  const [propsListTableIn, setPropsListTableIn] = useState({});
  const [deleteListPatientIn, setDeleteListPatientIn] = useState({});

  const [activeTab, setActiveTab] = useState("active");

  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const onSearchValue = (searchInput) => {
    const valueSearchState = searchInput.target?.value;
    setSearch(valueSearchState);
  };

  const handleRow = (index, data) => () => {
    setRow(index);
    setData(data);
    handleSidebar();
  };

  const handleChangeTab = async (tab) => {
    await dispatch(setPCPNull());
    setActiveTab(tab);
    setData(null);
    setRow(-1);
    setPropsListTable({});
    setDeleteListPatient({});
    setAddProps({});
    setPropsListTableIn({});
    setDeleteListPatientIn({});
  };

  const AddNewCheck = (value) => {
    setAddProps(value);
    setRow(-1);
  };

  const updatePropsListPatient = (id, value) => {
    if (id) {
      setPropsListTable(value);
    }
  };

  const deletePropsListPatient = (id, value) => {
    if (id) {
      setDeleteListPatient(value);
    }
  };

  //
  const updatePropsListPatientIn = (id, value) => {
    if (id) {
      setPropsListTableIn(value);
    }
  };

  const deletePropsListPatientIn = (id, value) => {
    if (id) {
      setDeleteListPatientIn(value);
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            right: "32px",
            maxWidth: "440px",
            width: "calc(100% - 240px)",
            top: "16px",
            zIndex: 20,
          }}
        >
          <div className="rightSearchField" style={{ width: "100%" }}>
            <Search
              className="dependencySearch"
              placeholder="Search Patient"
              value={search}
              onChange={onSearchValue}
              style={{ width: "100%" }}
              suffix={<SearchSVG />}
            />
          </div>
        </div>

        <Tabs className="patientChart" type="card" onChange={handleChangeTab}>
          <TabPane tab="Active" key="active">
            {activeTab == "active" && (
              <ActiveTable
                addProps={addProps}
                search={search}
                deleteListPatient={deleteListPatient}
                propsListTable={propsListTable}
                handleRow={handleRow}
                loading={loading}
                activeIndex={activeIndex}
                title={"activeEmployee"}
              />
            )}
          </TabPane>
          <TabPane tab="Inactive" key="in-active">
            {activeTab !== "active" && (
              <InActiveTable
                search={search}
                deleteListPatientIn={deleteListPatientIn}
                propsListTableIn={propsListTableIn}
                handleRow={handleRow}
                loading={loading}
                activeIndex={activeIndex}
                title={"inactiveEmployee"}
              />
            )}
          </TabPane>
        </Tabs>
      </div>

      {activeData && activeIndex >= 0 && activeTab === "active" && (
        <ActiveSidebar
          ref={sidebarRef}
          updatePropsListPatient={(id, value) => {
            updatePropsListPatient(id, value);
          }}
          deletePropsListPatient={(id, value) => {
            deletePropsListPatient(id, value);
          }}
          AddNewCheck={(value) => AddNewCheck(value)}
          type="patient"
          usersDetail={activeData ? activeData : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {activeData && activeIndex >= 0 && activeTab !== "active" && (
        <InactiveSidebar
          ref={sidebarRef}
          updatePropsListPatientIn={(id, value) => {
            updatePropsListPatientIn(id, value);
          }}
          deletePropsListPatientIn={(id, value) => {
            deletePropsListPatientIn(id, value);
          }}
          usersDetail={activeData ? activeData : undefined}
          resource={StaticResource}
          type="patient"
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default PatientComponent;

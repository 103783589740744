import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import YesNo from "../../../common/components/YesNo";
import { deleteConditionVariable, editConditionVariable, getConditionVariable, setGlobalToastr, setProcedureDetails } from "../../../store/actions";
import RegexSpecial from "src/helpers/RegexSpecial";

let timer = null;

const VariableCapture = ({ resource, rowAction }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [activeDeleteAnswer, setActiveDeleteAnswer] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const conditionVariable = useSelector((state) => state.procedureDetail.conditionVariable);
  const condition_type = useSelector((state) => state.procedureDetail.condition_type);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);

  useEffect(() => {
    if (rowAction) {
      rowAction({
        index: null,
        selectedVariable: null,
      });
    }
  }, [resource]);

  const handleClickRow = (row, index) => {
    if (rowAction) {
      rowAction({ index, selectedVariable: row });
    }
  };

  const updateVariable = async () => {
    const checkArray = (condition_type || []).map((r, index) => {
      return {
        variable_name: r?.variable_name,
        id: r?.id,
        quest: r?.question,
        is_multiple: r?.is_multiple,
        is_list: r?.is_list,
        answer: r?.answers,
        cpt_code: r?.icd_codes,
        order: r?.order,
      };
    });
    await dispatch(setProcedureDetails("conditionVariable", [...checkArray]));
  };

  useEffect(() => {
    updateVariable();
    setError(null);
  }, [condition_type]);

  const updateProviders = async (type, index, value, indexAnswer, idOnRow) => {
    if (type === "quest") {
      conditionVariable[index][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editConditionVariable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: value,
            condition_id: selectedReferralCondition?.id,
            answers: conditionVariable[index]["answer"],
            is_multiple: conditionVariable[index]["is_multiple"],
            is_list: conditionVariable[index]["is_list"],
          })
        );
      }, 1000);
    } else if (type == "is_multiple") {
      conditionVariable[index][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editConditionVariable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: idOnRow?.quest,
            condition_id: selectedReferralCondition?.id,
            answers: conditionVariable[index]["answer"],
            is_list: conditionVariable[index]["is_list"],
            is_multiple: value,
          })
        );
      }, 1000);
    } else if (type === "is_list") {
      conditionVariable[index][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editConditionVariable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: idOnRow?.quest,
            condition_id: selectedReferralCondition?.id,
            answers: conditionVariable[index]["answer"],
            is_multiple: conditionVariable[index]["is_multiple"],
            is_list: value,
          })
        );
      }, 1000);
    } else {
      conditionVariable[index]["answer"][indexAnswer][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          editConditionVariable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: idOnRow?.quest,
            condition_id: selectedReferralCondition?.id,
            answers: conditionVariable[index]["answer"],
            is_multiple: conditionVariable[index]["is_multiple"],
            is_list: conditionVariable[index]["is_list"],
          })
        );
      }, 1000);
    }
    await dispatch(setProcedureDetails("conditionVariable", [...conditionVariable]));
  };

  const removeProvider = async (type, index, indexAnswer, idOnRow) => {
    if (type === "quest") {
      if (!activeDeleteQuestion) {
        setActiveDeleteQuestion(`quest-${index}`);
      } else {
        if (activeDeleteQuestion === `quest-${index}`) {
          setActiveDeleteQuestion(null);
          conditionVariable.splice(index, 1);
          await dispatch(deleteConditionVariable(idOnRow));
          await dispatch(getConditionVariable(selectedReferralCondition?.id));
        } else {
          setActiveDeleteQuestion(`quest-${index}`);
        }
      }
    } else {
      if (!activeDeleteAnswer) {
        setActiveDeleteAnswer(`answer-${index}-${indexAnswer}`);
      } else {
        if (activeDeleteAnswer === `answer-${index}-${indexAnswer}`) {
          conditionVariable[index]["answer"].splice(indexAnswer, 1);
          setActiveDeleteAnswer(null);
          await dispatch(
            editConditionVariable(idOnRow?.id, {
              variable_name: idOnRow?.variable_name,
              question: idOnRow?.quest,
              condition_id: selectedReferralCondition?.id,
              answers: conditionVariable[index]["answer"],
            })
          );
          await dispatch(getConditionVariable(selectedReferralCondition?.id));
        } else {
          setActiveDeleteAnswer(`answer-${index}-${indexAnswer}`);
        }
      }
    }
    await dispatch(getConditionVariable(selectedReferralCondition?.id));
  };

  const handleError = (type, index) => {
    setError({
      type,
      index,
    });
    dispatch(
      setGlobalToastr({
        header: "Alert",
        type: "failed",
        message: "Invalid characters not allowed!",
      })
    );
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {conditionVariable?.map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            marginBottom: index !== conditionVariable?.length - 1 ? "1rem" : 0,
          }}
          onClick={() => handleClickRow(r, index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "calc(100% - 32px)", marginRight: "8px" }}>
              <EditableFormInput
                isError={error && error["type"] === "quest" && error["index"] === index ? true : false}
                required={true}
                label="Specifications"
                value={r.quest}
                fullWidth
                focusWhite
                handleChange={(value) => {
                  if (RegexSpecial(value)) {
                    handleError("quest", index);
                  } else {
                    updateProviders("quest", index, value, null, r);
                  }
                }}
              />
            </div>
            <DeleteIcon
              icon="can"
              selected={activeDeleteQuestion === `quest-${index}`}
              handleClick={() => removeProvider("quest", index, null, r?.id)}
            />
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Variable Name: </b>
            {r.variable_name}
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Is List: </b>
            <YesNo
              disabled={!r?.is_multiple}
              option={r?.is_list}
              setOption={(value) => {
                updateProviders("is_list", index, value, null, r);
              }}
            />
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Is Multiple: </b>
            <YesNo
              option={r?.is_multiple}
              setOption={(value) => {
                updateProviders("is_multiple", index, value, null, r);
              }}
            />
          </div>
          <div>
            {(r?.answer || []).map((item, indexAnswer) => (
              <div
                key={indexAnswer}
                style={{
                  display: "flex",
                  // alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: indexAnswer !== r.answer.length - 1 ? "8px" : "0",
                }}
              >
                <div style={{ width: "calc(40% - 8px)", marginRight: "8px" }}>
                  <EditableFormInput
                    isError={error && error["type"] === "answer" && error["index"] === indexAnswer ? true : false}
                    required={true}
                    handleChange={(value) => {
                      if (RegexSpecial(value)) {
                        handleError("answer", indexAnswer);
                      } else {
                        updateProviders("answer", index, value, indexAnswer, r);
                      }
                    }}
                    value={item.answer}
                    label={`Answer ${indexAnswer + 1}`}
                    fullWidth
                    focusWhite
                    type="area"
                    isHospitalProcedure={true}
                  />
                </div>
                <div style={{ width: "calc(60% - 32px)", marginRight: "8px" }}>
                  <EditableFormInput
                    isError={error && error["type"] === "text_template" && error["index"] === indexAnswer ? true : false}
                    required={true}
                    handleChange={(value) => {
                      if (RegexSpecial(value)) {
                        handleError("text_template", indexAnswer);
                      } else {
                        updateProviders("text_template", index, value, indexAnswer, r);
                      }
                    }}
                    label="Sentence"
                    value={item.text_template}
                    fullWidth
                    focusWhite
                    type="area"
                    isHospitalProcedure={true}
                  />
                </div>
                <DeleteIcon
                  icon="can"
                  selected={activeDeleteAnswer === `answer-${index}-${indexAnswer}`}
                  handleClick={() => removeProvider("answer", index, indexAnswer, r)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VariableCapture;

import React, {useState, useRef, useEffect, useMemo} from 'react';
import {Icon, Checkbox} from 'antd';
import './style.scss';

const options = [1, 2, 3, 4, 5];

const Ratings = ({
  label,
  isIcon,
  handleEdit,
  disableSelfbox,
  defaultOption,
  handleOption,
}) => {
  const editBox = useRef(null);
  const [option, setOption] = useState(-1);
  const [openBox, setBox] = useState(false);

  useEffect(() => {
    if (!disableSelfbox && openBox && editBox) {
      editBox.current.focus();
    }
  }, [openBox]);

  const handleIconClick = () => {
    if (!disableSelfbox) {
      setBox(!openBox);
    } else {
      if (handleEdit) {
        handleEdit();
      }
    }
  };

  const onClickOption = (idx) => (e) => {
    if (e.target.checked) {
      setOption(idx);
      if (handleOption) {
        handleOption(options[idx]);
      }
    }
  };

  const value = useMemo(() => {
    if (defaultOption !== undefined) {
      return defaultOption - 1;
    }
    return option;
  }, [defaultOption]);

  return (
    <div className={`Ratings`}>
      <div className="Ratings__label">{label}</div>
      {isIcon && (
        <div
          className={`Ratings__icon ${openBox ? 'open' : ''}`}
          onClick={handleIconClick}
        >
          <Icon type="edit" />
        </div>
      )}
      <div className={`Ratings__options`}>
        {(options || []).map((opt, index) => (
          <Checkbox
            key={`${label}-${opt}-${index}`}
            style={{width: '100%'}}
            checked={value === index}
            onChange={onClickOption(index)}
          >
            {opt}
          </Checkbox>
        ))}
      </div>

      <div
        ref={editBox}
        className="Ratings__box"
        contentEditable={true}
        onBlur={() => setBox(false)}
        style={{
          display: !disableSelfbox && openBox ? 'inline-block' : 'none',
        }}
      />
    </div>
  );
};

export default Ratings;

import React, { useState, useEffect } from "react";
import { validateField } from "../../../services/validations";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";

const ResetPasswordForm = ({ handleReset, onCancel, resetError }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (validateField("text", password) && validateField("text", confirmPassword)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [password, confirmPassword]);

  const handleResetClick = () => {
    if (invalid) {
      setEnableError(true);
      return;
    }
    handleReset(password, confirmPassword);
  };

  const handleResetKeyDown = (event) => {
    if (event.key === "Enter") {
      if (invalid) {
        setEnableError(true);
        return;
      }
      handleReset(password, confirmPassword);
    }
  };

  return (
    <div className="loginForm">
      <div className="formContainer">
        <div className="formContainer__title">
          <img src="/images/faact_logo_black.svg" alt="black logo" style={{ maxWidth: "768px" }} />
        </div>
        {/*  */}
        <div className="formContainer__username">
          <div className="formContainer__username-labelWrapper">
            <EditableFormInput
              label="Password"
              value={password}
              handleChange={(value) => setPassword(value)}
              isError={validateField("text", password)}
              enableErrorText={enableError}
              helperText="Password is Required!"
              type="password"
              required
              fullWidth
              autoFocus
            />
          </div>
        </div>
        {/*  */}
        <div className="formContainer__username">
          <div className="formContainer__username-labelWrapper">
            <EditableFormInput
              label="Confirm Password"
              value={confirmPassword}
              handleChange={(value) => setConfirmPassword(value)}
              isError={validateField("text", confirmPassword)}
              enableErrorText={enableError}
              helperText="Confirm Password is Required!"
              type="password"
              required
              fullWidth
            />
          </div>
        </div>
        {/*  */}
        {resetError && (
          <div
            style={{
              color: "#da1e28",
              fontSize: "0.9em",
              marginBottom: "12px",
            }}
          >
            {resetError}
          </div>
        )}
        {/*  */}
        <div className="formContainer__buttonWrapper">
          <button className="common-btn outlined-btn" style={{ marginTop: "0.4rem", border: 0, marginLeft: "auto" }} onClick={onCancel}>
            Cancel
          </button>
          <button
            className="common-btn"
            style={{ marginTop: "0.4rem", border: 0, marginLeft: 12 }}
            onClick={handleResetClick}
            onKeyDown={handleResetKeyDown}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;

import { Checkbox, DatePicker, Select } from 'antd';
import * as _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExpandSVG
} from '../../../../common/components/icons/SVGIcon';
import {
  getReceivingOrders, setInventoryData
} from '../../../../store/actions';

const {Option} = Select;
const dateFormat = 'MM/DD/YYYY';

const InvoicesFilter = ({}) => {
  const receivings = useSelector((state) => state.inventory.receivings);
  const filterOption = useSelector((state) => state.inventory.orderFilter);
  const dispatch = useDispatch();

  const [selectedCompanies, setCompanies] = useState('');

  useEffect(() => {
    dispatch(getReceivingOrders());
    dispatch(setInventoryData('receivingOrder', null));
    dispatch(
        setInventoryData('orderFilter', {
          amount: undefined,
          startDate: undefined,
          endDate: undefined,
          company: undefined,
        }),
    );
  }, []);

  const companies = useMemo(() => {
    if (receivings) {
      const names = receivings.map((receive) => receive.brand);
      return _.uniq(names.filter((name) => !!name));
    }
    return [];
  }, [receivings]);

  const handleChagneCategory = (values) => {
    setCompanies(values);
    const newFilter = {...filterOption, company: values};
    dispatch(setInventoryData('orderFilter', newFilter));
  };

  const changeFilter = (type) => (value) => {
    const newFilter = {...filterOption, [type]: value};
    dispatch(setInventoryData('orderFilter', newFilter));
  };

  const changeFilterDate = (type) => (date, dateString) => {
    const newFilter = {...filterOption, [type]: dateString};
    dispatch(setInventoryData('orderFilter', newFilter));
  };

  return (
    <div className="filterContainer">
      <div className="statusFilterContainer" style={{width: '34%'}}>
        <div className="statusFilter" style={{width: '100%'}}>
          <Select
            placeholder="Company"
            mode={'multiple'}
            suffixIcon={<ExpandSVG />}
            onChange={handleChagneCategory}
            maxTagCount={0}
            showArrow={true}
            style={{
              width: '100%',
            }}
          >
            {companies.map((opt, index) => (
              <Option key={`company-${opt}-${index}`} value={opt}>
                <Checkbox checked={selectedCompanies.includes(opt)}>
                  {opt}
                </Checkbox>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="statusFilterContainer" style={{width: '68%'}}>
        <div className="statusFilter" style={{flex: 1}}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="Start Date"
            suffixIcon={<ExpandSVG />}
            style={{
              width: '100%',
            }}
            onChange={changeFilterDate('startDate')}
          />
        </div>
        <div className="statusFilter" style={{flex: 1}}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="End Date"
            suffixIcon={<ExpandSVG />}
            style={{
              width: '100%',
            }}
            onChange={changeFilterDate('endDate')}
          />
        </div>
        <div className="statusFilter" style={{flex: 1}}>
          <Select
            placeholder="Amount..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.amount}
            style={{
              width: '100%',
            }}
            onChange={changeFilter('amount')}
          >
            <Option value="10">+10</Option>
            <Option value="50">+50</Option>
            <Option value="100">+100</Option>
            <Option value="500">+500</Option>
            <Option value="1000">+1000</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default InvoicesFilter;

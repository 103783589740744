import React from 'react';
import './style.scss';

const TopBar = ({title, subStatus, sideComponent}) => {
  return (
    <div className="subTopBar">
      <div className="subTopTitle">
        <div className="mainContent">{`${title}`}</div>
        {`${subStatus ? '|' : ''}`}
        {subStatus && subStatus}
      </div>
      {sideComponent && sideComponent}
    </div>
  );
};

export default TopBar;

import { Icon } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SubSideNav from "../../common/components/SubSideNav";
import MainWorkflow from "./RegisterWrapper";

function ClinicWorkFlow({ location }) {
  const sideNavLinks = [
    {
      link: "/subsidiary-workflow/check-in",
      label: "Subsidiary Module",
    },
  ];

  const [isSideNav, setIsSideNav] = useState(true);

  const closeSideNav = () => {
    setIsSideNav(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      closeSideNav();
    }
  }, []);

  const renderMetadata = () => (
    <Helmet>
      <title>FAACT Vascular | Subsidiary Workflow</title>
      <meta name="title" content="FAACT Vascular | Subsidiary Workflow" />
    </Helmet>
  );

  const openSideNav = () => {
    setIsSideNav(true);
  };

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="user" />
      Subsidiary Workflow
    </div>
  );

  return (
    <div className="clinicWorkflow">
      {renderMetadata()}
      <SubSideNav subMenu={sideNavLinks} title={getTitle()} isSideNav={isSideNav} openSideNav={openSideNav} closeSideNav={closeSideNav} />
      <MainWorkflow location={location} isSideNav={isSideNav} />
    </div>
  );
}

export default ClinicWorkFlow;

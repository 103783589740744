import {createActionThunk} from 'redux-thunk-actions';

import AT from '../actionTypes';

import * as API from '../../services/api.services';

export const getScanningToken = createActionThunk(
    AT.GET_SCAN_TOKEN_SCHEDULES,
    (scanType = 'id', patient_id) => API.getScanningToken(scanType, patient_id),
);

export const getIDCardInfo = createActionThunk(
    AT.GET_ID_CARD_INFO,
    (patient_id, card_type) => API.getIDCardInfo(patient_id, card_type),
);

export const scanDriverLicenseMatched = () => (dispatch) =>
  dispatch({type: AT.SCAN_DRIVER_LICENSE_MATCHED});

export const scanDriverLicenseIncorrect = () => (dispatch) =>
  dispatch({type: AT.SCAN_DRIVER_LICENSE_INCORRECT});

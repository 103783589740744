import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchSVG } from '../../../common/components/icons/SVGIcon';
import { setGlobalLoading } from '../../../store/actions';

const { Search } = Input;

const CallbackFilters = ({ handleSearch, searchValue }) => {
  const loadingEvents = useSelector(
    (state) => state.clinicEvents.loadingEvents,
  );
  const loadingCallback = useSelector(
    (state) => state.patientCallback.loadingCallback,
  );
  const loadingPending = useSelector(
    (state) => state.patientPending.loadingPending,
  );

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setGlobalLoading(loadingEvents || loadingCallback || loadingPending),
    );
  }, [loadingEvents, loadingCallback, loadingPending]);

  const changeSearch = (value) => {
    setSearch(value);
    handleSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default CallbackFilters;

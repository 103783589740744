import { Drawer } from "antd";
import { convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import PusherConnector from "../../../../common/components/PusherScan/pusherConnector";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SlackEditor from "../../../../common/components/SlackEditor";
import { convertToLongNumber } from "../../../../common/utils";
import {
  closeSupportModule,
  createSupportModule,
  getAllUserMedia,
  setActiveSupportTicket,
  updateSupportModule,
  updateSupportModuleHistory,
} from "../../../../store/actions";

const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "2px dotted #0f62fe" : "",
  width: "100%",
  filter: isDraggingOver ? "blur(10px)" : "",
});

const DetailSidebarComponent = React.forwardRef(
  ({ resource, isShow, handleCloseSidebar, currentUserInfo, mediaFileDoubleClicked, onCreatedModule }, ref) => {
    const dispatch = new useDispatch();
    const [isNew, setIsNew] = useState(true);
    const [subject, setSubject] = useState("");
    const [comment, setComment] = useState();
    const [history, setHistory] = useState(null);
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [status, setStatus] = useState(0);
    const [isDev, setIsDev] = useState(0);
    const [user, setUser] = useState(null);
    const [resetEditor, setResetEditor] = useState(false);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [pusherChannel, setPusherChannel] = useState(false);
    const [pusherConnector, setPusherConnnector] = useState();

    const newMessage = async (callback) => {
      const data = await callback;
      if (data?.is_dev != 1 && data?.support_id == resource?.id) {
        setHistory([...history, data]);
      }
    };

    useEffect(() => {
      const pusher = new PusherConnector();
      const channelName = "support-messages";
      const channel = pusher.createChannel(channelName);
      setPusherConnnector(pusher);
      setPusherChannel(channel);
    }, []);

    useEffect(() => {
      if (pusherChannel && pusherChannel.bind && !!resource && pusherConnector) {
        const eventName = "new-message";
        pusherConnector.unbindEvent(pusherChannel, eventName);
        pusherConnector.bindEvent(pusherChannel, eventName, newMessage);
      }
    }, [resource, history, pusherChannel, pusherConnector]);

    useEffect(() => {
      if (!!currentUserInfo?.id) {
        const { role_id } = currentUserInfo;
        setIsDev(currentUserInfo.is_support == true ? 1 : 0);
        dispatch(getAllUserMedia(currentUserInfo?.id));
      }
    }, [currentUserInfo?.id]);

    useEffect(() => {
      if (!!resource) {
        setSubject(resource.subject);
        setComment();
        setHistory(resource.history);
        setStatus(resource.status);
        dispatch(setActiveSupportTicket(resource?.id));
        setIsNew(false);
        setResetEditor(true);
        setUser(resource.user);
      }

      return () => {
        dispatch(setActiveSupportTicket(null));
      };
    }, [resource]);

    const handleSubmit = async () => {
      if (currentUserInfo?.id && subject && (comment || attachedFiles.length > 0)) {
        const payload = {
          user_id: currentUserInfo?.id,
          status: status, // 0 => in progress
          subject: subject,
          is_dev: isDev,
          comment: comment,
          attached_files: JSON.stringify(attachedFiles),
        };
        if (!!selectedHistory) {
          await dispatch(updateSupportModuleHistory(selectedHistory, { comment }));
        } else {
          if (isNew) {
            if (!comment) {
              payload.comment = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
            }
            onCreatedModule();
            await dispatch(createSupportModule(payload));
          } else {
            payload.type = "update";
            if (!comment) {
              payload.comment = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
            }
            await dispatch(updateSupportModule(resource?.id, payload));
          }
        }
        setSelectedHistory(null);
        setAttachedFiles([]);
      } else {
      }
    };

    const handleEditorChange = (editorState) => {
      const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      setComment(data);
      setResetEditor(false);
      setUser(currentUserInfo);
    };

    const handleDragEnd = (result) => {
      const { destination } = result;
      if (!destination) return;
      if (result.destination.droppableId === "droppable_input") {
        const item = JSON.parse(result.draggableId);
        const tempFiles = attachedFiles.filter((e) => e?.id !== item?.id);
        setAttachedFiles([...tempFiles, item]);
      }
    };

    const handleRemoveAttachedFile = (value) => {
      const temp = attachedFiles.filter((e) => e?.id !== value);
      setAttachedFiles(temp);
    };

    const handleSelecedHistory = (id) => setSelectedHistory(id);

    const handleCloseSupportModule = () => {
      const payload = {
        status: 1,
        type: "close",
      };

      dispatch(closeSupportModule(resource?.id, payload));
    };

    return (
      <Drawer
        title={!isNew ? `${subject} (ID: ` + convertToLongNumber(resource?.id) + ")" : "New Ticket"}
        placement="right"
        mask={false}
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          <DragDropContext onDragEnd={handleDragEnd}>
            <ScrollWrapper>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  marginTop: "1.5rem",
                }}
              >
                <div className="resourceInfo d-flex" style={{ marginTop: 0, marginBottom: "0.5rem" }}>
                  <Droppable droppableId="droppable_input">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {snapshot.isDraggingOver && <h1>Test</h1>}

                        <SlackEditor
                          inputBoxTitle="Reply"
                          onEditorChange={handleEditorChange}
                          historyData={history}
                          userInfo={user}
                          onSelectedHistory={handleSelecedHistory}
                          onSubmit={handleSubmit}
                          isReset={resetEditor}
                          mediaFiles={attachedFiles}
                          onRemoveBtnClicked={handleRemoveAttachedFile}
                          fileDoubleClicked={mediaFileDoubleClicked}
                        />
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div
                className="actionContent"
                style={{
                  margin: 0,
                  display: "flex",
                }}
              >
                <button
                  className="common-btn delete-btn"
                  onClick={handleCloseSupportModule}
                  style={{
                    marginRight: "-3px",
                  }}
                >
                  Close Support Case
                </button>
              </div>
            </ScrollWrapper>
          </DragDropContext>
        </div>
      </Drawer>
    );
  }
);

export default DetailSidebarComponent;

const dataCheckVariance = {
  "Cilostazol Therapy": {
    contraindication:
      "The patient is unable to use Cilostazol (Pletal) therapy due to intolerance and/or medical contraindication based on health status. (COR I)(CS 1)",
    side_Effects: "The patient is unable to use Cilostazol (Pletal) therapy due to intolerance with side effects. (COR I)(CS 1)",
    cost: "The patient is unable to use Cilostazol (Pletal) therapy due to inability to afford the medication. (COR I)(CS 1)",
    refused: "The patient is unable to use Cilostazol (Pletal) therapy due the patients refusal to be on the medication. (COR I)(CS 1)",
  },
  "Antiplatelet Therapy": {
    contraindication:
      "The patient is unable to use antiplatelet therapy due to intolerance and/or medical contraindication based on health status. (COR I)(CS 1)",
    side_Effects: "The patient is unable to use antiplatelet therapy due to intolerance with side effects. (COR I)(CS 1)",
    cost: "The patient is unable to use antiplatelet therapy due to inability to afford the medication. (COR I)(CS 1)",
    refused: "The patient is unable to use antiplatelet therapy due to the patients refusal to be on the medication. (COR I)(CS 1)",
  },
  "Statin Therapy": {
    contraindication:
      "The patient is unable to use statin therapy due to intolerance and/or medical contraindication based on health status. (COR I)(CS 1)",
    side_Effects: "The patient is unable to use statin therapy due to intolerance with side effects. (COR I)(CS 1)",
    cost: "The patient is unable to use statin therapy due to inability to afford the medication. (COR I)(CS 1)",
    refused: "The patient is unable to use statin therapy due to the patients refusal to be on the medication. (COR I)(CS 1)",
  },
  "ACE Inhibitor or Angiotensin Receptor Blocker Therapy": {
    contraindication:
      "The patient is unable to use ACE Inhibitor or Angiotensin Receptor Blocker therapy due to intolerance and/or medical contraindication based on health status.",
    side_Effects: "The patient is unable to use ACE Inhibitor or Angiotensin Receptor Blocker therapy due to intolerance with side effects",
    cost: "The patient is unable to use ACE Inhibitor or Angiotensin Receptor Blocker therapy due to inability to afford the medication.",
    refused:
      "The patient is unable to use ACE Inhibitor or Angiotensin Receptor Blocker therapy due to the patients refusal to be on the medication.",
  },
};

export default dataCheckVariance;

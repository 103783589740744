import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import { autoZip } from "../../../services/api.services";
import { editPharmacy, getPharmacies, getPharmacy, savePharmacy } from "../../../store/actions";

let timer = null;

const AddPharmacy = () => {
  const dispatch = useDispatch();
  const pharmacies = useSelector((state) => state.common.pharmacies);
  const pharmacy = useSelector((state) => state.common.pharmacy);

  const [zipAutoData, setZipAutoData] = useState({});
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const [isError, setIsError] = useState(false);
  const [state, setState] = useState({
    contact_name: "",
    company_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zipcode: "",
    npi: "",
    phone: "",
    fax1: "",
  });

  const debounce = useDebounce(search, 400);

  useEffect(() => {
    fetchData(debounce);
  }, [debounce]);

  const fetchData = (query) => {
    if (!query) {
      setListSearch([]);
      return;
    }
    dispatch(getPharmacies(query));
  };

  useEffect(() => {
    dispatch(getPharmacies(""));
  }, []);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0) {
      if (zipAutoData?.success) {
        if (zipAutoData?.addresses && zipAutoData?.addresses[0]) {
          const address = zipAutoData?.addresses[0];
          const params = {
            ...state,
            state: address.state_id,
            city: address.place,
          };
          setState(params);
        }
      } else {
        const params = {
          ...state,
          state: "",
          city: "",
        };
        setState(params);
      }
    }
  }, [zipAutoData]);

  useEffect(() => {
    setListSearch(pharmacies);
  }, [pharmacies]);

  useEffect(() => {
    if (pharmacy && pharmacy?.id) {
      setState({
        ...pharmacy,
      });
    }
  }, [pharmacy]);

  const addSearch = async (value) => {
    if (value && value?.id) {
      await dispatch(getPharmacy(value?.id));
    }
  };

  const handleProvider = (value, type) => {
    const params = {
      ...state,
      [type]: value,
    };
    if (type === "zipcode") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const zipData = await autoZip(value);
        if (!zipData) {
          setZipAutoData({ success: false });
        } else {
          setZipAutoData(zipData);
        }
      }, 1000);
    }
    setState(params);
    if (params && params?.id) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const saveSuccess = await dispatch(editPharmacy(params?.id, params));
        if (saveSuccess) {
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
          }, 1000);
        }
      }, 500);
    }
  };

  const handleBtnSaveClicked = async () => {
    let error;
    Object.keys(state).forEach((field) => {
      if (field !== "contact_name" && field !== "address_2" && field !== "npi") {
        if (!state[field]) {
          error = true;
          setIsError(true);
        }
      }
    });
    if (!error) {
      const saveSucess = await dispatch(savePharmacy(state));
      if (saveSucess) {
        setIsToastr(true);
        setTimeout(() => {
          setState({});
          setIsToastr(false);
        }, 1000);
      }
    }
  };

  const styleLeftElement = {
    width: "50%",
    paddingRight: "1rem",
    borderRight: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const styleRightElement = {
    width: "50%",
    paddingLeft: "1rem",
    borderLeft: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <AutoCompleteSearch
        options={listSearch}
        handlePopulate={(value) => {
          addSearch(value);
        }}
        handleSearch={(value) => {
          setSearch(value);
        }}
        title="Healthcare-provider-location"
        placeholder="Search Pharmacy"
        actionLabel="Search"
        keyLabel="name"
      />
      <div style={{ flex: 1, paddingTop: "1rem", width: "100%", overflow: "scroll" }}>
        {isToastr && <ToastMessage type="Add Pharmacy" status="success" msg="Save Pharmacy Successfully!" />}
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: "1rem",
                borderRight: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Contact Name"
                value={state.contact_name}
                handleChange={(value) => {
                  handleProvider(value, "contact_name");
                }}
                fullWidth
              />
            </div>
            <div
              style={{
                width: "50%",
                paddingLeft: "1rem",
                borderLeft: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Pharmacy Name"
                value={state.company_name}
                handleChange={(value) => {
                  handleProvider(value, "company_name");
                }}
                fullWidth
                required
                enableErrorText={isError}
                isError={!state.company_name}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: "1rem",
                borderRight: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Address"
                value={state.address_1}
                handleChange={(value) => {
                  handleProvider(value, "address_1");
                }}
                fullWidth
                required
                enableErrorText={isError}
                isError={!state.address_1}
              />
            </div>
            <div
              style={{
                width: "50%",
                paddingLeft: "1rem",
                borderLeft: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Suite No"
                value={state.address_2}
                handleChange={(value) => {
                  handleProvider(value, "address_2");
                }}
                fullWidth
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="City"
                value={state.city}
                handleChange={(value) => {
                  handleProvider(value, "city");
                }}
                fullWidth
                required
                enableErrorText={isError}
                isError={!state.city}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="State"
                value={state.state}
                handleChange={(value) => {
                  handleProvider(value, "state");
                }}
                fullWidth
                required
                enableErrorText={isError}
                isError={!state.state}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Zip Code"
                value={state.zipcode}
                handleChange={(value) => {
                  handleProvider(value, "zipcode");
                }}
                fullWidth
                required
                enableErrorText={isError}
                isError={!state.zipcode}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="NPI"
                value={state.npi}
                handleChange={(value) => {
                  handleProvider(value, "npi");
                }}
                fullWidth
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Phone"
                value={state.phone}
                handleChange={(value) => {
                  handleProvider(value, "phone");
                }}
                fullWidth
                mask={"phone"}
                required
                enableErrorText={isError}
                isError={!state.phone}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Fax"
                value={state.fax1}
                handleChange={(value) => {
                  handleProvider(value, "fax1");
                }}
                fullWidth
                mask={"phone"}
                required
                enableErrorText={isError}
                isError={!state.fax1}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
              float: "right",
              marginLeft: "10px",
              marginRight: "0px",
            }}
          >
            <button onClick={handleBtnSaveClicked} className={"common-btn"}>
              Save
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default AddPharmacy;

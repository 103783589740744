/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getLocationsStatus } from "../../../../store/actions";
import { Icon } from "antd";

const InActive = ({ activeIndex, title, loading, handleRow }) => {
  const ref = useRef(null);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const size = useWindowSize(ref);
  const dispatch = useDispatch();
  const locationStatusInActive = useSelector((state) => state.common.locationStatusInActive);
  const [tableRows, setTableRows] = useState([]);
  let emptyRows = [];

  useEffect(() => {
    setTableRows(locationStatusInActive);
  }, [locationStatusInActive]);

  const fetchApi = async () => {
    await dispatch(getLocationsStatus("inactive"));
  };

  useEffect(() => {
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
        <div className="tableItem">
          <div className="th" style={{ width: "calc(17% - 3rem)", marginLeft: "3rem" }}>
            <b>Name</b>
          </div>
          <div className="th" style={{ width: "17%" }}>
            <b>Angiography</b>
          </div>
          <div className="th" style={{ width: "17%" }}>
            <b>Laboratory</b>
          </div>
          <div className="th" style={{ width: "17%" }}>
            <b>Hyperbaric 02</b>
          </div>
          <div className="th" style={{ width: "17%" }}>
            <b>Ultrasound</b>
          </div>
          <div className="th" style={{ width: "16%" }}>
            <b>Wound Care</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper>
            <div className="tableList">
              {tableRows.map((row, index) => (
                <div
                  className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                  key={`resultTable-${title}-${index}`}
                  onClick={handleRow(index, row)}
                >
                  <div className="td with-icon" style={{ width: "17%" }}>
                    <div className="iconField">
                      <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                    </div>
                    <p>{row?.value || ""}</p>
                  </div>
                  <div className="td" style={{ width: "17%" }}>
                    <p>{row.angiography === -1 ? "" : row.angiography ? "Yes" : "No"}</p>
                  </div>
                  <div className="td" style={{ width: "17%" }}>
                    <p>{row.laboratory === -1 ? "" : row.laboratory ? "Yes" : "No"}</p>
                  </div>
                  <div className="td" style={{ width: "17%" }}>
                    <p>{row.hyperbaric === -1 ? "" : row.hyperbaric ? "Yes" : "No"}</p>
                  </div>
                  <div className="td" style={{ width: "17%" }}>
                    <p>{row.ultrasound === -1 ? "" : row.ultrasound ? "Yes" : "No"}</p>
                  </div>
                  <div className="td" style={{ width: "16%" }}>
                    <p>{row.wound_care === -1 ? "" : row.wound_care ? "Yes" : "No"}</p>
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                  <div className="td" style={{ width: "17%" }} />
                  <div className="td" style={{ width: "17%" }} />
                  <div className="td" style={{ width: "17%" }} />
                  <div className="td" style={{ width: "17%" }} />
                  <div className="td" style={{ width: "17%" }} />
                  <div className="td" style={{ width: "16%" }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default InActive;

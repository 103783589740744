import { Drawer } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SummaryEmptySVG } from "../../components/icons/SVGIcon";
import ScrollWrapper from "../../components/ScrollBar";
import "./style.scss";
import { getLocal } from "src/helpers/Local";

const EmptySidebar = React.forwardRef(({ isShow, handleCloseSidebar, processOrders, is_locked, ...restProps }, ref) => {
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const patientResource = useSelector((state) => state.patientRecords.resource || state.clinicProvider.patientResource);
  const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
  const deceased = patientResource?.deceased;
  const cancelled_reason = patientResource?.cancelled_reason;

  return (
    <Drawer
      title={`${fullName} ${getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""}`}
      placement="right"
      variable="permanent"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (cancelled_reason != "" && cancelled_reason) ? "red" : ""}`}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 160px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
          {!is_locked && (
            <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
              <button
                className="common-btn blue-btn"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={processOrders}
              >
                <span>Process Orders</span>
                <span>+</span>
              </button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default EmptySidebar;

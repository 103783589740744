import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ResetPasswordForm from "./components/ResetPasswordForm";
import Loading from "../../common/components/Loading";
import * as API from "../../services/api.services";
import { getLocal } from "../../helpers/Local";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [queryData, setQueryData] = useState({
    email: '',
    code: '',
  });
  const [resetError, setResetError] = useState("");
  const [isAuth] = useState(!!getLocal("cvai-auth-token"));

  useEffect(() => {
    if (isAuth) {
      props.history.push('/');
    }
  }, [isAuth]);

  useEffect(() => {
    const {
      location: { search },
    } = props;
    const email = new URLSearchParams(search).get('email');
    const code = new URLSearchParams(search).get('code');
    setQueryData({
      email,
      code,
    });
  }, []);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular</title>
        <meta name="title" content="FAACT Vascular" />
      </Helmet>
    );
  };

  const handleReset = async (password, confirm_password) => {
    if (password !== confirm_password) {
      setResetError("Password doesn't match");
      return;
    } else {
      setResetError('');
    }
    const { email, code } = queryData;
    if (!password || !confirm_password || !email || !code) {
      return;
    }
    setLoading(true);
    try {
      const params = {
        email,
        code,
        password,
        confirm_password,
      };
      const response = await API.resetPassword(params);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (isAuth) {
    return null;
  }

  return (
    <div className="loginPage">
      {loading && <Loading />}
      {renderMetadata()}
      <div className="loginMainContainer">
        <div className="splashContainer">
          <ResetPasswordForm
            handleReset={handleReset}
            onCancel={() => props.history.push("/login")}
            resetError={resetError}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import {createReducer} from 'redux-create-reducer';
import AT from '../actionTypes';

const initialState = {
  isSidebarOpen: false,
};

const UPDATE_SIDEBAR_STATE_SUCCEEDED = `${AT.UPDATE_SIDEBAR_STATE}_SUCCEEDED`;

export default createReducer(initialState, {
  [UPDATE_SIDEBAR_STATE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isSidebarOpen: action?.payload,
    });
  },
});

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import TopBar from '../../common/components/TopBar';
import { getAppointmentBoardAppointments } from '../../store/actions';
import ClinicTable from './ClinicScheduleTable';
import PatientTable from './PatientScheduleTable';
import ScheduleTimer from './scheduleTimer';
import './style.scss';

const LocationSchedulePage = ({match}) => {
  const {key} = match.params;
  const dispatch = useDispatch();
  const appointments = useSelector(
      (state) => state.appointmentBoard.appointments,
  );
  const location = useSelector((state) => state.appointmentBoard.location);
  const type = useSelector((state) => state.appointmentBoard.type);

  const retrieveAppointments = () => {
    dispatch(getAppointmentBoardAppointments(key));
  };

  useEffect(() => {
    retrieveAppointments();
    setInterval(retrieveAppointments, 60000);
  }, []);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>Location Schedule | FAACT Vascular</title>
        <meta name="title" content="Location Schedule | FAACT Vascular" />
      </Helmet>
    );
  };
  return (
    <div className="locationSchedule">
      {renderMetadata()}
      <TopBar
        title={`Location Schedule ${location?.value ? location?.value : ''}`}
        sideComponent={<ScheduleTimer />}
      />
      <div className="main">
        {type === 'clinic' && (
          <ClinicTable appointments={appointments ? appointments : []} />
        )}

        {type === 'patient' && (
          <PatientTable appointments={appointments ? appointments : []} />
        )}
      </div>
    </div>
  );
};

export default LocationSchedulePage;

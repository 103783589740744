import {createReducer} from 'redux-create-reducer';
import moment from 'moment';
import AT from '../actionTypes';

const initialState = {
  uploading: '',
  patientResource: undefined,
  schedules: [],
  selectedDocument: undefined,
  documents: [],
  loadingReset: false,
  loadingSchedules: false,
  loadingResource: false,
};
const DELETE_PATIENT_DOCUMENT_SUCCESS = `${AT.DELETE_PATIENT_DOCUMENT}_SUCCEEDED`;
const CHOOSE_SELECTED_PATIENT_RESOURCE_STARTED = `${AT.CHOOSE_SELECTED_PATIENT_RESOURCE}_STARTED`;
const CHOOSE_SELECTED_PATIENT_RESOURCE_SUCCEEDED = `${AT.CHOOSE_SELECTED_PATIENT_RESOURCE}_SUCCEEDED`;
const RESET_SELECT_DOCUMENT_SUCCEEDED = `${AT.RESET_SELECT_DOCUMENT}_SUCCEEDED`;
const RESET_STATE_RESOURCE_SUCCEEDED = `${AT.RESET_STATE_RESOURCE}_SUCCEEDED`;

export default createReducer(initialState, {
  [RESET_SELECT_DOCUMENT_SUCCEEDED](state) {
    return Object.assign({}, state, {
      selectedDocument: undefined,
      documents: [],
    });
  },

  [RESET_STATE_RESOURCE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      patientResource: undefined,
    });
  },

  [CHOOSE_SELECTED_PATIENT_RESOURCE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingReset: false,
    });
  },
  [CHOOSE_SELECTED_PATIENT_RESOURCE_STARTED](state) {
    return Object.assign({}, state, {
      loadingReset: true,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [DELETE_PATIENT_DOCUMENT_SUCCESS](state) {
    return Object.assign({}, state, {
      selectedDocument: {},
    });
  },
  [`${AT.GET_PATIENT_SCHEDULES}_STARTED`](state, action) {
    return {
      ...state,
      loadingSchedules: true,
    };
  },
  [`${AT.GET_PATIENT_SCHEDULES}_SUCCEEDED`](state, action) {
    (action?.payload?.elements || []).map((element) => {
      const newStart = moment(element.start, 'YYYY-MM-DD HH:mm:ss');
      const newEnd = moment(element.end, 'YYYY-MM-DD HH:mm:ss');
      element.start = newStart.toDate();
      element.end = newEnd.toDate();
      return element;
    });
    return {
      ...state,
      schedules: action?.payload?.elements,
      loadingSchedules: false,
    };
  },
  [`${AT.SEARCH_PATIENT_SCHEDULES}`](state, action) {
    const newSchedules = [];
    const {searchValue} = action?.payload || {};
    for (const schedule of state.schedules) {
      if (searchValue === '' || schedule.title.includes(searchValue)) {
        schedule.isHighlight = true;
      } else {
        schedule.isHighlight = false;
      }
      newSchedules.push(schedule);
    }

    return {
      ...state,
      schedules: newSchedules,
    };
  },
  [`${AT.CHOOSE_PATIENT_ON_SCHEDULES}_STARTED`](state, action) {
    return {
      ...state,
      loadingResource: true,
    };
  },
  [`${AT.REMOVE_PATIENT_ON_SCHEDULES}_SUCCEEDED`](state, action) {
    return {
      ...state,
      patientResource: undefined,
      uploading: '',
      selectedDocument: undefined,
      documents: [],
      loadingResource: false,
    };
  },
  [`${AT.CHOOSE_PATIENT_ON_SCHEDULES}_SUCCEEDED`](state, action) {
    const patientResource = action?.payload;
    return {
      ...state,
      patientResource,
      uploading: '',
      selectedDocument: undefined,
      documents: [],
      loadingResource: false,
    };
  },
  [`${AT.UPLOAD_STARTED}`](state, action) {
    return {
      ...state,
      uploading: 'uploading',
    };
  },
  [`${AT.UPLOAD_DONE}`](state, action) {
    return {
      ...state,
      uploading: 'done',
    };
  },
  [`${AT.UPLOAD_REFRESH}`](state, action) {
    return {
      ...state,
      uploading: '',
    };
  },
  [`${AT.SAVE_UPLOADED_PATIENT_DOCUMENT}_SUCCEEDED`](state, action) {
    return {
      ...state,
      uploading: '',
    };
  },
  [`${AT.SEARCH_PATIENT_DOCUMENTS}_SUCCEEDED`](state, action) {
    const {documents} = action?.payload || {};
    return {
      ...state,
      documents,
    };
  },

  [`${AT.GET_PATIENT_DOCUMENT}_SUCCEEDED`](state, action) {
    const selectedDocument = action?.payload;
    return {
      ...state,
      selectedDocument,
    };
  },
});

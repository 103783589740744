import Pusher from "pusher-js";

export default class PusherConnector {
  constructor() {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    this.pusher.connection.bind("error", function (err) {
      if (err && err?.error && err?.error?.data) {
        if (err?.error?.data?.code === 4004) {
          alert("Over limit!");
        } else {
          console.log(err);
        }
      } else {
        console.log("error sentry");
      }
    });
  }

  createChannel(name) {
    return this.pusher.subscribe(name);
  }

  async bindEvent(channel, name, callback) {
    channel.bind(name, (data) => {
      callback(data);
    });
  }

  async unbindEvent(channel, name) {
    channel.unbind(name);
  }
}

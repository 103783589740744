import { Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import { getLocations } from "../../../store/actions";
import { TabHeaders } from "../constant";
import TransactionHistory from "./inventoryModules/HistoryTable";
import InventorySearch from "./inventoryModules/InventorySearch";
import Invoices from "./inventoryModules/Invoices";
import NarcoticInventory from "./inventoryModules/NarcoticInventory";
import PurchaseList from "./inventoryModules/PurchaseList";
import Receiving from "./inventoryModules/Receiving";
import ReturnCredit from "./inventoryModules/ReturnCredit";
import Supplies from "./inventoryModules/Supplies";

const InventoryModule = () => {
  const locations = useSelector((state) => state.common.locations);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!locations || !locations.length) {
      dispatch(getLocations());
    }
  }, [locations]);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Inventory Module" subStatus={""} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {TabHeaders.map((tab, index) => (
                <div
                  key={`tabHeaders-${tab.label}-${index}`}
                  className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                  style={{ width: tab.width }}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.label}
                </div>
              ))}
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <InventorySearch title="inventory-search" locations={locations} />}
        {activeTab === 1 && <Supplies title="angiographic-supplies" locations={locations} />}
        {activeTab === 2 && <NarcoticInventory title="narcotic-inventory" locations={locations} />}
        {activeTab === 3 && <PurchaseList title="inventory-purchase-list" locations={locations} />}
        {activeTab === 4 && <Receiving title="inventory-receiving" locations={locations} />}
        {activeTab === 5 && <ReturnCredit title="inventory-return-credit" locations={locations} />}
        {activeTab === 6 && <Invoices title="inventory-Invoices" locations={locations} />}
        {activeTab === 7 && <TransactionHistory title="inventory-transaction-history" locations={locations} />}
      </div>
    </div>
  );
};

export default InventoryModule;

import {createActionThunk} from 'redux-thunk-actions';

import AT from '../actionTypes';


export const setOblDateString = createActionThunk(
    AT.SET_OBL_DATE,
    (type, data) => ({type, data}),
);
export const setClinicDateString = createActionThunk(
    AT.SET_CLINIC_DATE,
    (type, data) => ({type, data}),
);

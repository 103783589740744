import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import { get_plan_patient, markPlanProcessed, save_ct_info_plan, setGlobalLoading, setProcedureData } from "../../../store/actions";

const CtComponent = ({
  procedure_id_row,
  handleContinue,
  nextSave,
  titleProps,
  display,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  currentPlanId,
  activeStatus,
  is_locked,
  ...restProps
}) => {
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");

  const dispatch = useDispatch();
  const [checkDetail, setCheckDetail] = useState({});
  const [textCT, setTextCT] = useState(null);

  const fetchFirst = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
    await setCheckDetail({});
    if (plans && plans.length > 0) {
      const check = plans.find((r) => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter((r) => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0]);
        }
      }
    }
  };

  useEffect(() => {
    setTextCT(checkDetail?.procedure_extra?.reason || "");
  }, [checkDetail]);

  useEffect(() => {
    fetchFirst();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  const callApiDetail = async () => {
    if (currentPlanId) {
      await dispatch(setGlobalLoading(true));
      await dispatch(markPlanProcessed(currentPlanId));
      await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      await dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    if (currentPlanId) {
      callApiDetail();
    }
  }, [currentPlanId]);

  const setFirstFC = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const onHandleClick = async () => {
    if (textCT && textCT.length > 0) {
      if (nextSave) {
        dispatch(setGlobalLoading(true));
        await dispatch(
          save_ct_info_plan(currentPlanId, {
            plan_id: currentPlanId,
            reason: textCT,
          })
        );
        nextSave();
        dispatch(setGlobalLoading(false));
        setFirstFC(-1);
      }
    }
  };

  const onChangeText = (e) => {
    setTextCT(e.target.value);
  };

  return (
    <>
      <div className="resourceContent plan_order_tab">
        <React.Fragment key={`clinic-appointment-${0}`}>
          <TextArea
            rows={4}
            className={textCT && textCT.length > 0 ? "" : "require_text_area"}
            placeholder="Indications for Study"
            value={textCT}
            showCount
            onChange={onChangeText}
            style={{ borderRadius: "0" }}
          />
        </React.Fragment>
      </div>
      {activeStatus && textCT && textCT.length > 0 && (
        <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
          <button
            disabled={is_locked}
            className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
            onClick={() => {
              onHandleClick();
            }}
          >
            Continue
          </button>
        </div>
      )}
    </>
  );
};

export default CtComponent;

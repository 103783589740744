import { Button, Form, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import { validateField } from "../../../services/validations";
import Styles from "./style.module.css";

const LoginForm = ({ handleSubmit, loginError, onClickForget }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (validateField("text", userName) || validateField("text", password)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [userName, password]);

  const handleLogin = () => {
    if (invalid) {
      setEnableError(true);
      return;
    }
    handleSubmit(userName, password);
  };

  const handleLoginKeyDown = (event) => {
    if (event.key === "Enter") {
      if (invalid) {
        setEnableError(true);
        return;
      }
      handleSubmit(userName, password);
    }
  };
  return (
    <div className={Styles.login_container}>
      <div className={Styles.image_container}>
        <img src="/images/LoginImage.svg" alt="black logo" />
      </div>
      <div className={Styles.login_form_container}>
        <div className={Styles.logo_container}>
          <img src="/images/faact_logo_black.svg" alt="black logo" />
        </div>
        <div className={Styles.formContainer__heading}>
          <h1>Log In</h1>
          <div>
            <p>Enter your username and password to login to our dashboard</p>
          </div>
        </div>

        {enableError && invalid && (
          <div className="formContainer__message">
            <Icon type="warning" theme="twoTone" twoToneColor="#DA1E28" />
            <p>The info entered doesn't match our records. Please try again or select Forget Username or Password.</p>
          </div>
        )}

        <Form
          name="normal_login"
          className={Styles.login_form}
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <label style={{ fontWeight: "bolder", color: "black", marginTop: "1rem" }}>Username</label>
          <EditableFormInput
            id="username"
            isLoginScreen={true}
            isBackgroundTransparent={true}
            mainStyle={{ margin: "10px 0 2rem 0" }}
            value={userName}
            handleChange={(value) => setUserName(value)}
            isError={validateField("text", userName)}
            required={true}
            fullWidth={true}
            enableErrorText={enableError}
            helperText={"Username is Required!"}
            autoFocus={true}
          />

          <label style={{ fontWeight: "bolder", color: "black" }}>Password</label>
          <EditableFormInput
            isBackgroundTransparent={true}
            id="pass"
            mainStyle={{ margin: "10px 0 2rem 0" }}
            label={"Enter your Password"}
            value={password}
            handleChange={(value) => setPassword(value)}
            isError={validateField("text", password)}
            required={true}
            fullWidth={true}
            type={"password"}
            enableErrorText={enableError}
            helperText={"Password is Required!"}
            onKeyDown={handleLoginKeyDown}
          />

          {loginError && (
            <div
              style={{
                color: "#da1e28",
                fontSize: "0.9em",
                marginBottom: "12px",
              }}
            >
              Login ID or Password is invalid!
            </div>
          )}

          <Form.Item className={Styles.submit_container}>
            <Button
              type="primary"
              htmlType="submit"
              className={Styles.login_form_button}
              onClick={handleLogin}
              onKeyDown={handleLoginKeyDown}
            >
              Sign In
            </Button>
          </Form.Item>

          <Form.Item>
            Don't have an account? <Link style={{ color: "#0062ff" }}>Sign Up</Link> <br />{" "}
            <a style={{ color: "#0062ff" }} onClick={onClickForget}>
              Forgot Password?
            </a>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;

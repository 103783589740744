import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DeleteIconButton from "src/common/components/icons/DeleteIconButton";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getListTask, setGlobalLoading } from "../../../../store/actions";
import { deleteTask } from "src/services/api.services";
const Tasks = ({ title }) => {
  const [data, setData] = useState([]);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [toDelete, setToDelete] = useState(-1);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(false);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  let emptyRows = [];
  const size = useWindowSize(ref);
  const dispatch = useDispatch();

  const setIdLocal = async (id) => {
    await localStorage.setItem("IdEmploy", JSON.stringify(id));
  };

  const getApi = async () => {
    dispatch(setGlobalLoading(true));
    const dataSuccess = await dispatch(getListTask(1));
    setData(dataSuccess?.data);
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    getApi();
  }, []);

  const handleRow = (index, id) => () => {
    setRow(index);
    if (index !== activeIndex) {
      setIdLocal(id);
    }
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const onLoadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t)) {
      if (!offLoad) {
        dispatch(setGlobalLoading(true));
        let listUpdate = null;
        listUpdate = await getListTask(page + 1);
        if (listUpdate?.data) {
          if (listUpdate?.data && listUpdate?.data.length > 0) {
            setData([...data, ...listUpdate?.data]);
            setPage(page + 1);
            dispatch(setGlobalLoading(false));
          } else {
            setOffLoad(true);
            dispatch(setGlobalLoading(false));
          }
        } else {
          dispatch(setGlobalLoading(false));
        }
      }
    }
  };

  const handleRemove = async (id, index) => {
    if (id) {
      dispatch(setGlobalLoading(true));
      await deleteTask(id);
      data.splice(index, 1);
      setData([...data]);
      dispatch(setGlobalLoading(false));
    }
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = data.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > data.length) {
    emptyRows = new Array(rowCount - data.length).fill({});
  }

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
            <div className="tableItem">
              <div className="th" style={{ width: "calc(20% - 3rem)", marginLeft: "3rem" }}>
                <b>{"Patient Name"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>{"Staff Name"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>{"Message Date Time"}</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>Message</b>
              </div>
              <div className="th" style={{ width: "20%" }}>
                <b>Reply</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper onScrollFrame={onLoadMore}>
                <div className="tableList">
                  {data.map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "20%" }}>
                        <div className="iconField d-flex">
                          {row?.approved_overtime ? (
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          ) : (
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          )}
                        </div>
                        <p>{row?.patient?.name}</p>
                      </div>
                      <div className="td" style={{ width: "20%" }}>
                        <p>{row?.provider?.value}</p>
                      </div>
                      <div className="td" style={{ width: "20%" }}>
                        <p>{row?.updated_at ? moment(row?.updated_at).format("MM/DD/YYYY hh:mm:ss A") : ""}</p>
                      </div>
                      <div className="td" style={{ width: "20%" }}>
                        <p>{row?.message}</p>
                      </div>
                      <div className="td with-icon" style={{ width: "20%" }}>
                        <p>{row?.reply}</p>
                        <div className="iconField d-flex" style={{ paddingRight: 20 }}>
                          <DeleteIconButton
                            selected={toDelete === index}
                            icon={"can"}
                            handleClick={(e) => {
                              e.stopPropagation();
                              if (toDelete === index) {
                                setToDelete(-1);
                                handleRemove(row?.id, index);
                              } else {
                                setToDelete(index);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                      <div className="td" style={{ width: "20%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;

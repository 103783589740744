export default function ReplaceTrash(value, typeRemove, valueRemove = "") {
  if (value && value.length > 0) {
    if (typeRemove) {
      return value.replaceAll(typeRemove, valueRemove).replaceAll("No No", "No").replaceAll("Not Not", "Not").replaceAll("undefined, ", " ");
    } else {
      return value.replaceAll("No No", "No").replaceAll("Not Not", "Not").replaceAll("undefined, ", " ");
    }
  } else {
    return null;
  }
}

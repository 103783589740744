import React, { useState } from 'react';
import './style.scss';

const CptCodeDataTable = ({title, data, disableHeader, half}) => {
  const [activeIndex, setActive] = useState(-1);

  return (
    <div className="subDataTable">
      {!disableHeader && (
        <div className="subCPTTable__title">
          <span>{title}</span>
          <span>Description</span>
        </div>
      )}
      {(data || []).map((row, index) => (
        <div
          className={`subCPTTable__item ${half ? 'half' : ''} ${
            activeIndex === index ? 'active' : ''
          }`}
          key={`item-${row.item}-${index}`}
        >
          <span>{row.code}</span>
          <span>{row.short_desc}</span>
        </div>
      ))}
    </div>
  );
};

export default CptCodeDataTable;

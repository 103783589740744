import { Checkbox, Collapse, DatePicker, Icon, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReplaceN from "../../../helpers/ReplaceN";
import ScrollWrapper from "../ScrollBar";
import { CloseIconSVG, ExpandSVG, SearchSVG } from "../icons/SVGIcon";
import "./style.scss";
import { useSelector } from "react-redux";
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;

export const HPIItem = ({
  label,
  isIcon,
  options,
  handleEdit,
  disableSelfbox,
  handleOption,
  type,
  multiple,
  multiline,
  onChangeDatePicker,
  onChangeTimePicker,
  onChangeSelect,
  clickChangeRow,
  onChangeInput,
  onCheckBox,
  value,
  format,
  checkedAllNormalsP,
  onChangeCommentCheck,
  isPost,
  maxWidth,
  isLocked,
  dataUpdate,
  updateDataProps,
  minWidth,
}) => {
  const [openBox, setBox] = useState(false);
  const [comments, setComments] = useState({});
  const [data, setData] = useState({});
  const checkGreenValue = useSelector((state) => state.patientExamination.preoperative_drugs);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const right_abi_text = useSelector((state) => state.patientExamination.right_abi_text);
  const left_abi_text = useSelector((state) => state.patientExamination.left_abi_text);
  const checkRequireForReview = useSelector((state) => state.common.checkRequireForReview);
  const vessel_states_angio = useSelector((state) => state.patientExamination.vessel_states_angio);
  const all_normal_post_operative = useSelector((state) => state.patientExamination.all_normal_post_operative);
  const all_normal_pre_operative = useSelector((state) => state.patientExamination.all_normal_pre_operative);
  let commentsCheck = {};
  let dataAll = data;

  const onChangeDatePickerGlobal = (value, type) => {
    const time = moment(value).format("MM/DD/YYYY");
    if (type === "Last Anticoagulation") {
      dataAll = {
        ...dataAll,
        "Last Anticoagulation": time,
      };
      /*
      for this type we have the value as object include {date, time and momentobj}
       */
    } else if (type === "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions") {
      if (dataAll[type] && dataAll[type]["momentObj"] && dataAll[type]["date"] != "Invalid date" && dataAll[type]["time"] != "Invalid date") {
        const tempMoment = dataAll[type]["momentObj"];
        tempMoment.date(moment(value).date());
        tempMoment.month(moment(value).month());
        tempMoment.year(moment(value).year());
        dataAll = {
          ...dataAll,
          [type]: {
            date: tempMoment.format("MM/DD/YYYY"),
            time: tempMoment.format("hh:mm A"),
            momentObj: tempMoment,
          },
        };
      } else if (dataAll[type] == "Invalid date") {
        dataAll = {
          ...dataAll,
          [type]: { date: null, time: null },
        };
      } else if (dataAll[type]["date"] == "Invalid date" || dataAll[type]["time"] == "Invalid date") {
        dataAll = {
          ...dataAll,
          [type]: {
            date: time,
            time: moment().format("hh:mm A"),
            momentObj: moment(value),
          },
        };
      } else {
        dataAll = {
          ...dataAll,
          [type]: {
            date: time,
            time: moment().format("hh:mm A"),
            momentObj: moment(value),
          },
        };
      }
    } else {
      dataAll = {
        ...dataAll,
        [type]: time,
      };
    }
    setData(dataAll);
  };

  const onChangeTimePickerGlobal = (value, type) => {
    if (!value) {
      dataAll = {
        ...dataAll,
        [type]: null,
      };
    } else {
      const time = moment(value).format("hh:mm A");
      if (type === "Patient Admission") {
        dataAll = {
          ...dataAll,
          "Patient Admission": time,
        };
      } else if (type === "Recovery Entry Time") {
        dataAll = {
          ...dataAll,
          "Recovery Entry Time": time,
        };
      } else if (type === "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions") {
        if (dataAll[type] && dataAll[type]["momentObj"] && dataAll[type]["date"] != "Invalid date" && dataAll[type]["time"] != "Invalid date") {
          const tempMoment = dataAll[type]["momentObj"];
          tempMoment.hours(moment(value).hours());
          tempMoment.minutes(moment(value).minutes());
          dataAll = {
            ...dataAll,
            [type]: {
              date: tempMoment.format("MM/DD/YYYY"),
              time: tempMoment.format("hh:mm A"),
              momentObj: tempMoment,
            },
          };
        } else if (dataAll[type] == "Invalid date") {
          dataAll = {
            ...dataAll,
            [type]: { date: null, time: null },
          };
        } else if (dataAll[type]["date"] == "Invalid date" || dataAll[type]["time"] == "Invalid date") {
          dataAll = {
            ...dataAll,
            [type]: {
              date: time,
              time: moment().format("hh:mm A"),
              momentObj: moment(value),
            },
          };
        } else {
          dataAll = {
            ...dataAll,
            [type]: {
              date: time,
              time: moment().format("hh:mm A"),
              momentObj: moment(value),
            },
          };
        }
      } else {
        dataAll = {
          ...dataAll,
          [type]: time,
        };
      }
    }

    setData(dataAll);
  };

  const onChangeSelectGlobal = (value, type) => {
    if (type === "NPO Status") {
      dataAll = {
        ...dataAll,
        "NPO Status": value,
      };
    } else if (type === "Recovery Bed") {
      dataAll = {
        ...dataAll,
        "Recovery Bed": value,
      };
    } else if (type === "Accepting Nurse") {
      dataAll = {
        ...dataAll,
        "Accepting Nurse": value,
      };
    } else if (type === "ASA Classification") {
      dataAll = {
        ...dataAll,
        "ASA Classification": value,
      };
    } else {
      dataAll = {
        ...dataAll,
        [type]: value,
      };
    }
    setData(dataAll);
  };

  const onChangeInputGlobal = (value, type) => {
    if (type === "Notes") {
      dataAll = {
        ...dataAll,
        Notes: value,
      };
    } else if (type === "INR") {
      dataAll = { ...dataAll, INR: value };
    } else if (type === "Creatinine") {
      dataAll = {
        ...dataAll,
        Creatinine: value,
      };
    } else {
      dataAll = {
        ...dataAll,
        [type]: value,
      };
    }
    setData(dataAll);
  };

  const onClickOptionGlobal = (value, type, isArr) => {
    if (isArr) {
      if (comments[type]) {
        let checkComment = "";
        if (value && value.length > 0) {
          checkComment = comments[type];
          (value || []).map((r) => {
            if (r.checked == true) {
              checkComment = checkComment.replace(`${r.replaced_value}`, `${r.option}`);
            } else {
              checkComment = checkComment.replace(`${r.option}`, `${r.replaced_value}`);
            }
          });
        }
        if (checkComment.includes("No No")) {
          const check = checkComment.split("No No");
          if (check) {
            check.map((r) => {
              checkComment = checkComment.replace("No No", "No");
            });
          }
        }
        if (checkComment.includes("Not Not")) {
          const check = checkComment.split("Not Not");
          if (check) {
            check.map((r) => {
              checkComment = checkComment.replace("Not Not", "Not");
            });
          }
        }
        comments[type] = checkComment;
        setComments({ ...comments });
      }
    } else {
      if (type === "Contrast Allergy") {
        dataAll = {
          ...dataAll,
          "Contrast Allergy": value,
        };
      } else if (type === "Latex Allergy") {
        dataAll = {
          ...dataAll,
          "Latex Allergy": value,
        };
      } else if (type === "Breath Sounds") {
        dataAll = {
          ...dataAll,
          "Breath Sounds": value,
        };
      } else if (type === "Respiratory Pattern") {
        dataAll = {
          ...dataAll,
          "Respiratory Pattern": value,
        };
      } else if (type === "Cardiac") {
        dataAll = {
          ...dataAll,
          Cardiac: value,
        };
      } else if (type === "Anticoagulation") {
        dataAll = {
          ...dataAll,
          Anticoagulation: value,
        };
      } else {
        dataAll = {
          ...dataAll,
          [type]: value,
        };
      }
    }
    setData(dataAll);
  };

  const onCheckBoxGlobal = (value, type) => {
    if (type === "Reviewed") {
      dataAll = {
        ...dataAll,
        Reviewed: value ? 1 : 0,
      };
    } else if (type == "Informed" || type == "informed") {
      dataAll = {
        ...dataAll,
        Informed: value ? 1 : 0,
      };
    } else {
      dataAll = {
        ...dataAll,
        [type]: value,
      };
    }
    setData(dataAll);
  };

  const handleIconClick = () => {
    if (!disableSelfbox) {
      setBox(!openBox);
    } else {
      if (handleEdit) {
        handleEdit();
      }
    }
  };

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      let dataCheck = {};
      if (value.fields && Array.isArray(value.fields)) {
        (value.fields || []).map((r) => {
          let commentsValue = checkedAllNormalsP ? r.default_comments : r.comments;
          if (multiple && checkedAllNormalsP && r.field_value && typeof r.field_value == "object" && r.field_value.length > 0) {
            (r.field_value || []).map((r) => {
              if (r.checked == true) {
                commentsValue = commentsValue.replace(`${r.replaced_value}`, `${r.option}`);
              } else {
                commentsValue = commentsValue.replace(`${r.option}`, `${r.replaced_value}`);
              }
            });
            if (commentsValue.includes("No No")) {
              const check = commentsValue.split("No No");
              if (check) {
                check.map((r) => {
                  commentsValue = commentsValue.replace("No No", "No");
                });
              }
            }
            if (commentsValue.includes("Not Not")) {
              const check = commentsValue.split("Not Not");
              if (check) {
                check.map((r) => {
                  commentsValue = commentsValue.replace("Not Not", "Not");
                });
              }
            }
          } else if (multiple && !checkedAllNormalsP && r.field_value && typeof r.field_value == "object" && r.field_value.length > 0) {
            let commentNotCheck = "";
            (r.field_value || []).map((r) => {
              if (r.checked == true) {
                commentNotCheck += `${r.option}, `;
              }
            });
            commentsValue = commentNotCheck;
          }

          const value = r.field_value && r.field_value != "" && r.field_value != null ? r.field_value : "";
          if (r.field_name === "patient_admission") {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            commentsCheck = {
              ...commentsCheck,
              "Patient Admission": ReplaceN(commentsValue),
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dataCheck = {
              ...dataCheck,
              "Patient Admission": ReplaceN(value),
            };
          } else if (r.field_name === "npo_status") {
            commentsCheck = {
              ...commentsCheck,
              "NPO Status": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "NPO Status": ReplaceN(value) };
          } else if (r.field_name === "contrast_allergy") {
            commentsCheck = {
              ...commentsCheck,
              "Contrast Allergy": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "Contrast Allergy": ReplaceN(value) };
          } else if (r.field_name === "latex_allergy") {
            commentsCheck = {
              ...commentsCheck,
              "Latex Allergy": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "Latex Allergy": ReplaceN(value) };
          } else if (r.field_name === "last_anticoagulation" || r.field_name === "Last Anticoagulation") {
            commentsCheck = {
              ...commentsCheck,
              "Last Anticoagulation": ReplaceN(commentsValue),
            };
            dataCheck = {
              ...dataCheck,
              "Last Anticoagulation": ReplaceN(value) ? moment(value).format("MM/DD/YYYY") : null,
            };
          } else if (r.field_name === "breath_sounds") {
            commentsCheck = {
              ...commentsCheck,
              "Breath Sounds": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "Breath Sounds": ReplaceN(value) };
          } else if (r.field_name === "respiratory_pattern") {
            commentsCheck = {
              ...commentsCheck,
              "Respiratory Pattern": ReplaceN(commentsValue),
            };
            dataCheck = {
              ...dataCheck,
              "Respiratory Pattern": ReplaceN(value),
            };
          } else if (r.field_name === "cardiac") {
            commentsCheck = {
              ...commentsCheck,
              Cardiac: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, Cardiac: ReplaceN(value) };
          } else if (r.field_name === "inr") {
            commentsCheck = {
              ...commentsCheck,
              INR: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, INR: ReplaceN(value) };
          } else if (r.field_name === "creatinine") {
            commentsCheck = {
              ...commentsCheck,
              Creatinine: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, Creatinine: ReplaceN(value) };
          } else if (r.field_name === "asa_classification") {
            commentsCheck = {
              ...commentsCheck,
              "ASA Classification": ReplaceN(commentsValue),
            };
            dataCheck = {
              ...dataCheck,
              "ASA Classification": ReplaceN(value),
            };
          } else if (r.field_name === "reviewed" || r.field_name === "Reviewed") {
            commentsCheck = {
              ...commentsCheck,
              Reviewed: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, Reviewed: r.field_value };
          } else if (r.field_name === "informed" || r.field_name === "Informed") {
            commentsCheck = {
              ...commentsCheck,
              Informed: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, Informed: r.field_value };
          } else if (r.field_name === "recovery_entry_time") {
            commentsCheck = {
              ...commentsCheck,
              "Recovery Entry Time": ReplaceN(commentsValue),
            };
            dataCheck = {
              ...dataCheck,
              "Recovery Entry Time": ReplaceN(value),
            };
          } else if (r.field_name === "recovery_bed") {
            commentsCheck = {
              ...commentsCheck,
              "Recovery Bed": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "Recovery Bed": ReplaceN(value) };
          } else if (r.field_name === "accepting_nurse") {
            commentsCheck = {
              ...commentsCheck,
              "Accepting Nurse": ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, "Accepting Nurse": ReplaceN(value) };
          } else if (r.field_name === "notes") {
            commentsCheck = {
              ...commentsCheck,
              Notes: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, Notes: ReplaceN(value) };
          } else if (r.field_name === "abi") {
            commentsCheck = {
              ...commentsCheck,
              ABI: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, ABI: ReplaceN(value) };
          } else if (
            r.field_name === "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions"
          ) {
            commentsCheck = {
              ...commentsCheck,
              "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions":
                ReplaceN(commentsValue),
            };
            if (value.length > 0) {
              const temp_moment = moment(ReplaceN(value));
              dataCheck = {
                ...dataCheck,
                [`${r.field_name}`]: {
                  date: temp_moment.format("MM/DD/YYYY"),
                  time: temp_moment.format("hh:mm A"),
                  momentObj: moment(value),
                },
              };
            } else {
              dataCheck = { ...dataCheck, [`${r.field_name}`]: "" };
            }
          } else if (r.field_name === "anticoagulation") {
            commentsCheck = {
              ...commentsCheck,
              ["Anticoagulation"]: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, ["Anticoagulation"]: ReplaceN(value) };
          } else {
            commentsCheck = {
              ...commentsCheck,
              [`${r.field_name}`]: ReplaceN(commentsValue),
            };
            dataCheck = { ...dataCheck, [`${r.field_name}`]: ReplaceN(value) };
          }
        });
        setComments(commentsCheck);
        setData(dataCheck);

        // if (!data || Object.keys(data).length == 0) {
        // }
      }
    }
  }, [value, checkedAllNormalsP]);

  useEffect(() => {
    if (onChangeCommentCheck) {
      onChangeCommentCheck(comments[label], label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, label]);

  const updateData = () => {
    updateDataProps && updateDataProps(data);
  };

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onClickOption = (value, type) => () => {
    if (!multiple) {
      // uncheck
      if (data[type] == value) {
        data[type] = null;
        clickChangeRow(null, type);
        onClickOptionGlobal(null, label);
        setData({ ...data });
      } else {
        clickChangeRow(value, type);
        onClickOptionGlobal(value, label);
      }
    } else {
      if (Array.isArray(data[type])) {
        for (let index = 0; index < data[type].length; index++) {
          if (data[type][index] && data[type][index]["option"] == value) {
            data[type][index]["checked"] = !data[type][index]["checked"];
          }
        }
        setData({ ...data });
        clickChangeRow(data[type], type, true);
        onClickOptionGlobal(data[type], label, true);
      } else {
        const valueCheck = data[type] ? `${data[type]}` : "";
        const arrayCheck = valueCheck.split(",")?.[0] == "" ? [] : valueCheck.split(",");
        if (value == "None") {
          if (arrayCheck.some((r) => r == value)) {
            data[type] = "";
          } else {
            data[type] = "None";
          }
        } else {
          // filter array khi chọn value khác none
          const arrayNone = arrayCheck.filter((r) => r !== "None");
          if (arrayNone.some((r) => r == value)) {
            const indexArr = arrayNone.findIndex((r) => r == value);
            if (indexArr != -1) {
              arrayNone.splice(indexArr, 1);
              data[type] = arrayNone.join(",");
            }
          } else {
            data[type] = [...arrayNone, value].join(",");
          }
        }

        setData(data);
        clickChangeRow(data[type], type);
        onClickOptionGlobal(data[type], label);
      }
    }
  };

  const onChangeComment = (value, type) => {
    comments[type] = value;
    setComments({ ...comments });
  };

  const checkOptionsValidate = (labelPass) => {
    if (!globalLoading && checkRequireForReview) {
      // pass required
      const arrayDiff = ["INR", "Creatinine", "Right ABI", "Left ABI", "Summary", "Last Anticoagulation", "Recovery Bed", "Discharge With"];
      // check label pass
      if (!arrayDiff.some((r) => r == labelPass) == false) {
        return true;
      } else if (Array.isArray(data[label]) && data[label].length > 0) {
        // check if data is Array object have checked
        if (data[label]?.some((r) => r?.checked)) {
          return true;
        } else {
          return false;
        }
        // check if data have
      } else if (data[label] && data[label] != "Invalid date") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkOptionsValidateGreen = (labelPass) => {
    if (!globalLoading) {
      // pass required
      const arrayDiff = ["INR", "Creatinine", "Right ABI", "Left ABI", "Summary", "Last Anticoagulation", "Recovery Bed", "Discharge With"];
      // check label pass
      if (!arrayDiff.some((r) => r == labelPass) == false) {
        return true;
      } else if (Array.isArray(data[label]) && data[label].length > 0) {
        // check if data is Array object have checked
        if (data[label]?.some((r) => r?.checked)) {
          return true;
        } else {
          return false;
        }
        // check if data have
      } else if (data[label] && data[label] != "Invalid date") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  // Last Anticoagulation (pre-operative)(datepicker)
  // Recovery Entry Time (post-operative)(timepicker)
  // Notes (post-operative)(input.textarea)
  // Discharge Time (discharge)(timepicker)
  // Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions (discharge)(date-time)
  const timePickerLabels = ["Recovery Entry Time", "Discharge Time"];
  const datepickerLabels = ["Last Anticoagulation"];
  const dateTimeLabels = ["Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions"];
  return (
    <div className={`HPIItem ${type === "checkbox" ? "checkbox" : ""} ${type === "textField" ? "textfield" : ""}`}>
      <div className={`HPIItem__label ${multiline ? "multiline" : ""}`}>{label}</div>
      {isIcon && (
        <div className={`HPIItem__icon ${openBox ? "open" : ""}`} onClick={!isLocked && handleIconClick}>
          <Icon type="edit" />
        </div>
      )}
      <div className={`HPIItem__options ${type === "checkbox" ? "checkbox" : ""}`}>
        {!type &&
          (options || []).map((opt, index) => (
            <button
              tabIndex="0"
              key={`${label}-${opt}-${index}`}
              className={`HPIItem__option 
              ${checkOptionsValidate(label) || (checkGreenValue || []).some((r) => r == opt) ? "" : "required"}
              ${isLocked == 0 && "not-locked"}
              ${data[label] === opt ? "active" : ""}
              ${!multiple && data && data[label] && data[label].length > 0 && ` ${data[label]} `.includes(` ${opt} `) ? "active" : ""}
              ${
                multiple && data && data[label] && !Array.isArray(data[label]) && data[label].length > 0 && `${data[label]},`.includes(`${opt},`)
                  ? "active"
                  : ""
              }
              ${multiple && data && data[label] && Array.isArray(data[label]) && data?.[label]?.[index]?.["checked"] == true ? "active" : ""}
              `}
              style={
                (checkGreenValue || []).some((r) => r == opt) && !checkOptionsValidateGreen(label) && multiple && !globalLoading
                  ? { border: "1px solid #3CB043", backgroundColor: "#3CB04310", minWidth: minWidth }
                  : { minWidth: minWidth }
              }
              onClick={onClickOption(opt, label)}
              disabled={isLocked}
            >
              {opt}
            </button>
          ))}
        {type === "Date" && (
          <DatePicker
            format={"MM/DD/YYYY"}
            value={
              Object.keys(data).some((r) => r === label) &&
              data &&
              data[label] &&
              data[label].length > 0 &&
              moment(moment(`${data[label]}`, "MM/DD/YYYY").format("MM/DD/YYYY"), "MM/DD/YYYY", true).isValid()
                ? moment(moment(`${data[label]}`, "MM/DD/YYYY").format("MM/DD/YYYY"), "MM/DD/YYYY")
                : undefined
            }
            placeholder={label}
            onChange={(value) => {
              if (value === "Invalid date") {
              } else {
                onChangeDatePickerGlobal(value, label);
                onChangeDatePicker && onChangeDatePicker(value, label);
              }
            }}
            className={`${datepickerLabels.includes(label) ? "obl-ant-datepicker" : ""} ${
              checkOptionsValidate(label) ? "" : "required-calendar-picker-input"
            }`}
            style={{
              ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
            }}
            suffixIcon={<ExpandSVG />}
            disabled={isLocked}
          />
        )}
        {type === "TimePicker" && (
          <>
            <TimePicker
              style={{
                ...{
                  borderBottom: "1px solid #8d8d8d",
                },
                ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
              }}
              format={"h:mm A"}
              use12Hours
              value={
                Object.keys(data).some((r) => r === label) && data && data[label] && data[label].length > 0
                  ? moment(moment(`${data[label]}`, "h:mm A").format("h:mm A"), "h:mm A")
                  : undefined
              }
              placeholder={label}
              suffixIcon={<ExpandSVG />}
              className={`${timePickerLabels.includes(label) ? "obl-ant-datepicker" : ""} ${
                checkOptionsValidate(label) ? "" : "required-calendar-picker-input"
              }`}
              onChange={(value) => {
                if (value === "Invalid date") {
                  onChangeTimePickerGlobal(null, label);
                  onChangeTimePicker(null, label);
                } else {
                  onChangeTimePickerGlobal(value, label);
                  onChangeTimePicker(value, label);
                }
              }}
              disabled={isLocked}
            />
            <button
              className={"common-btn"}
              disabled={isLocked}
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                if (!isLocked) {
                  onChangeTimePickerGlobal(moment(), label);
                  onChangeTimePicker(moment(), label);
                }
              }}
            >
              Now
            </button>
          </>
        )}
        {type === "date-time" && (
          <div className={dateTimeLabels.includes(label) ? "obl-ant-datetime" : ""}>
            <DatePicker
              style={{
                ...{
                  width: "40%",
                },
                ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
              }}
              format={"MM/DD/YYYY"}
              value={
                Object.keys(data).some((r) => r === label) && data && data[label]
                  ? moment(moment(`${data[label]["date"]}`, "MM/DD/YYYY").format("MM/DD/YYYY"), "MM/DD/YYYY")
                  : undefined
              }
              className={`${checkOptionsValidate(label) ? "" : "required-calendar-picker-input"}`}
              placeholder={"Date"}
              onChange={(value) => {
                if (value === "Invalid date") {
                } else {
                  onChangeDatePickerGlobal(value, label);
                  onChangeDatePicker && onChangeDatePicker(value, label);
                }
              }}
              suffixIcon={<ExpandSVG />}
              disabled={isLocked}
            />
            <TimePicker
              format={"h:mm A"}
              style={{
                ...{
                  width: "40%",
                  marginLeft: "0.5rem",
                  borderBottom: "1px solid #8d8d8d",
                },
                ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
              }}
              use12Hours
              value={
                Object.keys(data).some((r) => r === label) && data && data[label]
                  ? moment(moment(`${data[label]["time"]}`, "h:mm A").format("h:mm A"), "h:mm A")
                  : undefined
              }
              placeholder={"Time"}
              suffixIcon={<ExpandSVG />}
              className={`${checkOptionsValidate(label) ? "" : "required-calendar-picker-input"}`}
              onChange={(value) => {
                if (value === "Invalid date") {
                } else {
                  onChangeTimePickerGlobal(value, label);
                  onChangeTimePicker(value, label);
                }
              }}
              disabled={isLocked}
            />
          </div>
        )}
        {type === "time" && (
          <div className={dateTimeLabels.includes(label) ? "obl-ant-datetime" : ""}>
            <TimePicker
              format={"h:mm A"}
              style={{
                ...{
                  width: "60%",
                  marginLeft: "0.5rem",
                  borderBottom: "1px solid #8d8d8d",
                },
                ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
              }}
              use12Hours
              value={
                Object.keys(data).some((r) => r === label) && data && data[label]
                  ? moment(moment(`${data[label]["time"]}`, "h:mm A").format("h:mm A"), "h:mm A")
                  : undefined
              }
              placeholder={"Time"}
              suffixIcon={<ExpandSVG />}
              className={`${checkOptionsValidate(label) ? "" : "required-calendar-picker-input"}`}
              onChange={(value) => {
                if (value === "Invalid date") {
                } else {
                  onChangeTimePickerGlobal(value, label);
                  onChangeTimePicker(value, label);
                }
              }}
              disabled={isLocked}
            />
          </div>
        )}
        {type === "dropdown" && (
          <Select
            placeholder={label}
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            style={{
              ...{ maxHeight: "2.5rem", height: "2.5rem", width: "60%" },
              ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
            }}
            value={Object.keys(data).some((r) => r === label) && data && data[label] && data[label].length > 0 ? data[label] : undefined}
            onChange={(value) => {
              onChangeSelectGlobal(value, label);
              onChangeSelect(value, label);
            }}
            disabled={isLocked}
          >
            {(options || []).map((opt, index) => (
              <Option key={`${label}-${opt}-${index}`} value={opt}>
                {opt}
              </Option>
            ))}
          </Select>
        )}
        {type === "checkbox" &&
          (options || []).map((opt, index) => (
            <Checkbox
              onChange={(value) => {
                onCheckBoxGlobal(value.target.checked, opt.substring(0, 8));
                onCheckBox(value.target.checked, opt.substring(0, 8));
              }}
              checked={Number(`${data[opt.substring(0, 8)]}`)}
              key={`${label}-${opt}-${index}`}
              style={{ width: "100%" }}
              disabled={isLocked}
            >
              {opt}
            </Checkbox>
          ))}
        {type === "text" && (
          <div style={{ display: "flex", gap: 20 }}>
            <Input
              style={{
                ...(maxWidth ? { maxWidth, borderBottom: "1px solid rgb(141, 141, 141)" } : { borderBottom: "1px solid rgb(141, 141, 141)" }),
                ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
              }}
              value={Object.keys(data).some((r) => r === label) && data && data[label] && data[label].length > 0 ? data[label] : undefined}
              onChange={(event) => {
                if (format && format === "x.x") {
                  const value = event.target?.value.replace(/[^0-9.]/g, "");
                  if (value.length <= 3 && value.length > 1) {
                    if (!value.includes(".")) {
                      const checkPush = value.split("").map((r, index) => {
                        if (index === 1) {
                          return `.${r}`;
                        }
                        return r;
                      });
                      const valueJoin = checkPush.join("");
                      onChangeInputGlobal(valueJoin, label);
                      onChangeInput(valueJoin, label);
                    } else if (value.includes(".")) {
                      onChangeInputGlobal(value, label);
                      onChangeInput(value, label);
                    }
                  } else if (value.length <= 1) {
                    onChangeInputGlobal(value, label);
                    onChangeInput(value, label);
                  }
                } else {
                  onChangeInputGlobal(event.target?.value, label);
                  onChangeInput(event.target?.value, label);
                }
              }}
              disabled={isLocked}
            />
            {/* TODO MIKE */}
            {label == "Right ABI" || label == "Left ABI" ? (
              <Input
                value={label == "Right ABI" ? right_abi_text : left_abi_text}
                disabled={true}
                style={{
                  ...(maxWidth ? { maxWidth, borderBottom: "1px solid rgb(141, 141, 141)" } : { borderBottom: "1px solid rgb(141, 141, 141)" }),
                  ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
                }}
              />
            ) : null}
          </div>
        )}
        {type === "textField" && (
          <Input.TextArea
            style={{
              ...{
                borderRadius: 0,
                backgroundColor: "#f4f7fb",
                maxWidth: maxWidth ? maxWidth : "100%",
              },
              ...(checkOptionsValidate(label) ? {} : { border: "1px solid red", backgroundColor: "#ff000010" }),
            }}
            value={Object.keys(data).some((r) => r === label) && data && data[label] && data[label].length > 0 ? data[label] : undefined}
            placeholder={label}
            autoSize={{ minRows: 3 }}
            className={label === "Notes" ? "notes-ant-input" : ""}
            onChange={(e) => {
              onChangeInputGlobal(e.target?.value, label);
              onChangeInput(e.target?.value, label);
            }}
            disabled={isLocked}
          />
        )}
      </div>
      <div
        className="HPIItem__box"
        onBlur={() => setBox(false)}
        style={{
          display: !disableSelfbox && openBox ? "inline-block" : "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "5px",
            zIndex: 10,
            width: "15px",
            height: "15px",
            cursor: "pointer",
          }}
          onClick={() => setBox(false)}
        >
          <CloseIconSVG />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <Input.TextArea rows={4} value={comments[label] ? comments[label] : ""} onChange={(e) => onChangeComment(e.target?.value, label)} />
        </div>
      </div>
    </div>
  );
};

const getExtra = ({ removeItem, row }) => (
  <div
    style={{
      display: "inline-flex",
      marginRight: "0.5rem",
      cursor: "pointer",
    }}
    onClick={() => removeItem(row)}
  >
    <Icon type="delete" />
  </div>
);

const AutoAccordionComponent = ({ rows, title, handleSearch, addItem, removeItem, enable }) => {
  const [selectedPanel, setSelected] = useState(-1);
  const [boxIndex, setEditBox] = useState(-1);
  const data = rows ? rows : [];

  const handleEditBox = (idx) => () => {
    setEditBox(idx);
  };

  const onChangePanel = (keyValue) => {
    setEditBox(-1);
    if (!keyValue) {
      setSelected(-1);
    } else {
      if (keyValue.includes("-")) {
        const panelIndex = keyValue.split("-").pop();
        setSelected(parseInt(panelIndex));
      }
    }
  };

  return (
    <div className="atuoAccordion">
      <div className="atuoAccordion__filter">
        <div className="atuoAccordion__filter-search">
          <Search
            placeholder="Search Diagnosis"
            onChange={(e) => {
              handleSearch(e.target?.value);
            }}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
        <div className="atuoAccordion__filter-action">
          <button className="atuoAccordion__filter-add" onClick={addItem}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>

      <div className="atuoAccordion__result">
        <ScrollWrapper css="no-padding">
          <div className="atuoAccordion__result-list">
            <Collapse accordion onChange={onChangePanel}>
              {(data || []).map((row, index) => (
                <Panel
                  className="atuoAccordion__result-item"
                  key={`collapse-${title}-${index}`}
                  header={row?.title}
                  extra={getExtra({ removeItem, row })}
                >
                  <div className="content">
                    <div className="hpiList">
                      {(row?.conditions || []).map((condition, idx) => (
                        <HPIItem
                          key={`panel-${row?.title}-${idx}`}
                          label={condition.label}
                          isIcon={condition.isIcon}
                          options={condition.options}
                          handleEdit={handleEditBox(idx)}
                        />
                      ))}
                    </div>
                    {enable && selectedPanel === index && boxIndex !== -1 && (
                      <div className="content__editbox" contentEditable={true} onBlur={() => setEditBox(-1)} />
                    )}
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default AutoAccordionComponent;

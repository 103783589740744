import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { YellowAlertSVG } from "../../../../common/components/icons/SVGIcon";
import { getPCMDetail, getPCMFilter, setGlobalLoading } from "../../../../store/actions";

const NonEndovascularTable = ({ setCounts, onRowClick }) => {
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [userProvider, setUserProvider] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const dispatch = useDispatch();
  const pcm = useSelector((state) => state.common.pcm);
  const pcmFilter = useSelector((state) => state.common.pcmFilter);
  const pcmDetail = useSelector((state) => state.common.pcmDetail);

  const [sortOption, setSortOption] = useState({});

  useEffect(() => {
    setUserProvider(currentUserInfo?.id);
    fetchList();
  }, [currentUserInfo]);

  const sortList = async () => {
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }

    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        getPCMFilter({
          page: 1,
          type: "nonendo",
          column: sortColumn,
          user_id: currentUserInfo?.id,
        })
      );
    } else {
      await dispatch(
        getPCMFilter({
          user_id: currentUserInfo?.id,
          startDate,
          page: 1,
          type: "nonendo",
          search: search,
          column: sortColumn,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleRow = async (row, index) => {
    if (activeIndex !== index) {
      setRow(index);
      setLoading(true);
      const detailSuccess = await dispatch(getPCMDetail(row?.id));
      if (detailSuccess) {
        setLoading(false);
      }
    }
    onRowClick();
  };

  const fetchList = async () => {
    setUserProvider(currentUserInfo?.id);
    if (!currentUserInfo?.id) return;
    const listProvider = await dispatch(getPCMFilter({ type: "nonendo", user_id: currentUserInfo?.id }));
    if (listProvider) {
      setLoading(false);
    }

    setCounts({
      nonendovascularCheck: listProvider?.count_green || 0,
      nonendovascularWarning: listProvider?.count_red || 0,
    });
  };

  useEffect(() => {
    if (pcmDetail && Object.keys(pcmDetail).length > 0) {
      const indexEdit = tableLoad.findIndex((r) => r?.id === pcmDetail?.id);
      const listItems = [...tableLoad];
      listItems.splice(indexEdit, 1, { ...pcmDetail });
      setTableLoads([...listItems]);
    }
  }, [pcmDetail]);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad && !forFilter) {
        setLoading(true);

        const listPCM = await dispatch(
          getPCMFilter({
            type: "nonendo",
            user_id: currentUserInfo?.id,
            page: page + 1,
            column: sortColumn,
          })
        );
        setPage(page + 1);
        if (listPCM) {
          if (pcm && pcm.nonendovasculars && pcm.nonendovasculars.length > 0) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else if (offLoad && forFilter) {
        setLoading(true);
        const listPCMFilter = await dispatch(
          getPCMFilter({
            user_id: currentUserInfo?.id,
            type: "nonendo",
            startDate,
            page: page + 1,
            search: search,
            column: sortColumn,
          })
        );
        if (listPCMFilter) {
          if (pcmFilter && pcmFilter.nonendovasculars && pcmFilter.nonendovasculars.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (pcm && pcm.nonendovasculars && pcm.nonendovasculars.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...pcm.nonendovasculars]);
        setLoading(false);
      } else {
        setTableLoads([...pcm.nonendovasculars]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [pcm]);

  useEffect(() => {
    if (pcmFilter && pcmFilter.nonendovasculars) {
      if (tableLoad && tableLoad.length === 0) {
      }
      setTableLoads([...tableLoad, ...pcmFilter.nonendovasculars]);
    }
  }, [pcmFilter]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  return (
    <div className="fullContainer" style={{ paddingTop: "unset" }}>
      <DataTable
        title="pcm_endovascular"
        labels={["Patient Name", "DOB", "DOS", "CM"]}
        widths={["40%", "20%", "20%", "20%"]}
        columns={[
          { sortable: true, key: "patient_name" },
          { sortable: false, key: "patient_dob" },
          { sortable: true, key: "dos" },
          { sortable: true, key: "contribution_margin" },
        ]}
        sorts={[
          sortOption.patient_name,
          "",
          sortOption.dos,
          "",
          sortOption.fee,
          sortOption.allowed_amount,
          "",
          sortOption.insurance_balance,
          sortOption.patient_balance,
          sortOption.net,
          sortOption.direct_cost,
          sortOption.contribution_margin,
        ]}
        rowData={(tableLoad || []).map((row, index) => ({
          id: row?.id,
          patient_name: (
            <div
              style={{
                display: "flex",
                paddingLeft: "0.25rem",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {row?.icon_status === "green" && (
                <div className="has-tooltip">
                  {" "}
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                  <span className="tooltip tooltip-without-border" role="tooltip">
                    {"0 Net balance"}
                  </span>
                </div>
              )}
              {row?.icon_status === "yellow" && <YellowAlertSVG />}
              {row?.icon_status === "red" && (
                <div className="has-tooltip">
                  {" "}
                  <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                  <span className="tooltip tooltip-without-border" role="tooltip">
                    {"Net balance > 0"}
                  </span>
                </div>
              )}
              {row?.icon_status === "red_x" && (
                <div className="has-tooltip">
                  {" "}
                  <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                  <span className="tooltip tooltip-without-border" role="tooltip">
                    {"<70% and < 365 days"}
                  </span>
                </div>
              )}
              {!row?.icon_status && <span style={{ minWidth: "26px" }}></span>}
              <p style={{ paddingLeft: "0.75rem" }}>{row?.patient_name}</p>
            </div>
          ),
          patient_dob: row?.patient_dob && moment(row?.patient_dob).format("MM/DD/YYYY"),
          dos: row?.date_of_procedure && moment(row?.date_of_procedure).format("MM/DD/YYYY"),
          proceduralist: row?.provider_name,
          fee: row?.fee,
          allowed_amount: row?.allowed_amount,
          total_payment: row?.payment,
          net: row?.net,
          ros: row?.location,
          direct_cost: row?.direct_cost,
          contribution_margin: row?.contribution_amount,
          insurance_balance: row?.insurance_balance,
          patient_balance: row?.patient_balance,
          insurance_amount: row?.insurance_amount,
        }))}
        handleSort={(value, item) => {
          handleSortOption(value, item);
        }}
        fetchList={fetchList}
        loadMore={loadMore}
        handleClickRow={handleRow}
        defaultActive={-1}
        disableDelete={true}
      />
    </div>
  );
};

export default NonEndovascularTable;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../../common/components/DataTable/DataTable";
import { getLocal } from "../../../../helpers/Local";
import { getEvaluationsTagert, getEvaluationsUser } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import EvaluationsSidebar from "./EvaluationsSidebar";

const Evaluations = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const evaluationsTagert = useSelector((state) => state.common.evaluationsTagert);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);

  const authId = getLocal("cvai-current-user");

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const tableRows = evaluationsTagert ? evaluationsTagert : [];

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (_, index) => {
    setRow(index);
    setIsSidebar(true);
  };

  const fetchList = () => {
    dispatch(getEvaluationsUser(authId));
    dispatch(getEvaluationsTagert(authId));
  };

  const fetchData = () => {
    fetchList();
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_evaluations"
                tableWidth={920}
                labels={["Evaluation Date", "Type", "Reason", "Points", "Results"]}
                widths={["24%", "18%", "22%", "18%", "18%"]}
                columns={[
                  { sortable: false, key: "created_at" },
                  { sortable: false, key: "type" },
                  { sortable: false, key: "reason" },
                  { sortable: false, key: "points" },
                  { sortable: false, key: "corective_actions" },
                ]}
                sorts={["", "", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  created_at: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      <p>{row?.created_at}</p>
                    </div>
                  ),
                  type: "Variance",
                  reason: row?.variance_type,
                  points: row?.points,
                  corective_actions: row?.corective_actions,
                }))}
                fetchList={fetchData}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && evaluationsTagert.length > 0 && (
        <EvaluationsSidebar
          ref={sidebarRef}
          currentUserInfo={currentUserInfo}
          evaluationsTagertDetail={evaluationsTagert[activeIndex]}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Evaluations;

import { Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchSVG } from '../../../common/components/icons/SVGIcon';
import { setGlobalLoading } from '../../../store/actions';

const {Search} = Input;

const CallbackFilters = ({handleSearch}) => {
  const loadingCallback = useSelector(
      (state) => state.procedure.loadingCallback,
  );
  const loadingPending = useSelector((state) => state.procedure.loadingPending);
  const loadingEvents = useSelector((state) => state.procedure.loadingEvents);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
        setGlobalLoading(loadingCallback || loadingPending || loadingEvents),
    );
  }, [loadingCallback, loadingPending, loadingEvents]);

  const changeSearch = (value) => {
    handleSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{width: '100%'}}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default CallbackFilters;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Icon, Input, Select } from "antd";
import ClinicWorkflowSidebar from "../../../../common/views/SidebarUltrasoundFinding";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import ClinicalIndication from "./ClinicalIndication";
import Questionnaire from "./Questionnaire";
import Addendum from "./Addendum";
import NotesUltrasound from "../../../../common/components/NotesUltrasound";
import DataTable from "../../../../common/components/DataTable/DataTable";
import {
  get_ultrasound_finding_value,
  get_ultrasound_rule_result,
  setDetailUltrasoundProps,
  setGlobalLoading,
  getUltrasounds,
  get_patient_notes,
  save_ultrasound_rule_result,
} from "../../../../store/actions";
import { getLocal } from "../../../../helpers/Local";

const initialConditions = [
  { step: "clinical", status: false },
  { step: "addendum", status: false },
  { step: "notes", status: false },
];

const initialConditionsWithQuestionaire = [
  { step: "clinical", status: false },
  { step: "addendum", status: false },
  { step: "questionnaire", status: false },
  { step: "notes", status: false },
];

const formSteps = [
  {
    title: "Clinical Indication",
    content: ClinicalIndication,
  },
  {
    title: "Addendum",
    content: Addendum,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: NotesUltrasound,
  },
];

const formStepsWithQuestionaire = [
  {
    title: "Clinical Indication",
    content: ClinicalIndication,
  },
  {
    title: "Questionnaire",
    content: Questionnaire,
  },
  {
    title: "Addendum",
    content: Addendum,
  },
  {
    title: "Notes",
    content: NotesUltrasound,
  },
];

const UltrasoundFindings = ({ patientResource, saveChange }) => {
  const dataRef = useRef({});
  const dispatch = useDispatch();

  const [isSidebar, setIsSidebar] = useState(false);
  const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
  const current_ultrasound_id = getLocal("current_ultrasound_id");
  const [localLoading, setLocalLoading] = useState(false);
  const conditions = useSelector((state) => state.common.referralConditions);
  const errorUltrasound = useSelector((state) => state.common.errorUltrasound);
  const resource = useSelector((state) => state.newPatient.resource);
  const ultrasound_finding = useSelector((state) => state.procedureDetail.ultrasound_type);
  const ultrasound_value = useSelector((state) => state.common.ultrasound_value);
  const detail_ultrasound_result = useSelector((state) => state.common.detail_ultrasound_result);
  const detailUltrasounds = useSelector((state) => state.common.detailUltrasounds);
  const [ultrasounds_store, set_ultrasounds_store] = useState([]);
  const [checkedState, setCheckedState] = useState([false, false, false, false, false, false, false]);
  const [dataAddendum, setDataAddendum] = useState(null);
  const [activeIndex, setRow] = useState(-1);
  const activeRequireDiameter = useSelector((state) => state.common.activeRequireDiameter);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const dataDiameterCM = ["< 1 cm", "1 to 2 cm", "2 to 3 cm", "3 to 4 cm", "4 to 5 cm", "5 to 6 cm", "> 6 cm"];
  const dataDiameterM = ["< 1 mm", "1 to 2 mm", "2 to 3 mm", "3 to 4 mm", "4 to 5 mm", "5 to 6 mm", "> 6 mm"];
  const dataDiameterNumber = [
    "0.1",
    "0.2",
    "0.3",
    "0.4",
    "0.5",
    "0.6",
    "0.7",
    "0.8",
    "0.9",
    "1.0",
    "1.5",
    "2.0",
    "2.5",
    "3.0",
    "3.5",
    "4.0",
    "4.5",
    "5.0",
    "5.5",
    "6.0",
  ];
  const [dataDiameter, setDataDiameter] = useState(dataDiameterCM);

  const checkNameDetail = (value, type) => {
    return value?.name && value?.name.includes(type);
  };

  useEffect(() => {
    switch (true) {
      case checkNameDetail(detailUltrasounds, "Hemodialysis"):
        setDataDiameter(dataDiameterM);
        break;
      case checkNameDetail(detailUltrasounds, "Aorta"):
        setDataDiameter(dataDiameterCM);
        break;
      case checkNameDetail(detailUltrasounds, "Vessel Mapping"):
        setDataDiameter(dataDiameterM);
        break;
      case checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping"):
        setDataDiameter(dataDiameterNumber);
        break;
      case checkNameDetail(detailUltrasounds, "Retroperitoneal"):
        setDataDiameter(dataDiameterCM);
        break;
      case checkNameDetail(detailUltrasounds, "Extremity Veins"):
        setDataDiameter(dataDiameterNumber);
        break;
      default:
        break;
    }
  }, [detailUltrasounds]);

  useEffect(() => {
    if (detail_ultrasound_result) {
      dispatch(
        setDetailUltrasoundProps("detail_ultrasound_props", {
          conclusion_l: detail_ultrasound_result?.left_conclusion,
          conclusion_r: detail_ultrasound_result?.right_conclusion,
          result_l: detail_ultrasound_result?.left_result,
          result_r: detail_ultrasound_result?.right_result,
          right_addendum: detail_ultrasound_result?.right_addendum,
          left_addendum: detail_ultrasound_result?.left_addendum,
          disease_id:
            (detail_ultrasound_result?.icd_codes && detail_ultrasound_result?.icd_codes[0] && detail_ultrasound_result?.icd_codes[0]["id"]) || "",
          cpt_code_id:
            (detail_ultrasound_result?.cpt_codes && detail_ultrasound_result?.cpt_codes[0] && detail_ultrasound_result?.cpt_codes[0]["id"]) || "",
        })
      );
    } else {
      dispatch(setDetailUltrasoundProps("detail_ultrasound_props", {}));
    }
  }, [detail_ultrasound_result]);

  useEffect(() => {
    dispatch(setGlobalLoading(localLoading));
  }, [localLoading]);

  const checkRequireData = (data) => {
    const dataCheckRequire = (data || []).map((r) => {
      if ((r?.Phasic || r?.Augmented || r?.Compressability) && !r?.Reflux && !r?.["Diameter (cm)"]) {
        return {
          ...r,
          isError: true,
        };
      }
      return {
        ...r,
        isError: false,
      };
    });
    return dataCheckRequire;
  };

  useEffect(() => {
    if (
      ultrasound_finding &&
      ultrasound_value &&
      Object.keys(ultrasound_value).length > 0 &&
      detailUltrasounds &&
      Object.keys(detailUltrasounds).length > 0 &&
      appointment_ultrasound_id
    ) {
      const check = (ultrasound_finding || []).map((r) => {
        return {
          finding_name:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`]["finding_name"]
              ? ultrasound_value[`${r?.id}`]["finding_name"]
              : "",
          finding_id:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`]["finding_id"]
              ? ultrasound_value[`${r?.id}`]["finding_id"]
              : "",
          ultrasound_type_id:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`]["ultrasound_type_id"]
              ? ultrasound_value[`${r?.id}`]["ultrasound_type_id"]
              : "",
          appointment_id: appointment_ultrasound_id,
          [`${detailUltrasounds?.headers[0]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[0]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[0]}`]
              : "",
          [`${detailUltrasounds?.headers[1]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[1]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[1]}`]
              : "",
          [`${detailUltrasounds?.headers[2]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[2]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[2]}`]
              : "",
          [`${detailUltrasounds?.headers[3]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[3]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[3]}`]
              : "",
          [`${detailUltrasounds?.headers[4]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[4]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[4]}`]
              : "",
          [`${detailUltrasounds?.headers[5]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[5]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[5]}`]
              : "",
          [`${detailUltrasounds?.headers[6]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[6]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[6]}`]
              : "",
          [`${detailUltrasounds?.headers[7]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[7]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[7]}`]
              : "",
        };
      });
      // nếu activeRequireDiameter bằng true (khi click finalize ở review) thì check điều kiện nếu 3 ô trước có giá trị nhưng reflux và diameter không có giá trị thì báo đỏ
      let valueData = [...check];
      if (activeRequireDiameter) {
        valueData = checkRequireData([...check]);
      }
      set_ultrasounds_store([...valueData]);
    }
  }, [ultrasound_value, ultrasound_finding, detailUltrasounds, appointment_ultrasound_id, activeRequireDiameter]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = async () => {
    if (typeof dataAddendum === "object" && dataAddendum !== null) {
      if (dataAddendum && Object.keys(dataAddendum).length > 0) {
        dispatch(setGlobalLoading(true));
        await dispatch(save_ultrasound_rule_result(appointment_ultrasound_id, dataAddendum));
        dispatch(setGlobalLoading(false));
        setDataAddendum(null);
      }
    }
    setIsSidebar(false);
  };

  const handleRow = async (index, id) => {
    setRow(index);
    dispatch(setGlobalLoading(true));
    dispatch(get_ultrasound_rule_result(appointment_ultrasound_id));
    await dispatch(get_patient_notes(resource?.id, current_ultrasound_id));
    dispatch(setGlobalLoading(false));
    handleSidebar();
  };

  const callSaveAPI = async () => {
    if (
      ultrasound_finding &&
      typeof ultrasound_finding === "object" &&
      typeof ultrasound_value === "object" &&
      ultrasound_value &&
      Object.keys(ultrasound_value).length > 0 &&
      detailUltrasounds &&
      Object.keys(detailUltrasounds).length > 0
    ) {
      setLocalLoading(true);
      const data = dataRef.current;
      if (data && typeof data === "object" && data !== null && Object.keys(data).length > 0) {
        saveChange && saveChange({ values: data });
      }
      setLocalLoading(false);
    }
  };

  const setOnChange = async (type, value, index) => {
    if (current_ultrasound_id == 5) {
      //special rule for Lower Extremity Arteries
      if (index != 1 && index != 11) {
        if (type == "Stenosis") {
          // truy vấn tồi tệ nhất trong list
          let checkWave = null;

          // data ultrasounds_store sẽ dc làm mới tại điểm click nhầm mục đích check khi xảy ra việc click đầu tiên là Abnormal (Biphasic) và sửa lại Abnormal (Monophasic) sẽ bị bug
          let ultrasounds_store_new = ultrasounds_store.map((r, indexCheck) => {
            if (indexCheck == index) {
              return {
                ...r,
                Wave: "",
              };
            }
            return {
              ...r,
            };
          });

          // Profunda Femoris sẽ được loại bỏ
          if (
            ultrasounds_store_new.some((r) => r?.Wave == "Abnormal (Monophasic)" && r?.finding_name && !r?.finding_name.includes("Profunda Femoris"))
          ) {
            checkWave = "Abnormal (Monophasic)";
          } else if (
            ultrasounds_store_new.some((r) => r?.Wave == "Abnormal (Biphasic)" && r?.finding_name && !r?.finding_name.includes("Profunda Femoris")) &&
            !checkWave
          ) {
            checkWave = "Abnormal (Biphasic)";
          } else if (ultrasounds_store_new.some((r) => r?.Wave == "Normal (Triphasic)") && !checkWave) {
            checkWave = "Normal (Triphasic)";
          }

          // chọn minal
          if (value == "Minimal") {
            ultrasounds_store[index]["Wave"] = checkWave || "Normal (Triphasic)";

            ultrasounds_store[index][type] = value || "";
          } else if (value == "Moderate (> 50%)") {
            // chọn "Moderate (> 50%)"
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = checkWave && checkWave !== "Normal (Triphasic)" ? checkWave : "Abnormal (Biphasic)";
          } else if (value == "Severe (> 75%)") {
            // chọn "Severe (> 75%)"
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = "Abnormal (Monophasic)";
          } else if (value == "Occluded") {
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = "Not Identified";
          } else {
            ultrasounds_store[index][type] = value || "";
          }
        } else {
          ultrasounds_store[index][type] = value || "";
        }
      } else {
        if (type == "Stenosis") {
          if (value == "Minimal") {
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = "Normal (Triphasic)";
          } else if (value == "Moderate (> 50%)") {
            // chọn "Moderate (> 50%)"
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = "Abnormal (Biphasic)";
          } else if (value == "Severe (> 75%)") {
            // chọn "Severe (> 75%)"
            ultrasounds_store[index][type] = value || "";
            ultrasounds_store[index]["Wave"] = "Abnormal (Monophasic)";
          } else {
            ultrasounds_store[index][type] = value || "";
          }
        } else {
          ultrasounds_store[index][type] = value || "";
        }
      }

      // nếu activeRequireDiameter bằng true (khi click finalize ở review) thì check điều kiện nếu 3 ô trước có giá trị nhưng reflux và diameter không có giá trị thì báo đỏ
      if (activeRequireDiameter) {
        set_ultrasounds_store([...checkRequireData(ultrasounds_store)]);
      } else {
        set_ultrasounds_store([...ultrasounds_store]);
      }
      //
    } else {
      ultrasounds_store[index][type] = value || "";
      set_ultrasounds_store([...ultrasounds_store]);
    }

    let check = {};
    ultrasounds_store.map((row) => {
      delete check?.isError;
      delete check?.undefined;
      check = {
        ...check,
        [`${row?.finding_id}`]: {
          finding_name: row?.finding_name,
          finding_id: row?.finding_id,
          appointment_id: row?.appointment_id,
          [`${detailUltrasounds?.headers[0]}`]: row[`${detailUltrasounds?.headers[0]}`],
          [`${detailUltrasounds?.headers[1]}`]: row[`${detailUltrasounds?.headers[1]}`],
          [`${detailUltrasounds?.headers[2]}`]: row[`${detailUltrasounds?.headers[2]}`],
          [`${detailUltrasounds?.headers[3]}`]: row[`${detailUltrasounds?.headers[3]}`],
          [`${detailUltrasounds?.headers[4]}`]: row[`${detailUltrasounds?.headers[4]}`],
          [`${detailUltrasounds?.headers[5]}`]: row[`${detailUltrasounds?.headers[5]}`],
          [`${detailUltrasounds?.headers[6]}`]: row[`${detailUltrasounds?.headers[6]}`],
          [`${detailUltrasounds?.headers[7]}`]: row[`${detailUltrasounds?.headers[7]}`],
        },
      };
    });
    dataRef.current = check;
    callSaveAPI();
  };

  const onChangeAddendum = (value) => {
    setDataAddendum(value);
  };

  const checkUltra = (header, row) => {
    // true is select , false is input
    if (row?.ultrasound_type_id == "2") {
      if (
        (header == "Stenosis" && row?.finding_name !== "Left Vertebral Flow" && row?.finding_name !== "Right Vertebral Flow") ||
        (header == "Plaque" && row?.finding_name !== "Left Vertebral Flow" && row?.finding_name !== "Right Vertebral Flow") ||
        (header == "Wave" && row?.finding_name !== "Left Vertebral Flow" && row?.finding_name !== "Right Vertebral Flow") ||
        (header == "Direction of Flow" &&
          (row?.finding_name == "Left Vert Flow" ||
            row?.finding_name == "Right Vert Flow" ||
            row?.finding_name == "Right Vertebral Flow" ||
            row?.finding_name == "Left Vertebral Flow"))
      ) {
        return true;
      } else {
        if (header == "Diameter (cm)" && !row?.finding_name.includes("Proximal Compression")) {
          return true;
        }
        if (header == "Phasic" && row?.finding_name.includes("Proximal Compression")) {
          return true;
        }
        return false;
      }
      // for other ultrasounds
    } else {
      if (
        (header === "Stenosis" ||
          header === "Plaque" ||
          header === "Intervention" ||
          header === "Wave" ||
          header === "Diameter" ||
          header === "Phasic" ||
          header === "Augmented" ||
          header === "Compressability" ||
          header === "Thrombus" ||
          header === "Reflux") &&
        !row?.finding_name.includes("Vertebral Flow") &&
        !row?.finding_name.includes("Proximal Compression") &&
        !row?.finding_name.includes("Kidney Dimension")
      ) {
        return true;
      } else if (header === "Direction of Flow" && row?.finding_name.includes("Vertebral Flow")) {
        return true;
      } else if (row?.finding_name.includes("Vertebral Flow") && header !== "Direction of Flow") {
        return false;
      } else if (row?.finding_name.includes("Kidney Dimension")) {
        return false;
      } else {
        if (header == "Diameter (cm)" && !row?.finding_name.includes("Proximal Compression")) {
          return true;
        }
        if (header == "Phasic" && row?.finding_name.includes("Proximal Compression")) {
          return true;
        }
        return false;
      }
    }
  };

  const checkUltraInput = (header, row) => {
    // true is show , false is not show
    if (row?.ultrasound_type_id == "2") {
      if (
        header == "PSV/EDV" &&
        row?.finding_name !== "Left Vertebral Flow" &&
        row?.finding_name !== "Right Vertebral Flow" &&
        row?.finding_name !== "Proximal Compression"
      ) {
        return true;
      }
      return false;
    } else {
      if (row?.finding_name.includes("Vertebral Flow")) {
        return false;
      } else {
        if (header === "Direction of Flow") {
          return false;
        } else if (row?.finding_name.includes("Kidney Dimension") && header !== "AP Diameter" && header !== "Transverse Diameter") {
          return false;
        } else if (row?.finding_name.includes("Proximal Compression")) {
          return false;
        }
        return true;
      }
    }
  };

  useEffect(() => {
    dispatch(get_ultrasound_finding_value(appointment_ultrasound_id));
  }, []);

  useEffect(() => {
    dispatch(getUltrasounds());
  }, []);

  const fixName = (value) => {
    if (value) {
      return value.replaceAll(" ", "_");
    }
    return value;
  };

  const sidebarRef = useRef();

  const checkStenosisAndPreviousFinding = (row, index) => {
    return ["None", "Minimal", "Moderate (> 50%)", "Severe (> 75%)", "Occluded", "Not Identified"];
  };

  const checkWaveAndPreviousFinding = (row, index) => {
    return ["Normal (Triphasic)", "Abnormal (Biphasic)", "Abnormal (Monophasic)", "Not Identified"];
  };

  const hoverActiveCheck = [
    {
      label: "Phasic",
      value: "Yes",
    },
    {
      label: "Augmented",
      value: "Yes",
    },
    {
      label: "Compressability",
      value: "Compressible",
    },
    {
      label: "Thrombus",
      value: "No",
    },
    {
      label: "Reflux",
      value: "< 0.5 seconds",
    },
    {
      label: "Intervention",
      value: "None",
    },
  ];

  const handleChecked = (value, index, label) => {
    const activeHover = hoverActiveCheck.find((r) => r?.label == label);
    if (value === true && activeHover) {
      const mergeData = ultrasounds_store.map((r) => {
        return {
          ...r,
          [label]: activeHover?.value,
        };
      });
      set_ultrasounds_store([...mergeData]);
      saveChange && saveChange({ values: [...mergeData] });
    } else {
      const mergeData = ultrasounds_store.map((r) => {
        return {
          ...r,
          [label]: "",
        };
      });
      set_ultrasounds_store([...mergeData]);
      saveChange && saveChange({ values: [...mergeData] });
    }
    checkedState[index] = value;
    setCheckedState([...checkedState]);
  };

  const checkErrorInput = (value) => {
    if (errorUltrasound) {
      if (!value || value.length == 0) {
        return `error_ultrasound_finding_input`;
      }
    }
    return `ultrasound_finding_input`;
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className={`fullContainer pt-2 ultrasoundFindings ${is_locked ? "clinic-ultrasoundFindings" : ""}`}>
          <DataTable
            title="ultrasound-findings"
            onFirstColClick={(row, index) => handleRow(index, row?.id)}
            tableWidth={960}
            disableIcon={true}
            labels={["Label", ...(detailUltrasounds?.headers || [])] || []}
            widths={new Array((["Label", ...(detailUltrasounds?.headers || [])] || []).length).fill(
              (["Label", ...(detailUltrasounds?.headers || [])] || []).length > 0
                ? `${100 / (["", ...(detailUltrasounds?.headers || [])] || []).length}%`
                : "100%"
            )}
            setCheckedState={handleChecked}
            checkedState={checkedState}
            columns={[
              { sortable: false, key: "finding_name" },
              {
                sortable: false,
                key: "detail0",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[0]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[0]) ? detailUltrasounds?.headers?.[0] : false,
              },
              {
                sortable: false,
                key: "detail1",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[1]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[1]) ? detailUltrasounds?.headers?.[1] : false,
              },
              {
                sortable: false,
                key: "detail2",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[2]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[2]) ? detailUltrasounds?.headers?.[2] : false,
              },
              {
                sortable: false,
                key: "detail3",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[3]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[3]) ? detailUltrasounds?.headers?.[3] : false,
              },
              {
                sortable: false,
                key: "detail4",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[4]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[4]) ? detailUltrasounds?.headers?.[4] : false,
              },
              {
                sortable: false,
                key: "detail5",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[5]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[5]) ? detailUltrasounds?.headers?.[5] : false,
              },
              {
                sortable: false,
                key: "detail6",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[6]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[6]) ? detailUltrasounds?.headers?.[6] : false,
              },
              {
                sortable: false,
                key: "detail7",
                hover: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[7]) ? "Select all as Expected" : undefined,
                checkbox: hoverActiveCheck.some((r) => r?.label == detailUltrasounds?.headers?.[7]) ? detailUltrasounds?.headers?.[7] : false,
              },
            ]}
            sorts={["", "", "", "", "", "", "", "", ""]}
            rowData={(ultrasounds_store || []).map((row, index) => ({
              id: row?.finding_id,
              origin_record: row,
              finding_name: row?.finding_name,

              detail0:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length >= 1 ? (
                  checkUltra(detailUltrasounds?.headers[0], row) ? (
                    <Select
                      id={`select_ul_0_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[0]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[0]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[0]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[0] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[0] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[0] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[0] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[0] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[0] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[0] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[0] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[0] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[0] == "Diameter (cm)"
                        ? dataDiameter
                        : //
                        detailUltrasounds?.headers[0] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${ix}_${r}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[0], row) == true ? (
                    <Input
                      id={`detail0_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[0]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[0]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[0]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : detailUltrasounds?.headers[0] == "PSV/EDV" &&
                    row?.finding_name !== "Left Distal Common Femoral" &&
                    row?.finding_name !== "Right Vert Flow" ? (
                    ""
                  ) : null
                ) : null,

              detail1:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length >= 1 ? (
                  checkUltra(detailUltrasounds?.headers[1], row) ? (
                    <Select
                      id={`select_ul_1_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[1]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[1]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[1]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[1] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[1] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[1] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[1] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[1] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[1] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[1] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[1] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[1] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[1] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[1] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[1], row) == true ? (
                    <Input
                      id={`detail1_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[1]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[1]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[1]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail2:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 2 ? (
                  checkUltra(detailUltrasounds?.headers[2], row) ? (
                    <Select
                      id={`select_ul_2_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[2]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[2]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[2]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[2] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[2] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[2] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[2] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[2] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[2] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[2] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[2] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[2] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[2] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[2] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[2], row) == true ? (
                    <Input
                      id={`detail2_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[2]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[2]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[2]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail3:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 3 ? (
                  checkUltra(detailUltrasounds?.headers[3], row) ? (
                    <Select
                      id={`select_ul_3_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[3]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[3]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[3]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[3] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[3] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[3] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[3] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[3] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[3] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[3] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[3] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[3] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[3] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[3] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[3], row) == true ? (
                    <Input
                      id={`detail3_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[3]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[3]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[3]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail4:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 4 ? (
                  checkUltra(detailUltrasounds?.headers[4], row) ? (
                    <Select
                      id={`select_ul_4_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[4]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[4]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[4]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[4] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[4] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[4] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[4] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[4] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[4] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[4] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[4] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[4] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[4] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[4] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[4], row) == true ? (
                    <Input
                      id={`detail4_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[4]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[4]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[4]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail5:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 5 ? (
                  checkUltra(detailUltrasounds?.headers[5], row) ? (
                    <Select
                      id={`select_ul_5_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[5]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[5]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[5]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[5] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[5] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[5] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[5] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[5] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[5] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[5] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[5] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[5] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[5] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[5] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[5], row) == true ? (
                    <Input
                      id={`detail5_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[5]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[5]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[5]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail6:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 6 ? (
                  checkUltra(detailUltrasounds?.headers[6], row) ? (
                    <Select
                      id={`select_ul_6_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[6]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[6]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[6]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[6] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[6] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[6] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[6] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[6] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[6] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[6] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[6] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[6] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[6] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[6] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[6], row) == true ? (
                    <Input
                      id={`detail6_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[6]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[6]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[6]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
              detail7:
                detailUltrasounds?.headers && detailUltrasounds?.headers.length > 7 ? (
                  checkUltra(detailUltrasounds?.headers[7], row) ? (
                    <Select
                      id={`select_ul_7_${fixName(row?.finding_name)}`}
                      allowClear
                      className={`ultrasound-finding-selection ${checkErrorInput(row[`${detailUltrasounds?.headers[7]}`])}`}
                      suffixIcon={<ExpandSVG />}
                      value={row[`${detailUltrasounds?.headers[7]}`] || undefined}
                      onChange={(value) => {
                        setOnChange(`${detailUltrasounds?.headers[7]}`, value, index);
                      }}
                      disabled={is_locked}
                    >
                      {(detailUltrasounds?.headers[7] == "Stenosis"
                        ? checkStenosisAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[7] == "Plaque"
                        ? ["Homogeneous", "Heterogenous", "Calcified", "Not Identified", "None"]
                        : detailUltrasounds?.headers[7] == "Intervention" && detailUltrasounds?.id != 11
                        ? ["Stent", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[7] == "Intervention" && detailUltrasounds?.id == 11
                        ? ["Stent", "Stent - Endoleak", "Vein Graft", "Prosthetic Graft", "Restenosis", "None"]
                        : detailUltrasounds?.headers[7] == "Wave" && detailUltrasounds?.id != 5
                        ? ["Normal", "Abnormal", "Not Identified"]
                        : detailUltrasounds?.headers[7] == "Wave" && detailUltrasounds?.id == 5
                        ? checkWaveAndPreviousFinding(row, index)
                        : detailUltrasounds?.headers[7] == "Direction of Flow"
                        ? ["Antegrade", "Retrograde"]
                        : detailUltrasounds?.headers[7] == "Compressability"
                        ? ["Compressible", "Partially Compressible", "Non-Compressible", "Not Seen"]
                        : detailUltrasounds?.headers[7] == "Reflux"
                        ? ["> 0.5 seconds", "< 0.5 seconds"]
                        : detailUltrasounds?.headers[7] == "Diameter (cm)"
                        ? dataDiameter
                        : detailUltrasounds?.headers[7] == "Diameter" && checkNameDetail(detailUltrasounds, "Venous Leg Vein Mapping")
                        ? dataDiameterNumber
                        : ["Yes", "No"]
                      ).map((r, ix) => (
                        <Select.Option id={`${r}_${ix}_${fixName(row?.finding_name)}`} key={r} value={r}>
                          {r}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : checkUltraInput(detailUltrasounds?.headers[7], row) == true ? (
                    <Input
                      id={`detail7_input_${fixName(row?.finding_name)}`}
                      className={`${checkErrorInput(row[`${detailUltrasounds?.headers[7]}`])}`}
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                        borderRadius: 0,
                        border: "none",
                        outline: "none",
                      }}
                      value={row[`${detailUltrasounds?.headers[7]}`] || undefined}
                      onChange={(e) => {
                        setOnChange(`${detailUltrasounds?.headers[7]}`, e.target?.value, index);
                      }}
                      disabled={is_locked}
                    />
                  ) : null
                ) : null,
            }))}
            defaultActive={-1}
            disableDelete={true}
          />
        </div>
      </div>
      {detail_ultrasound_result &&
        typeof detail_ultrasound_result === "object" &&
        detail_ultrasound_result !== null &&
        Object.keys(detail_ultrasound_result).length > 0 && (
          <React.Fragment>
            <ClinicWorkflowSidebar
              ref={sidebarRef}
              ultrasounds_store={ultrasounds_store}
              isFinding={true}
              detail_ultrasound_result={detail_ultrasound_result}
              resource={patientResource}
              isShow={isSidebar}
              handleCloseSidebar={handleCloseSidebar}
              referralConditions={conditions || []}
              isLocked={is_locked}
              isNormal={true}
              activeIndex={activeIndex}
              onChangeAddendum={onChangeAddendum}
              formSteps={
                detail_ultrasound_result?.procedure_name?.toLowerCase() === "lower extremity arteries" ? formStepsWithQuestionaire : formSteps
              }
              initialConditions={
                detail_ultrasound_result.procedure_name === "Lower Extremity Arteries" ? initialConditionsWithQuestionaire : initialConditions
              }
              disableIDImage={true}
              hasSubHeader={true}
              sidebarIcon={<Icon type="funnel-plot" theme="twoTone" twoToneColor="#740937" />}
              sidebarTitle={detailUltrasounds && detailUltrasounds?.name ? detailUltrasounds?.name : "ULTRASOUND FINDINGS"}
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default UltrasoundFindings;

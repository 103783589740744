import { DatePicker } from "antd";
import React, { useState } from "react";
import AutoCompleteSearch from "../../../../common/components/AutoComplete/AutoCompleteSearch";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";

const dateFormat = "MM/DD/YYYY";

const MeaningfulUseFilter = ({ autoCompleteOptions, valueSearch, providers, onChangeDate, onChangeProvider, handlePopulate }) => {
  const [search, setSearch] = useState("");

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <AutoCompleteSearch
            isNotTargetInput={true}
            value={search}
            handlePopulate={(item) => handlePopulate && handlePopulate(item)}
            title="Patient-Search"
            options={autoCompleteOptions}
            handleSearch={(value) => changeSearch(value)}
            placeholder="Search Patient"
            keyLabel="patient_name"
            actionLabel="Search"
            backgroundWhite
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker format={dateFormat} placeholder="Filter by DOS" suffixIcon={<ExpandSVG />} onChange={onChangeDate} />
        </div>
      </div>
    </div>
  );
};

export default MeaningfulUseFilter;

import { Drawer } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import YesNo from "../../../../common/components/YesNo/YesNoOne";
import { validateField, validateSteps } from "../../../../services/validations";
import { createAppointmentBoardClinical, createAppointmentBoardPatient } from "../../../../store/actions";
import { deleteLocation } from "../../../../store/actions/common";
import {
  addNewLocation,
  autoZip,
  getLocationsStatus,
  removeZipAuto,
  setGlobalLoading,
  updateDetailLocations,
} from "../../../../store/actions/common.js";

const typeValidate = [
  { value: "address", type: "default" },
  { value: "city", type: "default" },
  { value: "value", type: "default" },
  { value: "zipcode", type: "default" },
];

let timer = null;
const DetailsSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, makeChangeHandle }, ref) => {
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [typeData, setTypeData] = useState([]);
  const [enableError, setEnableError] = useState(true);
  const [reset, setReset] = useState(true);
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const clinicalSlug = useSelector((state) => state.appointmentBoard.clinicalSlug);
  const patientSlug = useSelector((state) => state.appointmentBoard.patientSlug);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const loadingAddLocation = useSelector((state) => state.common.loadingAddLocation);
  const [isAddForm, setOpenAddForm] = useState(false);
  const loading_get_detail_location = useSelector((state) => state.common.loading_get_detail_location);
  const [isReset, setIsReset] = useState(false);
  const [state, setState] = useState({});
  const [first, setFirst] = useState(false);

  const dispatch = useDispatch();

  const saveNew = async () => {
    const data = {
      address: state.address,
      city: state.city,
      wound_care: state.care,
      zipcode: state.zip,
      value: state.titleLocation,
      angiography: state.angiography,
      laboratory: state.laboratory,
      hyperbaric: state.hyperbaric,
      ultrasound: state.ultrasound,
      opening_time: state.openTime,
      closing_time: state.closeTime,
      ops_location_id: state.ops_location_id,
      ae_title: state.ae_title,
      type: state.type,
      office_fax: state.office_fax,
    };
    if (!validateSteps(data, typeValidate)) {
      setEnableError(true);
      return;
    } else {
      setEnableError(false);
      setLoading(true);
      const addNew = await dispatch(addNewLocation(data));
      const updateList = await dispatch(getLocationsStatus(`${resource.status == "active" ? "active" : "inactive"}`));
      if (addNew && updateList) {
        setLoading(false);
        cancelAddForm();
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const clickOnSave = async (value, loading) => {
    const data = {
      type: value.type,
      address: value.address,
      city: value.city,
      wound_care: value.care,
      zipcode: value.zip,
      value: value.titleLocation,
      angiography: value.angiography,
      laboratory: value.laboratory,
      hyperbaric: value.hyperbaric,
      ultrasound: value.ultrasound,
      opening_time: value.openTime,
      closing_time: value.closeTime,
      ops_location_id: value.ops_location_id,
      office_fax: value.office_fax,
      ae_title: value.ae_title,
      clinical_procedure_monitor_slug: value.angiography === 1 ? value.clinicalURL : null,
      patient_procedure_monitor_slug: value.angiography === 1 ? value.patientURL : null,
    };
    if (!validateSteps(data, typeValidate)) {
      setEnableError(true);
      return;
    } else {
      setEnableError(false);
      setLoading(true);
      const update = await dispatch(updateDetailLocations(value?.id, data));
      const updateList = await dispatch(getLocationsStatus(`${resource.status == "active" ? "active" : "inactive"}`));
      if (update && updateList) {
        setLoading(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const setValue = (isSet, value) => {
    if (isSet) {
      setState({
        id: value?.id || "",
        type: value.type || "",
        address: value.address || "",
        city: value.city || "",
        zip: value.zipcode || "",
        angiography: !value.angiography ? 0 : value.angiography,
        laboratory: !value.laboratory ? 0 : value.laboratory,
        hyperbaric: !value.hyperbaric ? 0 : value.hyperbaric,
        ultrasound: !value.ultrasound ? 0 : value.ultrasound,
        care: !value.wound_care ? 0 : value.wound_care,
        openTime: value.opening_time || "",
        closeTime: value.closing_time || "",
        titleLocation: value?.value || "",
        ops_location_id: value.ops_location_id || "",
        clinicalURL: value.clinical_procedure_monitor_slug || "",
        patientURL: value.patient_procedure_monitor_slug || "",
        ae_title: value.ae_title || "",
        office_fax: value.office_fax,
      });
    } else {
      setState({});
    }
  };

  const changeHandler = useCallback(
    debounce((value, loading) => clickOnSave(value, loading), 400),
    []
  );

  const handleChange = (type, value) => {
    const newValue = {
      ...state,
      [type]: value,
    };
    setState(newValue);
    if (!isAddForm) {
      isReset && changeHandler(newValue, loading_get_detail_location);
    }
  };

  const handleChangeOption = (key, value) => {
    if (key === "Angiography") {
      handleChange("angiography", value);
      if (value === 1) {
        if (!isAddForm) {
          if (!state.clinicalURL) generateClinicalURL();
          if (!state.patientURL) generatePatientURL();
        }
      }
    } else if (key === "Laboratory") {
      handleChange("laboratory", value);
    } else if (key === "Hyperbaric") {
      handleChange("hyperbaric", value);
    } else if (key === "Ultrasound") {
      handleChange("ultrasound", value);
    } else if (key === "Care") {
      handleChange("care", value);
    }
  };

  const checkZipAuto = (value) => {
    clearTimeout(timer);
    handleChange("zip", value);
    setLoading(true);
    timer = setTimeout(() => {
      setLoading(false);
      dispatch(autoZip(value));
    }, 1000);
  };

  const clickOnAdd = () => {
    setOpenAddForm(true);
    setConfirmDelete(false);
    setValue(false);
    setEnableError(false);
    dispatch(removeZipAuto());
  };

  const handleStatus = async () => {
    if (resource?.id) {
      const data = {
        type: state?.type,
        address: state?.address,
        city: state?.city,
        wound_care: state?.care,
        zipcode: state?.zip,
        value: state?.titleLocation,
        angiography: state?.angiography,
        laboratory: state?.laboratory,
        hyperbaric: state?.hyperbaric,
        ultrasound: state?.ultrasound,
        opening_time: state?.openTime,
        closing_time: state?.closeTime,
        ops_location_id: state?.ops_location_id,
        office_fax: state?.office_fax,
        ae_title: state?.ae_title,
        status: `${resource.status == "active" ? "inactive" : "active"}`,
        clinical_procedure_monitor_slug: state?.angiography === 1 ? state?.clinicalURL : null,
        patient_procedure_monitor_slug: state?.angiography === 1 ? state?.patientURL : null,
      };
      setLoading(true);
      await dispatch(updateDetailLocations(resource?.id, data));
      const loadActive = await dispatch(getLocationsStatus("active"));
      if (makeChangeHandle && loadActive) {
        makeChangeHandle();
      }
      await dispatch(getLocationsStatus("inactive"));
      setLoading(false);
    }
  };

  const clickOnDelete = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    if (Object.keys(resource).length > 0) {
      await dispatch(deleteLocation(resource?.id));
      setConfirmDelete(false);
      await dispatch(getLocationsStatus(`${resource.status == "active" ? "active" : "inactive"}`));
      setValue(false);
    }
  };

  const cancelAddForm = () => {
    setOpenAddForm(false);
    setConfirmDelete(false);
    setValue(true, resource);
    setEnableError(false);
  };

  const generatePatientURL = async () => {
    await dispatch(createAppointmentBoardPatient(resource?.id));
  };

  const generateClinicalURL = async () => {
    await dispatch(createAppointmentBoardClinical(resource?.id));
  };

  useEffect(() => {
    if (clinicalSlug === null || clinicalSlug === "") return;
    handleChange("clinicalURL", "https://app.faact.com/mon/" + clinicalSlug);
    setTimeout(() => {
      handleChange("clinicalURL", "https://app.faact.com/mon/" + clinicalSlug);
    }, 1000);
  }, [clinicalSlug]);

  useEffect(() => {
    if (patientSlug === null || patientSlug === "") return;
    handleChange("patientURL", "https://app.faact.com/mon/" + patientSlug);
    setTimeout(() => {
      handleChange("patientURL", "https://app.faact.com/mon/" + patientSlug);
    }, 1000);
  }, [patientSlug]);

  useEffect(() => {
    setTypeData([
      { label: "Clinic", value: "clinic" },
      { label: "OBL", value: "vhc" },
      { label: "Hospital", value: "hospital" },
      { label: "Nursing Home", value: "nursing_home" },
    ]);
    if (resource && resource?.id !== state?.id) {
      setReset(true);
      setOpenAddForm(false);
      setValue(true, resource);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }

    if (resource.angiography === 1 && (resource.clinical_procedure_monitor_slug === null || resource.clinical_procedure_monitor_slug === "")) {
      generateClinicalURL();
    }
    if (resource.angiography === 1 && (resource.patient_procedure_monitor_slug === null || resource.patient_procedure_monitor_slug === "")) {
      generatePatientURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  useEffect(() => {
    generatePatientURL();
    generateClinicalURL();
    setFirst(true);
  }, []);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          handleChange("city", address.place);
          setReset(true);
        }
      } else {
        handleChange("city", undefined);
        setReset(true);
      }
    }
  }, [zipAutoData]);

  useEffect(() => {
    if (isShow && resource && Object.keys(resource).length) {
      if (loadingAddLocation || loading) {
        dispatch(setGlobalLoading(true));
      } else {
        dispatch(setGlobalLoading(false));
      }
    }
  }, [loadingAddLocation, loading, isShow, resource]);

  if (!resource || !Object.keys(resource).length) {
    return null;
  }
  return (
    <Drawer
      title={!isAddForm ? resource?.value : " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && <ToastMessage type="Locations" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          >
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Name"}
                value={state.titleLocation}
                handleChange={(value) => {
                  handleChange("titleLocation", value);
                }}
                required={true}
                isError={validateField("value", state.titleLocation)}
                enableErrorText={enableError}
                helperText={"Name is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Type"}
                value={state.type}
                handleChange={(value) => {
                  handleChange("type", value);
                }}
                required={true}
                isError={validateField("value", state.type)}
                enableErrorText={enableError}
                helperText={"Type is Required!"}
                type={"check"}
                optionKey={"value"}
                valueKey={"label"}
                options={typeData || []}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Address"}
                value={state.address}
                handleChange={(value) => {
                  handleChange("address", value);
                }}
                required={true}
                isError={validateField("address", state.address)}
                enableErrorText={enableError}
                helperText={"Address is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"City"}
                value={state.city}
                handleChange={(value) => {
                  handleChange("city", value);
                }}
                required={true}
                isError={validateField("city", state.city)}
                enableErrorText={enableError}
                helperText={"City is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Zip"}
                value={state.zip}
                handleChange={(value) => {
                  checkZipAuto(value);
                }}
                isError={validateField("zipcode", state.zip)}
                enableErrorText={enableError}
                required={true}
                helperText={"Zip is Required!"}
              />
            </div>
            {state.type === "hospital" && (
              <div className="resourceInfo d-flex align-items-end required-field">
                <EditableMaterialText
                  isErrorTextSpacing={true}
                  reset={reset}
                  label={"Surgery Scheduling Office Fax"}
                  value={state.office_fax}
                  handleChange={(value) => {
                    handleChange("office_fax", value);
                  }}
                  mask={"phone"}
                />
              </div>
            )}
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Opening Time"}
                value={state.openTime}
                handleChange={(value) => {
                  handleChange("openTime", value);
                }}
                type={"time"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"Close Time"}
                value={state.closeTime}
                handleChange={(value) => {
                  handleChange("closeTime", value);
                }}
                type={"time"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"OPS Location ID"}
                value={state.ops_location_id}
                handleChange={(value) => {
                  handleChange("ops_location_id", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableMaterialText
                isErrorTextSpacing={true}
                reset={reset}
                label={"AE Title"}
                value={state.ae_title}
                handleChange={(value) => {
                  handleChange("ae_title", value);
                }}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <b style={{ minWidth: "37%" }}>Angiography: </b>
              <YesNo
                option={state.angiography}
                setOption={(value) => {
                  handleChangeOption("Angiography", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "37%" }}>Laboratory: </b>
              <YesNo
                option={state.laboratory}
                setOption={(value) => {
                  handleChangeOption("Laboratory", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "37%" }}>Hyperbaric O2: </b>
              <YesNo
                option={state.hyperbaric}
                setOption={(value) => {
                  handleChangeOption("Hyperbaric", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "37%" }}>Ultrasound: </b>
              <YesNo
                option={state.ultrasound}
                setOption={(value) => {
                  handleChangeOption("Ultrasound", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "37%" }}>Wound Care: </b>
              <YesNo
                option={state.care}
                setOption={(value) => {
                  handleChangeOption("Care", value);
                }}
              />
            </div>
            {state.angiography === 1 && <hr />}
          </div>

          {state.angiography === 1 &&
            !isAddForm &&
            (state.clinicalURL === null || state.patientURL === null ? (
              <div />
            ) : (
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableMaterialText
                    isErrorTextSpacing={true}
                    reset={reset}
                    value={state.clinicalURL}
                    required={true}
                    label={"Media Wall Patient Procedure Schedule URL"}
                    noEditable={true}
                    selection={true}
                    isError={validateField("value", state.clinicalURL)}
                    enableErrorText={enableError}
                    helperText={""}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableMaterialText
                    isErrorTextSpacing={true}
                    reset={reset}
                    noEditable={true}
                    selection={true}
                    label={"Media Wall Clinical Procedure Schedule URL"}
                    value={state.patientURL}
                    required={true}
                    isError={validateField("value", state.patientURL)}
                    enableErrorText={enableError}
                    helperText={""}
                  />
                </div>
              </div>
            ))}

          {isAddForm ? (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
              }}
            >
              <button onClick={cancelAddForm} className="outlined-btn" style={{ marginTop: "0.5rem" }}>
                Back
              </button>
              <button onClick={saveNew} style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                Save
              </button>
            </div>
          ) : (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
              }}
            >
              <button onClick={handleStatus} className="outlined-btn" style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}>
                {resource?.status == "active" ? " Make InActive" : " Make Active"}
              </button>
              <button onClick={clickOnAdd} className="outlined-btn" style={{ marginTop: "0.5rem", marginRight: "0.5rem" }}>
                Add New Location
              </button>
              <button
                onClick={clickOnDelete}
                className={`common-btn ${confirmDelete ? "delete-btn" : "outlined-btn"}`}
                style={
                  confirmDelete
                    ? {
                        marginRight: 0,
                        backgroundColor: "#CA373B",
                        marginTop: "0.5rem",
                      }
                    : { marginTop: "0.5rem" }
                }
              >
                {confirmDelete ? "Confirm Delete?" : "Delete Location"}
              </button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default DetailsSidebar;

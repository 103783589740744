import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import { setLocal } from "../../../helpers/Local";
import {
  delete_angio_type,
  detail_angio_type,
  edit_angio_type,
  get_angio_type,
  get_angio_type_variable,
  save_angio_type,
  setGlobalLoading,
} from "../../../store/actions";

const { Search } = Input;
let timer = null;

const AngioSearch = ({ setResource }) => {
  const dispatch = useDispatch();
  const [angioID, setAngioId] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [dataRow, setDataRow] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [search, setSearch] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const emptyRows = [];

  const loading = useSelector((state) => state.procedureDetail.loading_angio_type);
  const angio_list = useSelector((state) => state.procedureDetail.angio_list);

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    if (angioID !== row?.id) {
      setAngioId(row?.id);
      setResource(row?.id);
      setLocal("id_angio_type", row?.id);
      await dispatch(detail_angio_type(row?.id));
      await dispatch(get_angio_type_variable(row?.id));
    }
  };

  const handleAddAngio = async () => {
    if (!search.trim()) {
      return;
    }
    setLoadingLocal(true);
    const param = {
      name: search,
      locations: "[]",
      providers: "[]",
      ultrasounds: "[]",
    };
    const addsuccess = await dispatch(save_angio_type(param));
    if (addsuccess) {
      setSearch("");
      setLoadingLocal(false);
      dispatch(get_angio_type());
    }
  };

  const handleSearch = (e) => {
    const value = e.target?.value;
    setSearch(value);
    setActiveRow(-1);
  };

  useEffect(() => {
    const arrayFilter = [...angio_list].reverse();
    const arraySucces = arrayFilter.filter((item) => {
      return item?.name.toLowerCase().includes(search.toLowerCase());
    });
    setDataRow([...arraySucces]);
  }, [angio_list, search]);

  const fetchAPI = async () => {
    setLoadingLocal(true);
    const loadSuccess = await dispatch(get_angio_type());
    if (loadSuccess) {
      setLoadingLocal(false);
    }
  };

  const handleDelete = async (row, index) => {
    if (activeDelete !== row?.data?.id) {
      setActiveDelete(row?.data?.id);
      return;
    }
    if (index === activeRow) {
      setActiveRow(-1);
    }
    if (angioID === activeDelete) {
      setAngioId(-1);
      setResource(-1);
      setLocal("id_angio_type", 0);
    }
    const deleteSuccess = await dispatch(delete_angio_type(row?.data?.id));
    const loadSuccess = await dispatch(get_angio_type());
    if (deleteSuccess && loadSuccess) {
      setActiveDelete(-1);
      setActiveRow(-1);
    }
  };

  const onChangeDetail = async (e, row) => {
    setHeaderTitle(e.target?.value);
    clearTimeout(timer);
    const params = {
      name: e.target?.value,
    };
    timer = setTimeout(async () => {
      await dispatch(edit_angio_type(row?.id, params));
      await dispatch(get_angio_type());
    }, 500);
  };

  const onChangeSide = async (value, row) => {
    const params = {
      side: value === "yes" ? 1 : 0,
    };
    timer = setTimeout(async () => {
      await dispatch(edit_angio_type(row?.id, params));
      await dispatch(get_angio_type());
    }, 500);
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingLocal || loading));
  }, [loading, loadingLocal]);

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search value={search} placeholder="Search OBL Procedures" onChange={handleSearch} suffix={<SearchSVG />} />
        </div>
        <div className="patientSearch__filter-action">
          <button className="patientSearch__filter-add" onClick={handleAddAngio}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="fullContainer" style={{ position: "relative" }}>
        <DataTable
          title="procedure_search_clinical"
          labels={["Procedure", "Side"]}
          widths={["70%", "30%"]}
          fullHeight={true}
          columns={[
            { sortable: false, key: "procedure" },
            { sortable: false, key: "side" },
          ]}
          handleDelete={(value, index) => handleDelete(value, index)}
          handleClickRow={(row, index) => handleSelect(row?.data, index)}
          rowData={(dataRow || []).map((item, index) => {
            return {
              data: item,
              disableDelete: item.is_default === 1,
              procedure:
                activeRow === index ? (
                  <Input
                    value={headerTitle}
                    autoFocus={true}
                    onChange={(e) => {
                      onChangeDetail(e, item);
                    }}
                    onFocus={() => {
                      setHeaderTitle(item?.name);
                    }}
                  />
                ) : (
                  item?.name
                ),
              side: (
                <Select
                  placeholder="Side"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  style={{ width: "100%", borderBottom: 0 }}
                  value={item.side === 1 ? "yes" : "no"}
                  onChange={(e) => {
                    onChangeSide(e, item);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default AngioSearch;

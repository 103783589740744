import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getPrescriptions } from "src/services/api.services";
import { getLocal } from "../../../helpers/Local";
import { setGlobalLoading, setSidebarState } from "../../../store/actions";
import DataTable from "../../components/DataTable/DataTable";
import Loading from "../../components/Loading";
import Sidebar from "./sidebar";

const Medications = ({ data, title, display }) => {
  const [activeIndex, setRow] = useState(-1);
  const [isSidebar, setIsSidebar] = useState(true);
  const [resource, setResource] = useState(null);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    setIsSidebar(true);
  };

  useEffect(() => {
    dispatch(setSidebarState(true));
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => {
    setValuePharmacyEdit(null);
    setIsPharmacyAdding(false);
    setIsPharmacyEdit(false);
    setRow(index);
    setResource(tableLoad[index]);
    handleSidebar();
  };

  const current_patient_id = getLocal("current_patient_id");
  const fetchList = () => {};

  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
  const [isPharmacyEdit, setIsPharmacyEdit] = useState(false);
  const [valuePharmacyEdit, setValuePharmacyEdit] = useState(null);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        const listUpdate = await getPrescriptions(current_patient_id, page + 1);

        if (listUpdate && listUpdate.length > 0) {
          setPage(page + 1);
          setTableLoads([...tableLoad, ...listUpdate]);
          setLoading(false);
        } else {
          setLoading(false);
          setOffLoad(false);
        }
      }
    }
  };

  const setPharmacyAddForm = (value) => {
    setIsPharmacyAdding(value);
  };

  const setPharmacyEditForm = (value, valueDetail) => {
    setIsPharmacyEdit(value);
    setValuePharmacyEdit(valueDetail);
  };

  const fetchAPI = async () => {
    await dispatch(setGlobalLoading(true));
    setLoading(true);
    setTableLoads([]);
    const listUpdate = await getPrescriptions(current_patient_id, 1);
    setTableLoads(listUpdate);
    await dispatch(setGlobalLoading(false));
    setLoading(false);
  };

  const fetchAPISavePharmacy = async () => {
    await dispatch(setGlobalLoading(true));
    setTableLoads([]);
    const listUpdate = await getPrescriptions(current_patient_id, 1);
    setTableLoads(listUpdate);
    if (activeIndex >= 0) {
      setResource(listUpdate[activeIndex]);
    }
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          {loading && <Loading />}
          <DataTable
            title="appointment_table"
            tableWidth={1060}
            labels={["Date and Time", "Medication Name", "Dose", "Route", "Frequency", "Number of Units", "Pharmacy"]}
            widths={["17%", "20%", "10%", "10%", "10%", "10%", "23%"]}
            columns={[
              { sortable: false, key: "date_time" },
              { sortable: false, key: "medication_name" },
              { sortable: false, key: "dose" },
              { sortable: false, key: "route" },
              { sortable: false, key: "frequency" },
              { sortable: false, key: "number_of_unit" },
              { sortable: false, key: "pharmacy" },
            ]}
            sorts={["", "", "", "", "", "", ""]}
            rowData={(tableLoad || []).map((row, index) => ({
              id: row?.id,
              date_time: (
                <div
                  style={{
                    display: "flex",
                    paddingLeft: "0.25rem",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  {row.fax_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {row.fax_status === 2 && <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />}
                  {row.fax_status === 0 && <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />}
                  {!row.fax_status && <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />}
                  <p
                    style={{
                      paddingLeft: "0.75rem",
                      fontWeight: "300",
                      color: "#152935",
                    }}
                  >
                    {row.updated_at && moment(row.updated_at).format("MM/DD/YYYY hh:mm A")}
                  </p>
                </div>
              ),
              medication_name: row.medication && row.medication.full_medication,
              dose: row.medication && row.medication.strength,
              route: row.medication && row.medication.route,
              frequency: row.frequency,
              number_of_unit: row.number_of_unit,
              pharmacy: row.pharmacy && row.pharmacy?.name,
            }))}
            fetchList={fetchList}
            loadMore={loadMore}
            handleClickRow={handleRow}
            defaultActive={-1}
            disableDelete={true}
          />
        </div>
      </div>
      <Sidebar
        sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
        ref={sidebarRef}
        onReload={fetchAPISavePharmacy}
        isPharmacyAdding={isPharmacyAdding}
        isPharmacyEdit={isPharmacyEdit}
        valuePharmacyEdit={valuePharmacyEdit}
        setPharmacyAddForm={setPharmacyAddForm}
        setPharmacyEditForm={setPharmacyEditForm}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
        resource={resource}
      />
    </div>
  );
};

export default Medications;

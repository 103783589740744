import { Drawer, Icon, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteDataTable from "../../../../common/components/AutoComplete/AutoCompleteDataTable";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";
import Loading from "../../../../common/components/Loading";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import ToastMessage from "../../../../common/components/Toast";
import DeleteIcon from "../../../../common/components/icons/DeleteIconButton";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { getCommaSeparatedValues, useOutsideDetect } from "../../../../common/utils";
import { getLocal } from "../../../../helpers/Local";
import { validateField } from "../../../../services/validations";
import { getNarcoticInventories, saveAngioMedicationVital, setSidebarState } from "../../../../store/actions";
import { filterMedicationsByQuery as filterDataByQuery } from "../../constant";

let timer = null;

const titleStyle = {
  width: "25%",
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  height: "100%",
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
};

const IntaOperativeSideBar = React.forwardRef(({ resource, isShow, handleCloseSidebar, dataOnchange, appointment }, ref) => {
  const supplies = useSelector((state) => state.inventory.narcotics);
  const [inputMedication, setInputMedication] = useState("");
  const [loading, setLoading] = useState("");
  const [medications, setMedications] = useState([]);
  const [filteredMedications, setFilteredMedications] = useState([]);
  const [operatives, setOperatives] = useState([]);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [activeMedDelete, setActiveMedDelete] = useState(-1);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeMedRow, setActiveMedRow] = useState(-1);
  const [hasTotalRadiationTime, setHasTotalRadiationTime] = useState(false);
  const [hasTotalDAP, setHasTotalDAP] = useState(false);
  const [hasTotalContrastLoad, setHasTotalContrastLoad] = useState(false);
  const [hasComplication, setHasComplication] = useState(false);
  const [hasQualifiedHealthcareProvider, setHasQualifiedHealthcareProvider] = useState(false);
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const [isToastr, setIsToastr] = useState(false);
  const [isTimePicker, setIsTimePicker] = useState(false);
  const [isRoute, setIsRoute] = useState(false);
  const [isType, setIsType] = useState(false);
  const [totalRadiation, setTotalRadiation] = useState("");
  const [totalDAP, setTotalDAP] = useState("");
  const [totalContrast, setTotalContrast] = useState("");
  const [complication, setComplication] = useState("");
  const [qualifiedHealthCareProvider, setQualifiedHealthcareProvider] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState(-1);
  const [isAddable, setIsAddable] = useState(true);
  const [isReSize, setIsReSize] = useState(false);
  const dispatch = useDispatch();

  const complication_value = useSelector((state) => state.patientExamination.complication);
  const total_contrast_load = useSelector((state) => state.patientExamination.total_contrast_load);
  const total_radiation_time = useSelector((state) => state.patientExamination.total_radiation_time);
  const total_dap = useSelector((state) => state.patientExamination.dose_area_product);
  const qualified_healthcare_provider = useSelector((state) => state.patientExamination.qualified_healthcare_provider);
  const is_locked = useSelector((state) => state.common.is_locked);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const activeStaff = useSelector((state) => state.common.activeStaff);
  const patientResource = useSelector((state) => state.clinicProvider?.patientResource);
  const patientExamination = useSelector((state) => state.patientExamination);

  const debouncedSearch = useDebounce(inputMedication, 400);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const refMed1 = useRef(null);
  const refMed2 = useRef(null);

  useEffect(() => {
    callApis();
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  async function callApis() {
    setLoading(true);
    await dispatch(getNarcoticInventories(""));
    await dispatch(setSidebarState(true));
    setLoading(false);
  }

  useEffect(() => {
    if (totalRadiation === "" || totalRadiation === null) {
      setHasTotalRadiationTime(false);
    } else {
      setHasTotalRadiationTime(true);
    }
    if (totalDAP === "" || totalDAP === null || totalDAP == -1) {
      setHasTotalDAP(false);
    } else {
      setHasTotalDAP(true);
    }
    if (totalContrast === "" || totalContrast === null) {
      setHasTotalContrastLoad(false);
    } else {
      setHasTotalContrastLoad(true);
    }

    if (complication === "" || complication === null) {
      setHasComplication(false);
    } else {
      setHasComplication(true);
    }

    if (qualifiedHealthCareProvider === "" || qualifiedHealthCareProvider === null || qualifiedHealthCareProvider === 0) {
      setHasQualifiedHealthcareProvider(false);
    } else {
      setHasQualifiedHealthcareProvider(true);
    }
  }, [totalRadiation, totalContrast, complication, qualifiedHealthCareProvider, totalDAP]);

  useEffect(() => {
    setComplication(complication_value);
  }, [complication_value]);

  useEffect(() => {
    setTotalContrast(total_contrast_load);
  }, [total_contrast_load]);

  useEffect(() => {
    setQualifiedHealthcareProvider(qualified_healthcare_provider);
  }, [qualified_healthcare_provider]);

  useEffect(() => {
    setTotalRadiation(total_radiation_time);
  }, [total_radiation_time]);

  useEffect(() => {
    if (total_dap == 0) {
      setTotalDAP("0");
    } else {
      setTotalDAP(total_dap);
    }
  }, [total_dap]);

  const handleSetValue = (value, type) => {
    if (type === "total_radiation_time") {
      setTotalRadiation(value);
    }
    if (type === "dose_area_product") {
      setTotalDAP(value);
    }
    if (type === "total_contrast_load") {
      setTotalContrast(value);
    }
    if (type === "complication") {
      setComplication(value);
    }
    if (type === "qualified_healthcare_provider") {
      setQualifiedHealthcareProvider(value);
    }
    const params = {
      total_radiation_time: totalRadiation,
      dose_area_product: totalDAP,
      total_contrast_load: totalContrast,
      complication: complication,
      medications: medications,
      qualified_healthcare_provider: qualifiedHealthCareProvider,
      [type]: value,
    };
    dataOnchange && dataOnchange(params);
  };

  useEffect(() => {
    if (debouncedSearch) {
      const allInventories = filterDataByQuery(supplies, debouncedSearch);
      setFilteredMedications(allInventories);
    }
  }, [debouncedSearch]);

  const addOperative = () => {
    setOperatives([
      {
        time: moment(),
        bp: "",
        hr: "",
        sp: "",
        type: "intra",
      },
      ...operatives,
    ]);
    setIsAddable(false);
    setActiveRow(0);
  };

  const handleChange = (type, value, index) => {
    if (operatives && index >= 0 && type) {
      const updatedOperatives = [...operatives];
      updatedOperatives[index][type] = value;
      setOperatives(updatedOperatives);
      callVitalUpdate(operatives);
    }
  };

  const handleSearchMedication = (search) => {
    setInputMedication(search);
  };

  const selectItemMedication = (medicationName, medicationId, unit) => {
    if (medicationName === "") return;
    for (let medicationId_ of medications) {
      if (medicationId_ === medicationId) return;
    }

    let listItems = [...medications];
    listItems.unshift({
      id: medicationId,
      name: medicationName,
      time: moment(),
      dose: "",
      route: undefined,
      drugs: {
        unit,
      },
      type: "intra",
    });
    setMedications(listItems);
  };

  const removeRowMedication = (medication, index) => {
    if (activeMedDelete !== index) {
      setActiveMedDelete(index);
    } else {
      const reMapMedication = [...(medications || [])].map((r) => {
        return {
          ...r,
          id: r?.drug_id,
        };
      });
      let listItems = [...reMapMedication];
      listItems.splice(index, 1);
      setActiveMedDelete(-1);
      setMedications(listItems);
      callUpdate(listItems);
    }
  };

  const handleMedicationChange = (type, value, index) => {
    const updatedMedications = medications?.map((med) => {
      return {
        ...med,
        id: med?.drug_id ? med?.drug_id : med.id,
      };
    });
    if (type === "dose") {
      updatedMedications[index][type] = getCommaSeparatedValues(value);
    } else {
      updatedMedications[index][type] = value;
    }
    const detailCheck = updatedMedications && updatedMedications[index];
    setMedications(updatedMedications);
    if (detailCheck && detailCheck["dose"] && detailCheck["route"] && detailCheck["time"]) {
      callUpdate(updatedMedications);
    }
  };

  useEffect(() => {
    const medications = patientExamination?.medication_administration?.intra_op;
    const vitalSigns = patientExamination?.vital_signs;
    setMedications(medications || []);
    setOperatives(vitalSigns || []);
  }, [patientExamination]);

  const removeItem = (index) => {
    if (activeDelete !== index) {
      setActiveDelete(index);
    } else {
      const updatedOperatives = [...operatives];
      updatedOperatives.splice(index, 1);
      setOperatives(updatedOperatives);
      setActiveDelete(-1);
      callVitalUpdate(updatedOperatives);
    }
  };

  const callUpdate = async (valueMedication) => {
    if (valueMedication) {
      if (valueMedication.some((r) => r.dose == null || r.dose === "" || r.route == null)) {
      } else {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          const saveSuccess = await dispatch(
            saveAngioMedicationVital({
              medication: valueMedication,
              procedure_id: current_appointment_id,
              patient_id: current_patient_id,
              operatives: operatives,
            })
          );
          if (saveSuccess) {
            setIsToastr(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }, 1000);
      }
    }
  };

  const callVitalUpdate = async (valueVital) => {
    if (valueVital) {
      if (valueVital.some((r) => r.bp == "" || r.hr == "" || r.sp == "")) {
      } else {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          const saveSuccess = await dispatch(
            saveAngioMedicationVital({
              procedure_id: current_appointment_id,
              patient_id: current_patient_id,
              medication: medications,
              operatives: valueVital,
            })
          );
          if (saveSuccess) {
            setIsToastr(true);
            setIsAddable(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }, 500);
      }
    }
  };

  const handleAddMedication = () => {
    setMedications([
      {
        type: "intra",
        time: moment().format("YYYY-MM-DD hh:mm:ss A"),
        name: "",
        dose: "",
        route: undefined,
      },
      ...medications,
    ]);
  };

  useOutsideDetect(ref1, ref2, activeRow !== -1, (_) => setActiveRow(-1));
  useOutsideDetect(refMed1, refMed2, activeMedRow !== -1, (_) => setActiveMedRow(-1));

  const getWidth = (index) => {
    return selectedColumn === -1
      ? index === 0
        ? "calc(25% - 3rem)"
        : "25%"
      : selectedColumn === 0 && index === 0
      ? "calc(30% - 3rem)"
      : selectedColumn !== 0 && index === 0
      ? "calc(23.33% - 3rem)"
      : selectedColumn === index
      ? "30%"
      : "23.33%";
  };

  const getTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            ...titleStyle,
            paddingLeft: "0",
            width: getWidth(0),
            marginLeft: "3rem",
          }}
        >
          Time
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(1),
          }}
        >
          BP
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(2),
          }}
        >
          HR
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(3),
          }}
        >
          Sp02 (%)
        </div>
      </div>
    );
  };

  const renderIcons = (type) => {
    let html;
    if (!type) {
      html = <span />;
    } else {
      if (type === "intra") {
        html = (
          <div
            style={{
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={require("../../../../common/components/icons/intra.svg")} alt="intraSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "post") {
        html = (
          <div
            style={{
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={require("../../../../common/components/icons/post.svg")} alt="postSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "pre") {
        html = (
          <div
            style={{
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={require("../../../../common/components/icons/pre.svg")} alt="preSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "discharge") {
        html = (
          <div
            style={{
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={require("../../../../common/components/icons/discharge.svg")} alt="dischargeSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
    }

    return html;
  };
  const { first_name, last_name, deceased, cancelled_reason } = patientResource || {};
  const hrRef = useRef(null);
  const spRef = useRef(null);
  const timeRef = useRef(null);
  const timeRef2 = useRef(null);
  const doseRef = useRef(null);

  const handleFocus = (event, name) => {
    event.preventDefault();
    let ref = "";
    if (name === "hr") ref = hrRef;
    if (name === "dose") ref = doseRef;
    if (name === "sp") ref = spRef;
    if (ref?.current && ref.current.input) {
      if (ref?.current?.input?.className && ref?.current?.input?.className.split(" ").includes("input-error")) {
        ref.current.input.style.setProperty("border", "2px solid #da1e28", "important");
        ref.current.input.style.setProperty("background", "rgb(253, 229, 229)", "important");
        ref.current.input.style.setProperty("color", "da1e28", "important");
        return;
      } else {
        ref.current.input.style.setProperty("background", "#fff", "important");
      }
      ref.current.input.style.setProperty("border", "2px solid #0f62fe", "important");
    }
  };

  const handleBlur = (event, name) => {
    event.preventDefault();
    let ref = "";
    if (name === "hr") ref = hrRef;
    if (name === "time") ref = timeRef;
    if (name === "dose") ref = doseRef;
    if (name === "sp") ref = spRef;
    if (ref?.current && ref.current.input) {
      if (ref?.current?.input?.className && ref?.current?.input?.className.split(" ").includes("input-error")) {
        ref.current.input.style.setProperty("border", "2px solid #da1e28", "important");
        ref.current.input.style.setProperty("background", "rgb(253, 229, 229)", "important");
        ref.current.input.style.setProperty("color", "da1e28", "important");
        return;
      } else {
        ref.current.input.style.setProperty("background", "#fff", "important");
      }
      if (ref === doseRef) {
        ref.current.input.style.setProperty("border", "none");
        return;
      }

      ref.current.input.style.setProperty("border", "none", "important");
    }
  };

  return (
    <Drawer
      title={`Intra-operative - ${first_name || ""} ${last_name || ""} ${
        getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""
      }`}
      placement="right"
      variant="permanent"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (cancelled_reason != "" && cancelled_reason) ? "red" : ""}`}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      {loading ? (
        <Loading
          extra={{
            width: "40px",
            height: "40px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          color="#ffffff"
        />
      ) : (
        <div ref={ref} className="resourceContainer new">
          {isToastr && <ToastMessage type="Inta Operative" status="success" msg="Saved Successfully!" />}
          <ScrollWrapper css="x-hidden">
            <div
              style={{
                marginTop: "1.5rem",
              }}
            >
              <SidebarTitle icon={<Icon type="interaction" theme="twoTone" twoToneColor="#198038" />} title="VITAL SIGNS" subTitle="" />
            </div>

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
                paddingBottom: "1.5rem",
                position: "relative",
                zIndex: 5,
              }}
              ref={!isTimePicker && !isType ? ref1 : null}
            >
              <button
                className="text-btn"
                style={{
                  marginRight: "1rem",
                  position: "absolute",
                  top: "-24px",
                  right: "22px",
                  color: is_locked === true || is_locked === 1 || !isAddable ? "#9B9C9F" : "#005ef6",
                }}
                onClick={addOperative}
                disabled={is_locked || !isAddable}
              >
                Add +
              </button>
              <div
                className="resourceInfo"
                style={{
                  marginTop: 0,
                  paddingTop: 0,
                  paddingRight: 0,
                  height: "calc(30 * 8px + 48px + 5px)",
                }}
                ref={!isTimePicker && !isType ? ref2 : null}
              >
                <ExtendDataTable
                  isBorderLeftNone={true}
                  isBorderThick={true}
                  isBorderOnHover={true}
                  title={getTitle()}
                  onClickRow={(index) => setActiveRow(index)}
                  onClickColumn={(index) => setSelectedColumn(index)}
                  activeRow={activeRow}
                  data={(operatives || []).map((opt, index) => ({
                    key: `intra-operative-${index}`,
                    time:
                      activeRow === index || !opt.time ? (
                        <div className="vitals-first-col">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              paddingRight: "10px",
                            }}
                          >
                            <div style={{ flex: 1, paddingRight: "10px" }}>
                              <Select
                                disabled={is_locked}
                                className={validateField("text", opt?.type) ? "input-error" : ""}
                                suffixIcon={<ExpandSVG />}
                                allowClear={true}
                                value={opt?.type || undefined}
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: validateField("text", opt?.type) ? "rgba(255, 0, 0, 0.3)" : "#fff",
                                }}
                                onChange={(val) => {
                                  handleChange("type", val, index);
                                }}
                                onFocus={() => setIsType(true)}
                                onBlur={() => setIsType(false)}
                              >
                                {["pre", "intra", "post", "discharge"].map((r, index) => {
                                  return (
                                    <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                      {r}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <TimePicker
                            disabled={is_locked}
                            format={"h:mm A"}
                            use12Hours
                            ref={timeRef}
                            className={validateField("time", opt.time) ? "input-mix-requied-true" : ""}
                            onOpenChange={(status) => {
                              if (status) timeRef.current.timePickerRef.picker.style.setProperty("border", "2px solid #0f62fe", "important");
                              else timeRef.current.timePickerRef.picker.style.setProperty("border", "none", "important");
                              setIsTimePicker(status);
                            }}
                            placeholder="Time"
                            style={{
                              maxWidth: "calc(100% - 5px)",
                              width: "calc(100% - 5px)",
                              marginRight: "5px",
                              background: "transparent",
                            }}
                            suffixIcon={<span />}
                            value={
                              opt.time && moment(opt.time).format("YYYY-MM-DD h:mm A") !== "Invalid date"
                                ? moment(opt.time, "YYYY-MM-DD h:mm A")
                                : moment()
                            }
                            onChange={(value) => {
                              handleChange("time", value && value !== "Invalid date" ? value : null, index);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="vitals-first-col">
                          <div className="icon-wrapper">{renderIcons(opt.type)}</div>
                          <div className="ant-time-picker">{opt.time ? moment(opt.time).format("LT") : null}</div>
                        </div>
                      ),
                    bp:
                      activeRow === index || !opt.bp ? (
                        <EditableInputWithDiv
                          disabled={is_locked}
                          value={opt.bp}
                          subLabel="BP"
                          handleChange={(value) => handleChange("bp", value, index)}
                          isError={!opt.bp}
                          enableErrorText
                          required
                          sm
                        />
                      ) : (
                        opt.bp
                      ),
                    hr:
                      activeRow === index || !opt.hr ? (
                        <Input
                          disabled={is_locked}
                          ref={hrRef}
                          className={validateField("text", opt.hr) ? "input-error" : ""}
                          style={{
                            width: "calc(100% - 5px)",
                            marginRight: "5px",
                            // borderBottom: "1px solid #8d8d8d !important",
                            background: "rgb(253, 229, 229) !important",
                            border: "2px solid #da1e28 !important",
                            boxShadow: "none",
                          }}
                          defaultValue={opt.hr}
                          placeholder={"HR"}
                          maxLength={3}
                          mask="111"
                          onChange={(e) => {
                            handleChange("hr", e.target.value, index);
                          }}
                          allowClear={false}
                          onFocus={(e) => handleFocus(e, "hr")}
                          onBlur={(e) => handleBlur(e, "hr")}
                        />
                      ) : (
                        opt.hr
                      ),
                    sp:
                      activeRow === index || !opt.sp ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            paddingRight: "10px",
                          }}
                        >
                          <div style={{ flex: 1, paddingRight: "10px" }}>
                            <Input
                              disabled={is_locked}
                              ref={spRef}
                              className={validateField("text", opt.sp) ? "input-error" : ""}
                              style={{
                                width: "100%",
                                borderBottom: "1px solid #8d8d8d !important",
                                boxShadow: "none",
                              }}
                              maxLength={3}
                              mask="111"
                              defaultValue={opt.sp}
                              placeholder={"SP"}
                              onChange={(e) => {
                                handleChange("sp", e.target.value, index);
                              }}
                              allowClear={false}
                              onFocus={(e) => handleFocus(e, "sp")}
                              onBlur={(e) => handleBlur(e, "sp")}
                            />
                          </div>
                          <div
                            style={{
                              display: is_locked ? "none" : "flex",
                            }}
                          >
                            <DeleteIcon handleClick={() => removeItem(index)} selected={activeDelete === index} icon="can" />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: is_locked ? "none" : "flex",
                            justifyContent: "space-between",
                            marginRight: 12,
                          }}
                        >
                          {opt.sp}
                          <DeleteIcon handleClick={() => removeItem(index)} selected={activeDelete === index} icon="can" />
                        </div>
                      ),
                  }))}
                  options={["time", "bp", "hr", "sp"]}
                  widths={Array.from({ length: 4 }).map((_, index) => {
                    return selectedColumn === -1 ? "25%" : selectedColumn === index ? "30%" : "23.33%";
                  })}
                  minRows={6}
                />
              </div>
            </div>
            <SidebarTitle icon={<Icon type="experiment" theme="twoTone" twoToneColor="#198038" />} title="MEDICATION ADMINISTRATION" />
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "1rem",
                paddingBottom: "1.5rem",
              }}
              ref={!isTimePicker && !isRoute && !isType ? refMed1 : null}
            >
              <div
                className="resourceInfo between flex-end"
                style={{ paddingRight: 0, marginTop: "0px", paddingTop: 0 }}
                ref={!isTimePicker && !isRoute && !isType ? refMed2 : null}
              >
                <AutoCompleteDataTable
                  isRmBorders={true}
                  isBorderThick={true}
                  isBorderOnHover={true}
                  title="condition-medications"
                  placeholder="Search Medications"
                  keyLabel={"name"}
                  keyValue={"id"}
                  keyType={"unit"}
                  disableDelete={true}
                  isResult={false}
                  isIcon={true}
                  loading={loading}
                  disableSearchIcon={false}
                  activeRow={activeMedRow}
                  onClickTableRow={(index) => setActiveMedRow(index)}
                  handleSearch={handleSearchMedication}
                  selectItem={selectItemMedication}
                  removeItem={removeRowMedication}
                  tableData={(medications || []).map((row, index) => {
                    return {
                      time:
                        activeMedRow === index || !row?.time ? (
                          <div className="vitals-first-col">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                paddingRight: "10px",
                              }}
                            >
                              <div style={{ flex: 1, paddingRight: "10px" }}>
                                <Select
                                  disabled={is_locked}
                                  className={validateField("text", row?.type) ? "input-error" : ""}
                                  suffixIcon={<ExpandSVG />}
                                  allowClear={true}
                                  value={row?.type || undefined}
                                  style={{
                                    maxWidth: isReSize ? 120 : 75,
                                    width: isReSize ? "calc(100% + 50px)" : "100%",
                                    zIndex: isReSize ? 1 : 0,
                                    overflow: "hidden",
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: validateField("text", row?.type) ? "rgba(255, 0, 0, 0.3)" : "#fff",
                                  }}
                                  onChange={(val) => {
                                    handleMedicationChange("type", val, index);
                                  }}
                                  onDropdownVisibleChange={(status) => {
                                    setIsReSize(status);
                                  }}
                                  onFocus={() => setIsType(true)}
                                  onBlur={() => setIsType(false)}
                                >
                                  {["pre", "intra", "post", "discharge"].map((r, index) => {
                                    return (
                                      <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                        {r}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <TimePicker
                              disabled={is_locked}
                              ref={timeRef2}
                              format={"h:mm A"}
                              use12Hours
                              onOpenChange={(status) => {
                                if (status) timeRef2.current.timePickerRef.picker.style.setProperty("border", "2px solid #0f62fe", "important");
                                else timeRef2.current.timePickerRef.picker.style.setProperty("border", "none", "important");
                                setIsTimePicker(status);
                              }}
                              placeholder="Time"
                              style={{
                                maxWidth: "calc(100% - 5px)",
                                width: "calc(100% - 5px)",
                                marginRight: "5px",
                                background: "transparent",
                              }}
                              suffixIcon={<span />}
                              value={
                                row?.time && moment(row?.time).format("YYYY-MM-DD h:mm A") !== "Invalid date"
                                  ? moment(row?.time, "YYYY-MM-DD h:mm A")
                                  : moment()
                              }
                              onChange={(value) => {
                                handleMedicationChange("time", value && value !== "Invalid date" ? value : null, index);
                              }}
                            />
                          </div>
                        ) : (
                          <div className="vitals-first-col">
                            <div className="icon-wrapper">{renderIcons(row?.type)}</div>
                            {row?.time ? moment(row?.time).format("LT") : null}
                          </div>
                        ),
                      name: row?.name,
                      dose:
                        activeMedRow === index || !row?.dose ? (
                          <Input
                            disabled={is_locked}
                            ref={doseRef}
                            className={validateField("text", row?.dose) || !row?.dose || row?.dose === "" ? "input-error" : ""}
                            style={{
                              width: "calc(100% - 5px)",
                              marginRight: "5px",
                              borderBottom: "1px solid #8d8d8d !important",
                              boxShadow: "none",
                            }}
                            value={row?.dose}
                            onChange={(e) => {
                              handleMedicationChange("dose", e.target.value, index);
                            }}
                            suffix={<span className="medicine-unit-suffix">{row?.drugs?.unit ? row?.drugs?.unit : ""}</span>}
                            allowClear={false}
                            onFocus={(e) => handleFocus(e, "dose")}
                            onBlur={(e) => handleBlur(e, "dose")}
                          />
                        ) : (
                          <span>
                            {row?.dose} <span className="medicine-unit-suffix">{row?.drugs?.unit ? row?.drugs?.unit : ""}</span>
                          </span>
                        ),
                      route:
                        activeMedRow === index || !row?.route ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              paddingRight: "10px",
                            }}
                          >
                            <div style={{ flex: 1, paddingRight: "10px" }}>
                              <Select
                                disabled={is_locked}
                                className={validateField("text", row?.route) ? "input-error" : ""}
                                suffixIcon={<ExpandSVG />}
                                allowClear={true}
                                value={row?.route || undefined}
                                placeholder={"Route"}
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: validateField("text", row?.route) ? "rgba(255, 0, 0, 0.3)" : "transparent",
                                }}
                                onChange={(val) => {
                                  handleMedicationChange("route", val, index);
                                }}
                                onFocus={() => setIsRoute(true)}
                                onBlur={() => setIsRoute(false)}
                              >
                                {["IV", "PO", "IM", "NC"].map((r, index) => {
                                  return (
                                    <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                      {r}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </div>
                            <div
                              style={{
                                display: is_locked ? "none" : "flex",
                              }}
                            >
                              <DeleteIcon
                                selected={activeMedDelete === index}
                                handleClick={() => {
                                  removeRowMedication("", index);
                                }}
                                icon="can"
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: is_locked ? "none" : "flex",
                              justifyContent: "space-between",
                              marginRight: 12,
                            }}
                          >
                            {row?.route}
                            <DeleteIcon
                              selected={activeMedDelete === index}
                              handleClick={() => {
                                removeRowMedication("", index);
                              }}
                              icon="can"
                            />
                          </div>
                        ),
                    };
                  })}
                  options={filteredMedications}
                  minRows={6}
                  isLocked={is_locked}
                  tableTitle="medication-administration"
                  tableTitleOptions={["Time", "Medication", "Dose", "Route"]}
                  tableOptions={["time", "name", "dose", "route"]}
                  tableWidths={["25%", "25%", "25%", "25%"]}
                  addItem={handleAddMedication}
                />
              </div>
            </div>
            <SidebarTitle icon={<Icon type="file-text" theme="twoTone" twoToneColor="#198038" />} title="DOCUMENTATION" />
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "1rem",
                paddingBottom: "5rem",
                marginTop: "5px",
              }}
            >
              {appointment?.appointment_type_id != 7 && appointment?.appointment_type_id != 10 && appointment?.appointment_type_id != 12 ? (
                <>
                  <div className="resourceInfo">
                    <EditableFormInput
                      label={"Total Radiation Time"}
                      value={totalRadiation}
                      isErrorTextSpacing={true}
                      unit={"sec"}
                      isError={!hasTotalRadiationTime}
                      enableErrorText={!hasTotalRadiationTime}
                      required={true}
                      helperText="Must Enter Total Radiation Time"
                      handleChange={(value) => {
                        handleSetValue(value, "total_radiation_time");
                      }}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo">
                    <EditableFormInput
                      label={"Dose area product (DAP)"}
                      value={totalDAP}
                      isErrorTextSpacing={true}
                      unit={"mGy"}
                      isError={!hasTotalDAP}
                      enableErrorText={!hasTotalDAP}
                      required={true}
                      helperText="Must Enter Dose area product (DAP)"
                      handleChange={(value) => {
                        handleSetValue(value, "dose_area_product");
                      }}
                      disabled={is_locked}
                    />
                  </div>
                  <div className="resourceInfo">
                    <EditableFormInput
                      label={"Total Contrast Load"}
                      value={totalContrast}
                      isErrorTextSpacing={true}
                      unit={"mL"}
                      isError={!hasTotalContrastLoad}
                      enableErrorText={!hasTotalContrastLoad}
                      required={true}
                      helperText="Must Enter Contrast Load"
                      handleChange={(value) => {
                        handleSetValue(value, "total_contrast_load");
                      }}
                      disabled={is_locked}
                    />
                  </div>
                </>
              ) : null}
              <div className="resourceInfo">
                <EditableFormInput
                  label={"Complication"}
                  value={complication}
                  isErrorTextSpacing={true}
                  isError={!hasComplication}
                  enableErrorText={!hasComplication}
                  required={true}
                  helperText="Must Select Complication"
                  handleChange={(value) => {
                    handleSetValue(value, "complication");
                  }}
                  type={"check"}
                  options={[
                    { label: "Yes", value: "Yes" },
                    { label: "No", value: "No" },
                  ]}
                  optionKey={"label"}
                  valueKey={"value"}
                  disabled={is_locked}
                />
              </div>
              <div className="resourceInfo">
                <EditableFormInput
                  label={"Qualified Healthcare Provider"}
                  value={qualifiedHealthCareProvider}
                  type={"check"}
                  isErrorTextSpacing={true}
                  options={activeStaff}
                  optionKey={"id"}
                  valueKey={"name"}
                  isError={!hasQualifiedHealthcareProvider}
                  enableErrorText={!hasQualifiedHealthcareProvider}
                  required={true}
                  helperText="Must Enter Qualified Healthcare Provider"
                  handleChange={(value) => {
                    handleSetValue(value, "qualified_healthcare_provider");
                  }}
                  disabled={is_locked}
                />
              </div>
            </div>
          </ScrollWrapper>
        </div>
      )}
    </Drawer>
  );
});

export default IntaOperativeSideBar;

import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const patientExaminationLoadInit = createActionThunk(AT.PATIENT_EXAMINATION_LOAD_INIT, (procedure_id) =>
  API.patientExaminationLoadInit(procedure_id)
);

export const patientExaminationSetData = createActionThunk(AT.PATIENT_EXAMINATION_SET_DATA, (scope, examinations, type) => ({
  scope,
  examinations,
  type,
}));

export const patientExaminationCopyLast = createActionThunk(AT.PATIENT_EXAMINATION_COPY_LAST, (patient_id, scope) =>
  API.patientExaminationCopyLast(patient_id, scope)
);

export const setVitalOG = createActionThunk(AT.SET_VITAL_OG, (value) => ({
  value,
}));

export const setCopyFrom = createActionThunk(AT.SET_COPY_FROM, (value, scope) => ({
  value,
  scope,
}));

export const patientExaminationSetNomalCheck = createActionThunk(AT.PATIENT_EXAMINATION_SET_NOMAL_CHECK, (value, type) => ({ value, type }));

export const patientExaminationSave = createActionThunk(AT.PATIENT_EXAMINATION_SAVE, (procedure_id, data) =>
  API.patientExaminationSave(procedure_id, data)
);

export const save_chief_complaint = createActionThunk(AT.SAVE_CHIEF_COMPLAINT, (id, param) => API.save_chief_complaint(id, param));

export const save_appointment_notes = createActionThunk(AT.SAVE_APPOINTMENT_NOTES, (id, param) => API.save_appointment_notes(id, param));

export const resetVitalMedication = createActionThunk(AT.RESET_VITAL_MEDICATION, () => {});

export const clinic_copy_last = createActionThunk(AT.CLINIC_COPY_LAST, (id) => API.clinic_copy_last(id));
export const angio_copy_last = createActionThunk(AT.ANGIO_COPY_LAST, (id) => API.angio_copy_last(id));
export const update_copy_intra = createActionThunk(AT.UPDATE_COPY_INTRA, (status) => ({ status }));

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { useOutsideDetect } from "../../../common/utils";
import { getLocal } from "../../../helpers/Local";
import { getPatientAppointment, getPatients } from "../../../services/api.services";
import {
  deleteProcedure,
  getCalendarProcedureTypes,
  getUltrasounds,
  get_angio_type,
  get_location_provider,
  list_clinic_procedure_type,
  setDashboardAppointments,
  setGlobalLoading,
} from "../../../store/actions";

const AppointmentNew = ({ acctionClick, activeIndex, resetWhenDelete }) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const procedureData = useSelector((state) => state.common.procedureData);
  const untrasound_list = useSelector((state) => state.procedureDetail.untrasound_list);
  const angio_list = useSelector((state) => state.procedureDetail.angio_list);
  const patient = useSelector((state) => state.dashboard.appointments.patient);
  const appointmentsCheck = useSelector((state) => state.dashboard.appointments.appointments);
  const clinic_list = useSelector((state) => state.procedureDetail.list_clinic_type);
  const dispatch = useDispatch();
  const [openPanel, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [run, setRun] = useState([]);
  const patient_id = getLocal("current_patient_id");
  const providersAll = useSelector((state) => state.common.providers);
  const locationsAll = useSelector((state) => state.common.locations);
  const [angioList, setAngioList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [activeSelect, setActiveSelect] = useState(-1);
  const [patients, setPatients] = useState([]);
  const [editable, setEditable] = useState(false);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const data = (appointmentsCheck || []).map((item) => ({
      ...item,
      date: item.appointment_date && moment(item.appointment_date).format("MM/DD/YYYY"),
      time: item.appointment_date && moment(item.appointment_date).format("h:mm A"),
    }));
    setAppointments(data || []);
  }, [appointmentsCheck]);

  const fetchAppointmentType = async () => {
    if (run && run[activeSelect] && activeSelect != -1 && editable) {
      if (run[activeSelect]["date"]) {
        const params = {
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
        };
        const dataSuccess = await dispatch(getCalendarProcedureTypes(params));
        let clinic = [];
        let angio = [];
        setClinicList(clinic);
        setAngioList(angio);
        if (dataSuccess && dataSuccess.clinic) {
          clinic = (dataSuccess.clinic || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "clinic",
              label: "Clinic Procedure",
            };
          });
          setClinicList(clinic);
        }
        if (dataSuccess && dataSuccess.obl) {
          angio = (dataSuccess.obl || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "angio",
              label: "OBL Procedure",
            };
          });
          setAngioList(angio);
        }
      }
    }
  };

  const mixDataSuccess = (dataSuccess) => {
    if (dataSuccess && dataSuccess?.location && dataSuccess?.time && dataSuccess?.provider) {
      run[activeSelect]["location"] = dataSuccess?.location;
      if ((dataSuccess?.location || []).some((r) => r?.id == appointments[activeSelect]["location_id"])) {
        run[activeSelect]["location_id"] = appointments[activeSelect]["location_id"] ?? null;
      } else {
        run[activeSelect]["location_id"] = null;
      }
      if ((dataSuccess?.provider || []).some((r) => r?.id == appointments[activeSelect]["provider_id"])) {
        run[activeSelect]["provider_id"] = appointments[activeSelect]["provider_id"] ?? null;
      } else {
        run[activeSelect]["provider_id"] = null;
      }
      run[activeSelect]["provider"] = dataSuccess?.provider;
      run[activeSelect] = {
        ...run[activeSelect],
        timeDisable: dataSuccess?.time,
      };
      if (run[activeSelect]["time"] && dataSuccess?.time.length > 0) {
        const timeCheck = moment(run[activeSelect]["time"], "HH:mm A").format("HH");
        if (dataSuccess?.time.some((check) => check == timeCheck)) {
          run[activeSelect]["time"] = undefined;
        }
      }
      const checkRun = run.map((r) => {
        return {
          ...r,
          location_name: r?.location && Array.isArray(r?.location) ? (r?.location).find((lc) => lc?.id == r.location_id) : {},
          provider_name: r?.provider && Array.isArray(r?.provider) ? (r?.provider).find((pr) => pr?.id == r.provider_id) : {},
        };
      });
      setAppointments([...checkRun]);
    }
  };

  const fetchApiGetLocationProvider = async () => {
    if (run && run[activeSelect] && activeSelect != -1 && editable) {
      if (
        run[activeSelect]["date"] &&
        run[activeSelect]["appointment_type_id"] &&
        run[activeSelect]["type"] &&
        !Array.isArray(run[activeSelect]["location"])
      ) {
        const params = {
          patient_id: patient_id,
          id: run[activeSelect]["appointment_type_id"],
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
          type: run[activeSelect]["type"] == "or" ? "hospital" : run[activeSelect]["type"],
        };
        const dataSuccess = await dispatch(get_location_provider(params));
        mixDataSuccess(dataSuccess);
      } else if (
        run[activeSelect]["date"] &&
        run[activeSelect]["appointment_type_id"] &&
        run[activeSelect]["type"] &&
        run[activeSelect]["location_id"] &&
        Array.isArray(run[activeSelect]["location"])
      ) {
        const params = {
          patient_id: patient_id,
          id: run[activeSelect]["appointment_type_id"],
          date: moment(run[activeSelect]["date"]).format("YYYY-MM-DD"),
          type: run[activeSelect]["type"] == "or" ? "hospital" : run[activeSelect]["type"],
          location_id: run[activeSelect]["location_id"],
        };
        const dataSuccess = await dispatch(get_location_provider(params));
        if (dataSuccess && dataSuccess?.location && dataSuccess?.time) {
          if ((dataSuccess?.provider || []).some((r) => r?.id == appointments[activeSelect]["provider_id"])) {
            run[activeSelect]["provider_id"] = appointments[activeSelect]["provider_id"] ?? null;
          } else {
            run[activeSelect]["provider_id"] = null;
          }
          run[activeSelect]["provider"] = dataSuccess?.provider;
          const checkRun = run.map((r) => {
            return {
              ...r,
              provider_name: r?.provider && Array.isArray(r?.provider) ? (r?.provider).find((pr) => pr?.id == r.provider_id) : {},
            };
          });
          setAppointments([...checkRun]);
        }
      } else {
        run[activeSelect]["location"] = null;
        run[activeSelect]["location_id"] = null;
        run[activeSelect]["provider"] = null;
        run[activeSelect]["provider_id"] = null;
        run[activeSelect] = {
          ...run[activeSelect],
          timeDisable: [],
        };
        setAppointments([...run]);
      }
    }
  };

  useEffect(() => {
    fetchApiGetLocationProvider();
    fetchAppointmentType();
  }, [activeSelect, run]);

  const [sortOptions, setSort] = useState([
    { name: "date", direction: "" },
    { name: "time", direction: "" },
    { name: "type", direction: "" },
    { name: "location", direction: "" },
    { name: "provider", direction: "" },
  ]);

  useEffect(() => {
    if (appointments && appointments[activeSelect]) {
      if (
        appointments[activeSelect]["location_id"] &&
        appointments[activeSelect]["location_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = locationsAll.find((r) => r?.id === appointments[activeSelect]["location_id"]);
        if (check && check.providers_unique) {
          if (check.type === "clinic") {
            setAngioList([]);
          } else if (check.type === "hospital") {
            setAngioList([]);
          } else if (check.type === "vhc") {
            setAngioList(angioList);
            setClinicList(clinicList);
          }
        }
      } else if (
        appointments[activeSelect]["provider_id"] &&
        appointments[activeSelect]["provider_id"] !== "" &&
        appointments[activeSelect]["type"] === ""
      ) {
        const check = providersAll.find((r) => r?.id === appointments[activeSelect]["provider_id"]);

        if (check && check.locations_unique) {
          const angio_find = (check.procedure_types || []).filter((r) => r.type === "angio");
          const angio = (angio_find || []).map((r) => {
            return {
              ...r,
              value: r?.name,
              type: "angio",
              label: "OBL Procedure",
            };
          });
          setAngioList(angio);
          setClinicList(clinic_list);
        }
      }
    }
  }, [appointments, procedureData, activeSelect]);

  useEffect(() => {
    const angio = (angio_list || []).map((r) => {
      return {
        ...r,
        value: r?.name,
        type: "angio",
        label: "OBL Procedure",
      };
    });
    const clinic = (clinic_list || []).map((r) => {
      return {
        ...r,
        value: r?.name,
        type: "clinic",
        label: "Clinic Procedure",
      };
    });
    setAngioList(angio);
    setClinicList(clinic);
  }, [untrasound_list, angio_list, procedureData, clinic_list]);

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(setDashboardAppointments("patient", null));
    dispatch(setDashboardAppointments("appointments", []));
    dispatch(getUltrasounds());
    dispatch(get_angio_type());
    dispatch(list_clinic_procedure_type());
  }, []);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      fetchPatients(search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2 && ref2.current) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const handleSort = (sorts) => {
    const newSorts = [...sortOptions];
    sorts.forEach((sort, index) => (newSorts[index].direction = sort));
    setSort(newSorts);
    fetchAppointments(getRequestParams(patient));
  };

  const fetchPatients = async (query = "") => {
    setLoading(true);
    const result = await getPatients(query);

    if (result && result?.length > 0) {
      setPatients(result);
      setLoading(false);
    } else {
      setPatients([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments({});
  }, []);

  const fetchAppointments = async (params) => {
    const result = await getPatientAppointment({
      columns: [],
      patient_id: patient_id,
      ...params,
      type: "hospital",
    });
    if (result) {
      const data = result.map((item) => ({
        ...item,
        date: item.appointment_date && moment(item.appointment_date).format("MM/DD/YYYY h:mm A"),
        time: item.appointment_date && moment(item.appointment_date).format("h:mm A"),
      }));
      setRun(data);
      dispatch(setDashboardAppointments("appointments", data));
    } else {
      setRun([]);
      dispatch(setDashboardAppointments("appointments", []));
    }
  };

  const getRequestParams = (record) => {
    const request = {
      patient_id: patient_id,
      appointment_date: moment().format("MM/DD/YYYY"),
      columns: sortOptions.filter((opt) => opt.direction),
    };
    return request;
  };

  const handleDeleteApt = async (row, index) => {
    dispatch(setGlobalLoading(true));
    await dispatch(deleteProcedure(row.id));
    await dispatch(fetchAppointments);
    resetWhenDelete && resetWhenDelete(row?.id);
    dispatch(setGlobalLoading(false));
  };

  return (
    <div className="div-app-new-appointment">
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: 10,
          marginTop: 10,
        }}
      ></div>
      <div className="patientSearch">
        <div className="fullContainer" style={{ padding: "1rem 0 0 0", position: "relative", flex: 1 }}>
          <DataTable
            isRmBorders={true}
            isShowBodyBorders={true}
            isRmHeaderBorder={true}
            isBorderThick={true}
            isHospitalCharting={true}
            isTableNew={true}
            disableDelete={false}
            title="appointment_manager"
            labels={["Date", "Appointment Type", "Location"]}
            widths={["30%", "35%", "35%"]}
            isNormal
            columns={[
              { sortable: true, key: "date" },
              { sortable: true, key: "appointment_type" },
              { sortable: true, key: "location" },
            ]}
            handleClickRow={(value, index) => {
              acctionClick && acctionClick(index, value);
            }}
            activeIndex={activeIndex}
            handleSort={handleSort}
            rowData={(appointments || []).map((item, index) => {
              return {
                key: item.key,
                alldata: item,
                id: item?.id,
                date: `${item?.date || null} ${item?.time || null}`,
                appointment_type: item?.procedure_name,
                location: (item?.location && item?.location?.value) || (item?.location_name && item?.location_name?.value),
              };
            })}
            emptyRows={new Array(3).fill(1)}
            sorts={[sortOptions[0].direction, sortOptions[1].direction, sortOptions[2].direction]}
            handleDelete={handleDeleteApt}
          />
          {(patient && appointments && appointments.length == 0) ||
            (!appointments && (
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  marginTop: 10,
                  transform: "translate(-50%, -50%)",
                }}
              >
                No Appointments
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AppointmentNew;

import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "src/helpers/Local";
import { useDebounce } from "use-lodash-debounce";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import { useOutsideDetect, useWindowSize } from "../../../common/utils";
import { createTaskManager, getPatients, getTaskManager } from "../../../services/api.services";
import { getActiveStaff, setDashboardDemographics, setGlobalLoading, setGlobalToastr, setTaskManager } from "../../../store/actions";

const { Search } = Input;

const MessageCenterSearch = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const patientDemographic = useSelector((state) => state.dashboard.patientDemographics);
  const loadingPatients = useSelector((state) => state.dashboard.loadingPatients);
  const loadingWideMess = useSelector((state) => state.dashboard.loadingWideMess);
  const activeStaff = useSelector((state) => state.common.activeStaff);
  const loadingAdd = useSelector((state) => state.dashboard.addingPatient);
  const loadingHistory = useSelector((state) => state.dashboard.loadingHistory);
  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({});
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [patients, setPatients] = useState([]);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const dataProvider = async () => {
    dispatch(getActiveStaff(null));
  };
  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dataProvider();
    dispatch(setDashboardDemographics(null));
  }, []);

  useEffect(() => {
    setState({
      ...patientDemographic,
    });
  }, [patientDemographic]);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      fetchPatients(search);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2 && ref2.current) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  useEffect(() => {
    if (loading || loadingPatients || loadingWideMess || loadingAdd || loadingHistory) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [loading, loadingPatients, loadingWideMess, loadingAdd, loadingHistory]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  const fetchPatients = async (query = "") => {
    setLoading(true);
    const result = await getPatients(query);
    if (result && result.length > 0) {
      setPatients(result);
      setLoading(false);
    } else {
      setPatients([]);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target?.value);
    setOpen(true);
  };

  const handleCreateMess = async () => {
    if (state?.message && state?.message.length > 0 && state?.urgency && state?.urgency.length && state?.staff) {
      dispatch(setGlobalLoading(true));
      const authId = await getLocal("cvai-current-user");
      await createTaskManager({
        provider_id: state?.staff,
        patient_id: state?.id,
        urgency: state?.urgency,
        message: state?.message,
        user_id: authId,
      });
      const data = await getTaskManager(authId);
      if (data?.data) {
        dispatch(setTaskManager(data?.data));
        setState({});
        dispatch(
          setGlobalToastr({
            header: "New Message",
            message: "Send Message Success",
            type: "success",
          })
        );
      }
      dispatch(setGlobalLoading(false));
      dispatch(setDashboardDemographics({}));
      setSearch(null);
      setState({});
    } else {
      dispatch(
        setGlobalToastr({
          header: "New Message",
          message: "Please enter message, staff and urgency for the task",
          type: "failed",
        })
      );
    }
  };

  const handleSearchSelect = (row, index) => {
    setActiveSearch(index);
    setActiveSearchRecords(row);
    setSearch(`${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`);
    setOpen(false);
    handleAddHistory(row);
  };

  const handleAddHistory = async (searchRow = null) => {
    if (activeSearchRow) {
      dispatch(setDashboardDemographics(activeSearchRow));
      setActiveSearchRecords(null);
    } else if (searchRow) {
      dispatch(setGlobalLoading(true));
      dispatch(
        setDashboardDemographics({
          ...searchRow,
          staff: "",
        })
      );
      dispatch(setGlobalLoading(false));
      setActiveSearchRecords(null);
    }
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      dispatch(setDashboardDemographics(activeSearchRow));
      setActiveSearchRecords(null);
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (rowData && rowData[activeSearch + 1]) {
        setActiveSearchRecords(rowData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      setActiveSearch(activeSearch - 1);
      setActiveSearchRecords(rowData[activeSearch - 1]);
    }
  };

  const handleState = (value, type) => {
    setState({
      ...state,
      [type]: value,
    });
  };

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div ref={ref} className="patientSearch__filter-search">
          <Search
            value={search}
            placeholder="Search Patient"
            onChange={handleSearch}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
            onFocus={() => setOpen(true)}
          />
        </div>
      </div>
      {openPanel && search && rowCount !== NaN && rowData?.length > 0 && (
        <div ref={ref1} className="fullContainer patientSearch__result" style={rowData?.length <= rowCount - 1 ? { height: "auto" } : {}}>
          <div
            className="tableSection"
            style={{
              height: rowData?.length <= rowCount - 1 ? "auto" : "100%",
              position: "relative",
            }}
          >
            {loading && <Loading />}
            <div className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll} disableScroll={rowData?.length <= rowCount - 1}>
                <div className="tableList">
                  {rowData?.map((row, index) => (
                    <div
                      className={`tableItem ${index === activeSearch ? "active" : ""}`}
                      key={`resultTable-${index}`}
                      onClick={() => handleSearchSelect(row, index)}
                    >
                      <div
                        className="td with-icon"
                        style={{
                          width: "100%",
                          paddingLeft: "calc(1.75rem - 2px)",
                        }}
                      >
                        <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                          row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                        }`}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      )}
      <div className="fullContainer" style={{ padding: "1rem 0 0 0", position: "relative" }}>
        <div ref={ref2} className="demographicSection" style={{ width: "100%", height: "100%" }}>
          <ScrollWrapper css="no-padding x-hidden">
            {state && Object.keys(state).length > 0 && (
              <div style={{ width: "100%", paddingTop: "1rem" }}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput label="Patient Name" value={`${state?.first_name} ${state?.last_name}`} fullWidth />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    label="DOB"
                    handleChange={(e) => handleState(e, "date_of_birth")}
                    value={state?.date_of_birth && moment(state?.date_of_birth).format("MM/DD/YYYY")}
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    type={"check"}
                    label="Staff"
                    value={state?.staff}
                    handleChange={(e) => handleState(e, "staff")}
                    fullWidth
                    valueKey="name"
                    optionKey="id"
                    options={activeStaff || []}
                    isError={state?.staff ? false : true}
                    required={true}
                    enableErrorText={true}
                    helperText={"Staff is Required"}
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    type={"check"}
                    label="Urgency"
                    value={state?.urgency}
                    handleChange={(e) => handleState(e, "urgency")}
                    fullWidth
                    valueKey="label"
                    optionKey="value"
                    options={[
                      {
                        label: "Emergent",
                        value: "emergent",
                      },
                      {
                        label: "Urgent",
                        value: "urgent",
                      },
                      {
                        label: "Routine",
                        value: "routine",
                      },
                    ]}
                    isError={state?.urgency && state?.urgency?.length ? false : true}
                    required={true}
                    enableErrorText={true}
                    helperText={"Urgency is Required"}
                  />
                </div>
                <div style={{ marginBottom: "0.5rem" }}>
                  <EditableFormInput
                    isChangeFontSize={true}
                    type={"area"}
                    value={state?.message}
                    handleChange={(e) => handleState(e, "message")}
                    label="Message"
                    fullWidth
                    rows={3}
                    isError={state?.message && state?.message?.length ? false : true}
                    required={true}
                    enableErrorText={true}
                    helperText={"Message is Required"}
                  />
                </div>
                <div className="fullContainer__actions" style={{ paddingTop: "1rem" }}>
                  <button onClick={handleCreateMess} className="common-btn blue-btn">
                    Send
                  </button>
                </div>
              </div>
            )}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default MessageCenterSearch;

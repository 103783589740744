import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoCompleteComponent from "../../../common/components/AutoComplete";
import { save_patient_notes, delete_patient_notes } from "../../../services/api.services";
import { get_patient_notes } from "../../../store/actions";
import { getLocal } from "../../../helpers/Local";

const NotesComponent = ({ minRows }) => {
  const resource = useSelector((state) => state.procedure.resource);
  const current_patient_id = getLocal("cvai-current-user");
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const fetchNotes = async () => {
    const result = await dispatch(get_patient_notes(resource.patient?.id));
    const newRows = (result || []).map((notes) => {
      return { note: `${notes?.notes}`, name: `${notes?.user?.name}` };
    });
    const newRowID = (result || []).map((notes) => `${notes?.id}`);
    setRows(newRows);
    setRowID(newRowID);
  };

  useEffect(() => {
    if (resource && resource.patient) {
      fetchNotes();
    }
  }, []);

  const handleSearch = (search) => {
    setSearchInput(search);
  };

  const addRow = () => {
    const newRows = [...rows, { label: searchInput }];
    setRows(newRows);
  };

  const removeRow = async (item, rowIndex) => {
    if (!rowID[rowIndex]) {
      setRows([]);
      setRowID([]);
      setSearchInput("");
    } else {
      await delete_patient_notes(rowID[rowIndex]);
      await fetchNotes();
    }
  };

  const addNote = async () => {
    if (resource && resource.patient) {
      const formData = new FormData();
      formData.append("notes", searchInput);
      formData.append("patient_id", resource.patient?.id);
      formData.append("user_id", current_patient_id);
      await save_patient_notes(formData);
      await fetchNotes();
    }
  };

  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <AutoCompleteComponent
          title="previous-medical"
          rows={rows}
          twoColum={true}
          keyLabel="note"
          keyType="name"
          removeItem={removeRow}
          addItem={addRow}
          handleSearch={handleSearch}
          placeholder={"Add Information Note"}
          disableSearchIcon={true}
          disableOptions={true}
          selectItem={addNote}
          minRows={minRows || 8}
        />
      </div>
    </div>
  );
};

export default NotesComponent;

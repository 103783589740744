import { Drawer } from "antd";
import React from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";

const EvaluationsSidebar = React.forwardRef(({ resource, isShow, evaluationsTagertDetail, handleCloseSidebar, currentUserInfo }, ref) => {
  if (!currentUserInfo || !evaluationsTagertDetail) {
    return null;
  }

  return (
    <Drawer
      title={currentUserInfo?.name + (currentUserInfo.position && "(" + currentUserInfo.position + ")") || " "}
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex" style={{ marginBottom: "1rem" }}>
              <div>
                Employee evaluations are a means to monitor progress and methods of evaluations within the organization. Employee evaluations are
                utilized for promotion and assessment of raises or bonuses. Improvements in employment metrics are a key determinent of raises and
                bonuses and will be directly utilized on a calculation to determine end of year increases. Raises and bonuses are not guaranteed.
                Performance improvement and evaluations are reset at the beginning of the year with respect to raises and bonuses.
              </div>
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label="Summary" value={evaluationsTagertDetail.summary} type="area" minRows={8} fullWidth />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label="Facts" value={evaluationsTagertDetail.facts} type="area" minRows={8} fullWidth />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label="Corrective Actions" value={evaluationsTagertDetail.corrective_actions} type="area" minRows={8} fullWidth />
            </div>
          </div>

          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button>Save</button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default EvaluationsSidebar;

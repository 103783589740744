import {createReducer} from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  inventories: [],
  supplies: [],
  narcotics: [],
  narcoticReason: [],
  purchaseList: [],
  purchase: null,
  loadingAPI: false,
  loadingCostHistory: false,
  addingToPurchase: false,
  isUpdated: false,
  isDeleted: false,
  inventory: null,
  inventoryCostHistory: [],
  inventoryProducts: [],
  inventoryLocations: [],
  specificLocations: [],
  inventoryFilter: {
    product_number: undefined,
    location: undefined,
  },
  orderFilter: {},
  receivings: [],
  returnCredits: [],
  histories: [],
  history: null,
  returnCredit: null,
  receivingOrder: null,
  ordered: false,
  users: [],
};

const SET_INVENTORY_DATA_SUCCEEDED = `${AT.SET_INVENTORY_DATA}_SUCCEEDED`;
// Get Inventories on inventory search section
const GET_INVENTORY_SEARCH_STARTED = `${AT.GET_INVENTORY_SEARCH}_STARTED`;
const GET_INVENTORY_SEARCH_SUCCEEDED = `${AT.GET_INVENTORY_SEARCH}_SUCCEEDED`;
const GET_INVENTORY_PRODUCTS_SUCCEEDED = `${AT.GET_INVENTORY_PRODUCTS}_SUCCEEDED`;
const GET_INVENTORY_LOCATIONS_SUCCEEDED = `${AT.GET_INVENTORY_LOCATIONS}_SUCCEEDED`;
const GET_INVENTORY_SPECIFIC_LOCATIONS_SUCCEEDED = `${AT.GET_INVENTORY_SPECIFIC_LOCATIONS}_SUCCEEDED`;
// Add New Inventory
const ADD_NEW_INVENTORY_STARTED = `${AT.ADD_NEW_INVENTORY}_STARTED`;
const ADD_NEW_INVENTORY_SUCCEEDED = `${AT.ADD_NEW_INVENTORY}_SUCCEEDED`;
// Update Inventory Item
const UPDATE_INVENTORY_ITEM_STARTED = `${AT.UPDATE_INVENTORY_ITEM}_STARTED`;
const UPDATE_INVENTORY_ITEM_SUCCEEDED = `${AT.UPDATE_INVENTORY_ITEM}_SUCCEEDED`;
// Angiographic Supplies
const GET_ANGIOGRAPHIC_SUPPLIES_STARTED = `${AT.GET_ANGIOGRAPHIC_SUPPLIES}_STARTED`;
const GET_ANGIOGRAPHIC_SUPPLIES_SUCCEEDED = `${AT.GET_ANGIOGRAPHIC_SUPPLIES}_SUCCEEDED`;
// Narcotic Inventories
const GET_NARCOTIC_INVENTORIES_STARTED = `${AT.GET_NARCOTIC_INVENTORIES}_STARTED`;
const GET_NARCOTIC_INVENTORIES_SUCCEEDED = `${AT.GET_NARCOTIC_INVENTORIES}_SUCCEEDED`;
// Get Inventory Cost History
const GET_INVENTORY_COST_HISTORY_STARTED = `${AT.GET_INVENTORY_COST_HISTORY}_STARTED`;
const GET_INVENTORY_COST_HISTORY_SUCCEEDED = `${AT.GET_INVENTORY_COST_HISTORY}_SUCCEEDED`;
// Add Inventory Cost History
const ADD_INVENTORY_COST_HISTORY_STARTED = `${AT.ADD_INVENTORY_COST_HISTORY}_STARTED`;
const ADD_INVENTORY_COST_HISTORY_SUCCEEDED = `${AT.ADD_INVENTORY_COST_HISTORY}_SUCCEEDED`;
// Add Inventory to Purchase List
const ADD_TO_PURCHASE_LIST_STARTED = `${AT.ADD_TO_PURCHASE_LIST}_STARTED`;
const ADD_TO_PURCHASE_LIST_SUCCEEDED = `${AT.ADD_TO_PURCHASE_LIST}_SUCCEEDED`;
// Get Purchase List
const GET_PURCHASE_LIST_STARTED = `${AT.GET_PURCHASE_LIST}_STARTED`;
const GET_PURCHASE_LIST_SUCCEEDED = `${AT.GET_PURCHASE_LIST}_SUCCEEDED`;
// Update Angiographic Supply
const UPDATE_ANGIOGRAPHIC_SUPPLY_STARTED = `${AT.UPDATE_ANGIOGRAPHIC_SUPPLY}_STARTED`;
const UPDATE_ANGIOGRAPHIC_SUPPLY_SUCCEEDED = `${AT.UPDATE_ANGIOGRAPHIC_SUPPLY}_SUCCEEDED`;
// Order Purchase Item
const ORDER_PURCHASE_ITEM_STARTED = `${AT.ORDER_PURCHASE_ITEM}_STARTED`;
const ORDER_PURCHASE_ITEM_SUCCEEDED = `${AT.ORDER_PURCHASE_ITEM}_SUCCEEDED`;
// Get Receiving Orders
const GET_RECEIVING_ORDERS_STARTED = `${AT.GET_RECEIVING_ORDERS}_STARTED`;
const GET_RECEIVING_ORDERS_SUCCEEDED = `${AT.GET_RECEIVING_ORDERS}_SUCCEEDED`;
// Update Receiving Order
const UPDATE_RECEIVING_ORDER_STARTED = `${AT.UPDATE_RECEIVING_ORDER}_STARTED`;
const UPDATE_RECEIVING_ORDER_SUCCEEDED = `${AT.UPDATE_RECEIVING_ORDER}_SUCCEEDED`;

const INVENTORY_ORDER_ITEM_RECEIVED_STARTED = `${AT.INVENTORY_ORDER_ITEM_RECEIVED}_STARTED`;
const INVENTORY_ORDER_ITEM_RECEIVED_SUCCEEDED = `${AT.INVENTORY_ORDER_ITEM_RECEIVED}_SUCCEEDED`;

const INVENTORY_ORDER_CREDIT_RECEIVED_STARTED = `${AT.INVENTORY_ORDER_CREDIT_RECEIVED}_STARTED`;
const INVENTORY_ORDER_CREDIT_RECEIVED_SUCCEEDED = `${AT.INVENTORY_ORDER_CREDIT_RECEIVED}_SUCCEEDED`;

const INVENTORY_ORDER_SHIPPED_STARTED = `${AT.INVENTORY_ORDER_SHIPPED}_STARTED`;
const INVENTORY_ORDER_SHIPPED_SUCCEEDED = `${AT.INVENTORY_ORDER_SHIPPED}_SUCCEEDED`;
// Get Return Credit Orders
const GET_RETURN_CREDIT_ORDERS_STARTED = `${AT.GET_RETURN_CREDIT_ORDERS}_STARTED`;
const GET_RETURN_CREDIT_ORDERS_SUCCEEDED = `${AT.GET_RETURN_CREDIT_ORDERS}_SUCCEEDED`;

// Get All Orders
const GET_ALL_ORDERS_STARTED = `${AT.GET_ALL_ORDERS}_STARTED`;
const GET_ALL_ORDERS_SUCCEEDED = `${AT.GET_ALL_ORDERS}_SUCCEEDED`;

// Update Narcotic Inventory
const UPDATE_NARCOTIC_INVENTORY_STARTED = `${AT.UPDATE_NARCOTIC_INVENTORY}_STARTED`;
const UPDATE_NARCOTIC_INVENTORY_SUCCEEDED = `${AT.UPDATE_NARCOTIC_INVENTORY}_SUCCEEDED`;

// Delete Inventory Item
const DELETE_INVENTORY_ITEM_STARTED = `${AT.DELETE_INVENTORY_ITEM}_STARTED`;
const DELETE_INVENTORY_ITEM_SUCCEEDED = `${AT.DELETE_INVENTORY_ITEM}_SUCCEEDED`;

// Update Inventory notes
const UPDATE_INVENTORY_NOTES_STARTED = `${AT.UPDATE_INVENTORY_NOTES}_STARTED`;
const UPDATE_INVENTORY_NOTES_SUCCEEDED = `${AT.UPDATE_INVENTORY_NOTES}_SUCCEEDED`;

// Delete Purchase Item
const DELETE_PURCHASE_ITEM_STARTED = `${AT.DELETE_PURCHASE_ITEM}_STARTED`;
const DELETE_PURCHASE_ITEM_SUCCEEDED = `${AT.DELETE_PURCHASE_ITEM}_SUCCEEDED`;

// Delete Receiving Order
const DELETE_RECEIVING_ORDER_STARTED = `${AT.DELETE_RECEIVING_ORDER}_STARTED`;
const DELETE_RECEIVING_ORDER_SUCCEEDED = `${AT.DELETE_RECEIVING_ORDER}_SUCCEEDED`;

// Get Inventory Users
const GET_INVENTORY_USERS_SUCCEEDED = `${AT.GET_INVENTORY_USERS}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [SET_INVENTORY_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...action?.payload,
    });
  },

  [GET_INVENTORY_USERS_SUCCEEDED](state, action) {
    const result = action?.payload;
    return Object.assign({}, state, {
      users: result || [],
    });
  },

  [GET_INVENTORY_SPECIFIC_LOCATIONS_SUCCEEDED](state, action) {
    const result = action?.payload;
    return Object.assign({}, state, {
      specificLocations: result || [],
    });
  },

  [GET_PURCHASE_LIST_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_PURCHASE_LIST_SUCCEEDED](state, action) {
    const {narcotic, others, supplies} = action?.payload || {};
    return Object.assign({}, state, {
      purchaseList: [...narcotic, ...others, ...supplies],
      loadingAPI: false,
    });
  },

  [ORDER_PURCHASE_ITEM_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
      ordered: false,
    });
  },
  [ORDER_PURCHASE_ITEM_SUCCEEDED](state, action) {
    return Object.assign({}, state, {loadingAPI: false, ordered: true});
  },

  [GET_RECEIVING_ORDERS_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_RECEIVING_ORDERS_SUCCEEDED](state, action) {
    const {narcotic, others, supplies} = action?.payload || {};
    return Object.assign({}, state, {
      loadingAPI: false,
      receivings: [...narcotic, ...others, ...supplies],
    });
  },

  [GET_RETURN_CREDIT_ORDERS_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_RETURN_CREDIT_ORDERS_SUCCEEDED](state, action) {
    const {narcotic, others, supplies} = action?.payload || {};
    return Object.assign({}, state, {
      loadingAPI: false,
      returnCredits: [...narcotic, ...others, ...supplies],
    });
  },

  [GET_ALL_ORDERS_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_ALL_ORDERS_SUCCEEDED](state, action) {
    const {narcotic, others, supplies} = action?.payload || {};
    return Object.assign({}, state, {
      loadingAPI: false,
      histories: [...narcotic, ...others, ...supplies],
    });
  },

  [UPDATE_RECEIVING_ORDER_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [UPDATE_RECEIVING_ORDER_SUCCEEDED](state, action) {
    return Object.assign({}, state, {loadingAPI: false, isUpdated: true});
  },

  [INVENTORY_ORDER_ITEM_RECEIVED_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [INVENTORY_ORDER_ITEM_RECEIVED_SUCCEEDED](state, action) {
    return Object.assign({}, state, {loadingAPI: false, isUpdated: true});
  },

  [INVENTORY_ORDER_CREDIT_RECEIVED_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [INVENTORY_ORDER_CREDIT_RECEIVED_SUCCEEDED](state, action) {
    return Object.assign({}, state, {loadingAPI: false, isUpdated: true});
  },

  [INVENTORY_ORDER_SHIPPED_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [INVENTORY_ORDER_SHIPPED_SUCCEEDED](state, action) {
    return Object.assign({}, state, {loadingAPI: false, isUpdated: true});
  },

  [GET_INVENTORY_SEARCH_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_INVENTORY_SEARCH_SUCCEEDED](state, action) {
    const {others, supplies, narcotic} = action?.payload || {};
    return Object.assign({}, state, {
      inventories: [...others, ...supplies, ...narcotic],
      loadingAPI: false,
    });
  },
  [ADD_NEW_INVENTORY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [ADD_NEW_INVENTORY_SUCCEEDED](state, action) {
    const {result, cost_history, type} = action?.payload || {};
    const newState = {
      inventory: result,
      inventoryCostHistory: [...state.inventoryCostHistory, cost_history],
      loadingAPI: false,
      isUpdated: true,
    };
    if (type === 'angiographic') {
      newState['supplies'] = [result, ...state.supplies];
    } else {
      newState['inventories'] = [result, ...state.inventories];
    }
    return Object.assign({}, state, newState);
  },
  [UPDATE_INVENTORY_ITEM_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [UPDATE_INVENTORY_ITEM_SUCCEEDED](state, action) {
    const {inventory} = action?.payload || {};
    const inventories = [...state.inventories];
    const existingIndex = state.inventories.findIndex(
        (inv) => inv?.id === inventory?.id,
    );
    if (existingIndex > -1) {
      inventories[existingIndex] = inventory;
    }
    return Object.assign({}, state, {
      inventory,
      inventories,
      loadingAPI: false,
      isUpdated: true,
    });
  },

  [UPDATE_ANGIOGRAPHIC_SUPPLY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [UPDATE_ANGIOGRAPHIC_SUPPLY_SUCCEEDED](state, action) {
    const {inventory} = action?.payload || {};
    const supplies = [...state.supplies];
    const existingIndex = state.supplies.findIndex(
        (inv) => inv?.id === inventory?.id,
    );
    if (existingIndex > -1) {
      supplies[existingIndex] = inventory;
    }
    return Object.assign({}, state, {
      inventory,
      supplies,
      loadingAPI: false,
      isUpdated: true,
    });
  },

  [UPDATE_NARCOTIC_INVENTORY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [UPDATE_NARCOTIC_INVENTORY_SUCCEEDED](state, action) {
    const {inventory} = action?.payload || {};
    const narcotics = [...state.narcotics];
    const existingIndex = state.narcotics.findIndex(
        (inv) => inv?.id === inventory?.id,
    );
    if (existingIndex > -1) {
      narcotics[existingIndex] = inventory;
    }
    return Object.assign({}, state, {
      inventory,
      narcotics,
      loadingAPI: false,
      isUpdated: true,
    });
  },

  [GET_INVENTORY_PRODUCTS_SUCCEEDED](state, action) {
    const inventoryProducts = action?.payload;
    return Object.assign({}, state, {inventoryProducts});
  },
  [GET_INVENTORY_LOCATIONS_SUCCEEDED](state, action) {
    const inventoryLocations = action?.payload;
    return Object.assign({}, state, {inventoryLocations});
  },

  [GET_INVENTORY_COST_HISTORY_STARTED](state, action) {
    return Object.assign({}, state, {loadingCostHistory: true});
  },
  [GET_INVENTORY_COST_HISTORY_SUCCEEDED](state, action) {
    const inventoryCostHistory = action?.payload;
    return Object.assign({}, state, {
      loadingCostHistory: false,
      inventoryCostHistory,
    });
  },
  [ADD_INVENTORY_COST_HISTORY_STARTED](state, action) {
    return Object.assign({}, state, {loadingCostHistory: true});
  },
  [ADD_INVENTORY_COST_HISTORY_SUCCEEDED](state, action) {
    const newHistory = action?.payload;
    return Object.assign({}, state, {
      loadingCostHistory: false,
      inventoryCostHistory: [...state.inventoryCostHistory, newHistory],
    });
  },

  [ADD_TO_PURCHASE_LIST_STARTED](state, action) {
    return Object.assign({}, state, {addingToPurchase: true});
  },
  [ADD_TO_PURCHASE_LIST_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      addingToPurchase: false,
    });
  },

  [GET_ANGIOGRAPHIC_SUPPLIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_ANGIOGRAPHIC_SUPPLIES_SUCCEEDED](state, action) {
    const {results} = action?.payload || {};
    return Object.assign({}, state, {supplies: results, loadingAPI: false});
  },
  [GET_NARCOTIC_INVENTORIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: true,
    });
  },
  [GET_NARCOTIC_INVENTORIES_SUCCEEDED](state, action) {
    const {inventory, reasons} = action?.payload || {};
    return Object.assign({}, state, {
      narcotics: inventory,
      narcoticReason: reasons,
      loadingAPI: false,
    });
  },

  [DELETE_INVENTORY_ITEM_STARTED](state, action) {
    return Object.assign({}, state, {loadingAPI: true});
  },
  [DELETE_INVENTORY_ITEM_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },

  [UPDATE_INVENTORY_NOTES_STARTED](state, action) {
    return Object.assign({}, state, {loadingAPI: true});
  },
  [UPDATE_INVENTORY_NOTES_SUCCEEDED](state, action) {
    const {notes} = action?.payload || {};

    return Object.assign({}, state, {
      loadingAPI: false,
      isUpdated: true,
    });
  },

  [DELETE_PURCHASE_ITEM_STARTED](state, action) {
    return Object.assign({}, state, {loadingAPI: true});
  },
  [DELETE_PURCHASE_ITEM_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },

  [DELETE_RECEIVING_ORDER_STARTED](state, action) {
    return Object.assign({}, state, {loadingAPI: true});
  },
  [DELETE_RECEIVING_ORDER_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },
});

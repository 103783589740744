import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import Loading from "../../../common/components/Loading";
import { get_me_variable_rule, save_me_variable_rule, search_cpt_code, setProcedureDetails } from "../../../store/actions";

let timer = null;

const EstablishedPatientLogic = () => {
  const established_patients = useSelector((state) => state.procedureDetail.established_patients);
  const get_me_rules_established_patients = useSelector((state) => state.procedureDetail.get_me_rules_established_patients);
  const selectDataGetMe = useSelector((state) => state.procedureDetail.get_me_select_list);
  const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
  const selectData = useSelector((state) => state.common.searchCPT);

  const [page, setPage] = useState(1);
  const [selectSearch, setSelectSearch] = useState(null);
  const [first, setFirst] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [selectResult, setSelectResult] = useState([]);
  const [selectResultGetMe, setSelectResultGetMe] = useState([]);
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const dispatch = useDispatch();

  const handleChange = async (type, value, index, indexCPT) => {
    if (type === "cpt_code_id") {
      established_patients[indexCPT]["condition_serialize"][index]["cpt_code"]["code"] = value[1];
      established_patients[indexCPT]["condition_serialize"][index]["cpt_code"]["short_desc"] = value[2];
      established_patients[indexCPT]["cpt_code_id"] = value[0];
      await dispatch(setProcedureDetails("established_patients", [...established_patients]));
    }
    if (type == "question_id") {
      established_patients[indexCPT]["condition_serialize"][index]["answer"] = "";
    }
    if (established_patients && established_patients[indexCPT]) {
      established_patients[indexCPT]["condition_serialize"][index][type] = value === "THAN" && type === "operator" ? "THAN" : value;
      await dispatch(setProcedureDetails("established_patients", [...established_patients]));
    }
  };

  const handleChangeOperator = async (value, index, indexCPT) => {
    const nextIndex = index + 1;
    if (
      established_patients[indexCPT] &&
      established_patients[indexCPT]["condition_serialize"] &&
      established_patients[indexCPT]["condition_serialize"].length > 0
    ) {
      const plan = established_patients[indexCPT]["condition_serialize"][nextIndex];
      const paramNU = {
        question_id: plan && typeof plan["question_id"] != "undefined" ? plan["question_id"] : "",
        question_name: plan && typeof plan["question_name"] != "undefined" ? plan["question_name"] : "",
        answer: plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison: plan && typeof plan["comparison"] != "undefined" ? plan["comparison"] : ">=",
        operator: plan && typeof plan["operator"] != "undefined" ? plan["operator"] : "",
      };
      const paramUN = {
        question_id: undefined,
        question_name: undefined,
        cpt_code: { code: "", short_desc: "" },
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (established_patients && established_patients[indexCPT]) {
        if (value !== "THAN") {
          established_patients[indexCPT]["condition_serialize"][index + 1] = {
            ...paramNU,
          };
          await dispatch(setProcedureDetails("established_patients", [...established_patients]));
        } else {
          const abc = established_patients[indexCPT];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          established_patients[indexCPT]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("established_patients", [...established_patients]));
        }
      }
    }
  };

  useEffect(() => {
    setLocalLoading(true);
    setSelectResult([]);
    setPage(1);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(search_cpt_code(selectSearch, page));
      setLocalLoading(false);
    }, 1000);
  }, [selectSearch]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult(selectData);
    }
  }, [selectData]);

  useEffect(() => {
    setSelectResultGetMe(selectDataGetMe);
  }, [selectDataGetMe]);

  useEffect(() => {
    if (get_me_rules_established_patients && get_me_rules_established_patients.length > 0) {
      dispatch(setProcedureDetails("established_patients", [...get_me_rules_established_patients]));
    }
  }, [get_me_rules_established_patients]);

  const handleClickRow = (index) => {};

  const update = async () => {
    const arrayADD = established_patients.map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        type: "established_patient",
        condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
      };
    });
    await dispatch(
      save_me_variable_rule({
        rules: arrayADD,
        type: "established_patient",
      })
    );
  };

  useEffect(() => {
    if (first) {
      update();
    }
  }, [established_patients]);
  const fetchApi = async () => {
    await dispatch(get_me_variable_rule("established_patient"));
    setFirst(true);
  };
  useEffect(() => {
    fetchApi();
  }, []);

  const removeProvider = async (index, indexCPT) => {
    const checkCPTRow = established_patients[indexCPT];
    if (checkCPTRow && checkCPTRow["condition_serialize"] && checkCPTRow["condition_serialize"].length > 1) {
      if (checkCPTRow["condition_serialize"][index].operator === "THAN" || checkCPTRow["condition_serialize"][index].operator === "") {
        checkCPTRow["condition_serialize"].splice(index, 2);
      } else {
        checkCPTRow["condition_serialize"].splice(index, 1);
      }

      const check = checkCPTRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        established_patients[indexCPT]["condition_serialize"] = [...check];
      } else {
        established_patients.splice(indexCPT, 1);
      }

      await dispatch(setProcedureDetails("established_patients", [...established_patients]));
    } else {
      delete established_patients[indexCPT];
      const arrayJoin = [...established_patients].filter((r) => {
        if (r) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("established_patients", [...arrayJoin]));
      const arrayADD = arrayJoin.map((r) => {
        return {
          cpt_code_id: r?.cpt_code_id,
          type: "established_patient",
          condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
        };
      });
      await dispatch(
        save_me_variable_rule({
          rules: arrayADD,
          type: "established_patient",
        })
      );
    }
  };

  useEffect(() => {
    dispatch(get_me_variable_rule("established_patient"));
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {established_patients.map((itemCPT, indexCPT) => {
        return (
          <div
            key={indexCPT}
            style={{
              width: "100%",
              background: "rgb(219, 227, 238)",
              padding: "7px 0",
              marginBottom: "1rem",
            }}
          >
            <DataTable
              title="established_patient"
              hideHeader={true}
              sizeHeightFix={itemCPT?.condition_serialize && itemCPT?.condition_serialize.length > 1 ? itemCPT?.condition_serialize.length * 40 : 40}
              isFixHeight={true}
              isOverFlow={true}
              isICD={true}
              hideHeaderHeight="0px"
              handleClickRow={() => {
                handleClickRow(indexCPT);
              }}
              allActive={true}
              handleDelete={(value, index) => removeProvider(index, indexCPT)}
              parentIndex={indexCPT}
              handleActiveDelete={(val) => {
                const activeDeletes = [...activeDelete];
                activeDeletes[indexCPT] = val;
                if (removableParent !== indexCPT) {
                  activeDeletes[removableParent] = -1;
                  setRemovableParent(indexCPT);
                } else {
                  if (val === -1) setRemovableParent(-1);
                }
                setActiveDelete(activeDeletes);
              }}
              activeParent={activeDelete[indexCPT]}
              labels={["Question", "Option", "Answer", "Operator"]}
              widths={["35%", "15%", "20%", "30%"]}
              columns={[
                { sortable: false, key: "question" },
                { sortable: false, key: "option" },
                { sortable: false, key: "answer" },
                { sortable: false, key: "operator" },
              ]}
              rowData={(itemCPT?.condition_serialize || []).map((item, index) => {
                let arrayAnswer = [];
                const answerCheck = (itemCPT?.condition_serialize || []).find((r) => {
                  return r.question_id == item?.question_id;
                });
                if (answerCheck && answerCheck.question_id && answerCheck.question_id == item?.question_id) {
                  arrayAnswer = [...(answerCheck?.question_name?.answer || [])];
                }
                return {
                  key: index,
                  disableDelete: typeof item?.operator === "undefined" && true,
                  fullsize: typeof item?.operator === "undefined" && true,
                  question:
                    typeof item?.operator === "undefined" ? (
                      <Select
                        key={index}
                        showSearch
                        placeholder="Search CPT Code"
                        value={`${item?.cpt_code?.code} ${item?.cpt_code?.short_desc}`}
                        onSearch={(value) => {
                          setSelectSearch(value);
                        }}
                        onDropdownVisibleChange={(open) => {
                          setFocusState(open);
                        }}
                        onBlur={() => {
                          setSelectSearch(null);
                        }}
                        notFoundContent={
                          localLoading || loadingSearchCPT ? (
                            <div style={{ height: 100 }}>
                              <Loading />
                            </div>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        autoClearSearchValue={false}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        onChange={(value) => {
                          handleChange("cpt_code_id", value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(selectResult || []).map((r, index) => (
                          <Select.Option key={r?.id} value={focusState ? [r?.id, r.code, r.short_desc] : `${r.code} ${r.short_desc}`}>
                            {r.code} {r.short_desc}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        placeholder={"Question"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.question_id}
                        onChange={(value) => {
                          handleChange("question_id", value, index, indexCPT);
                          handleChange(
                            "question_name",
                            selectResultGetMe.find((r) => r?.id == value),
                            index,
                            indexCPT
                          );
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(selectResultGetMe || []).map((opt, index) => (
                          <Select.Option key={`question-${index}-${opt?.id}`} value={opt?.id}>
                            {opt.question}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  option:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Opt"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.comparison}
                        onChange={(value) => {
                          handleChange("comparison", value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["<=", "=", "!=", ">="].map((opt) => (
                          <Select.Option key={`option--${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  answer:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Answer"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={arrayAnswer && arrayAnswer.length > 0 ? false : true}
                        value={item?.answer}
                        autoClearSearchValue={false}
                        showSearch={arrayAnswer && arrayAnswer.length > 0 ? false : true}
                        showArrow={arrayAnswer && arrayAnswer.length > 0 ? true : false}
                        onSearch={(value) => {
                          if (value && value.length > 0) {
                            clearTimeout(timer);
                            timer = setTimeout(() => {
                              handleChange("answer", value, index, indexCPT);
                            }, 500);
                          }
                        }}
                        notFoundContent={null}
                        onChange={(value) => {
                          handleChange("answer", value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {arrayAnswer && arrayAnswer.length > 0
                          ? arrayAnswer.map((r) => (
                              <Select.Option key={`answer--${r?.id}`} value={r?.id}>
                                {r.answer}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    ),
                  operator:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Operator"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.operator}
                        onChange={(value) => {
                          handleChange("operator", value, index, indexCPT);
                          handleChangeOperator(value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["OR", "AND", "THAN"].map((opt) => (
                          <Select.Option key={`operator-${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                };
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EstablishedPatientLogic;

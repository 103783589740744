import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/DataTable";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { getCapitalizeString } from "../../../common/utils";
import { setLocal } from "../../../helpers/Local";
import {
  checkApointment,
  filterLocations,
  filterProviders,
  getPending,
  patient_insurances_id_insurances,
  selectPatient,
  setGlobalLoading,
  setPatientSnapshotData,
  setStepData,
} from "../../../store/actions";

const PendingTable = React.memo(({ title, search, selectedType, handleSidebar, handleTableSelect, getPendingCount, getPendingHasDocsCount }) => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [first, setFirst] = useState(false);
  const [offset, setOffset] = useState(0);
  const action_add_and_select = useSelector((state) => state.common.action_add_and_select);
  const data = useSelector((state) => state.patientPending.pending);
  const isAdded = useSelector((state) => state.patientPending.isAdded);
  const isSave = useSelector((state) => state.patientPending.isSave);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setStepData("loadingPending", true));
    dispatch(getPending({}));
  }, []);

  useEffect(() => {
    if (isAdded) {
      setRow(-1);
      handleTableSelect("pending", -1);
    }
  }, [data, isAdded]);

  useEffect(() => {
    if (isSave) {
      setRow(0);
      handleTableSelect("pending", 0);
      dispatch(setStepData("isSave", false));
    }
  }, [isSave]);

  const filterSuggestion = (suggestions, userInput) => {
    let pendingCount = 0;
    let pendingHasDocsCount = 0;
    if (!userInput) {
      suggestions.map((obj) => {
        if (!!obj?.chart_status) {
          pendingHasDocsCount++;
        } else {
          pendingCount++;
        }
      });
      getPendingCount(pendingCount);
      getPendingHasDocsCount(pendingHasDocsCount);
      return suggestions;
    }
    let filteredSuggestions = [];

    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        (suggestion?.first_name && suggestion?.first_name.toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1) ||
        (suggestion?.last_name && suggestion?.last_name.toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1) ||
        (suggestion?.first_name + suggestion?.last_name).toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1 ||
        (suggestion?.last_name + suggestion?.first_name).toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1
    );

    filteredSuggestions.map((obj) => {
      if (!!obj?.chart_status) {
        pendingHasDocsCount++;
      } else {
        pendingCount++;
      }
    });
    getPendingCount(pendingCount);
    getPendingHasDocsCount(pendingHasDocsCount);

    return filteredSuggestions;
  };

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search);
    }
    return [];
  }, [data, search]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  useEffect(() => {
    if (!action_add_and_select && first) {
      if (rowData && rowData.length > 0) {
        const patient_id = rowData[0].patient_id || rowData[0].id;
        if (patient_id) {
          setRow(0);
          setLocal("current_patient_id", patient_id);
          dispatch(setStepData("loadingResource", true));
          dispatch(selectPatient(patient_id));
          dispatch(filterLocations("", "clinic"));
          dispatch(filterProviders(patient_id));
          handleTableSelect("pending", 0);
          handleSidebar();
        }
      }
    } else {
      setFirst(true);
    }
  }, [action_add_and_select]);

  const handleRow = async (index) => {
    setRow(index);
    dispatch(setGlobalLoading(true));
    const patient_id = rowData[index].patient_id || rowData[index].id;
    setLocal("current_patient_id", patient_id);
    dispatch(setStepData("loadingResource", true));
    const check = await dispatch(selectPatient(patient_id));
    if (check) {
      await dispatch(setPatientSnapshotData("resource", check));
    }
    dispatch(filterLocations("", "clinic"));
    dispatch(filterProviders(patient_id));
    dispatch(checkApointment(false));
    dispatch(patient_insurances_id_insurances(patient_id));
    handleTableSelect("pending", index);
    dispatch(setGlobalLoading(false));
    handleSidebar();
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 30 && offset < tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
        <div className="tableList">
          <DataTable
            isXHidden={true}
            title="new_patient_pending"
            isNormal={true}
            labels={["Patient Name", "Problem", "Referral Date"]}
            widths={["34%", "38%", "28%"]}
            defaultActive={-1}
            activeIndex={activeIndex}
            disableDelete={true}
            columns={[
              { sortable: false, key: "name" },
              { sortable: false, key: "referral_condition" },
              { sortable: false, key: "referral_date" },
            ]}
            handleClickRow={(row, index) => {
              handleRow(index);
            }}
            rowData={(rowData || []).map((row, index) => {
              return {
                name: (
                  <div
                    className="iconField td with-icon"
                    style={{
                      display: "flex",
                      paddingLeft: "0.25rem",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Icon type="mail" theme="twoTone" twoToneColor={!!row?.chart_status ? "#d02670" : "#0f62fe"} />
                    <p style={{ paddingLeft: "0.75rem" }}>{`${getCapitalizeString(row?.first_name)} ${getCapitalizeString(row?.last_name)}`}</p>
                  </div>
                ),
                referral_condition: row?.referral_condition,
                referral_date: row?.referral_date && moment(row?.referral_date).format("MM/DD/YYYY"),
              };
            })}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
});

export default PendingTable;

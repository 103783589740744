import { Drawer, Icon, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteDataTable from "../../../../common/components/AutoComplete/AutoCompleteDataTable";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import ToastMessage from "../../../../common/components/Toast";
import DeleteIcon from "../../../../common/components/icons/DeleteIconButton";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { getCommaSeparatedValues, useOutsideDetect } from "../../../../common/utils";
import { getLocal } from "../../../../helpers/Local";
import { validateField } from "../../../../services/validations";
import { getNarcoticInventories, saveAngioMedicationVital } from "../../../../store/actions";
import { filterMedicationsByQuery } from "../../constant";

let timer = null;

const titleStyle = {
  width: "25%",
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  height: "100%",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
};

const DischargeSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, dataAll, value }, ref) => {
  const supplies = useSelector((state) => state.inventory.narcotics);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const is_locked = useSelector((state) => state.common.is_locked);
  const patientResource = useSelector((state) => state.clinicProvider?.patientResource);

  const dispatch = useDispatch();
  const [inputMedication, setInputMedication] = useState("");
  const [loading, setLoading] = useState(false);
  const [operatives, setOperatives] = useState([]);
  const [medications, setMedications] = useState([]);
  const [filteredMedications, setFilteredMedications] = useState([]);
  const [isToastr, setIsToastr] = useState(false);
  const [isTimePicker, setIsTimePicker] = useState(false);
  const [isRoute, setIsRoute] = useState(false);
  const [isType, setIsType] = useState(false);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [activeMedDelete, setActiveMedDelete] = useState(-1);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeMedRow, setActiveMedRow] = useState(-1);
  const [selectedColumn, setSelectedColumn] = useState(-1);
  const [isAddable, setIsAddable] = useState(true);
  const [isReSize, setIsReSize] = useState(false);

  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const debouncedSearch = useDebounce(inputMedication, 400);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const refMed1 = useRef(null);
  const refMed2 = useRef(null);

  useEffect(() => {
    dispatch(getNarcoticInventories(""));
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      const allInventories = filterMedicationsByQuery(supplies, debouncedSearch);
      setFilteredMedications(allInventories);
      setLoading(false);
    }
  }, [debouncedSearch]);

  const addOperative = () => {
    setOperatives([
      {
        time: moment(),
        bp: "",
        hr: "",
        sp: "",
        type: "discharge",
      },
      ...operatives,
    ]);
    setIsAddable(false);
    setActiveRow(0);
  };

  const handleChange = (type, value, index) => {
    const updatedOperatives = [...operatives];
    updatedOperatives[index][type] = value;
    setOperatives(updatedOperatives);
    callVitalUpdate(operatives);
  };

  const handleSearchMedication = (search) => {
    setInputMedication(search);
    if (search) {
      setInputMedication(search);
      setLoading(true);
    }
  };

  const saveAll = async () => {
    const saveSuccess = await dispatch(
      saveAngioMedicationVital({
        procedure_id: current_appointment_id,
        patient_id: current_patient_id,
        medication: medications,
        operatives: operatives,
      })
    );
    if (saveSuccess) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const selectItemMedication = (medicationName, medicationId, unit) => {
    if (medicationName === "") return;
    for (let medicationId_ of medications) {
      if (medicationId_ === medicationId) return;
    }

    let listItems = [...medications];
    listItems.unshift({
      id: medicationId,
      name: medicationName,
      time: moment(),
      dose: "",
      route: "",
      drugs: {
        unit,
      },
      type: "discharge",
    });
    setMedications(listItems);
  };

  const removeRowMedication = (medication, index) => {
    if (activeMedDelete !== index) {
      setActiveMedDelete(index);
    } else {
      const reMapMedication = [...(medications || [])].map((r) => {
        return {
          ...r,
          id: r?.drug_id,
        };
      });
      let listItems = [...reMapMedication];
      listItems.splice(index, 1);
      setActiveMedDelete(-1);
      callUpdate(listItems);
      setMedications(listItems);
    }
  };

  const handleMedicationChange = (type, value, index) => {
    const updatedMedications = medications?.map((med) => {
      return {
        ...med,
        id: med?.drug_id ? med?.drug_id : med.id,
      };
    });
    if (type === "dose") {
      updatedMedications[index][type] = getCommaSeparatedValues(value);
    } else {
      updatedMedications[index][type] = value;
    }
    const detailCheck = updatedMedications && updatedMedications[index];
    setMedications(updatedMedications);
    if (detailCheck && detailCheck["dose"] && detailCheck["route"] && detailCheck["time"]) {
      callUpdate(updatedMedications);
    }
  };

  const patientExamination = useSelector((state) => state.patientExamination);

  useEffect(() => {
    const medications = patientExamination?.medication_administration?.discharge;
    const vitalSigns = patientExamination?.vital_signs;
    setMedications(medications || []);
    setOperatives(vitalSigns || []);
  }, [patientExamination]);

  useEffect(() => {
    let mediName = [];
    let mediID = [];
    if (value && value.medication) {
      (value.medication || []).map((r) => {
        mediName.push(r.drug && r.drug?.name);
        mediID.push(r.drug && r.drug?.id);
      });
      setMedications(mediID);
    }
  }, [value]);

  const removeItem = (index) => {
    if (activeDelete !== index) {
      setActiveDelete(index);
    } else {
      const updatedOperatives = [...operatives];
      updatedOperatives.splice(index, 1);
      setOperatives(updatedOperatives);
      setActiveDelete(-1);
      callVitalUpdate(updatedOperatives);
    }
  };

  const callUpdate = async (valueMedication) => {
    if (valueMedication) {
      if (valueMedication.some((r) => r.dose == null || r.dose === "" || r.route == null)) {
      } else {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          const saveSuccess = await dispatch(
            saveAngioMedicationVital({
              procedure_id: current_appointment_id,
              patient_id: current_patient_id,
              medication: valueMedication,
              operatives: operatives,
            })
          );
          if (saveSuccess) {
            setIsToastr(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }, 500);
      }
    }
  };

  const callVitalUpdate = async (valueVital) => {
    if (valueVital) {
      if (valueVital.some((r) => r.bp == "" || r.hr == "" || r.sp == "" || r.time == "")) {
      } else {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          const saveSuccess = await dispatch(
            saveAngioMedicationVital({
              procedure_id: current_appointment_id,
              patient_id: current_patient_id,
              medication: medications,
              operatives: valueVital,
            })
          );
          if (saveSuccess) {
            setIsToastr(true);
            setIsAddable(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }, 500);
      }
    }
  };

  const handleAddMedication = () => {
    setMedications([
      {
        type: "discharge",
        time: moment().format("YYYY-MM-DD hh:mm:ss A"),
        name: "",
        dose: "",
        route: "",
      },
      ...medications,
    ]);
  };

  useOutsideDetect(ref1, ref2, activeRow !== -1, (_) => setActiveRow(-1));
  useOutsideDetect(refMed1, refMed2, activeMedRow !== -1, (_) => setActiveMedRow(-1));

  const getWidth = (index) => {
    return selectedColumn === -1
      ? index === 0
        ? "calc(25% - 3rem)"
        : "25%"
      : selectedColumn === 0 && index === 0
      ? "calc(30% - 3rem)"
      : selectedColumn !== 0 && index === 0
      ? "calc(23.33% - 3rem)"
      : selectedColumn === index
      ? "30%"
      : "23.33%";
  };

  const getTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            ...titleStyle,
            paddingLeft: "0",
            width: getWidth(0),
            marginLeft: "3rem",
          }}
        >
          Time
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(1),
          }}
        >
          BP
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(2),
          }}
        >
          HR
        </div>
        <div
          style={{
            ...titleStyle,
            width: getWidth(3),
          }}
        >
          Sp02 (%)
        </div>
      </div>
    );
  };

  const renderIcons = (type) => {
    let html;
    if (!type) {
      html = <span />;
    } else {
      if (type === "intra") {
        html = (
          <div style={{ width: "15px", height: "15px", display: "flex", alignItems: "center", marginRight: "10px" }}>
            <img src={require("../../../../common/components/icons/intra.svg")} alt="intraSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "post") {
        html = (
          <div style={{ width: "15px", height: "15px", display: "flex", alignItems: "center", marginRight: "10px" }}>
            <img src={require("../../../../common/components/icons/post.svg")} alt="postSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "pre") {
        html = (
          <div style={{ width: "15px", height: "15px", display: "flex", alignItems: "center", marginRight: "10px" }}>
            <img src={require("../../../../common/components/icons/pre.svg")} alt="preSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
      if (type === "discharge") {
        html = (
          <div
            style={{
              width: "15px",
              height: "15px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <img src={require("../../../../common/components/icons/discharge.svg")} alt="dischargeSvgIcon" style={{ width: "100%" }} />
          </div>
        );
      }
    }
    return html;
  };

  const { first_name, last_name, deceased, cancelled_reason } = patientResource;
  const hrRef = useRef(null);
  const spRef = useRef(null);
  const timeRef = useRef(null);
  const timeRef2 = useRef(null);
  const doseRef = useRef(null);

  const handleFocus = (event, name) => {
    event.preventDefault();
    let ref = "";
    if (name === "hr") ref = hrRef;
    if (name === "dose") ref = doseRef;
    if (name === "sp") ref = spRef;
    if (ref?.current && ref?.current?.input && ref?.current?.input?.parentNode?.className) {
      if (ref?.current?.input?.parentNode?.className && ref?.current?.input?.parentNode?.className.split(" ").includes("input-error")) return;
      ref.current.input.style.setProperty("border", "2px solid #0f62fe", "important");
    }
  };

  const handleBlur = (event, name) => {
    event.preventDefault();
    let ref = "";
    if (name === "hr") ref = hrRef;
    if (name === "time") ref = timeRef;
    if (name === "dose") ref = doseRef;
    if (name === "sp") ref = spRef;
    if (ref?.current && ref?.current?.input) {
      if (ref?.current?.input?.parentNode?.className) {
        return;
      }
      if (ref === doseRef) {
        ref.current.input.style.setProperty("border", "none");
        return;
      }
      ref.current.input.style.setProperty("border", "none", "important");
    }
  };

  return (
    <Drawer
      title={`Discharge - ${first_name} ${last_name} ${getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""}`}
      placement="right"
      variant="permanent"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (cancelled_reason != "" && cancelled_reason) ? "red" : ""}`}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Discharge" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper css="x-hidden">
          <div
            style={{
              marginTop: "1.5rem",
            }}
          >
            <SidebarTitle icon={<Icon type="interaction" theme="twoTone" twoToneColor="#198038" />} title="VITAL SIGNS" subTitle="" />
          </div>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "1.5rem",
              position: "relative",
              zIndex: 5,
            }}
            ref={!isTimePicker && !isType ? ref1 : null}
          >
            <button
              className="text-btn"
              style={{
                marginRight: "1rem",
                position: "absolute",
                top: "-24px",
                right: "22px",
                color: is_locked === true || is_locked === 1 || !isAddable ? "#9B9C9F" : "#005ef6",
              }}
              onClick={addOperative}
              disabled={is_locked || !isAddable}
            >
              Add +
            </button>
            <div
              className="resourceInfo"
              style={{
                marginTop: 0,
                paddingTop: 0,
                paddingRight: 0,
                height: "calc(30 * 8px + 48px + 5px)",
              }}
              ref={!isTimePicker && !isType ? ref2 : null}
            >
              <ExtendDataTable
                isBorderThick={true}
                isBorderLeftNone={true}
                isBorderOnHover={true}
                title={getTitle()}
                onClickRow={(index) => setActiveRow(index)}
                onClickColumn={(index) => setSelectedColumn(index)}
                activeRow={activeRow}
                data={(operatives || []).map((opt, index) => ({
                  key: `discharge-${index}`,
                  time:
                    activeRow === index || !opt.time ? (
                      <div className="vitals-first-col">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            paddingRight: "10px",
                          }}
                        >
                          <div style={{ flex: 1, paddingRight: "10px" }}>
                            <Select
                              disabled={is_locked}
                              className={validateField("text", opt?.type) ? "input-error" : ""}
                              suffixIcon={<ExpandSVG />}
                              allowClear={true}
                              value={opt?.type || undefined}
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: validateField("text", opt?.type) ? "rgba(255, 0, 0, 0.3)" : "#fff",
                              }}
                              onChange={(val) => {
                                handleChange("type", val, index);
                              }}
                              onFocus={() => setIsType(true)}
                              onBlur={() => setIsType(false)}
                            >
                              {["pre", "intra", "post", "discharge"].map((r, index) => {
                                return (
                                  <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                    {r}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <TimePicker
                          disabled={is_locked}
                          ref={timeRef}
                          format={"h:mm A"}
                          className={validateField("time", opt.time) ? "input-mix-requied-true" : ""}
                          use12Hours
                          onOpenChange={(status) => {
                            if (status) timeRef.current.timePickerRef.picker.style.setProperty("border", "2px solid #0f62fe", "important");
                            else timeRef.current.timePickerRef.picker.style.setProperty("border", "none", "important");
                            setIsTimePicker(status);
                          }}
                          placeholder="Time"
                          style={{
                            maxWidth: "calc(100% - 5px)",
                            width: "calc(100% - 5px)",
                            marginRight: "5px",
                            background: "transparent",
                          }}
                          suffixIcon={<span />}
                          value={
                            opt.time && moment(opt.time).format("YYYY-MM-DD h:mm A") !== "Invalid date"
                              ? moment(opt.time, "YYYY-MM-DD h:mm A")
                              : moment()
                          }
                          onChange={(value) => {
                            handleChange("time", value && value !== "Invalid date" ? value : null, index);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="vitals-first-col">
                        <div className="icon-wrapper">{renderIcons(opt.type)}</div>
                        <div className="ant-time-picker">{opt.time ? moment(opt.time).format("LT") : null}</div>
                      </div>
                    ),
                  bp:
                    activeRow === index || !opt.bp ? (
                      <EditableInputWithDiv
                        disabled={is_locked}
                        value={opt.bp}
                        subLabel="BP"
                        handleChange={(value) => handleChange("bp", value, index)}
                        isError={!opt.bp}
                        enableErrorText
                        required
                        sm
                      />
                    ) : (
                      opt.bp
                    ),
                  hr:
                    activeRow === index || !opt.hr ? (
                      <Input
                        disabled={is_locked}
                        ref={hrRef}
                        style={{
                          width: "calc(100% - 5px)",
                          marginRight: "5px",
                          borderBottom: "1px solid #8d8d8d !important",
                          boxShadow: "none",
                        }}
                        className={validateField("text", opt.hr) || !opt.hr || opt.hr == 0 ? "input-mix-requied-true input-error" : ""}
                        defaultValue={opt.hr}
                        placeholder={"HR"}
                        maxLength={3}
                        mask="111"
                        onChange={(e) => {
                          handleChange("hr", e.target.value, index);
                        }}
                        onFocus={(e) => handleFocus(e, "hr")}
                        onBlur={(e) => handleBlur(e, "hr")}
                        allowClear={false}
                      />
                    ) : (
                      opt.hr
                    ),
                  sp:
                    activeRow === index || !opt.sp ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          paddingRight: "10px",
                        }}
                      >
                        <div style={{ flex: 1, paddingRight: "10px" }}>
                          <Input
                            disabled={is_locked}
                            ref={spRef}
                            className={validateField("text", opt.sp) ? "input-error" : ""}
                            style={{
                              width: "100%",
                              borderBottom: "1px solid #8d8d8d !important",
                              boxShadow: "none",
                            }}
                            defaultValue={opt.sp}
                            placeholder={"SP"}
                            maxLength={3}
                            mask="111"
                            onChange={(e) => {
                              handleChange("sp", e.target.value, index);
                            }}
                            allowClear={false}
                            onFocus={(e) => handleFocus(e, "sp")}
                            onBlur={(e) => handleBlur(e, "sp")}
                          />
                        </div>
                        <div
                          style={{
                            display: is_locked ? "none" : "flex",
                          }}
                        >
                          <DeleteIcon handleClick={() => removeItem(index)} selected={activeDelete === index} icon="can" />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: is_locked ? "none" : "flex",
                          justifyContent: "space-between",
                          marginRight: 12,
                        }}
                      >
                        {opt.sp}
                        <DeleteIcon handleClick={() => removeItem(index)} selected={activeDelete === index} icon="can" />
                      </div>
                    ),
                }))}
                options={["time", "bp", "hr", "sp"]}
                widths={Array.from({ length: 4 }).map((_, index) => {
                  return selectedColumn === -1 ? "25%" : selectedColumn === index ? "30%" : "23.33%";
                })}
                minRows={6}
              />
            </div>
          </div>
          <SidebarTitle icon={<Icon type="experiment" theme="twoTone" twoToneColor="#198038" />} title="MEDICATION ADMINISTRATION" />
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "1rem",
            }}
            ref={!isTimePicker && !isRoute && !isType ? refMed1 : null}
          >
            <div
              className="resourceInfo between flex-end"
              style={{ paddingRight: 0, marginTop: "0px", paddingTop: 0 }}
              ref={!isTimePicker && !isRoute && !isType ? refMed2 : null}
            >
              <AutoCompleteDataTable
                isRmBorders={true}
                isBorderThick={true}
                isBorderOnHover={true}
                title="condition-medications"
                placeholder="Search Medications"
                keyLabel={"name"}
                keyValue={"id"}
                disableDelete={true}
                keyType={"unit"}
                isResult={false}
                isIcon={true}
                loading={loading}
                disableSearchIcon={false}
                activeRow={activeMedRow}
                onClickTableRow={(index) => setActiveMedRow(index)}
                handleSearch={handleSearchMedication}
                selectItem={selectItemMedication}
                removeItem={removeRowMedication}
                tableData={(medications || []).map((row, index) => {
                  return {
                    name: row?.name,
                    time:
                      activeMedRow === index || !row?.time ? (
                        <div className="vitals-first-col">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              paddingRight: "10px",
                            }}
                          >
                            <div style={{ flex: 1, paddingRight: "10px" }}>
                              <Select
                                disabled={is_locked}
                                className={validateField("text", row?.type) ? "input-error" : ""}
                                suffixIcon={<ExpandSVG />}
                                allowClear={true}
                                value={row?.type || undefined}
                                style={{
                                  maxWidth: isReSize ? 120 : 75,
                                  width: isReSize ? "calc(100% + 50px)" : "100%",
                                  zIndex: isReSize ? 1 : 0,
                                  overflow: "hidden",
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: validateField("text", row?.type) ? "rgba(255, 0, 0, 0.3)" : "#fff",
                                }}
                                onChange={(val) => {
                                  handleMedicationChange("type", val, index);
                                }}
                                onDropdownVisibleChange={(status) => {
                                  setIsReSize(status);
                                }}
                                onFocus={() => setIsType(true)}
                                onBlur={() => setIsType(false)}
                              >
                                {["pre", "intra", "post", "discharge"].map((r, index) => {
                                  return (
                                    <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                      {r}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </div>
                          <TimePicker
                            disabled={is_locked}
                            ref={timeRef2}
                            format={"h:mm A"}
                            use12Hours
                            onOpenChange={(status) => {
                              if (status) timeRef2.current.timePickerRef.picker.style.setProperty("border", "2px solid #0f62fe", "important");
                              else timeRef2.current.timePickerRef.picker.style.setProperty("border", "none", "important");
                              setIsTimePicker(status);
                            }}
                            placeholder="Time"
                            style={{
                              maxWidth: "calc(100% - 5px)",
                              width: "calc(100% - 5px)",
                              marginRight: "5px",
                              background: "transparent",
                            }}
                            suffixIcon={<span />}
                            value={
                              row.time && moment(row.time).format("YYYY-MM-DD h:mm A") !== "Invalid date"
                                ? moment(row.time, "YYYY-MM-DD h:mm A")
                                : moment()
                            }
                            onChange={(value) => {
                              handleMedicationChange("time", value && value !== "Invalid date" ? value : null, index);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="vitals-first-col">
                          <div className="icon-wrapper">{renderIcons(row?.type)}</div>
                          {row?.time ? moment(row?.time).format("LT") : null}
                        </div>
                      ),
                    dose:
                      activeMedRow === index || !row?.dose ? (
                        <Input
                          disabled={is_locked}
                          ref={doseRef}
                          className={validateField("text", row?.dose) || !row?.dose || row?.dose === "" ? "input-mix-requied-true input-error" : ""}
                          style={{
                            width: "calc(100% - 5px)",
                            marginRight: "5px",
                            borderBottom: "1px solid #8d8d8d !important",
                            boxShadow: "none",
                          }}
                          value={row?.dose}
                          onChange={(e) => {
                            handleMedicationChange("dose", e.target.value, index);
                          }}
                          suffix={<span className="medicine-unit-suffix">{row?.drugs?.unit ? row?.drugs?.unit : ""}</span>}
                          allowClear={false}
                          onFocus={(e) => handleFocus(e, "dose")}
                          onBlur={(e) => handleBlur(e, "dose")}
                        />
                      ) : (
                        <span>
                          {row?.dose} <span className="medicine-unit-suffix">{row?.drugs?.unit ? row?.drugs?.unit : ""}</span>
                        </span>
                      ),
                    route:
                      activeMedRow === index || !row?.route ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            paddingRight: "10px",
                          }}
                        >
                          <div style={{ flex: 1, paddingRight: "10px" }}>
                            <Select
                              disabled={is_locked}
                              className={validateField("text", row?.route) ? "input-error" : ""}
                              suffixIcon={<ExpandSVG />}
                              allowClear={true}
                              value={row?.route || undefined}
                              placeholder="Route"
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: validateField("text", row?.route) ? "rgba(255, 0, 0, 0.3)" : "transparent",
                              }}
                              onChange={(val) => {
                                handleMedicationChange("route", val, index);
                              }}
                              onFocus={() => setIsRoute(true)}
                              onBlur={() => setIsRoute(false)}
                            >
                              {["IV", "PO", "IM", "NC"].map((r, index) => {
                                return (
                                  <Select.Option style={{ width: "100%" }} key={`${r}-${index}`} value={r}>
                                    {r}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </div>
                          <div
                            style={{
                              display: is_locked ? "none" : "flex",
                            }}
                          >
                            <DeleteIcon
                              selected={activeMedDelete === index}
                              handleClick={() => {
                                removeRowMedication("", index);
                              }}
                              icon="can"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: is_locked ? "none" : "flex",
                            justifyContent: "space-between",
                            marginRight: 12,
                          }}
                        >
                          {row?.route}
                          <DeleteIcon
                            selected={activeMedDelete === index}
                            handleClick={() => {
                              removeRowMedication("", index);
                            }}
                            icon="can"
                          />
                        </div>
                      ),
                  };
                })}
                options={filteredMedications}
                minRows={6}
                isLocked={is_locked}
                tableTitle="medication-administration"
                tableTitleOptions={["Time", "Medication", "Dose", "Route"]}
                tableOptions={["time", "name", "dose", "route"]}
                tableWidths={["25%", "25%", "25%", "25%"]}
                addItem={handleAddMedication}
              />
            </div>
          </div>
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button
              onClick={saveAll}
              disabled={is_locked}
              style={{
                backgroundColor: is_locked === true ? "#9B9C9F" : "#305AA2",
              }}
            >
              Discharge
            </button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default DischargeSidebar;

import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/DataTable";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { getProcedureCallAttempt, getProcedureCallback, selectProcedure, setGlobalLoading, setProcedureData } from "../../../store/actions";

const CallbackTable = ({ title, search, selectedType, handleSidebar, handleSelect, getCallBackCount }) => {
  const ref = useRef(null);
  const loading = useSelector((state) => state.procedure.loadingCallback);
  const data = useSelector((state) => state.procedure.callback);

  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!data && !loading) {
      dispatch(setProcedureData("loadingCallback", true));
      dispatch(getProcedureCallback({}));
    }
  }, [data]);

  const filterSuggestion = (suggestions, userInput) => {
    let count = 0;

    if (!userInput) {
      suggestions.map((obj) => {
        const Hours = new Date(obj?.message_datetime).getHours();
        if (Hours > 12 && Hours < 24) {
          count++;
        }
      });
      getCallBackCount(count);
      return suggestions;
    }
    let filteredSuggestions = [];

    filteredSuggestions = suggestions.filter(
      (suggestion) =>
        suggestion.patient_name && suggestion.patient_name.split(" ").join("").toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1
    );

    suggestions.map((obj) => {
      const Hours = new Date(obj?.message_datetime).getHours();
      if (Hours > 12 && Hours <= 24) {
        count++;
      }
    });
    getCallBackCount(count);

    return filteredSuggestions;
  };

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search);
    }
    return [];
  }, [data, search]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const handleRow = async (index) => {
    dispatch(setGlobalLoading(true));
    dispatch(setProcedureData("loadingResource", true));
    dispatch(selectProcedure(rowData[index]));
    dispatch(getProcedureCallAttempt(rowData[index].id));
    dispatch(setGlobalLoading(false));
    handleSelect("call-back");
    handleSidebar();
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 30 && offset < tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const getProcedureName = (row) => {
    if (row?.side) return row?.side + " " + row?.procedure_name;
    else return row?.procedure_name;
  };

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper css="no-padding no-b-padding x-hidden" onScrollFrame={onScroll}>
        <div className="tableList">
          <DataTable
            isXHidden={true}
            tableWidth={"100%"}
            title="new_patient_pending"
            isNormal={true}
            labels={["Patient Name", "Procedure", "Appointment Date"]}
            widths={["30%", "40%", "30%"]}
            defaultActive={-1}
            disableDelete={true}
            columns={[
              { sortable: false, key: "name" },
              { sortable: false, key: "referral_condition" },
              { sortable: false, key: "referral_date" },
            ]}
            handleClickRow={(row, index) => {
              handleRow(index);
            }}
            rowData={(rowData || []).map((row, index) => {
              return {
                name: (
                  <div
                    className="iconField td with-icon"
                    style={{
                      display: "flex",
                      paddingLeft: "0.25rem",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Icon type="phone" theme="twoTone" twoToneColor={row?.has_intraday_call_attempt === 1 ? "#198038" : "#F1C21B"} />
                    <p
                      style={{
                        paddingLeft: "0.75rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.patient && row?.patient?.name.toLowerCase()}
                    </p>
                  </div>
                ),
                referral_condition: getProcedureName(row),
                referral_date: row?.appointment_date && moment(row?.appointment_date).format("MM/DD/YYYY hh:mm A"),
              };
            })}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default CallbackTable;

import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hipaaGetList, setGlobalLoading } from "../../../store/actions";
import GenerateQRCode from "../../components/PusherScan/generateQRCode";
import ExtendDataTable from "../../components/SubDataTable/ExtendDataTable";

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const HIPDocumentationComponent = ({ resource, handleContinue }) => {
  const dispatch = useDispatch();
  const selectedPatientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const documents = useSelector((state) => state.hipaa.documents);
  useEffect(() => {
    const reloadDocuments = () => {
      const patient_id = selectedPatientResource?.id;
      dispatch(hipaaGetList(patient_id));
    };
    const reloadDocumentsInterval = setInterval(reloadDocuments, 3000);
    return () => {
      clearInterval(reloadDocumentsInterval);
    };
  });

  useEffect(() => {
    const patient_id = selectedPatientResource?.id;
    dispatch(hipaaGetList(patient_id));
  }, []);

  const [newDocumentData, setNewDocumentData] = useState(undefined);
  const onCreateHipaa = () => {
    const newDocumentData_ = {
      patient_id: selectedPatientResource["id"],
      patient_data: {
        name: selectedPatientResource["first_name"] + " " + selectedPatientResource["last_name"],
      },
      is_representative: false,
      representative_data: {
        name: "",
        relationship: "",
      },
      expires_at: "",
      providers: [
        {
          name: "",
          relationship: "",
          phone: "",
        },
        {
          name: "",
          relationship: "",
          phone: "",
        },
      ],
    };
    setNewDocumentData(newDocumentData_);
  };
  const [documentsCount, setDocumentsCount] = useState(0);
  useEffect(() => {
    const documentsCount_ = documents.length;
    if (documentsCount != documentsCount_) {
      setNewDocumentData(undefined);
    }
    setDocumentsCount(documentsCount_);
  }, [documents]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPdfLink, setSelectedPdfLink] = useState(undefined);

  useEffect(() => {
    if (isLoading) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [isLoading]);

  const printPdf = async (pdfLink) => {
    setSelectedPdfLink(pdfLink);
    setIsLoading(true);
    const pdfData = await pdf2base64(pdfLink);
    printB64(pdfData);
    setIsLoading(false);
  };

  const printB64 = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf";
    const blob = b64toBlob(b64, contentType);
    const blobUrl = URL.createObjectURL(blob);
    let iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <ExtendDataTable
          title={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  ...titleStyle,
                  width: "30%",
                  textAlign: "left",
                  paddingLeft: 0,
                  borderLeft: 0,
                }}
              >
                Date
              </div>
              <div style={{ ...titleStyle, width: "70%", textAlign: "left" }}>Document</div>
            </div>
          }
          data={documents.map((doc) => ({
            date: doc.date,
            filename: (
              <a h onClick={() => printPdf(doc["fileurl"])}>
                HIPAA and Privacy Policy Acknowledgement
              </a>
            ),
          }))}
          options={["date", "filename"]}
          widths={["30%", "70%"]}
        />
      </div>
      <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end" }}>
        <button className="common-btn" onClick={onCreateHipaa}>
          New HIPPA Form
        </button>
      </div>
      <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end" }}>
        <iframe title="pdf" id="printPdfViewer" name="printPdfViewer" src={selectedPdfLink} style={{ display: "none" }}></iframe>
      </div>
      {newDocumentData && (
        <div className="resourceInfo" style={{ marginTop: "30px" }}>
          <GenerateQRCode qrData={{ hipaa_data: newDocumentData, type: "hipaa-sign" }} />
        </div>
      )}
    </div>
  );
};

export default HIPDocumentationComponent;

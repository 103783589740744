import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import { newSnapshotOption } from "../../../common/constants/initialData";
import { addNewPatient as addPatient } from "../../../services/api.services";
import { validateField, validateSteps } from "../../../services/validations";
import {
  addpatientPending,
  filterLocations,
  filterProviders,
  getPharmacies,
  searchPcpNpe,
  setGlobalLoading,
  setStepData,
} from "../../../store/actions";
import Loading from "../../components/Loading";
import ToastMessage from "../../components/Toast";

const { demographics } = newSnapshotOption;
let timer = null;
const AddNewPatient = ({ handleClose, ...restProps }) => {
  const loading = useSelector((state) => state.newPatient.loadingResource);
  const pcpDataRef = useSelector((state) => state.newPatient.pcpDataRef);
  const loadingSearchPCP = useSelector((state) => state.newPatient.loadingSearchPCP);
  const loadingPharmacies = useSelector((state) => state.common.loadingPharmacies);
  const tabActive = useSelector((state) => state.clinicProviderReview.tabActive);
  const dispatch = useDispatch();

  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(true);
  const [isToastr, setIsToastr] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [dataPCPRef, setDataPCPRef] = useState([]);

  useEffect(() => {
    setDataPCPRef(pcpDataRef);
  }, [pcpDataRef]);

  const [referData, setReferData] = useState([]);

  useEffect(() => {
    if (restProps?.referralConditions) {
      setReferData(restProps.referralConditions);
    }
  }, [restProps]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const fetchSearchNew = async () => {
    await dispatch(setGlobalLoading(true));
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchSearchNew();
  }, []);

  const onChangePatient = (type) => (value) => {
    if (type == "pharmacy_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [type]: id ? Number(id) : null,
        ["pharmacy_name"]: name,
      };
      setPatient(newPatient);
      setEnableError(false);
    } else if (type === "referral_provider" || type === "primary_care_provider_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [`${type}_name`]: name,
        [type]: id,
      };
      setPatient(newPatient);
      setEnableError(false);
    } else {
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
      setEnableError(false);
    }
  };

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);
  };

  const addNewPatient = async () => {
    if (!validateSteps(patient, demographics)) {
      setEnableError(true);
      return;
    }
    dispatch(setStepData("loadingResource", true));
    dispatch(setStepData("isAdded", false));
    try {
      const result = await addPatient(patient);
      if (result) {
        dispatch(setStepData("resource", result?.data && result?.data?.data));
        dispatch(setStepData("loadingResource", false));
        dispatch(addpatientPending(result?.data && result?.data?.data));
        await dispatch(setStepData("isSave", true));
        dispatch(filterLocations("", "clinic"));
        dispatch(filterProviders());
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          dispatch(setStepData("isSave", false));
          handleClose();
        }, 1000);
      } else {
        alert("please try again");
      }
    } catch (error) {
      dispatch(setStepData("loadingResource", false));
      handleClose();
    }
  };

  const searchHandle = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(getPharmacies(value));
      setLocalLoading(false);
    }, 500);
  };

  const fetchSearch = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const getDetailSuccess = await dispatch(searchPcpNpe(1, value));
      if (getDetailSuccess) {
        setLocalLoading(false);
      }
    }, 500);
  };

  return (
    <div className="resourceContent" style={{ paddingLeft: "22px" }}>
      {isToastr && <ToastMessage type="Patient" status="success" msg="Save Successfully!" />}
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"First Name"}
          value={patient?.first_name}
          handleChange={onChangePatient("first_name")}
          required={true}
          isError={validateField("text", patient?.first_name)}
          enableErrorText={enableError}
          helperText={"First Name is Required!"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Last Name"}
          value={patient?.last_name}
          handleChange={onChangePatient("last_name")}
          required={true}
          isError={validateField("text", patient?.last_name)}
          enableErrorText={enableError}
          helperText={"Last Name is Required!"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Deceased"}
          handleChange={onChangePatient("deceased")}
          value={patient.deceased}
          type={"check"}
          required={true}
          isError={validateField("text", patient.deceased)}
          enableErrorText={enableError}
          helperText={"Deceased is Required!"}
          optionKey={"value"}
          valueKey={"name"}
          options={[
            { value: "yes", name: "Yes" },
            { value: "no", name: "No" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"DOB"}
          value={patient.date_of_birth}
          handleChange={onChangePatient("date_of_birth")}
          required={true}
          mask={"date"}
          isError={validateField("date", patient.date_of_birth)}
          enableErrorText={enableError}
          helperText={"Birthday should be MM/DD/YYYY!"}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Gender"}
          handleChange={onChangePatient("gender")}
          value={patient.gender}
          type={"check"}
          required={true}
          isError={validateField("text", patient.gender)}
          enableErrorText={enableError}
          helperText={"Gender is Required!"}
          optionKey={"value"}
          valueKey={"name"}
          options={[
            { value: "male", name: "Male" },
            { value: "female", name: "Female" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Language"}
          handleChange={onChangePatient("preferred_language")}
          value={patient.preferred_language}
          type={"check"}
          optionKey={"value"}
          valueKey={"name"}
          options={[
            { value: "English", name: "English" },
            { value: "Spanish", name: "Spanish" },
            { value: "Other", name: "Other" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Marital Status"}
          handleChange={onChangePatient("marital_status")}
          value={patient.marital_status}
          type={"check"}
          optionKey={"value"}
          valueKey={"name"}
          options={[
            { value: "Married", name: "Married" },
            { value: "Single", name: "Single" },
            { value: "Divorced", name: "Divorced" },
            { value: "Widowed", name: "Widowed" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Ethnicity"}
          handleChange={onChangePatient("ethnicity")}
          value={patient.ethnicity}
          type={"check"}
          optionKey={"value"}
          valueKey={"name"}
          options={[
            { value: "White", name: "White" },
            { value: "Hispanic", name: "Hispanic" },
            { value: "Not Hispanic or Latino", name: "Not Hispanic or Latino" },
            { value: "Other", name: "Other" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Phone No"}
          value={patient.phone}
          handleChange={onChangePatient("phone")}
          required={true}
          isError={validateField("phone", patient.phone)}
          enableErrorText={enableError}
          helperText={"Phone Number should be (XXX) XXX-XXXX format"}
          mask={"phone"}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput label={"Phone No"} value={patient.secondary_phone} handleChange={onChangePatient("secondary_phone")} mask={"phone"} />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput label={"E-Mail"} value={patient.email} handleChange={onChangePatient("email")} />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput label={"SSN"} value={patient.ssn} handleChange={onChangePatient("ssn")} mask={"ssn"} />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Address"}
          value={patient.address}
          handleChange={onChangeAddress("address")}
          required={true}
          isError={validateField("text", patient.address)}
          enableErrorText={enableError}
          helperText={"Address is required"}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput label={"Apt"} value={patient.apt_number} handleChange={onChangePatient("apt_number")} />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"State"}
          value={patient.state}
          handleChange={onChangePatient("state")}
          required={true}
          isError={validateField("text", patient.state)}
          enableErrorText={enableError}
          helperText={"State is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"City"}
          value={patient.city}
          handleChange={onChangePatient("city")}
          required={true}
          isError={validateField("text", patient.city)}
          enableErrorText={enableError}
          helperText={"City is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Zip Code"}
          value={patient.zipcode}
          handleChange={onChangePatient("zipcode")}
          required={true}
          isError={validateField("text", patient.zipcode)}
          enableErrorText={enableError}
          helperText={"Zip Code is required"}
        />
      </div>
      <hr />
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Diagnosis"}
          value={patient.referral_condition || undefined}
          handleChange={onChangePatient("referral_condition")}
          required={true}
          type={"check"}
          isError={validateField("text", patient.referral_condition)}
          optionKey={"value"}
          valueKey={"value"}
          options={referData || []}
          enableErrorText={enableError}
          helperText={"Diagnosis is required"}
        />
      </div>
      {patient.referral_condition === "Other" && (
        <div className="resourceInfo d-flex required-field">
          <EditableFormInput
            label={"Diagnosis Specifics"}
            value={patient.diagnosis_specifics}
            handleChange={onChangePatient("diagnosis_specifics")}
          />
        </div>
      )}
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Referring "}
          hideIconSelect={true}
          type={"check"}
          value={patient?.referral_provider && patient?.referral_provider_name ? `${patient?.referral_provider_name}` : undefined}
          options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
          autoClearSearchValue={false}
          onChangeSearch={(value) => {
            fetchSearch(value);
          }}
          handleChange={onChangePatient("referral_provider")}
          toGetAll={true}
          disableFilter={true}
          notFoundContent={
            localLoading || loadingSearchPCP ? (
              <div style={{ height: 100 }}>
                <Loading />
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          optionKey={"id"}
          valueKey={"full_name"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"PCP"}
          hideIconSelect={true}
          type={"check"}
          value={
            patient?.primary_care_provider_id && patient?.primary_care_provider_id_name ? `${patient?.primary_care_provider_id_name}` : undefined
          }
          options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
          autoClearSearchValue={false}
          onChangeSearch={(value) => {
            fetchSearch(value);
          }}
          handleChange={onChangePatient("primary_care_provider_id")}
          toGetAll={true}
          disableFilter={true}
          notFoundContent={
            localLoading || loadingSearchPCP ? (
              <div style={{ height: 100 }}>
                <Loading />
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          optionKey={"id"}
          valueKey={"full_name"}
        />
      </div>

      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Referral Date"}
          value={patient.referral_date}
          type="date"
          handleChange={onChangePatient("referral_date")}
          required={true}
          isError={validateField("text", patient.referral_date)}
          enableErrorText={enableError}
          helperText={"Referral Date is required"}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Pharmacy"}
          hideIconSelect={true}
          type={"check"}
          value={patient?.pharmacy_name && patient?.pharmacy_id ? `${patient?.pharmacy_name} - ${patient?.pharmacy_id}` : undefined}
          options={restProps.pharmacies || []}
          autoClearSearchValue={false}
          onChangeSearch={(value) => {
            searchHandle(value);
          }}
          handleChange={onChangePatient("pharmacy_id")}
          toGetAll={true}
          disableFilter={true}
          notFoundContent={
            localLoading || loadingPharmacies ? (
              <div style={{ height: 100 }}>
                <Loading />
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          optionKey={"id"}
          valueKey={"name"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Advance Directives"}
          value={patient.advanced_directive || undefined}
          handleChange={onChangePatient("advanced_directive")}
          required={true}
          type={"check"}
          isError={validateField("text", patient.advanced_directive)}
          optionKey={"value"}
          valueKey={"value"}
          options={[{ value: "No Advance Directives On File" }, { value: "Advance Directives On File" }]}
          enableErrorText={tabActive ? true : false}
          helperText={"Advance Directives"}
        />
      </div>

      <div className="actionContent" style={{ paddingBottom: "1rem" }}>
        <button className="common-btn outlined-btn" style={{ marginRight: "0.5rem" }} onClick={handleClose}>
          Back
        </button>
        <button className="common-btn" onClick={addNewPatient}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddNewPatient;

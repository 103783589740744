import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  hpi_conditions: [],
  ROSs: [],
  physical_examinations: [],
  vitals: [],
  comment: "",
  vessel_states: [],
  icd_codes: [],
  medical_history: [],
  previous_surgical_histories: [],
  allergies: [],
  family_history: [],
  social_history: [],
  descList: [],
  mips: [],
  chiefComplaint: "",
  is_locked: null,
  medical_decision_making: "",
  meaningful_uses: [],
  discussion_text: [],
  loading_meaningful_uses: false,
  result_meaningful_uses: false,
  tabActive: "",
  accordion: "",
  step: "",
  loadingClinicReview: false,
  leftRight: null,
  text_template: null,
  reading_physician_id: 0,
  has_empty_hpi_question: false,
  provider_name: "",
  has_unprocessed_plans: false,
  appointment_status: "",
  ultrasound_discussion_text: [],
  reason_for_visit: "",
  has_wrong_physical_exam: false,
  required_abi_exercise_left: false,
  required_abi_exercise_right: false,
  required_tbi_left: false,
  required_tbi_right: false,
  required_diameter_left: false,
  required_diameter_right: false,
  has_empty_icd: false,
  has_wrong_physical_exam_2_right: false, //If the pulse exam is anything but (0 or D) in the tibials (below knee) than need icon with the exclamation point followed by "The pulse examination must be 0 or D for the rest pain selection of the [RIGHT or LEFT]"
  has_wrong_physical_exam_2_left: false,
  has_wrong_physical_exam_3_right: false, //If the pulse exam is anything but (0 or D or 1) in the tibials (below knee)  than need icon with the exclamation point followed by "The pulse examination must be 0 or D or 1+ for the claudication selection of the [RIGHT or LEFT]"
  has_wrong_physical_exam_3_left: false,
  has_trial_right: false, // If the patient is a NEW PATIENT.  And they only have claudication (rest pain, sudden pain, wound, claudication]  as the HPI.  And the user scheduled an angiogram.  "The patient is a new patient and needs a trial of 3 months conservative therapy prior to scheduling a [RIGHT OR LEFT] angiogram.
  has_trial_left: false,
  require_other_symptom_right: false, // Need to have either [sudden pain OR claudication OR rest pain] selected with restenosis.  If not than Need the icon with the exclamation point followed by "The patient requires symptoms with restenosis prior to scheduling a [RIGHT OR LEFT] angiogram.
  require_other_symptom_left: false,
  has_same_choice: false, //If the patient underwent a procedure (angigoram) prior to the (clinic/OBL) the HPI MUST BE DIFFERENT.  Example if we fixed the right side anf the previous selections were (rest pain, claudication), you cant have the same selections after having fixed what the issue was!.  So Need the icon with the exclamation point followed by "The patients HPI has not changed after intervention of the {RIGHT OR LEFT].  In the error popup - drop down that says [Worsening Symptoms, Incomplete Intervention, Unchanged]
  additional_plan_text: [],
  additional_plan_text_lymphedema: [],
};

const rerenderArr = (value, typeRos, TypeExam) => {
  if (value && value.length > 0) {
    const mixCheck = (value || []).map((r) => {
      let letMix = "";
      let indexOF = null;
      let endComment = null;
      let indexOFNull = null;
      let endCommentNull = null;
      if (r?.comments && r?.default_comments && r?.comments?.length > 0 && r?.default_comments.length > 0 && r?.comments != r?.default_comments) {
        letMix = r?.comments;
        indexOF = r?.comments && r?.comments.lastIndexOf(", ");
      }
      if (Array.isArray(r?.values)) {
        let mixMap = [];
        let parse = [];
        if (r["values"] && r["values"][0] && r["values"][0]["replaced_value"]) {
          parse = r["values"] || [];
        }
        if (r["values"] && r["values"][0] && !r["values"][0]["replaced_value"]) {
          parse = r["values"][0]["option"] ? JSON.parse(r["values"][0]["option"]) : [];
        }
        if (parse.some((i) => i.active_text)) {
          let commentsCheck2 = "";
          const parseMix = parse.map((v, indexCheck) => {
            if (typeRos == 1 || TypeExam == 1) {
              if (Number(parse.length) - 1 == indexCheck) {
                if (v.checked) {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.option}, `) - 1;
                  endComment = `${v.option}, `;
                } else {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.replaced_value}, `) - 1;
                  endComment = `${v.replaced_value}, `;
                }
              }
              if (v.checked) {
                commentsCheck2 += `${v.option}, `;
              } else {
                commentsCheck2 += `${v.replaced_value}, `;
              }
            } else {
              if (Number(parse.length) - 1 == indexCheck) {
                indexOFNull = r?.comments && r?.comments.lastIndexOf(`${v.option}, `);
                endCommentNull = `${v.option}, `;
              }
              if (v.checked) {
                commentsCheck2 += `${v.option}, `;
              }
            }
            return {
              checked: false,
              option: v.inactive_text,
              replaced_value: v.active_text,
            };
          });
          mixMap = parseMix;
          if (letMix.length > 0 && commentsCheck2.length > 0) {
            if (endComment && indexOF > 0) {
              commentsCheck2 = commentsCheck2.replace(endComment, "") + letMix.slice(indexOF, letMix.length);
            } else if (endCommentNull && indexOFNull > 0) {
              commentsCheck2 = commentsCheck2.replace(endCommentNull, "") + letMix.slice(indexOFNull, letMix.length);
            } else {
              commentsCheck2 = commentsCheck2;
            }
          }
          return {
            ...r,
            comments: commentsCheck2,
            values: mixMap,
          };
        } else {
          let commentsCheck = "";
          parse.map((v, indexCheck) => {
            if (typeRos == 1 || TypeExam == 1) {
              if (Number(parse.length) - 1 == indexCheck) {
                if (v.checked) {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.option}, `) - 1;
                  endComment = `${v.option}, `;
                } else {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.replaced_value}, `) - 1;
                  endComment = `${v.replaced_value}, `;
                }
              }
              if (v.checked) {
                commentsCheck += `${v.option}, `;
              } else {
                commentsCheck += `${v.replaced_value}, `;
              }
            } else {
              if (v.checked) {
                commentsCheck += `${v.option}, `;
                if (Number(parse.length) - 1 == indexCheck) {
                  indexOFNull = r?.comments && r?.comments.lastIndexOf(`${v.option}, `);
                  endCommentNull = `${v.option}, `;
                }
              }
            }
          });
          if (letMix && commentsCheck && letMix.length > 0 && commentsCheck.length > 0) {
            if (endComment && indexOF > 0) {
              commentsCheck = commentsCheck.replace(endComment, "") + letMix.slice(indexOF, letMix.length);
            } else if (endCommentNull && indexOFNull > 0) {
              commentsCheck = commentsCheck.replace(endCommentNull, "") + letMix.slice(indexOFNull, letMix.length);
            } else {
              commentsCheck = commentsCheck;
            }
          }
          return {
            ...r,
            comments: commentsCheck,
            values: parse,
          };
        }
      } else {
        return {
          ...r,
          comments: r?.values || "",
        };
      }
    });
    return mixCheck || [];
  }
  return [];
};

const SAVE_TEMP_SUCCEEDED = `${AT.SAVE_TEMP}_SUCCEEDED`;
const GET_CPT_CODE_REVIEW_SUCCESS = `${AT.GET_CPT_CODE_REVIEW}_SUCCEEDED`;
const EDIT_ICD_SUCCESS = `${AT.EDIT_ICD}_SUCCEEDED`;

export default createReducer(initialState, {
  [EDIT_ICD_SUCCESS](state, action) {
    const ICD = action?.payload;
    return Object.assign({}, state, {
      icd_codes: ICD,
    });
  },
  [`${AT.ADD_PLAN_ME}_STARTED`](state, action) {
    return {
      ...state,
      loading_meaningful_uses: true,
    };
  },
  [`${AT.ADD_PLAN_ME}_SUCCEEDED`](state, action) {
    const result_meaningful_uses = action?.payload;
    return {
      ...state,
      loading_meaningful_uses: false,
      result_meaningful_uses,
    };
  },
  [`${AT.CHANGE_TAB}_SUCCEEDED`](state, action) {
    const { tabActive, accordion, step } = action?.payload || {};
    return {
      ...state,
      tabActive,
      accordion,
      step,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.CHANGE_MEDICAL_DECISION}_SUCCEEDED`](state, action) {
    const medical_decision_making = action?.payload;
    return {
      ...state,
      medical_decision_making,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      hpi_conditions: [],
      additional_plan_text: [],
      additional_plan_text_lymphedema: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      comment: "",
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      mips: [],
      chiefComplaint: "",
      is_locked: null,
      medical_decision_making: "",
      meaningful_uses: [],
      discussion_text: [],
      text_template: null,
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_STARTED`](state) {
    return {
      ...state,
      hpi_conditions: [],
      additional_plan_text: [],
      additional_plan_text_lymphedema: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      comment: "",
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      mips: [],
      discussion_text: [],
      chiefComplaint: "",
      text_template: null,
      meaningful_uses: [],
      loadingClinicReview: true,
      reading_physician_id: 0,
      has_empty_hpi_answer: false,
      has_wrong_carotid: false,
      provider_name: "",
      has_unprocessed_plans: false,
      leftRight: null,
      reason_for_visit: "",
      has_wrong_physical_exam: false,
      has_wrong_physical_exam_2_right: false,
      has_wrong_physical_exam_2_left: false,
      has_wrong_physical_exam_3_right: false,
      has_wrong_physical_exam_3_left: false,
      has_trial_right: false,
      has_trial_left: false,
      require_other_symptom_right: false,
      require_other_symptom_left: false,
      has_same_choice: false,
      has_empty_vasquol6: false,
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (!action?.payload) {
      return { ...state };
    }
    const {
      hpi_conditions,
      additional_plan_text,
      additional_plan_text_lymphedema,
      ROSs,
      physical_examinations,
      vitals,
      comment,
      vessel_states,
      icd_codes,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
      text_template,
      discussion_text,
      leftRight,
      has_empty_hpi_question,
      has_wrong_carotid,
      provider_name,
      has_unprocessed_plans,
      reason_for_visit,
      has_wrong_physical_exam,
      has_wrong_physical_exam_2_left,
      has_wrong_physical_exam_2_right,
      has_wrong_physical_exam_3_left,
      has_wrong_physical_exam_3_right,
      has_trial_right,
      has_trial_left,
      require_other_symptom_right,
      require_other_symptom_left,
      has_same_choice,
      mips,
      has_empty_vasquol6,
    } = action?.payload;
    return {
      ...state,
      leftRight,
      text_template,
      hpi_conditions,
      ROSs: rerenderArr(ROSs),
      physical_examinations: rerenderArr(physical_examinations),
      vitals: (vitals || []).map((item) => {
        if (item.type === "Temperature") {
          return { type: item.type, value: `${item?.value} ℉` };
        } else if (item.type === "BP (Right)" || item.type === "BP (Left)") {
          return { type: item.type, value: `${item?.value} mnHg` };
        } else if (item.type === "HR") {
          return { type: item.type, value: `${item?.value} BPM` };
        } else if (item.type === "Resp") {
          return { type: item.type, value: `${item?.value} RR` };
        } else if (item.type === "SpO2") {
          return { type: item.type, value: `${item?.value} %` };
        } else if (item.type === "Weight") {
          return { type: item.type, value: `${item?.value} Kg` };
        } else if (item.type === "Height") {
          return { type: item.type, value: `${item?.value} Inches` };
        }
        return item;
      }),
      comment,
      vessel_states,
      icd_codes,
      additional_plan_text,
      additional_plan_text_lymphedema,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
      discussion_text,
      loadingClinicReview: false,
      has_empty_hpi_question,
      has_wrong_carotid,
      provider_name,
      has_unprocessed_plans,
      reason_for_visit,
      has_wrong_physical_exam,
      has_wrong_physical_exam_2_left,
      has_wrong_physical_exam_2_right,
      has_wrong_physical_exam_3_left,
      has_wrong_physical_exam_3_right,
      has_trial_right,
      has_trial_left,
      require_other_symptom_left,
      require_other_symptom_right,
      has_same_choice,
      mips,
      has_empty_vasquol6,
    };
  },
  [`${AT.ULTRASOUND_REVIEW_LOAD_INIT}_STARTED`](state) {
    return {
      ...state,
      ultrasound_discussion_text: [],
    };
  },
  [`${AT.ULTRASOUND_REVIEW_LOAD_INIT}_SUCCEEDED`](state, action) {
    const {
      ultrasound_discussion_text,
      is_locked,
      reading_physician_id,
      text_template,
      required_abi_exercise_right,
      required_abi_exercise_left,
      required_tbi_right,
      required_tbi_left,
      required_diameter_right,
      required_diameter_left,
      has_empty_icd,
    } = action?.payload || {};
    return {
      ...state,
      is_locked,
      reading_physician_id,
      text_template,
      required_abi_exercise_right,
      required_abi_exercise_left,
      required_tbi_right,
      required_tbi_left,
      required_diameter_right,
      required_diameter_left,
      has_empty_icd,
      ultrasound_discussion_text: ultrasound_discussion_text || "",
    };
  },
  [`${AT.UNLOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 0,
    });
  },
  [`${AT.LOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 1,
    });
  },
  // check
  [GET_CPT_CODE_REVIEW_SUCCESS](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      is_locked: CPT?.is_locked || false,
      appointment_status: CPT?.appointment_status || "",
    });
  },
  [SAVE_TEMP_SUCCEEDED](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      is_locked: CPT?.is_locked || false,
      appointment_status: CPT?.appointment_status || "",
    });
  },
});

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../common/components/Card";
import {
  addProcedureDetails,
  editConditionVariable,
  getConditionVariable,
  saveConditionVariable,
  setProcedureDetails,
  sortConditionVariables,
  update_icd_rule,
  update_plan_condition,
} from "../../store/actions";
import { Icon } from "antd";
import { getLocal } from "../../helpers/Local";
import ProviderBlock from "./conditionDetails/ConditionProviders";
import ConditionSearch from "./conditionDetails/ConditionSearch";
import UltrasoundBlock from "./conditionDetails/FollowUpUltrasounds";
import ICDCodeLogicBlock from "./conditionDetails/ICDCodeLogic";
import PlanSuggestionBlock from "./conditionDetails/PlanSuggestion";
import DocumentationBlock from "./conditionDetails/TemplateDocumentation";
import VariableCaptureBlock from "./conditionDetails/VariableCapture";
import "./style.scss";

const ConditionDetail = (props) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [duplicatedRow, setDuplicatedRow] = useState(null);
  const [activeCard, setActiveCard] = useState("");
  const [firstProps, setFirstProps] = useState(false);
  const [resource, setResource] = useState(null);
  const [isDxRanges, setIsDxRanges] = useState(false);
  const [isVariableSelected, setIsVariableSelected] = useState({
    index: null,
    selectedVariable: {
      id: null,
    },
  });

  const conditionVariable = useSelector((state) => state.procedureDetail.conditionVariable);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);
  const icdCodes = useSelector((state) => state.procedureDetail.icdCodes);
  const plans = useSelector((state) => state.procedureDetail.plans);

  const id_procedure_type_icd = getLocal("id_procedure_type_icd");

  const toggleDxRanges = () => {
    setIsDxRanges(!isDxRanges);
  };

  const enableDuplicate = (row) => {
    if (row) {
      setDuplicatedRow(row);
    }
  };

  const moveCard = async (type) => {
    const currentIndex = isVariableSelected?.index;
    if (type === "up") {
      const previousIndex = currentIndex - 1;
      const source = conditionVariable[currentIndex]["order"];
      const destination = conditionVariable[previousIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortConditionVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: previousIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
    if (type === "down") {
      const nextIndex = currentIndex + 1;
      const source = conditionVariable[currentIndex]["order"];
      const destination = conditionVariable[nextIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortConditionVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: nextIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleAddNewData = async (type, data) => {
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        saveConditionVariable({
          question: "",
          condition_id: selectedReferralCondition?.id,
          answers: [],
          cpt_code: [],
        })
      );
      await dispatch(getConditionVariable(selectedReferralCondition?.id));
    }
  };

  const update = async () => {
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      const index = isVariableSelected?.index;
      const id = isVariableSelected?.selectedVariable?.id;
      if (conditionVariable?.length > 0 && id) {
        conditionVariable[index]["answer"] = [
          ...conditionVariable[index]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(setProcedureDetails("conditionVariable", [...conditionVariable]));
        // call api save answer
        await dispatch(
          editConditionVariable(id && id, {
            variable_name: conditionVariable[index]["variable_name"],
            question: conditionVariable[index]["quest"],
            condition_id: selectedReferralCondition?.id,
            answers: conditionVariable[index]["answer"],
          })
        );
        await dispatch(getConditionVariable(selectedReferralCondition?.id));
      }
    }
  };

  const updateICD = async () => {
    const arrayADD = icdCodes.map((r) => {
      return {
        disease_id: r?.disease_id,
        condition_id: selectedReferralCondition?.id,
        disease: r?.disease,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      disease_id: 1,
      condition_id: selectedReferralCondition?.id,
      disease: { code: "", short_desc: "", icd_code_name: "" },
      condition_serialize: [
        {
          variable_id: undefined,
          answer: undefined,
          variable_name: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(setProcedureDetails("icdCodes", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      update_icd_rule(id_procedure_type_icd, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  const duplicateRow = async () => {
    const tempArray = plans.map((r) => {
      return {
        ...r,
        condition_id: selectedReferralCondition?.id,
        procedure_type_id: r?.procedure_type_id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                bracket: "",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      side: duplicatedRow?.side,
      bundle: duplicatedRow?.bundle,
      procedure_type_id: duplicatedRow?.procedure_type_id,
      condition_id: selectedReferralCondition?.id,
      entity_id: duplicatedRow?.entity_id,
      condition_serialize: [...duplicatedRow?.condition_serialize.map((obj) => ({ ...obj }))],
    };
    await dispatch(setProcedureDetails("plans", tempArray && Object.keys(tempArray).length > 0 ? [...tempArray, param] : [param]));
    await dispatch(
      update_plan_condition(id_procedure_type_icd, {
        rules: tempArray && Object.keys(tempArray).length > 0 ? [...tempArray, param] : [param],
      })
    );
  };

  const updatePlan = async () => {
    const arrayADD = plans.map((r) => {
      return {
        ...r,
        procedure_type_id: r?.procedure_type_id,
        condition_id: selectedReferralCondition?.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                bracket: "",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      side: undefined,
      bundle: undefined,
      procedure_type_id: 1,
      condition_id: selectedReferralCondition?.id,
      condition_serialize: [
        {
          variable_id: undefined,
          answer: undefined,
          variable_name: undefined,
          comparison: ">=",
          bracket: "",
          operator: "Select",
        },
      ],
    };
    await dispatch(setProcedureDetails("plans", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      update_plan_condition(id_procedure_type_icd, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Search Condition" varient="quarter" other="" contentPadding="1rem">
            <ConditionSearch setFirstProps={setFirstProps} setResource={setResource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Condition Providers" varient="quarter" other="" contentPadding="1rem">
            <ProviderBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Follow-Up Ultrasounds" varient="quarter" other="" contentPadding="1rem">
            <UltrasoundBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock title="Template Documentation" varient="quarter" other="" contentPadding="1rem">
            <DocumentationBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            title="Variable Capture"
            onClick={() => {
              setActiveCard("variable_capture");
            }}
            varient={activeCard == "" || activeCard == "variable_capture" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 16,
                    position: "absolute",
                    top: 2,
                    left: -35,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  {isVariableSelected?.index >= 1 && conditionVariable?.length > 1 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      type="up-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => !isDisabled && moveCard("up")}
                    />
                  ) : (
                    ""
                  )}
                  {(isVariableSelected?.index || isVariableSelected?.index === 0) &&
                  conditionVariable?.length > 1 &&
                  isVariableSelected?.index !== conditionVariable?.length - 1 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      type="down-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => !isDisabled && moveCard("down")}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <button
                  disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    handleAddNewData("conditionVariable", {
                      quest: "",
                      answer: [],
                    });
                  }}
                >
                  Add Specification +
                </button>
                <button
                  disabled={(selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0) || isVariableSelected.index === null}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={update}
                >
                  Add Answer +
                </button>
              </div>
            }
          >
            <VariableCaptureBlock resource={resource} rowAction={setIsVariableSelected} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            title="ICD Code Logic"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                  className="text-btn"
                  onClick={() => {
                    resource && toggleDxRanges();
                  }}
                >
                  Dx Ranges {isDxRanges ? "-" : "+"}
                </button>
                <button
                  disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updateICD}
                >
                  Add +
                </button>
              </div>
            }
            onClick={() => {
              setActiveCard("icd_code_logic");
            }}
            varient={activeCard == "icd_code_logic" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
          >
            <ICDCodeLogicBlock resource={resource} isDxRanges={isDxRanges} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            title="Plan Suggestion"
            onClick={() => {
              setActiveCard("plan_suggestion");
            }}
            varient={activeCard == "plan_suggestion" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {duplicatedRow && !duplicatedRow?.disableDelete && (
                  <button
                    disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                    className="text-btn"
                    onClick={duplicateRow}
                  >
                    Duplicate
                  </button>
                )}
                <button
                  disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updatePlan}
                >
                  Plan +
                </button>
              </div>
            }
          >
            <PlanSuggestionBlock firstProps={firstProps} resource={resource} rowAction={enableDuplicate} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ConditionDetail;

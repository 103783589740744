import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BoldButton, ItalicButton, OrderedListButton, UnderlineButton, UnorderedListButton } from "draft-js-buttons";
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import { text_template_ultrasound } from "../../../store/actions";
import ScrollWrapper from "../../../common/components/ScrollBar";

const toolbarPlugin = createToolbarPlugin();

let timer = null;

const TemplateDocumentation = ({ resource }) => {
  const [footerEditorState, setFooterEditor] = useState(EditorState.createEmpty());
  const [checkResoure, setCheckResoure] = useState(null);
  const [focusFooterEditor, setFocusFooterEditor] = useState(false);
  const dispatch = useDispatch();
  const detail_untrasound_list_type = useSelector((state) => state.procedureDetail.detail_untrasound_list_type);

  useEffect(() => {
    setCheckResoure(resource);
  }, [resource]);

  const onChangeFooter = (footerState) => {
    if (resource === null || resource === undefined) {
      return;
    }
    setFooterEditor(footerState);
    const data = JSON.stringify(convertToRaw(footerState.getCurrentContent()));
    if (detail_untrasound_list_type && Object.keys(detail_untrasound_list_type).length > 0 && focusFooterEditor) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          text_template_ultrasound(detail_untrasound_list_type?.id, {
            text_template: "",
            footer_template: data,
          })
        );
      }, 1000);
    }
  };

  useEffect(() => {
    if (detail_untrasound_list_type && Object.keys(detail_untrasound_list_type).length > 0) {
      if (detail_untrasound_list_type.footer_template && detail_untrasound_list_type.footer_template.length > 0) {
        try {
          const contentStateFooter = convertFromRaw(JSON.parse(detail_untrasound_list_type.footer_template));
          const editorStateFooter = EditorState.createWithContent(contentStateFooter);
          setFooterEditor(editorStateFooter);
        } catch (error) {
          setFooterEditor(EditorState.createEmpty());
        }
      } else {
        setFooterEditor(EditorState.createEmpty());
      }
    }
  }, [detail_untrasound_list_type]);

  const { Toolbar } = toolbarPlugin;
  const pluginsFooter = [toolbarPlugin];

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div className="custom-toolbar-editor">
        <Toolbar>
          {(externalProps) => (
            <div className="editor-custom-toolbar">
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </div>
      <div style={{ height: "calc(100% - 40px)", width: "100%" }}>
        <div className="resourceInfo d-flex" style={{ marginTop: "8px", marginBottom: "4px" }}>
          <b>FOOTER</b>
        </div>
        <div
          style={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "calc(100% - 32px)",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            {checkResoure !== null && checkResoure !== undefined ? (
              <Editor
                onFocus={() => {
                  setFocusFooterEditor(true);
                }}
                onBlur={() => {
                  setFocusFooterEditor(false);
                }}
                editorState={footerEditorState}
                onChange={onChangeFooter}
                plugins={pluginsFooter}
                placeholder="Footer"
              />
            ) : null}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default TemplateDocumentation;

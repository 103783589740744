import { Empty, Icon, Input, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../../common/components/Loading";
import ExtendDataTable from "../../../common/components/SubDataTable/ExtendDataTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import { search_insurance_companies, setGlobalLoading } from "../../../store/actions";

const { Option } = Select;

const dateMask = "99/99/9999";

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

let timer = null;
const CODEOPTIONS = [
  {
    label: "01 Spouse",
    policy_number: "spouse",
  },
  {
    label: "18 Self",
    value: "self",
  },
  {
    label: "19 Child",
    value: "child",
  },
  {
    label: "20 Employee/Attorney",
    value: "employee",
  },
  {
    label: "21 Unknown",
    value: "unknown",
  },
  {
    label: "53 Life Partner",
    value: "partner",
  },
  {
    label: "G8 Other Relationship",
    value: "other",
  },
];
const InsuranceDetail = ({ setPropsInsurance, valueInsurance }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const loading = useSelector((state) => state.newPatient.isVerifyInsurance);

  const dispatch = useDispatch();
  const search_insurance_companies_value = useSelector((state) => state.newPatient.search_insurance_companies);
  const insurance_types = useSelector((state) => state.newPatient.insurance_types);
  const loading_search_insurance_companies = useSelector((state) => state.newPatient.loading_search_insurance_companies);

  const [stateFocus, setStateFocus] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [isDelete, setIsDelete] = useState(-1);
  const [selectedColumn, setSelectedColumn] = useState(-1);
  const [isGroup, setIsGroup] = useState(false);
  const [isSelfPay, setIsSelfPay] = useState(false);
  const [tableColumnWidths, setTableColumnWidths] = useState(["25%", "25%", "25%", "25%"]);

  const handleAddNew = () => {
    const newInsurance = {
      key: Date.now(),
      id: null,
      insurance_company_id: "",
      policy_number: "",
      group_no: "",
      insurance_type: "",
      code: "",
      moreInfo: {},
      posted: false,
      insurance_name: "",
      is_verified: false,
      is_eligible: false,
    };
    setPropsInsurance && setPropsInsurance([...valueInsurance, newInsurance]);
  };

  const [insurancesType, setInsurancesType] = useState([]);

  useEffect(() => {
    setInsurancesType(insurance_types);
  }, [insurance_types]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingLocal));
  }, [loading, loadingLocal]);

  const removeVaraible = async (index) => {
    const arrayCheck = [...valueInsurance];
    const activeArray = arrayCheck[index];
    if (activeArray && activeArray["id"] && activeArray["posted"]) {
      setIsDelete(-1);
      setSelectedColumn(-1);
    } else {
      const vars = [...valueInsurance];
      vars.splice(index, 1);
      setPropsInsurance && setPropsInsurance(vars);

      setIsDelete(-1);
    }
  };

  useEffect(() => {
    if (isDelete >= 0) {
      removeVaraible(isDelete);
    }
  }, [isDelete]);

  const handleChangeInsurance = (type, value, index) => {
    const vars = [...valueInsurance];
    if (type == "firstName" || type == "lastName" || type == "DOB") {
      vars[index]["moreInfo"][type] = value;
      setPropsInsurance && setPropsInsurance(vars);
    } else if (type == "insurance") {
      vars[index]["insurance_company_id"] = value[0];
      vars[index]["insurance_name"] = `${value[1]}`;
      setPropsInsurance && setPropsInsurance(vars);
    } else {
      vars[index][type] = value;
      if (type == "code") {
        let moreInfo;
        let post;
        if (value == "self") {
          moreInfo = {};
          post = false;
        } else {
          moreInfo = {
            firstName: "",
            lastName: "",
            DOB: "",
          };
          post = true;
        }
        vars[index]["post"] = post;
        vars[index]["moreInfo"] = moreInfo;
        setPropsInsurance && setPropsInsurance(vars);
      } else {
        setPropsInsurance && setPropsInsurance(vars);
      }
    }
  };

  const findMedications = (medication) => {
    if (!medication) {
      return false;
    }
    if (medication.indexOf("BLUE") !== -1) {
      return true;
    }
    return false;
  };

  const findSelfpay = (insurance_company_id) => {
    if (!insurance_company_id) {
      return false;
    }
    if (insurance_company_id === 1361) {
      return true;
    }
    return false;
  };

  const searchInsurance = (value) => {
    clearTimeout(timer);
    setLoadingLocal(true);
    timer = setTimeout(() => {
      setLoadingLocal(false);
      dispatch(search_insurance_companies(value));
    }, 500);
  };

  useEffect(() => {
    const checkArray = valueInsurance?.map((el) => findMedications(el?.insurance_name));
    const checkSelfPay = valueInsurance?.map((el) => findSelfpay(el?.insurance_company_id));
    const selfPayExist = checkSelfPay?.some((el) => el);
    if (selfPayExist) {
      setIsSelfPay(true);
    } else {
      setIsSelfPay(false);
    }
    const groupExist = checkArray?.some((el) => el);
    if (groupExist) {
      setIsGroup(true);
    } else {
      setIsGroup(false);
    }
  }, [valueInsurance]);

  useEffect(() => {
    dispatch(search_insurance_companies(""));
  }, []);

  const getOptions = () => {
    if (isGroup) {
      if (isSelfPay) {
        return ["insurance_company_id", "insurance_type"];
      } else {
        return ["insurance_company_id", "policy_number", "group_no", "insurance_type", "code"];
      }
    } else {
      if (isSelfPay) {
        return ["insurance_company_id", "insurance_type"];
      } else {
        return ["insurance_company_id", "policy_number", "insurance_type", "code"];
      }
    }
  };

  useEffect(() => {
    getWidths();
  }, [selectedColumn]);

  const getWidths = () => {
    let columnWidths = [];
    if (isGroup) {
      if (selectedColumn === -1 || selectedColumn === 2 || selectedColumn === 3) {
        columnWidths = ["20%", "20%", "20%", "20%", "20%"];
      } else if (selectedColumn === 0) {
        columnWidths = ["40%", "15%", "15%", "15%", "15%"];
      } else if (selectedColumn === 1) {
        columnWidths = ["32%", "17%", "17%", "17%", "17%"];
      } else if (selectedColumn === 4) {
        columnWidths = ["16%", "16%", "16%", "16%", "36%"];
      } else {
        columnWidths = ["20%", "20%", "20%", "20%", "20%"];
      }
    } else {
      if (selectedColumn === -1 || selectedColumn === 2) {
        columnWidths = ["25%", "25%", "25%", "25%"];
      } else if (selectedColumn === 0) {
        columnWidths = ["40%", "20%", "20%", "20%"];
      } else if (selectedColumn === 1) {
        columnWidths = ["31%", "23%", "23%", "23%"];
      } else if (selectedColumn === 3) {
        columnWidths = ["21%", "21%", "21%", "37%"];
      } else {
        columnWidths = ["25%", "25%", "25%", "25%"];
      }
    }

    setTableColumnWidths(columnWidths);
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative", marginTop: "-8px" }}>
      <div
        className="resourceInfo d-flex"
        style={{
          justifyContent: "flex-end",
          marginTop: "0.5rem",
          position: "absolute",
          top: "-34px",
          right: "12px",
        }}
      >
        <button className="text-btn" style={{ marginLeft: "1rem" }} onClick={handleAddNew}>
          Add +
        </button>
      </div>

      <div className="resourceInfo d-flex" style={{ marginTop: "1.375rem" }}>
        <ExtendDataTable
          onClickColumn={(index) => setSelectedColumn(index)}
          isActiveBorder={true}
          isFixed={true}
          title={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[0],
                  height: "100%",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                  paddingLeft: "0",
                  borderLeft: 0,
                }}
              >
                Insurance Name
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[1],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                }}
              >
                Policy No
              </div>
              {isGroup && (
                <div
                  style={{
                    ...titleStyle,
                    width: tableColumnWidths[2],
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingRight: "4px",
                  }}
                >
                  Group No
                </div>
              )}
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[isGroup ? 3 : 2],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Type
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[isGroup ? 4 : 3],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Code
              </div>
            </div>
          }
          data={(valueInsurance || []).map((variable, index) => ({
            key: variable?.key,
            row_color_status:
              variable?.skip_checking === 1
                ? "yellow"
                : variable?.is_verified && variable?.is_verified === 1 && variable?.is_eligible && variable?.is_eligible === 1
                ? "green"
                : variable?.is_verified === 1 && variable?.is_eligible === 0
                ? "pink"
                : "",
            insurance_company_id: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  paddingRight: 0,
                }}
              >
                <div
                  style={{
                    paddingRight: 0,
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <Select
                    placeholder={"Insurance..."}
                    showSearch
                    id={"insurance"}
                    optionFilterProp="children"
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    className={!variable?.insurance_name ? "select-error" : ""}
                    value={!stateFocus ? variable?.insurance_name : [variable?.insurance_name, variable?.insurance_company_id]}
                    onFocus={() => {
                      setStateFocus(true);
                    }}
                    onBlur={() => {
                      setStateFocus(false);
                    }}
                    filterOption={(inp, opt) => opt.props.children.toLowerCase().indexOf(inp.toLowerCase()) >= 0}
                    notFoundContent={
                      loading_search_insurance_companies ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onSearch={(value) => {
                      searchInsurance(value);
                    }}
                    onChange={(value) => {
                      handleChangeInsurance("insurance", value, index);
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    {(search_insurance_companies_value || []).map((item, index) => (
                      <Option key={`${item?.id}-${index}`} value={stateFocus ? [item?.id, item?.name] : item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
            policy_number: (
              <div style={{ width: "100%", paddingRight: 0 }}>
                <Input
                  id={"policy_number"}
                  className={!variable?.policy_number ? "noneActive input-error" : ""}
                  placeholder="Policy No"
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable?.policy_number}
                  onChange={(e) => handleChangeInsurance("policy_number", e.target?.value, index)}
                />
              </div>
            ),
            group_no: findMedications(variable["insurance_name"]) ? (
              <div style={{ width: "100%", paddingRight: 0 }}>
                <Input
                  placeholder="Group No"
                  id={"group_no"}
                  className={!variable?.group_no ? "noneActive input-error" : ""}
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable?.group_no}
                  onChange={(e) => handleChangeInsurance("group_no", e.target?.value, index)}
                />
              </div>
            ) : (
              ""
            ),
            insurance_type: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Select
                    id={"insurance_type"}
                    placeholder={"Type..."}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={variable?.insurance_type || undefined}
                    className={!variable?.insurance_type ? "noneActive input-error" : ""}
                    onChange={(value) => handleChangeInsurance("insurance_type", value, index)}
                    style={{ width: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {(insurancesType || []).map((item, index) => (
                      <Option key={`${item?.id}-${index}`} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
            code: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  paddingRight: "8px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    width: "calc(100% - 30px)",
                    paddingRight: "8px",
                  }}
                >
                  <Select
                    id={"code"}
                    placeholder={"Code"}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={variable?.code || undefined}
                    className={!variable?.code ? "noneActive input-error" : ""}
                    onChange={(value) => handleChangeInsurance("code", value, index)}
                    style={{ width: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {CODEOPTIONS.map(({ label, value }, index) => (
                      <Option key={`code-${index}`} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="remove-icon" onClick={() => setIsDelete(index)}>
                  <Icon type="delete" />
                </div>
              </div>
            ),
            extraData: Object.keys(variable?.moreInfo).length > 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  bottom: 3,
                  paddingTop: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[0],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(0);
                    }}
                  >
                    <Input
                      className={!variable?.moreInfo?.firstName ? "noneActive input-error" : ""}
                      placeholder="First Name"
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                      }}
                      value={variable?.moreInfo?.firstName}
                      onChange={(e) => handleChangeInsurance("firstName", e.target?.value, index)}
                    />
                  </span>
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[1],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(1);
                    }}
                  >
                    <Input
                      className={!variable?.moreInfo?.lastName ? "noneActive input-error" : ""}
                      placeholder="Last Name"
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                      }}
                      value={variable?.moreInfo?.lastName}
                      onChange={(e) => handleChangeInsurance("lastName", e.target?.value, index)}
                    />
                  </span>
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[2],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(2);
                    }}
                  >
                    <InputMask
                      mask={dateMask}
                      value={variable?.moreInfo?.DOB || ""}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        handleChangeInsurance("DOB", e.target?.value, index);
                      }}
                    >
                      <Input
                        className={!variable?.moreInfo?.DOB ? "noneActive input-error" : ""}
                        placeholder="DOB"
                        style={{
                          width: "100%",
                          color: "#152935",
                          boxShadow: "none",
                        }}
                        value={variable?.moreInfo?.DOB || ""}
                      />
                    </InputMask>
                  </span>
                </div>
              </div>
            ),
          }))}
          options={getOptions()}
          widths={tableColumnWidths}
          minRows={3}
        />
      </div>
    </div>
  );
};

export default memo(InsuranceDetail);

import React, {useState} from 'react';
import {DatePicker} from 'antd';

import PatientChart from './components/PatientChart';
import PatientAnalytics from './components/PatientAnalytics';

const dateFormat = 'MM/DD/YYYY';

const RegistrationAnalytics = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <React.Fragment>
      <div
        className="cardBlock"
        style={{height: 'calc(65% - 20px)', width: '100%'}}
      >
        <div
          className="filterContainer"
          style={{padding: 0, paddingRight: '35px', paddingLeft: '35px'}}
        >
          <div className="cardBlock__title">Registration Volume</div>
          <div className="statusFilter" style={{minWidth: '200px'}}>
            <DatePicker
              format={dateFormat}
              placeholder="Start Date"
              value={startDate}
              onChange={(val) => {
                setStartDate(val);
              }}
            />
          </div>
          <div className="statusFilter" style={{minWidth: '200px'}}>
            <DatePicker
              format={dateFormat}
              placeholder="End Date"
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
              }}
            />
          </div>
        </div>

        {/* Chart */}
        <PatientChart startDate={startDate} endDate={endDate} />
      </div>

      <PatientAnalytics startDate={startDate} endDate={endDate} />
    </React.Fragment>
  );
};

export default RegistrationAnalytics;

const SelectOptions = [
  { value: "", label: "Variance" },
  { value: "contraindication", label: "Contraindication" },
  { value: "side_Effects", label: "Side Effects" },
  { value: "cost", label: "Cost" },
  { value: "refused", label: "Refused" },
];

const SelectOptionsExtra = {
  "Has the patient been on antiplatelet therapy for at least 12 weeks?": [
    { value: "", label: "Restriction" },
    { value: "Active Bleeding", label: "Active Bleeding" },
    { value: "Hypersensitivity", label: "Hypersensitivity" },
    { value: "Drug Interaction", label: "Drug Interaction" },
    { value: "Alcohol", label: "Alcohol" },
    { value: "Pregnancy", label: "Pregnancy" },
  ],
  "Has the patient been on statin therapy for at least 12 weeks?": [
    { value: "", label: "Restriction" },
    { value: "Cirrhosis", label: "Cirrhosis" },
    { value: "Liver Failure", label: "Liver Failure" },
    { value: "Hypersensitivity", label: "Hypersensitivity" },
    { value: "Drug Interaction", label: "Drug Interaction" },
    { value: "Alcohol", label: "Alcohol" },
    { value: "Pregnancy", label: "Pregnancy" },
  ],
  "Has the patient been on ACE inhibitor or Angiotensin Receptor Blocker?": [
    { value: "", label: "Restriction" },
    { value: "Aortic Stenosis", label: "Aortic Stenosis" },
    { value: "Dehydration", label: "Dehydration" },
    { value: "Angioedema", label: "Angioedema" },
    { value: "Renal Insufficiency", label: "Renal Insufficiency" },
    { value: "Hypersensitivity", label: "Hypersensitivity" },
    { value: "Drug Interaction", label: "Drug Interaction" },
    { value: "Pregnancy", label: "Pregnancy" },
  ],
  "Has the patient been on Cilostazol (Pletal) therapy for at least 12 weeks?": [
    { value: "", label: "Restriction" },
    { value: "Active Bleeding", label: "Active Bleeding" },
    { value: "CHF", label: "CHF" },
    { value: "Cardiac Disease", label: "Cardiac Disease" },
    { value: "Hypersensitivity", label: "Hypersensitivity" },
    { value: "Drug Interaction", label: "Drug Interaction" },
    { value: "Pregnancy", label: "Pregnancy" },
  ],
};

const selectACEOptions = [
  { value: "", label: "Variance" },
  { value: "Benazepril", label: "Benazepril (Lotensin)" },
  { value: "Captopril", label: "Captopril" },
  { value: "Enalapril", label: "Enalapril (Vasotec)" },
  { value: "Fosinopril", label: "Fosinopril" },
  { value: "Lisinopril", label: "Lisinopril (Zestril)" },
  { value: "Moexipril", label: "Moexipril" },
  { value: "Perindopril", label: "Perindopril" },
  { value: "Quinapril", label: "Quinapril" },
  { value: "Ramipril", label: "Ramipril (Altace)" },
  { value: "Trandolapril", label: "Trandolapril" },
  { value: "Azilsartan", label: "Azilsartan (Edarbi)" },
  { value: "Candesartan", label: "Candesartan (Atacand)" },
  { value: "Irbesartan", label: "Irbesartan (Avapro)" },
  { value: "Losartan", label: "Losartan (Cozaar)" },
  { value: "Olmesartan", label: "Olmesartan (Benicar)" },
  { value: "Telmisartan", label: "Telmisartan (Micardis)" },
  { value: "Valsartan", label: "Valsartan (Diovan)" },
];

export { SelectOptions, SelectOptionsExtra };

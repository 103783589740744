import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getTimeEntries, setSidebarState } from "../../../../store/actions";
import DashboardSidebar from "./DashboardSidebar";

const Dashboard = ({ loading }) => {
  const [tableRows, setTableRows] = useState([]);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const timeEntries = useSelector((state) => state.common.timeEntries);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setSidebarState(false));
    }
    dispatch(getTimeEntries());
    dispatch(setSidebarState(true));
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  useEffect(() => {
    if (timeEntries?.length > 0) {
      setTableRows(timeEntries);
    }
  }, [timeEntries]);

  const handleRow = (_, index) => {};

  const loadMore = () => {
    // TODO: API integration with pagination
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_dashboard"
                labels={["Pay Period", "Badge In", "Badge Out", "Regular Hours", "OT Hours"]}
                widths={["24%", "19%", "19%", "19%", "19%"]}
                columns={[
                  { sortable: false, key: "period" },
                  { sortable: false, key: "bagde_in" },
                  { sortable: false, key: "bagde_out" },
                  { sortable: false, key: "regular_hours" },
                  { sortable: false, key: "ot_hours" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  key: index,
                  id: row?.id,
                  period: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row?.icon && row?.icon}
                      {!row?.icon && <span style={{ minWidth: "26px" }}></span>}
                      <p style={{ paddingLeft: "0.75rem" }}>
                        {`${moment(row?.pay_periods?.from_date).format("MM/DD/YYYY")} - 
                        ${moment(row?.pay_periods?.to_date).format("MM/DD/YYYY")}`}
                      </p>
                    </div>
                  ),
                  bagde_in: row?.in_date,
                  bagde_out: row?.out_date,
                  regular_hours: row?.hours,
                  ot_hours: row?.overtime,
                }))}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {currentUserInfo && currentUserInfo?.name && (
        <DashboardSidebar ref={sidebarRef} currentUserInfo={currentUserInfo} isShow={isSidebar} bagdeTime={timeEntries} />
      )}
    </div>
  );
};

export default Dashboard;

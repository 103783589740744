import React, {useState, useEffect} from 'react';
import {Input, DatePicker} from 'antd';
import moment from 'moment';
import {ExpandSVG} from '../icons/SVGIcon';
import './style.scss';

const EditableText = ({label, handleChange, type, isEditable, isError}) => {
  const [editable, setEditable] = useState(true);
  const [title, setTitle] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    setTitle(label);
    if (!isError) {
      setError(false);
    }
  }, [label, isEditable, isError]);

  const enableBlur = () => {
    if (isError) {
      setError(true);
    }
  };

  const onChange = (e) => {
    setTitle(e.target?.value);
    if (handleChange) {
      handleChange(e.target?.value);
    }
  };

  const onChangeDate = (date, dateString) => {
    setTitle(dateString);
    if (handleChange) {
      handleChange(dateString);
    }
  };

  const dateFormat = 'MM/DD/YYYY';

  return (
    <div
      className={`editableText ${
        isEditable || !title || editable ? 'open' : 'no-open'
      }`}
    >
      {!isEditable && !!title && !editable && (
        <div
          className={`text-value ${!!title ? '' : 'empty'}`}
          onClick={() => setEditable(true)}
        >
          {!!title ?
            type === 'date' ?
              moment(title).format('MM/DD/YYYY') :
              title :
            ''}
        </div>
      )}
      {(isEditable || !title || editable) && (!type || type !== 'date') && (
        <Input
          value={title}
          onChange={onChange}
          onPressEnter={enableBlur}
          onBlur={enableBlur}
          className={error ? 'error' : ''}
        />
      )}
      {(isEditable || !title || editable) && type && type === 'date' && (
        <DatePicker
          value={!!title ? moment(title) : undefined}
          format={dateFormat}
          autoFocus={true}
          onChange={onChangeDate}
          suffixIcon={<ExpandSVG />}
        />
      )}
    </div>
  );
};

export default EditableText;

import React from 'react';
import {YellowAlertSVG} from '../icons/SVGIcon';
import './style.scss';

const SidebarTitle = ({icon, title, subTitle, width,style}) => {
  return (
    <div
      className={`title sidebarTitle ${width ? width : ''}`}
      style={{
        display: 'block',
        paddingLeft: '2rem',
        position: 'relative',
        ...style
      }}
    >
      <div style={{textTransform: 'uppercase'}}>
        {icon && icon}
        {!icon && <YellowAlertSVG />}
        <span style={{fontWeight: ''}}>{title}</span>
        <br />
        {subTitle}
      </div>
    </div>
  );
};

export default SidebarTitle;

import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const adminUserGetSignatureImage = createActionThunk(
    AT.ADMIN_USER_GET_SIGNATURE_IMAGE,
    (provider_id) => API.adminUserGetSignatureImage(provider_id),
);

export const get_employee_documents = createActionThunk(
    AT.GET_EMPLOYEE_DOCUMENTS,
    (param) => API.get_employee_documents(param),
);

export const get_detail_employee_documents = createActionThunk(
    AT.GET_DETAIL_EMPLOYEE_DOCUMENTS,
    (id) => API.get_detail_employee_documents(id),
);

export const delete_employee_documents = createActionThunk(
    AT.DELETE_EMPLOYEE_DOCUMENTS,
    (id) => API.delete_employee_documents(id),
);

export const send_fax_employee_documents = createActionThunk(
    AT.SEND_FAX_EMPLOYEE_DOCUMENTS,
    (id, params) => API.send_fax_employee_documents(id, params),
);

export const email_fax_employee_documents = createActionThunk(
    AT.EMAIL_FAX_EMPLOYEE_DOCUMENTS,
    (id, params) => API.email_fax_employee_documents(id, params),
);

export const print_fax_employee_documents = createActionThunk(
    AT.PRINT_FAX_EMPLOYEE_DOCUMENTS,
    (id) => API.print_fax_employee_documents(id),
);

export const get_job_status = createActionThunk(
    AT.GET_JOB_STATUS,
    () => API.get_job_status(),
);


/* eslint-disable react-hooks/exhaustive-deps */
import ImageEditor from "@toast-ui/react-image-editor";
import { Icon, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { BsFonts } from "react-icons/bs";
import "tui-image-editor/dist/tui-image-editor.css";
import "./style.scss";

const myTheme = {
  "common.backgroundColor": "#f4f7fb",
  "common.border": "0px",
  // header
  "header.display": "none",
  "downloadButton.display": "none",
  "loadButton.display": "none",
  "submenu.display": "none",

  "menu.iconSize.width": "18px",
  "menu.iconSize.height": "18px",
  // 'submenu.iconSize.width': '18px',
  // 'submenu.iconSize.height': '18px',

  // editor control
  "controls.backgroundColor": "#000",
  "controls.height": "40px",
  // submenu primary color
  // 'submenu.backgroundColor': '#000',
  // 'submenu.partition.color': '#858585',
  // rango style
  // 'range.pointer.color': '#fff',
  // 'range.bar.color': '#666',
  // 'range.subbar.color': '#d1d1d1',

  // 'range.disabledPointer.color': '#414141',
  // 'range.disabledBar.color': '#282828',
  // 'range.disabledSubbar.color': '#414141',

  // 'range?.value.color': '#fff',
  // 'range?.value.fontWeight': 'lighter',
  // 'range?.value.fontSize': '11px',
  // 'range?.value.border': '1px solid #353535',
  // 'range?.value.backgroundColor': '#151515',
  // 'range.title.color': '#fff',
  // 'range.title.fontWeight': 'lighter',

  // colorpicker style
  // 'colorpicker.button.border': '1px solid #1e1e1e',
  // 'colorpicker.title.color': '#fff'
};

function Editor({ imageData, onSaveImage, onCloseEditor, disableEditing = false }) {
  const [uiElement, setUIElement] = useState();
  const [currentMode, setCurrentMode] = useState(null);
  const currentStateRef = useRef(null);
  const editorRef = useRef();
  useEffect(() => {
    if (!!imageData) {
      setUIElement({
        loadImage: {
          path: imageData.url + "?t=" + new Date().getTime(),
          name: `image-${imageData?.id}`,
        },
        theme: myTheme,
        menu: disableEditing ? [] : ["crop", "draw", "text"],
        uiSize: { width: "100%" },
        menuBarPosition: "bottom",
      });
    }
  }, [imageData]);

  const handleCloseEditor = () => {
    const editorInstance = editorRef.current.getInstance();
    const data = editorInstance.toDataURL();
    onSaveImage({
      updatedImage: data,
      originImage: imageData,
    });
    onCloseEditor();
  };

  const onMouseDown = (_, position) => {
    const editorInstance = editorRef.current.getInstance();
    let x;
    let y;
    if (currentStateRef?.current !== "TEXT" || !position) {
      return;
    }
    x = parseInt(position.x, 10);
    y = parseInt(position.y, 10);
    editorInstance
      .addText("Write Here", {
        styles: {
          fill: "#ff0000",
          fontSize: 60,
          fontWeight: "bold",
          fontFamily: "IBM plex sans",
        },
        position: {
          x,
          y,
        },
      })
      .then(() => {});
  };

  const cropHandler = () => {
    const editorInstance = editorRef.current.getInstance();
    if (currentMode !== "CROP") {
      setCurrentMode("CROP");
      editorInstance.startDrawingMode("CROPPER");
    } else {
      editorInstance.stopDrawingMode();
      setCurrentMode(null);
    }
  };

  const textHandler = () => {
    const editorInstance = editorRef.current.getInstance();
    if (currentMode === null) {
      setCurrentMode("TEXT");
      currentStateRef.current = "TEXT";
      editorInstance.changeCursor("text");
    } else if (currentMode === "TEXT") {
      currentStateRef.current = null;
      setCurrentMode(null);
      editorInstance.changeCursor("default");
    } else if (currentMode === "DRAW") {
      setCurrentMode("TEXT");
      currentStateRef.current = "TEXT";
      editorInstance.changeCursor("text");
      editorInstance.stopDrawingMode();
    }
  };

  const drawHandler = () => {
    const editorInstance = editorRef.current.getInstance();
    if (currentMode === null) {
      setCurrentMode("DRAW");
      editorInstance.startDrawingMode("FREE_DRAWING", {
        width: 10,
        color: "ff0000",
      });
    } else if (currentMode === "DRAW") {
      setCurrentMode(null);
      editorInstance.stopDrawingMode();
    } else if (currentMode === "TEXT") {
      setCurrentMode("DRAW");
      currentStateRef.current = null;
      editorInstance.startDrawingMode("FREE_DRAWING", {
        width: 10,
        color: "ff0000",
      });
    }
  };

  return (
    !!uiElement && (
      <div className="image-editor-wrapper">
        <ImageEditor
          ref={editorRef}
          includeUI={uiElement}
          selectionStyle={{
            cornerSize: 5,
            rotatingPointOffset: 70,
          }}
          onMousedown={onMouseDown}
          usageStatistics={true}
        />

        <Tooltip placement="top" title="Close Image Editor">
          <Icon type="close" className="image-close-icon" onClick={handleCloseEditor} />
        </Tooltip>
        <ul className="menu-bar">
          <li className={currentMode === "DRAW" ? "active" : ""} onClick={drawHandler}>
            <BiPencil />
          </li>
          <li className={currentMode === "TEXT" ? "active" : ""} onClick={textHandler}>
            <BsFonts />
          </li>
        </ul>
      </div>
    )
  );
}

export default Editor;

const ReplaceNN = (value, isReview) => {
  if (value && value.length > 0) {
    let mixFix = value;
    if (
      JSON.stringify(value).includes(`!!new!!`) ||
      value.includes('!!new!!')
    ) {
      value.split(`!!new!!`).map((r, index) => {
        mixFix = mixFix.replace(`!!new!!`, '<br/>');
      });
    }
    return mixFix;
  }
  return null;
};

export default ReplaceNN;

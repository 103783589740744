import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcedureType } from "../../../../store/actions";
const ROS = ({ type, onChangeData, dataCheck, keyPanel, dataAll, isLocked, pageTask, setPageTask, subPro }) => {
  const [data, setData] = useState([]);
  const [array_type, set_array_type] = useState([]);
  const procedure_type = useSelector((state) => state.procedureDetail.procedure_type);

  const paramsChart = useSelector((state) => state.patientRecords.paramsChart);
  const dispatch = useDispatch();

  const onChangeComments = (index, comments) => {
    const data_ = [...data];
    data_[index]["comments"] = comments;
    onChangeData(data_, pageTask || 0);
  };
  const onChangeValues = (index, values) => {
    const data_ = [...data];
    data_[index]["values"] = values;
    onChangeData(data_, pageTask || 0);
  };
  const updateData = (valueArr) => {
    if (dataAll && dataAll?.specAll) {
      const dataAllCheck = dataAll?.specAll;
      const valueArrCheck = (valueArr || []).map((item) => {
        let idvari = 0;
        return {
          comments: "",
          id: item?.id,
          type: item?.question && item?.question.length > 18 ? `${item?.question.substring(0, 18)}...` : item?.question,
          is_multiple: item.is_multiple,
          typeFull: item?.question,
          group: item?.group || 0,
          variable_name: item?.variable_name,
          values: (item?.answers || []).map((r) => {
            idvari = r.procedure_type_variable_id;
            return {
              id: r?.id,
              option: r.answer,
              checked: false,
              text_template: r.text_template,
              procedure_type_variable_id: r.procedure_type_variable_id,
              variable_name: item?.variable_name,
            };
          }),
          procedure_type_variable_id: idvari,
        };
      });
      (valueArrCheck || []).map((r) => {
        if (!dataAllCheck.some((a) => a?.id === r?.id)) {
          dataAllCheck.push({
            ...r,
            group: pageTask || 0,
          });
        }
      });
      setData(dataAllCheck);
    } else if (valueArr && valueArr.length > 0) {
      const arrayCheck = (valueArr || []).map((item) => {
        let idvari = 0;
        return {
          comments: "",
          id: item?.id,
          type: item?.question && item?.question.length > 18 ? `${item?.question.substring(0, 18)}...` : item?.question,
          is_multiple: item.is_multiple,
          typeFull: item?.question,
          group: item?.group || 0,
          variable_name: item?.variable_name,
          values: (item?.answers || []).map((r) => {
            idvari = r.procedure_type_variable_id;
            return {
              id: r?.id,
              option: r.answer,
              checked: false,
              text_template: r.text_template,
              procedure_type_variable_id: r.procedure_type_variable_id,
              variable_name: item?.variable_name,
            };
          }),
          procedure_type_variable_id: idvari,
        };
      });
      let checkTrue = [];

      if (dataAll && dataAll?.specifications) {
        (dataAll.specifications || []).map((r, index) => {
          checkTrue = [
            ...checkTrue,
            {
              findindex: arrayCheck.findIndex((i) => i?.id == r.procedure_type_variable_id),
              valueindex:
                arrayCheck[index] && arrayCheck[index]["values"] ? arrayCheck[index]["values"].findIndex((check) => check.option == r.answer) : -1,
              valuecheck: r.answer,
            },
          ];
        });
      }
      (checkTrue || []).map((r) => {
        if (
          arrayCheck[r.findindex] &&
          arrayCheck[r.findindex]["values"] &&
          arrayCheck[r.findindex]["values"][r?.valueindex] &&
          arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] !== undefined
        ) {
          arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] = true;
        }
      });
      setData([...arrayCheck]);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    if (procedure_type && procedure_type.length > 0) {
      (procedure_type || []).map((r) => {
        if (!array_type.some((a) => a?.id === r?.id)) {
          array_type.push({
            ...r,
            group: pageTask || 0,
          });
        }
      });
      updateData([...array_type]);
      set_array_type([...array_type]);
    } else {
      set_array_type([]);
    }
  }, [procedure_type, pageTask, dataAll]);

  useEffect(() => {
    if (!procedure_type || procedure_type.length == 0) {
      if (dataAll?.appointment_type_id?.[0]) {
        set_array_type([]);
        setPageTask(0);
        dispatch(getProcedureType(dataAll?.appointment_type_id?.[0]?.value));
      }
    }
  }, [procedure_type, subPro]);

  return (
    <React.Fragment>
      {(data.filter((r) => r.group == pageTask || 0) || []).map((item, index) => (
        <ROSItem
          key={`examination-item-${index}`}
          title={item.typeFull}
          comments={item.comments}
          values={item?.values}
          items={item}
          onChangeComments={(comments) => {
            const indexFind = data.findIndex((r) => r?.id == item?.id);
            onChangeComments(indexFind, comments);
          }}
          onChangeValues={(values) => {
            const indexFind = data.findIndex((r) => r?.id == item?.id);
            onChangeValues(indexFind, values);
          }}
          isLocked={isLocked}
        />
      ))}
    </React.Fragment>
  );
};

const ROSItem = (props) => {
  const [title, setTitle] = useState("");
  const [values, setValues] = useState([]);
  const loading_procedure_type = useSelector((state) => state.procedureDetail.loading_procedure_type);

  useEffect(() => {
    setTitle(props.title);
    setValues(props?.values);
  }, [props.comments, props?.values]);

  const onClickOption = (index, is_multiple, optChecked) => {
    if (is_multiple) {
      const values_ = [...values];
      values_[index]["checked"] = !values_[index]["checked"];
      props.onChangeValues(values_);
      setValues(values_);
    } else {
      const values_ = [...values];
      values_.map((r, i) => {
        values_[i]["checked"] = false;
      });
      if (values_[index]["checked"] == false && optChecked == true) {
        values_[index]["checked"] = false;
      } else {
        values_[index]["checked"] = !values_[index]["checked"];
      }
      props.onChangeValues(values_);
      setValues(values_);
    }
  };

  if (props?.items.is_multiple == 1 || props?.items.is_multiple == true) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          position: "relative",
        }}
        className={`ExaminationROSItem`}
      >
        <div style={{ width: "40%" }}>
          <div className="ExaminationROSItem__label" style={{ width: "100%", overflow: "hidden" }}>
            {title}
          </div>
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <div style={{ width: "100%" }} className={`ExaminationROSItem__options`}>
            {(values || []).map((opt, index) => (
              <button
                key={`ros-item-${title}-${index}`}
                className={`ExaminationROSItem__option ${opt.checked && "active"}`}
                onClick={() => onClickOption(index, true)}
                disabled={props.isLocked}
              >
                {opt.option}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          position: "relative",
        }}
        className={`ExaminationROSItem`}
      >
        <div style={{ width: "40%" }}>
          <div className="ExaminationROSItem__label" style={{ width: "100%", overflow: "hidden" }}>
            {title}
          </div>
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <div style={{ width: "100%" }} className={`ExaminationROSItem__options`}>
            {(values || []).map((opt, index) => (
              <button
                key={`ros-item-${title}-${index}`}
                className={`ExaminationROSItem__option ${opt.checked && "active"}`}
                onClick={() => onClickOption(index, false, opt.checked)}
                disabled={props.isLocked}
              >
                {opt.option}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default ROS;

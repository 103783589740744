import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import YesNo from "../../../common/components/YesNo/YesNoOne";
import { setStepData } from "../../../store/actions";

const initialData = {
  leg_swelling: -1,
  smoked_cigarettes: -1,
  history_aneurysm: -1,
  suffered_dvt: -1,
  leg_pain: -1,
  stroke_symptoms: -1,
};

const ThirdStepComponent = ({ handleContinue, ...restProps }) => {
  const [questionnaire, setQuestions] = useState(initialData);
  const { questions } = useSelector((state) => ({
    resource: state.newPatient.resource,
    questions: state.newPatient.questions,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (questions) {
      const newData = {
        leg_swelling: questions.leg_swelling,
        smoked_cigarettes: questions.smoked_cigarettes,
        history_aneurysm: questions.history_aneurysm,
        suffered_dvt: questions.suffered_dvt,
        leg_pain: questions.leg_pain,
        stroke_symptoms: questions.stroke_symptoms,
      };
      setQuestions(newData);
    }
  }, [questions]);

  const handleChangeOption = (type) => (value) => {
    const newData = { ...questionnaire };
    if (newData[type] === value) {
      newData[type] = -1;
    } else {
      newData[type] = value;
    }
    dispatch(setStepData("questions", newData));
  };

  return (
    <div className="resourceContent pr-7px" style={{ paddingLeft: "10px" }}>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>Do you have leg swelling and/or varicose veins? </div>
        <YesNo id="leg_swelling" option={questionnaire.leg_swelling} setOption={handleChangeOption("leg_swelling")} />
      </div>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>Have you smoked over 100 cigarettes in your lifetime? </div>
        <YesNo id="smoked_cigarettes" option={questionnaire.smoked_cigarettes} setOption={handleChangeOption("smoked_cigarettes")} />
      </div>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>Do you or your family have a history of abdominal aortic aneurysm? </div>
        <YesNo id="history_aneurysm" option={questionnaire.history_aneurysm} setOption={handleChangeOption("history_aneurysm")} />
      </div>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>Have you suffered from deep vein thrombosis (DVT)? </div>
        <YesNo id="suffered_dvt" option={questionnaire.suffered_dvt} setOption={handleChangeOption("suffered_dvt")} />
      </div>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>Do you suffer from pain, numbness, coldness or weakness of the legs? </div>
        <YesNo id="leg_pain" option={questionnaire.leg_pain} setOption={handleChangeOption("leg_pain")} />
      </div>
      <div className="resourceInfo between half-pannel">
        <div style={{ width: "calc(100% - 180px)" }}>
          Have you experienced any stroke-like symptoms? (loss of vision in an eye, slurring of speech, paralysis of an arm or a leg?){" "}
        </div>
        <YesNo id="stroke_symptoms" option={questionnaire.stroke_symptoms} setOption={handleChangeOption("stroke_symptoms")} />
      </div>
    </div>
  );
};

export default ThirdStepComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import { TabHeaders } from "../constant";
import AllPersonalTickets from "./DetailsComponent/PersonalTickets";

const PersonalTickets = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Personal Support Tickets" subStatus={''} />
      <div
        className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''
          }`}
      >
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
          <Tabs
              TabIndicatorProps={{style:{display:'none'}}}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
              <div style={{ display: "flex" }}>
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? 'active' : ''
                      }`}
                    style={{ width: tab.width }}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <AllPersonalTickets title="support-detail" />}
        {activeTab === 1 && (
          <AllPersonalTickets title="support-detail" filter="open" />
        )}
        {activeTab === 2 && (
          <AllPersonalTickets title="support-detail" filter="closed" />
        )}
      </div>
    </div>
  );
};

export default PersonalTickets;

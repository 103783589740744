import React, { useState, useEffect, useRef } from "react";
import { Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import PDFView from "../../../../../common/components/PDFView";
import PatientChartSideBar from "./sidebar";
import { StaticResource } from "./constant";
import { SummaryEmptySVG } from "../../../../../common/components/icons/SVGIcon";
import { get_employee_documents, setSidebarState } from "../../../../../store/actions";
import { getLocal } from "../../../../../helpers/Local";

const { TabPane } = Tabs;

const PatientChart = () => {
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);

  const selectedDocument = useSelector((state) => state.adminUser.detailEmployeeDocuments);

  const loadingGetDataDocument = useSelector((state) => state.adminUser.loadingEmployeeDocument);

  const dispatch = useDispatch();
  const user_id = getLocal("cvai-current-user");
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState("documentation");

  useEffect(() => {
    let params = { user_id: user_id };
    if (activeTab === "documentation") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "image-viewer-tab") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    } else if (activeTab === "laboratory") {
      params = {
        ...params,
        category: "",
        provider_id: "",
        created_at: "",
      };
    }
    dispatch(get_employee_documents(params));
  }, [activeTab]);

  useEffect(() => {
    dispatch(setSidebarState(true));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else if (patientResource) {
      setIsSidebar(true);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleTab = (activeTab) => {
    setActiveTab(activeTab);
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <Tabs className="patientChart" type="card" onChange={handleTab} activeTab={activeTab}>
          <TabPane tab="Documentation" key="documentation">
            {selectedDocument && !loadingGetDataDocument ? (
              <PDFView url={selectedDocument.filename} />
            ) : (
              <>
                <div
                  className="empty-container"
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#fff",
                  }}
                >
                  <SummaryEmptySVG />
                </div>
              </>
            )}
          </TabPane>
        </Tabs>
      </div>
      <PatientChartSideBar
        ref={sidebarRef}
        resource={StaticResource}
        activeTab={activeTab}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default PatientChart;

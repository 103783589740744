import {createActionThunk} from 'redux-thunk-actions';

import AT from '../actionTypes';

import * as API from '../../services/api.services';

export const getFilterTypes = createActionThunk(AT.GET_FILTER_TYPES, () =>
  API.getFilterListOfType(),
);

export const getReminders = createActionThunk(
    AT.GET_REMINDERS,
    ({recent_contact_date, appointment_date}) =>
      API.getClinicReminders({recent_contact_date, appointment_date}),
);

export const selectReminder = createActionThunk(
    AT.SELECT_REMINDER,
    (id, procedure_date) => API.getPatient({id, procedure_date}),
);

export const setReminderData = createActionThunk(
    AT.SET_REMINDER_DATA,
    (type, value) => ({[type]: value}),
);

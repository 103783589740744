import React from 'react';
import {DragPreviewImage, useDrag} from 'react-dnd';
import {DevicesSVG} from '../icons/SVGIcon';
import './style.scss';

const LabelIcon = ({
  icon,
  label,
  item,
  handleClick,
  isHover,
  active,
  isDraggable,
}) => {
  const [{isDragging}, drag, preview] = useDrag({
    item: {type: 'operation', option: item, pos: -1},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const onClickLabel = () => {
    if (!isDragging && handleClick) {
      handleClick();
    }
  };

  return (
    <div
      className={`labelIcon ${isHover ? 'hoverEffect' : ''} ${
        active ? 'active' : ''
      }`}
      onClick={onClickLabel}
    >
      <DragPreviewImage connect={preview} />
      <div ref={isDraggable && drag} className="labelIcon-iconContent">
        {icon || <DevicesSVG />}
      </div>
      <div className="labelIcon-iconLabel">{label}</div>
    </div>
  );
};

export default LabelIcon;

import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { get_job_status } from "../../../../services/api.services";
import { setGlobalLoading } from "../../../../store/actions";

const Health = ({ data, title }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [tableRows, setTableRows] = useState(null);
  const [totalResult, setTotalResult] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const callGetHealth = async () => {
    setLoading(true);
    const dataSuccess = await get_job_status();
    setTableRows((dataSuccess && dataSuccess?.data) || []);
    setTotalResult((dataSuccess && dataSuccess?.total_results) || 0);
    setLoading(false);
  };

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index, id) => () => {
    setRow(index);
    if (index !== activeIndex) {
    }
  };

  const loadMore = (values) => {
    if (totalResult === 0 || page === totalResult) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      const success = [];
      if (success?.data?.length > 0) {
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    callGetHealth();
  }, []);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 4;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > 4) {
    emptyRows = new Array(rowCount - 4).fill({});
  }

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
            <div className="tableItem">
              <div className="th" style={{ width: "36%", marginLeft: "3rem" }}>
                <b> {"Process Name"}</b>
              </div>
              <div className="th" style={{ width: "34%" }}>
                <b>{"Recent Run"} </b>
              </div>
              <div className="th" style={{ width: "34%" }}>
                <b>{"Errors"} </b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <ScrollWrapper onScrollFrame={loadMore} css="no-padding x-hidden">
                <div className="tableList">
                  {(tableRows || []).map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "36%" }}>
                        <div className="iconField d-flex">
                          {row?.status === "processing" || row?.status === "new" || row?.status == "starting" ? (
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          ) : (
                            <>
                              {row?.status === "failed" || row?.status === "stopped" ? (
                                <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                              ) : (
                                <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                              )}
                            </>
                          )}
                        </div>
                        <p>{row?.description}</p>
                      </div>
                      <div className="td" style={{ width: "34%" }}>
                        <p>{row?.most_recent_job}</p>
                      </div>
                      <div className="td" style={{ width: "34%" }}>
                        <p>{row?.errors}</p>
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                      <div className="td" style={{ width: "36%" }} />
                      <div className="td" style={{ width: "34%" }} />
                      <div className="td" style={{ width: "34%" }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Health;

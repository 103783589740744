import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getDetailPolicies, getPolicies } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import ManualPoliciesSidebar from "./ManualPoliciesSidebar";

const ManualPolicies = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const policies = useSelector((state) => state.common.policies);
  const policiesDetail = useSelector((state) => state.common.policiesDetail);
  const tableRows = useMemo(() => {
    const sortedPolicies = policies?.sort((a, b) => a?.policy_number - b?.policy_number);
    return sortedPolicies;
  }, [policies]);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index) => {
    if (activeIndex != index) {
      setRow(index);
    }
    if (!policiesDetail || policiesDetail?.id !== tableRows[index].id) {
      dispatch(getDetailPolicies(tableRows[index].id));
      handleSidebar();
    }
    if (policiesDetail?.id === tableRows[index].id) {
      handleSidebar();
    }
  };

  const fetchList = () => {
    dispatch(getPolicies());
  };

  useEffect(() => {
    if (policies.length <= 0) {
      fetchList();
    }
  }, [policies]);

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="manual_policies"
                labels={["ID", "Subject", "Updated"]}
                widths={["calc(25% - 2rem)", "50%", "25%"]}
                columns={[
                  { sortable: false, key: "id" },
                  { sortable: false, key: "subject" },
                  { sortable: false, key: "updated" },
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index)}
                defaultActive={-1}
                activeIndex={activeIndex}
                rowData={tableRows.map((row, index) => {
                  return {
                    id: (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ paddingLeft: "2.735rem" }}>{row?.policy_number}</p>
                      </div>
                    ),
                    subject: row.policy_name,
                    updated: row.effective_date && moment(row.effective_date).format("MM/DD/YY"),
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {policiesDetail && activeIndex >= 0 && isSidebar && (
        <ManualPoliciesSidebar
          ref={sidebarRef}
          policiesDetail={policiesDetail}
          resource={StaticResource}
          isShow={isSidebar}
          changeActiveIndex={setRow}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default ManualPolicies;

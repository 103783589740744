import React, { useEffect, useRef, useState } from "react";
import { AiOutlineRedo } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";
import EditableInputWithSingleDiv from "../../../../common/components/EditableText/EditableInputWithSingleDiv";
import { getLocal } from "../../../../helpers/Local";
import { post_ultrasound_question, setGlobalLoading } from "../../../../store/actions";
import { Checkbox } from "antd";
import RoundDown from "src/helpers/RoundNumberDown";

const QUESTIONS = [
  "Does the patient have a vein graft stenosis (>75%) by Duplex?",
  "Does the patient have in-stent or recurring stenosis (> 75%) by Duplex?",
  "Does the patient have (> 75%) stenosis A. Common iliac arterial lesion B. External iliac arterial lesion C. Femoral arterial lesion D. Popliteal arterial lesion by Duplex?",
  "Tibial Arterial Lesion (> 75%) by Duplex?",
];
const BUTTONS = [
  {
    text: "Right",
    value: "right",
  },
  {
    text: "Left",
    value: "left",
  },
  {
    text: "None",
    value: "none",
  },
];
const skipReasons = ["Amputee", "Intolerant"];

const Questionnaire = ({ ...restProps }) => {
  const dataRef = useRef();
  const [data, setData] = useState({
    rightResting: "",
    leftResting: "",
    rightExercise: "",
    leftExercise: "",
    rightToePressure: "",
    leftToePressure: "",
    [`question-0`]: "",
    [`question-1`]: "",
    [`question-2`]: "",
    [`question-3`]: "",
    abi_right_resting: 0,
    abi_left_resting: 0,
    abi_right_exercise: 0,
    abi_left_exercise: 0,
    skip_tbi_right: false,
    skip_tbi_left: false,
    skip_abi_exercise_right: false,
    skip_abi_exercise_left: false,
    skip_abi_exercise_right_reason: "",
    skip_abi_exercise_left_reason: "",
    skip_tbi_right_reason: "",
    skip_tbi_left_reason: "",
    tbi_right: 0,
    tbi_left: 0,
    skip_abi_resting_right: false,
    skip_abi_resting_left: false,
    abi_right_resting_type: "",
    abi_left_resting_type: "",
    abi_right_exercise_type: "",
    abi_left_exercise_type: "",
  });
  const [showCondition, setShowCondition] = useState(false);
  const [hideRightAbiResting, setHideRightAbiResting] = useState(false);
  const [hideLeftAbiResting, setHideLeftAbiResting] = useState(false);
  const [hideRightAbiExercise, setHideRightAbiExercise] = useState(false);
  const [hideLeftAbiExercise, setHideLeftAbiExercise] = useState(false);
  const [hideTbiRight, setHideTbiRight] = useState(false);
  const [hideTbiLeft, setHideTbiLeft] = useState(false);

  useEffect(() => {
    if (restProps?.sidebarTitle === "Lower Extremity Arteries") {
      setShowCondition(true);
    } else {
      setShowCondition(false);
    }
  }, [restProps.sidebarTitle]);

  const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");

  const questionnaireData = useSelector((state) => state.ultrasound.questionnaireData);
  const dispatch = useDispatch();
  const loadingGetQuestion = useSelector((state) => state.ultrasound.loadingGetQuestion);
  const { rightResting, leftResting, rightExercise, leftExercise, rightToePressure, leftToePressure } = data;

  useEffect(() => {
    if (
      rightResting &&
      leftResting &&
      rightExercise &&
      leftExercise &&
      rightToePressure &&
      leftToePressure &&
      data[`question-0`] &&
      data[`question-1`] &&
      data[`question-2`] &&
      data[`question-3`]
    ) {
      restProps.checkQuestionaire(true);
    } else {
      restProps.checkQuestionaire(false);
    }
  }, [data]);

  const refreshRightRestingAbi = (data) => {
    let a2 = 0;
    let b2 = 0;
    let a1 = 0;
    let b1 = 0;
    let stateCheck = {};
    let maxA = 0;

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
      if (data["rightResting"].split("/")[0]) {
        a1 = data["rightResting"].split("/")[0];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }

    maxA = Math.max(a2, b2);
    stateCheck = {
      ...data,
      abi_right_resting: RoundDown(a1, maxA),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideRightAbiResting(true);
  };
  const refreshLeftRestingAbi = (data) => {
    let a2 = 0;
    let b2 = 0;
    let b1 = 0;
    let stateCheck = {};
    let maxA = 0;

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }

    maxA = Math.max(a2, b2);
    stateCheck = {
      ...data,
      abi_left_resting: RoundDown(b1, maxA),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideLeftAbiResting(true);
  };

  const refreshRightExerciseAbi = (data) => {
    let c2 = 0;
    let d2 = 0;
    let c1 = 0;
    let stateCheck = {};
    let maxC = 0;

    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
      if (data["rightExercise"].split("/")[0]) {
        c1 = data["rightExercise"].split("/")[0];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
    }

    maxC = Math.max(c2, d2);
    stateCheck = {
      ...data,
      abi_right_exercise: RoundDown(c1, maxC),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideRightAbiExercise(true);
  };

  const refreshLeftExerciseAbi = (data) => {
    let c2 = 0;
    let d2 = 0;
    let d1 = 0;
    let stateCheck = {};
    let maxC = 0;

    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
      if (data["leftExercise"].split("/")[0]) {
        d1 = data["leftExercise"].split("/")[0];
      }
    }

    maxC = Math.max(c2, d2);
    stateCheck = {
      ...data,
      abi_left_exercise: RoundDown(d1, maxC),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideLeftAbiExercise(true);
  };

  const refreshTbiRight = (data) => {
    let e = 0;
    let a2 = 0;
    let b2 = 0;
    let maxA = 0;
    let tbi_right = 0;
    let stateCheck = {};

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
    }

    maxA = Math.max(a2, b2);
    if (e > 0 && maxA > 0) {
      tbi_right = e / maxA;
      if (tbi_right < 0.1) tbi_right = 0.1;
      else tbi_right = RoundDown(tbi_right);
    } else {
      tbi_right = null;
    }

    stateCheck = {
      ...data,
      tbi_right: tbi_right,
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideTbiRight(true);
  };

  const refreshTbiLeft = (data) => {
    let f = 0;
    let a2 = 0;
    let b2 = 0;
    let maxA = 0;
    let tbi_left = 0;
    let stateCheck = {};

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
    }

    maxA = Math.max(a2, b2);

    if (f > 0 && maxA > 0) {
      tbi_left = f / maxA;
      if (tbi_left < 0.1) tbi_left = 0.1;
      else tbi_left = RoundDown(tbi_left);
    } else {
      tbi_left = null;
    }

    stateCheck = {
      ...data,
      tbi_left: tbi_left,
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideTbiLeft(true);
  };

  const checkCondition = (data) => {
    if (showCondition) {
      let a2 = null;
      let b2 = null;
      let c2 = null;
      let d2 = null;
      let a1 = null;
      let b1 = null;
      let c1 = null;
      let d1 = null;
      let e = null;
      let f = null;
      let maxA = 0;
      let maxC = 0;
      let stateCheck = {};
      let tbi_right = null;
      let tbi_left = null;
      let abi_right_resting = 0;
      let abi_left_resting = 0;
      let abi_right_exercise = 0;
      let abi_left_exercise = 0;
      let skip_abi_exercise_left_reason = "";
      let skip_abi_exercise_right_reason = "";
      let skip_tbi_right_reason = "";
      let skip_tbi_left_reason = "";

      if (data && data["rightToePressure"]) {
        e = data["rightToePressure"];
      }
      if (data && data["leftToePressure"]) {
        f = data["leftToePressure"];
      }
      if (data && data["rightResting"]) {
        if (data["rightResting"].split("/")[1]) {
          a2 = data["rightResting"].split("/")[1];
        }
        if (data["rightResting"].split("/")[0]) {
          a1 = data["rightResting"].split("/")[0];
        }
      }
      if (data && data["leftResting"]) {
        if (data["leftResting"].split("/")[1]) {
          b2 = data["leftResting"].split("/")[1];
        }
        if (data["leftResting"].split("/")[0]) {
          b1 = data["leftResting"].split("/")[0];
        }
      }
      if (data && data["rightExercise"]) {
        if (data["rightExercise"].split("/")[1]) {
          c2 = data["rightExercise"].split("/")[1];
        }
        if (data["rightExercise"].split("/")[0]) {
          c1 = data["rightExercise"].split("/")[0];
        }
      }
      if (data && data["leftExercise"]) {
        if (data["leftExercise"].split("/")[1]) {
          d2 = data["leftExercise"].split("/")[1];
        }
        if (data["leftExercise"].split("/")[0]) {
          d1 = data["leftExercise"].split("/")[0];
        }
      }
      maxA = Math.max(a2, b2);
      maxC = Math.max(c2, d2);
      if (e > 0 && maxA > 0) {
        tbi_right = e / maxA;
        if (tbi_right < 0.1) tbi_right = 0.1;
        else tbi_right = RoundDown(tbi_right);
      } else {
        tbi_right = null;
        skip_tbi_right_reason = "";
      }
      if (f > 0 && maxA > 0) {
        tbi_left = f / maxA;
        if (tbi_left < 0.1) tbi_left = 0.1;
        else tbi_left = RoundDown(tbi_left);
      } else {
        tbi_left = data?.["tbi_left"];
        skip_tbi_left_reason = "";
      }
      if (a1 > 0 && maxA > 0) {
        abi_right_resting = RoundDown(a1, maxA);
      } else {
        abi_right_resting = data?.["abi_right_resting"];
        skip_abi_exercise_right_reason = "";
        skip_tbi_right_reason = "";
      }
      if (b1 > 0 && maxA > 0) {
        abi_left_resting = RoundDown(b1, maxA);
      } else {
        abi_left_resting = data?.["abi_left_resting"];
        skip_abi_exercise_left_reason = "";
        skip_tbi_left_reason = "";
      }
      if (c1 > 0 && maxC > 0) {
        abi_right_exercise = RoundDown(c1, maxC);
      } else {
        abi_right_exercise = data?.["abi_right_exercise"];
        skip_abi_exercise_right_reason = "";
      }
      if (d1 > 0 && maxC > 0) {
        abi_left_exercise = RoundDown(d1, maxC);
      } else {
        abi_left_exercise = data?.["abi_left_exercise"];
        skip_abi_exercise_left_reason = "";
      }

      stateCheck = {
        ...data,
        abi_right_resting: abi_right_resting,
        abi_left_resting: abi_left_resting,
        abi_right_exercise: abi_right_exercise,
        abi_left_exercise: abi_left_exercise,
        tbi_right: tbi_right,
        tbi_left: tbi_left,
      };
      setData(stateCheck);
      dataRef.current = stateCheck;
    } else {
      setData(data);
      dataRef.current = data;
    }
  };

  const handleChange = async (key, value) => {
    let dataParams = {
      ...data,
      [key]: value,
    };
    if (key == "abi_right_resting_type") {
      dataParams = {
        ...data,
        ["abi_right_exercise_type"]: value,
      };
    } else if (key == "abi_left_resting_type") {
      dataParams = {
        ...data,
        ["abi_left_exercise_type"]: value,
      };
    }
    checkCondition(dataParams);
  };

  const handleChangeCheckbox = async (key, value) => {
    const dataParams = {
      ...data,
      [key]: value,
    };
    if (key == "skip_abi_resting_right") {
      setData({
        abi_right_resting: null,
      });
      setData(dataParams);
    } else {
      setData({
        abi_left_resting: null,
      });
      setData(dataParams);
    }
    dataRef.current = data;
  };

  const initButtons = (data) => {
    let a1 = null;
    let a2 = null;
    let b1 = null;
    let b2 = null;
    let c1 = null;
    let c2 = null;
    let d1 = null;
    let d2 = null;
    let maxA = null;
    let maxC = null;
    let abi_right_resting = null;
    let abi_left_resting = null;
    let abi_left_exercise = null;
    let abi_right_exercise = null;
    let tbi_left = null;
    let tbi_right = null;
    let e = null;
    let f = null;
    let skip_abi_exercise_left_reason = "";
    let skip_abi_exercise_right_reason = "";
    let skip_tbi_right_reason = "";
    let skip_tbi_left_reason = "";

    if (data && data["rightToePressure"]) {
      e = data["rightToePressure"];
    }
    if (data && data["leftToePressure"]) {
      f = data["leftToePressure"];
    }

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
      if (data["rightResting"].split("/")[0]) {
        a1 = data["rightResting"].split("/")[0];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }
    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
      if (data["rightExercise"].split("/")[0]) {
        c1 = data["rightExercise"].split("/")[0];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
      if (data["leftExercise"].split("/")[0]) {
        d1 = data["leftExercise"].split("/")[0];
      }
    }
    maxA = Math.max(a2, b2);
    maxC = Math.max(c2, d2);
    if (a1 > 0 && maxA > 0) {
      abi_right_resting = RoundDown(a1, maxA);
    } else {
      abi_right_resting = null;
      skip_abi_exercise_right_reason = "";
      skip_tbi_right_reason = "";
    }

    if (abi_right_resting == data["abi_right_resting"]) {
      setHideRightAbiResting(true);
    }

    if (b1 > 0 && maxA > 0) {
      abi_left_resting = RoundDown(b1, maxA);
    } else {
      abi_left_resting = null;
      skip_abi_exercise_left_reason = "";
    }

    if (abi_left_resting == data["abi_left_resting"]) {
      setHideLeftAbiResting(true);
    }

    if (c1 > 0 && maxC > 0) {
      abi_right_exercise = RoundDown(c1, maxC);
    } else {
      abi_right_exercise = null;
      skip_abi_exercise_right_reason = "";
    }

    if (abi_right_exercise == data["abi_right_exercise"]) {
      setHideRightAbiExercise(true);
    }

    if (d1 > 0 && maxC > 0) {
      abi_left_exercise = RoundDown(d1, maxC);
    } else {
      abi_left_exercise = null;
      skip_abi_exercise_left_reason = "";
    }

    if (abi_left_exercise == data["abi_left_exercise"]) {
      setHideLeftAbiExercise(true);
    }

    if (e > 0 && maxA > 0) {
      tbi_right = e / maxA;
      if (tbi_right < 0.1) tbi_right = 0.1;
      else tbi_right = RoundDown(tbi_right);
    } else {
      tbi_right = null;
      skip_tbi_right_reason = "";
    }

    if (tbi_right == data["tbi_right"]) {
      setHideTbiRight(true);
    }

    if (f > 0 && maxA > 0) {
      tbi_left = f / maxA;
      if (tbi_left < 0.1) tbi_left = 0.1;
      else tbi_left = RoundDown(tbi_left);
    } else {
      tbi_left = null;
      skip_tbi_left_reason = "";
    }

    if (tbi_left == data["tbi_left"]) {
      setHideTbiLeft(true);
    }
  };

  useEffect(() => {
    var dataMix = {};
    if (questionnaireData && questionnaireData.length > 0) {
      questionnaireData.map((r) => {
        dataMix = { ...dataMix, [r.key]: r?.value };
      });
      setData(dataMix);
      initButtons(dataMix);
      dataRef.current = dataMix;
      if (restProps?.ultrasounds_store) {
        const data_ = {
          "question-0": data["question-0"],
          "question-1": data["question-1"],
          "question-2": data["question-2"],
          "question-3": data["question-3"],
        };
        data_["question-0"] = "none";
        data_["question-1"] = "none";
        data_["question-2"] = "none";
        data_["question-3"] = "none";
        const value = restProps?.ultrasounds_store || [];
        let largest_right = 0;
        let largest_right_text = "";
        let largest_left = 0;
        let largest_left_text = "";
        value.map(function (item, index) {
          if (item["Stenosis"] == "Severe (> 75%)" && (item["Intervention"] == "Stent" || item["Intervention"] == "Restenosis")) {
            const finding_name = item["finding_name"].indexOf("Right") > -1 ? "Right" : "Left";
            if (finding_name == "Right") {
              if (data_["question-1"] == "none") {
                data_["question-1"] = "right";
              } else {
                data_["question-1"] += ",right";
              }
            } else {
              if (data_["question-1"] == "none") {
                data_["question-1"] = "left";
              } else {
                data_["question-1"] += ",left";
              }
            }
          }
          if (item["Intervention"] == "Vein Graft" && item["Stenosis"] == "Severe (> 75%)") {
            const finding_name = item["finding_name"].indexOf("Right") > -1 ? "Right" : "Left";
            if (finding_name == "Right") {
              if (data_["question-0"] == "none") {
                data_["question-0"] = "right";
              } else {
                data_["question-0"] += ",right";
              }
            } else {
              if (data_["question-0"] == "none") {
                data_["question-0"] = "left";
              } else {
                data_["question-0"] += ",left";
              }
            }
          }
          if (item["PSV"] && item["finding_name"] == "Right PTA" && parseInt(item["PSV"]) > parseInt(largest_right)) {
            largest_right = item["PSV"];
            largest_right_text = "PT";
          }
          if (item["PSV"] && item["finding_name"] == "Right ATA" && parseInt(item["PSV"]) > parseInt(largest_right)) {
            largest_right = item["PSV"];
            largest_right_text = "AT/DP";
          }
          if (item["PSV"] && item["finding_name"] == "Left PTA" && parseInt(item["PSV"]) > parseInt(largest_left)) {
            largest_left = item["PSV"];
            largest_left_text = "PT";
          }
          if (item["PSV"] && item["finding_name"] == "Left ATA" && parseInt(item["PSV"]) > parseInt(largest_left)) {
            largest_left = item["PSV"];
            largest_left_text = "AT/DP";
          }
          if (item["Stenosis"] == "Severe (> 75%)" || item["Stenosis"] == "Occluded") {
            if (
              item["finding_name"] == "Right TPT" ||
              item["finding_name"] == "Right ATA" ||
              item["finding_name"] == "Right PTA" ||
              item["finding_name"] == "Right Peroneal"
            ) {
              if (data_["question-3"] == "none") {
                data_["question-3"] = "right";
              } else {
                data_["question-3"] += ",right";
              }
            }
            if (
              item["finding_name"] == "Left TPT" ||
              item["finding_name"] == "Left ATA" ||
              item["finding_name"] == "Left PTA" ||
              item["finding_name"] == "Left Peroneal"
            ) {
              if (data_["question-3"] == "none") {
                data_["question-3"] = "left";
              } else {
                data_["question-3"] += ",left";
              }
            }
            if (
              item["finding_name"] == "Left Common Femoral" ||
              item["finding_name"] == "Left Profunda Femoris" ||
              item["finding_name"] == "Left Proximal SFA" ||
              item["finding_name"] == "Left Middle SFA" ||
              item["finding_name"] == "Left Distal SFA" ||
              item["finding_name"] == "Left Popliteal"
            ) {
              if (data_["question-2"] == "none") {
                data_["question-2"] = "left";
              } else {
                data_["question-2"] += ",left";
              }
            }
            if (
              item["finding_name"] == "Right Common Femoral" ||
              item["finding_name"] == "Right Profunda Femoris" ||
              item["finding_name"] == "Right Proximal SFA" ||
              item["finding_name"] == "Right Middle SFA" ||
              item["finding_name"] == "Right Distal SFA" ||
              item["finding_name"] == "Right Popliteal"
            ) {
              if (data_["question-2"] == "none") {
                data_["question-2"] = "right";
              } else {
                data_["question-2"] += ",right";
              }
            }
          }
        });
        data_["abi_right_resting_type"] = largest_right_text;
        data_["abi_right_exercise_type"] = largest_right_text;
        data_["abi_left_resting_type"] = largest_left_text;
        data_["abi_left_exercise_type"] = largest_left_text;
        const params = { ...dataMix, ...data_ };
        setData(params);
        dataRef.current = params;
      }
    }
  }, [questionnaireData, restProps?.ultrasounds_store]);

  useEffect(() => {
    return async () => {
      const data = dataRef?.current;
      if (data && Object.keys(data).length > 0) {
        const arrCheck = Object.keys(data).map((r) => {
          return {
            key: r,
            value: data[r],
          };
        });
        await dispatch(setGlobalLoading(true));
        await dispatch(
          post_ultrasound_question({
            procedure_id: appointment_ultrasound_id,
            values: arrCheck,
          })
        );
        await dispatch(setGlobalLoading(false));
      }
    };
  }, []);

  const handleChangeCustom = (value, type) => {
    const params = {
      ...data,
      [type]: value,
    };
    setData(params);
    dataRef.current = params;
    // eslint-disable-next-line default-case
    switch (type) {
      case "abi_right_resting":
        setHideRightAbiResting(false);
        break;
      case "abi_left_resting":
        setHideLeftAbiResting(false);
        break;
      case "abi_right_exercise":
        setHideRightAbiExercise(false);
        break;
      case "abi_left_exercise":
        setHideLeftAbiExercise(false);
        break;
      case "tbi_right":
        setHideTbiRight(false);
        break;
      case "tbi_left":
        setHideTbiLeft(false);
        break;
    }
  };

  const checkRightRAbi = () => {
    refreshRightRestingAbi(data);
  };

  const checkLeftRAbi = () => {
    refreshLeftRestingAbi(data);
  };

  const checkRightEAbi = () => {
    refreshRightExerciseAbi(data);
  };

  const checkLeftEAbi = () => {
    refreshLeftExerciseAbi(data);
  };

  const checkRightT = () => {
    refreshTbiRight(data);
  };

  const checkLeftT = () => {
    refreshTbiLeft(data);
  };

  const checkRequiredTbi = (side) => {
    if (side == "right") {
      if (data?.["abi_right_resting"] >= 0.7) {
        if (!data?.["tbi_right"] && !data?.["skip_tbi_right_reason"]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (side == "left") {
      if (data?.["abi_left_resting"] >= 0.7) {
        if (!data?.["tbi_left"] && !data?.["skip_tbi_left_reason"]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const checkRequiredExercise = (side) => {
    if (side == "right") {
      if (data?.["abi_right_resting"] >= 0.7) {
        if (!data?.["abi_right_exercise"] && !data?.["skip_abi_exercise_right_reason"]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (side == "left") {
      if (data?.["abi_left_resting"] >= 0.7) {
        if (!data?.["abi_left_exercise"] && !data?.["skip_abi_exercise_left_reason"]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const checkRequiredResting = (side) => {
    if (side == "right") {
      if (data?.["abi_right_resting"] || data?.["skip_abi_resting_right"]) {
        return false;
      } else {
        return true;
      }
    } else if (side == "left") {
      if (data?.["abi_left_resting"] || data?.["skip_abi_resting_left"]) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleUpdateReason = (type) => (value) => {
    let params = null;

    switch (type) {
      case "skip_abi_exercise_right_reason":
        if (data?.["abi_right_resting"] >= 0.7) {
          params = {
            ...data,
            rightExercise: null,
            abi_right_exercise: null,
            skip_abi_exercise_right_reason: value,
          };
          setData(params);
          dataRef.current = params;
        }
        break;
      case "skip_abi_exercise_left_reason":
        if (data?.["abi_left_resting"] >= 0.7) {
          params = {
            ...data,
            leftExercise: null,
            abi_left_exercise: null,
            skip_abi_exercise_left_reason: value,
          };
          setData(params);
          dataRef.current = params;
        }
        break;
      case "skip_tbi_right_reason":
        if (data?.["abi_right_resting"] >= 0.7) {
          params = {
            ...data,
            rightToePressure: null,
            tbi_right: null,
            skip_tbi_right_reason: value,
          };
          setData(params);
          dataRef.current = params;
        }
        break;
      case "skip_tbi_left_reason":
        if (data?.["abi_left_resting"] >= 0.7) {
          params = {
            ...data,
            leftToePressure: null,
            tbi_left: null,
            skip_tbi_left_reason: value,
          };
          setData(params);
          dataRef.current = params;
        }
        break;
    }
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo no-padding" style={{ paddingLeft: "0" }}>
        <div className="resourceInfo d-flex required-field newFlex">
          <div style={{ width: 140 }}>
            <EditableInputWithDiv
              isErrorTextSpacing={true}
              label="ABI (Right) Resting (Ankle SBP / Brachial SBP)"
              value={rightResting}
              subLabel="BP"
              fullWidth={true}
              unit="mmHg"
              handleChange={(value) => handleChange("rightResting", value)}
              required={checkRequiredResting("right")}
              isError={checkRequiredResting("right")}
              enableErrorText={checkRequiredResting("right")}
              helperText="ABI (Right) Resting is required."
              disabled={restProps?.isLocked || data?.["skip_abi_resting_right"] ? true : false}
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredResting("right") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  handleChange={(value) => handleChangeCustom(value, "abi_right_resting")}
                  // isError={!rightResting || !data?.["abi_right_resting"] || data?.["skip_abi_resting_right"]}
                  value={isFinite(data?.abi_right_resting) ? data?.abi_right_resting : 0}
                  disabled={restProps?.isLocked || data?.["skip_abi_resting_right"] ? true : false}
                  required={checkRequiredResting("right")}
                  isError={checkRequiredResting("right")}
                  enableErrorText={checkRequiredResting("right")}
                  isCheckBox={true}
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginLeft: "12px", columnGap: "12px" }}>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_right_resting_type`] && data[`abi_right_resting_type`].indexOf("AT/DP") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_right_resting_type`] && data[`abi_right_resting_type`].indexOf("AT/DP") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_right_resting_type", "AT/DP")}
                  >
                    AT/DP
                  </button>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_right_resting_type`] && data[`abi_right_resting_type`].indexOf("PT") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_right_resting_type`] && data[`abi_right_resting_type`].indexOf("PT") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_right_resting_type", "PT")}
                  >
                    PT
                  </button>
                </div>
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideRightAbiResting && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightRAbi} />}
                </div>
                <div style={{ width: 140, marginLeft: 12, display: "flex" }}>
                  <Checkbox
                    style={{ marginLeft: "10px", fontWeight: "bold", top: "17px" }}
                    checked={data["skip_abi_resting_right"]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const params = {
                          ...data,
                          rightResting: null,
                          abi_right_resting: null,
                          skip_abi_resting_right: e.target.checked,
                        };
                        setData(params);
                        dataRef.current = params;
                      } else {
                        handleChangeCheckbox("skip_abi_resting_right", e.target.checked);
                      }
                    }}
                  >
                    Exclude
                  </Checkbox>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="resourceInfo d-flex required-field newFlex">
          <div style={{ width: 140 }}>
            <EditableInputWithDiv
              isErrorTextSpacing={true}
              label="ABI (Left) Resting (Ankle SBP / Brachial SBP)"
              value={leftResting}
              fullWidth={true}
              subLabel="BP"
              unit="mmHg"
              handleChange={(value) => handleChange("leftResting", value)}
              required={checkRequiredResting("left")}
              isError={checkRequiredResting("left")}
              enableErrorText={checkRequiredResting("left")}
              helperText="ABI (Left) Resting is required."
              disabled={restProps?.isLocked || data?.["skip_abi_resting_left"] ? true : false}
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredResting("left") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  // isError={!leftResting || !data?.["abi_left_resting"] || data?.["skip_abi_resting_left"]}
                  handleChange={(value) => handleChangeCustom(value, "abi_left_resting")}
                  value={isFinite(data?.abi_left_resting) ? data?.abi_left_resting : 0}
                  disabled={restProps?.isLocked || data?.["skip_abi_resting_left"] ? true : false}
                  required={checkRequiredResting("left")}
                  isError={checkRequiredResting("left")}
                  enableErrorText={checkRequiredResting("left")}
                  isCheckBox={true}
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginLeft: "12px", columnGap: "12px" }}>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_left_resting_type`] && data[`abi_left_resting_type`].indexOf("AT/DP") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_left_resting_type`] && data[`abi_left_resting_type`].indexOf("AT/DP") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_left_resting_type", "AT/DP")}
                  >
                    AT/DP
                  </button>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_left_resting_type`] && data[`abi_left_resting_type`].indexOf("PT") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_left_resting_type`] && data[`abi_left_resting_type`].indexOf("PT") > -1 ? "1px solid #0f62fe" : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_left_resting_type", "PT")}
                  >
                    PT
                  </button>
                </div>
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideLeftAbiResting && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftRAbi} />}
                </div>
                <div style={{ width: 140, marginLeft: 12, display: "flex" }}>
                  <Checkbox
                    style={{ marginLeft: "10px", fontWeight: "bold", top: "17px" }}
                    checked={data["skip_abi_resting_left"]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const params = {
                          ...data,
                          leftResting: null,
                          abi_left_resting: null,
                          skip_abi_resting_left: e.target.checked,
                        };
                        setData(params);
                        dataRef.current = params;
                      } else {
                        handleChangeCheckbox("skip_abi_resting_left", e.target.checked);
                      }
                    }}
                  >
                    Exclude
                  </Checkbox>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex" style={{ position: "relative" }}>
          <div style={{ width: 140 }}>
            <EditableInputWithDiv
              isErrorTextSpacing={true}
              label="ABI (Right) Exercise"
              value={rightExercise}
              subLabel="BP"
              unit="mmHg"
              handleChange={(value) => handleChange("rightExercise", value)}
              helperText="ABI (Right) Exercise is required."
              required={checkRequiredExercise("right")}
              isError={checkRequiredExercise("right")}
              enableErrorText={checkRequiredExercise("right")}
              disabled={
                restProps?.isLocked || data?.["skip_abi_exercise_right_reason"]
                  ? true
                  : false || data?.["abi_right_resting"] < 0.7 || !data?.["abi_right_resting"]
              }
              className={restProps?.isLocked || data?.["skip_abi_exercise_right_reason"] ? "disabled" : ""}
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredExercise("right") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  required={checkRequiredExercise("right")}
                  isError={checkRequiredExercise("right")}
                  enableErrorText={checkRequiredExercise("right")}
                  isCheckBox={true}
                  disabled={
                    restProps?.isLocked || data?.["skip_abi_exercise_right_reason"]
                      ? true
                      : false || data?.["abi_right_resting"] < 0.7 || !data?.["abi_right_resting"]
                  }
                  handleChange={(value) => handleChangeCustom(value, "abi_right_exercise")}
                  value={isFinite(data?.abi_right_exercise) ? data?.abi_right_exercise : 0}
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginLeft: "12px", columnGap: "12px" }}>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor:
                        data[`abi_right_exercise_type`] && data[`abi_right_exercise_type`].indexOf("AT/DP") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_right_exercise_type`] && data[`abi_right_exercise_type`].indexOf("AT/DP") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_right_exercise_type", "AT/DP")}
                  >
                    AT/DP
                  </button>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_right_exercise_type`] && data[`abi_right_exercise_type`].indexOf("PT") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_right_exercise_type`] && data[`abi_right_exercise_type`].indexOf("PT") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_right_exercise_type", "PT")}
                  >
                    PT
                  </button>
                </div>
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideRightAbiExercise && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightEAbi} />}
                </div>
                <div style={{ width: 165, marginLeft: 12, visibility: data?.["abi_right_resting"] >= 0.7 ? "visible" : "hidden" }}>
                  <EditableFormInput
                    label={"Contraindication"}
                    fullWidth={true}
                    allowClear={true}
                    value={data["skip_abi_exercise_right_reason"]}
                    type="check"
                    options={[{ value: "Amputee" }, { value: "Intolerant" }]}
                    optionKey={"value"}
                    valueKey={"value"}
                    handleChange={handleUpdateReason("skip_abi_exercise_right_reason")}
                    style={{ marginLeft: "10px", top: "17px" }}
                    isCheckBox={true}
                    isError={false}
                    disabled={data?.["abi_right_exercise"] || restProps?.isLocked}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex" style={{ position: "relative" }}>
          <div style={{ width: 140 }}>
            <EditableInputWithDiv
              isErrorTextSpacing={true}
              label="ABI (Left) Exercise"
              value={leftExercise}
              subLabel="BP"
              unit="mmHg"
              handleChange={(value) => handleChange("leftExercise", value)}
              helperText="ABI (Left) Exercise is required."
              required={checkRequiredExercise("left")}
              isError={checkRequiredExercise("left")}
              enableErrorText={checkRequiredExercise("left")}
              disabled={
                restProps?.isLocked || data?.["skip_abi_exercise_left_reason"]
                  ? true
                  : false || data?.["abi_left_resting"] < 0.7 || !data?.["abi_left_resting"]
              }
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredExercise("left") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  handleChange={(value) => handleChangeCustom(value, "abi_left_exercise")}
                  required={checkRequiredExercise("left")}
                  isError={checkRequiredExercise("left")}
                  enableErrorText={checkRequiredExercise("left")}
                  isCheckBox={true}
                  disabled={
                    restProps?.isLocked || data?.["skip_abi_exercise_left_reason"]
                      ? true
                      : false || data?.["abi_left_resting"] < 0.7 || !data?.["abi_left_resting"]
                  }
                  value={isFinite(data?.abi_left_exercise) ? data?.abi_left_exercise : 0}
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginLeft: "12px", columnGap: "12px" }}>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_left_exercise_type`] && data[`abi_left_exercise_type`].indexOf("AT/DP") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_left_exercise_type`] && data[`abi_left_exercise_type`].indexOf("AT/DP") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_left_exercise_type", "AT/DP")}
                  >
                    AT/DP
                  </button>
                  <button
                    disabled={restProps?.isLocked}
                    style={{
                      height: "2rem",
                      backgroundColor: data[`abi_left_exercise_type`] && data[`abi_left_exercise_type`].indexOf("PT") > -1 ? "#dbe3ee" : "#f4f7fb",
                      color: "#152935",
                      border:
                        data[`abi_left_exercise_type`] && data[`abi_left_exercise_type`].indexOf("PT") > -1
                          ? "1px solid #0f62fe"
                          : "1px solid #dfe3e6",
                      minWidth: 75,
                      maxWidth: 75,
                      padding: "0 0.5rem",
                      cursor: "pointer",
                      fontSize: 12,
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    onClick={() => handleChange("abi_left_exercise_type", "PT")}
                  >
                    PT
                  </button>
                </div>
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideLeftAbiExercise && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftEAbi} />}
                </div>
                <div style={{ width: 165, marginLeft: 12, visibility: data?.["abi_left_resting"] >= 0.7 ? "visible" : "hidden" }}>
                  <EditableFormInput
                    label={"Contraindication"}
                    allowClear={true}
                    fullWidth={true}
                    value={data["skip_abi_exercise_left_reason"]}
                    type="check"
                    options={[{ value: "Amputee" }, { value: "Intolerant" }]}
                    optionKey={"value"}
                    valueKey={"value"}
                    handleChange={handleUpdateReason("skip_abi_exercise_left_reason")}
                    style={{ marginLeft: "10px", top: "17px" }}
                    isError={false}
                    isCheckBox={true}
                    disabled={data?.["abi_left_exercise"] || restProps?.isLocked}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex" style={{ position: "relative" }}>
          <div style={{ width: 140 }}>
            <EditableInputWithSingleDiv
              isErrorTextSpacing={true}
              label="Toe Pressure (Right)"
              value={rightToePressure}
              subLabel="BP"
              unit="mmHg"
              handleChange={(value) => handleChange("rightToePressure", value)}
              helperText="Toe Pressure (Right) is required."
              required={checkRequiredTbi("right")}
              isError={checkRequiredTbi("right")}
              enableErrorText={checkRequiredTbi("right")}
              disabled={
                restProps?.isLocked || data?.["skip_tbi_right_reason"]
                  ? true
                  : false || data?.["abi_right_resting"] < 0.7 || !data?.["abi_right_resting"]
              }
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredTbi("right") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  handleChange={(value) => handleChangeCustom(value, "tbi_right")}
                  value={isFinite(data?.tbi_right) ? data?.tbi_right : 0}
                  required={checkRequiredTbi("right")}
                  isError={checkRequiredTbi("right")}
                  enableErrorText={checkRequiredTbi("right")}
                  isCheckBox={true}
                  disabled={
                    restProps?.isLocked || data?.["skip_tbi_right_reason"]
                      ? true
                      : false || data?.["abi_right_resting"] < 0.7 || !data?.["abi_right_resting"]
                  }
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideTbiRight && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightT} />}
                </div>
                <div style={{ width: 165, marginLeft: 12, visibility: data?.["abi_right_resting"] >= 0.7 ? "visible" : "hidden" }}>
                  <EditableFormInput
                    label={"Contraindication"}
                    allowClear={true}
                    fullWidth={true}
                    value={data["skip_tbi_right_reason"]}
                    type="check"
                    options={[{ value: "Amputee" }, { value: "Intolerant" }]}
                    optionKey={"value"}
                    valueKey={"value"}
                    handleChange={handleUpdateReason("skip_tbi_right_reason")}
                    style={{ marginLeft: "10px", top: "17px" }}
                    isCheckBox={true}
                    isError={false}
                    disabled={data?.["tbi_right"] || restProps?.isLocked}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex" style={{ position: "relative" }}>
          <div style={{ width: 140 }}>
            <EditableInputWithSingleDiv
              isErrorTextSpacing={true}
              label="Toe Pressure (Left)"
              value={leftToePressure}
              subLabel="BP"
              unit="mmHg"
              handleChange={(value) => handleChange("leftToePressure", value)}
              helperText="Toe Pressure (Left) is required."
              required={checkRequiredTbi("left")}
              isError={checkRequiredTbi("left")}
              enableErrorText={checkRequiredTbi("left")}
              disabled={
                restProps?.isLocked || data?.["skip_tbi_left_reason"]
                  ? true
                  : false || data?.["abi_left_resting"] < 0.7 || !data?.["abi_left_resting"]
              }
              isCheckBox={true}
            />
          </div>
          {showCondition ? (
            <div className={`new-input-right-ultrasound-question ${checkRequiredTbi("left") ? "active" : ""}`}>
              <div style={{ width: 70 }}>
                <EditableFormInput
                  handleChange={(value) => handleChangeCustom(value, "tbi_left")}
                  value={isFinite(data?.tbi_left) ? data?.tbi_left : 0}
                  required={checkRequiredTbi("left")}
                  isError={checkRequiredTbi("left")}
                  enableErrorText={checkRequiredTbi("left")}
                  isCheckBox={true}
                  disabled={
                    restProps?.isLocked || data?.["skip_tbi_left_reason"]
                      ? true
                      : false || data?.["abi_left_resting"] < 0.7 || !data?.["abi_left_resting"]
                  }
                />
              </div>

              <div
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: 25, height: 25, marginLeft: 12 }}>
                  {!hideTbiLeft && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftT} />}
                </div>
                <div style={{ width: 165, marginLeft: 12, visibility: data?.["abi_left_resting"] >= 0.7 ? "visible" : "hidden" }}>
                  <EditableFormInput
                    label={"Contraindication"}
                    allowClear={true}
                    fullWidth={true}
                    value={data["skip_tbi_left_reason"]}
                    type="check"
                    options={[{ value: "Amputee" }, { value: "Intolerant" }]}
                    optionKey={"value"}
                    valueKey={"value"}
                    handleChange={handleUpdateReason("skip_tbi_left_reason")}
                    style={{ marginLeft: "10px", top: "17px" }}
                    isCheckBox={true}
                    isError={false}
                    disabled={data?.["tbi_left"] || restProps?.isLocked}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {QUESTIONS.map((question, index) => (
          <div
            key={`question-${index}`}
            className="resourceInfo d-flex"
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              borderTop: index === 0 ? ".5px solid #dfe3e6" : 0,
              borderBottom: ".5px solid #dfe3e6",
              padding: "0.5rem 0 5px",
              margin: "0 0.5rem",
              marginTop: index === 0 ? "0.5rem" : 0,
            }}
          >
            <p
              style={{
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {question}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              {BUTTONS.map(({ text, value }, indexBtn) => (
                <button
                  disabled={restProps?.isLocked}
                  key={`btn-${index}-${indexBtn}`}
                  style={{
                    height: "2rem",
                    backgroundColor: data[`question-${index}`] && data[`question-${index}`].indexOf(value) > -1 ? "#dbe3ee" : "#f4f7fb",
                    color: "#152935",
                    border: data[`question-${index}`] && data[`question-${index}`].indexOf(value) > -1 ? "1px solid #0f62fe" : "1px solid #dfe3e6",
                    minWidth: 75,
                    maxWidth: 75,
                    padding: "0 0.5rem",
                    cursor: "pointer",
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {text}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questionnaire;

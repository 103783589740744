import React from 'react';
import './style.scss';

const CardWhiteBlock = ({
  varient,
  title,
  children,
  other,
  cardContainerStyle = {},
  extraStyle = {},
}) => {
  return (
    <div
      className={`cardWhiteBlock ${varient || 'default'} ${other ? other : ''}`}
      style={cardContainerStyle}
    >
      <div className={`cardWhiteBlock__title ${!title ? 'no-title' : ''}`}>
        {title}
      </div>
      <div className="cardWhiteBlock__content" style={extraStyle}>
        {children}
      </div>
    </div>
  );
};

export default CardWhiteBlock;

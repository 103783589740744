import { Drawer } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CptCodeDataTable from "../../../../common/components/CptCodeDataTable";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { getPCMDetail, setGlobalLoading, updatePCM } from "../../../../store/actions";

const NonendovascularSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, detailPCM, updateState, moveState }, ref) => {
  const [reset, setReset] = useState(true);
  const [direct, setDirect] = useState("");
  const [payment, setPayment] = useState("");
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [secon, setSecon] = useState(false);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    setFirst(false);
  }, [detailPCM.id]);

  useEffect(() => {
    if (detailPCM && detailPCM.direct_cost) {
      setDirect(detailPCM.direct_cost);
      updateState(detailPCM, {
        direct: detailPCM.direct_cost,
      });
    }
    if (detailPCM && detailPCM.payment) {
      setPayment(detailPCM.payment);
      updateState(detailPCM, {
        payment: detailPCM.payment,
      });
      setReset(true);
    }
  }, [detailPCM]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const saveAll = async () => {
    setSecon(true);
    if (secon) {
      setLoading(true);
      const saveSuccess = await dispatch(
        updatePCM(detailPCM.id, {
          direct_cost: direct,
          total_payment: payment,
        })
      );

      const getDetailSuccess = await dispatch(getPCMDetail(detailPCM.id));

      if (saveSuccess && getDetailSuccess) {
        setLoading(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const changePayment = useCallback(
    debounce((value) => setPayment(value), 400),
    []
  );
  const changeDirect = useCallback(
    debounce((value) => setDirect(value), 400),
    []
  );

  useEffect(() => {
    setFirst(true);
    if (first) {
      saveAll();
    }
  }, [direct, payment]);

  const saveType = async () => {
    setLoading(true);
    const params = {
      direct_cost: direct,
      total_payment: payment,
      type_endo_nonendo: "endo",
    };
    const saveSuccess = await dispatch(updatePCM(detailPCM.id, params));

    if (saveSuccess) {
      setLoading(false);
      setIsToastr(true);
      moveState(detailPCM.id, params);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isShow && detailPCM && Object.keys(detailPCM).length) {
      dispatch(setGlobalLoading(loading));
    }
  }, [loading, isShow, detailPCM]);

  if (!detailPCM || !Object.keys(detailPCM).length) {
    return null;
  }

  return (
    <Drawer
      title={`Non-Endovascular - ${detailPCM?.patient_name}`}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="NonEndovascular" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditableMaterialText reset={reset} label={"Provider"} value={detailPCM.provider_name} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText reset={reset} label={"Date of Service"} value={detailPCM.date_of_procedure} type={"date"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Date of Billing"}
                value={detailPCM.date_of_billing && moment(detailPCM.date_of_billing).format("MM/DD/YYYY")}
                type={"date"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText reset={reset} label={"Payment"} value={payment} handleChange={changePayment} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText reset={reset} label={"Direct Costs"} handleChange={changeDirect} value={direct} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText reset={reset} label={"Contribution Margin"} value={detailPCM.contribution_amount} />
            </div>
            <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
              <CptCodeDataTable title="CPT" data={detailPCM.cpt_code} />
            </div>
            <hr />
          </div>
          <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
            <button onClick={saveType} style={{ marginRight: "10px" }}>
              Move to Endo
            </button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default NonendovascularSidebar;

import { Collapse } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./SnapList.scss";
const { Panel } = Collapse;
const SnapList = ({ items, selectedDate, onChangeSelectedDate, isClinicWorkFlow, collapseLabel }) => {
  const [conclusion, setConclusion] = useState([]);
  const [finding, setFinding] = useState([]);
  const onSelectRow = (date_) => {
    onChangeSelectedDate(date_);
  };
  useEffect(() => {
    let Dataconclusion = {};
    let Arayconclusion = [];
    let Datafinding = {};
    let Arayfinding = [];

    if (items && items.length > 0) {
      (items || []).map((item, indexItem) => {
        Arayconclusion = [];
        Arayfinding = [];
        if (item?.values && item?.values.length > 0) {
          (item?.values || []).map((value) => {
            if (value.field === "conclusion") {
              Arayconclusion.push(value);
              Dataconclusion = {
                ...Dataconclusion,
                [`conclusion${indexItem}`]: [...Arayconclusion],
              };
            } else if (value.field === "finding") {
              Arayfinding.push(value);
              Datafinding = {
                ...Datafinding,
                [`finding${indexItem}`]: [...Arayfinding],
              };
            }
          });
        }
      });
    }
    setConclusion(Dataconclusion);
    setFinding(Datafinding);
  }, [items]);

  return (
    <div className="snapList">
      {(items || []).map((node, index) => (
        <Collapse
          style={{
            borderBottom: "0.5px solid #dfe3e6",
          }}
          className={isClinicWorkFlow ? "clinicWorkflow-ant-collapse" : ""}
          expandIcon={({ isActive }) => (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontWeight: "normal",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: "14px",
                }}
                className="snapDetail__content-title"
              >
                {node.date}
              </div>
              <div
                className={`snapItem ${isActive && "selected"}`}
                style={{
                  marginLeft: 50,
                }}
                key={`snap-${index}`}
              >
                <div
                  className={`snapDetail__status ${node.type === "ultrasound" && "red"} ${node.type === "procedure" && "yellow"}`}
                  style={isClinicWorkFlow ? { background: "transparent" } : {}}
                >
                  <div>{index + 1}</div>
                </div>
              </div>
            </div>
          )}
          key={`${index} Extracranial Arteries`}
          accordion
          activeKey={moment(node.date).format("MM/DD/YYYY") === selectedDate ? `${index}-panel ${node.title}` : ""}
          onChange={(key) => onSelectRow(moment(node.date).format("MM/DD/YYYY"))}
        >
          <Panel
            className={`panel_snap ${isClinicWorkFlow ? "clinicWorkFlow_panel" : ""}`}
            header={
              <div>
                <p
                  className="snapDetail__content-title"
                  style={{
                    paddingLeft: 200,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {node.title}
                </p>
              </div>
            }
            key={`${index}-panel ${node.title}`}
          >
            <div
              style={{
                width: "100%",
                paddingLeft: 185,
                paddingBottom: 20,
                ...(isClinicWorkFlow && { backgroundColor: "#F4F7FB  " }),
              }}
              className={`snapItem ${"selected"}`}
            >
              <div className="snapDetail">
                <div className="snapDetail__content">
                  <div className="snapDetail__content-info">
                    <div className="snapDetail__content-block">
                      <div className="snapDetail__content-title">Finding: </div>
                      {finding &&
                        finding[`finding${index}`] &&
                        (finding[`finding${index}`] || []).map((valuecheck, indexValue) => {
                          if (valuecheck?.value.trim() == "" || valuecheck?.value == " ") {
                            return <></>;
                          }
                          return (
                            <div
                              className="description"
                              key={`snap-${indexValue}-finding`}
                              dangerouslySetInnerHTML={{
                                __html: valuecheck?.value,
                              }}
                            ></div>
                          );
                        })}
                    </div>
                    <div className="snapDetail__content-block" style={{ marginTop: "10px" }}>
                      <div className="snapDetail__content-title">Conclusion: </div>
                      {conclusion &&
                        conclusion[`conclusion${index}`] &&
                        (conclusion[`conclusion${index}`] || []).map((valuecheck, indexValue) => {
                          if (valuecheck?.value.trim() == "" || valuecheck?.value == " ") {
                            return <></>;
                          }
                          return (
                            <div
                              className="description"
                              key={`snap-${indexValue}-conclusion`}
                              dangerouslySetInnerHTML={{
                                __html: valuecheck?.value,
                              }}
                            ></div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};

export default SnapList;

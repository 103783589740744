import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import CardBlock from "../../common/components/Card";
import ScrollWrapper from "../../common/components/ScrollBar";
import TopBar from "../../common/components/TopBar";
import AppointmentManager from "./components/AppointmentManager";
import PatientSearch from "./components/PatientSearch";
import QuickRegister from "./components/QuickRegister";
import { Box, Typography } from "@material-ui/core";
import CookieConsent from "react-cookie-consent";
import { getLocal } from "../../helpers/Local";
import { getProcedureData, getProviders, getReferralConditions, getReferredProviders } from "../../store/actions";
import MessageCenterSearch from "./components/MessageCenterSearch";
import TaskSearch from "./components/TaskSearch";
import "./style.scss";

class Dashboard extends Component {
  state = {
    isSidebar: false,
    isAuth: getLocal("cvai-auth-token") ? true : false,
    userRole: getLocal("cvai-auth-role"),
    activeCard: "",
  };

  componentDidMount() {
    this.setState({
      isAuth: getLocal("cvai-auth-token") ? true : false,
      userRole: getLocal("cvai-auth-role"),
    });
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular - Dashboard</title>
        <meta name="title" content="FAACT Vascular | Dashboard" />
      </Helmet>
    );
  }

  render() {
    const { isAuth, userRole, activeCard } = this.state;
    if (isAuth) {
      if (userRole === "patient") {
        this.props.history.push("/patient-dashboard");
      } else if (userRole === "provider") {
        this.props.history.push("/referral-dashboard");
      }
    }
    if (!isAuth) {
      this.props.history.push("/login");
      return null;
    }

    return (
      <div className="homePage">
        {this.renderMetadata()}
        <TopBar title="Dashboard" />
        <div className="main">
          <ScrollWrapper css="no-padding x-hidden">
            <div
              className="mainContainer"
              style={{
                paddingRight: "calc(5% - 3rem)",
                paddingTop: "0",
                paddingBottom: "0",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Search Patient" varient="half" other="dashboard">
                <PatientSearch />
              </CardBlock>
              <CardBlock title="Message Center" varient="quarter" other="dashboard">
                <MessageCenterSearch />
              </CardBlock>
              <CardBlock fixContent="new-content-max-height" title="Task Manager" varient="quarter" other="dashboard">
                <TaskSearch />
              </CardBlock>
              <CardBlock
                title="Quick Register"
                other="dashboard"
                contentPadding={"0.25rem"}
                varient={activeCard === "" ? "half" : activeCard === "quick_register" ? "three_fourth" : "quarter"}
                onClick={() => this.setState({ activeCard: "quick_register" })}
              >
                <QuickRegister />
              </CardBlock>

              <AppointmentManager
                varient={activeCard === "" ? "half" : activeCard === "appointment" ? "three_fourth" : "quarter"}
                onCardClick={() =>
                  this.setState({
                    activeCard: "appointment",
                  })
                }
              />
              <CookieConsent
                location="bottom"
                buttonText="Accept all"
                buttonStyle={{
                  backgroundColor: "#005ef6",
                  border: "1px solid #005ef6",
                  color: "white",
                  order: "1",
                  fontSize: "0.875rem",
                  marginBottom: "0",
                  padding: "0 12px",
                  margin: "0px",
                  minHeight: "2.5rem",
                  minWidth: "150px",
                  height: "40px",
                  width: "150px",
                  alignItems: "center",
                }}
                declineButtonStyle={{
                  backgroundColor: "#005ef6",
                  border: "1px solid #005ef6",
                  color: "white",
                  order: "2",
                  fontSize: "0.875rem",
                  marginBottom: "0",
                  padding: "0 12px",
                  margin: "6px 0px",
                  minHeight: "2.5rem",
                  minWidth: "150px",
                  height: "40px",
                  width: "150px",
                  alignItems: "center",
                }}
                declineButtonText="Required only"
                enableDeclineButton={true}
                cookieName="myAwesomeCookieName2"
                style={{ background: "white", padding: "0px 6rem", alignItems: "center", gap: "5rem" }}
                expires={150}
                overlay
              >
                <h4 style={{ fontWeight: 900, marginBottom: 0, fontSize: "0.875rem" }}>About cookies on this site</h4>
                <Box width="100%" display="flex" justifyContent="space-between" gridGap="5rem">
                  <div style={{ width: "33.33%" }}>
                    <Typography
                      noWrap={false}
                      variant="caption"
                      style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                    >
                      Our websites require some cookies to function properly (required). In addition, other cookies may be used with your consent to
                      analyze site usage, improve the use experience and for advertising
                    </Typography>
                  </div>

                  <div style={{ width: "33.33%" }}>
                    <Typography
                      noWrap={false}
                      variant="caption"
                      style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                    >
                      For more information, please review your <a>Cookie preferences</a> options and FAACT's <a>privacy statement</a>
                    </Typography>
                  </div>
                  <div style={{ width: "33.33%" }}>
                    <Typography
                      noWrap={false}
                      variant="caption"
                      style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                    >
                      To provide a smooth navigation, your cookie preferences will be shared across the FAACT web domains listed <a>here</a>
                    </Typography>
                  </div>
                </Box>
              </CookieConsent>
            </div>
          </ScrollWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
});

const mapDispatchToProps = {
  getReferralConditions,
  getReferredProviders,
  getProviders,
  getProcedureData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useOutsideDetect } from "../../utils";
import Loading from "../Loading";
import ScrollWrapper from "../ScrollBar";
import ExtendDataTable from "../SubDataTable/ExtendDataTable";
import { SearchSVG } from "../icons/SVGIcon";
import "./style.scss";

const { Search } = Input;

const AutoCompleteDataTable = ({
  rows,
  options,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  disableOptions,
  minRows,
  onScroll,
  isLocked,
  tableTitleOptions,
  onClickTableRow,
  tableData,
  handleDeleteRow,
  tableOptions,
  tableWidths,
  activeRow,
  disableHeader,
  disableDelete,
  isIcon,
  isRmBorders,
  isBorderOnHover,
  isBorderThick,
  isHideTable,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [data, setData] = useState([]);

  const [openResult, setOpenResult] = useState(false);

  useEffect(() => {
    setData(rows || []);
  }, [rows]);

  const optionData = options ? options : [];
  const minRowCount = minRows ? minRows : 4;

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel] || row["value"] || row["name"]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel] || row["value"] || row["name"]);
    if (title === "previous-medical-cpt") {
      setSearch(`${row[keyLabel] || row["value"] || row["name"]} ${row[keyType]}`);
    }
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    if (!isHideTable) setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target?.value);
    if (handleSearch) {
      handleSearch(e.target?.value);
    }
  };

  const getTableTitle = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {tableTitleOptions.map((row, index) => {
          if (index === 0) {
            return (
              <div
                key={`row-${index}`}
                style={{
                  width: "25%",
                  textAlign: "left",
                  color: "rgba(0, 0, 0, 0.65)",
                  fontWeight: 600,
                  display: "flex",
                  height: "100%",
                  ...(isRmBorders ? { borderTop: "none" } : { borderTop: "1px solid #dfe3e6" }),
                  alignItems: "center",
                  fontSize: "14px",
                  minWidth: isIcon ? `calc(${tableWidths[index]} - 3rem)` : tableWidths[index],
                  borderLeft: isIcon ? "1px solid #dfe3e6" : 0,
                  marginLeft: isIcon ? "3rem" : "",
                }}
              >
                {row}
              </div>
            );
          }

          return (
            <div
              key={`row-${index}`}
              style={{
                width: "25%",
                textAlign: "left",
                paddingLeft: "11px",
                color: "rgba(0, 0, 0, 0.65)",
                fontWeight: 600,
                borderLeft: "1px solid #dfe3e6",
                borderTop: "none",
                display: "flex",
                height: "100%",
                alignItems: "center",
                fontSize: "14px",
                minWidth: tableWidths[index],
              }}
            >
              {row}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (search !== "") {
      if (optionData?.filter((item) => item.value === search).length > 0) addSelectedItem();
      else return;
    }
  }, [search]);

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div ref={ref} className={`atuoComplete__filter-search origin ${disableSearchIcon ? "disable-icon" : ""}`}>
          <Search
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{ width: "100%", height: "42.5px" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
            disabled={isLocked}
          />
        </div>
        {isHideTable ? (
          ""
        ) : (
          <div className={`atuoComplete__filter-action ${isLocked == true || isLocked == 1 ? "disabled" : ""}`}>
            <button className={"atuoComplete__filter-add"} onClick={addSelectedItem} disabled={isLocked}>
              <span>Add</span>
              <span>+</span>
            </button>
          </div>
        )}
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper
                onScrollFrame={
                  onScroll
                    ? (value) => {
                        onScroll(value);
                      }
                    : null
                }
                css="no-padding x-hidden"
              >
                {!!optionData?.length &&
                  optionData?.map((row, index) => (
                    <div className="atuoComplete__result-item" key={`empty-${title}-${index}`} onClick={() => handleSelectItem(row)}>
                      {title === "previous-medical-cpt" ? (
                        <p style={{ paddingLeft: "28px" }}>
                          {row[keyLabel] || row["value"] || row["name"]} {row[keyType]}
                        </p>
                      ) : (
                        <p style={{ paddingLeft: "28px" }}>{row[keyLabel] || row["value"] || row["name"]}</p>
                      )}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      {isHideTable ? (
        ""
      ) : (
        <div
          className="atuoComplete__result"
          style={{
            paddingTop: disableHeader ? 0 : "1rem",
            height: disableHeader ? `calc(40 * ${minRowCount}px + 48px)` : `calc(40 * ${minRowCount}px + 48px + 1rem)`,
          }}
        >
          <ExtendDataTable
            {...(isRmBorders && { isBorderLeftNone: true })}
            {...(isBorderThick && { isBorderThick: true })}
            isBorderOnHover={isBorderOnHover}
            title={!disableHeader && getTableTitle()}
            disableHeader={disableHeader}
            disableDelete={disableDelete}
            showDelete={true}
            handleDeleteRow={handleDeleteRow}
            onClickRow={onClickTableRow}
            activeRow={activeRow}
            data={tableData || data}
            options={tableOptions}
            widths={tableWidths}
            minRows={8}
          />
        </div>
      )}
    </div>
  );
};

export default AutoCompleteDataTable;

import { Input } from "antd";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchSVG } from "../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ExtendDataTable from "../../../../common/components/SubDataTable/ExtendDataTable";
import { useOutsideDetect } from "../../../../common/utils";
import { getLocal } from '../../../../helpers/Local';
import { getDiseases } from '../../../../services/api.services';
import { editICD, saveAppointmentIcd, setGlobalLoading } from '../../../../store/actions';

const { Search } = Input;
const titleStyle = {
  textAlign: 'left',
  paddingLeft: '11px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontWeight: 600,
  borderLeft: '1px solid #dfe3e6',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  fontSize: '14px',
};
let timer = null;
const ReviewIcdCode = ({ isHaveSearch = false, setDataICD = () => { }, disableButton = false }) => {
  const icdCodes = useSelector(state => state.clinicProviderReview.icd_codes);
  const current_appointment_id = getLocal("current_appointment_id");
  const [icdCodeState, setIcdCodeState] = useState([]);
  const [indexDelete, setIndexDelete] = useState(-1);
  const [selected, setSelected] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [openPanel, setOpen] = useState(false);
  const ref = useRef(null);
  const ref1 = useRef(null);
  const dispatch = useDispatch();
  const [activeRow, setActiveRow] = useState(-1);
  const [search, setSearch] = useState(null);
  useOutsideDetect(ref, ref1, openPanel, setOpen);
  const getTitle = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        <div
          className="td"
          style={{ ...titleStyle, width: '30%', borderLeft: 0 }}
        >
          ICD Code
        </div>
        <div className="td" style={{ ...titleStyle, width: '70%' }}>
          Diagnosis
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (icdCodes) {
      setIcdCodeState(icdCodes);
      setActiveRow(-1);
      setSearch(null);
      setIndexDelete(-1);
    }
  }, [icdCodes]);

  const handleSearch = async (e) => {
    const value = e.target?.value;
    dispatch(setGlobalLoading(true));
    setActiveRow(-1);
    setSearch(value);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const dataSearch = await getDiseases(value);
      setSearchData(dataSearch?.data ?? []);
      dispatch(setGlobalLoading(false));
    }, 500);
  };

  const handleSearchSelect = (row, index) => {
    if (row?.id) {
      setSelected(row);
      setSearch(row?.icd_name);
      setOpen(false);
    } else {
      setSelected(null);
      setSearch(null);
      setOpen(false);
    }
  };

  const handleAdd = () => {
    if (icdCodeState.some((r) => r?.id == selected?.id)) {
      setSelected(null);
      setSearch(null);
      setOpen(false);
      setIndexDelete(-1);
      return null;
    }
    if (selected && selected?.id) {
      setIcdCodeState([...icdCodeState, selected]);
      dispatch(editICD([...icdCodeState, selected]));
      dispatch(
        saveAppointmentIcd(current_appointment_id, {
          icd_code: [...icdCodeState, selected],
        }),
      );
    }
    setSelected(null);
    setSearch(null);
    setOpen(false);
    setIndexDelete(-1);
  };

  useEffect(() => {
    setDataICD && setDataICD(icdCodeState);
  }, [icdCodeState]);

  const handleDeleteRow = (index, row) => {
    setIndexDelete(index);
    if (indexDelete === index) {
      const removeICD = [...icdCodeState];
      removeICD.splice(index, 1);
      setIcdCodeState([...removeICD]);
      dispatch(editICD([...removeICD]));
      dispatch(
        saveAppointmentIcd(current_appointment_id, {
          icd_code: [removeICD],
        }),
      );
    }
  };

  return (
    <div className="patientSearch">
      <div className="review-icd-code">
        {isHaveSearch && (
          <div className="patientSearch__filter">
            <div className="patientSearch__filter-search">
              <Search
                disabled={disableButton}
                value={search}
                placeholder="Search Diseases"
                onChange={handleSearch}
                suffix={<SearchSVG />}
                onFocus={() => setOpen(true)}
              />
            </div>
            <div
              style={disableButton ? { backgroundColor: '#C6C6C6' } : {}}
              className="patientSearch__filter-action"
            >
              <button
                aria-disabled={disableButton}
                disabled={disableButton}
                className="patientSearch__filter-add"
                style={disableButton ? { backgroundColor: '#C6C6C6' } : {}}
                onClick={handleAdd}
              >
                <span>Add</span>
                <span>+</span>
              </button>
            </div>
          </div>
        )}
        {openPanel && search && searchData && searchData.length > 0 && (
          <div
            ref={ref1}
            className="fullContainer patientSearch__result"
            style={{
              height: `${searchData.length > 0 ? searchData.length * 40 : 80
                }px`,
            }}
          >
            <div
              className="tableSection"
              style={{ height: '100%', position: 'relative' }}
            >
              <div className="tableContainer">
                <ScrollWrapper css="no-padding">
                  <div className="tableList">
                    {search &&
                      (searchData || []).map((row, index) => (
                        <div
                          className={`tableItem ${index === 0 ? 'no-border' : ''
                            }`}
                          key={`resultTable-${index}`}
                          onClick={() => handleSearchSelect(row, index)}
                        >
                          <div
                            className="td with-icon"
                            style={{ width: '100%' }}
                          >
                            <p>{row.icd_name ?? ''}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </ScrollWrapper>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: isHaveSearch ? 25 : 0 }}>
          <ExtendDataTable
            title={getTitle()}
            data={icdCodeState}
            indexDelete={indexDelete}
            showDelete={true}
            handleDeleteRow={handleDeleteRow}
            options={['icd_code', 'icd_name']}
            widths={['30%', '70%']}
            minRows={4}
            isFixed
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewIcdCode;

import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "../DataTable/WhiteHeaderTableWithLock";
import { SearchSVG } from "../icons/SVGIcon";
import Loading from "../Loading";
import ScrollWrapper from "../ScrollBar";

import { useOutsideDetect } from "../../utils";
import "./style.scss";

const { Search } = Input;

const CptReview = ({
  rows,
  options,
  query,
  DataOpSearch,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keySearch,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  isModifier,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  minRows,
  itemKeyRight,
  isLocked,
  isHaveSearch = false,
  onScroll,
  isRmBorder,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [rowSelect, setRowSelect] = useState({});
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [openResult, setOpenResult] = useState(false);

  const data = rows ? rows : [];
  const dataSearch = DataOpSearch ? DataOpSearch : [];
  const minRowCount = minRows ? minRows : 3;

  let emptyRows = [];

  if (data && data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  const getTableHeight = () => {
    if (data && data.length < minRowCount + 1) {
      return 40 * minRowCount;
    }
    return 40 * data.length;
  };

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setRowSelect(row);
    setSelected(row[keySearch]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keySearch]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem && !!dataSearch.length) {
      selectItem(selected, selectedValue, selectedType, rowSelect);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target?.value);
    if (handleSearch) {
      handleSearch(e.target?.value);
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  return (
    <div className="atuoComplete" style={{ height: "100%" }}>
      {!!isHaveSearch && (
        <div className="atuoComplete__filter">
          <div ref={ref} className={`atuoComplete__filter-search ${disableSearchIcon ? "disable-icon" : ""}`}>
            <Search
              id={"search_bill"}
              onPressEnter={() => {
                if (dataSearch && dataSearch.length > 0) {
                  handleSelectItem(dataSearch[0]);
                }
              }}
              value={search}
              placeholder={placeholder || "Search"}
              onChange={changeSearchInput}
              style={{ width: "100%" }}
              suffix={!disableSearchIcon && <SearchSVG />}
              onFocus={() => setOpenResult(true)}
            />
          </div>
          <div className={`atuoComplete__filter-action ${isLocked == 1 ? "disabled" : ""}`}>
            <button className="atuoComplete__filter-add" onClick={addSelectedItem} disabled={isLocked}>
              <span>Add</span>
              <span>+</span>
            </button>
          </div>
          {!disableOptions && openResult && !!search && (
            <div ref={ref1} className="atuoComplete__filter-result">
              <div className="atuoComplete__filter-result-list">
                {loading && <Loading />}
                <ScrollWrapper
                  css="no-padding x-hidden"
                  onScrollFrame={(value) => {
                    typeof onScroll === "function" && onScroll(value);
                  }}
                >
                  {!!dataSearch.length &&
                    dataSearch.map((row, index) => {
                      return (
                        <div className="atuoComplete__result-item" key={`empty-${row[keySearch]}-${index}`} onClick={() => handleSelectItem(row)}>
                          <p style={{ paddingLeft: "28px", whiteSpace: "pre" }}>{row[keySearch]}</p>
                        </div>
                      );
                    })}
                </ScrollWrapper>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="atuoComplete__result" style={{ marginTop: "0.5rem", height: "100%" }}>
        <div
          className="atuoComplete__result-list"
          style={{
            height: (3 * (minRowCount + 1) + 3).toString() + "rem",
            borderLeft: "1px solid #dfe3e6",
            borderRight: "1px solid #dfe3e6",
            ...(isRmBorder && { border: "none" }),
          }}
        >
          <DataTable
            isShowBodyBorders={true}
            isBorderThick={true}
            hideHeaderHeight={true}
            title="cpt_code_review"
            is
            labels={isModifier ? ["CPT Code", "Modifier", "Units"] : ["CPT Code", "Modifier", "Description"]}
            widths={isModifier ? ["20%", "20%", "60%"] : ["20%", "20%", "60%"]}
            disableFirstBorder
            isNormal
            sizeHeightFix={getTableHeight()}
            columns={
              isModifier
                ? [
                    { sortable: true, key: "cpt_code" },
                    { sortable: false, key: "modifier" },
                    { sortable: false, key: "units" },
                  ]
                : [
                    { sortable: true, key: "cpt_code" },
                    { sortable: false, key: "modifier" },
                    { sortable: false, key: "description" },
                  ]
            }
            disableDelete={typeof removeItem !== "function"}
            handleDelete={(value, index) => removeItem(index)}
            handleClickRow={(row, index) => clickHandle(row.row[keySearch], index)}
            rowData={(data || []).map((item, index) => {
              return {
                key: item.key,
                id: item?.id,
                row: item,
                cpt_code: item.cpt_code,
                description: <span style={{ textTransform: "capitalize" }}>{item.short_desc.toLowerCase()}</span>,
                modifier: item.pivot && item.pivot.modifier,
                units: item.pivot && item.pivot.units,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", "", ""]}
            isLocked={isLocked}
          />
        </div>
      </div>
    </div>
  );
};

export default CptReview;

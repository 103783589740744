/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "antd";
import Loading from "../Loading";
import { useWindowSize } from "../../utils";

function DisplayIDCard({ data, cardType }) {
  const [imageData, setImageData] = useState();
  const [imgWidth, setImgWidth] = useState(0);
  const defaultIDCard = "/images/drivers-license-ph.png";
  const parentRef = useRef(null);
  const size = useWindowSize(parentRef);
  useEffect(() => {
    const images = [{ url: !data?.card ? defaultIDCard : data?.card.image_path }, { url: !data?.card ? defaultIDCard : data?.card.image2_path }];
    if (parentRef.current) {
      setImgWidth(parentRef.current.offsetWidth);
    }
    setImageData(images);
  }, [size, data?.card, parentRef]);

  const onChange = (a, b, c) => {};

  return (
    <div className="resourceInfo center" ref={parentRef} style={{ position: "relative" }}>
      {!data?.card ? (
        <img src={defaultIDCard} alt="driver card" style={{ width: "80%", marginRight: "auto" }} />
      ) : data?.card.type !== cardType ? (
        <img src={defaultIDCard} alt="driver card" style={{ width: "80%", marginRight: "auto" }} />
      ) : imageData === undefined ? (
        <Loading />
      ) : (
        <Carousel afterChange={onChange} effect="fade">
          {imageData?.map((element, index) => (
            <div key={index}>
              <img
                src={element.url}
                style={{
                  width: imgWidth * 0.8,
                  height: imgWidth * 0.5,
                  marginRight: "auto",
                  borderRadius: "10px",
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default DisplayIDCard;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../../common/components/Card";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import DeleteButton from "../../../../common/components/icons/DeleteIconButton";
import { RightArrowSVG } from "../../../../common/components/icons/SVGIcon";
import { delete_ocr_category, get_ocr_category, get_ocr_words, post_ocr_words, put_ocr_words, setGlobalLoading } from "../../../../store/actions";
let timer = null;

const DocumentCategorization = () => {
  const dispatch = useDispatch();
  const [idFocus, setIdFocus] = useState(null);
  const [containEdit, setContainEdit] = useState({});
  const [activeDeleted, setActiveDeleted] = useState();
  const orc_category = useSelector((state) => state.setting.orc_category);
  const ocr_words = useSelector((state) => state.setting.ocr_words);
  const loading_get = useSelector((state) => state.setting.loading);

  const fetchAPIList = async () => {
    await dispatch(get_ocr_words());
  };

  const fetchApiSelect = async () => {
    await dispatch(get_ocr_category());
  };

  useEffect(() => {
    if (ocr_words) {
      let localState = {};
      ocr_words.map((r, index) => {
        localState = {
          ...localState,
          [`${r?.id}`]: {
            category_id: r?.category?.id,
            category_name: r?.category?.category,
            id: r?.id,
            word: r.word,
          },
        };
      });
      setContainEdit(localState);
    }
  }, [ocr_words]);

  useEffect(() => {
    fetchApiSelect();
    fetchAPIList();
  }, []);

  const handlePlus = async () => {
    const formData = new FormData();
    formData.append("ocr_category_id", "");
    formData.append("word", "");
    await dispatch(post_ocr_words(formData));
    fetchAPIList();
  };

  const handleSetEdit = async (id, value) => {
    await setIdFocus(id);
    await setContainEdit({
      ...containEdit,
      [`${id}`]: { ...containEdit[`${id}`], word: value },
    });
  };

  const handleDelete = async (id) => {
    if (activeDeleted !== id) {
      setActiveDeleted(id);
      return;
    }
    await dispatch(delete_ocr_category(id));
    fetchAPIList();
  };

  useEffect(() => {
    if (idFocus) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(
          put_ocr_words(idFocus, {
            word: containEdit[`${idFocus}`]?.word,
            ocr_category_id: containEdit[`${idFocus}`]?.category_id,
          })
        );
      }, 500);
    }
  }, [containEdit]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_get));
  }, [loading_get]);

  const handleSelectEdit = async (id, value) => {
    await setIdFocus(id);
    const name_categor = orc_category.filter((r) => {
      if (r?.id === value) {
        return r;
      } else {
        return null;
      }
    });
    await setContainEdit({
      ...containEdit,
      [`${id}`]: {
        ...containEdit[`${id}`],
        category_id: value,
        category_name: name_categor[0]?.category || "",
      },
    });
  };

  return (
    <CardBlock
      isOverFlow={true}
      title="Document Categorization"
      varient="half"
      other=""
      contentPadding="1rem"
      subAction={
        <button className="text-btn" onClick={handlePlus}>
          Add +
        </button>
      }
    >
      <div
        className="documentCategorization"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          paddingTop: "8px",
        }}
      >
        <div style={{ flex: 1, marginRight: "16px" }}>
          {(ocr_words || []).map((category, index) => (
            <div
              key={`documentCategory-${category?.id}-${index}`}
              className="documentCategory"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ flex: 1 }}>
                <EditableFormInput
                  label="Document Text Contains"
                  value={containEdit[`${category?.id}`]?.word}
                  fullWidth={true}
                  handleChange={(value) => {
                    handleSetEdit(category?.id, value);
                  }}
                />
              </div>
              <div style={{ margin: "0 16px" }} className="documentArrowIcon">
                <RightArrowSVG />
              </div>
              <div style={{ flex: 1 }}>
                <EditableFormInput
                  label="Document Categorized As"
                  value={containEdit[`${category?.id}`]?.category_name}
                  fullWidth={true}
                  handleChange={(value) => {
                    handleSelectEdit(category?.id, value);
                  }}
                  type={"check"}
                  options={orc_category}
                  optionKey={"id"}
                  valueKey={"category"}
                />
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            alignSelf: "flex-start",
            marginTop: "0px",
            width: "20px",
            height: "",
          }}
        >
          {(ocr_words || []).map((category, index) => (
            <div
              key={`delete-btn-${index}`}
              style={{
                marginTop: "0.5rem",
                paddingTop: "4px",
                textAlign: "right",
                lineHeight: "40px",
                height: "40px",
              }}
            >
              <DeleteButton handleClick={() => handleDelete(category?.id)} selected={activeDeleted === category?.id} icon={"can"} />
            </div>
          ))}
        </div>
      </div>
    </CardBlock>
  );
};

export default DocumentCategorization;

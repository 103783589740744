import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { useWindowSize } from "../../../../common/utils";
import { getInventoryCostHistory, setGlobalLoading, setInventoryData } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import NarcoticFilter from "./NarcoticInventoryFilter";
import NarcoticSidebar from "./NarcoticInventorySidebar";

const filterDataByOption = (data, option) => {
  const { quantity, location, startDate, endDate } = option;
  let filteredData = data;
  if (!quantity && !location && !startDate && !endDate) {
    return filteredData;
  }
  if (endDate) {
    filteredData = filteredData?.filter((d) => d.date && moment(endDate).diff(moment(d.date)) >= 0);
  }
  if (startDate) {
    filteredData = filteredData?.filter((d) => d.date && moment(d.date).diff(moment(startDate)) >= 0);
  }
  if (quantity) {
    filteredData = filteredData?.filter((d) => d.quantity >= +quantity);
  }
  if (location) {
    filteredData = filteredData?.filter((d) => d.location === location);
  }
  return filteredData;
};

const NarcoticInventory = ({ data, title, locations }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const narcotics = useSelector((state) => state.inventory.narcotics);
  const inventory = useSelector((state) => state.inventory.inventory);
  const filterOption = useSelector((state) => state.inventory.inventoryFilter);
  const loadingSearch = useSelector((state) => state.inventory.loadingAPI);

  const dispatch = useDispatch();

  const tableRows = useMemo(() => {
    if (narcotics && narcotics.length) {
      return filterDataByOption(narcotics, filterOption);
    }
    return [];
  }, [narcotics, filterOption]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("inventory", tableRows[index]));
    dispatch(getInventoryCostHistory(tableRows[index].id, "narcotic"));
    handleSidebar();
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loadingSearch));
  }, [loadingSearch]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <NarcoticFilter locations={locations} />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="inventory_supplies"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={["Name", "Date", "Quantity", "Location"]}
                widths={["calc(72% - 130px)", "calc(28% - 130px)", "130px", "130px"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "date" },
                  { sortable: false, key: "quantity" },
                  { sortable: false, key: "location" },
                ]}
                rowData={rowData?.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE" />
                        <p style={{ paddingLeft: "0.75rem" }}>{row?.name || ""}</p>
                      </div>
                    ),
                    quantity: row?.inventory,
                    date: row?.date && moment(row?.date).format("MM/DD/YYYY"),
                    location: row?.location,
                  };
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {inventory && activeIndex >= 0 && (
        <NarcoticSidebar
          ref={sidebarRef}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          locations={locations}
        />
      )}
    </div>
  );
};

export default NarcoticInventory;

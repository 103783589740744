import React, {useState, useEffect} from 'react';
import {validateField} from '../../../services/validations';
import EditableFormInput from '../../../common/components/EditableText/EditableFormInput';

const FogetPasswordForm = ({handleForget, onClickCancel}) => {
  const [email, setEmail] = useState('');
  const [enableError, setEnableError] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    if (validateField('email', email)) {
      setInvalid(true);
    } else {
      setInvalid(false);
    }
  }, [email]);

  const handleForgetClick = () => {
    if (invalid) {
      setEnableError(true);
      return;
    }
    handleForget(email);
  };

  const handleForgetKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (invalid) {
        setEnableError(true);
        return;
      }
      handleForget(email);
    }
  };

  return (
    <div className="loginForm">
      <div className="formContainer">
        <div className="formContent">
          <div className="formContent">
            <div className="formContent__username">
              <div className="formContent__username-labelWrapper">
                <EditableFormInput
                  label="Email"
                  value={email}
                  handleChange={(value) => setEmail(value)}
                  isError={validateField('email', email)}
                  enableErrorText={enableError}
                  helperText="Email is Required!"
                  required
                  fullWidth
                  autoFocus
                />
              </div>
            </div>

            <div className="formContent__buttonWrapper">
              <button
                id="loginButton"
                className="common-btn outlined-btn"
                style={{marginTop: '0.4rem', border: 0, marginLeft: 'auto'}}
                onClick={onClickCancel}
              >
                Cancel
              </button>
              <button
                id="loginButton"
                className="common-btn"
                style={{marginTop: '0.4rem', border: 0, marginLeft: 12}}
                onClick={handleForgetClick}
                onKeyDown={handleForgetKeyDown}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FogetPasswordForm;

import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import PatientChartSidebar from "./PatientChartSidebar";
import TopBar from "../../common/components/SubTopBar";
import ScrollWrapper from "../../common/components/ScrollBar";
import PDFView from "../../common/components/PDFView";
import { getPatientDocuments } from "../../store/actions";
import { TabHeaders } from "./constant";
import "./style.scss";
import { getLocal } from "../../helpers/Local";
import CookieConsent from "react-cookie-consent";
import { Box, Typography } from "@material-ui/core";

const { TabPane } = Tabs;

const PatientDashboard = ({ history }) => {
  const dispatch = useDispatch();
  const patientDocuments = useSelector(state => state.dashboard.patientDocuments);
  const loadingDocument = useSelector(state => state.dashboard.loadingPatients);
  const documentDetails = useSelector(state => state.dashboard.documentDetails);
  const [isAuth] = useState(getLocal("cvai-auth-token") ? true : false);
  const [activeTab] = useState(0);
  const [isSideBar, setIsSidebar] = useState(true);

  useEffect(() => {
    dispatch(getPatientDocuments());
  }, [])

  const closeSidebar = () => {
    setIsSidebar(false);
  }

  const enablePDFViewer = useMemo(() => {
    if (loadingDocument) {
      return false;
    }
    if (documentDetails && documentDetails.file_path) {
      return true;
    }
    return false;
  }, [documentDetails, loadingDocument]);

  if (!isAuth) {
    history.push("/login");
    return null;
  }

  return (
    <div className="clinicWorkflow patient-dashboard">
      <div className="clinicWorkflow__main">
        <ScrollWrapper css="no-padding x-hidden">
          <div className="clinicWorkflow__main-page">
            <TopBar title="Patient Dashboard" subStatus={""} noLeftSide />
            <div className={"clinicWorkflow__main-container"}>
              <div
                className="clinicWorkflow__main-subTitle tabContainer"
                style={{
                  paddingLeft: "5%",
                }}
              >
                <div className="tabHeaders" style={{ height: "29px" }}>
                  <ScrollWrapper css="no-padding tab-header-scroll">
                    <div style={{ display: "flex" }}>
                      {TabHeaders.map((tab, index) => (
                        <div
                          key={`tabHeaders-${tab.label}-${index}`}
                          className={`tabHeaders-item ${activeTab === index ? "active" : ""
                            }`}
                          style={{ minWidth: tab.width }}
                        >
                          {tab.label}
                        </div>
                      ))}
                    </div>
                  </ScrollWrapper>
                </div>
              </div>
              {activeTab === 0 && (
                <div className={`clinicWorkflow__main-section 
                    ${isSideBar ? "sidebar" : ""} 
                  `}
                >
                  <div className="eventsContainer has-sub-header">
                    <Tabs
                      className="patientChart"
                      type="card"
                      activeTab="documentation"
                      onTabClick={() => setIsSidebar(true)}
                    >
                      <TabPane tab="Documentation" key="documentation">
                        <PDFView url={enablePDFViewer && documentDetails.file_path} />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Accept all"
                buttonStyle={{ backgroundColor: '#005ef6', border: '1px solid #005ef6', color: 'white', order: "1", fontSize: '0.875rem', marginBottom: '0', padding: '0 12px', margin: '0px',minHeight:'2.5rem',minWidth:'150px',height:'40px',width:'150px',alignItems:'center' }}
                declineButtonStyle={{ backgroundColor: '#005ef6', border: '1px solid #005ef6', color: 'white', order: "2", fontSize: '0.875rem', marginBottom: '0', padding: '0 12px', margin: '6px 0px',minHeight:'2.5rem',minWidth:'150px',height:'40px',width:'150px',alignItems:'center' }}
                declineButtonText="Required only"
                enableDeclineButton={true}
                cookieName="myAwesomeCookieName2"
                style={{ background: "white", padding: '0px 6rem',alignItems:'center',gap:'5rem' }}
                expires={150}
                overlay
              >
                <h4 style={{ fontWeight: 900, marginBottom: 0,fontSize:'0.875rem' }}>About cookies on this site</h4>
                <Box width="100%" display='flex' justifyContent="space-between" gridGap="5rem"  >
                  <div style={{width:'33.33%'}} >
                    <Typography noWrap={false} variant="caption" style={{ color: 'black',fontSize:'.875rem', lineHeight:"18px",display:'inline-block' }}  >Our websites require some cookies to function properly (required). In addition, other cookies may be used with your consent to analyze site usage, improve the use experience and for advertising</Typography>
                  </div>

                  <div style={{width:'33.33%'}} > <Typography noWrap={false} variant="caption" style={{ color: 'black',fontSize:'.875rem', lineHeight:"18px",display:'inline-block' }} > For more information, please review your <a>Cookie preferences</a> options and FAACT's <a>privacy statement</a> </Typography> </div>
                  <div style={{width:'33.33%'}} > <Typography noWrap={false} variant="caption" style={{ color: 'black',fontSize:'.875rem', lineHeight:"18px",display:'inline-block' }} >To provide a smooth navigation, your cookie preferences will be shared across the FAACT web domains listed <a>here</a></Typography> </div>
                </Box>

              </CookieConsent>
          </div>
        </ScrollWrapper>
        <PatientChartSidebar isShow={isSideBar} resource={patientDocuments} onClose={closeSidebar} />
      </div>
    </div>
  );
};

export default PatientDashboard;
/* eslint-disable jsx-a11y/alt-text */
import { Upload } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Toaster from "../../../../../common/components/Toast/index";
import { adminSettingLogoUpload } from "../../../../../store/actions";

let timer;
function DocumentLogoUploader({ resource }) {
  const dispatch = useDispatch();
  const [isToaster, setIsToaster] = useState(false);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (!resource.loading && !!resource.logo_url) {
      setLogoUrl(resource.logo_url);
      setFile(null);
      setFileContent("");
    }
  }, [resource]);

  const uploadProps = useMemo(() => {
    return {
      onRemove: (file) => {
        setFile(null);
        setFileContent(null);
      },
      beforeUpload: (file) => {
        if (file.type === "image/jpeg" || file.type === "image/png") {
          const reader = new FileReader();
          reader.onload = (e) => {
            setFileContent(e.target.result);
          };
          reader.readAsDataURL(file);
          setFile(file);
          return false;
        } else {
          alert("Please choose the correct image type.");
          return true;
        }
      },
    };
  }, []);

  const handleImageUpload = (e) => {
    if (!fileContent) {
      setIsToaster(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsToaster(false);
      }, 2000);
      return;
    }
    const payload = {
      type: "logo",
      is_second: false,
      image: fileContent,
    };

    dispatch(adminSettingLogoUpload(payload));
  };

  return (
    <div
      className="documentLogoUpload"
      style={{
        height: "85%",
      }}
    >
      {isToaster && <Toaster status="failed" type="File Upload" msg="Please select an Image" />}
      <div className="FileImporter">
        <Upload
          className="FileImporter__file"
          {...uploadProps}
          style={{
            maxWidth: "190px",
          }}
        >
          {file
            ? file?.name.length > 20
              ? file?.name.slice(0, 10) + "..." + file?.name.slice(file?.name.length - 7, file?.name.length - 4) + "." + file.type.split("/")[1]
              : file?.name
            : "Choose File..."}
        </Upload>
        <button onClick={(e) => handleImageUpload(e)} className="common-btn blue-btn">
          {fileContent ? "Upload File" : "Import File"}
        </button>
      </div>
      <div
        className="img-logo"
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          padding: "20px",
          marginTop: "0.5rem",
          border: "1px solid #d6d6d6",
          position: "relative",
        }}
      >
        {resource.loading
          ? null
          : !!logoUrl && (
              <img
                src={logoUrl}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  position: "relative",
                }}
              />
            )}
      </div>
    </div>
  );
}

export default DocumentLogoUploader;

import React from "react";
import { DragPreviewImage, useDrag, useDrop } from "react-dnd";

const DetailItem = ({ isDraggable, icon, label, pos, item, moveItem, handleClick }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "operation", option: item, pos: pos },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "operation",
    canDrop: (item) => item.pos !== pos,
    drop: (item) => moveItem && moveItem(item.pos, pos, item.option),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const onClick = () => {
    if (!isDragging) {
      handleClick();
    }
  };

  return (
    <>
      <div
        ref={isDraggable && drop}
        className="arteryDetail-medical"
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        onClick={onClick}
      >
        <DragPreviewImage connect={preview} />
        <div ref={isDraggable && drag}>{icon}</div>
        <div className="arteryDetail-medical__value">{label}</div>
      </div>
    </>
  );
};

export default DetailItem;

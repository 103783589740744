import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "antd";
import CardBlock from "../../../../common/components/Card";
import { StaticResource } from "../../constant";
import EndovascularTable from "./EndovascularTable";
import NonEndovascularTable from "./NonEndovascularTable";
import ProviderContributionSidebar from "./ProviderContributionSidebar";

const ProviderContribution = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const [endovascularCheck, setEndovascularCheck] = useState(0);
  const [endovascularWarning, setEndovascularWarning] = useState(0);
  const [nonendovascularCheck, setNonEndovascularCheck] = useState(0);
  const [nonendovascularWarning, setNonEndovascularWarning] = useState(0);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const setEndovascularCounts = ({ endovascularCheck, endovascularWarning }) => {
    setEndovascularCheck(endovascularCheck);
    setEndovascularWarning(endovascularWarning);
  };

  const setNonEndovascularCounts = ({ nonendovascularCheck, nonendovascularWarning }) => {
    setNonEndovascularCheck(nonendovascularCheck);
    setNonEndovascularWarning(nonendovascularWarning);
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <CardBlock
            title={
              <>
                Endovascular Results
                <div className="statusHealth" style={{ margin: "auto", marginLeft: 0 }}>
                  <Icon type="clock-circle" theme="twoTone" twoToneColor="#24A148" />
                  {endovascularCheck}
                  <span style={{ minWidth: "10px", display: "inline-block" }} />
                  <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                  {endovascularWarning}
                </div>
              </>
            }
          >
            <EndovascularTable setCounts={setEndovascularCounts} onRowClick={() => currentUserInfo && setIsSidebar(true)} />
          </CardBlock>
          <CardBlock
            title={
              <>
                Non-Endovascular Results
                <div className="statusHealth" style={{ margin: "auto", marginLeft: 0 }}>
                  <Icon type="clock-circle" theme="twoTone" twoToneColor="#24A148" />
                  {nonendovascularCheck}
                  <span style={{ minWidth: "10px", display: "inline-block" }} />
                  <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                  {nonendovascularWarning}
                </div>
              </>
            }
          >
            <NonEndovascularTable setCounts={setNonEndovascularCounts} onRowClick={() => currentUserInfo && setIsSidebar(true)} />
          </CardBlock>
        </div>
      </div>
      {currentUserInfo && Object.keys(currentUserInfo).length > 0 && (
        <ProviderContributionSidebar
          ref={sidebarRef}
          userDetail={currentUserInfo}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default ProviderContribution;

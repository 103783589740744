import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../common/components/Card";
import {
  addProcedureDetails,
  editProcedureType,
  getProcedureType,
  saveProcedureType,
  setProcedureDetails,
  sortProcedureVariables,
  update_cpt_rule,
} from "../../store/actions";
import UltrasoundBlock from "./components/FollowUpUltrasounds";
import SupportLocationBlock from "./components/LocationsAndSupport";
import ProviderBlock from "./components/ProcedureProviders";
import ProcedureSearch from "./components/ProcedureSearch";
import DocumentationBlock from "./components/TemplateDocumentation";
import VariableCaptureBlock from "./components/VariableCapture";
import CPTCodeLogicBlock from "./components/cptCodeLogic";
import "./style.scss";
// Icons
import { Icon } from "antd";
import { getLocal } from "../../helpers/Local";

const ProcedureDetail = (props) => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeCard, setActiveCard] = useState("");
  const [resource, setResource] = useState(null);
  const [duplicatedRow, setDuplicatedRow] = useState(null);
  const [isVariableSelected, setIsVariableSelected] = useState({
    index: null,
    selectedVariable: {
      id: null,
    },
  });

  const variable = useSelector((state) => state.procedureDetail.variable);
  const cptCodes = useSelector((state) => state.procedureDetail.cptCodes);
  const detailProcedureData = useSelector((state) => state.common.detailProcedureData);

  const id_procedure_type = getLocal("id_procedure_type");

  const handleAddNewData = async (type, data) => {
    if (detailProcedureData && Object.keys(detailProcedureData).length > 0) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        saveProcedureType({
          question: "",
          procedure_type_id: detailProcedureData?.id,
          answers: [],
          cpt_code: [],
        })
      );
      await dispatch(getProcedureType(detailProcedureData?.id));
    }
  };

  const updateCPT = async () => {
    const arrayADD = cptCodes.map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        procedure_type_id: detailProcedureData?.id,
        cpt_code: r?.cpt_code,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      cpt_code_id: 1,
      procedure_type_id: detailProcedureData?.id,
      cpt_code: { code: "", short_desc: "" },
      condition_serialize: [
        {
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(setProcedureDetails("cptCodes", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      update_cpt_rule(id_procedure_type, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  const enableDuplicate = (row) => {
    if (row) {
      setDuplicatedRow(row);
    }
  };

  const duplicateRow = async () => {
    const tempArray = cptCodes.map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        procedure_type_id: detailProcedureData?.id,
        cpt_code: r?.cpt_code,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      cpt_code_id: 1,
      procedure_type_id: detailProcedureData?.id,
      cpt_code: { ...duplicatedRow?.cpt_code },
      condition_serialize: [...duplicatedRow?.condition_serialize.map((obj) => ({ ...obj }))],
    };
    await dispatch(setProcedureDetails("cptCodes", tempArray && Object.keys(tempArray).length > 0 ? [...tempArray, param] : [param]));
    await dispatch(
      update_cpt_rule(id_procedure_type, {
        rules: tempArray && Object.keys(tempArray).length > 0 ? [...tempArray, param] : [param],
      })
    );
  };

  const moveCard = async (type) => {
    const currentIndex = isVariableSelected?.index;
    if (type === "up") {
      const previousIndex = currentIndex - 1;
      const source = variable[currentIndex]["order"];
      const destination = variable[previousIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortProcedureVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: previousIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
    if (type === "down") {
      const nextIndex = currentIndex + 1;
      const source = variable[currentIndex]["order"];
      const destination = variable[nextIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortProcedureVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: nextIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const update = async () => {
    if (detailProcedureData && Object.keys(detailProcedureData).length > 0) {
      const index = isVariableSelected?.index;
      const id = isVariableSelected?.selectedVariable?.id;
      if (variable?.length > 0 && id) {
        variable[index]["answer"] = [
          ...variable[index]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(setProcedureDetails("variable", [...variable]));
        await dispatch(
          editProcedureType(id && id, {
            variable_name: variable[index]["variable_name"],
            question: variable[index]["quest"],
            procedure_type_id: detailProcedureData?.id,
            answers: variable[index]["answer"],
          })
        );
        await dispatch(getProcedureType(detailProcedureData?.id));
      }
    }
  };

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Search Hospital Procedures" varient="quarter" other="" contentPadding="1rem">
            <ProcedureSearch setResource={setResource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Hospital Providers" varient="quarter" other="" contentPadding="1rem">
            <ProviderBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Hospital Locations" varient="quarter" other="" contentPadding="1rem">
            <SupportLocationBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock title="Template Documentation" varient="quarter" other="" contentPadding="1rem">
            <DocumentationBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            title="Variable Capture"
            onClick={() => {
              setActiveCard("variable_capture");
            }}
            varient={activeCard == "variable_capture" || activeCard == "" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 16,
                    position: "absolute",
                    top: 2,
                    left: -35,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  {isVariableSelected?.index >= 1 && variable?.length > 1 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      type="up-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => !isDisabled && moveCard("up")}
                    />
                  ) : (
                    ""
                  )}
                  {(isVariableSelected?.index || isVariableSelected?.index === 0) &&
                  variable?.length > 1 &&
                  isVariableSelected?.index !== variable?.length - 1 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      type="down-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => !isDisabled && moveCard("down")}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <button
                  disabled={detailProcedureData && Object.keys(detailProcedureData).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    handleAddNewData("variable", {
                      quest: "",
                      answer: [],
                    });
                  }}
                >
                  Add Specification +
                </button>
                <button
                  disabled={(detailProcedureData && Object.keys(detailProcedureData).length <= 0) || isVariableSelected.index === null}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={update}
                >
                  Add Answer +
                </button>
              </div>
            }
          >
            <VariableCaptureBlock resource={resource} rowAction={setIsVariableSelected} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            isOverFlowHidden={true}
            title="Follow-Up Ultrasounds"
            onClick={() => {
              setActiveCard("ultrasounds");
            }}
            varient={activeCard == "ultrasounds" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
          >
            <UltrasoundBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            isHpcclblock={true}
            title="CPT Code Logic"
            onClick={() => {
              setActiveCard("cpt_code_logic");
            }}
            varient={activeCard == "cpt_code_logic" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {duplicatedRow && !duplicatedRow?.disableDelete && (
                  <button disabled={detailProcedureData && Object.keys(detailProcedureData).length <= 0} className="text-btn" onClick={duplicateRow}>
                    Duplicate
                  </button>
                )}
                <button
                  disabled={detailProcedureData && Object.keys(detailProcedureData).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updateCPT}
                >
                  Add +
                </button>
              </div>
            }
          >
            <CPTCodeLogicBlock resource={resource} rowAction={enableDuplicate} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ProcedureDetail;

import { DatePicker, Drawer, message, Upload } from "antd";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { BASE_API } from "../../../../common/constants/constants";
import { deletePolicies, getPolicies, postPolicies, putPolicies } from "../../../../store/actions";

const draggerProps = {
  name: "file",
  multiple: true,
  action: BASE_API + "patients/1/upload",
};

const ManualPoliciesSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, policiesDetail, changeActiveIndex }, ref) => {
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [isNew, setIsNew] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [files, setFiles] = useState([]);
  const [isToastr, setIsToastr] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dataRef = useRef(null);
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyName, setPolicyName] = useState("");

  const [state, setState] = useState({
    purposeEdit: EditorState.createEmpty(),
    appliesEdit: EditorState.createEmpty(),
    policyEdit: EditorState.createEmpty(),
    proceduresEdit: EditorState.createEmpty(),
  });
  const [isReset, setIsReset] = useState(false);

  const dispatch = useDispatch();
  const currentUserInfo = useSelector((state) => state?.common.currentUserInfo);
  const sideNavStatus = useSelector((state) => state?.common.sideNavStatus);

  const fetchState = () => {
    if (policiesDetail) {
      setReset(true);
      const purposeBlock = convertFromHTML(policiesDetail?.purpose ? policiesDetail?.purpose : "");
      const appliesBlock = convertFromHTML(policiesDetail?.apply_to ? policiesDetail?.apply_to : "");
      const policyBlock = convertFromHTML(policiesDetail?.policy ? policiesDetail?.policy : "");
      const proceduresBlock = convertFromHTML(policiesDetail?.procedures ? policiesDetail?.procedures : "");
      setState({
        id: policiesDetail?.id,
        policy_number: policiesDetail?.policy_number,
        policy_name: policiesDetail?.policy_name,
        approved_by: policiesDetail?.approved_by,
        purposeEdit: purposeBlock
          ? EditorState.createWithContent(ContentState.createFromBlockArray(purposeBlock.contentBlocks, purposeBlock.entityMap))
          : EditorState.createEmpty(),
        appliesEdit: appliesBlock
          ? EditorState.createWithContent(ContentState.createFromBlockArray(appliesBlock.contentBlocks, appliesBlock.entityMap))
          : EditorState.createEmpty(),
        policyEdit: policyBlock
          ? EditorState.createWithContent(ContentState.createFromBlockArray(policyBlock.contentBlocks, policyBlock.entityMap))
          : EditorState.createEmpty(),
        proceduresEdit: proceduresBlock
          ? EditorState.createWithContent(ContentState.createFromBlockArray(proceduresBlock.contentBlocks, proceduresBlock.entityMap))
          : EditorState.createEmpty(),
        date: moment(policiesDetail?.effective_date),
      });
      let files = [];
      if (policiesDetail?.file !== null) {
        files = JSON.parse(policiesDetail?.file);
      }
      if (files?.length > 0) {
        files.forEach((file, index) => {
          setFiles((prevFile) => [
            ...prevFile,
            {
              url: file?.fileUrl,
              name: file?.fileName,
              uid: index + 1,
              status: "done",
            },
          ]);
        });
      }
    }
  };

  const onChangeUpload = (info) => {
    const { status } = info?.file;
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info?.file?.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
      setUploaded(true);
    }
  };

  const downloadFile = (url, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  // const changePolicies = (type) => (value) => {
  //   const newPolicies = { ...policies, [type]: value };
  //   setPolicies(newPolicies);
  // };

  const resetPolicies = () => {
    setPolicyNumber("");
    setPolicyName("");
    setState({
      purposeEdit: EditorState.createEmpty(),
      appliesEdit: EditorState.createEmpty(),
      policyEdit: EditorState.createEmpty(),
      proceduresEdit: EditorState.createEmpty(),
    });
    setIsNew(true);
  };

  const deletePolicy = () => {
    if (!confirmDelete) {
      return setConfirmDelete(true);
    }
    dispatch(deletePolicies(policiesDetail?.id));
    dispatch(getPolicies());
    setConfirmDelete(false);
    handleCloseSidebar();
  };

  const cancelNewPolicies = () => {
    fetchState();
    setIsNew(false);
  };

  const updateAll = async (value) => {
    const { id } = value;
    if (currentUserInfo && Object.keys(currentUserInfo).length > 0 && id) {
      const saveSuccess = await dispatch(
        putPolicies(id, {
          policy_number: value?.policy_number,
          policy_name: value?.policy_name,
          purpose: stateToHTML(value?.purposeEdit.getCurrentContent()),
          policy: stateToHTML(value?.policyEdit.getCurrentContent()),
          apply_to: stateToHTML(value?.appliesEdit.getCurrentContent()),
          procedures: stateToHTML(value?.proceduresEdit.getCurrentContent()),
          approved_by: value?.approved_by,
          effective_date: value?.date ? moment(value?.date).format("MM/DD/YYYY") : null,
        })
      );
      const getList = await dispatch(getPolicies());
      if (saveSuccess && getList) {
        const activeIndex = getList?.findIndex((policy) => policy?.id === id);
        changeActiveIndex(activeIndex);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    }
  };

  const changeHandler = useCallback(
    debounce((value) => updateAll(value), 400),
    []
  );

  const handleStateChange = (type, value) => {
    const newState = {
      ...state,
      [type]: value,
    };
    setState(newState);
    if (!isNew && isReset) {
      // isReset && changeHandler(newState);
      dataRef.current = newState;
    }
  };

  const saveNewPolicies = async () => {
    const saveNewSuccess = await dispatch(
      postPolicies({
        policy_number: policyNumber,
        policy_name: policyName,
        purpose: stateToHTML(state?.purposeEdit.getCurrentContent()),
        policy: stateToHTML(state?.policyEdit.getCurrentContent()),
        apply_to: stateToHTML(state?.appliesEdit.getCurrentContent()),
        procedures: stateToHTML(state?.proceduresEdit.getCurrentContent()),
        approved_by: currentUserInfo?.name,
        effective_date: state?.date ? moment(state?.date).format("MM/DD/YYYY") : null,
      })
    );
    const getNewList = await dispatch(getPolicies());
    if (saveNewSuccess && getNewList) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
    setIsNew(false);
  };

  useEffect(() => {
    if (policiesDetail && policiesDetail?.id !== state?.id) {
      setFiles([]);
      fetchState();
      setIsReset(false);
      setConfirmDelete(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [policiesDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  useEffect(() => {
    return () => {
      if (dataRef.current) {
        updateAll(dataRef.current);
      }
    };
  }, []);

  if (!policiesDetail || !Object.keys(policiesDetail).length) {
    return null;
  }

  return (
    <Drawer
      title={!isNew ? `Policy ${state?.policy_number} - ${state?.policy_name}` : " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Policy" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          >
            {isNew ? (
              <>
                <div className="resourceInfo">
                  <EditableFormInput
                    label={"Policy No"}
                    value={policyNumber}
                    handleChange={(value) => {
                      setPolicyNumber(value);
                    }}
                  />
                </div>
                <div className="resourceInfo">
                  <EditableFormInput
                    label={"Policy Name"}
                    value={policyName}
                    handleChange={(value) => {
                      setPolicyName(value);
                    }}
                  />
                </div>

                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Purpose: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      placeholder="Purpose"
                      editorState={state?.purposeEdit}
                      onEditorStateChange={(value) => {
                        handleStateChange("purposeEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Applies to: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      placeholder="Applies"
                      editorState={state?.appliesEdit}
                      onEditorStateChange={(value) => {
                        handleStateChange("appliesEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Policy: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      placeholder="Policy"
                      editorState={state?.policyEdit}
                      onEditorStateChange={(value) => {
                        handleStateChange("policyEdit", value);
                      }}
                    />
                  </div>
                </div>

                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Procedures: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      editorState={state?.proceduresEdit}
                      placeholder="Procedures"
                      onEditorStateChange={(value) => {
                        handleStateChange("proceduresEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%", marginTop: "5px" }}>Form Attachment: </b>
                  <div className="detailContent" style={{ display: "flex" }}>
                    <div style={{ marginLeft: "10px" }}>
                      <Upload onChange={onChangeUpload} className="barcode-uploader" {...draggerProps}>
                        <button className="common-btn">Upload File</button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%" }}>Approved by: </b>
                  <div style={{ marginLeft: "0.5rem" }}>{currentUserInfo?.name || ""}</div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%", marginTop: "10px" }}>Effective date: </b>
                  <div style={{ marginLeft: "0.5rem" }}>
                    <DatePicker
                      format={"MM/DD/YYYY"}
                      placeholder="Effective date"
                      suffixIcon={<ExpandSVG />}
                      onChange={(value) => {
                        handleStateChange("date", value);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="resourceInfo">
                  <EditableFormInput
                    label={"Policy No"}
                    value={state?.policy_number}
                    handleChange={(value) => handleStateChange("policy_number", value)}
                  />
                </div>
                <div className="resourceInfo">
                  <EditableFormInput
                    label={"Policy Name"}
                    value={state?.policy_name}
                    handleChange={(value) => handleStateChange("policy_name", value)}
                  />
                </div>
                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Purpose: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      editorState={state?.purposeEdit}
                      placeholder="Purpose"
                      onEditorStateChange={(value) => {
                        handleStateChange("purposeEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Applies to: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      editorState={state?.appliesEdit}
                      placeholder="Applies"
                      onEditorStateChange={(value) => {
                        handleStateChange("appliesEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Policy: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      editorState={state?.policyEdit}
                      placeholder="Policy"
                      onEditorStateChange={(value) => {
                        handleStateChange("policyEdit", value);
                      }}
                    />
                  </div>
                </div>

                <div className="resourceInfo flex">
                  <b style={{ minWidth: "35%" }}>Procedures: </b>
                  <div style={{ marginTop: "5px" }}>
                    <Editor
                      toolbar={{
                        options: ["inline", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                      editorStyle={{
                        border: "1px solid #f2f2f2",
                        padding: "10px",
                      }}
                      editorState={state?.proceduresEdit}
                      placeholder="Procedures"
                      onEditorStateChange={(value) => {
                        handleStateChange("proceduresEdit", value);
                      }}
                    />
                  </div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%", marginTop: "5px" }}>Form Attachment: </b>
                  <div className="detailContent" style={{ display: "flex" }}>
                    <div style={{ marginLeft: "10px" }}>
                      <Upload
                        onChange={onChangeUpload}
                        className="barcode-uploader"
                        name="file[]"
                        defaultFileList={files}
                        multiple
                        onPreview={({ url, name }) => downloadFile(url, name)}
                        action={`${BASE_API}policies/${state?.id}/upload_document`}
                      >
                        <button className="common-btn">Upload File</button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%" }}>Approved by: </b>
                  <div style={{ marginLeft: "0.5rem" }}>{state?.approved_by}</div>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "35%", marginTop: "10px" }}>Effective date: </b>
                  <div style={{ marginLeft: "0.5rem" }}>
                    <DatePicker
                      format={"MM/DD/YYYY"}
                      value={state?.date}
                      placeholder="Effective date"
                      suffixIcon={<ExpandSVG />}
                      onChange={(value) => {
                        handleStateChange("date", value);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {!isNew && (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "2rem",
                gap: "0.5rem",
              }}
            >
              <button
                className={!confirmDelete ? "outlined-btn" : ""}
                style={{
                  border: !confirmDelete ? "1px solid #3d70b2" : "1px solid transparent",
                }}
                onClick={deletePolicy}
              >
                {!confirmDelete ? "Delete" : "Confirm Delete?"}
              </button>
              <button className={"outlined-btn"} onClick={resetPolicies}>
                New Policy or Form
              </button>
            </div>
          )}
          {isNew && (
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                paddingBottom: "2rem",
              }}
            >
              <button className={"outlined-btn"} onClick={cancelNewPolicies}>
                Back
              </button>
              <button style={{ marginLeft: "0.5rem" }} onClick={saveNewPolicies}>
                Save
              </button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ManualPoliciesSidebar;

import { Drawer } from 'antd';
import React from 'react';
import ScrollWrapper from '../../../../common/components/ScrollBar';

const ReportingSidebar = React.forwardRef(
    ({resource, isShow, handleCloseSidebar, currentUserInfo}, ref) => {
      if (!currentUserInfo) {
        return null;
      }

      return (
        <Drawer
          title={
            currentUserInfo?.name +
            (currentUserInfo.position &&
              '(' + currentUserInfo.position + ')') || ' '
          }
          placement="right"
          closable
          onClose={handleCloseSidebar}
          visible={isShow}
          destroyOnClose
          key="right"
        >
          <div ref={ref} className="resourceContainer new">
            <ScrollWrapper>
              <div
                className="resourceContent"
                style={{
                  flex: 'unset',
                  paddingLeft: '24px',
                  paddingRight: '20px',
                }}
              >
                <div
                  className="resourceInfo d-flex"
                  style={{marginBottom: '1rem'}}
                >
                  <div>
                  Anyone with knowledge or concerns about the care of a patient
                  may file a complaint. You may use the form below to file a
                  complaint if you are concerned about the health care,
                  treatment, or services that you or another person received or
                  did not receive in the health care facilities.
                    <br />
                    <br />
                  Some reasons for filing a complaint would be abuse, neglect,
                  poor care, not enough staff, unsafe or unsanitary conditions,
                  dietary problems, or mistreatment. Please include as much
                  information as possible when submitting your complaint. The
                  response and timing of any investigation will be based upon
                  the information you provide. Report a concern as soon as
                  possible since it will be easier for you to remember the
                  facts.
                    <br />
                    <br />
                  Step 1: Please include as much information as possible when
                  submitting your complaint. The response and timing of any
                  investigation by the organization will be based upon the
                  information you provide.
                    <br />
                  Report a concern as soon as possible since it will be easier
                  for you to remember the facts and will assist the reviewers in
                  gathering important information.
                    <br />
                    <br />
                  Step 2: Following receipt of your complaint, a reviewer may
                  contact you about your concerns for more information if
                  needed.
                    <br />
                    <br />
                  Step 3: The investigation may include a review of records,
                  interviews with staff, patients or residents, and the
                  observation of patient care.
                    <br />
                    <br />
                  Step 4: At the end of the complaint review, the reviewer may
                  notify you of the results.
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </Drawer>
      );
    },
);

export default ReportingSidebar;

export const VeinLabels = {
  'm-body-v': 'Inferior Vena Cava (IVC) Inferior',
  'l-neck-1-v': 'Left Carotid Vein',
  'r-body-2-v': 'Right Renal Vein',
  'l-body-0-v': 'Left Renal Vein',
  'r-neck-1-v': 'Right Carotid Vein',
  'r-arm-4-v': 'Right Axillary Vein',
  'r-arm-3-v': 'Right Brachial Vein',
  'r-arm-1l-v': 'Right Radial Vein',
  'r-arm-0-v': 'Right Palmar Arch Vein',
  'r-arm-1r-v': 'Right Ulnar Vein',
  'r-arm-2-v': 'Right Ulnar-Radial Vein',
  'l-arm-4-v': 'Left Subclavian Vein',
  'l-arm-3-v': 'Left Brachial Vein',
  'l-arm-1r-v': 'Left Radial Vein',
  'l-arm-0-v': 'Left Palmar Arch Vein',
  'l-arm-1l-v': 'Left Ulnar Vein',
  'l-arm-2-v': 'Left Ulnar-Radial Vein',
  'r-leg-0-v': 'Right External Iliac Vein',
  'r-leg-1-v': 'Right Common Femoral Vein',
  'r-leg-2-v': 'Right Proximal Femoral Vein',
  'r-leg-3-v': 'Right Distal Femoral Vein',
  'r-leg-5-v': 'Right Tibioperoneal Vein',
  'r-leg-4-v': 'Right Popliteal Vein',
  'r-leg-7l-v': 'Right Mid Anterior Tibial Vein',
  'r-leg-8l-v': 'Right Distal Anterior Tibial Vein',
  'r-leg-9-v': 'Right Plantar Arch',
  'r-leg-8r-v': 'Right Distal Posterior Tibial Vein',
  'r-leg-7r-v': 'Right Mid Posterior Tibial Vein',
  'r-leg-6r-v': 'Right Proximal Posterior Tibial Vein',
  'r-leg-o-3-v': 'Right Distal Peroneal Vein',
  'r-leg-o-1-v': 'Right Profunda Femoris Vein',
  'r-leg-o-0-v': 'Right Internal Iliac Vein',
  'r-leg-o-2-v': 'Right Proximal Peroneal Vein',
  'r-leg-6l-v': 'Right Proximal Anterior Tibial Vein',
  'r-neck-0-v': 'Right Common Carotid Vein',
  'l-l-body-v': 'Inferior Vena Cava (ICV) Superior',
  'r-lung-v': 'Right Subclavian Vein',
  'l-lung-v': 'Left Brachiocephalic Vein',
  'r-body-1-v': 'Superior Mesenteric Vein',
  'r-body-0-v': 'Celiac Vein',
  'l-leg-0-v': 'Left External Iliac Vein',
  'l-leg-1-v': 'Left Common Femoral Vein',
  'l-leg-2-v': 'Left Proximal Femoral Vein',
  'l-leg-3-v': 'Left Distal Femoral Vein',
  'l-leg-5-v': 'Left Tibioperoneal Vein',
  'l-leg-4-v': 'Left Popliteal Vein',
  'l-leg-7r-v': 'Left Mid Anterior Tibial Vein',
  'l-leg-8r-v': 'Left Distal Anterior Tibial Vein',
  'l-leg-9-v': 'Left Plantar Vein',
  'l-leg-8l-v': 'Left Distal Posterior Tibial Vein',
  'l-leg-7l-v': 'Left Mid Posterior Tibial Vein',
  'l-leg-6l-v': 'Left Proximal Posterior Tibial Vein',
  'l-leg-o-3-v': 'Left Distal Peroneal Vein',
  'l-leg-o-1-v': 'Left Profunda Femoris Vein',
  'l-leg-o-0-v': 'Left Internal Iliac Vein',
  'l-leg-o-2-v': 'Left Proximal Peroneal Vein',
  'l-leg-6r-v': 'Left Proximal Anterior Tibial Vein',
  'r-leg-v': 'Right Common Iliac Vein',
  'l-leg-v': 'Left Common Iliac Vein',
  'l-neck-0-v': 'Left Common Carotid Vein',
};

export const getVeinLabel = (body) => {
  return VeinLabels[body];
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import { adminSettingSaveOCRInfo } from "../../../../../store/actions";

const DocumentOCRSettings = ({ resource }) => {
  const [ocrEmail, setOCREmail] = useState("");
  const [fax, setFax] = useState("");
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const { loading, ocr_email, ocr_fax_number, ocr_phone_number } = resource;
    if (!loading) {
      setOCREmail(ocr_email);
      setFax(ocr_fax_number === "(" ? "" : ocr_fax_number);
      setPhone(ocr_phone_number === "(" ? "" : ocr_phone_number);
    }
  }, [resource]);

  const handlInputChange = (key, val) => {
    const payload = {
      type: "ocr",
      key: key,
      value: val,
    };
    if (key !== "ocr_email" && val === "(") {
      payload.value = "";
    }
    dispatch(adminSettingSaveOCRInfo(payload));
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="OCR Email"
        value={ocrEmail}
        onCustomBlur={() => handlInputChange("ocr_email", ocrEmail)}
        handleChange={(val) => setOCREmail(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Fax Number"
        value={fax}
        mask={"phone"}
        onCustomBlur={() => handlInputChange("ocr_fax_number", fax)}
        handleChange={(val) => setFax(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Phone Number"
        value={phone}
        mask={"phone"}
        onCustomBlur={() => handlInputChange("ocr_phone_number", phone)}
        handleChange={(val) => setPhone(val)}
      />
    </div>
  );
};

export default DocumentOCRSettings;

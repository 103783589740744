import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getLocations, getUsers, incident_reports_all } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import VariancesSidebar from "./VariancesSidebar";

const Variances = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const dispatch = useDispatch();
  const incidents = useSelector((state) => state.common.incident_reports_all);
  const users = useSelector((state) => state.common.users);
  const locations = useSelector((state) => state.common.locations);
  const tableRows = incidents.length > 0 ? incidents : [];

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index) => {
    setRow(index);
    handleSidebar();
  };

  const fetchList = () => {
    dispatch(incident_reports_all());
    dispatch(getUsers());
    dispatch(getLocations());
  };

  useEffect(() => {
    fetchList();
  }, []);

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="vacation_request"
                labels={["Employee Name", "Variance", "Incident Date / Time", "Facility"]}
                widths={["calc(31% - 2rem)", "23%", "23%", "23%"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "variance" },
                  { sortable: false, key: "incident" },
                  { sortable: false, key: "facility" },
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index)}
                defaultActive={-1}
                rowData={tableRows.map((row, index) => {
                  return {
                    name: (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ minWidth: "26px" }} />
                        <p style={{ paddingLeft: "0.75rem" }}>{row.user && row.user.username}</p>
                      </div>
                    ),
                    variance: row.variance_type,
                    incident: row.created_at,
                    facility: row.location && row.location.city,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {incidents && !!incidents.length && activeIndex >= 0 && (
        <VariancesSidebar
          ref={sidebarRef}
          locations={locations || []}
          users={users && users.active_users}
          dataDetail={incidents && incidents[activeIndex] ? incidents[activeIndex] : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Variances;

import {createActionThunk} from 'redux-thunk-actions';

import AT from '../actionTypes';

import * as API from '../../services/api.services';

export const getPatientSchedules = createActionThunk(
    AT.GET_PATIENT_SCHEDULES,
    ({
      date,
      location,
      provider_id,
      patient_id,
      only_clinics,
      only_procedures,
      only_us,
      only_angio,
    }) =>
      API.getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id,
        only_clinics,
        only_procedures,
        only_us,
        only_angio,
      }),
);

export const searchPatientSchedules = (searchValue) => (dispatch) =>
  dispatch({
    type: AT.SEARCH_PATIENT_SCHEDULES,
    payload: {
      searchValue,
    },
  });

export const set_data_template_review = createActionThunk(
    AT.SET_DATA_TEMPLATE_REVIEW,
    (data) => data,
);

export const remove_selected_patient_resource = createActionThunk(
    AT.REMOVE_SELECTED_PATIENT_RESOURCE,
    () => {},
);

export const set_data_complication = createActionThunk(
    AT.SET_DATA_COMPLICATION,
    (data) => data,
);

export const chooseSelectedPatientResourceByRandom = createActionThunk(
    AT.CHOOSE_SELECTED_PATIENT_RESOURCE_BY_RANDOM,
    () => API.getPatients(''),
);

export const chooseSelectedPatientResource = createActionThunk(
    AT.CHOOSE_SELECTED_PATIENT_RESOURCE,
    (id) => API.getPatient({id}),
);

export const getPatientNotes = createActionThunk(
    AT.GET_PATIENT_NOTES,
    (patientId) => API.getPatientNotes({patientId}),
);

export const addPatientNote = (patient_id, notes) => (dispatch) =>
  dispatch({
    type: AT.ADD_PATIENT_NOTE,
    payload: {
      patient_id,
      notes,
    },
  });

export const deletePatientNote = (rowIndex) => (dispatch) =>
  dispatch({
    type: AT.DELETE_PATIENT_NOTE,
    payload: {
      rowIndex,
    },
  });

export const savePatientNotes = createActionThunk(
    AT.SAVE_PATIENT_NOTES,
    (patientId, allNotes, allNotesDeleted) =>
      API.savePatientNotes({patientId, allNotes, allNotesDeleted}),
);

export const patientScheduleVerifyInsurance = createActionThunk(
    AT.PATIENT_SCHEDULE_VERIFY_INSURANCE,
    (id, params) => API.verifyInsuranceDetail(id, params),
);

export const patientScheduleConfirmCheckin = createActionThunk(
    AT.PATIENT_SCHEDULE_CONFIRM_CHECKIN,
    (patient_id) => API.patientScheduleConfirmCheckin(patient_id),
);
/*
export const getPatientNotes = createActonThunk(
  AT.GET_PATIENT_NOTES,
  ({ patient_id }) => API.getPatientNotes(patient_id)
)
*/

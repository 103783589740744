import React from 'react';
import {YellowAlertSVG} from '../../components/icons/SVGIcon';

export const StaticResource = {
  id: 1,
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />,
};

export const TabHeaders = [
  {
    label: 'Clinic Schedule',
    width: '100px',
  },
  {
    label: 'Patient Snapshot',
    width: '112px',
  },
  {
    label: 'Patient Chart',
    width: '86.1px',
  },
  {
    label: 'HPI',
    width: '25px',
  },
  {
    label: 'ROS / Vitals',
    width: '77.4px',
  },
  {
    label: 'Physical Examination',
    width: '139px',
  },
  {
    label: 'Plan/Orders',
    width: '79.1px',
  },
  {
    label: 'Review',
    width: '47.2px',
  },
  {
    label: 'Appointments',
    width: 'auto',
  },
];

const RedSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(224, 24, 45)',
        border: '2px solid rgb(224, 24, 45)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};

const YellowSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(239, 193, 0)',
        border: '2px solid rgb(239, 193, 0)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};

export const SnapData = [
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={1} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={2} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={3} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Right Carotid Endarterectomy',
    actionDate: '02/05/2019',
    status: <YellowSnap info={4} />,
    description: [
      'Right Carotid Endarterectomy with bovine pericardial patch placement with Dr Omar Haqqani, MD at Bay McLaren Hospital',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={5} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
];

export const LaboratoryLabels = [
  'Na',
  'K',
  'Cl',
  'HCO3',
  'BUN',
  'Cr',
  'Glucose',
  'Ca',
  'Albumin',
  'Tl Protein',
  'ALP',
  'ALT',
  'AST',
  'Bilirubin',
  'WBC',
  'Hb',
  'Hct',
  'Plt',
  '% Lymph',
  '% Mono',
  '% PMN',
  'Cholesterol',
  'HDL',
  'LDL',
  'Triglyceride',
  'COL/EPI CT',
  'COL/ADP CT',
];

export const DataTableRows = {
  row1: [
    {
      item: 'Na',
      amount: '141 mmol/L',
    },
    {
      item: 'K',
      amount: '4.1 mEq/L',
    },
    {item: '', amount: ''},
    {item: '', amount: ''},
  ],
  row2: [
    {
      item: 'WBC',
      amount: '7.1 mmol/L',
    },
    {
      item: 'HCT',
      amount: '33 mEq/L',
    },
    {item: '', amount: ''},
    {item: '', amount: ''},
  ],
  row3: [
    {
      item: 'Total Cholesterol',
      amount: '230 mmol/L',
    },
    {
      item: 'HDL',
      amount: '33 mEq/L',
    },
    {
      item: 'LDL',
      amount: '33 mEq/L',
    },
    {item: '', amount: ''},
  ],
};

export const ChartTableList = [
  {
    date: '02/03/2017',
    link: '#',
    title: 'New Patient Referral',
    athor: 'Haqqani',
  },
  {
    date: '06/12/2018',
    link: '#',
    title: 'Insurance Documentation',
    athor: '',
  },
  {
    date: '09/02/2018',
    link: '#',
    title: 'LLE Angiogram with Stenting',
    athor: 'Aized',
  },
  {date: '05/09/2019', link: '#', title: 'F/U Appointment', athor: 'Haqqani'},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
];

export const ReviewTableList = [
  {label: 'Rx Atorvastatin 40 mg po qhs', value: 'E-Prescribed'},
  {label: 'Right Carotid Endarterectomy at Bay McLaren', value: 'Faxed'},
  {label: 'Clinic Followup 2/4/2020 11:00 AM', value: 'Scheduled'},
  {label: 'Referral to Rheumatology Henry Ford', value: 'Faxed'},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
];

export const abbreviations = [
  ' us ',
  ' cta ',
  ' cda ',
  ' abi ',
  ' le ',
  ' rfa ',
  ' dvt ',
  ' dl ',
  ' iv ',
  ' nit ',
  ' ecg ',
  ' ivus ',
  ' echo ',
  ' hippa ',
  ' obl ',
  ' (obl) ',
  ' ct ',
];

export const sortCategories = [
  {category: 'Laboratory', value: 'Laboratory'},
  {category: 'Referral Pack', value: 'referral_pack'},
  {category: 'Hospital Records', value: 'hospital_records'},
  {category: 'Operative Reports', value: 'operative_reports'},
  {category: 'Consultation Notes', value: 'consultation_notes'},
  {category: 'Clinical Data', value: 'clinical_data'},
  {category: 'Imaging Reports', value: 'imaging_reports'},
  {category: 'HIPAA Form', value: 'hipaa_form'},
  {category: 'Image Upload', value: 'Image Upload'},
  {category: 'Note Upload', value: 'Note Upload'},
];

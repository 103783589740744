import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import { getLocations, getOnlyProviders, getPatientSchedules } from "../../../store/actions";
import PriorFilters from "./PriorFilters";
import ResultTable from "./PriorResultTable";

const QuickRegisterC = () => {
  const selectedPatientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(false);
  const [filters, setFilters] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    location: "",
    provider_id: "",
  });
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEvents = ({ date, location, provider_id }) => {
    setFilters({
      ...filters,
      date,
      location,
      provider_id,
    });
    dispatch(getPatientSchedules({ date, location, provider_id, patient_id: "" }));
  };

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getOnlyProviders());
    loadInitialPaymentJs();
    handleEvents(filters);
  }, [filters]);

  const loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const refreshCalendar = () => {
    const { date, location, provider_id } = filters;
    dispatch(getPatientSchedules({ date, location, provider_id, patient_id: "" }));
  };

  const handleSearch = (search) => {
    setSearch(search);
  };

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Subsidiary Module" isBarcode={true} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer">
              <PriorFilters filter={filter} handleChangeFilter={handleFilter} handleSearch={handleSearch} />
              <div className="tableSection">
                <ResultTable title="result" filter={filter} search={search} handleSidebar={handleSidebar} />
              </div>
            </div>
          </div>
          <ClinicWorkflowSidebar
            ref={sidebarRef}
            resource={selectedPatientResource}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
            paymentForm={window.SqPaymentForm}
            refreshCalendar={refreshCalendar}
            hideMedication={true}
          />
        </div>
      </div>
    </div>
  );
};

export default QuickRegisterC;

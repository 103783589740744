const renderFixStrong = (data) => {
  // make !!strong!!
  let dataMix = data && JSON.parse(data) ? { ...JSON.parse(data) } : null;
  // lấy giá trị trong strong
  if (dataMix?.blocks && dataMix?.blocks.length > 0 && Array.isArray(dataMix?.blocks)) {
    // get value into strong regex
    const pattern = /!!strong!!(.*?)!!strong!!/g;
    dataMix["blocks"] = (dataMix?.blocks || []).map((r, index) => {
      const indicesAndLengths = [];
      let match;
      while ((match = pattern.exec(r?.text)) !== null) {
        indicesAndLengths.push({
          offset: match.index,
          length: (match[1] && match[1].length) || 0,
          style: "BOLD",
        });
      }
      if (r?.text.includes("!!strong!!")) {
        return {
          ...r,
          text: r?.text.replace(/!!strong!!/g, "").trim(),
          inlineStyleRanges: indicesAndLengths,
        };
      } else {
        return {
          ...r,
          text: (r?.text && r.text.trim()) || "",
        };
      }
    });
    // xóa khoảng trống nếu bị quá nhiều
    let emptyFlag = false;
    let result = [];
    for (let i = 0; i < dataMix["blocks"].length; i++) {
      if (dataMix["blocks"][i]["text"] === "" || dataMix["blocks"][i]["text"].replaceAll(" ", "") === "") {
        if (!emptyFlag) {
          result.push(dataMix["blocks"][i]);
          emptyFlag = true;
        }
      } else {
        result.push(dataMix["blocks"][i]);
        emptyFlag = false;
      }
    }
    if (result && result.length > 0) {
      dataMix["blocks"] = result;
    }
  }
  return dataMix;
};

export default renderFixStrong;

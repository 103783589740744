/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Icon, Input, Select } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import {
  check_patient_insurances,
  choosePatientOnSchedules,
  delete_patient_insurances,
  edit_patient_insurances,
  insurance_types as getInsuranceTypes,
  patient_insurances_id_insurances,
  reset_check_patient,
  save_patient_insurances,
  search_insurance_companies,
  setDataPatient,
  setEnterInsuranceCheck,
  setGlobalLoading,
  setNewLoading,
} from "../../../store/actions";
import { ExpandSVG } from "../../components/icons/SVGIcon";
import Loading from "../../components/Loading";
import PusherScan from "../../components/PusherScan";
import ExtendDataTable from "../../components/SubDataTable/ExtendDataTable";
import ToastMessage from "../../components/Toast";

const { Option } = Select;

const dateMask = "99/99/9999";

let timer = null;

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const CODEOPTIONS = [
  {
    label: "01 Spouse",
    value: "spouse",
  },
  {
    label: "18 Self",
    value: "self",
  },
  {
    label: "19 Child",
    value: "child",
  },
  {
    label: "20 Employee/Attorney",
    value: "employee",
  },
  {
    label: "21 Unknown",
    value: "unknown",
  },
  {
    label: "53 Life Partner",
    value: "partner",
  },
  {
    label: "G8 Other Relationship",
    value: "other",
  },
];
const InsuranceDetail = (props) => {
  const resource = useSelector((state) => state.patientSnapshot.resource);
  const idCardInfo = useSelector((state) => state.scanDriverLicense?.idCardInfo);
  const [qrData, setQRData] = useState();
  const dispatch = useDispatch();
  // Scan
  const [clickedScanID, setClickedScanID] = useState(false);
  const patient_id = getLocal("current_patient_id");
  const [insurances, setInsurances] = useState([]);
  const [active, setActive] = useState(-1);
  //
  const search_insurance_companies_value = useSelector((state) => state.newPatient.search_insurance_companies);
  const loading_patient_insurances = useSelector((state) => state.newPatient.loading_patient_insurances);
  const insurance_types = useSelector((state) => state.newPatient.insurance_types);
  const loading_search_insurance_companies = useSelector((state) => state.newPatient.loading_search_insurance_companies);
  const patient_all_insurances_id = useSelector((state) => state.newPatient.patient_all_insurances_id);
  const loading = useSelector((state) => state.newPatient.isVerifyInsurance);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isDelete, setIsDelete] = useState(-1);
  const [isGroup, setIsGroup] = useState(false);
  const [isSelfPay, setIsSelfPay] = useState(false);
  const check_patient = useSelector((state) => state.newPatient.check_patient_insurances);

  const [insurancesType, setInsurancesType] = useState([]);
  const [tableColumnWidths, setTableColumnWidths] = useState(["25%", "25%", "25%", "25%"]);
  const [selectedColumn, setSelectedColumn] = useState(-1);

  useEffect(() => {
    setInsurancesType(insurance_types);
  }, [insurance_types]);

  useEffect(() => {
    getWidths();
  }, [selectedColumn]);

  const getWidths = () => {
    let columnWidths = [];
    if (isGroup) {
      if (selectedColumn === -1 || selectedColumn === 2 || selectedColumn === 3) {
        columnWidths = ["20%", "20%", "20%", "20%", "20%"];
      } else if (selectedColumn === 0) {
        columnWidths = ["40%", "15%", "15%", "15%", "15%"];
      } else if (selectedColumn === 1) {
        columnWidths = ["32%", "17%", "17%", "17%", "17%"];
      } else if (selectedColumn === 4) {
        columnWidths = ["16%", "16%", "16%", "16%", "36%"];
      } else {
        columnWidths = ["20%", "20%", "20%", "20%", "20%"];
      }
    } else {
      if (selectedColumn === -1 || selectedColumn === 2) {
        columnWidths = ["25%", "25%", "25%", "25%"];
      } else if (selectedColumn === 0) {
        columnWidths = ["40%", "20%", "20%", "20%"];
      } else if (selectedColumn === 1) {
        columnWidths = ["31%", "23%", "23%", "23%"];
      } else if (selectedColumn === 3) {
        columnWidths = ["21%", "21%", "21%", "37%"];
      } else {
        columnWidths = ["25%", "25%", "25%", "25%"];
      }
    }

    setTableColumnWidths(columnWidths);
  };

  const [stateFocus, setStateFocus] = useState(false);

  useEffect(() => {
    dispatch(getInsuranceTypes());
  }, []);

  useEffect(() => {
    if (loading || loadingLocal || loading_search_insurance_companies || loading_patient_insurances) {
      dispatch(setNewLoading(true));
    } else {
      dispatch(setNewLoading(false));
    }
  }, [loading, loadingLocal, loading_search_insurance_companies, loading_patient_insurances]);

  const handleAddNew = () => {
    const newInsurance = {
      key: Date.now(),
      id: null,
      insurance_id: "",
      policy_no: "",
      group_no: "",
      insurance_name: "",
      type: "",
      code: "",
      moreInfo: {},
      posted: false,
      is_verified: false,
      is_eligible: false,
      skip_checking: false,
    };
    setInsurances([...insurances, newInsurance]);
  };

  const removeVaraible = async (index) => {
    const arrayCheck = [...insurances];
    const activeArray = arrayCheck[index];
    if (activeArray && activeArray["id"] && activeArray["posted"]) {
      const deleteSuccess = await dispatch(delete_patient_insurances(activeArray["id"]));
      if (deleteSuccess) {
        await dispatch(patient_insurances_id_insurances(patient_id));
        setActive(-1);
        setIsDelete(-1);
        setSelectedColumn(-1);
      }
    } else {
      const vars = [...insurances];
      vars.splice(index, 1);
      setInsurances(vars);
      setIsDelete(-1);
      setActive(-1);
      setSelectedColumn(-1);
    }
  };

  useEffect(() => {
    if (isDelete >= 0) {
      removeVaraible(isDelete);
    }
  }, [isDelete]);

  useEffect(() => {
    const arrayCheck = (patient_all_insurances_id || []).map((item, index) => {
      return {
        key: Date.now(),
        posted: true,
        id: item?.id,
        insurance_name: `${item?.insurance_company?.name}`,
        insurance_id: item?.insurance_company_id,
        policy_no: item?.policy_number,
        group_no: item?.group_number,
        code: item?.code,
        type: item?.insurance_type_id,
        is_verified: item?.is_verified,
        is_eligible: item?.is_eligible,
        skip_checking: item?.skip_checking,
        moreInfo: item?.json ? JSON.parse(item?.json) : {},
      };
    });
    setInsurances([...arrayCheck]);
  }, [patient_all_insurances_id]);

  const handleChangeInsurance = (type, value, index) => {
    const vars = [...insurances];
    if (type == "firstName" || type == "lastName" || type == "DOB") {
      vars[index]["moreInfo"][type] = value;
      setInsurances(vars);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        apiPostEdit(vars);
      }, 500);
    } else if (type == "insurance") {
      const vars = [...insurances];
      vars[index]["insurance_id"] = value[0];
      vars[index]["insurance_name"] = `${value[1]}`;
      setInsurances(vars);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        apiPostEdit(vars);
      }, 500);
    } else {
      vars[index][type] = value;
      if (type == "code") {
        let moreInfo;
        let post;
        if (value == "self") {
          moreInfo = {};
          post = false;
        } else {
          moreInfo = {
            firstName: "",
            lastName: "",
            DOB: "",
          };
          post = true;
        }
        vars[index]["post"] = post;
        vars[index]["moreInfo"] = moreInfo;
      }
      setInsurances(vars);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        apiPostEdit(vars);
      }, 500);
    }
  };

  const findMedications = (medication) => {
    if (!medication) {
      return false;
    }
    if (medication.indexOf("BLUE") !== -1) {
      return true;
    }
    return false;
  };

  const findSelfpay = (insurance_id) => {
    if (!insurance_id) {
      return false;
    }
    if (insurance_id === 1361) {
      return true;
    }
    return false;
  };

  // ID card display disable => panamacheng modified.
  // const [disableCard, setDisableCard] = useState(false);
  const disableIDImage = false; // remove this row after implementing the parent function for props

  const onClickedScanID = () => {
    const inputSignData = {
      type: "input-sign",
      signature_data: {
        patient_id: resource?.id,
        card_type: "insurance_card",
      },
    };
    setQRData(inputSignData);
    setClickedScanID(true);
  };

  useEffect(() => {
    if (idCardInfo?.card) {
      setClickedScanID(false);
    } else {
      onClickedScanID();
    }
  }, [idCardInfo, resource]);

  const handleScanQRCode = () => {
    setClickedScanID(false);
  };

  const searchInsurance = (value) => {
    clearTimeout(timer);
    setLoadingLocal(true);
    timer = setTimeout(() => {
      setLoadingLocal(false);
      dispatch(search_insurance_companies(value));
    }, 500);
  };

  useEffect(() => {
    dispatch(search_insurance_companies(""));
  }, []);

  const apiPostEdit = async (varsInsurance) => {
    const arrayCheck = [...varsInsurance];
    const activeArray = arrayCheck[active] ? arrayCheck[active] : null;
    if (activeArray && activeArray["insurance_id"] == 1361) {
      const saveSuccess = await dispatch(
        save_patient_insurances({
          patient_id: patient_id,
          insurance_company_id: activeArray["insurance_id"],
          policy_number: activeArray["policy_no"],
          group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
          insurance_type_id: activeArray["type"],
          code: activeArray["code"],
          json: Object.keys(activeArray["moreInfo"]).length > 0 ? JSON.stringify(activeArray["moreInfo"]) : null,
        })
      );
      if (saveSuccess) {
        setIsSave(true);
        setTimeout(() => {
          setIsSave(false);
        }, 2000);
        await dispatch(patient_insurances_id_insurances(patient_id));
        await dispatch(setEnterInsuranceCheck());
        await dispatch(choosePatientOnSchedules(patient_id));
      }
    } else {
      if (
        activeArray &&
        activeArray["posted"] === false &&
        activeArray["insurance_id"] &&
        activeArray["type"] &&
        activeArray["policy_no"] &&
        activeArray["code"]
      ) {
        if (activeArray["code"] !== "self") {
          if (
            activeArray["moreInfo"] &&
            activeArray["moreInfo"]["DOB"] &&
            activeArray["moreInfo"]["firstName"] &&
            activeArray["moreInfo"]["lastName"] &&
            moment(activeArray["moreInfo"]["DOB"]).isValid()
          ) {
            const saveSuccess = await dispatch(
              save_patient_insurances({
                patient_id: patient_id,
                insurance_company_id: activeArray["insurance_id"],
                policy_number: activeArray["policy_no"],
                group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
                insurance_type_id: activeArray["type"],
                code: activeArray["code"],
                json: Object.keys(activeArray["moreInfo"]).length > 0 ? JSON.stringify(activeArray["moreInfo"]) : null,
              })
            );

            if (saveSuccess) {
              setIsSave(true);
              setTimeout(() => {
                setIsSave(false);
              }, 2000);
              await dispatch(patient_insurances_id_insurances(patient_id));
              await dispatch(setEnterInsuranceCheck());
              await dispatch(choosePatientOnSchedules(patient_id));
            }
          }
        } else {
          const saveSuccess = await dispatch(
            save_patient_insurances({
              patient_id: patient_id,
              insurance_company_id: activeArray["insurance_id"],
              policy_number: activeArray["policy_no"],
              group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
              insurance_type_id: activeArray["type"],
              code: activeArray["code"],
              json: Object.keys(activeArray["moreInfo"]).length > 0 ? JSON.stringify(activeArray["moreInfo"]) : null,
            })
          );
          if (saveSuccess) {
            setIsSave(true);
            setTimeout(() => {
              setIsSave(false);
            }, 2000);
            await dispatch(patient_insurances_id_insurances(patient_id));
            await dispatch(setEnterInsuranceCheck());
            await dispatch(choosePatientOnSchedules(patient_id));
          }
        }
      } else if (
        activeArray &&
        activeArray["posted"] === true &&
        activeArray["insurance_id"] &&
        activeArray["type"] &&
        activeArray["id"] &&
        activeArray["policy_no"] &&
        activeArray["code"]
      ) {
        if (activeArray["code"] !== "self") {
          if (
            activeArray["moreInfo"] &&
            Object.keys(activeArray["moreInfo"]).length > 0 &&
            activeArray["moreInfo"]["DOB"] &&
            activeArray["moreInfo"]["firstName"] &&
            activeArray["moreInfo"]["lastName"] &&
            moment(activeArray["moreInfo"]["DOB"]).isValid()
          ) {
            const updateSuccess = await dispatch(
              edit_patient_insurances(activeArray["id"], {
                patient_id: patient_id,
                insurance_company_id: activeArray["insurance_id"],
                policy_number: activeArray["policy_no"],
                group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
                insurance_type_id: activeArray["type"],
                code: activeArray["code"],
                json: Object.keys(activeArray["moreInfo"]).length > 0 ? JSON.stringify(activeArray["moreInfo"]) : null,
              })
            );
            if (updateSuccess) {
              setIsEdit(true);
              setTimeout(() => {
                setIsEdit(false);
              }, 2000);
              await dispatch(patient_insurances_id_insurances(patient_id));
              await dispatch(choosePatientOnSchedules(patient_id));
            }
          }
        } else {
          const updateSuccess = await dispatch(
            edit_patient_insurances(activeArray["id"], {
              patient_id: patient_id,
              insurance_company_id: activeArray["insurance_id"],
              policy_number: activeArray["policy_no"],
              group_number: activeArray["group_no"] ? activeArray["group_no"] : "",
              insurance_type_id: activeArray["type"],
              code: activeArray["code"],
              json: Object.keys(activeArray["moreInfo"]).length > 0 ? JSON.stringify(activeArray["moreInfo"]) : null,
            })
          );
          if (updateSuccess) {
            setIsEdit(true);
            setTimeout(() => {
              setIsEdit(false);
            }, 2000);
            await dispatch(patient_insurances_id_insurances(patient_id));
            await dispatch(choosePatientOnSchedules(patient_id));
          }
        }
      }
    }
  };

  const handleClickVerify = useCallback(async (insurance) => {
    dispatch(setNewLoading(true));
    await dispatch(check_patient_insurances(insurance["id"]));
    await dispatch(patient_insurances_id_insurances(resource?.id));
    const result = await dispatch(choosePatientOnSchedules(resource?.id));
    if (result) {
      dispatch(setDataPatient(result));
    }
    setTimeout(() => {
      dispatch(setNewLoading(false));
    }, 1000);
  }, []);

  useEffect(() => {
    if (check_patient && Object.keys(check_patient).length > 0) {
      setTimeout(() => {
        dispatch(reset_check_patient());
      }, 2000);
    }
  }, [check_patient]);

  useEffect(() => {
    const checkArray = insurances?.map((el) => findMedications(el?.insurance_name));
    const checkSelfPay = insurances?.map((el) => findSelfpay(el?.insurance_id));
    const selfPayExist = checkSelfPay?.some((el) => el);
    if (selfPayExist) {
      setIsSelfPay(true);
    } else {
      setIsSelfPay(false);
    }
    const groupExist = checkArray?.some((el) => el);
    if (groupExist) {
      setIsGroup(true);
    } else {
      setIsGroup(false);
    }
  }, [insurances]);

  const getOptions = () => {
    if (isGroup) {
      if (isSelfPay) {
        return ["insurance_id", "type"];
      } else {
        return ["insurance_id", "policy_no", "group_no", "type", "code"];
      }
    } else {
      if (isSelfPay) {
        return ["insurance_id", "type"];
      } else {
        return ["insurance_id", "policy_no", "type", "code"];
      }
    }
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative", marginTop: "-8px" }}>
      {check_patient && Object.keys(check_patient).length > 0 && check_patient.status && (
        <ToastMessage type="Insurance Details" status="success" msg={check_patient?.message} />
      )}
      {isEdit && <ToastMessage type="Insurance Details" status="success" msg={"Insurance Is Edited"} />}
      {isSave && <ToastMessage type="Insurance Details" status="success" msg={"Insurance Is Saved"} />}
      <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end" }}>
        <button className="text-btn" style={{ marginLeft: "1rem" }} onClick={handleAddNew}>
          Add +
        </button>
      </div>

      <div
        className="resourceInfo d-flex"
        style={{
          height: "calc(40 * 3px + 48px + 10px)",
        }}
      >
        <ExtendDataTable
          onClickRow={(index) => {
            if (!loading_patient_insurances) {
              setActive(index);
            }
          }}
          onClickColumn={(index) => setSelectedColumn(index)}
          isActiveBorder={true}
          isFixed={true}
          title={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[0],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                  borderLeft: 0,
                }}
              >
                Insurance Name
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[1],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  paddingRight: "4px",
                }}
              >
                Policy No
              </div>
              {isGroup && (
                <div
                  style={{
                    ...titleStyle,
                    width: tableColumnWidths[2],
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    paddingRight: "4px",
                  }}
                >
                  Group No
                </div>
              )}
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[isGroup ? 3 : 2],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Type
              </div>
              <div
                style={{
                  ...titleStyle,
                  width: tableColumnWidths[isGroup ? 4 : 3],
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                Code
              </div>
            </div>
          }
          data={insurances.map((variable, index) => ({
            key: variable?.key,
            row_color_status:
              variable?.skip_checking === 1
                ? "yellow"
                : variable?.is_verified && variable?.is_verified === 1 && variable?.is_eligible && variable?.is_eligible === 1
                ? "green"
                : variable?.is_verified === 1 && variable?.is_eligible === 0
                ? "pink"
                : "",
            insurance_id: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    paddingLeft: "0",
                    paddingRight: 0,
                    flex: 1,
                    width: "calc(100%)",
                  }}
                >
                  <Select
                    placeholder={"Insurance..."}
                    showSearch
                    optionFilterProp="children"
                    className={!variable?.insurance_name ? "select-error" : ""}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={!stateFocus ? variable?.insurance_name : [variable?.insurance_name, variable?.insurance_id]}
                    onFocus={() => {
                      setStateFocus(true);
                    }}
                    onBlur={() => {
                      setStateFocus(false);
                    }}
                    filterOption={(inp, opt) => opt.props.children.toLowerCase().indexOf(inp.toLowerCase()) >= 0}
                    notFoundContent={
                      loading_search_insurance_companies ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    onSearch={(value) => {
                      searchInsurance(value);
                    }}
                    onChange={(value) => {
                      handleChangeInsurance("insurance", value, index);
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    {(search_insurance_companies_value || []).map((item, index) => (
                      <Option key={item?.id} value={stateFocus ? [item?.id, item?.name] : item?.id}>
                        {`${item?.name}`}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            ),
            policy_no: (
              <div style={{ width: "100%", paddingLeft: "0", paddingRight: 0 }}>
                <Input
                  className={!variable?.policy_no ? "input-error" : ""}
                  placeholder="Policy No"
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable?.policy_no}
                  onChange={(e) => handleChangeInsurance("policy_no", e.target?.value, index)}
                />
              </div>
            ),
            group_no: findMedications(variable["insurance_name"]) ? (
              <div style={{ width: "100%", paddingLeft: "0", paddingRight: 0 }}>
                <Input
                  placeholder="Group No"
                  className={!variable?.group_no ? "input-error" : ""}
                  style={{ width: "100%", color: "#152935", boxShadow: "none" }}
                  value={variable?.group_no}
                  onChange={(e) => handleChangeInsurance("group_no", e.target?.value, index)}
                />
              </div>
            ) : (
              ""
            ),
            type: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  paddingLeft: "0",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Select
                    placeholder={"Type..."}
                    className={!variable?.type ? "select-error" : ""}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={variable?.type || undefined}
                    onChange={(value) => handleChangeInsurance("type", value, index)}
                    style={{ width: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {(insurancesType || []).map((item, index) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {isSelfPay ? (
                  <div className="remove-icon" onClick={() => setIsDelete(index)} style={{ marginLeft: 5 }}>
                    <Icon type="delete" />
                  </div>
                ) : null}
              </div>
            ),
            code: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  height: "100%",
                  paddingRight: "8px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    width: "calc(100% - 30px)",
                    paddingRight: "8px",
                  }}
                >
                  <Select
                    placeholder={"Code"}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    value={variable?.code || undefined}
                    className={!variable?.code ? "input-error" : ""}
                    onChange={(value) => handleChangeInsurance("code", value, index)}
                    style={{ width: "100%", borderBottom: "1px solid #fff" }}
                  >
                    {CODEOPTIONS.map(({ label, value }, index) => (
                      <Option key={`code-${index}`} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </div>
                {variable?.insurance_id !== 1361 &&
                variable?.insurance_id !== 1301 &&
                variable?.insurance_id !== 1279 &&
                variable?.insurance_id !== 254 &&
                variable?.insurance_id !== 1163 ? (
                  <div
                    onClick={() => handleClickVerify(variable)}
                    style={{
                      marginRight: 5,
                    }}
                  >
                    <Icon
                      type="check"
                      style={{
                        color: "#0f62fe",
                      }}
                    />
                  </div>
                ) : null}
                <div className="remove-icon" onClick={() => setIsDelete(index)}>
                  <Icon type="delete" />
                </div>
              </div>
            ),
            extraData: Object.keys(variable?.moreInfo).length > 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  bottom: 3,
                  paddingTop: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[0],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(0);
                    }}
                  >
                    <Input
                      className={!variable?.moreInfo?.firstName ? "input-error" : ""}
                      placeholder="First Name"
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                      }}
                      value={variable?.moreInfo?.firstName}
                      onChange={(e) => handleChangeInsurance("firstName", e.target?.value, index)}
                    />
                  </span>
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[1],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(1);
                    }}
                  >
                    <Input
                      className={!variable?.moreInfo?.lastName ? "input-error" : ""}
                      placeholder="Last Name"
                      style={{
                        width: "100%",
                        color: "#152935",
                        boxShadow: "none",
                      }}
                      value={variable?.moreInfo?.lastName}
                      onChange={(e) => handleChangeInsurance("lastName", e.target?.value, index)}
                    />
                  </span>
                  <span
                    style={{
                      width: "100%",
                      maxWidth: tableColumnWidths[2],
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingLeft: "0.75rem",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedColumn(2);
                    }}
                  >
                    <InputMask
                      mask={dateMask}
                      value={variable?.moreInfo?.DOB || ""}
                      maskPlaceholder={null}
                      onChange={(e) => {
                        handleChangeInsurance("DOB", e.target?.value, index);
                      }}
                    >
                      <Input
                        className={!variable?.moreInfo?.DOB ? "input-error" : ""}
                        placeholder="DOB"
                        style={{
                          width: "100%",
                          color: "#152935",
                          boxShadow: "none",
                        }}
                        value={variable?.moreInfo?.DOB || ""}
                      />
                    </InputMask>
                  </span>
                </div>
              </div>
            ),
          }))}
          options={getOptions()}
          widths={tableColumnWidths}
          minRows={3}
        />
      </div>

      {!disableIDImage && ( // replace with "disableCard" after implemented the props function.
        <React.Fragment>
          <hr />
          <PusherScan
            signType="input-sign" // define component type.
            scanData={resource} // define initial data.
            cardType="insurance_card"
            handleScanQRCode={handleScanQRCode}
            isQRcode={clickedScanID}
            qrData={qrData}
          />
          <hr />
        </React.Fragment>
      )}
    </div>
  );
};

export default InsuranceDetail;

import React from 'react';
import { MaleSVG } from '../../../../common/components/icons/FaxSVG';
import { CloseIcon } from '../../../../common/components/icons/SVGIcon';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import SidebarTitle from '../../../../common/components/SidebarTitle';

const CMESidebar = React.forwardRef(
    (
        {
          resource,
          isShow,
          evaluationsTagertDetail,
          handleCloseSidebar,
          currentUserInfo,
        },
        ref,
    ) => {
      if (!isShow || !currentUserInfo || !evaluationsTagertDetail) {
        return null;
      }

      return (
        <div
          ref={ref}
          className={`resourceContainer has-sub-header ${isShow ? 'show' : ''}`}
        >
          <ScrollWrapper css="x-hidden">
            <div className="headerContainer">
              <button onClick={handleCloseSidebar}>
                <CloseIcon />
              </button>
            </div>
            <SidebarTitle
              icon={<MaleSVG />}
              title={
                <span className="text-uppercase">
                  {currentUserInfo?.name +
                  (currentUserInfo.position &&
                    '(' + currentUserInfo.position + ')')}
                </span>
              }
              subTitle=""
            />

            <div
              className="resourceContent"
              style={{
                flex: 'unset',
                paddingLeft: '24px',
                paddingRight: '20px',
                paddingBottom: '2rem',
              }}
            >
              <div
                className="resourceInfo d-flex"
                style={{marginBottom: '1rem'}}
              >
                <div>
                Employee evaluations are a means to monitor progress and methods
                of evaluations within the organization. Employee evaluations are
                utilized for promotion and assessment of raises or bonuses.
                Improvements in employment metrics are a key determinent of
                raises and bonuses and will be directly utilized on a
                calculation to determine end of year increases. Raises and
                bonuses are not guaranteed. Performance improvement and
                evaluations are reset at the beginning of the year with respect
                to raises and bonuses.
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>Summary</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.summary}
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>Facts</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.facts}
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>Corrective Actions</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.corrective_actions}
              </div>

              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>CME Date</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.created_at}
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>CME Title</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.variance_type}
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>CME Hours</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.points}
              </div>
              <div className="resourceInfo d-flex">
                <strong style={{minWidth: '35%'}}>Uploaded Document</strong>
              </div>
              <div className="resourceInfo d-flex">
                {evaluationsTagertDetail.corective_actions}
              </div>
            </div>
          </ScrollWrapper>
        </div>
      );
    },
);

export default CMESidebar;

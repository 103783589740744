const ReplaceN = (value, isReview) => {
  if (value && value.length > 0) {
    let mixFix = value;
    if (JSON.stringify(value).includes(`!!new!!`) || value.includes("!!new!!")) {
      value.split(`!!new!!`).map((r, index) => {
        mixFix = mixFix.replace(`!!new!!`, "\n");
      });
    }
    if (JSON.stringify(value).includes(`!!UL!!`) || value.includes("!!UL!!")) {
      value.split(`!!UL!!`).map((r, index) => {
        mixFix = mixFix.replace(`!!UL!!`, "•");
      });
    }
    return mixFix;
  }
  return null;
};

export default ReplaceN;

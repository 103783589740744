import React, {useState, useEffect} from 'react';
import moment from 'moment';

const ScheduleTimer = () => {
  const [time, setTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));

  useEffect(() => {
    const getTime = () => {
      setTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    };

    const timer = setInterval(getTime, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{display: 'inline-block', width: '500px', textAlign: 'right'}}
    >
      <p style={{margin: 0, fontSize: '1rem', fontWeight: '600'}}>{time}</p>
    </div>
  );
};

export default ScheduleTimer;

import { Tabs } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PDFView from "../../../common/../common/components/PDFView";
import TopBar from "../../../common/components/SubTopBar";
import { emptyFaxLogs, setGlobalLoading, updateFaxLog } from "../../../store/actions";
import { StaticResource } from "../constant";
import FaxingSidebar from "./FaxingSidebar";

const { TabPane } = Tabs;

export const FaxingModule = (props) => {
  const detail_fax_logs = useSelector((state) => state.patientRecords.detail_fax_logs);
  const loadingDetailLogs = useSelector((state) => state.patientRecords.loadingDetailLogs);
  const loadingFaxLogs = useSelector((state) => state.patientRecords.loadingFaxLogs);

  const [isSidebar, setIsSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState("incoming-fax");
  const [changedTab, setChangedTab] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
    return () => {
      dispatch(emptyFaxLogs());
    };
  }, []);

  useEffect(() => {
    if (loadingDetailLogs) {
      setChangedTab(false);
    }
  }, [loadingDetailLogs]);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingDetailLogs || loadingFaxLogs));
  }, [loadingDetailLogs, loadingFaxLogs]);

  const enablePDFViewer = useMemo(() => {
    if (loadingDetailLogs || (!loadingDetailLogs && changedTab)) {
      return false;
    }
    if (detail_fax_logs && detail_fax_logs.media_url) {
      return true;
    }
    return false;
  }, [loadingDetailLogs, changedTab, detail_fax_logs]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleTab = (activeKey) => {
    setActiveTab(activeKey);
    setChangedTab(true);
    dispatch(emptyFaxLogs());
  };

  const rotatePdf = (orientation) => {
    const { id } = detail_fax_logs;
    if (id) {
      dispatch(
        updateFaxLog(id, {
          media_orientation: orientation,
        })
      );
    }
  };

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Faxing Module" subStatus="" />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <Tabs className="patientChart" type="card" onChange={handleTab}>
              <TabPane tab="Incoming Fax" key="incoming-fax">
                <PDFView
                  url={enablePDFViewer && detail_fax_logs.media_url}
                  onRotate={rotatePdf}
                  rotations={detail_fax_logs?.media_orientation ? JSON.parse(detail_fax_logs?.media_orientation) : []}
                />
              </TabPane>
              <TabPane tab="Outgoing Fax" key="outgoing-fax">
                <PDFView
                  url={enablePDFViewer && detail_fax_logs.media_url}
                  onRotate={rotatePdf}
                  rotations={detail_fax_logs?.media_orientation ? JSON.parse(detail_fax_logs?.media_orientation) : []}
                />
              </TabPane>
            </Tabs>
          </div>
          <FaxingSidebar
            ref={sidebarRef}
            resource={StaticResource}
            activeTab={activeTab}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
          />
        </div>
      </div>
    </div>
  );
};

export default FaxingModule;

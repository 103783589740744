import { ContentState, convertToRaw, EditorState } from "draft-js";

const CreateTextN = (value) => {
  if (value && value.length > 0) {
    let data = value;
    if (value && value.includes("!!new!!")) {
      value.split(`!!new!!`).map((r) => {
        data = data?.replace(`!!new!!`, "\n");
      });
    }
    if (value && value.includes("\\u2022")) {
      value.split(`\\u2022`).map((r) => {
        data = data?.replace(`\\u2022`, "!!UL!!");
      });
    }
    if (value && value.includes("\u2022")) {
      value.split(`\u2022`).map((r) => {
        data = data?.replace(`\u2022`, "!!UL!!");
      });
    }
    const contentState = ContentState.createFromText(data);
    const mix = EditorState.createWithContent(contentState);
    let dataText = JSON.stringify(convertToRaw(mix.getCurrentContent()));
    dataText = dataText.replace(`{"blocks":[`, "").replace(`],"entityMap": {}}`, "").replace(`],"entityMap":{}}`, "");
    return dataText;
  } else {
    return null;
  }
};
export default CreateTextN;

import React from 'react';
import ClinicalModule from './components/ClinicalModule';
import {YellowAlertSVG} from '../../common/components/icons/SVGIcon';

export const Children = {
  dashboard: '',
  clinical: ClinicalModule,
};

export const TabHeaders = [
  {label: 'Patient Snapshot', width: 'auto'},
  {label: 'Patient Chart', width: 'auto'},
  {label: 'Plan/Orders', width: 'auto'},
  {label: 'Medications', width: 'auto'},
  {label: 'Appointments', width: 'auto'},
  {label: 'Communication', width: 'auto'},
  {label: 'Logs', width: 'auto'},
  {label: 'Hospital Charting', width: 'auto'},
];
export const StaticResource = {
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />,
};
const RedSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(224, 24, 45)',
        border: '2px solid rgb(224, 24, 45)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};

const YellowSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(239, 193, 0)',
        border: '2px solid rgb(239, 193, 0)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};
export const SnapData = [
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={1} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={2} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={3} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Right Carotid Endarterectomy',
    actionDate: '02/05/2019',
    status: <YellowSnap info={4} />,
    description: [
      'Right Carotid Endarterectomy with bovine pericardial patch placement with Dr Omar Haqqani, MD at Bay McLaren Hospital',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={5} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
];

export const ChartTableList = [
  {date: '02/03/2017', link: '#', title: 'New Patient Referral', athor: ''},
  {
    date: '06/12/2018',
    link: '#',
    title: 'Insurance Documentation',
    athor: '',
  },
  {
    date: '09/02/2018',
    link: '#',
    title: 'LLE Angiogram with Stenting',
    athor: 'Aized',
  },
  {date: '05/09/2019', link: '#', title: 'F/U Appointment', athor: 'Haqqani'},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
];

export const ProcedureSpecificationTemp = [
  {
    comments: '',
    type: 'General',
    values: [
      {
        option: 'Well',
        checked: false,
      },
      {
        option: 'Bad',
        checked: false,
      },
      {
        option: 'Good',
        checked: false,
      },
      {
        option: 'Better',
        checked: false,
      },
    ],
  },
  {
    comments: '',
    type: 'General2',
    values: [
      {
        option: 'Well',
        checked: false,
      },
      {
        option: 'Bad',
        checked: false,
      },
      {
        option: 'Good',
        checked: false,
      },
      {
        option: 'Better',
        checked: false,
      },
    ],
  },
  {
    comments: '',
    type: 'General0',
    values: [
      {
        option: 'Well',
        checked: false,
      },
      {
        option: 'Bad',
        checked: false,
      },
      {
        option: 'Good',
        checked: false,
      },
      {
        option: 'Better',
        checked: false,
      },
    ],
  },
  {
    comments: '',
    type: 'General1',
    values: [
      {
        option: 'Well',
        checked: false,
      },
      {
        option: 'Bad',
        checked: false,
      },
      {
        option: 'Good',
        checked: false,
      },
      {
        option: 'Better',
        checked: false,
      },
    ],
  },
];

export const ReviewTableList = [
  {label: 'Rx Atorvastatin 40 mg po qhs', value: 'E-Prescribed'},
  {label: 'Right Carotid Endarterectomy at Bay McLaren', value: 'Faxed'},
  {label: 'Clinic Followup 2/4/2020 11:00 AM', value: 'Scheduled'},
  {label: 'Referral to Rheumatology Henry Ford', value: 'Faxed'},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
];

export const TempReview =
  'DISPOSITION: ICU \n\n' +
  'SHUNT: Yes \n\n' +
  'DRAIN: No \n\n' +
  'INDICATIONS: \n\n' +
  'The patent is a Patient with right carotid stenosis \n\n' +
  'The patient was made aware of the risks and benefits of the procedure' +
  ' including infection, bleeding, cardiac events, stroke, amputation and' +
  ' death and wished to proceed. All questions were answered in full.' +
  '\n\n' +
  'PROCEDURE PERFORMED: \n\n' +
  'The patient was brought to the operating room and laid in the' +
  ' supine position. IV access was obtained and General was administered.' +
  ' Clippers were utilized to remove the unwanted hairs from the surgical' +
  ' field. A surgical timeout was ascertained. Medical record number, site' +
  ' and location of surgery were confirmed. The areas of surgical access' +
  ' were prepped and draped in the standard, usual fashion. Perioperative' +
  ' antibiotics were administered within 30 minutes of incision and redosed' +
  ' within 4 hours.' +
  '\n\n' +
  'The right neck was prepped and draped in standard, usual fashion.\n\n' +
  'A 10 blade scalpel was utilized to make an incision within the neck' +
  ' anterior to the medial edge of the sternocleidomastoid muscle extending' +
  ' from the mastoid process to the sternal notch. This incision was carried with' +
  ' electrocautery through the subcutaneous tissue and through the' +
  ' platysma muscle. Two Kelley forceps were utilized to grasp the anterior' +
  ' flap and raised cranially. Electrocautery was utilized to dissect the' +
  ' anterior border of the sternocleidomastoid muscle which was subsequently' +
  ' retracted posteriorly. Self-retaining retractors were placed' +
  ' within the surgical wound for exposure ensuring that there was no compression' +
  ' to the cranial nerves.' +
  '\n\n' +
  'A right angle was utilized to dissect the cervical fascia exposing' +
  ' the carotid sheath. Sharp dissection ensued of the carotid sheath at the level' +
  ' of the common carotid artery. Sharp dissection was utilized to dissect' +
  ' circumferentially around the common carotid artery ensuring' +
  ' protection of cranial nerve X. Sharp dissection continued along the anterior' +
  ' aspect of the carotid artery with dissection of the superior' +
  ' thyroidal, external and internal carotid arteries. The external carotid,' +
  ' internal carotid and superior thyroidal were doubly vessel looped. A' +
  ' umbilical tape was utilized around the common carotid artery with a rammell.' +
  ' The dissection continued cranially to the level of the disease' +
  ' free zone of the internal carotid artery. Cranial nerver XII was identified' +
  ' and protected.' +
  '\n\n' +
  'The patients blood pressure was raised by anesthesia for a SBP greater' +
  ' than 160 and weight based heparin was administered to the patient' +
  ' such that the ACT (Activated Clotting Time) was greater than 250 through' +
  ' the entire procedure. A Detrich bulldog was applied along the' +
  ' internal carotid artery, and a angled Fogarty clamp was applied to the' +
  ' common carotid artery and the vessel loupes were retracted for the' +
  ' external carotid artery and the superior thyroidal artery.' +
  '\n\n' +
  'An 11 blade scalpel was utilized to make an arteriotomy on the anterior' +
  ' aspect of the carotid artery and this was extended cranially and' +
  ' cephalad with a Potts scissors. The plaque morphology appeared to have' +
  ' high grade complex plaque morphology. A Penfield was utilized to' +
  ' dissect the plaque from the common carotid artery and was extended' +
  ' cranially to the internal carotid artery for which the endarterectomy' +
  ' plane was feathered. The external carotid plaque was removed in a eversion' +
  ' technique with the enbloc nature of the entire plaque.';

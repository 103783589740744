const idCheck = [6, 7, 9, 10, 12, 20, 19];

const CheckIdAngio = (value) => {
  if (value && idCheck.some((r) => r == value)) {
    return true;
  }
  return false;
};

export default CheckIdAngio;

import TextField from "@material-ui/core/TextField";
import React, { useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import { RedRequiredCircleSVG } from "../icons/SVGIcon";
import "./style.scss";

const numberMask = "999";

const EditableInputWithDiv = ({
  label,
  value,
  handleChange,
  subLabel,
  isError,
  required,
  enableErrorText,
  helperText,
  fullWidth,
  noEditable,
  sm,
  unit,
  disabled,
  id,
  isErrorTextSpacing,
  isCheckBox,
}) => {
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [topValue, setTopValue] = useState("");
  const [bottomValue, setBottomValue] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (value && value !== inputValue) {
      const values = (value + "").split("/");
      setTopValue(values[0]);
      setBottomValue(values[1]);
      setInputValue(value);
    } else if (!value) {
      setTopValue("");
      setBottomValue("");
      setInputValue("");
    }
  }, [value]);

  const enableBlur = () => {
    setFocus(false);
  };

  useEffect(() => {
    let input_value = "";
    if (!!topValue || !!bottomValue) {
      input_value = topValue + "/" + bottomValue;
      setInputValue(input_value);
    }
    if (topValue === "0" || bottomValue === "0") {
      input_value = topValue + "/" + bottomValue;
      setInputValue(input_value);
    }
    if (handleChange && isLoaded) {
      handleChange(input_value);
    }

    // check input have
    // step 1 check if above not have
    // step 2 check if is checkbox have but input not have
    // step 3 check if input have but checkbox not have
    // step 4 check all have
    if (!value && !isCheckBox) {
      setEnableHelper(true);
      setError(true);
    } else if (!value && isCheckBox) {
      if (isError) {
        setEnableHelper(true);
        setError(true);
      } else {
        setInputValue("");
        setEnableHelper(false);
        setError(false);
      }
    } else if (value && !isCheckBox) {
      setEnableHelper(false);
      setError(false);
    } else if (value && isCheckBox) {
      setEnableHelper(false);
      setError(false);
      if (isError) {
      } else {
        setInputValue("");
      }
    }
  }, [topValue, bottomValue, isCheckBox, isError]);

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  return (
    <div
      className={`editableText material ${fullWidth ? "fullWidth" : ""} ${isErrorTextSpacing && "isErrorTextSpacing"} ${disabled ? "disabled" : ""}`}
    >
      {noEditable && <TextField id={id} variant="outlined" label={lableContent} value={value || ""} style={{ pointerEvents: "none" }} />}

      {!noEditable && (
        <>
          <div
            className={`MuiFormControl-root outlined-root MuiTextField-root ${error ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""} ${
              sm ? "sm" : ""
            }`}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
          >
            {(focus || !!inputValue) && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                paddingRight: "14px",
              }}
            >
              <InputMask
                id={`${id}_1`}
                mask={numberMask}
                value={topValue || ""}
                onChange={(e) => {
                  setTopValue(e.target?.value);
                  setIsLoaded(true);
                }}
                onFocus={() => setFocus(true)}
                onBlur={enableBlur}
                maskPlaceholder={null}
              >
                <TextField
                  className="no-border no-right-padding"
                  style={{ flex: 0, minWidth: sm ? 45 : 56 }}
                  placeholder={!focus && !inputValue && subLabel}
                />
              </InputMask>
              <div
                className="no-border"
                style={{
                  flex: 0,
                  textAlign: "center",
                  minWidth: sm ? 10 : 16,
                }}
              >
                {"/"}
              </div>
              <InputMask
                id={`${id}_2`}
                mask={numberMask}
                value={bottomValue || ""}
                onChange={(e) => {
                  setBottomValue(e.target?.value);
                  setIsLoaded(true);
                }}
                onFocus={() => setFocus(true)}
                onBlur={enableBlur}
                maskPlaceholder={null}
              >
                <TextField className="no-border no-left-padding" style={{ flex: 1 }} />
              </InputMask>
              {!!unit && (
                <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                  <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary" style={{ fontSize: "0.875rem" }}>
                    {unit}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </>
      )}
    </div>
  );
};

export default EditableInputWithDiv;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import MainContainer from "./ProcessesContainer";

const sideNavLinks = [
  {
    link: "/processes/quick-register",
    label: "Quick Register",
  },
  {
    link: "/processes/processing-referral",
    label: "Processing Referral",
  },
  {
    link: "/processes/clinic-confirmation",
    label: "Clinic Confirmation",
  },
  {
    link: "/processes/clinic-appointment",
    label: "Clinic Appointment",
  },
  {
    link: "/processes/obl-confirmation",
    label: "OBL Confirmation",
  },
  {
    link: "/processes/obl-appointment",
    label: "OBL Appointment",
  },
  {
    link: "/processes/surgery-confirmation",
    label: "Surgery Confirmation",
  },
];

const ProcessesPage = ({ location }) => {
  const [isSideNav, setIsSideNav] = useState(true);

  const openSideNav = () => setIsSideNav(true);

  const closeSideNav = () => setIsSideNav(false);

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="alert" />
      Processes
    </div>
  );

  return (
    <div className="clinicWorkflow">
      <Helmet>
        <title>Processes | FAACT Vascular</title>
        <meta name="title" content="Processes | FAACT Vascular" />
      </Helmet>
      <SubSideNav subMenu={sideNavLinks} title={getTitle()} isSideNav={isSideNav} openSideNav={openSideNav} closeSideNav={closeSideNav} />
      <MainContainer location={location} isSideNav={isSideNav} />
    </div>
  );
};

export default ProcessesPage;

import { Icon, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Tabs } from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import CardBlock from "../../common/components/Card";
import ScrollWrapper from "../../common/components/ScrollBar";
import SubTopBar from "../../common/components/SubTopBar";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import { ClinicProcessingTabHeaders } from "../../common/tabs";
import SnapshotSideBar from "../../common/views/PatientSnapshot/SnapSidebar";
import {
  changeTab,
  getCurrentUserInfo,
  patient_insurances_id_insurances,
  selectPatient,
  setGlobalLoading,
  setPatientSnapshotData,
} from "../../store/actions";
import ProcedureAnalytics from "./ProcedureAnalytics";
import ProcedureCalendar from "./ProcedureCalendar";
import CallbackTable from "./components/CallBackTable";
import CallbackFilter from "./components/CallbackFilter";
import HospitalSideBar from "./components/HospitalSidebar";
import PendingFilter from "./components/PendingFilter";
import PendingTable from "./components/PendingTable";
import "./style.scss";

function ClinicProcedure() {
  const sidebarRef = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [selectedType, setSelectedType] = useState("pending");
  const [search, setSearch] = useState({
    callback: "",
    pending: "",
  });
  const [active, setActive] = useState(false);
  const [activeID, setActiveID] = useState(null);
  const [settup, setSettup] = useState(-1);
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const pending = useSelector((state) => state.procedure.pending_clinics);
  const callback = useSelector((state) => state.procedure.callback_clinics);
  const resource = useSelector((state) => state.procedure.resource);
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);

  useEffect(() => {
    if (currentUserInfo) {
      setIsAdmin(currentUserInfo.is_admin === 1);
    }
  }, [currentUserInfo]);

  const fetchSelect = async (type) => {
    setSelectedType(type);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
    setActive(false);
    setActiveID(null);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleSearch = (type) => (value) => {
    setSearch({ ...search, [type]: value });
  };

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Ultrasound Procedure</title>
        <meta name="title" content="FAACT Vascular | Ultrasound Procedure" />
      </Helmet>
    );
  };

  const getStatus = (status, status1, status3) => {
    return (
      <div className="statusHealth">
        <Icon type="notification" theme="twoTone" twoToneColor="#0f62fe" />
        {status}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#F1C21B" />
        {status1}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#198038" />
        {status3}
      </div>
    );
  };

  const onHandleClick = async (event) => {
    setSettup(0);
    setTimeout(() => {
      setSettup(-1);
    }, 0);
    dispatch(setGlobalLoading(true));
    await dispatch(changeTab({ tabActive: null, accordion: null, step: 0 }));
    const result = await dispatch(selectPatient(event.patient_id));
    await dispatch(setPatientSnapshotData("resource", { ...result }));
    await dispatch(patient_insurances_id_insurances(event.patient_id));
    setIsSidebar(true);
    setActive(true);
    setActiveID(event?.id);
    setTimeout(() => {
      dispatch(setGlobalLoading(false));
    }, 800);
  };

  const handleResource = (step) => {};

  const renderSideComponent = () => {
    if (!resource || !Object.keys(resource).length) {
      return null;
    }

    const fullName = resource.patient && resource.patient?.name.toUpperCase();
    const age = resource.patient && resource.patient.date_of_birth ? "(" + moment().diff(resource.patient.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resource.patient.allergies) {
      resource.patient.allergies.forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resource.patient.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resource.patient.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resource.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy) => allergy)}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={resource.patient.allergies.map((allergy) => allergy.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>

        {resource.gender && resource.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const pendingLengh = pending ? pending.length : 0;
  const callbackLength =
    callback && callback.length > 0 ? callback.filter((r) => !r.has_intraday_call_attempt || r.has_intraday_call_attempt === 0).length : 0;
  const callbackToCall =
    callback && callback.length > 0 ? callback.filter((r) => r.has_intraday_call_attempt == true || r.has_intraday_call_attempt == 1).length : 0;
  const isResource = !resource || !Object.keys(resource).length;

  return (
    <div className="hospitalContent mainPage">
      {renderMetadata()}
      <SubTopBar
        title="Clinic Processing"
        noLeftSide={true}
        subStatus={getStatus(pendingLengh, callbackLength, callbackToCall)}
        sideComponent={renderSideComponent()}
        isBarcode={!isResource}
      />
      <div className={`clinicWorkflow__main-container`}>
        <div className="clinicWorkflow__main-subTitle tabContainer" style={{ paddingTop: "1.5rem", paddingLeft: "5%" }}>
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {ClinicProcessingTabHeaders.map((tab, index) => {
                return (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item
                    ${activeTab === index ? "active" : ""}
                    ${tab.label === "Analytics" && !isAdmin ? "disabled" : ""}
                  `}
                    style={{ width: tab.width, marginLeft: index !== 0 ? "3rem" : "" }}
                    onClick={() => {
                      if (tab.label === "Analytics" && !isAdmin) {
                        return;
                      }
                      setActiveTab(index);
                    }}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </Tabs>
          </div>
        </div>
        {active ? (
          <SnapshotSideBar
            ref={sidebarRef}
            patientResource={patientResource}
            isShow={isSidebar}
            settup={settup}
            isScan={true}
            handleCloseSidebar={handleCloseSidebar}
            notPermanent
          />
        ) : (
          <HospitalSideBar
            ref={sidebarRef}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
            handleNext={handleResource}
            selectedType={selectedType}
          />
        )}

        <div className="main multi-main" style={{ paddingTop: 20 }}>
          <ScrollWrapper css="no-padding x-hidden">
            <div
              className={`mainContainer ${isResource || !isSidebar ? "no-sidebar" : ""}`}
              style={{
                paddingRight: "calc(5% - 3rem)",
                paddingTop: "0",
                paddingBottom: "0",
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
              }}
            >
              {activeTab === 0 && (
                <div className="mainTableContainer">
                  <CardBlock title="Clinic Pending" varient="half" other="dashboard">
                    <PendingFilter handleSearch={handleSearch("pending")} />
                    <div className="tableSection">
                      <PendingTable
                        title="result"
                        search={search.pending}
                        selectedType={selectedType}
                        handleSidebar={handleSidebar}
                        handleSelect={fetchSelect}
                      />
                    </div>
                  </CardBlock>

                  <CardBlock title="Clinic Call Back" varient="half" other="dashboard">
                    <CallbackFilter handleSearch={handleSearch("callback")} />
                    <div className="tableSection">
                      <CallbackTable
                        title="result"
                        search={search.callback}
                        selectedType={selectedType}
                        handleSidebar={handleSidebar}
                        handleSelect={fetchSelect}
                      />
                    </div>
                  </CardBlock>
                </div>
              )}

              {activeTab === 1 && <ProcedureCalendar activeID={activeID} onHandleClick={onHandleClick} />}

              {activeTab === 2 && <ProcedureAnalytics />}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
}

export default ClinicProcedure;

import moment from "moment";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../../common/components/DataTable/DataTable";
import { getDetailPolicies, getPolicies } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import ManualPoliciesSidebar from "./ManualPoliciesSidebar";

const ManualPolicies = ({ loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const policies = useSelector((state) => state.common.policies);
  const policiesDetail = useSelector((state) => state.common.policiesDetail);
  const tableRows = useMemo(() => {
    const sortedPolicies = policies?.sort((a, b) => a?.policy_number - b?.policy_number);
    return sortedPolicies?.length ? sortedPolicies : [];
  }, [policies]);

  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => {
    if (activeIndex != index) {
      setRow(index);
      dispatch(getDetailPolicies(row?.id));
      setIsSidebar(true);
    }
  };

  const fetchList = () => {
    dispatch(getPolicies());
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_manual_policies"
                labels={["Policy", "Title", "Updated"]}
                widths={["25%", "50%", "25%"]}
                columns={[
                  { sortable: false, key: "policy_number" },
                  { sortable: false, key: "policy_name" },
                  { sortable: false, key: "effective_date" },
                ]}
                sorts={["", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  origin: row,
                  policy_number: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      <p>{row?.policy_number}</p>
                    </div>
                  ),
                  policy_name: row?.policy_name,
                  effective_date: row?.effective_date && moment(row?.effective_date).format("MM/DD/YY"),
                }))}
                fetchList={fetchList}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && policiesDetail && Object.keys(policiesDetail).length > 0 && (
        <ManualPoliciesSidebar
          ref={sidebarRef}
          policiesDetail={policiesDetail}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default ManualPolicies;

import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';

export const resetPatientStates = createActionThunk(
    AT.RESET_PATIENT_STATES,
    () => ({
      reset: 'all',
    }),
);

export const setPatientSnapshotData = createActionThunk(
    AT.SET_PATIENT_SNAPSHOT_DATA,
    (type, newData) => ({[type]: newData}),
);

export const setFirstLoadedSnapshot = createActionThunk(
    AT.SET_FIRST_LOADED_SNAPSHOT,
    () => {},
);

import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import {useWindowSize} from '../../../../common/utils';

const Logs = ({data, title, loading}) => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const tableRows = data ? data : [];
  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => () => {
    setRow(index);
  };

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{padding: '1rem 2rem', height: 'calc(100vh - 165px)'}}
    >
      <div className="fullContainer no-padding-right">
        <div className="tableHeader" style={{borderBottom:'2px solid #dfe3e6 '}}>
          <div className="tableItem">
            <div className="th" style={{width: '20%',borderLeft:'none'}}>
              <b>Date</b>
            </div>
            <div className="th" style={{width: '60%'}}>
              <b>Action</b>
            </div>
            <div className="th" style={{width: '20%'}}>
              <b>Employee</b>
            </div>
          </div>
        </div>
        <div className="tableSection">
          <div ref={ref} className="tableContainer">
            <ScrollWrapper css="no-padding">
              <div className="tableList">
                {tableRows.map((row, index) => (
                  <div
                    className={`tableItem ${
                      activeIndex === index ? 'active' : ''
                    }`}
                    key={`resultTable-${title}-${index}`}
                    onClick={handleRow(index)}
                  >
                    <div className="td" style={{width: '20%'}}>
                      <p>
                        {row?.date && moment(row?.date).format('MM/DD/YYYY')}
                      </p>
                    </div>
                    <div className="td" style={{width: '60%'}}>
                      <p>{row?.action}</p>
                    </div>
                    <div className="td" style={{width: '20%'}}>
                      <p>{row?.employee}</p>
                    </div>
                  </div>
                ))}
                {emptyRows.map((row, index) => (
                  <div
                    className={`tableItem empty`}
                    key={`resultTable-empty-${title}-${index}`}
                  >
                    <div className="td" style={{width: '20%'}} />
                    <div className="td" style={{width: '60%'}} />
                    <div className="td" style={{width: '20%'}} />
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;

import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BillingNote from "../../../../common/components/BillingNote";
import CptCodeDataTable from "../../../../common/components/CptCodeDataTable";
import DataTable from "../../../../common/components/DataTable/WhiteHeaderTable";
import ButtonDropdown from "../../../../common/components/EditableText/Dropdown";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { exportPCMOverview, setGlobalLoading, updatePCM } from "../../../../store/actions";

const OverviewSidebar = React.forwardRef(
  ({ resource, isShow, handleCloseSidebar, detailPCM, updateState, moveState, updateLocal, iconOverviewStatus }, ref) => {
    const [reset, setReset] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isToastr, setIsToastr] = useState(false);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const dispatch = useDispatch();

    useEffect(() => {
      if (detailPCM && detailPCM.direct_cost) {
        updateState(detailPCM, {
          direct: detailPCM.direct_cost,
        });
      }
      if (detailPCM && detailPCM.payment) {
        updateState(detailPCM, {
          payment: detailPCM.payment,
        });
        setReset(true);
      }
      if (detailPCM && detailPCM.contribution_margin) {
        updateState(detailPCM, {
          contribution_margin: detailPCM.contribution_margin,
        });
        setReset(true);
      }
    }, [detailPCM]);

    useEffect(() => {
      if (reset) {
        setReset(false);
      }
    }, [reset]);

    const sendExport = async () => {
      if (resource) {
        const result = await dispatch(exportPCMOverview(iconOverviewStatus));
        window.open(result.url, "_self");
      }
    };

    const descFormat = (desc) => {
      const cutDesc = desc.slice(1);
      const firstChar = desc[0];

      return firstChar.toUpperCase() + cutDesc.toLowerCase();
    };

    const saveType = async (type) => {
      setLoading(true);

      const params = {
        icon_overview_status: type,
      };
      const saveSuccess = await dispatch(updatePCM(detailPCM.id, params));
      if (saveSuccess) {
        if (updateLocal) {
          updateLocal({ id: detailPCM.id, status: type });
        }
        detailPCM.icon_overview_status = type;
        setLoading(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (isShow && detailPCM && Object.keys(detailPCM).length) {
        dispatch(setGlobalLoading(loading));
      }
    }, [loading, isShow, detailPCM]);

    const minRowCount = 4;
    let emptyRows = [];
    if ([].length < minRowCount + 1) {
      emptyRows = new Array(minRowCount - [].length).fill({ label: "" });
    }

    if (!detailPCM || !Object.keys(detailPCM).length) {
      return null;
    }

    return (
      <Drawer
        title={`Overview - ${detailPCM?.patient_name}`}
        placement="right"
        closable
        className={sideNavStatus ? "wide" : ""}
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
      >
        <div ref={ref} className="resourceContainer new">
          {isToastr && <ToastMessage type="Endovascular" status="success" msg="Saved Successfully!" />}
          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <div className="resourceInfo d-flex">
                <LoginFormInput label={"Provider"} value={detailPCM.provider_name} />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Date of Service"}
                  value={detailPCM.date_of_procedure && moment(detailPCM.date_of_procedure).format("MM/DD/YYYY")}
                  type={"date"}
                  noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput
                  label={"Date of Billing"}
                  value={detailPCM.date_of_billing && moment(detailPCM.date_of_billing).format("MM/DD/YYYY")}
                  type={"date"}
                  noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput label={"Primary Insurance"} value={detailPCM.primary_insurance} type={"text"} noEditable={true} />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput label={"Primary Insurance Number"} value={detailPCM.primary_insurance_number} type={"text"} noEditable={true} />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput label={"Secondary Insurance"} value={detailPCM.secondary_insurance} type={"text"} noEditable={true} />
              </div>
              <div className="resourceInfo d-flex">
                <LoginFormInput label={"Secondary Insurance Number"} value={detailPCM.secondary_insurance_number} type={"text"} noEditable={true} />
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
                <CptCodeDataTable
                  title="CPT"
                  data={(detailPCM?.cpt_code || []).map((data) => {
                    return {
                      code: data?.code,
                      short_desc: descFormat(data?.short_desc),
                    };
                  })}
                />
              </div>
              <hr />
            </div>

            {detailPCM ? (
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >
                <BillingNote detailPCM={detailPCM} saveType={saveType}></BillingNote>
              </div>
            ) : null}

            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
              }}
            >
              <DataTable
                title="date-action-table"
                bordered
                isNormal
                isNotFirstactive
                showIcon
                isFixHeight
                sizeHeightFix={`calc((${minRowCount} * 40px) + 50px)`}
                labels={["Date", "Action"]}
                widths={["60%", "40%"]}
                columns={[
                  { sortable: false, key: "date", noPadding: true },
                  { sortable: false, key: "action" },
                ]}
                rowData={[].map((item, index) => {
                  return {
                    date: item.date,
                    action: item.action,
                  };
                })}
                emptyRows={emptyRows}
                sorts={["", ""]}
              />
            </div>

            <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "0" }}>
              <button className="common-btn outlined-btn" onClick={() => {}}>
                Appeal/Correction
              </button>
              <ButtonDropdown
                options={[
                  {
                    display: "Correction Team",
                    value: "correction",
                  },
                  {
                    display: "Research Team",
                    value: "research",
                  },
                  {
                    display: "Payment Posting",
                    value: "payment_posting",
                  },
                  {
                    display: "Adjust Off",
                    value: "adjust_off",
                  },
                  {
                    display: "Collection",
                    value: "positive_patient_balance",
                  },
                ]}
                onSelect={(val) => {
                  saveType(val);
                }}
                label="Assignment"
                marginLeft={10}
              />
              <button
                className={"button-fix"}
                onClick={() => {
                  sendExport();
                }}
                style={{ marginRight: "10px" }}
              >
                Export To Excel
              </button>
            </div>
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default OverviewSidebar;

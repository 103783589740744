import moment from "moment";
import { createReducer } from "redux-create-reducer";
import AT from "../actionTypes";

const initialState = {
  oblDateString: moment(new Date()).format("MM/DD/YYYY"),
  clinicDateString: moment(new Date()).format("MM/DD/YYYY"),
};

const SET_OBL_DATE_STRING_SUCCEEDED = `${AT.SET_OBL_DATE}_SUCCEEDED`;

const SET_CLINIC_DATE_STRING_SUCCEEDED = `${AT.SET_CLINIC_DATE}_SUCCEEDED`;

export default createReducer(initialState, {
  [SET_OBL_DATE_STRING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      oblDateString: action?.payload?.data,
    });
  },
  [SET_CLINIC_DATE_STRING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      clinicDateString: action?.payload?.data,
    });
  },
});

import {createReducer} from 'redux-create-reducer';
import moment from 'moment';
import AT from '../actionTypes';

const initialState = {
  saving: false,
  list: [],
  immunizations: [],
  family_histories: [],
  social_histories: [],
  previous_medical_histories: [],
  previous_surgical_histories: [],
  allergies: [],
  medications: [],
  allPreviousMedicalHistories: [],
  allSurgicalSurgicalHistories: [],
  allAllergies: [],
  allMedications: [],
  calList: {},
  descList: [],
};

const LOAD_ULTRASOUND_HISTORY_FOR_CONDITION_SUCCEEDED = `${AT.LOAD_ULTRASOUND_HISTORY_FOR_CONDITION}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      saving: false,
      list: [],
      immunizations: [],
      family_histories: [],
      social_histories: [],
      previous_medical_histories: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      allPreviousMedicalHistories: [],
      allSurgicalSurgicalHistories: [],
      allAllergies: [],
      allMedications: [],
    };
  },

  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.CLINIC_PATIENT_SNAPSHOT_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (action?.payload) {
      const {
        list,
        immunizations,
        family_histories,
        social_histories,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        allPreviousMedicalHistories,
        allSurgicalSurgicalHistories,
        allAllergies,
        allMedications,
      } = action?.payload;

      return {
        ...state,
        list,
        immunizations,
        family_histories,
        social_histories,
        previous_medical_histories,
        previous_surgical_histories,
        allergies,
        medications,
        allPreviousMedicalHistories,
        allSurgicalSurgicalHistories,
        allAllergies,
        allMedications,
      };
    }
    return {...state};
  },
  [`${AT.CLINIC_PATIENT_SNAPSHOT_SAVE_MEDICAL_HISTORY}_STARTED`](
      state,
      action,
  ) {
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.CLINIC_PATIENT_SNAPSHOT_SAVE_MEDICAL_HISTORY}_SUCCEDED`](
      state,
      action,
  ) {
    return {
      ...state,
      saving: false,
    };
  },
  [`${AT.CLINIC_PATIENT_SNAPSHOT_SAVE_MEDICAL_HISTORY}_STARTED`](
      state,
      action,
  ) {
    return {
      ...state,
      loadingHistory: true,
    };
  },
  [LOAD_ULTRASOUND_HISTORY_FOR_CONDITION_SUCCEEDED](state, action) {
    const {calList, descList} = action?.payload;
    return Object.assign({}, state, {
      calList,
      descList,
    });
  },
});

import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import { setLocal } from "../../../helpers/Local";
import {
  deleteUltrasoundType,
  getDetailUltrasounds,
  getUltrasounds,
  get_ultrasound_type,
  save_new_ultrasound,
  setGlobalLoading,
  updateDetailUltrasounds,
} from "../../../store/actions";

const { Search } = Input;
let timer = null;

const UltrasoundSearch = ({ setResource }) => {
  const loading = useSelector((state) => state.procedureDetail.loading_ultrasound_type);
  const untrasound_list = useSelector((state) => state.procedureDetail.untrasound_list);
  const dispatch = useDispatch();
  const [ultrasoundID, setUltrasoundID] = useState(0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [headerTitle, setHeaderTitle] = useState("");
  const [activeRow, setActiveRow] = useState(-1);
  const [dataRow, setDataRow] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [search, setSearch] = useState("");
  const emptyRows = [];

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    if (ultrasoundID !== row?.id) {
      setUltrasoundID(row?.id);
      setResource(row?.id);
      setLocal("id_utralsound_type", row?.id);
      await dispatch(getDetailUltrasounds(row?.id));
      await dispatch(get_ultrasound_type(row?.id));
    }
  };

  const handleAddUltrasound = async () => {
    if (!search.trim()) {
      return;
    }
    setLoadingLocal(true);
    const param = {
      name: search,
      length_of_time: "",
      description: "",
      cpt_code: [],
      conditions: [],
      codes: [],
    };
    const addsuccess = await dispatch(save_new_ultrasound(param));
    if (addsuccess) {
      setSearch("");
      setLoadingLocal(false);
      dispatch(getUltrasounds());
    }
  };

  const handleSearch = (e) => {
    const value = e.target?.value;
    setActiveRow(-1);
    setSearch(value);
  };

  const handleDelete = async (row, index) => {
    if (activeDelete !== row?.id) {
      setActiveDelete(row?.id);
      return;
    }
    if (index === activeRow) {
      setActiveRow(-1);
    }

    if (ultrasoundID === activeDelete) {
      setUltrasoundID(-1);
      setResource(-1);
      setLocal("id_utralsound_type", 0);
    }
    const deleteSuccess = await dispatch(deleteUltrasoundType(row?.id));
    const loadSuccess = await dispatch(getUltrasounds());
    if (deleteSuccess && loadSuccess) {
      setActiveDelete(-1);
      setActiveRow(-1);
    }
  };

  useEffect(() => {
    const arrayFilter = [...untrasound_list].reverse();
    const arraySucces = arrayFilter.filter((item) => {
      return item?.name.toLowerCase().includes(search.toLowerCase());
    });
    setDataRow([...arraySucces]);
  }, [untrasound_list, search]);

  const fetchAPI = async () => {
    setLoadingLocal(true);
    const loadSuccess = await dispatch(getUltrasounds());
    if (loadSuccess) {
      setLoadingLocal(false);
    }
  };

  const onChangeDetail = async (e, row) => {
    setHeaderTitle(e.target?.value);
    clearTimeout(timer);
    const params = {
      name: e.target?.value,
    };
    timer = setTimeout(async () => {
      await dispatch(updateDetailUltrasounds(row?.id, params));
      await dispatch(getUltrasounds());
    }, 500);
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingLocal || loading));
  }, [loading, loadingLocal]);

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search value={search} placeholder="Search Ultrasound" onChange={handleSearch} suffix={<SearchSVG />} />
        </div>
        <div className="patientSearch__filter-action">
          <button className="patientSearch__filter-add" onClick={handleAddUltrasound}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="fullContainer" style={{ position: "relative" }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div className="tableContainer" style={{ overflow: "scroll" }}>
            <div className="tableList">
              {(dataRow || []).map((row, index) => (
                <div
                  className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                  key={`resultTable-${index}`}
                  onClick={() => {
                    handleSelect(row, index);
                  }}
                  style={{ paddingRight: "10px" }}
                >
                  {activeRow === index ? (
                    <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                      <Input
                        value={headerTitle}
                        autoFocus={true}
                        onChange={(e) => {
                          onChangeDetail(e, row);
                        }}
                        onFocus={() => {
                          setHeaderTitle(row?.name);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                      <p>{row?.name}</p>
                    </div>
                  )}
                  {row?.is_default === 1 ? null : (
                    <DeleteIcon icon="can" handleClick={() => handleDelete(row, index)} selected={activeDelete === row?.id} />
                  )}
                </div>
              ))}
              {(emptyRows || []).map((row, index) => (
                <div className={`tableItem`} key={`empty-resultTable-${index}`}>
                  <div className="td" style={{ width: "50%" }} />
                  <div className="td" style={{ width: "50%" }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UltrasoundSearch;

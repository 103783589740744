import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs } from "@material-ui/core";
import { Tooltip } from "antd";
import moment from "moment";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import { getLocal } from "../../../helpers/Local";
import {
  getLocations,
  getProviders,
  getReferralConditions,
  getUltrasounds,
  get_ultrasound_finding_value,
  remove_selected_patient_resource,
  save_ultrasound_finding_value,
  setActionGoNextTab,
  setActiveReviewChange,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../store/actions";
import { UltrasoundTabHeaders } from "../constant";
import ClinicSchedule from "./ultrasoundViews/ClinicSchedule";
import Review from "./ultrasoundViews/Review";
import UltrasoundFindings from "./ultrasoundViews/UltrasoundFindings";

function UltrasoundModule() {
  const dispatch = useDispatch();
  const firstLoadingActive = useSelector((state) => state.common.firstLoadingActive);
  const selectedPatientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const resourceCheck = useSelector((state) => state.patientSnapshot.resource);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  const lockUltrasound = useSelector((state) => state.common.lockUltrasound);
  const actionGoNextTab = useSelector((state) => state.common.actionGoNextTab);

  const [activeTab, setActiveTab] = useState(0);
  const [isSelectedPatient, setIsSelectedPatient] = useState(false);
  const [valueSaveFinding, setValueSaveFinding] = useState(null);
  const [stateStatusChange, setStateStatusChange] = useState(null);

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getProviders());
    dispatch(getReferralConditions());
    dispatch(getUltrasounds());
  }, []);

  useEffect(() => {
    if (actionGoNextTab) {
      setActiveTab(1);
      dispatch(setActionGoNextTab(false));
    }
  }, [actionGoNextTab]);

  useEffect(() => {
    const isSelectedPatientProps = selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length < 1 && activeTab !== 0) {
      setActiveTab(0);
    }
    if (activeTab === 0 && Object.keys(isSelectedPatientProps).length > 0 && firstLoadingActive) {
      setActiveTab(1);
      dispatch(setFirstLoadingActive(false));
    }
    if (Object.keys(isSelectedPatientProps).length > 0) {
      setIsSelectedPatient(true);
    } else {
      setIsSelectedPatient(false);
    }
  }, [selectedPatientResource, firstLoadingActive, activeTab]);

  const renderSideComponent = () => {
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {selectedPatientResource.gender && selectedPatientResource.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const goNextTabReview = () => {
    setActiveTab(2);
    dispatch(setActiveReviewChange(false));
  };

  const checkClick = () => {
    if (selectedPatientResource && selectedPatientResource?.has_valid_insurance === 0) {
      dispatch({
        header: "Ultrasound Workflow",
        message: "Please verify the patient insurance before proceeding",
        type: "failed",
      });
    }
  };

  const changeTabIndex = async (index) => {
    if (index === 0) {
      dispatch(remove_selected_patient_resource());
    }
    if (activeTab === 1 && valueSaveFinding) {
      dispatch(setGlobalLoading(true));
      await dispatch(save_ultrasound_finding_value(valueSaveFinding));
      const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
      await dispatch(get_ultrasound_finding_value(appointment_ultrasound_id));
      dispatch(setGlobalLoading(false));
    }

    setActiveTab(index);
    setValueSaveFinding(null);
  };

  const saveChangeFinding = (value) => {
    setValueSaveFinding(value);
  };

  const updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      setStateStatusChange(value);
    }
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Ultrasound Module" isBarcode={isSelectedPatient} sideComponent={renderSideComponent()} subStatus={""} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{ height: "29px" }}>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={activeTab}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable force tabs example"
            >
              {UltrasoundTabHeaders.map((tab, index) =>
                isSelectedPatient && !lockUltrasound && selectedPatientResource?.has_valid_insurance === 1 ? (
                  <div
                    id={`${tab?.label && tab?.label.length > 0 && tab.label.replaceAll(" ", "_")}`}
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                    style={{ width: tab.width }}
                    onClick={() => changeTabIndex(index)}
                  >
                    {tab.label}
                  </div>
                ) : (
                  <div
                    id={`${tab?.label && tab?.label.length > 0 && tab.label.replaceAll(" ", "_")}`}
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                    style={{ width: tab.width }}
                  >
                    {tab.label}
                  </div>
                )
              )}
            </Tabs>
          </div>
        </div>
        <PusherCheck updateStatus={updateStatusPusher} />
        {activeTab >= 0 && (
          <ClinicSchedule
            onClick={() => {
              checkClick();
            }}
            stateStatusProps={stateStatusChange}
            updateStatusProps={() => {
              setStateStatusChange(null);
            }}
            display={activeTab === 0}
            goNextTab={() => {}}
            goNextTabReview={goNextTabReview}
            isClinicWorkFlow={true}
          />
        )}
        {!lockUltrasound && selectedPatientResource && selectedPatientResource?.has_valid_insurance === 1 && activeTab === 1 && (
          <UltrasoundFindings saveChange={saveChangeFinding} patientResource={selectedPatientResource} />
        )}
        {!lockUltrasound && selectedPatientResource && selectedPatientResource?.has_valid_insurance === 1 && activeTab === 2 && (
          <Review patientResource={selectedPatientResource} />
        )}
      </div>
    </div>
  );
}

export default UltrasoundModule;

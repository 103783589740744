import {createReducer} from 'redux-create-reducer';
import AT from '../actionTypes';

const initialState = {
  intra_operations: [],
  pre_operations: [],
  post_operations: [],
};

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SET_PROCEDURE_MODULE_REVIEW}_SUCCEEDED`](state, action) {
    return {
      ...state,
      ...action?.payload,
    };
  },
});

const RemoveN = (value) => {
  if (value && value.length > 0) {
    let mixFix = value;
    if (JSON.stringify(value).includes(`\n`) || value.includes('\n')) {
      value.split(`\n`).map((r) => {
        mixFix = mixFix.replace(`\n`, '!!new!!');
      });
    }
    return mixFix;
  }
  return null;
};

export default RemoveN;

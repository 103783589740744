const data = {
  text_template: `{
	"blocks": [
		{
		"key": "txHead",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 0,
			"style": ""
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "48o8a",
		"text": "PROCEDURE PERFORMED",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 20,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 20,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "text4",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 0,
			"style": ""
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "b16nu",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "570ck",
		"text": "SURGEON",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 8,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 8,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "text9",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 0,
			"style": ""
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "textstarttxt",
		"text": "PROCEDURE PERFORMED",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 20,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 20,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "5td5x",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "sikcma",
		"text": "SIGNATURE:",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 10,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 10,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "textsigsmall",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "textsigsmallempty",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "dimaks",
		"text": "DATE FINALIZED:",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 15,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 15,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "text20startspace",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "text20",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "textendtxt",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}],
	"entityMap": {}
}`,
};

export default data;

import {createReducer} from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  events: null,
  activeOpenings: null,
  closedDates: null,
  openingTime: null,
  closingTime: null,
  loadingEvents: false,
  errorMessage: '',
  closedEvents: null,
};

const SET_STEP_DATA_SUCCEEDED = `${AT.SET_STEP_DATA}_SUCCEEDED`;
// MainSection
const GET_EVENTS_SUCCEEDED = `${AT.GET_EVENTS}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [SET_STEP_DATA_SUCCEEDED](state, action) {
    const {loadingEvents} = action?.payload || {};

    return Object.assign({}, state, {
      loadingEvents:
        loadingEvents !== undefined ? loadingEvents : state.loadingEvents,
    });
  },
  // Get events process
  [GET_EVENTS_SUCCEEDED](state, action) {
    const {
      events,
      active_openings: activeOpenings,
      closed_dates: closedDates,
      opening_time: openingTime,
      closing_time: closingTime,
    } = action?.payload || {};
    return Object.assign({}, state, {
      events,
      activeOpenings,
      closedDates,
      openingTime,
      closingTime,
      loadingEvents: false,
    });
  },
});

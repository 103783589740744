import React from "react";
import { LoadingSVG } from "../icons/SVGIcon";
import "./style.scss";

const Loading = ({ extra, color }) => (
  <div
    style={{
      position: "absolute",
      inset: 0,
      backgroundColor: "rgba(0,0,0, 0.5)",
      zIndex: 499,
    }}
  >
    <div
      className="loading-icon loading-vital-ros"
      style={{
        ...extra,
      }}
    >
      <div style={{ width: 40, height: 40 }}>
        <LoadingSVG color={color} />
      </div>
    </div>
  </div>
);

export default Loading;

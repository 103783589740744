import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../../common/components/Card";
import { adminSettingGetConfig } from "../../../../store/actions";
import AddPharmacy from "../../../procedureDetail/meaningfulUse/AddPharmacy";
import DocumentCategorization from "./DocumentCategorization";
import DocumentLogoUpload from "./DocumentComponents/DocumentLogoUpload";
import DocumentOCRSettings from "./DocumentComponents/DocumentOCRSettings";
import DocumentTemplates from "./DocumentComponents/DocumentPDFUpload";
import HealthcareProviderLocation from "./DocumentComponents/HealthcareProviderLocation";
import OrganizationDetails from "./DocumentComponents/OrganizationDetails";

const DocumentCustomization = () => {
  const dispatch = useDispatch();
  const resource = useSelector((state) => state.adminSetting);

  useEffect(() => {
    dispatch(adminSettingGetConfig());
  }, []);

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <DocumentCategorization />
          <CardBlock isOverFlow={true} title="Logo Upload" varient="quarter" other="">
            <DocumentLogoUpload resource={resource} />
          </CardBlock>
          <CardBlock isOverFlow={true} title="OCR Settings" varient="quarter" other="" contentPadding="1rem">
            <DocumentOCRSettings resource={resource} />
          </CardBlock>

          <CardBlock title="Healthcare Provider Location2" varient="quarter" other="">
            <HealthcareProviderLocation />
          </CardBlock>

          <CardBlock title="Pharmacy Information" varient="quarter" other="">
            <AddPharmacy />
          </CardBlock>

          <CardBlock isOverFlow={true} title="Organization Details" varient="quarter" other="" contentPadding="1rem">
            <OrganizationDetails resource={resource} />
          </CardBlock>
          <CardBlock isOverFlow={true} title="Document Template" varient="quarter" other="" contentPadding="1rem">
            <DocumentTemplates resource={resource} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default DocumentCustomization;

import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";

const ContributionMarginSidebar = React.forwardRef(({ handleCloseSidebar, isShow, resource, currentUserInfo }, ref) => {
  const [total, setTotal] = useState(0);
  const [payPayPeriod, setpayPayPeriod] = useState(0);

  useEffect(() => {
    if (resource?.total > 0) {
      setTotal(resource.total);
    }
    const salaryPeriod = Number(currentUserInfo?.meta?.salary) / 26.5;

    if (resource?.total + resource?.nonendo_cm > salaryPeriod) {
      setpayPayPeriod(resource?.total + resource?.nonendo_cm);
    } else {
      setpayPayPeriod(salaryPeriod.toFixed(2));
    }
  }, [resource]);

  return (
    <Drawer title={`Total Contribution Margin`} placement="right" closable onClose={handleCloseSidebar} visible={isShow} destroyOnClose key="right">
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <React.Fragment>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "20px",
                marginTop: "1.5rem",
              }}
            >
              <div className="resourceInfo d-flex align-items-end">
                <EditableFormInput
                  label={"Base Pay"}
                  value={currentUserInfo?.meta && currentUserInfo?.meta?.salary && (Number(currentUserInfo?.meta?.salary) / 26.5).toFixed(2)}
                  prefix={"$"}
                  noEditable={true}
                />
              </div>
              <div className="resourceInfo d-flex align-items-end">
                <EditableFormInput label={"OBL CM"} value={total} noEditable={true} prefix={"$"} />
              </div>
              <div className="resourceInfo d-flex align-items-end">
                <EditableFormInput label={"Non-endo CM"} value={total} noEditable={true} prefix={"$"} />
              </div>
              <div className="resourceInfo d-flex align-items-end">
                <EditableFormInput label={"Pay for this period"} value={payPayPeriod} noEditable={true} prefix={"$"} />
              </div>
            </div>
          </React.Fragment>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ContributionMarginSidebar;

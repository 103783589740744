import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-lodash-debounce";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useOutsideDetect } from "../../../common/utils";
import { SearchSVG } from "../icons/SVGIcon";

import { setLocal } from "../../../helpers/Local";
import {
  addPatientSearchHistory,
  getPatientsByQuery,
  get_patient_notes,
  selectPatient,
  setPatientForRecords,
  setPatientHistory,
  setPatientRecordData,
  setPatientSnapshotData,
  setStepData,
} from "../../../store/actions";
import "./style.scss";

const { Search } = Input;

const SearchPatient = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const history = useHistory();
  const patients = useSelector((state) => state.dashboard.patients);

  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeSearch, setActiveSearch] = useState(-1);

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(getPatientsByQuery(search));
    }
  }, [debouncedValue]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const handleSearch = (e) => {
    setSearch(e.target?.value);
    setOpen(true);
  };

  const handleSearchSelect = async (row, index) => {
    if (row?.id) {
      await setLocal("current_patient_id", row?.id);
      await setActiveSearch(index);
      await setSearch(`${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row.date_of_birth).format("MM/DD/YYYY")}`);
      await setOpen(false);
      await dispatch(setStepData("resource", null));
      await dispatch(setPatientRecordData("resource", null));
      await dispatch(setPatientForRecords(row?.id));
      const check = await dispatch(selectPatient(row?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      await dispatch(get_patient_notes(row?.id));
      await dispatch(addPatientSearchHistory(row?.id));
      await dispatch(setPatientHistory(row));
      history.push("/patient-records/clinical");
    }

    setSearch("");
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  return (
    <li ref={ref} className="searchListItem headerListItem">
      <Search
        value={search}
        placeholder="Search Patient"
        className={"headerSearch"}
        onChange={handleSearch}
        style={{
          width: "calc(100% - 120px)",
          height: "100%",
          backgroundColor: "#2c3f48",
          color: "#f2f4f8",
          paddingRight: 16,
        }}
        suffix={<SearchSVG />}
        onFocus={() => setOpen(true)}
      />
      {openPanel && search && rowData?.length > 0 && (
        <div
          ref={ref1}
          className="fullContainer patientSearch__result"
          style={{
            height: `${rowData?.length > 0 ? rowData?.length * 40 : 80}px`,
            left: 60,
          }}
        >
          <div className="tableSection" style={{ height: "100%", position: "relative" }}>
            <div className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
                <div className="tableList">
                  {search &&
                    (rowData || []).map((row, index) => (
                      <div
                        className={`tableItem ${index === activeSearch ? "active" : ""}
                        `}
                        key={`resultTable-${index}`}
                        onClick={() => handleSearchSelect(row, index)}
                      >
                        <div className="td with-icon" style={{ width: "100%" }}>
                          <p
                            style={{
                              paddingLeft: "calc(1.5rem - 1px)",
                            }}
                          >
                            {`${row?.first_name || ""} ${row?.last_name || ""} - ${
                              row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                            }`}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default SearchPatient;

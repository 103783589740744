import { Upload } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { upload_document_setting } from "../../../store/actions";
import ToastMessage from "../Toast";

const CommonDocumentUpload = ({ title, data }) => {
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [isToast, setIsToast] = useState("");
  const dispatch = useDispatch();
  const uploadProps = useMemo(() => {
    return {
      onRemove: (file) => {
        setFile(null);
      },
      beforeUpload: (file) => {
        if (file.type === "application/pdf") {
          const reader = new FileReader();
          reader.onload = (e) => {
            setFileContent(e.target.result);
          };
          reader.readAsDataURL(file);
          setFile(file);
          return false;
        } else {
          alert("Please choose pdf file.");
          return true;
        }
      },
    };
  }, []);

  const handleImageUpload = (e) => {
    const payload = {
      type: "document",
      is_second: false,
      pdf: fileContent,
    };

    onSave(payload);
  };

  const onSave = async (value) => {
    const formData = new FormData();
    if (title == "New Patient Documents") {
      formData.append("category_name", "new_patient_document");
    } else if (title == "Hospital Documents") {
      formData.append("category_name", "in_patient_document");
    } else {
      formData.append("category_name", "out_patient_document");
    }
    formData.append("file", file);
    const postUpload = await dispatch(upload_document_setting(formData));
    if (postUpload) {
      setIsToast(true);
      setTimeout(() => {
        setIsToast(false);
      }, 1000);
    }
  };

  const uploadedFilePath = data ? data.split("/") : [];

  return (
    <>
      {isToast && <ToastMessage type="Setting" msg={"Upload Successfully!"} status={"success"} />}

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="FileImporter" style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              display: file || data ? "inline-block" : "none",
              padding: "0 10px 0 4px",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 20,
              fontSize: "0.625rem",
              color: "#152934",
              fontWeight: 600,
              backgroundColor: "#fff",
              transform: "translate(10px, -8px)",
            }}
          >
            {title || "Documents"}
          </div>
          <Upload
            className="FileImporter__file"
            {...uploadProps}
            style={{
              maxWidth: "190px",
            }}
          >
            {file ? file?.name && file?.name : data ? uploadedFilePath[uploadedFilePath.length - 1] : title || "Choose File..."}
            {!fileContent && (
              <span
                style={{
                  display: "inline-block",
                  position: "absolute",
                  width: "150px",
                  height: "40px",
                  zIndex: 10,
                  top: 0,
                  right: 0,
                }}
              />
            )}
          </Upload>
          <button onClick={(e) => handleImageUpload(e)} className="common-btn blue-btn">
            {fileContent ? "Upload File" : "Import File"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CommonDocumentUpload;

import { Checkbox, Collapse, Drawer, Select, DatePicker, Input } from "antd";
import moment from "moment";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/DataTable/WhiteHeaderTable";
import EditableFormInput from "../../components/EditableText/EditableFormInput";
import EditableMaterialText from "../../components/EditableText/MaterialInput";
import { ArrowDownSVG, PalMessageSVG } from "../../components/icons/SVGIcon";
import { SearchSVG, ExpandSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../components/ScrollBar";
import ImageList from "./ImageList";
import ToastMessage from "../../../common/components/Toast";
import { getLocal, setLocal } from "../../../helpers/Local";
import { validateField, validateSteps } from "../../../services/validations";
import { useDebounce } from "use-lodash-debounce";
import {
  deletePatientDocument,
  emailPatientDocument,
  emailPatientDocumentBulk,
  faxPatientDocumentBulk,
  get_filter_patient_document,
  get_patient_document,
  get_sorted_patient_document,
  printPatientDocument,
  printPatientDocumentBulk,
  reset_fax_scan_document_log,
  sendFaxPatientDocument,
  setGlobalLoading,
  set_default_toast,
  getPatientsByQuery,
  setGlobalToastr,
  put_patient_document,
} from "../../../store/actions";
import { abbreviations, sortCategories } from "./constant";
import { printPatientBulkDate, get_compliance_status, get_compliance_status_multi } from "src/services/api.services";
import TableCustom from "src/common/components/TableComplianceStatus";

const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;

const dataLabel = [
  { name: "Date and Time", width: "calc(30% - 10px)", type: "service_date" },
  { name: "Appointment Type", width: "calc(30% - 10px)", type: "service" },
  { name: "Compliance Measures", width: "calc(50% - 10px)", type: "compliance" },
];

let timer = null;
const PatientChartSideBar = React.forwardRef(({ activeTab, resource, sidebarIcon, isShow, handleCloseSidebar, opener, setResetClick }, ref) => {
  const tableRef = useRef(null);
  const patientResource = useSelector((state) => state.patientRecords.resource || state.clinicProvider.patientResource);
  const documentsData = useSelector((state) => state.common.patientDocumentsData);
  const pcpList = useSelector((state) => state.common.pcpList);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const loadingGetPatientDocument = useSelector((state) => state.common.loadingGetPatientDocument);
  const globalLoading = useSelector((state) => state.common.globalLoading);
  const [dataDoc, setDataDoc] = useState([]);
  const selectedDocument = useSelector((state) => state.clinicProvider.selectedDocument);
  const print_patient_document = useSelector((state) => state.patientRecords.print_patient_document);
  const [isToastr, setIsToastr] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const loading_print = useSelector((state) => state.patientRecords.loading_print_patient_document);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const angio_list = useSelector((state) => state.procedureDetail.angio_list);
  const untrasound_list = useSelector((state) => state.procedureDetail.untrasound_list);
  const isToast = useSelector((state) => state.patientRecords.isToast);
  const user_id = getLocal("cvai-current-user");
  const id_patient = getLocal("current_patient_id");
  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();
  const [faxValue, setFaxValue] = useState(undefined);
  const [emailValue, setEmailValue] = useState(undefined);
  const [pcp, setPCP] = useState(undefined);
  const [printLink, setPrintLink] = useState("");
  const [documentFilters, setFilters] = useState({
    date: undefined,
    category: undefined,
    provider_id: undefined,
  });
  const [typePanel, setTypePanel] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);
  const patient_document_id = getLocal("patient_document_id");
  const [enableError, setEnableError] = useState(false);
  const [enableErrorEmail, setEnableErrorEmail] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedIDRows, setCheckedIDRows] = useState([]);
  const [sortOption, setSortOption] = useState({
    date: "",
    document_category: "",
  });
  const [isOpenFax, setOpenFax] = useState(false);
  const [isOpenEmail, setOpenEmail] = useState(false);
  const [classificationPatient, setClassificationPatient] = useState("");
  const [openSearchClassification, setOpenSearchClassification] = useState(false);
  const debouncedValueClassification = useDebounce(classificationPatient, 400);
  const [classificationPatientId, setClassificationPatientId] = useState("");
  const [classificationCategory, setClassificationCategory] = useState("");
  const [classificationDate, setClassificationDate] = useState(null);
  const ref1 = useRef(null);
  const [offset, setOffset] = useState(0);
  const patients = useSelector((state) => state.dashboard.patients);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeRow, setActiveRow] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [multiCompliance, setMultiCompliance] = useState([]);
  const [checkAppointmentIDRows, setCheckAppointmentIDRows] = useState([]);
  const [dataType, setDataType] = useState([]);

  const toggleOpenFax = (e) => {
    e.stopPropagation();
    setOpenFax(!isOpenFax);
  };
  const toggleOpenEmail = (e) => {
    e.stopPropagation();
    setOpenEmail(!isOpenEmail);
  };

  useEffect(() => {
    if (!loadingGetPatientDocument && Object.keys(documentsData).length > 0) {
      setTotalPages(documentsData.last_page);
      if (documentsData?.data?.length > 0) {
        setDataDoc(documentsData?.data);
      } else {
        setDataDoc([]);
        setTotalPages(0);
      }
    } else {
      setDataDoc([]);
      setTotalPages(0);
    }
  }, [documentsData]);

  useEffect(() => {
    if (isShow) {
      dispatch(setGlobalLoading(loading_print || loadingGetPatientDocument));
    }
  }, [loading_print, loadingGetPatientDocument, isShow]);

  useEffect(() => {
    if (selectedDocument) {
      setReset(true);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (isToast) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [isToast]);

  useEffect(() => {
    if (angio_list && angio_list.length > 0 && untrasound_list && untrasound_list.length > 0) {
      const valueAngio = angio_list.map((r) => {
        return { name: r?.name };
      });
      const valueUntrasound = untrasound_list.map((r) => {
        return { name: r?.name };
      });
      setDataType([...valueAngio, ...valueUntrasound]);
    }
  }, [angio_list, untrasound_list]);

  useEffect(() => {
    if (print_patient_document && Object.keys(print_patient_document).length > 0) {
      checkPrint();
    }
  }, [print_patient_document]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf",
      blob = b64toBlob(b64, contentType),
      blobUrl = URL.createObjectURL(blob);

    var iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  const handleCheckRows = async (idx, item) => {
    const checksID = [...checkedIDRows];
    const currentCheckID = checksID.findIndex((check) => check === item?.id);

    const checks = [...checkedRows];
    const currentCheck = checks.findIndex((check) => check === idx);

    const checksAppointmentID = [...checkAppointmentIDRows];
    const currentCheckAppointmentID = checksAppointmentID.findIndex((check) => check === item?.appointment_id);
    if (currentCheck === -1) {
      checks.push(idx);
      checksID.push(item?.id);
      checksAppointmentID.push(item?.appointment_id);
    } else {
      checks.splice(currentCheck, 1);
      checksID.splice(currentCheckID, 1);
      checksAppointmentID.splice(currentCheckAppointmentID, 1);
    }
    const data = await get_compliance_status_multi(checksAppointmentID);
    if (data) {
      const mixData = data.map((r) => {
        return {
          service_date: r?.appointment_date ? moment(r?.appointment_date).format("MM/DD/YYYY hh:mm A") : "",
          service: r?.service,
          compliance: r?.compliance || [],
        };
      });
      setMultiCompliance([...(mixData || [])]);
    }
    setCheckedRows(checks);
    setCheckedIDRows(checksID);
    setCheckAppointmentIDRows(checksAppointmentID);
  };

  const handleSortOption = (value, categoryOption) => {
    let { category, provider_id, date } = documentFilters;
    setPage(1);
    if (!categoryOption) category = "";
    else category = categoryOption;
    if (!provider_id) provider_id = "";
    if (!date) date = "";
    setSortOption({ ["date"]: value[0], ["document_category"]: value[1] });
    const params = {
      patient_id: id_patient,
      date,
      category,
      provider_id,
      sort_directionDate: value[0],
      sort_directionCate: value[1],
      page: 1,
    };
    dispatch(get_sorted_patient_document(params));
  };

  useEffect(() => {
    if (patient_document_id && patient_document_id != null && patient_document_id != "null") {
      onOpenDocument(null, Number(patient_document_id));
    }
  }, [patient_document_id]);

  const onClickActiveRow = async (document) => {
    if (document?.appointment_id) {
      const data = await get_compliance_status(document?.appointment_id);
      if (data) {
        const mixData = [
          {
            service_date: data?.appointment_date ? moment(data?.appointment_date).format("MM/DD/YYYY hh:mm A") : "",
            service: data?.service,
            compliance: data?.compliance || [],
          },
        ];
        setMultiCompliance([...(mixData || [])]);
      }
    }
  };

  const onOpenDocument = async (document, idCookie) => {
    if (document && document?.id) {
      setLocal("patient_document_id", null);
    }
    setActiveId((document && document?.id) || idCookie);
    if (document && document?.id) {
      setClassificationPatientId(document?.patient_id);
      setClassificationCategory(document?.filename);
      setClassificationDate(document?.document_date);
      setActiveRow(document);
    }
    if ((document && document?.id && document?.id !== activeId) || (idCookie && idCookie !== activeId)) {
      setEmailValue(undefined);
      setFaxValue(undefined);
      dispatch(get_patient_document(document?.id || idCookie));
    }
  };

  const checkPrint = async (value) => {
    dispatch(setGlobalLoading(true));
    const base64 = await pdf2base64(value ? value : print_patient_document?.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    dispatch(setGlobalLoading(false));
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  const deleteApiDocument = async (row, index) => {
    if (row.data && row.data?.id) {
      await dispatch(deletePatientDocument(row.data?.id));
      let { category, provider_id, date } = documentFilters;
      if (!category) category = "";
      if (!provider_id) provider_id = "";
      if (!date) date = "";
      await setMessage("Delete Document Successfully!");
      await setActiveId(null);
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const emptyRows = useMemo(() => {
    if (!tableRef || !tableRef.current) {
      return [];
    }
    const totalRowsCount = Math.floor((tableRef.current.offsetHeight - 50) / 40);
    const emptyRowsCount = totalRowsCount - (documentsData?.data?.length ? documentsData?.data?.length : 0);
    if (emptyRowsCount < 1) {
      return [];
    }
    return new Array(emptyRowsCount).fill({ document: null });
  }, [documentsData]);

  const sendFax = async () => {
    if (checkedIDRows.length > 1) {
      const data = {
        fax_to: faxValue,
      };
      if (!validateSteps(data, [{ value: "fax_to", type: "default" }])) {
        setEnableError(true);
        return;
      } else {
        setEnableError(false);
        await dispatch(
          faxPatientDocumentBulk({
            fax_to: faxValue,
            user_id: user_id,
            document_ids: checkedIDRows,
          })
        );
        setMessage("Send Fax Successfully!");
        setFaxValue("");
      }
    } else {
      if (selectedDocument && selectedDocument?.id) {
        const data = {
          fax_to: faxValue,
        };
        if (!validateSteps(data, [{ value: "fax_to", type: "default" }])) {
          setEnableError(true);
          return;
        } else {
          setEnableError(false);
          const formData = new FormData();
          formData.append("fax_to", faxValue);
          formData.append("user_id", user_id);
          await dispatch(sendFaxPatientDocument(selectedDocument?.id, formData));
          setMessage("Send Fax Successfully!");
          setFaxValue("");
        }
      } else {
        setMessage("Please Click Document You Need!");
        setStatus(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          setMessage(null);
          setStatus(true);
        }, 2000);
      }
    }
  };

  const sendEmail = async () => {
    if (checkedIDRows.length > 1) {
      const data = {
        email: emailValue,
      };
      if (!validateSteps(data, [{ value: "email", type: "email" }])) {
        setEnableErrorEmail(true);
        return;
      } else {
        setEnableErrorEmail(false);
        await dispatch(
          emailPatientDocumentBulk({
            document_ids: checkedIDRows,
            email: emailValue,
          })
        );
        setMessage("Send Email Successfully!");
        setEmailValue("");
      }
    } else {
      if (selectedDocument && selectedDocument?.id) {
        const data = {
          email: emailValue,
        };
        if (!validateSteps(data, [{ value: "email", type: "email" }])) {
          setEnableErrorEmail(true);
          return;
        } else {
          setEnableErrorEmail(false);
          const formData = new FormData();
          formData.append("email_address", emailValue);
          await dispatch(emailPatientDocument(selectedDocument?.id, formData));
          setMessage("Send Email Successfully!");
          setEmailValue("");
        }
      } else {
        setMessage("Please Click Document You Need!");
        setStatus(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          setMessage(null);
          setStatus(true);
        }, 2000);
      }
    }
  };

  const sendPrint = async () => {
    if (checkedIDRows.length > 1) {
      await dispatch(
        printPatientDocumentBulk({
          document_ids: checkedIDRows,
        })
      );
    } else {
      if (selectedDocument && selectedDocument?.id) {
        await dispatch(printPatientDocument(selectedDocument?.id));
      } else {
        setMessage("Please Click Document You Need!");
        setStatus(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          setMessage(null);
          setStatus(true);
        }, 2000);
      }
    }
  };

  const getDocName = (name) => {
    if (!name) return "";
    let docName = name.toLowerCase().replace(/-/g, " - ");
    docName = ` ${docName} `;

    abbreviations.map((abbr) => {
      if (docName.indexOf(abbr) !== -1) {
        docName = docName.replace(abbr, abbr.toUpperCase());
      }
    });

    return docName.trim();
  };

  useEffect(() => {
    if (dataDoc?.length > 0 && !loadingGetPatientDocument) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (patient_document_id && patient_document_id != null && patient_document_id != "null") {
          const activeDoc = dataDoc?.findIndex((el) => el?.id === Number(patient_document_id));
          setActiveIndex(activeDoc);
        } else {
          onOpenDocument(dataDoc[0]);
          setActiveIndex(0);
        }
      }, 500);
    }
  }, [dataDoc]);

  const showToast = () => {
    return <ToastMessage type="Document" status={status ? "success" : "failed"} msg={message ? message : "Update Successfully!"} />;
  };

  const existingProvider = (pcpList && pcpList?.active_pcp ? pcpList?.active_pcp : pcpList || []).find((rp) => {
    if (rp && rp?.id) {
      return patientResource && rp?.id === patientResource.referral_provider;
    }
  });

  const existingPCP = (pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList || []).find((cp) => {
    if (cp && cp?.id) {
      return patientResource && cp?.id === patientResource.primary_care_provider_id;
    }
  });

  const loadMore = async (values) => {
    if (totalPages === 0 || page === totalPages) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loadingGetPatientDocument) {
      let { category, provider_id, date } = documentFilters;
      const success = await dispatch(
        get_filter_patient_document({
          patient_id: id_patient,
          date,
          category,
          provider_id,
          sort_directionDate: sortOption.date,
          sort_directionCate: sortOption.document_category,
          page: page + 1,
        })
      );
      if (success?.data?.length > 0) {
        setPage(page + 1);
      }
    }
  };

  const { first_name, last_name, deceased, cancelled_reason } = patientResource;
  useEffect(() => {
    setClassificationPatient(first_name + " " + last_name);
  }, [patientResource]);

  useEffect(() => {
    if (debouncedValueClassification && openSearchClassification) {
      dispatch(getPatientsByQuery(classificationPatient));
    }
  }, [debouncedValueClassification]);

  const handleSearchSelectClassification = (row) => {
    setClassificationPatient(
      `${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`
    );
    setClassificationPatientId(row?.id);
    setOpenSearchClassification(false);
  };

  const handleTypeClassification = (value) => {
    setClassificationCategory(value);
  };

  const handleAPIClassification = async () => {
    setGlobalLoading(true);
    await dispatch(
      put_patient_document(activeId, {
        patient_id: classificationPatientId,
        category: classificationCategory,
        created_at: classificationDate,
      })
    );
    setGlobalLoading(false);
    dispatch(
      setGlobalToastr({
        header: "New Support Message",
        message: "Your document has been updated successfully",
        type: "success",
      })
    );
  };

  const handleDateClassification = (value) => {
    setClassificationDate(moment(value).format("YYYY/MM/DD hh:mm:ss"));
  };

  const handleClassification = async () => {
    const indexDoc = dataDoc?.findIndex((el) => el?.id === activeRow?.id);
    if (indexDoc !== -1) {
      if (classificationPatientId == "" || classificationPatientId == patientResource?.id) {
        handleAPIClassification();
        dataDoc.splice(indexDoc, 1, {
          ...dataDoc[indexDoc],
          filename: classificationCategory,
          document_date: classificationDate,
        });
        setDataDoc([...dataDoc]);
        setActiveRow(dataDoc[indexDoc]);
      } else {
        handleAPIClassification();
        dataDoc.splice(indexDoc, 1);
        setDataDoc([...dataDoc]);
        setActiveRow(null);
        setTypePanel(null);
        setActiveIndex(-1);
      }
    }
  };

  const handleSearchClassification = (e) => {
    setClassificationPatient(e.target?.value);
    setOpenSearchClassification(true);
  };

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleDateFrom = (value) => {
    setDateTo(undefined);
    if (value == null || value == "Invalid date") {
      setDateFrom(undefined);
    } else {
      setDateFrom(moment(value).format("MM/DD/YYYY"));
    }
  };

  const handleDateTo = (value) => {
    if (value == null || value == "Invalid date") {
      setDateTo(undefined);
    } else {
      setDateTo(moment(value).format("MM/DD/YYYY"));
    }
  };

  function disabledDateFrom(current) {
    return current;
  }

  function disabledDateTo(current) {
    return current && current < moment(dateFrom).subtract(1, "day").endOf("day");
  }

  const handlePrintMedical = async () => {
    if (!globalLoading) {
      dispatch(setGlobalLoading(true));
      if (dateFrom && dateTo) {
        try {
          const urlPrint = await printPatientBulkDate({ date_from: dateFrom, date_to: dateTo, patient_id: id_patient });
          checkPrint(urlPrint?.url);
        } catch (error) {
          setGlobalToastr({
            header: "Alert!",
            message: error,
            type: "error",
          });
          dispatch(setGlobalLoading(false));
        }
      } else {
        dispatch(
          setGlobalToastr({
            header: "Alert!",
            message: "Please Click Date From and Date To",
            type: "error",
          })
        );
        dispatch(setGlobalLoading(false));
      }
    }
  };

  return (
    <Drawer
      title={
        activeTab === "documentation"
          ? !first_name || !last_name
            ? `Document Database`
            : `Document Database - ${first_name} ${last_name} - ID: ${id_patient}`
          : activeTab === "image-viewer-tab"
          ? `Images Database - ${first_name} ${last_name}`
          : `Laboratory Database - ${first_name} ${last_name}`
      }
      placement="right"
      className={`${sideNavStatus ? "wide" : ""} ${deceased == "Yes" || (cancelled_reason != "" && cancelled_reason) ? "red" : ""}`}
      variant="permanent"
      closable={false}
      mask={false}
      visible={isShow}
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}

        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              height: activeTab !== "image-viewer-tab" ? "calc(100% - 70px)" : "auto",
              display: activeTab !== "image-viewer-tab" ? "flex" : "block",
              flexDirection: "column",
            }}
          >
            {activeTab === "documentation" && (
              <div className="resourceInfo d-flex" style={{ flex: 1, width: "100%", flexDirection: "column" }}>
                <div
                  ref={tableRef}
                  style={{
                    height: "calc(100% - 10px)",
                    minHeight: "25vh",
                    width: "100%",
                  }}
                >
                  <DataTable
                    isPatientChart={true}
                    isBorderThick={true}
                    isRmBorders={true}
                    hideHeaderHeight="42px"
                    title="patient_chart_imagelist"
                    isNormal={true}
                    noColPad
                    fullHeight
                    loadMore={loadMore}
                    labels={["Date", "Document Category"]}
                    widths={["35%", "65%"]}
                    activeIndex={activeIndex}
                    customSorting={{
                      sortType: "Document Category",
                      node: (
                        <Select
                          placeholder="Document Category"
                          suffixIcon={<span />}
                          mod="multiple"
                          value={documentFilters.category && documentFilters.category.length > 0 ? documentFilters.category : undefined}
                          onClear={() => {
                            setFilters({ ...sortOption, category: "" });
                            handleSortOption(Object?.values(sortOption));
                          }}
                          onChange={(value) => {
                            setFilters({ ...documentFilters, category: value });
                            handleSortOption(Object?.values(sortOption), value);
                          }}
                          allowClear
                          style={{
                            width: "100%",
                            maxWidth: "100%",
                            backgroundColor: "transparent",
                            borderBottom: 0,
                            boxShadow: "none",
                          }}
                        >
                          {sortCategories?.map((r) => (
                            <Select.Option key={r.category} value={r?.value}>
                              <span style={{ textTransform: "capitalize" }}>{r.value}</span>
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                    }}
                    showIcon={false}
                    columns={[
                      { sortable: true, key: "date" },
                      { sortable: false, key: "filename" },
                    ]}
                    handleSort={(sortOpt) => handleSortOption(sortOpt)}
                    handleClickRow={(row, index) => {
                      onOpenDocument(row.data);
                      onClickActiveRow(row.data);
                    }}
                    rowData={(dataDoc || []).map((item, index) => ({
                      key: `image-list-${item?.id}-${index}`,
                      data: item,
                      date: (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div onClick={(e) => e.stopPropagation()} style={{ width: "15%" }}>
                            <Checkbox
                              checked={checkedRows.includes(index)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleCheckRows(index, item);
                              }}
                            />
                          </div>
                          <div style={{ width: "85%" }}>
                            <p style={{ paddingLeft: "1rem" }}>{moment(item.document_date, "YYYY/MM/DD hh:mm:ss").format("MM/DD/YYYY")}</p>
                          </div>
                        </div>
                      ),
                      filename: (
                        <div
                          style={{
                            textTransform: "capitalize",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.category === "hipaa_form"
                            ? "HIPAA and Privacy Policy Acknowledgement"
                            : item.category.toLowerCase().replace(/-/g, " - ")
                            ? item.category.toLowerCase().replace(/-/g, " - ")
                            : getDocName(item.filename)}
                        </div>
                      ),
                    }))}
                    emptyRows={emptyRows}
                    handleDelete={deleteApiDocument}
                    sorts={[sortOption.date, sortOption.document_category]}
                  />
                </div>
                <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end", marginTop: "0.5rem", minHeight: 17 }}></div>
              </div>
            )}

            {activeTab === "image-viewer-tab" && <ImageList dataType={dataType} haveDetail={true} setResetClickAction={setResetClick} />}

            {activeTab === "laboratory" && (
              <React.Fragment>
                <div
                  className="resourceInfo d-flex"
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PalMessageSVG />
                </div>
              </React.Fragment>
            )}
            {activeTab !== "image-viewer-tab" && (
              <Collapse
                activeKey={typePanel}
                onChange={(value) => {
                  setTypePanel(value);
                }}
                accordion
              >
                <Panel header="Fax" key="fax">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        position: "relative",
                        maxWidth: "400px",
                      }}
                      onClick={() => setOpenFax(false)}
                    >
                      <EditableFormInput
                        handleChange={(value) => {
                          setFaxValue(value);
                        }}
                        value={faxValue}
                        label={"Fax No"}
                        isErrorTextSpacing={true}
                        mask={"phone"}
                        required={true}
                        enableErrorText={enableError}
                        isError={validateField("fax_to", faxValue)}
                        helperText={"Fax No is Required!"}
                      />

                      {((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                        <div
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            width: "40px",
                            height: "40px",
                            zIndex: 20,
                            top: "2px",
                            right: "2px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: isOpenFax ? "rotate(180deg)" : "rotate(0deg)",
                          }}
                          className="dropdown-button"
                          onClick={toggleOpenFax}
                        >
                          <ArrowDownSVG />
                        </div>
                      )}

                      {isOpenFax && ((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 20,
                            left: 0,
                            right: 0,
                            top: "42px",
                            background: "#fff",
                            border: "1px solid #ddd",
                          }}
                        >
                          {existingPCP && existingPCP.fax_number && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                borderBottom: "1px solid #ddd",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "pcp" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingPCP.fax_number);
                                if (existingPCP.email) {
                                  setEmailValue(existingPCP.email);
                                }
                                setPCP("pcp");
                                setOpenFax(false);
                              }}
                            >
                              PCP
                            </div>
                          )}
                          {existingProvider && existingProvider.fax_number && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "referring_provider" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingProvider.fax_number);
                                if (existingProvider.email) {
                                  setEmailValue(existingProvider.email);
                                }
                                setPCP("referring_provider");
                                setOpenFax(false);
                              }}
                            >
                              Referring Provider
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      className="common-btn blue-btn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "8px",
                      }}
                      onClick={sendFax}
                    >
                      <span>Send</span>
                      <span>+</span>
                    </button>
                  </div>
                </Panel>
                <Panel header="Reclassification" key="classification">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                      <div className="classificationPatient" style={{ position: "relative" }}>
                        <div className="patientSearch__filter" style={{ width: "190px" }}>
                          <div ref={ref} className="patientSearch__filter-search" style={{ borderRight: "1px solid #dfe3e6" }}>
                            <Search
                              value={classificationPatient}
                              placeholder="Search Patient"
                              onChange={(value) => {
                                handleSearchClassification(value);
                              }}
                              style={{ width: "100%" }}
                              suffix={<SearchSVG />}
                              onFocus={() => setOpenSearchClassification(true)}
                            />
                          </div>
                        </div>
                        {openSearchClassification && classificationPatient && rowData?.length > 0 && tableRows.length > 0 && (
                          <div ref={ref1} className="fullContainer patientSearch__result">
                            <div
                              className="tableSection"
                              style={{
                                height: "100%",
                                maxHeight: "200px",
                                position: "relative",
                              }}
                            >
                              <div className="tableContainer">
                                <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
                                  <div className="tableList">
                                    {rowData?.map((row, index) => (
                                      <div
                                        className={`tableItem ${index === activeSearch ? "active" : ""}`}
                                        key={`resultTable-${index}`}
                                        onClick={() => handleSearchSelectClassification(row, index)}
                                      >
                                        <div className="td with-icon" style={{ width: "100%" }}>
                                          <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                                            row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                                          }`}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </ScrollWrapper>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Select
                        placeholder="Categorization"
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        onChange={handleTypeClassification}
                        value={classificationCategory}
                        style={{
                          flex: 1,
                          width: "200px",
                          border: "1px solid #dfe3e6",
                          marginRight: 6,
                          borderBottom: 0,
                          boxShadow: "none",
                          backgroundColor: "#fff",
                          alignItems: "center",
                          height: "2.5rem",
                          maxHeight: "none",
                          borderBottomColor: "#dfe3e6",
                          borderBottomWidth: "1px",
                          borderBottomStyle: "solid",
                        }}
                      >
                        {sortCategories?.map((r) => (
                          <Option key={r.category} value={r.value}>
                            <span style={{ textTransform: "capitalize" }}>{r.value}</span>
                          </Option>
                        ))}
                      </Select>

                      <DatePicker
                        format={"MM/DD/YYYY"}
                        placeholder={"Date"}
                        style={{ width: "180px" }}
                        suffixIcon={<ExpandSVG />}
                        value={classificationDate ? moment(classificationDate) : null}
                        onChange={handleDateClassification}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <button
                        className="common-btn blue-btn"
                        onClick={handleClassification}
                        style={{ marginLeft: "7px", border: 0, marginTop: "10px" }}
                      >
                        Reclassify
                      </button>
                    </div>
                  </div>
                </Panel>
                <Panel header="E-Mail" key="e-mail">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        position: "relative",
                        maxWidth: "400px",
                      }}
                      onClick={() => setOpenEmail(false)}
                    >
                      <EditableMaterialText
                        reset={reset}
                        handleChange={(value) => {
                          setEmailValue(value);
                        }}
                        value={emailValue}
                        label={"E-Mail"}
                        required={true}
                        enableErrorText={enableErrorEmail}
                        isError={validateField("email", emailValue)}
                        helperText={"E-Mail is Required!"}
                      />

                      {((existingPCP && existingPCP.email) || (existingProvider && existingProvider.email)) && (
                        <div
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            width: "40px",
                            height: "40px",
                            zIndex: 20,
                            top: "2px",
                            right: "2px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: isOpenEmail ? "rotate(180deg)" : "rotate(0deg)",
                          }}
                          className="dropdown-button"
                          onClick={toggleOpenEmail}
                        >
                          <ArrowDownSVG />
                        </div>
                      )}

                      {isOpenEmail && ((existingPCP && existingPCP.email) || (existingProvider && existingProvider.email)) && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 20,
                            left: 0,
                            right: 0,
                            top: "42px",
                            background: "#fff",
                            border: "1px solid #ddd",
                          }}
                        >
                          {existingPCP && existingPCP.email && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                borderBottom: "1px solid #ddd",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "pcp" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setEmailValue(existingPCP.email);
                                if (existingPCP.fax_number) {
                                  setFaxValue(existingPCP.fax_number);
                                }
                                setPCP("pcp");
                                setOpenEmail(false);
                              }}
                            >
                              PCP
                            </div>
                          )}
                          {existingProvider && existingProvider.email && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "referring_provider" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setEmailValue(existingProvider.email);
                                if (existingProvider.fax_number) {
                                  setFaxValue(existingProvider.fax_number);
                                }
                                setPCP("referring_provider");
                                setOpenEmail(false);
                              }}
                            >
                              Referring Provider
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      className="common-btn blue-btn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "8px",
                      }}
                      onClick={sendEmail}
                    >
                      <span>Send</span>
                      <span>+</span>
                    </button>
                  </div>
                </Panel>
                <Panel header="Print" key="print">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    onClick={sendPrint}
                  >
                    <button
                      className="common-btn blue-btn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "8px",
                      }}
                      onClick={sendFax}
                    >
                      <span>Print</span>
                      <span>+</span>
                    </button>
                  </div>
                </Panel>
                <Panel header="Medical Record" key="medicalRecord">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <div className="resourceInfo d-flex" style={{ gap: 10 }}>
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        placeholder={"Date from"}
                        style={{ width: "180px" }}
                        suffixIcon={<ExpandSVG />}
                        value={dateFrom ? moment(dateFrom) : undefined}
                        onChange={handleDateFrom}
                      />
                      <DatePicker
                        format={"MM/DD/YYYY"}
                        placeholder={"Date to"}
                        style={{ width: "180px" }}
                        suffixIcon={<ExpandSVG />}
                        value={dateTo ? moment(dateTo) : undefined}
                        onChange={handleDateTo}
                        disabledDate={disabledDateTo}
                        disabled={dateFrom ? false : true}
                      />
                    </div>
                    <button
                      className="common-btn blue-btn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginLeft: "8px",
                      }}
                      onClick={handlePrintMedical}
                    >
                      <span>Print</span>
                      <span>+</span>
                    </button>
                  </div>
                </Panel>
                <Panel header="Compliance Status" key="complianceStatus">
                  <ScrollWrapper css="x-hidden" styleProps={{ minHeight: 150 }}>
                    <TableCustom dataCompliance={multiCompliance} dataLabel={dataLabel} />
                  </ScrollWrapper>
                </Panel>
              </Collapse>
            )}
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default PatientChartSideBar;

import { Collapse, Drawer, Icon, Input, Select } from "antd";
import moment from "moment";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";

import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { SortingArrowIcon } from "../../../common/components/icons/SortingIcon";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ToastMessage from "../../../common/components/Toast";
import { useOutsideDetect } from "../../../common/utils";
import { getLocal } from "../../../helpers/Local";
import { validateField, validateSteps } from "../../../services/validations";
import {
  clearScan,
  deleteScanLog,
  detailScanLog,
  emailScan,
  getPatientsByQuery,
  getScanLogs,
  getSortedScanLogs,
  get_ocr_category,
  printScan,
  reset_fax_scan_document_log,
  saveScanToDocument,
  sendScan,
  setGlobalToastr,
  set_default_toast,
} from "../../../store/actions";

const { Option } = Select;
const { Panel } = Collapse;

let per_page = 25;
const ScanningSidebar = React.forwardRef(({ resource, isShow, activeTab, handleCloseSidebar }, ref) => {
  const title = activeTab === "incoming-scan" ? "Incoming Scan History" : "Outgoing Scan History";
  const dispatch = useDispatch();

  const listPatients = useSelector((state) => state.common.list_patients);

  const scan_logs = useSelector((state) => state.patientRecords.scan_logs);
  const scanLoading = useSelector((state) => state.patientRecords.loadingScanLogs);
  const print_scan = useSelector((state) => state.patientRecords.print_scan);
  const detail_scan_logs = useSelector((state) => state.patientRecords.detail_scan_logs);
  const user_id = getLocal("cvai-current-user");
  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [tableLoad, setTableLoad] = useState([]);
  const [reset, setReset] = useState(true);
  const [sender, setSender] = useState([]);
  const [recipi, setRecipi] = useState([]);
  const [patient, setPatient] = useState([]);
  const [type, setType] = useState([]);
  const [printLink, setPrintLink] = useState("");
  const [scan_id, setScan_id] = useState(undefined);
  const [selectRecipi, setSelectRecipi] = useState(null);
  const [selectSender, setSelectSender] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const [selectPatient, setSelectPatient] = useState(null);
  const [selectDateIncom, setSelectDateIncom] = useState(null);
  const [scanValue, setScanValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState({});
  const ref1 = useRef(null);
  const patients = useSelector((state) => state.dashboard.patients);
  const [activeSearch, setActiveSearch] = useState(-1);
  const debouncedValue = useDebounce(search, 400);
  const [offset, setOffset] = useState(0);
  const [typeComponent, setTypeComponent] = useState({});
  const [pcp, setPCP] = useState(undefined);
  const [referring, setReferring] = useState(undefined);

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const toastAction = useSelector((state) => state.dashboard.toastAction);
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [enableErrorEmail, setEnableErrorEmail] = useState(false);
  const orc_category = useSelector((state) => state.setting.orc_category);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [sortOption, setSortOption] = useState({
    outgoing: {
      date: "",
      patient_name: "",
      type: "",
    },
    incoming: {
      date: "",
      patient_name: "",
      type: "",
    },
  });

  const [types, setCategories] = useState({ outgoing: "", incoming: "" });
  const [patientId, setPatientId] = useState("");

  useOutsideDetect(ref1, openPanel, setOpen);
  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(getPatientsByQuery(search && search[activeRow] && search[activeRow].value));
    }
  }, [debouncedValue]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  useEffect(() => {
    if (activeRow) {
      setScanValue(undefined);
      setEmailValue(undefined);
      setOpen(false);
    }
  }, [activeRow]);

  const fetchApiSelect = async () => {
    await dispatch(get_ocr_category());
  };

  useEffect(() => {
    fetchApiSelect();
  }, []);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const tableRef = useRef(null);

  const emptyRows = useMemo(() => {
    if (!tableRef || !tableRef.current) {
      return [];
    }
    const totalRowsCount = Math.floor(tableRef.current.offsetHeight / 40);
    const emptyRowsCount = totalRowsCount - tableLoad.length;
    if (emptyRowsCount < 1) {
      return [];
    }
    return new Array(emptyRowsCount).fill(0);
  }, [tableLoad]);

  const fetchAPI = () => {
    dispatch(
      getScanLogs({
        direction: activeTab === "incoming-scan" ? "incoming" : "outgoing",
        patient_id: "",
        user_id,
        per_page,
        page,
      })
    );
  };

  useEffect(() => {
    if (tableLoad && tableLoad.length > 0 && tableLoad.length <= 25) {
      handleRow(0, tableLoad[0].id)();
    }
  }, [tableLoad]);

  const handleRow = (index, id) => async () => {
    if (activeRow != index) {
      setActiveRow(index);
      setScan_id(id);
      setActiveDelete(-1);
      dispatch(detailScanLog(id));
    }
  };
  const handleSortOption = (key, type, value) => {
    let dateSort = "";
    let patientSort = "";
    setPage(1);
    setIsEnd(false);
    if (type == "date") {
      dateSort = value;
      patientSort = "";
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["patient_name"]: "",
        },
      });
    } else if (type == "patient_name") {
      dateSort = "";
      patientSort = value;
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["date"]: "",
        },
      });
    }
    dispatch(
      getSortedScanLogs({
        patient_id: "",
        user_id,
        direction: key,
        dateSort,
        patientSort,
        per_page,
        page: 1,
      })
    );
  };

  const fetchApiDetail = () => {
    if (!scan_logs || !scan_logs.length) {
      dispatch(clearScan());
    }
  };

  const scanSend = () => {
    if (scan_id) {
      const data = {
        fax_to: scanValue,
      };

      setEnableError(false);
      const formData = new FormData();
      formData.append("fax_to", scanValue);
      formData.append("user_id", user_id);
      formData.append("pcp", pcp);
      formData.append("rp", referring);
      dispatch(sendScan(scan_id, formData));
      setMessage("Send Fax Successfully!");
      setScanValue("");
    } else {
      setMessage("Please Click Scan You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const sendEmail = () => {
    if (scan_id) {
      const data = {
        email: emailValue,
      };
      if (!validateSteps(data, [{ value: "email", type: "email" }])) {
        setEnableErrorEmail(true);
        return;
      } else {
        setEnableErrorEmail(false);
        const formData = new FormData();
        formData.append("email_address", emailValue);
        dispatch(emailScan(scan_id, formData));
        setMessage("Send Email Successfully!");
        setEmailValue("");
      }
    } else {
      setMessage("Please Click Scan You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const saveFaxToDocumentRow = async (id, typeId) => {
    const formData = new FormData();
    if (typeComponent && typeComponent[typeId] && typeComponent[typeId].id) {
      formData.append("type", typeComponent[typeId].id);
      if (search && search[id] && search[id].id) {
        formData.append("patient_id", search[id].id);
      }
      const save = await dispatch(saveScanToDocument(scan_id, formData));
      if (save) {
        dispatch(
          setGlobalToastr({
            header: "Incoming Scan History",
            message: "Save Fax to successfully!",
            type: "success",
          })
        );
      }
    }
  };

  const sendPrint = async () => {
    if (scan_id) {
      dispatch(printScan(scan_id));
    } else {
      setMessage("Please Click Scan You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_scan.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    await dispatch(reset_fax_scan_document_log());
    setPrintLink(base64);
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  useEffect(() => {
    if (print_scan && Object.keys(print_scan).length > 0) {
      checkPrint();
    }
  }, [print_scan]);

  useEffect(() => {
    if (detail_scan_logs) {
      setReset(true);
    }
  }, [detail_scan_logs]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleSearch = (e, index) => {
    setSearch({ ...search, [index]: { value: e.target?.value } });
    setOpen(true);
  };
  const selectTypeComponent = (value, id) => {
    setTypeComponent({
      ...typeComponent,
      [`${id}`]: { value: value, id: value },
    });
  };
  const handleSearchSelect = (row) => {
    setSearch({
      ...search,
      [activeRow]: {
        value: `${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`,
        id: row?.id,
      },
    });
    setOpen(false);
  };

  const DeleteApi = async () => {
    if (scan_id) {
      await dispatch(deleteScanLog(scan_id));
      setMessage("Delete Scan Successfully!");
      fetchAPI();
      if (scan_id === activeDelete) {
        setScan_id(-1);
      }
      setActiveDelete(-1);
    } else {
      setMessage("Please Click Scan You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  useEffect(() => {
    if (toastAction) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [toastAction]);

  const clickAccept = () => {
    if (
      activeRow >= 0 &&
      scan_id &&
      typeComponent &&
      typeComponent[scan_id] &&
      typeComponent[scan_id]["id"] &&
      search &&
      search[activeRow] &&
      search[activeRow]["value"]
    ) {
      saveFaxToDocumentRow(activeRow, scan_id);
    } else {
      alert("please set all info");
    }
  };

  useEffect(() => {
    const arrSender = [];
    const arrRecipi = [];
    const arrPatient = [];
    const arrType = [];
    if (scan_logs && scan_logs.length > 0 && tableLoad.length == 0) {
      setTableLoad([...scan_logs]);
    }
    if (scan_logs) {
      fetchApiDetail();
      var state = {};
      var arrTypeComponent = {};
      scan_logs.map((r, index) => {
        if (r.type) {
          arrTypeComponent = {
            ...arrTypeComponent,
            [`${r?.id}`]: {
              value: r.type?.category,
              id: r.type?.id,
            },
          };
        }
        if (r.patient) {
          state = {
            ...state,
            [index]: {
              value: `${r?.patient?.first_name || ""} ${r?.patient?.last_name || ""}`,
              id: r.patient?.id,
            },
          };
          setSearch(state);
        }
        if (r.user && r.user?.id) {
          if (arrSender && !arrSender.some((check) => check?.id && check?.id === r.user?.id)) {
            arrSender.push(r.user);
          }
        }
        if (r.pcp && r.pcp?.id) {
          if (arrRecipi && !arrRecipi.some((check) => check?.id && check?.id === r.pcp?.id)) {
            arrRecipi.push(r.pcp);
          }
        }
        if (r.patient && r.patient?.id) {
          if (arrPatient && !arrPatient.some((check) => check?.id && check?.id === r.patient?.id)) {
            arrPatient.push(r.patient);
          }
        }
        if (r.type) {
          if (arrType && !arrType.some((check) => check === r.type)) {
            arrType.push(r.type);
          }
        }
      });
    }
    setTypeComponent(arrTypeComponent);
    setType(arrType);
    setSender(arrSender);
    setRecipi(arrRecipi);
    setPatient(arrPatient);
  }, [scan_logs]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf",
      blob = b64toBlob(b64, contentType),
      blobUrl = URL.createObjectURL(blob);

    var iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    fetchAPI();
    setActiveRow(-1);
  }, [activeTab]);
  // incomming
  useEffect(() => {
    let filterScan = [];
    if (selectType && !selectPatient && !selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return ` ${items.type?.category} `.toLowerCase().includes(` ${selectType} `.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (selectPatient && !selectType && !selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return ` ${items.patient_id} `.toLowerCase().includes(` ${selectPatient} `.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (!selectPatient && !selectType && selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return `${items.created_at}`.toLowerCase().includes(`${selectDateIncom}`.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (selectPatient && selectType && !selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.type?.category} `.toLowerCase().includes(` ${selectType} `.toLowerCase()) &&
          ` ${items.patient_id} `.toLowerCase().includes(` ${selectPatient} `.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (selectPatient && !selectType && selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return (
          `${items.created_at}`.toLowerCase().includes(`${selectDateIncom}`.toLowerCase()) &&
          ` ${items.patient_id} `.toLowerCase().includes(` ${selectPatient} `.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (!selectPatient && selectType && selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.type?.category} `.toLowerCase().includes(` ${selectType} `.toLowerCase()) &&
          `${items.created_at}`.toLowerCase().includes(`${selectDateIncom}`.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (selectType && selectPatient && selectDateIncom) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.type?.category} `.toLowerCase().includes(` ${selectType} `.toLowerCase()) &&
          ` ${items.patient_id} `.toLowerCase().includes(` ${selectPatient} `.toLowerCase()) &&
          `${items.created_at}`.toLowerCase().includes(`${selectDateIncom}`.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (!selectType && !selectPatient && !selectDateIncom) {
      setTableLoad(scan_logs);
    }
  }, [selectType, selectPatient, selectDateIncom]);
  // outcoming
  useEffect(() => {
    let filterScan = [];
    if (selectRecipi && !selectSender && !selectDate) {
      filterScan = scan_logs.filter((items) => {
        return ` ${items.pcp_id} `.toLowerCase().includes(` ${selectRecipi} `.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (selectSender && !selectRecipi && !selectDate) {
      filterScan = scan_logs.filter((items) => {
        return ` ${items.user?.id} `.toLowerCase().includes(` ${selectSender} `.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (!selectSender && !selectRecipi && selectDate) {
      filterScan = scan_logs.filter((items) => {
        return `${items.created_at}`.toLowerCase().includes(`${selectDate}`.toLowerCase());
      });
      setTableLoad(filterScan);
    } else if (selectSender && selectRecipi && !selectDate) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `.toLowerCase().includes(` ${selectRecipi} `.toLowerCase()) &&
          ` ${items.user?.id} `.toLowerCase().includes(` ${selectSender} `.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (selectSender && !selectRecipi && selectDate) {
      filterScan = scan_logs.filter((items) => {
        return (
          `${items.created_at}`.toLowerCase().includes(`${selectDate}`.toLowerCase()) &&
          ` ${items.user?.id} `.toLowerCase().includes(` ${selectSender} `.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (!selectSender && selectRecipi && selectDate) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `.toLowerCase().includes(` ${selectRecipi} `.toLowerCase()) &&
          `${items.created_at}`.toLowerCase().includes(`${selectDate}`.toLowerCase())
        );
      });
      setTableLoad(filterScan);
    } else if (selectRecipi && selectSender && selectDate) {
      filterScan = scan_logs.filter((items) => {
        return (
          ` ${items.pcp_id} `.toLowerCase().includes(` ${selectRecipi} `.toLowerCase()) &&
          ` ${items.user?.id} `.toLowerCase().includes(` ${selectSender} `.toLowerCase()) &&
          `${items.created_at}`.includes(`${selectDate}`)
        );
      });
      setTableLoad(filterScan);
    } else if (!selectRecipi && !selectSender && !selectDate) {
      setTableLoad(scan_logs);
    }
  }, [selectRecipi, selectSender, selectDate]);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const t = scrollTop / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !scanLoading && !isEnd) {
      const success = await dispatch(
        getScanLogs({
          direction: activeTab === "incoming-scan" ? "incoming" : "outgoing",
          patient_id: "",
          user_id,
          per_page,
          page: page + 1,
        })
      );
      if (success && success.length > 0) {
        setTableLoad([...tableLoad, ...success]);
        setPage(page + 1);
      } else {
        setIsEnd(true);
      }
    }
  };

  const showToast = () => {
    return <ToastMessage type="Scaning Module" status={status ? "success" : "failed"} msg={message ? message : "Update Successfully!"} />;
  };

  return (
    <Drawer
      title={title}
      placement="right"
      variant="permanent"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent fix-icon"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingBottom: "2rem",
              marginTop: "1.5rem",
              height: "calc(100% - 70px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {activeTab === "incoming-scan" && (
              <div
                className="resourceInfo d-flex sortableHeader"
                style={{
                  alignItems: "flex-end",
                  background: "#f4f7fb",
                  borderBottom: "1px solid #dfe3e6",
                }}
              >
                <div
                  className={`th sortable ${!sortOption.incoming.date ? "hide-icon" : ""}`}
                  style={{
                    width: "20%",
                    minWidth: "20%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Date
                  </b>
                  <SortingArrowIcon
                    sort={sortOption.incoming.date}
                    handleSortASC={() => handleSortOption("incoming", "date", "asc")}
                    handleSortDESC={() => handleSortOption("incoming", "date", "desc")}
                  />
                </div>
                <div
                  className={`th sortable ${!sortOption.incoming.patient_name ? "hide-icon" : ""}`}
                  style={{
                    width: "38%",
                    maxWidth: "38%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <b
                    style={{
                      width: "100%",
                      minWidth: "20%",
                      borderBottom: "none",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    Patient Name
                  </b>

                  <SortingArrowIcon
                    sort={sortOption.incoming.patient_name}
                    handleSortASC={() => handleSortOption("incoming", "patient_name", "asc")}
                    handleSortDESC={() => handleSortOption("incoming", "patient_name", "desc")}
                  />
                </div>
                <div
                  className={`th sortable hide-icon`}
                  style={{
                    width: "calc(42%)",
                    maxWidth: "calc(42%)",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Categorization"
                    suffixIcon={<span />}
                    mod="multiple"
                    allowClear={true}
                    value={types.outgoing && types.outgoing.length > 0 ? types.outgoing : undefined}
                    onClear={() => {
                      setCategories({ ...types, outgoing: "" });
                    }}
                    onChange={(value) => {
                      // selectTypeComponent({ ...types, outgoing: value });
                      setCategories({ ...types, outgoing: value });
                    }}
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      backgroundColor: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                  >
                    {orc_category.map((r) => (
                      <Option key={r.category} value={r.category}>
                        <span style={{ textTransform: "capitalize" }}>{r.category.toLowerCase()}</span>
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="resourceInfo d-flex" style={{ flex: 1, alignItems: "flex-start" }}>
              {activeTab === "incoming-scan" && (
                <div className="tableList" ref={tableRef} style={{ width: "100%", height: "calc(100% - 50px)", minHeight: "25vh" }}>
                  <ScrollWrapper css="no-padding x-hidden" onScrollFrame={(values) => loadMore(values)}>
                    {(tableLoad || []).map((item, index) => (
                      <div
                        className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                        onClick={handleRow(index, item?.id)}
                        key={`fax-${index}`}
                      >
                        <div className="tableItemField" style={{ width: "20%", minWidth: "20%" }}>
                          <p>{moment(item.created_at).format("MM/DD/YYYY")}</p>
                        </div>
                        <div className="tableItemField" style={{ width: "38%" }}>
                          <div style={{ paddingLeft: "0px", display: activeRow !== index && "none" }}>
                            <div className="patientSearch">
                              <div className="patientSearch__filter">
                                <div className="patientSearch__filter-search">
                                  <Input
                                    style={{
                                      width: "100%",
                                      textTransform: "capitalize !important",
                                    }}
                                    value={search && search[index] && search[index].value}
                                    placeholder={"Patient Name"}
                                    onChange={(value) => {
                                      handleSearch(value, index);
                                    }}
                                    onFocus={() => {
                                      setActiveRow(index);
                                      if (search && search[index] && search[index].value && search[index].value.length > 0) {
                                        setOpen(true);
                                      } else {
                                        setOpen(false);
                                      }
                                    }}
                                    allowClear={false}
                                  />
                                </div>
                              </div>
                              {openPanel && activeRow === index && (
                                <div
                                  style={{
                                    zIndex: 10,
                                    position: "absolute",
                                    width: 350,
                                    height: 400,
                                  }}
                                  key={`resultTable-${title}-${index}`}
                                  ref={ref1}
                                  className="fullContainer patientSearch__result"
                                >
                                  <div
                                    className="tableSection"
                                    style={{
                                      height: "100%",
                                      maxHeight: "201px",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="tableContainer">
                                      <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
                                        <div className="tableList">
                                          {search &&
                                            (rowData || []).map((row, index) => (
                                              <div
                                                className={`tableItem ${index === 0 ? "no-border" : ""} ${index === activeSearch ? "active" : ""}`}
                                                key={`resultTable-${index}`}
                                                onClick={() => handleSearchSelect(row)}
                                              >
                                                <div className="td with-icon icon-big" style={{ width: "100%" }}>
                                                  <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                                                    row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                                                  }`}</p>
                                                </div>
                                              </div>
                                            ))}
                                        </div>
                                      </ScrollWrapper>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <p style={{ display: activeRow !== index ? "block" : "none" }}>{search && search[index] && search[index].value}</p>
                        </div>
                        <div className="tableItemField" style={{ width: "42%" }}>
                          <Select
                            placeholder="Categorization Report"
                            suffixIcon={<ExpandSVG />}
                            value={typeComponent && typeComponent[`${item?.id}`] && typeComponent[`${item?.id}`].value}
                            onChange={(value) => {
                              selectTypeComponent(value, item?.id);
                            }}
                            allowClear={false}
                            style={{
                              flex: 1,
                              width: "100%",
                              maxWidth: "calc(100% - 54px)",
                              marginRight: 6,
                              borderBottom: 0,
                              boxShadow: "none",
                              display: activeRow !== index && "none",
                              backgroundColor:
                                typeComponent && typeComponent[`${item?.id}`] && typeComponent[`${item?.id}`].value ? "#fff" : "#ff000030",
                            }}
                          >
                            {orc_category.map((r) => (
                              <Option key={r.category} value={r.category}>
                                <span style={{ textTransform: "capitalize" }}>{r.category.toLowerCase()}</span>
                              </Option>
                            ))}
                          </Select>

                          <p
                            style={{
                              width: "100%",
                              maxWidth: "calc(100% - 48px)",
                              display: activeRow !== index ? "block" : "none",
                            }}
                          >
                            {typeComponent && typeComponent[`${item?.id}`] && typeComponent[`${item?.id}`]["value"]}
                          </p>

                          <Icon className="action" type="cloud-upload" onClick={() => saveFaxToDocumentRow(index, item?.id)} />

                          <DeleteIcon
                            extraStyle={{
                              marginLeft: "6px",
                            }}
                            icon="can"
                            selected={activeDelete === item?.id}
                            handleClick={(e) => {
                              e.stopPropagation();
                              if (activeDelete !== item?.id) {
                                setActiveDelete(item?.id);
                              } else {
                                DeleteApi();
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}

                    {emptyRows.map((item, index) => (
                      <div className="tableItem" key={`chart-empty-table-${index}`}>
                        <div className="tableItemField" style={{ width: "30%", minWidth: "30%" }} />
                        <div className="tableItemField" style={{ width: "35%" }} />
                        <div className="tableItemField" style={{ width: "35%", paddingRight: "10px" }} />
                      </div>
                    ))}
                  </ScrollWrapper>
                </div>
              )}
            </div>

            <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end", marginTop: "0.5rem" }} />

            <Collapse accordion style={{ marginTop: "2.5rem" }}>
              <Panel header="Fax" key="fax">
                <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <EditableFormInput
                      reset={reset}
                      handleChange={(value) => {
                        setScanValue(value);
                      }}
                      value={scanValue}
                      label={"Fax No"}
                      mask={"phone"}
                      required={true}
                      enableErrorText={enableError}
                      isError={validateField("fax_to", scanValue)}
                      helperText={"Fax No is Required!"}
                    />
                  </div>
                  <button onClick={scanSend} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="E-Mail" key="e-mail">
                <div
                  className="resourceInfo d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <EditableMaterialText
                      reset={reset}
                      handleChange={(value) => {
                        setEmailValue(value);
                      }}
                      value={emailValue}
                      label={"E-Mail"}
                      required={true}
                      enableErrorText={enableErrorEmail}
                      isError={validateField("email", emailValue)}
                      helperText={"E-Mail is Required!"}
                    />
                  </div>
                  <button className="common-btn blue-btn" onClick={sendEmail} style={{ marginLeft: "8px", border: 0 }}>
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="Print" key="print">
                <div className="resourceInfo d-flex" style={{ alignItems: "center", justifyContent: "flex-end" }} onClick={sendPrint}>
                  <button className="common-btn" style={{ border: 0 }}>
                    Print
                  </button>
                </div>
              </Panel>
            </Collapse>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ScanningSidebar;

import React, { useState, useEffect } from "react";
import moment from "moment";
import TopBar from "../../../common/components/SubTopBar";
import DataTable from "../../../common/components/DataTable/DataTable";
import { getCommunicationRecords, setGlobalLoading } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "antd";

const PatientReminders = () => {
  const communications = useSelector((state) => state.patientRecords.communications);
  const loadingCommunications = useSelector((state) => state.patientRecords.loadingCommunications);
  const countSuccess = useSelector((state) => state.patientRecords.count_success);
  const countFailure = useSelector((state) => state.patientRecords.count_failure);
  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (communications && communications.length > 0) {
      if (tableLoad.length === 0) {
        setTableLoads([...communications]);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [communications]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingCommunications));
  }, [loading, loadingCommunications]);

  const handleRow = (row, index) => () => {};

  const fetchAPI = () => {
    dispatch(getCommunicationRecords({ paging: 1 }));
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        const listUpdate = await dispatch(getCommunicationRecords({ paging: page + 1 }));
        if (listUpdate && listUpdate.data && listUpdate.data?.length > 0) {
          setPage(page + 1);
          setTableLoads([...tableLoad, ...listUpdate.data]);
          setLoading(false);
        } else {
          setLoading(false);
          setOffLoad(false);
        }
      }
    }
  };

  const getStatus = () => {
    return (
      <div className="statusHealth">
        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
        {countSuccess} <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {countFailure}
      </div>
    );
  };

  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Patient Reminders" subStatus={getStatus()} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer has-no-sidebar">
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title="patient_reminders"
                    isChangeColor={true}
                    isPatientReminder={true}
                    tableWidth={928}
                    labels={["", "Date", "Appointment Type", "Communication Method", "Result"]}
                    widths={["2%", "20%", "40%", "20%", "20%"]}
                    columns={[
                      { sortable: false, key: "icon" },
                      { sortable: false, key: "date" },
                      { sortable: false, key: "type" },
                      { sortable: false, key: "method" },
                      { sortable: false, key: "result" },
                    ]}
                    sorts={["", "", "", ""]}
                    rowData={(tableLoad || []).map((row, index) => ({
                      id: row?.id,
                      icon: (
                        <div className="d-flex">
                          {row?.attributes.result === "Success" ? (
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          ) : (
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          )}
                        </div>
                      ),
                      date: row?.attributes && row?.attributes["created-at"] && moment(row?.attributes["created-at"]).format("MM/DD/YYYY hh:mm A"),
                      type: row?.attributes && row?.attributes.reason,
                      method: row?.attributes && row?.attributes.method,
                      result: row?.attributes && row?.attributes.result,
                    }))}
                    disableIcon={true}
                    fetchList={fetchAPI}
                    loadMore={loadMore}
                    handleClickRow={handleRow}
                    defaultActive={-1}
                    disableDelete={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientReminders;

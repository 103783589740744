import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import { getLocal } from "../../helpers/Local";
import CookieConsent from "react-cookie-consent";
import { Box, Typography } from "@material-ui/core";
// Sections
import PatientSearch from "./components/PatientSearch";
import QuickReferral from "./components/QuickReferral";
// Components
import TopBar from "../../common/components/TopBar";
import CardBlock from "../../common/components/Card";
import ScrollWrapper from "../../common/components/ScrollBar";

const Dashboard = ({ history }) => {
  const [isAuth] = useState(getLocal("cvai-auth-token") ? true : false);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular - Dashboard</title>
        <meta name="title" content="FAACT Vascular | Dashboard" />
      </Helmet>
    );
  };

  if (!isAuth) {
    history.push("/login");
    return null;
  }

  return (
    <div className="referral-homePage">
      {renderMetadata()}
      <TopBar title="Dashboard" />
      <div className="main">
        <ScrollWrapper css="no-padding x-hidden">
          <div
            className="mainContainer"
            style={{
              marginLeft: "-10px",
              paddingRight: "calc(5% - 20px)",
              paddingTop: "0",
              paddingBottom: "0",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "stretch",
            }}
          >
            <CardBlock title="Search Patient" varient="half">
              <PatientSearch />
            </CardBlock>
            <CardBlock title="Quick Referral" varient="half" contentPadding={"0.25rem"}>
              <QuickReferral />
            </CardBlock>
            <CookieConsent
              location="bottom"
              buttonText="Accept all"
              buttonStyle={{
                backgroundColor: "#005ef6",
                border: "1px solid #005ef6",
                color: "white",
                order: "1",
                fontSize: "0.875rem",
                marginBottom: "0",
                padding: "0 12px",
                margin: "0px",
                minHeight: "2.5rem",
                minWidth: "150px",
                height: "40px",
                width: "150px",
                alignItems: "center",
              }}
              declineButtonStyle={{
                backgroundColor: "#005ef6",
                border: "1px solid #005ef6",
                color: "white",
                order: "2",
                fontSize: "0.875rem",
                marginBottom: "0",
                padding: "0 12px",
                margin: "6px 0px",
                minHeight: "2.5rem",
                minWidth: "150px",
                height: "40px",
                width: "150px",
                alignItems: "center",
              }}
              declineButtonText="Required only"
              enableDeclineButton={true}
              cookieName="myAwesomeCookieName2"
              style={{ background: "white", padding: "0px 6rem", alignItems: "center", gap: "5rem" }}
              expires={150}
              overlay
            >
              <h4 style={{ fontWeight: 900, marginBottom: 0, fontSize: "0.875rem" }}>About cookies on this site</h4>
              <Box width="100%" display="flex" justifyContent="space-between" gridGap="5rem">
                <div style={{ width: "33.33%" }}>
                  <Typography
                    noWrap={false}
                    variant="caption"
                    style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                  >
                    Our websites require some cookies to function properly (required). In addition, other cookies may be used with your consent to
                    analyze site usage, improve the use experience and for advertising
                  </Typography>
                </div>

                <div style={{ width: "33.33%" }}>
                  {" "}
                  <Typography
                    noWrap={false}
                    variant="caption"
                    style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                  >
                    {" "}
                    For more information, please review your <a>Cookie preferences</a> options and FAACT's <a>privacy statement</a>{" "}
                  </Typography>{" "}
                </div>
                <div style={{ width: "33.33%" }}>
                  {" "}
                  <Typography
                    noWrap={false}
                    variant="caption"
                    style={{ color: "black", fontSize: ".875rem", lineHeight: "18px", display: "inline-block" }}
                  >
                    To provide a smooth navigation, your cookie preferences will be shared across the FAACT web domains listed <a>here</a>
                  </Typography>{" "}
                </div>
              </Box>
            </CookieConsent>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default Dashboard;

import { Drawer } from 'antd';
import React from 'react';
import ScrollWrapper from '../../../../common/components/ScrollBar';

const TimeOffSidebar = React.forwardRef(
    ({resource, isShow, handleCloseSidebar, currentUserInfo}, ref) => {
      if (!currentUserInfo) {
        return null;
      }

      return (
        <Drawer
          title={
            currentUserInfo?.name +
            (currentUserInfo.position &&
              '(' + currentUserInfo.position + ')') || ' '
          }
          placement="right"
          closable
          onClose={handleCloseSidebar}
          visible={isShow}
          destroyOnClose
          key="right"
        >
          <div ref={ref} className="resourceContainer new">
            <ScrollWrapper css="x-hidden">
              <div
                className="resourceContent"
                style={{
                  flex: 'unset',
                  paddingLeft: '24px',
                  paddingRight: '20px',
                  paddingBottom: '2rem',
                }}
              >
                <div
                  className="resourceInfo d-flex"
                  style={{marginBottom: '1rem'}}
                >
                  <div>
                  Vacation Requests greater than 8 hours must be made 7 days
                  prior to start for approval. Requests for vacation are not
                  guaranteed and must be approved. Please allow 2-3 business
                  days for approval. <br />
                    <br />
                  If you require covering staff please ensure you have worked
                  out all covering issues prior to requesting time off. Vacation
                  days will be approved based on first-come first serve and
                  fairness regarding equity of holiday vacation. Use of vacation
                  days and sick time are in accordance with our policies set
                  forth within the Manual and Policy section. <br />
                    <br />
                  If you do not have enough hours to cover your vacation days
                  than these hours will be unpaid. Greater than 10 hours of
                  unpaid time in 12 months will be considered maximum.
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </Drawer>
      );
    },
);

export default TimeOffSidebar;

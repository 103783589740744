import {createReducer} from 'redux-create-reducer';
import AT from '../actionTypes';

const initialState = {
  loading: false,
  orcLoading: false,
  logo_url: null,
  ocr_email: null,
  ocr_fax_number: null,
  ocr_phone_number: null,
  orgLoading: false,
  org_name: null,
  org_address1: null,
  org_address2: null,
  org_state: null,
  org_city: null,
  org_zipcode: null,
  org_phone_number: null,
  org_fax_number: null,
  searchLoading: false,
  providers: [],
  isUpdated: false,
  isSaved: false,
};

const ADMIN_SETTING_LOGO_UPLOAD_STARTED = `${AT.ADMIN_SETTING_LOGO_UPLOAD}_STARTED`;
const ADMIN_SETTING_LOGO_UPLOAD_SUCCEEDED = `${AT.ADMIN_SETTING_LOGO_UPLOAD}_SUCCEEDED`;
const ADMIN_SETTING_GET_CONGIG_STARTED = `${AT.ADMIN_SETTING_GET_CONFIG}_STARTED`;
const ADMIN_SETTING_GET_CONGIG_SUCCEEDED = `${AT.ADMIN_SETTING_GET_CONFIG}_SUCCEEDED`;
const ADMIN_SETTING_SAVE_OCR_INFO_STARTED = `${AT.ADMIN_SETTING_SAVE_OCR_INFO}_STARTED`;
const ADMIN_SETTING_SAVE_OCR_INFO_SUCCEEDED = `${AT.ADMIN_SETTING_SAVE_OCR_INFO}_SUCCEEDED`;
const GET_PRIMARY_PROVIDERS_DETAIL_STARTED = `${AT.GET_PRIMARY_PROVIDERS_DETAIL}_STARTED`;
const GET_PRIMARY_PROVIDERS_DETAIL_SUCCEEDED = `${AT.GET_PRIMARY_PROVIDERS_DETAIL}_SUCCEEDED`;
const UPDATE_PRIMARY_PROVIDER_DETAIL_STARTED = `${AT.UPDATE_PRIMARY_PROVIDER_DETAIL}_STARTED`;
const UPDATE_PRIMARY_PROVIDER_DETAIL_SUCCEEDED = `${AT.UPDATE_PRIMARY_PROVIDER_DETAIL}_SUCCEEDED`;
const CREATE_NEW_PRIMARY_PROVIDER_STARTED = `${AT.CREATE_NEW_PRIMARY_PROVIDER}_STARTED`;
const CREATE_NEW_PRIMARY_PROVIDER_SUCCEEDED = `${AT.CREATE_NEW_PRIMARY_PROVIDER}_SUCCEEDED`;
const ADMIN_SETTING_SAVE_ORG_DETAIL_STARTED = `${AT.ADMIN_SETTING_SAVE_ORG_DETAIL}_STARTED`;
const ADMIN_SETTING_SAVE_ORG_DETAIL_SUCCEEDED = `${AT.ADMIN_SETTING_SAVE_ORG_DETAIL}_SUCCEEDED`;

export default createReducer(initialState, {
  [ADMIN_SETTING_LOGO_UPLOAD_STARTED](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [ADMIN_SETTING_LOGO_UPLOAD_SUCCEEDED](state, action) {
    return {
      ...state,
      loading: false,
      logo_url: action?.payload?.logo_url,
    };
  },
  [ADMIN_SETTING_GET_CONGIG_STARTED](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [ADMIN_SETTING_GET_CONGIG_SUCCEEDED](state, action) {
    return {
      ...state,
      loading: false,
      logo_url: !action?.payload?.find((e) => e.key === 'logo_url') ?
        null :
        action?.payload?.find((e) => e.key === 'logo_url').value,
      ocr_email: !action?.payload?.find((e) => e.key === 'ocr_email') ?
        null :
        action?.payload?.find((e) => e.key === 'ocr_email').value,
      ocr_fax_number: !action?.payload?.find((e) => e.key === 'ocr_fax_number') ?
        null :
        action?.payload?.find((e) => e.key === 'ocr_fax_number').value,
      ocr_phone_number: !action?.payload?.find(
          (e) => e.key === 'ocr_phone_number',
      ) ?
        null :
        action?.payload?.find((e) => e.key === 'ocr_phone_number').value,
      org_name: !action?.payload?.find((e) => e.key === 'org_name') ?
        null :
        action?.payload?.find((e) => e.key === 'org_name').value,
      org_address1: !action?.payload?.find((e) => e.key === 'org_address1') ?
        null :
        action?.payload?.find((e) => e.key === 'org_address1').value,
      org_address2: !action?.payload?.find((e) => e.key === 'org_address2') ?
        null :
        action?.payload?.find((e) => e.key === 'org_address2').value,
      org_state: !action?.payload?.find((e) => e.key === 'org_state') ?
        null :
        action?.payload?.find((e) => e.key === 'org_state').value,
      org_city: !action?.payload?.find((e) => e.key === 'org_city') ?
        null :
        action?.payload?.find((e) => e.key === 'org_city').value,
      org_zipcode: !action?.payload?.find((e) => e.key === 'org_zipcode') ?
        null :
        action?.payload?.find((e) => e.key === 'org_zipcode').value,
      org_phone_number: !action?.payload?.find(
          (e) => e.key === 'org_phone_number',
      ) ?
        null :
        action?.payload?.find((e) => e.key === 'org_phone_number').value,
      org_fax_number: !action?.payload?.find((e) => e.key === 'org_fax_number') ?
        null :
        action?.payload?.find((e) => e.key === 'org_fax_number').value,
      new_patient_document: !action?.payload?.find(
          (e) => e.key === 'new_patient_document',
      ) ?
        null :
        action?.payload?.find((e) => e.key === 'new_patient_document').value,
      in_patient_document: !action?.payload?.find(
          (e) => e.key === 'in_patient_document',
      ) ?
        null :
        action?.payload?.find((e) => e.key === 'in_patient_document').value,
      out_patient_document: !action?.payload?.find(
          (e) => e.key === 'out_patient_document',
      ) ?
        null :
        action?.payload?.find((e) => e.key === 'out_patient_document').value,
    };
  },
  [ADMIN_SETTING_SAVE_OCR_INFO_STARTED](state) {
    return {
      ...state,
      orcLoading: true,
    };
  },
  [ADMIN_SETTING_SAVE_OCR_INFO_SUCCEEDED](state, action) {
    return {
      ...state,
      orcLoading: false,
      [action?.payload?.key]: action?.payload?.value,
    };
  },
  [GET_PRIMARY_PROVIDERS_DETAIL_STARTED](state) {
    return {
      ...state,
      searchLoading: true,
    };
  },
  [GET_PRIMARY_PROVIDERS_DETAIL_SUCCEEDED](state, action) {
    return {
      ...state,
      searchLoading: false,
      providers: action?.payload,
    };
  },
  [UPDATE_PRIMARY_PROVIDER_DETAIL_STARTED](state) {
    return {
      ...state,
      isUpdated: false,
    };
  },
  [UPDATE_PRIMARY_PROVIDER_DETAIL_SUCCEEDED](state, action) {
    return {
      ...state,
      isUpdated: true,
    };
  },
  [CREATE_NEW_PRIMARY_PROVIDER_STARTED](state) {
    return {
      ...state,
      isSaved: false,
    };
  },
  [CREATE_NEW_PRIMARY_PROVIDER_SUCCEEDED](state, action) {
    return {
      ...state,
      isSaved: true,
    };
  },
  [ADMIN_SETTING_SAVE_ORG_DETAIL_STARTED](state) {
    return {
      ...state,
      orgLoading: true,
    };
  },
  [ADMIN_SETTING_SAVE_ORG_DETAIL_SUCCEEDED](state, action) {
    return {
      ...state,
      orgLoading: false,
      [action?.payload?.key]: action?.payload?.value,
    };
  },
});

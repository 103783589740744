import { Empty, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";
import {
  get_prior_authorization_settings,
  save_prior_authorization_settings,
  search_insurance_companies,
  setProcedureDetails,
} from "../../../store/actions";

let timer = null;

const PriorAuthorizations = () => {
  // TODO: captures integration
  const insurances = useSelector((state) => state.procedureDetail.insurances);
  const loading_search_insurance_companies = useSelector((state) => state.procedureDetail.loading_search_insurance_companies);
  const prior_authorization_settings = useSelector((state) => state.procedureDetail.prior_authorization_settings);
  const search_insurances = useSelector((state) => state.newPatient.search_insurance_companies);
  const dispatch = useDispatch();
  const [localLoading, setLocalLoading] = useState(false);

  const updateInsurance = async () => {
    const ArrayCheck = (prior_authorization_settings || []).map((r) => {
      return {
        ...r,
        name_isurance: r?.insurance && r?.insurance?.name ? r?.insurance?.name : "",
      };
    });
    await dispatch(setProcedureDetails("insurances", [...ArrayCheck]));
  };

  useEffect(() => {
    updateInsurance();
  }, [prior_authorization_settings]);

  useEffect(() => {
    dispatch(get_prior_authorization_settings());
    dispatch(search_insurance_companies(""));
  }, []);

  const updateProviders = async (type, index, value, indexCode, idOnRow) => {
    if (type === "insurance_company_id") {
      insurances[index][type] = value[0];
      insurances[index]["name_isurance"] = value[1]?.name ? value[1].name : "";
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(
          save_prior_authorization_settings({
            settings: [...insurances],
          })
        );
        await dispatch(get_prior_authorization_settings());
      }, 500);
    } else {
      insurances[index]["cpt_codes"] = value;
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(
          save_prior_authorization_settings({
            settings: [...insurances],
          })
        );
        await dispatch(get_prior_authorization_settings());
      }, 500);
    }
    await dispatch(setProcedureDetails("insurances", [...insurances]));
  };

  const removeProvider = async (type, index, indexCode, idOnRow) => {
    insurances.splice(index, 1);
    await dispatch(
      save_prior_authorization_settings({
        settings: [...insurances],
      })
    );
    await dispatch(get_prior_authorization_settings());
  };

  const searchHandle = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(search_insurance_companies(value));
      setLocalLoading(false);
    }, 500);
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      <ScrollWrapper css="no-padding x-hidden">
        <div style={{ paddingTop: "8px" }}>
          {(insurances || []).map((r, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "8px",
                  marginTop: index > 0 ? "12px" : 0,
                  paddingTop: index > 0 ? "12px" : 0,
                  borderTop: index > 0 ? "1px solid #f0f3f6" : "none",
                }}
              >
                <div style={{ width: "calc(100% - 40px)", marginRight: "8px" }}>
                  <EditableFormInput
                    fullWidth
                    label={"Search Insurance"}
                    hideIconSelect={true}
                    type={"check"}
                    value={r?.name_isurance}
                    options={search_insurances}
                    autoClearSearchValue={false}
                    onChangeSearch={(value) => {
                      searchHandle(value);
                    }}
                    handleChange={(value) =>
                      updateProviders(
                        "insurance_company_id",
                        index,
                        [value, search_insurances && search_insurances.find((r) => r?.id == value)],
                        null,
                        r
                      )
                    }
                    notFoundContent={
                      localLoading || loading_search_insurance_companies ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    optionKey={"id"}
                    valueKey={"name"}
                  />
                </div>
                <div
                  style={{
                    width: "32px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "0.75rem",
                  }}
                  onClick={() => removeProvider("insurance_company_id", index)}
                >
                  <Icon type="delete" style={{ fontSize: "16px", marginLeft: "16px" }} />
                </div>
              </div>
              <div style={{ marginBottom: 10 }} className="resourceInfo d-flex"></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "8px",
                }}
              >
                <div style={{ width: "calc(100% - 40px)", marginRight: "8px" }}>
                  <EditableFormInput
                    fullWidth
                    label={"Search CPT"}
                    value={r.cpt_codes}
                    handleChange={(value) => updateProviders("cpt_codes", index, value)}
                    type="area"
                  />
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default PriorAuthorizations;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { getLocal } from "../../../../helpers/Local";
import { save_ultrasound_rule_result, setDetailUltrasoundProps } from "../../../../store/actions";

const ClinicalIndication = ({ resource, ...restProps }) => {
  const [indications, setIndications] = useState([]);
  const [cptCodes, setCptCodes] = useState([]);
  const [indication, setIndication] = useState();
  const [cptCode, setCptCode] = useState();
  const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
  const dispatch = useDispatch();
  const detailUltrasounds = useSelector((state) => state.common.detailUltrasounds);
  const detail_ultrasound_props = useSelector((state) => state.common.detail_ultrasound_props);
  const is_locked = useSelector((state) => state.clinicProviderReview.is_locked);
  const activeRequireIndication = useSelector((state) => state.common.activeRequireIndication);

  useEffect(() => {
    setIndication(detail_ultrasound_props?.disease_id);
    setCptCode(detail_ultrasound_props?.cpt_code_id);
  }, [detail_ultrasound_props]);

  useEffect(() => {
    if (detailUltrasounds && detailUltrasounds.codes) {
      setIndications(detailUltrasounds.codes);
    }
    if (detailUltrasounds && detailUltrasounds.suggested_cpt_codes) {
      setCptCodes(detailUltrasounds.suggested_cpt_codes);
    }
  }, [detailUltrasounds]);

  const onchangeValue = async (value) => {
    await dispatch(
      setDetailUltrasoundProps("detail_ultrasound_props", {
        ...detail_ultrasound_props,
        disease_id: value,
      })
    );
    await dispatch(
      save_ultrasound_rule_result(appointment_ultrasound_id, {
        left_result: detail_ultrasound_props?.result_l,
        right_result: detail_ultrasound_props?.result_r,
        left_conclusion: detail_ultrasound_props?.conclusion_l,
        right_conclusion: detail_ultrasound_props?.conclusion_r,
        right_addendum: detail_ultrasound_props?.right_addendum,
        left_addendum: detail_ultrasound_props?.left_addendum,
        disease_id: value,
        cpt_code_id: detail_ultrasound_props?.cpt_code_id,
      })
    );
    setIndication(value);
  };

  const onChangeCptCode = async (value) => {
    await dispatch(
      setDetailUltrasoundProps("detail_ultrasound_props", {
        ...detail_ultrasound_props,
        cpt_code_id: value,
      })
    );
    await dispatch(
      save_ultrasound_rule_result(appointment_ultrasound_id, {
        left_result: detail_ultrasound_props?.result_l,
        right_result: detail_ultrasound_props?.result_r,
        left_conclusion: detail_ultrasound_props?.conclusion_l,
        right_conclusion: detail_ultrasound_props?.conclusion_r,
        right_addendum: detail_ultrasound_props?.right_addendum,
        left_addendum: detail_ultrasound_props?.left_addendum,
        disease_id: detail_ultrasound_props?.disease_id,
        cpt_code_id: value,
      })
    );
    setCptCode(value);
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo">
        <EditableFormInput
          label={"Clinical Indication"}
          value={indication}
          type={"check"}
          options={indications || []}
          optionKey={"id"}
          valueKey={"icd_code_name"}
          handleChange={(value) => {
            onchangeValue(value);
          }}
          disabled={is_locked}
          fullWidth
          required={true}
          enableErrorText={activeRequireIndication}
          helperText={activeRequireIndication ? "This field is required" : ""}
          isError={activeRequireIndication}
        />
      </div>
      {detailUltrasounds?.id != 5 ? (
        <div className="resourceInfo">
          <EditableFormInput
            label={"Suggested CPT Code"}
            value={cptCode}
            type={"check"}
            options={cptCodes || []}
            optionKey={"id"}
            valueKey={"cpt_code_name"}
            handleChange={(value) => {
              onChangeCptCode(value);
            }}
            disabled={is_locked}
            fullWidth
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClinicalIndication;

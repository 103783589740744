import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getUsers, setGlobalLoading } from "../../../../store/actions";

const Active = ({ activeIndex, title, loading, handleRow, searchValue }) => {
  const ref = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const size = useWindowSize(ref);
  const users = useSelector((state) => state.common.users);
  const dispatch = useDispatch();
  for (let i = 0; i <= 10; i++) {
    tableData.push({});
  }

  let emptyRows = [];

  const fetchApi = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getUsers());
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    const data = users && users["active_users"] && users["active_users"].length > 0 ? users["active_users"] : [];
    if (!searchValue) {
      setTableData(data);
      return;
    }
    const filteredArr = data.filter((el) => {
      return el?.name?.toLowerCase()?.includes(searchValue?.toLowerCase());
    });
    setTableData(filteredArr);
  }, [searchValue, users]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableData.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableData.length) {
    emptyRows = new Array(rowCount - tableData.length).fill({});
  }

  const getLocaleString = (str) => {
    if (!str) {
      return "$0.00";
    }
    const num = parseFloat(str).toFixed(2);
    const decimals = num.split(".")[1];
    if (!decimals || decimals === "00") {
      return "$" + Math.round(parseFloat(str)).toLocaleString() + ".00";
    }
    if (decimals.charAt(1) === "0") {
      return "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0";
    }
    return "$" + (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
  };

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader" style={{ borderBottom: "2px solid #dfe3e6" }}>
        <div className="tableItem">
          <div className="th" style={{ width: "calc(31% - 3rem)", marginLeft: "3rem" }}>
            <b>Employee Name</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Position</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Points</b>
          </div>
          <div className="th" style={{ width: "23%" }}>
            <b>Wage</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper css="no-padding">
            <div className="tableList">
              {(tableData || []).map((row, index) => (
                <div
                  className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                  key={`resultTable-${title}-${index}`}
                  onClick={handleRow(index, row)}
                >
                  <div className="td with-icon" style={{ width: "31%" }}>
                    <div className="iconField d-flex">
                      {row?.name ? <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" /> : <span></span>}
                    </div>
                    <p>{row?.name}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.position}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.meta && row.meta.points}</p>
                  </div>
                  <div className="td" style={{ width: "23%" }}>
                    <p>{row.meta && getLocaleString(row.meta.salary)}</p>
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
                  <div className="td" style={{ width: "31%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                  <div className="td" style={{ width: "23%" }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default Active;

import { Icon, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import './QuestionInput.scss';

const { Search } = Input;

const QuestionInput = ({
  rows,
  addQuestion,
  removeQuestion,
  addQuestionChoice,
  removeQuestionChoice,
}) => {
  const [questionInput, setQuestionInput] = useState('');
  const [choiceInput, setChoiceInput] = useState('');
  const [activeRow, setRow] = useState(-1);
  const data = rows ? rows : [];
  let emptyRows = [];
  if (data && data.length < 5) {
    emptyRows = new Array(4 - data.length).fill({ label: '' });
  }

  useEffect(() => {
    setRow(-1);
  }, [rows]);

  const onChangeQuestion = (e) => {
    setQuestionInput(e.target?.value);
  };

  const onAddQuestion = () => {
    if (questionInput == '') return;
    const question = {
      question: questionInput,
      choices: [],
    };
    addQuestion(question);
    setQuestionInput('');
  };

  const onSelectRow = (index) => {
    setRow(index);
  };

  const onChangeChoice = (e) => {
    setChoiceInput(e.target?.value);
  };

  const onAddChoice = () => {
    if (choiceInput == '') return;
    addQuestionChoice(activeRow, choiceInput);
    setChoiceInput('');
  };

  return (
    <div className="questionInput">
      <div className="questionInput__filter">
        <div className={`questionInput__filter-search`}>
          <Search
            value={questionInput}
            placeholder="Add Questions"
            onChange={onChangeQuestion}
            style={{ width: '100%' }}
          />
        </div>
        <div className="questionInput__filter-action">
          <button className="questionInput__filter-add" onClick={onAddQuestion}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>

      <div className="questionInput__result">
        <div className="questionInput__result-list">
          <ScrollWrapper css="no-padding x-hidden">
            {(data || []).map((row, index) => (
              <div
                className={`questionInput__result-item ${activeRow >= 0 && activeRow == index && 'active'
                  }`}
                key={`question-input-${index}`}
              >
                <div
                  style={{
                    display: 'inline-flex',
                    marginRight: '0.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => removeQuestion(index)}
                >
                  <Icon type="delete" />
                </div>
                <div
                  onClick={() => onSelectRow(index)}
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                >
                  <strong>{row.question}</strong>
                </div>
                {row.choices.map((row2, index2) => (
                  <React.Fragment key={`question-input-${index}-${index2}`}>
                    <div
                      style={{
                        display: 'inline-flex',
                        marginRight: '0.5rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => removeQuestionChoice(index, index2)}
                    >
                      <Icon type="delete" />
                    </div>
                    <p style={{ marginRight: '10px' }}>[{row2}]</p>
                  </React.Fragment>
                ))}
              </div>
            ))}
            {emptyRows.map((row, index) => (
              <div
                className="questionInput__result-item empty"
                key={`empty-question-input-${index}`}
              />
            ))}
          </ScrollWrapper>
        </div>
      </div>

      {activeRow >= 0 && (
        <div className="questionInput__filter">
          <div className={`questionInput__filter-search`}>
            <Search
              value={choiceInput}
              placeholder="Add Choice to selected question"
              onChange={onChangeChoice}
              style={{ width: '100%' }}
            />
          </div>
          <div className="questionInput__filter-action">
            <button className="questionInput__filter-add" onClick={onAddChoice}>
              <span>Add</span>
              <span>+</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionInput;

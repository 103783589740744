import React from "react";
import { NavLink } from "react-router-dom";
import { LeftCharvenSVG } from "../icons/SVGIcon";
import "./style.scss";

const SubSideNav = ({ subMenu, title, isSideNav, openSideNav, closeSideNav, onLinkClick }) => {
  const onClickHander = () => {
    if (isSideNav) {
      closeSideNav && closeSideNav();
    } else {
      openSideNav && openSideNav();
    }
  };

  const handleOpen = () => {
    if (!isSideNav) {
      openSideNav && openSideNav();
    }
  };

  const onClickLink = (link) => {
    if (typeof onLinkClick === "function") {
      onLinkClick(link);
    }
  };

  return (
    <div className={`SubSideNav ${isSideNav ? "" : "collapsed"}`}>
      <div className={`SubSideNav__main ${isSideNav ? "" : "collapsed"}`} onClick={handleOpen}>
        <div className={`SubSideNav__main-list ${isSideNav ? "" : "collapsed"}`}>
          {title && <div className="SubSideNav__main-title-logo">{title}</div>}
          {(subMenu || []).map((nav, index) => (
            <div
              id={`${nav?.label && nav?.label.length > 0 && nav?.label.replaceAll(" ", "_")}`}
              className="SubSideNav__main-item"
              key={`subsidenav-${index}`}
            >
              <NavLink onClick={() => onClickLink(nav.link)} to={nav.link}>
                {nav.label}
              </NavLink>
            </div>
          ))}
        </div>
      </div>
      <div className={`SubSideNav__handle ${isSideNav ? "" : "collapsed"}`} onClick={onClickHander}>
        <LeftCharvenSVG />
      </div>
    </div>
  );
};

export default SubSideNav;

import React, { useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import Employee from "./UsersComponents/Employee";
import Referring from "./UsersComponents/Referring";
import Patient from "./UsersComponents/Patient";
import { UsersHeaders } from "../constant";
import { setPCPNull } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "@material-ui/core";

const Users = () => {
  const [activeTab, setActiveTab] = useState(0);
  const isSidebar = useSelector((state) => state.sidebar.isSidebarOpen);
  const dispatch = useDispatch();

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Users" subStatus={''} isBarcode={false} />
      <div
        className={`clinicWorkflow__main-container ${isSidebar ? 'openSidebar' : ''
          }`}
      >
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders">
          <Tabs
              TabIndicatorProps={{style:{display:'none'}}}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
              {UsersHeaders.map((tab, index) => (
                <div
                  key={`tabHeaders-${tab.label}-${index}`}
                  className={`tabHeaders-item ${activeTab === index ? 'active' : ''
                    }`}
                  style={{ width: tab.width }}
                  onClick={async () => {
                    await dispatch(setPCPNull());
                    setActiveTab(index);
                  }}
                >
                  {tab.label}
                </div>
              ))}
            </Tabs>
          </div>
        </div>
        {activeTab === 0 && <Employee />}
        {activeTab === 1 && <Referring />}
        {activeTab === 2 && <Patient />}
      </div>
    </div>
  );
};

export default Users;

import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import { getDiseases, setProcedureDetails, update_icd_rule } from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import { getLocal } from "../../../helpers/Local";

let timer = null;
let timerICD = null;

const ICDCodeLogic = ({ resource, isDxRanges }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [IDRow, setIDRow] = useState(null);
  const [activeDelete, setActiveDelete] = useState([]);
  const [icd_range, setIcdRange] = useState("");
  const [removableParent, setRemovableParent] = useState(-1);
  const dispatch = useDispatch();

  const conditionVariable = useSelector((state) => state.procedureDetail.conditionVariable);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);
  const icdCodes = useSelector((state) => state.procedureDetail.icdCodes);
  const selectData = useSelector((state) => state.common.diseases);
  const loadingSearchICD = useSelector((state) => state.common.loadingDiseases);

  const id_procedure_type_icd = getLocal("id_procedure_type_icd");

  useEffect(() => {
    setIcdRange(selectedReferralCondition?.icd_range);
  }, [selectedReferralCondition]);

  const handleChange = async (type, value, index, indexICD) => {
    if (icdCodes && icdCodes[indexICD]) {
      if (type === "disease_id") {
        icdCodes[indexICD]["disease"] = {
          icd_code: value[1],
          icd_code_name: value[2],
          id: value[0],
        };
        icdCodes[indexICD]["disease_id"] = value[0];
        await dispatch(setProcedureDetails("icdCodes", [...icdCodes]));
      } else {
        icdCodes[indexICD]["condition_serialize"][index][type] = value === "THAN" && type === "operator" ? "THAN" : value;
        await dispatch(setProcedureDetails("icdCodes", [...icdCodes]));
      }
    }
  };

  const handleChangeOperator = async (value, index, indexICD) => {
    const nextIndex = index + 1;
    if (icdCodes[indexICD] && icdCodes[indexICD]["condition_serialize"] && icdCodes[indexICD]["condition_serialize"].length > 0) {
      const plan = icdCodes[indexICD]["condition_serialize"][nextIndex];
      const paramNU = {
        variable_id: plan && typeof plan["variable_id"] != "undefined" ? plan["variable_id"] : "",
        variable_name: plan && typeof plan["variable_name"] != "undefined" ? plan["variable_name"] : "",
        answer: plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison: plan && typeof plan["comparison"] != "undefined" ? plan["comparison"] : ">=",
        operator: plan && typeof plan["operator"] != "undefined" ? plan["operator"] : "",
      };
      const paramUN = {
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (icdCodes && icdCodes[indexICD]) {
        if (value !== "THAN") {
          icdCodes[indexICD]["condition_serialize"][index + 1] = { ...paramNU };
          await dispatch(setProcedureDetails("icdCodes", [...icdCodes]));
        } else {
          const abc = icdCodes[indexICD];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          icdCodes[indexICD]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("icdCodes", [...icdCodes]));
        }
      }
    }
  };

  useEffect(() => {
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      dispatch(setProcedureDetails("icdCodes", selectedReferralCondition?.icd_rules ? selectedReferralCondition?.icd_rules : []));
    }
  }, [selectedReferralCondition]);

  useEffect(() => {
    const variableMap = (conditionVariable || []).map((r) => {
      return { id: r?.id, value: r.variable_name };
    });
    setSearchResult([...variableMap]);
  }, [conditionVariable]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult([...selectData]);
    }
  }, [selectData]);

  useEffect(() => {
    clearTimeout(timer);
    setLocalLoading(true);
    setSelectResult([]);
    timer = setTimeout(() => {
      dispatch(getDiseases(selectSearch));
      setLocalLoading(false);
    }, 500);
  }, [selectSearch]);

  useEffect(() => {
    dispatch(getDiseases(null));
  }, []);

  const removeProvider = async (index, indexICD) => {
    const checkICDRow = icdCodes[indexICD];
    if (checkICDRow && checkICDRow["condition_serialize"] && checkICDRow["condition_serialize"].length > 1) {
      if (checkICDRow["condition_serialize"][index].operator === "THAN" || checkICDRow["condition_serialize"][index].operator === "") {
        checkICDRow["condition_serialize"].splice(index, 2);
      } else {
        checkICDRow["condition_serialize"].splice(index, 1);
      }
      const check = checkICDRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        icdCodes[indexICD]["condition_serialize"] = [...check];
      } else {
        icdCodes.splice(indexICD, 1);
      }
      await dispatch(setProcedureDetails("icdCodes", [...icdCodes]));
    } else {
      icdCodes[indexICD] = null;
      const checkArray = icdCodes.filter((r) => {
        if (r != null) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("icdCodes", [...checkArray]));
      await dispatch(update_icd_rule(id_procedure_type_icd, { rules: [...checkArray] }));
    }
  };

  const update = async () => {
    if (icdCodes && icdCodes[IDRow] && icdCodes[IDRow]["condition_serialize"]) {
      const arrayADD = icdCodes.map((r) => {
        return {
          disease_id: r?.disease_id,
          condition_id: id_procedure_type_icd,
          disease: r?.disease,
          condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
        };
      });
      await dispatch(update_icd_rule(id_procedure_type_icd, { rules: arrayADD }));
    }
  };

  const handleICDDX = (value) => {
    setIcdRange(value);
    clearTimeout(timerICD);
    timerICD = setTimeout(() => {
      dispatch(
        update_icd_rule(id_procedure_type_icd, {
          icd_range: value,
        })
      );
    }, 500);
  };

  useEffect(() => {
    update();
  }, [icdCodes]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isDxRanges && (
        <React.Fragment>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "8px",
            }}
          >
            <div style={{ width: "calc(100%)", paddingTop: ".5rem" }}>
              <EditableFormInput
                fullWidth={true}
                label={"ICD Dx Ranges"}
                value={icd_range}
                handleChange={(value) => {
                  handleICDDX(value);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      {icdCodes.map((itemICD, indexICD) => {
        return (
          <div
            key={indexICD}
            onClick={() => {
              setIDRow(indexICD);
            }}
            style={{
              width: "100%",
              background: "rgb(219, 227, 238)",
              padding: "7px 0",
              marginBottom: "1rem",
            }}
          >
            <DataTable
              title="icd_code"
              sizeHeightFix={itemICD?.condition_serialize && itemICD?.condition_serialize.length > 1 ? itemICD?.condition_serialize.length * 40 : 40}
              isFixHeight={true}
              isOverFlow={true}
              isICD={true}
              hideHeader={true}
              hideHeaderHeight="0px"
              allActive={true}
              handleDelete={(value, index) => removeProvider(index, indexICD)}
              parentIndex={indexICD}
              handleActiveDelete={(val) => {
                const activeDeletes = [...activeDelete];
                activeDeletes[indexICD] = val;
                if (removableParent !== indexICD) {
                  activeDeletes[removableParent] = -1;
                  setRemovableParent(indexICD);
                } else {
                  if (val === -1) setRemovableParent(-1);
                }
                setActiveDelete(activeDeletes);
              }}
              activeParent={activeDelete[indexICD]}
              labels={["Variable", "Option", "Answer", "Operator"]}
              widths={["35%", "15%", "20%", "30%"]}
              columns={[
                { sortable: false, key: "conditionVariable" },
                { sortable: false, key: "option" },
                { sortable: false, key: "answer" },
                { sortable: false, key: "operator" },
              ]}
              rowData={(itemICD?.condition_serialize || []).map((item, index) => {
                let arrayAnswer = [];
                const answerCheck = (conditionVariable || []).find((r) => {
                  return r.variable_name == item?.variable_name?.value;
                });
                if (answerCheck) {
                  const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
                    return { id: item?.id, answer: item.answer };
                  });
                  arrayAnswer = [...allAnswerCheck];
                }
                return {
                  key: index,
                  disableDelete: typeof item?.operator === "undefined" && true,
                  fullsize: typeof item?.operator === "undefined" && true,
                  conditionVariable:
                    typeof item?.operator === "undefined" ? (
                      <Select
                        key={index}
                        showSearch
                        placeholder="Search ICD Code"
                        value={`${itemICD?.disease?.icd_code_name}`}
                        onSearch={(value) => {
                          setSelectSearch(value);
                        }}
                        onDropdownVisibleChange={(open) => {
                          setFocusState(open);
                        }}
                        onBlur={() => {
                          setSelectSearch(null);
                        }}
                        notFoundContent={
                          localLoading || loadingSearchICD ? (
                            <div style={{ height: 100 }}>
                              <Loading />
                            </div>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        autoClearSearchValue={false}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        onChange={(value) => {
                          handleChange("disease_id", value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(selectResult || []).map((r, index) => (
                          <Select.Option key={r?.id} value={focusState ? [r?.id, r.icd_code, r.icd_code_name] : `${r.icd_code_name}`}>
                            {r.icd_code_name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        placeholder={"Variable"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.variable_id}
                        onChange={(value) => {
                          handleChange("variable_id", value, index, indexICD);
                          handleChange(
                            "variable_name",
                            searchResult.find((r) => r?.id == value),
                            index,
                            indexICD
                          );
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(searchResult || []).map((opt, index) => (
                          <Select.Option key={`conditionVariable-${index}-${opt?.id}`} value={opt?.id}>
                            {opt?.value}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  option:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Opt"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.comparison}
                        showArrow={false}
                        onChange={(value) => {
                          handleChange("comparison", value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["<=", "=", "!=", ">="].map((opt) => (
                          <Select.Option key={`option--${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  answer:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Answer"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.answer}
                        onChange={(value) => {
                          handleChange("answer", value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(arrayAnswer || []).map(
                          (opt) =>
                            opt.answer && (
                              <Select.Option key={`answer-${opt.answer}`} value={opt.answer}>
                                {opt.answer}
                              </Select.Option>
                            )
                        )}
                      </Select>
                    ),
                  operator:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Operator"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.operator || undefined}
                        onChange={(value) => {
                          handleChange("operator", value, index, indexICD);
                          handleChangeOperator(value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["OR", "AND", "THAN"].map((opt) => (
                          <Select.Option key={`operator-${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                };
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ICDCodeLogic;

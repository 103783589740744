import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { LockIconSVG } from "../../../common/components/icons/FaxSVG";
import { useWindowSize } from "../../../common/utils";
import { getPriorAuthorization, selectPrior, setPriorData, chooseSelectedPatientResource, setGlobalLoading } from "../../../store/actions";

const filterSuggestion = (suggestions, userInput, option) => {
  if (!userInput && (!option || !option.authorization_number)) {
    return suggestions;
  }
  let suggestionsByCode = suggestions;
  if (option && option.authorization_number) {
    suggestionsByCode = suggestionsByCode.filter(
      (suggestion) =>
        suggestion.authorization_number !== undefined &&
        suggestion.authorization_number !== null &&
        suggestion.authorization_number.split(" ").join("").toLowerCase().indexOf(option.authorization_number.split(" ").join("").toLowerCase()) > -1
    );
  }
  return suggestionsByCode.filter(
    (suggestion) =>
      suggestion.patient_name && suggestion.patient_name.split(" ").join("").toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1
  );
};

const PriorResultTable = ({ title, search, filter, handleSidebar }) => {
  const loading = useSelector((state) => state.prior.loadingPriors);
  const data = useSelector((state) => state?.prior?.priors?.data || []);
  const savedResource = useSelector((state) => state.prior.savedResoruces);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [takeCount, setTakeCount] = useState(30);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      const count = Math.round(ref.current.clientHeight / 40) + 3;
      setTakeCount(count);
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(setPriorData("loadingPriors", true));
    dispatch(setPriorData("resource", null));
    dispatch(getPriorAuthorization());
  }, []);

  useEffect(() => {
    if (!data) {
      dispatch(setPriorData("loadingPriors", true));
      dispatch(setPriorData("loadingResource", true));
      dispatch(getPriorAuthorization());
    }

    if (savedResource) {
      dispatch(setPriorData("loadingPriors", true));
      dispatch(setPriorData("loadingResource", true));
      dispatch(setPriorData("resource", null));
      dispatch(getPriorAuthorization());
      setRow(-1);
    }
  }, [data, savedResource]);

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search, filter);
    }
    return [];
  }, [data, search, filter]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      return tableRows.slice(0, offset + takeCount);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => () => {
    setRow(index);
    handleSidebar();
    dispatch(setPriorData("loadingResource", true));
    dispatch(selectPrior(rowData[index]));
    dispatch(chooseSelectedPatientResource(rowData[index].patient_id));
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > offset * 40 + 80) {
      setOffset(offset + takeCount);
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper css="no-x-padding x-hidden" onScrollFrame={onScroll}>
        <div className="tableList">
          {(rowData || []).map((row, index) => (
            <div
              className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
              key={`resultTable-${title}-${index}`}
              onClick={handleRow(index)}
            >
              <div className="td with-icon" style={{ width: "20%" }}>
                {row?.icon && row?.icon}
                {!row?.icon && <LockIconSVG />}
                <p>{row?.patient_name}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{moment(row?.date_of_birth).isValid() && moment(row?.date_of_birth).format("MM/DD/YYYY HH:mm")}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{moment(row?.date_of_procedure).isValid() && moment(row?.date_of_procedure).format("MM/DD/YYYY HH:mm")}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{row?.provider}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{row?.insurance_carrier}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{row?.icd_code}</p>
              </div>
              <div className="td" style={{ width: "calc(80% / 6)", paddingLeft: "26px" }}>
                <p>{row?.cpt_code}</p>
              </div>
            </div>
          ))}
          {emptyRows.map((row, index) => (
            <div className={`tableItem empty`} key={`resultTable-empty-${title}-${index}`}>
              <div className="nameField" />
              <div className="typeField" />
              <div className="dateField" />
              <div className="resultField" />
              <div className="resultField" />
              <div className="resultField" />
            </div>
          ))}
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default PriorResultTable;

import React from "react";
import { Helmet } from "react-helmet";

export default function About() {
  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular - About</title>
        <meta name="title" content="FAACT Vascular | About" />
      </Helmet>
    );
  };

  return (
    <div className="aboutPage">
      {renderMetadata()}
      <div className="main">
        <img src="/images/faact_logo_black.svg" alt="black logo" style={{ maxWidth: "768px" }} />
        <div className="aboutInfo" style={{ width: "100%", maxWidth: "618px" }}>
          <p>Version: 0.0.1</p>
          <p>(c) {new Date().getFullYear()}, Haqqani Corporation. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "antd";
import moment from "moment";
import DataTable from "../../../../common/components/DataTable/DataTable";
import ImageEditor from "../../../../common/components/ImageEditor";
import { convertToLongNumber } from "../../../../common/utils";
import {
  getAllSupportModules,
  getSupportModules,
  setAuthorRead,
  setGlobalLoading,
  setSidebarState,
  updateUserMedia,
} from "../../../../store/actions";
import DetailSidebar from "./DetailSidebar";

const AllPersonalTickets = ({ title, filter }) => {
  const sidebarRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const supportModules = useSelector((state) => state.supportModule);
  const userMediaFiles = useSelector((state) => state.userMedia);
  const [tableRows, setTableRows] = useState([]);
  const [isSidebar, setIsSidebar] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [activeIndex, setRow] = useState(-1);

  const fetchEvents = async () => {
    if (!!currentUserInfo?.id) {
      dispatch(setGlobalLoading(true));
      const { role_id } = currentUserInfo;
      if (role_id === 3) {
        await dispatch(getAllSupportModules());
      } else {
        await dispatch(getSupportModules(currentUserInfo?.id));
      }
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [currentUserInfo?.id]);

  useEffect(() => {
    if (!supportModules.loading && !!supportModules.supports) {
      let tmpSupportModules = supportModules.supports;

      if (filter === "open") {
        tmpSupportModules = tmpSupportModules.filter((row) => row?.status === 0);
      } else if (filter === "closed") {
        tmpSupportModules = tmpSupportModules.filter((row) => row?.status === 1);
      }
      setTableRows(tmpSupportModules);
    }
  }, [supportModules.loading]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isCaptured = searchParams.get("is_captured");

    if (isCaptured && !userMediaFiles.loading && userMediaFiles.media.length > 0) {
      setIsPreview(true);
      setPreviewData(userMediaFiles.media[userMediaFiles.media.length - 1]);
    }
  }, [userMediaFiles, location]);

  useEffect(() => {
    handleSidebar();
    dispatch(setSidebarState(true));

    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const handleSidebar = () => setIsSidebar(true);

  const handleCloseSidebar = () => {
    dispatch(setSidebarState(false));
    setIsSidebar(false);
  };

  const handleRow = async (index) => {
    dispatch(setGlobalLoading(true));
    setRow(index);
    await dispatch(setAuthorRead(tableRows[index].id));
    await dispatch(getSupportModules(currentUserInfo?.id));
    dispatch(setSidebarState(true));
    dispatch(setGlobalLoading(false));
    handleSidebar();
  };

  const handleMediaFileDoubleClicked = (value) => {
    setIsPreview(true);
    setPreviewData(value);
  };

  // Image Editor handler
  const handleSaveImage = (value) => {
    const payload = {
      type: "image",
      user_id: value.originImage.user_id,
      data: value.updatedImage,
    };
    dispatch(updateUserMedia(value.originImage?.id, payload));
    history.push("/support/details");
  };

  const handleCloseEditor = () => {
    setIsPreview(false);
    history.push("/support/details");
  };

  const handleCreatedModule = () => {
    setRow(tableRows.length);
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        {isPreview ? (
          <div className="fullContainer pt-2">
            {previewData?.type === "image" && <ImageEditor onSaveImage={handleSaveImage} onCloseEditor={handleCloseEditor} imageData={previewData} />}
          </div>
        ) : (
          <div className="fullContainer pt-2">
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                  isBorderThick={true}
                  title={"support_tickets"}
                  labels={["Date", "ID", "Subject"]}
                  widths={["30%", "20%", "50%"]}
                  columns={[
                    { sortable: false, key: "date" },
                    { sortable: false, key: "id" },
                    { sortable: false, key: "subject" },
                  ]}
                  disableDelete={true}
                  handleClickRow={(row, index) => handleRow(index)}
                  defaultActive={-1}
                  rowData={(tableRows || []).map((row) => {
                    return {
                      date: (
                        <div
                          className="iconField td with-icon"
                          style={{
                            display: "flex",
                            paddingLeft: "0.25rem",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {row?.author_unread == 1 ? (
                            <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                          ) : row?.status === 0 ? (
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          ) : (
                            row?.status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          )}

                          <p style={{ paddingLeft: "0.75rem" }}>{moment(row?.updated_at).format("MM/DD/YYYY HH:mm")}</p>
                        </div>
                      ),
                      id: <p>{convertToLongNumber(row?.id)}</p>,
                      subject: <p>{row?.subject}</p>,
                    };
                  })}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <DetailSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        isShow={isSidebar}
        resource={activeIndex !== -1 ? tableRows[activeIndex] : undefined}
        mediaFileDoubleClicked={handleMediaFileDoubleClicked}
        onCreatedModule={handleCreatedModule}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default AllPersonalTickets;

import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Input, Select, DatePicker} from 'antd';
import {ExpandSVG, SearchSVG} from '../../../common/components/icons/SVGIcon';
import {getReminders, setReminderData} from '../../../store/actions';

const {Search} = Input;
const {Option} = Select;

const dateFormat = 'MM/DD/YYYY';

const DashboardFilters = ({handleSearch, handleFilter}) => {
  const callResults = useSelector((state) => state.common.callResult);
  const types = useSelector((state) => state.reminders.types);

  const dispatch = useDispatch();

  const [contactDate, setContactDate] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');

  const changeSearch = (value) => {
    handleSearch(value);
  };

  const changeFilter = (type) => (value) => {
    handleFilter(type, value);
  };

  const changeRecentContactDate = (date, dateString) => {
    dispatch(setReminderData('loadingReminders', true));
    dispatch(
        getReminders({
          recent_contact_date: dateString,
          appointment_date: appointmentDate,
        }),
    );
    setContactDate(dateString);
  };

  const changeAppointmentDate = (date, dateString) => {
    dispatch(setReminderData('loadingReminders', true));
    dispatch(
        getReminders({
          recent_contact_date: contactDate,
          appointment_date: dateString,
        }),
    );
    setAppointmentDate(dateString);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{width: '100%'}}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter">
          <Select
            placeholder="Filter by Appointment Type..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeFilter('type')}
            style={{width: 'calc(100% - 0.75rem)'}}
          >
            {(types || []).map((type, index) => (
              <Option key={`type-${type?.id}-${index}`} value={type?.name}>
                {type?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="statusFilter">
          <DatePicker
            format={dateFormat}
            placeholder="Appointment Date..."
            suffixIcon={<ExpandSVG />}
            onChange={changeAppointmentDate}
            style={{width: 'calc(100% - 0.75rem)'}}
          />
        </div>
        <div className="statusFilter">
          <Select
            placeholder="Filter by Call Result..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeFilter('result')}
            style={{width: 'calc(100% - 0.75rem)'}}
          >
            {(callResults || []).map((result, index) => (
              <Option key={`type-${result?.id}-${index}`} value={result?.value}>
                {result?.value}
              </Option>
            ))}
          </Select>
        </div>
        <div className="statusFilter">
          <DatePicker
            format={dateFormat}
            placeholder="Recent Contact Date..."
            suffixIcon={<ExpandSVG />}
            onChange={changeRecentContactDate}
            style={{width: 'calc(100% - 0.75rem)'}}
          />
        </div>
        <div className="statusFilter">
          <Select
            placeholder="Filter by Number of Attempts..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeFilter('attempts')}
          >
            <Option value="1">1+</Option>
            <Option value="5">5+</Option>
            <Option value="10">10+</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default DashboardFilters;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Tooltip } from "antd";
import moment from "moment";
import { Helmet } from "react-helmet";
import { getCallAttemptResult, getFilterTypes } from "../../store/actions";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../common/components/ScrollBar";
import TopBar from "../../common/components/SubTopBar";
import DashboardFilters from "./components/DashboardFilters";
import DashboardResultTable from "./components/DashboardResultTable";
import ResourceSideBar from "./components/ResourceSideBar";
import "./style.scss";

function ClinicReminders() {
  const dispatch = useDispatch();
  const callResult = useSelector((state) => state.common.callResult);
  const types = useSelector((state) => state.reminders.types);
  const reminders = useSelector((state) => state.reminders.reminders);
  const resource = useSelector((state) => state.reminders.resource);
  const [isSidebar, setIsSidebar] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    type: "",
    result: "",
    attempts: "",
    activeRow: 0,
  });

  const sidebarRef = useRef(null);

  useEffect(() => {
    if (!callResult) {
      dispatch(getCallAttemptResult());
    }
    if (!types) {
      dispatch(getFilterTypes());
    }
  }, [callResult, types]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleSearch = (search) => {
    setSearch(search);
  };

  const handleFilter = (type, value) => {
    setFilter({ ...filter, [type]: value });
  };

  const handleChangeRowClick = (index) => {
    setFilter({ ...filter, activeRow: index });
  };

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Clinic Reminders</title>
        <meta name="title" content="FAACT Vascular | Clinic Reminders" />
      </Helmet>
    );
  };

  const getStatus = (status, status1) => {
    return (
      <div className="statusHealth">
        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
        {status} <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {status1}
      </div>
    );
  };

  const renderSideComponent = () => {
    if (!resource || !Object.keys(resource).length) {
      return null;
    }

    const fullName = `${resource?.first_name} ${resource?.last_name}`.toUpperCase();
    const age = resource?.date_of_birth ? `(${moment().diff(resource?.date_of_birth, "years")})` : "";

    const allerrgies = [];
    if (resource?.allergies) {
      (resource?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resource?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resource?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                width: "100%",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resource?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resource?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resource?.gender && resource?.gender.toUpperCase() === "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const filterReminders = reminders ? reminders.filter((re) => re.status === "Not Confirmed") : [];
  const status = reminders ? reminders.length - filterReminders.length : 0;
  const isResource = !resource || !Object.keys(resource).length;

  return (
    <div className="dashboardContent">
      {renderMetadata()}
      <TopBar
        title="Clinical Reminders"
        subStatus={getStatus(status, filterReminders.length)}
        noLeftSide={true}
        sideComponent={renderSideComponent()}
        isBarcode={!isResource}
      />
      <ResourceSideBar
        ref={sidebarRef}
        dataResult={(reminders && reminders[filter.activeRow] && reminders[filter.activeRow]["automated_contact"]) || []}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
        row={reminders && reminders[filter.activeRow]}
      />
      <div className="main">
        <ScrollWrapper css="no-padding">
          <div className={`mainContainer ${isResource || !isSidebar ? "no-sidebar" : ""}`}>
            <DashboardFilters handleSearch={handleSearch} handleFilter={handleFilter} />
            <div className="tableSection" style={{ position: "relative" }}>
              <DashboardResultTable
                handleChangeRowClick={(index) => {
                  handleChangeRowClick(index);
                }}
                title="result"
                search={search}
                filter={filter}
                handleSidebar={handleSidebar}
              />
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
}

export default ClinicReminders;

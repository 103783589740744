import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import EditFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SubDataTable from "../../../../common/components/SubDataTable";

import { getLocal } from "../../../../helpers/Local";
import { deletePurchaseItem, getPurchaseList, orderPurchaseItem, setInventoryData } from "../../../../store/actions";

const categories = {
  angiographic: "Angiographic Supply",
  narcotic: "Narcotic Inventory",
  other: "Other Inventory",
};

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (!userInput) {
    filteredSuggestions = suggestions;
  }
  if (userInput) {
    filteredSuggestions = suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
  }
  return filteredSuggestions;
};

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const getTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ ...titleStyle, width: "50%", paddingLeft: 0, borderLeft: 0 }}>Date</div>
      <div style={{ ...titleStyle, width: "50%", textAlign: "left" }}>Cost Per Item</div>
    </div>
  );
};

const PurchaseListSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const purchase = useSelector((state) => state.inventory.purchase);
  const costHistory = useSelector((state) => state.inventory.inventoryCostHistory);
  const isOrdered = useSelector((state) => state.inventory.ordered);
  const isDeleted = useSelector((state) => state.inventory.isDeleted);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [notes, setNotes] = useState([]);
  const [searchInput, setInput] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (purchase) {
      const initialNotes = purchase.notes ? purchase.notes.split(",") : [];
      setNotes(initialNotes);
    }
  }, [purchase]);

  useEffect(() => {
    if (isOrdered) {
      dispatch(getPurchaseList());
      dispatch(setInventoryData("ordered", false));
      dispatch(setInventoryData("purchase", null));
    }
    if (isDeleted) {
      dispatch(getPurchaseList());
      dispatch(setInventoryData("isDeleted", false));
      dispatch(setInventoryData("purchase", null));
      setOpenDelete(false);
    }
  }, [isOrdered, isDeleted]);

  const noteRows = useMemo(() => {
    if (searchInput) {
      return filterSuggestion(notes, searchInput);
    }
    return notes;
  }, [notes, searchInput]);

  const handleSearch = (search) => {
    setInput(search);
  };
  const addNote = () => {
    setInput("");
  };

  const removeNote = (row, index) => {};

  const orderPurchase = () => {
    const user = getLocal("cvai-current-user");
    if (purchase) {
      dispatch(orderPurchaseItem(purchase?.id, { ...purchase, added_user: user }));
    }
  };

  const deletePurchase = () => {
    if (purchase) {
      dispatch(deletePurchaseItem(purchase?.id));
    }
  };

  const cost_per_item = useMemo(() => {
    if (purchase && purchase.cost) {
      const quantity = purchase.quantity ? purchase.quantity : 1;
      return (purchase.cost / quantity).toFixed(2);
    }
    return null;
  }, [purchase]);

  const costRows = useMemo(() => {
    if (costHistory && costHistory.length) {
      return costHistory.map((ch) => ({
        item: ch.created_at ? moment(ch.created_at).format("MM/DD/YYYY") : undefined,
        amount: "$" + parseFloat(ch.cost_per_item).toFixed(2),
      }));
    }
    if (cost_per_item) {
      return [
        {
          item: moment().format("MM/DD/YYYY"),
          amount: "$" + parseFloat(cost_per_item).toFixed(2),
        },
      ];
    }
    return [];
  }, [costHistory]);

  if (!purchase) {
    return null;
  }

  return (
    <Drawer
      title={purchase?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          {!openDelete && (
            <div className="resourceContent" style={{ flex: "unset", paddingLeft: "24px", paddingRight: "20px" }}>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Product No"} value={purchase.product_number} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Code No"} value={purchase.code_number} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Category"} value={categories[purchase.type]} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Brand"} value={purchase.brand} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"PAR Level"} value={purchase.par_level} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Bin Rate"} value={purchase.bin_rate} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Location"} value={purchase.location} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Specific Location"} value={purchase.specific_location} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Supplier E-Mail"} value={purchase.supplier_email} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Supplier Fax"} value={purchase.supplier_fax} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Quantity per unit"} value={purchase.quantity} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Unit"} value={purchase.unit} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Cost"} value={purchase.cost && parseFloat(purchase.cost).toFixed(2)} prefix={"$"} />
              </div>
              <div className="resourceInfo d-flex">
                <EditFormInput label={"Cost/Item"} value={cost_per_item} prefix={"$"} />
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem" }}>
                <b style={{ minWidth: "35%" }}>Cost History </b>
              </div>
              <div className="resourceInfo">
                <SubDataTable title={getTitle()} data={costRows} half={true} />
              </div>
              <hr />
              <div className="resourceInfo d-flex">
                <b style={{ minWidth: "35%" }}>Notes </b>
              </div>
              <div className="resourceInfo d-flex">
                <AutoCompleteComponent
                  title="purchase-inventory-notes"
                  rows={noteRows}
                  removeItem={removeNote}
                  addItem={addNote}
                  handleSearch={handleSearch}
                  disableOptions={true}
                  placeholder={"Information Note"}
                  disableSearchIcon={true}
                />
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "2rem", alignItems: "center" }}>
                <b style={{ marginRight: "0.25rem" }}>Purchase </b>
                <div style={{ width: "110px", minWidth: "110px", maxWidth: "110px" }}>
                  <EditFormInput label={"Number"} value={purchase.purchase_number} />
                </div>
                <b style={{ marginLeft: "0.25rem" }}>{` at ${purchase.cost ? "$" + parseFloat(purchase.cost).toFixed(2) : ""} ${
                  purchase.unit || ""
                }`}</b>
              </div>
              <hr />
            </div>
          )}

          {!openDelete && (
            <div
              className="actionContent"
              style={{
                marginTop: "1.5rem",
                paddingRight: "1rem",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  marginTop: "0.5rem",
                }}
                className="outlined-btn"
                onClick={() => setOpenDelete(true)}
              >
                Delete
              </button>
              <button
                style={{
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                }}
                onClick={orderPurchase}
              >
                Order
              </button>
            </div>
          )}

          {openDelete && (
            <>
              <div className="resourceInfo">
                <b style={{ width: "100%", textAlign: "center" }}>Do you really want to delete this Purchase?</b>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "1.5rem",
                  paddingRight: "1rem",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button className="outlined-btn" style={{ marginTop: "0.5rem" }} onClick={() => setOpenDelete(false)}>
                  Back
                </button>
                <button onClick={deletePurchase} className="outlined-btn" style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}>
                  Delete
                </button>
              </div>
            </>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default PurchaseListSidebar;

const data = {
  text_template: `{
	  "blocks": [
		  {
		  "key": "txHead",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "d9347",
		  "text": "DATE OF PROCEDURE",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 18,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 18,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text1",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "5td5s",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "7v8ns",
		  "text": "PREOERATIVE DIAGNOSIS",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 22,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 22,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text2",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "e01nq",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "24pjh",
		  "text": "POSTOPERATIVE DIAGNOSIS",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 24,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 24,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text3",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "37ke",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		"key": "kicd_cods",
		"text": "ICD CODE(S)",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [{
			"offset": 0,
			"length": 11,
			"style": "BOLD"
		}, {
			"offset": 0,
			"length": 11,
			"style": "UNDERLINE"
		}],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "text245",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		"key": "textSpicd",
		"text": "",
		"type": "unstyled",
		"depth": 0,
		"inlineStyleRanges": [],
		"entityRanges": [],
		"data": {}
	}, {
		  "key": "48o8a",
		  "text": "PROCEDURE PERFORMED",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 20,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 20,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text4",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "b16nu",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  },{
		  "key": "cknsk",
		  "text": "DISPOSITION",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 12,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 12,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text5",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "a9p8d",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "134ve",
		  "text": "COMPLICATIONS",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 14,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 14,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text6",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "48v4j",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "a4d7a",
		  "text": "ESTIMATED BLOOD LOSS",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 21,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 21,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text7",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "8mesm",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "a123a",
		  "text": "ANESTHESIA",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 11,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 11,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text11",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "8ca3sm",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "6rddso2",
		  "text": "INDICATIONS:",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 12,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 12,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "4i8qs",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "570ck",
		  "text": "SURGEON",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 8,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 8,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text9",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 0,
			  "style": ""
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "textstarttxt",
		  "text": "PROCEDURE PERFORMED",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 20,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 20,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "5td5x",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "sikcma",
		  "text": "SIGNATURE:",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 10,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 10,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "textsigsmall",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "textsigsmallempty",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "dimaks",
		  "text": "DATE FINALIZED:",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [{
			  "offset": 0,
			  "length": 15,
			  "style": "BOLD"
		  }, {
			  "offset": 0,
			  "length": 15,
			  "style": "UNDERLINE"
		  }],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text20startspace",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "text20",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }, {
		  "key": "textendtxt",
		  "text": "",
		  "type": "unstyled",
		  "depth": 0,
		  "inlineStyleRanges": [],
		  "entityRanges": [],
		  "data": {}
	  }],
	  "entityMap": {}
  }`,
};

export default data;

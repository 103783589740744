import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssignedDocuments,
  removeDataPatient,
  setPatientForRecords,
  setPatientSnapshotData,
  get_patient_notes,
  setDataPatient,
  setGlobalLoading,
} from "../../../store/actions";
import DataTable from "../../../common/components/DataTable/DataTable";
import TopBar from "../../../common/components/SubTopBar";
import { getLocal, setLocal } from "../../../helpers/Local";

const DocumentAssignment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const assignedDocuments = useSelector((state) => state.common.assignedDocuments);
  const authId = getLocal("cvai-current-user");

  const fetchApi = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getAssignedDocuments(authId));
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const populateDocument = async (patientID, docID) => {
    dispatch(setGlobalLoading(true));
    await dispatch(removeDataPatient());
    const result = await dispatch(setPatientForRecords(patientID));
    if (result) {
      await dispatch(setDataPatient(result));
    }
    await dispatch(setPatientSnapshotData("resource", result));
    await dispatch(get_patient_notes(patientID));
    await setLocal("current_patient_id", patientID);
    await setLocal("patient_document_id", docID);
    dispatch(setGlobalLoading(false));
    if (result) {
      history.push("/patient-records/clinical");
    }
  };

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Document Assignment" />
      <div className="clinicWorkflow__main-container">
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer pt-2">
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title="assigned_documents"
                    tableWidth={1120}
                    labels={["Patient Name", "Document Type", "Date Recieved"]}
                    rowData={assignedDocuments?.map((doc) => {
                      return {
                        patient_name: `${doc?.patient?.first_name} ${doc?.patient?.last_name}`,
                        type: doc?.filename,
                        date: doc?.document_date,
                        id: doc?.id,
                        patient_id: doc?.patient?.id,
                      };
                    })}
                    widths={["40%", "30%", "30%"]}
                    columns={[
                      { sortable: false, key: "patient_name" },
                      { sortable: false, key: "type" },
                      { sortable: false, key: "date" },
                    ]}
                    handleClickRow={(row) => populateDocument(row?.patient_id, row?.id)}
                    defaultActive={-1}
                    disableDelete
                    disableIcon
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentAssignment;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, DatePicker, Empty } from "antd";
import { ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import { getPriorAuthorization, search_insurance_companies, setGlobalLoading, setPriorData } from "../../../store/actions";
import Loading from "../../../common/components/Loading";
import moment from "moment";
import AutoCompleteSearch from "src/common/components/AutoComplete/AutoCompleteSearch";

const { Search } = Input;
const { Option } = Select;
let timer = null;
const PriorFilters = ({ filter, handleChangeFilter, valueSearch, searchState, dataOption, onSelectChoice, loading }) => {
  const types = useSelector((state) => state.prior.types);
  const authNumbers = useSelector((state) => state.prior.authNumbers);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);
  const [typeFill, setTypeFill] = useState(null);
  const search_insurance_companies_value = useSelector((state) => state.newPatient.search_insurance_companies);
  const loading_search_insurance_companies = useSelector((state) => state.newPatient.loading_search_insurance_companies);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(searchState);
  }, [searchState]);

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch && valueSearch(value);
  };

  useEffect(() => {
    setTypeFill(types || []);
  }, [types]);

  const onChangeDate = (date, dateString) => {
    const newFilter = {
      ...filter,
      date_of_service: dateString,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData("loadingPriors", true));
    dispatch(getPriorAuthorization(newFilter));
  };

  useEffect(() => {
    if (loadingLocal || loading_search_insurance_companies) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [loadingLocal, loading_search_insurance_companies]);

  const changeType = (value) => {
    if (value && value.includes("-")) {
      const valueSplit = value.split("-");
      const typeCheck = valueSplit[1];
      const valueCheck = valueSplit[0];
      const selectedType = (typeFill || []).find((type) => type?.id == valueCheck && type?.type == typeCheck);
      const newFilter = {
        ...filter,
        type: selectedType?.type,
        appointment_type_id: selectedType?.id,
      };
      handleChangeFilter(newFilter);
      dispatch(setPriorData("loadingPriors", true));
      dispatch(getPriorAuthorization(newFilter, 1));
    } else {
      const newFilter = {
        ...filter,
        type: null,
        appointment_type_id: null,
      };
      handleChangeFilter(newFilter);
      dispatch(setPriorData("loadingPriors", true));
      dispatch(getPriorAuthorization(newFilter, 1));
    }
  };

  const changeCode = (value) => {
    const newFilter = {
      ...filter,
      authorization_number: value,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData("loadingPriors", true));
    dispatch(getPriorAuthorization(newFilter, 1));
  };

  const changeInsurance = (value) => {
    const newFilter = {
      ...filter,
      insurance_company_id: value,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData("loadingPriors", true));
    dispatch(getPriorAuthorization(newFilter, 1));
  };

  const changeResponseStatus = (status) => {
    const newFilter = {
      ...filter,
      authorization_status: status,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData("loadingPriors", true));
    dispatch(getPriorAuthorization(newFilter, 1));
  };

  const changeExpiration = (date, dateString) => {
    const newFilter = {
      ...filter,
      expiration_date: dateString,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData("loadingPriors", true));
    dispatch(getPriorAuthorization(newFilter, 1));
  };

  const searchInsurance = (value) => {
    clearTimeout(timer);
    setLoadingLocal(true);
    timer = setTimeout(() => {
      setLoadingLocal(false);
      dispatch(search_insurance_companies(value));
    }, 500);
  };

  const rows = useMemo(
    () =>
      (dataOption || []).map((r) => ({
        id: r?.id,
        name: `${r?.first_name || ""} ${r?.last_name || ""} - ${r?.date_of_birth ? moment(r?.date_of_birth).format("MM/DD/YYYY") : ""}`,
      })),
    [dataOption]
  );

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <AutoCompleteSearch
            loading={loading}
            options={(rows || []).filter((r) => r?.name.toLowerCase().includes(search?.toLowerCase()))}
            value={search}
            onClear={onSelectChoice}
            handlePopulate={onSelectChoice}
            handleSearch={(val) => changeSearch(val)}
            placeholder="Search"
            actionLabel="Search"
            keyLabel="name"
            optionKey="id"
            backgroundWhite
            isModified
            disableSearchIcon={search ? true : false}
            customStyle={search ? true : false}
            className={"record-review-search"}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="typeFilter">
          <Select
            placeholder="Filter by Type..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeType}
            showSearch
            style={{ width: "calc(100% - 0.75rem)" }}
          >
            {(typeFill || []).map((type, index) => (
              <Option key={`${index}-${type?.id}-${type?.name}`} value={`${type?.id}-${type?.type}`}>
                {type?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="dateFilter">
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="Date of Service..."
            // defaultValue={moment(moment(), "MM/DD/YYYY")}
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate}
            style={{ width: "calc(100% - 0.75rem)" }}
          />
        </div>
        <div className="resultFilter">
          <Select
            placeholder={"Insurance..."}
            showSearch
            style={{ width: "calc(100% - 0.75rem)" }}
            optionFilterProp="children"
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onFocus={() => {
              setStateFocus(true);
            }}
            onBlur={() => {
              setStateFocus(false);
            }}
            filterOption={(inp, opt) => opt.props.children.toLowerCase().indexOf(inp.toLowerCase()) >= 0}
            notFoundContent={
              loading_search_insurance_companies ? (
                <div style={{ height: 100 }}>
                  <Loading />
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            onSearch={(value) => {
              searchInsurance(value);
            }}
            onChange={changeInsurance}
          >
            {(search_insurance_companies_value || []).map((item, index) => (
              <Option key={item?.id} value={item?.id}>
                {`${item?.name}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter">
          <Select
            placeholder="Authorization Code..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeCode}
            style={{ width: "calc(100% - 0.75rem)" }}
          >
            {(authNumbers || []).map((code, index) => (
              <Option key={`auth-code-${code.authorization_number}-${index}`} value={code.authorization_number}>
                {code.authorization_number}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter">
          <DatePicker
            format={"MM/DD/YYYY"}
            placeholder="Expiration Date..."
            suffixIcon={<ExpandSVG />}
            onChange={changeExpiration}
            style={{ width: "calc(100% - 0.75rem)" }}
          />
        </div>
        <div className="resultFilter">
          <Select
            placeholder="Authorization Code Result..."
            suffixIcon={<ExpandSVG />}
            onChange={changeResponseStatus}
            allowClear={true}
            style={{ width: "100%" }}
          >
            {[
              { authorization_result: "Authorization Received", key: "authorization_received" },
              { authorization_result: "Authorization Required", key: "authorization_required" },
              { authorization_result: "Processing", key: "processing" },
            ].map((code, index) => (
              <Option key={`auth-code-${code.authorization_result}-${index}`} value={code.key}>
                {code.authorization_result}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PriorFilters;

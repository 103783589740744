import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

/*
// already defined on patientSchedule
export const getPatientSchedules = createActionThunk(
  AT.GET_PATIENT_SCHEDULES,
  ({ date, location, provider_id, patient_id }) =>
    API.getPatientSchedules({ date, location, provider_id, patient_id })
);

// already defined on patientSchedule
export const searchPatientSchedules = (searchValue) => (dispatch) =>
  dispatch({
    type: AT.SEARCH_PATIENT_SCHEDULES,
    payload: {
      searchValue,
    },
  });
*/

export const setUltrasoundData = createActionThunk(AT.SET_ULTRASOUND_DATA, (type, value) => ({ [type]: value }));

export const save_new_ultrasound = createActionThunk(AT.SAVE_NEW_ULTRASOUND, (param) => API.save_new_ultrasound(param));

export const getUltrasoundEvents = createActionThunk(AT.GET_ULTRASOUND_EVENTS, ({ date, location, provider }) =>
  API.getUltrasoundEvents({ date, location, provider })
);

export const get_ultrasound_question = createActionThunk(AT.GET_ULTRASOUND_QUESTION, (id) => API.get_ultrasound_question(id));

export const post_ultrasound_question = createActionThunk(AT.POST_ULTRASOUND_QUESTION, (param) => API.post_ultrasound_question(param));

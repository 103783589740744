import React, { useState } from "react";
import TopBar from "../../../common/components/SubTopBar";
import { ProcedureDetailsHeaders } from "../constant";
import OldConditionDetail from "./Procedure/OldConditionDetail";
import OldProcedureCondition from "./Procedure/OldProcedureCondition";

function OldProcedureDetails() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Procedure Details" subStatus={""} isBarcode={false} />
      <div className="clinicWorkflow__main-container">
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders">
            {ProcedureDetailsHeaders.map((tab, index) => (
              <div
                key={`tabHeaders-${tab.label}-${index}`}
                className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                style={{ width: tab.width }}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
        {activeTab === 0 && <OldProcedureCondition />}
        {activeTab === 1 && <OldConditionDetail />}
      </div>
    </div>
  );
}

export default OldProcedureDetails;

import React, { useEffect, useState } from "react";
import { BloodVessel } from "../icons/BloodVessel";
import "./style.scss";

const VesselBody = (props) => {
  const onChangeState = (field, state) => {
    props.onChangeState(field, state);
  };

  return (
    <div className={"blood-veseel-wrapper"}>
      <div className="bodyIcon">
        <BloodVessel />
      </div>
      {props.dots.map((node, index) => (
        <StateNode node={node} newError={props?.newError} key={`state-node-${index}`} onChangeState={onChangeState} isLocked={props.isLocked} />
      ))}
    </div>
  );
};

const StateNode = (props) => {
  const [node, setNode] = useState(undefined);
  const [errorActive, setErrorActive] = useState(false);
  useEffect(() => {
    setNode(props.node);
  }, [props.node]);
  const onClickListItem = (newState) => {
    const node_ = { ...node };
    node_["state"] = newState;
    setNode(node_);
    props.onChangeState(node.field, newState);
  };

  useEffect(() => {
    if (props?.newError && node?.label) {
      if (props?.newError?.left && props?.newError?.right) {
        if (["(L) DP", "(L) Popliteal", "(L) PT", "(R) DP", "(R) Popliteal", "(R) PT"].indexOf(node.label) > -1 && node.state === "2") {
          setErrorActive(true);
        } else {
          setErrorActive(false);
        }
      } else if (props?.newError?.right) {
        if (["(R) DP", "(R) Popliteal", "(R) PT"].indexOf(node.label) > -1 && node.state === "2") {
          setErrorActive(true);
        } else {
          setErrorActive(false);
        }
      } else if (props?.newError?.left) {
        if (["(L) DP", "(L) Popliteal", "(L) PT"].indexOf(node.label) > -1 && node.state === "2") {
          setErrorActive(true);
        } else {
          setErrorActive(false);
        }
      }
    }
  }, [props.newError, node]);
  if (!node) return <></>;

  return (
    <div
      className="nodeState"
      style={{
        left: node.left ? node.left + "px" : "auto",
        top: node.top ? node.top + "%" : "auto",
        right: node.right ? node.right + "px" : "auto",
        bottom: node.bottom ? node.bottom + "%" : "auto",
      }}
      title={node.field}
    >
      <div className={`nodeState__text ${props.isLocked == 0 && "not-locked"}`}>
        {`${node.label}`}

        {["0", "1", "2", "D", "X"].includes(node.state) && (
          <div
            className="nodeState__active_dropdown"
            style={{
              right: node.rightDir ? "-1px" : "unset",
              left: node.rightDir ? "unset" : "-1px",
              transform: node.rightDir ? "translateX(100%)" : "translateX(-100%)",
            }}
          >
            <p className={`${errorActive ? "error" : "active"}`}>{node.state}</p>
          </div>
        )}

        <div
          className="nodeState__dropdown"
          style={{
            right: node.rightDir ? "-1px" : "unset",
            left: node.rightDir ? "unset" : "-1px",
            transform: node.rightDir ? "translateX(100%)" : "translateX(-100%)",
          }}
        >
          {["0", "1", "2", "D", "X"].map((state, index2) => (
            <p
              onClick={() => onClickListItem(state)}
              key={`nodestate-dropdown-${index2}`}
              className={state === node.state && !errorActive ? "active" : state === "2" ? "error" : "active"}
            >
              {state}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VesselBody;

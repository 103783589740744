import { Tabs } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import { TabHeaders } from "../constant";
import CME from "./employeeModules/CME";
import ContributionMargin from "./employeeModules/ContributionMargin";
import Dashboard from "./employeeModules/Dashboard";
import Evaluations from "./employeeModules/Evaluations";
import ManualPolicies from "./employeeModules/ManualPolicies";
import ProviderContribution from "./employeeModules/ProviderContribution";
import Reporting from "./employeeModules/Reporting";
import TimeOff from "./employeeModules/TimeOff";
export class EmployeeModule extends Component {
  state = {
    activeTab: 0,
  };

  render() {
    const { activeTab } = this.state;
    const { currentUserInfo } = this.props;
    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="Employee Module" subStatus={""} />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {TabHeaders.map((tab, index) => {
                  if ((currentUserInfo && currentUserInfo.status === 1 && currentUserInfo.is_pcm === 1) || currentUserInfo?.is_admin === 1) {
                    return (
                      <div
                        key={`tabHeaders-${tab.label}-${index}`}
                        className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                        style={{ width: tab.width }}
                        onClick={() => this.setState({ activeTab: index })}
                      >
                        {tab.label}
                      </div>
                    );
                  } else {
                    if (tab.label === "Provider Contribution Margin") {
                      return null;
                    } else {
                      return (
                        <div
                          key={`tabHeaders-${tab.label}-${index}`}
                          className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                          style={{ width: tab.width }}
                          onClick={() => this.setState({ activeTab: index })}
                        >
                          {tab.label}
                        </div>
                      );
                    }
                  }
                })}
              </Tabs>
            </div>
          </div>
          {activeTab === 0 && <Dashboard title="employee-dashboard" />}
          {activeTab === 1 && <TimeOff title="employee-timeoff" />}
          {activeTab === 2 && <Reporting title="employee-reporting" />}
          {activeTab === 3 && <Evaluations title="employee-evaluations" />}
          {activeTab === 4 && <ManualPolicies title="employee-manual-policies" />}
          {activeTab === 5 && <CME title="employee-cme" />}
          {activeTab === 6 && <ContributionMargin title="contribution-margin" />}
          {activeTab === 7 && <ProviderContribution title="employee-provider-contribution" />}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    currentUserInfo: state.common.currentUserInfo,
    isSidebar: state.sidebar.isSidebarOpen,
  };
}

export default connect(mapStateToProps, null)(EmployeeModule);

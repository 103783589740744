import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  communications: [],
  loadingCommunications: false,
  resource: null,
  loadingResource: false,
  loadingPatient: false,
  loadingFaxLogs: false,
  loadingScanLogs: false,
  fax_logs_data: {},
  scan_logs: [],
  detail_fax_logs: {},
  loadingDetailLogs: false,
  print_fax: {},
  print_scan: {},
  filter_fax_logs: [],
  detail_scan_logs: {},
  print_patient_document: {},
  loading_print_patient_document: false,
  isToast: false,
  resourceHospital: [],
  paramsChart: {},
  indicationsValue: {},
  text_param: "",
  charting_pdf: {},
  cpt_rules: [],
  count_success: 0,
  count_failure: 0,
  subPro: null,
};

const GET_COMMUNICATION_RECORDS_SUCCEEDED = `${AT.GET_COMMUNICATION_RECORDS}_SUCCEEDED`;
const GET_FAX_LOGS_SUCCEEDED = `${AT.GET_FAX_LOGS}_SUCCEEDED`;
const GET_SORTED_FAX_LOGS_SUCCEEDED = `${AT.GET_SORTED_FAX_LOGS}_SUCCEEDED`;
const SAVE_FAX_TO_DOCUMENT_SUCCEEDED = `${AT.SAVE_FAX_TO_DOCUMENT}_SUCCEEDED`;
const DELETE_FAX_LOG_SUCCEEDED = `${AT.DELETE_FAX_LOG}_SUCCEEDED`;
const ASSIGN_FAX_SUCCEEDED = `${AT.ASSIGN_FAX}_SUCCEEDED`;
const EMPTY_FAX_LOGS_SUCCEEDED = `${AT.EMPTY_FAX_LOGS}_SUCCEEDED`;
const RESET_STATE_RESOURCE_SUCCEEDED = `${AT.RESET_STATE_RESOURCE}_SUCCEEDED`;
const GET_SCAN_LOGS_SUCCEEDED = `${AT.GET_SCAN_LOGS}_SUCCEEDED`;
const GET_SORTED_SCAN_LOGS_SUCCEEDED = `${AT.GET_SORTED_SCAN_LOGS}_SUCCEEDED`;
const SAVE_SCAN_TO_DOCUMENT_SUCCEEDED = `${AT.SAVE_SCAN_TO_DOCUMENT}_SUCCEEDED`;
const DETAIL_FAX_LOG_SUCCEEDED = `${AT.DETAIL_FAX_LOG}_SUCCEEDED`;
const EMPTY_SCAN_LOGS_SUCCEEDED = `${AT.EMPTY_SCAN_LOGS}_SUCCEEDED`;
//
const PATIENT_DOCUMENT_DELETE_MULTIPLE_STARTED = `${AT.PATIENT_DOCUMENT_DELETE_MULTIPLE}_STARTED`;

const PATIENT_DOCUMENT_DELETE_MULTIPLE_SUCCEEDED = `${AT.PATIENT_DOCUMENT_DELETE_MULTIPLE}_SUCCEEDED`;

// Detail Fax Log state using Started
const DETAIL_FAX_LOG_STARTED = `${AT.DETAIL_FAX_LOG}_STARTED`;
const DETAIL_SCAN_LOG_STARTED = `${AT.DETAIL_SCAN_LOG}_STARTED`;

const FILTER_FAX_LOG_SUCCEEDED = `${AT.FILTER_FAX_LOG}_SUCCEEDED`;
const DETAIL_SCAN_LOG_SUCCEEDED = `${AT.DETAIL_SCAN_LOG}_SUCCEEDED`;
const SET_PATIENT_RECORD_DATA_SUCCEEDED = `${AT.SET_PATIENT_RECORD_DATA}_SUCCEEDED`;
const SET_PATIENT_FOR_RECORDS_STARTED = `${AT.SET_PATIENT_FOR_RECORDS}_STARTED`;
const SET_PATIENT_FOR_RECORDS_SUCCEEDED = `${AT.SET_PATIENT_FOR_RECORDS}_SUCCEEDED`;
// Get initial Patient resource
const GET_INITIAL_PATIENT_DATA_STARTED = `${AT.GET_INITIAL_PATIENT_DATA}_STARTED`;
const GET_INITIAL_PATIENT_DATA_SUCCEEDED = `${AT.GET_INITIAL_PATIENT_DATA}_SUCCEEDED`;
const CLEAR_DEATAIL_FAX_LOG_SUCCEEDED = `${AT.CLEAR_DEATAIL_FAX_LOG}_SUCCEEDED`;
const CLEAR_DEATAIL_SCAN_LOG_SUCCEEDED = `${AT.CLEAR_DEATAIL_SCAN_LOG}_SUCCEEDED`;
const PRINT_PATIENT_DOCUMENT_STARTED = `${AT.PRINT_PATIENT_DOCUMENT}_STARTED`;
const PRINT_PATIENT_DOCUMENT_SUCCEEDED = `${AT.PRINT_PATIENT_DOCUMENT}_SUCCEEDED`;
const PRINT_FAX_SUCCEEDED = `${AT.PRINT_FAX}_SUCCEEDED`;
const PRINT_SCAN_SUCCEEDED = `${AT.PRINT_SCAN}_SUCCEEDED`;
const SET_DEFAULT_TOAST_SUCCEEDED = `${AT.SET_DEFAULT_TOAST}_SUCCEEDED`;

const EMAIL_PATIENT_DOCUMENT_STARTED = `${AT.EMAIL_PATIENT_DOCUMENT}_STARTED`;
const EMAIL_PATIENT_DOCUMENT_SUCCEEDED = `${AT.EMAIL_PATIENT_DOCUMENT}_SUCCEEDED`;
const EMAIL_PATIENT_DOCUMENT_BULK_STARTED = `${AT.EMAIL_PATIENT_DOCUMENT_BULK}_STARTED`;
const EMAIL_PATIENT_DOCUMENT_BULK_SUCCEEDED = `${AT.EMAIL_PATIENT_DOCUMENT_BULK}_SUCCEEDED`;
const PRINT_PATIENT_DOCUMENT_BULK_STARTED = `${AT.PRINT_PATIENT_DOCUMENT_BULK}_STARTED`;
const PRINT_PATIENT_DOCUMENT_BULK_SUCCEEDED = `${AT.PRINT_PATIENT_DOCUMENT_BULK}_SUCCEEDED`;
const FAX_PATIENT_DOCUMENT_BULK_STARTED = `${AT.FAX_PATIENT_DOCUMENT_BULK}_STARTED`;
const FAX_PATIENT_DOCUMENT_BULK_SUCCEEDED = `${AT.FAX_PATIENT_DOCUMENT_BULK}_SUCCEEDED`;

const SEND_FAX_PATIENT_DOCUMENT_STARTED = `${AT.SEND_FAX_PATIENT_DOCUMENT}_STARTED`;
const SEND_FAX_PATIENT_DOCUMENT_SUCCEEDED = `${AT.SEND_FAX_PATIENT_DOCUMENT}_SUCCEEDED`;

const DELETE_PATIENT_DOCUMENT_SUCCEEDED = `${AT.DELETE_PATIENT_DOCUMENT}_SUCCEEDED`;
const DELETE_PATIENT_DOCUMENT_STARTED = `${AT.DELETE_PATIENT_DOCUMENT}_STARTED`;

const RESET_FAX_SCAN_DOCUMENT_LOG_SUCCEEDED = `${AT.RESET_FAX_SCAN_DOCUMENT_LOG}_SUCCEEDED`;

const GET_PATIENT_HOSPITAL_SUCCEEDED = `${AT.GET_PATIENT_HOSPITAL}_SUCCEEDED`;
const GET_PATIENT_HOSPITAL_STARTED = `${AT.GET_PATIENT_HOSPITAL}_STARTED`;

const SAVE_IN_PATIENT_CHARTING_SUCCEEDED = `${AT.SAVE_IN_PATIENT_CHARTING}_SUCCEEDED`;
const SAVE_IN_PATIENT_CHARTING_STARTED = `${AT.SAVE_IN_PATIENT_CHARTING}_STARTED`;
const UPDATE_IN_PATIENT_CHARTING_SUCCEEDED = `${AT.UPDATE_IN_PATIENT_CHARTING}_SUCCEEDED`;
const UPDATE_IN_PATIENT_CHARTING_STARTED = `${AT.UPDATE_IN_PATIENT_CHARTING}_STARTED`;

const SET_PARAMS_CHART_SUCCEEDED = `${AT.SET_PARAMS_CHART}_SUCCEEDED`;
const REMOVE_PARAMS_CHART_SUCCEEDED = `${AT.REMOVE_PARAMS_CHART}_SUCCEEDED`;

const CHARTING_GENERATE_PDF_SUCCEEDED = `${AT.CHARTING_GENERATE_PDF}_SUCCEEDED`;
const CHARTING_GENERATE_PDF_STARTED = `${AT.CHARTING_GENERATE_PDF}_STARTED`;
const RESET_CHARTING_PDF_SUCCEEDED = `${AT.RESET_CHARTING_PDF}_SUCCEEDED`;

const APPLY_CPT_RULES_STARTED = `${AT.APPLY_CPT_RULES}_STARTED`;
const APPLY_CPT_RULES_SUCCEEDED = `${AT.APPLY_CPT_RULES}_SUCCEEDED`;
const RESET_CPT_RULES_SUCCEEDED = `${AT.RESET_CPT_RULES}_SUCCEEDED`;

const SAVE_FINALIZE_IN_PATIENT_CHARTING_STARTED = `${AT.SAVE_FINALIZE_IN_PATIENT_CHARTING}_STARTED`;
const SAVE_FINALIZE_IN_PATIENT_CHARTING_SUCCEEDED = `${AT.SAVE_FINALIZE_IN_PATIENT_CHARTING}_SUCCEEDED`;

const REMOVE_STATE_DELETE_CHART_SUCCESS = `${AT.REMOVE_STATE_DELETE_CHART}_SUCCEEDED`;

export default createReducer(initialState, {
  [RESET_STATE_RESOURCE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      resource: null,
    });
  },

  [REMOVE_STATE_DELETE_CHART_SUCCESS](state, action) {
    return Object.assign({}, state, {
      indicationsValue: {},
    });
  },
  [PATIENT_DOCUMENT_DELETE_MULTIPLE_STARTED](state, action) {
    return Object.assign({}, state, { loading_print_patient_document: true });
  },
  [PATIENT_DOCUMENT_DELETE_MULTIPLE_SUCCEEDED](state, action) {
    return Object.assign({}, state, { loading_print_patient_document: false });
  },
  //
  [RESET_CPT_RULES_SUCCEEDED](state, action) {
    return Object.assign({}, state, { cpt_rules: [] });
  },
  [APPLY_CPT_RULES_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [APPLY_CPT_RULES_SUCCEEDED](state, action) {
    const cpt_rules = action?.payload;
    return Object.assign({}, state, {
      loadingResource: false,
      cpt_rules,
    });
  },
  [RESET_CHARTING_PDF_SUCCEEDED](state, action) {
    return Object.assign({}, state, { charting_pdf: {} });
  },
  [CHARTING_GENERATE_PDF_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [CHARTING_GENERATE_PDF_SUCCEEDED](state, action) {
    const charting_pdf = action?.payload;
    return Object.assign({}, state, {
      loadingResource: false,
      charting_pdf,
    });
  },
  [SAVE_FINALIZE_IN_PATIENT_CHARTING_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [SAVE_FINALIZE_IN_PATIENT_CHARTING_SUCCEEDED](state, action) {
    const charting_pdf = action?.payload;
    return Object.assign({}, state, {
      loadingResource: false,
      charting_pdf,
    });
  },
  [REMOVE_PARAMS_CHART_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      paramsChart: {},
      indicationsValue: {},
      text_param: "",
      charting_pdf: {},
    });
  },
  [SET_PARAMS_CHART_SUCCEEDED](state, action) {
    return {
      ...state,
      ...action?.payload,
    };
  },
  [UPDATE_IN_PATIENT_CHARTING_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [UPDATE_IN_PATIENT_CHARTING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingResource: false,
    });
  },
  [SAVE_IN_PATIENT_CHARTING_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [SAVE_IN_PATIENT_CHARTING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingResource: false,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [RESET_FAX_SCAN_DOCUMENT_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      print_fax: {},
      print_scan: {},
      print_patient_document: {},
      isToast: false,
    });
  },
  [DELETE_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      isToast: false,
      loading_print_patient_document: true,
    });
  },
  [DELETE_PATIENT_DOCUMENT_SUCCEEDED](state) {
    return Object.assign({}, state, {
      isToast: true,
      loading_print_patient_document: false,
    });
  },
  [SET_DEFAULT_TOAST_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isToast: false,
    });
  },
  [EMAIL_PATIENT_DOCUMENT_STARTED](state, action) {
    return Object.assign({}, state, {
      isToast: false,
      loading_print_patient_document: true,
    });
  },
  [EMAIL_PATIENT_DOCUMENT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isToast: true,
      loading_print_patient_document: false,
    });
  },
  [EMAIL_PATIENT_DOCUMENT_BULK_STARTED](state, action) {
    return Object.assign({}, state, {
      isToast: false,
      loading_print_patient_document: true,
    });
  },
  [EMAIL_PATIENT_DOCUMENT_BULK_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isToast: true,
      loading_print_patient_document: false,
    });
  },
  [FAX_PATIENT_DOCUMENT_BULK_STARTED](state, action) {
    return Object.assign({}, state, {
      isToast: false,
      loading_print_patient_document: true,
    });
  },
  [FAX_PATIENT_DOCUMENT_BULK_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isToast: true,
      loading_print_patient_document: false,
    });
  },
  [SEND_FAX_PATIENT_DOCUMENT_STARTED](state, action) {
    return Object.assign({}, state, {
      isToast: false,
      loading_print_patient_document: true,
    });
  },
  [SEND_FAX_PATIENT_DOCUMENT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      isToast: true,
      loading_print_patient_document: false,
    });
  },
  [PRINT_PATIENT_DOCUMENT_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_print_patient_document: true,
      print_patient_document: {},
    });
  },
  [PRINT_PATIENT_DOCUMENT_BULK_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_print_patient_document: true,
      print_patient_document: {},
    });
  },
  [CLEAR_DEATAIL_FAX_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      detail_fax_logs: [],
    });
  },
  [CLEAR_DEATAIL_SCAN_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      detail_scan_logs: [],
    });
  },
  [PRINT_FAX_SUCCEEDED](state, action) {
    const print_fax = action?.payload;
    return Object.assign({}, state, {
      loading_print_patient_document: false,
      isToast: true,
      print_fax,
    });
  },
  [PRINT_PATIENT_DOCUMENT_BULK_SUCCEEDED](state, action) {
    const print_patient_document = action?.payload;
    return Object.assign({}, state, {
      loading_print_patient_document: false,
      isToast: true,
      print_patient_document,
    });
  },
  [PRINT_SCAN_SUCCEEDED](state, action) {
    const print_scan = action?.payload;
    return Object.assign({}, state, {
      loading_print_patient_document: false,
      isToast: true,
      print_scan,
    });
  },
  [PRINT_PATIENT_DOCUMENT_SUCCEEDED](state, action) {
    const print_patient_document = action?.payload;
    return Object.assign({}, state, {
      loading_print_patient_document: false,
      print_patient_document,
    });
  },
  [FILTER_FAX_LOG_SUCCEEDED](state, action) {
    const filter_fax_logs = action?.payload;
    return Object.assign({}, state, {
      filter_fax_logs,
    });
  },
  // Add loading state
  [DETAIL_FAX_LOG_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingDetailLogs: true,
      detail_fax_logs: {},
    });
  },
  [DETAIL_SCAN_LOG_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingDetailLogs: true,
      detail_scan_logs: {},
    });
  },
  [DETAIL_FAX_LOG_SUCCEEDED](state, action) {
    const detail_fax_logs = action?.payload;
    return Object.assign({}, state, {
      detail_fax_logs,
      loadingDetailLogs: false,
    });
  },
  [DETAIL_SCAN_LOG_SUCCEEDED](state, action) {
    const detail_scan_logs = action?.payload;
    return Object.assign({}, state, {
      detail_scan_logs,
      loadingDetailLogs: false,
    });
  },
  ["GET_FAX_LOGS_STARTED"](state, action) {
    return Object.assign({}, state, {
      loadingFaxLogs: true,
    });
  },
  ["GET_FAX_LOGS_ENDED"](state, action) {
    return Object.assign({}, state, {
      loadingFaxLogs: false,
    });
  },
  [GET_FAX_LOGS_SUCCEEDED](state, action) {
    const fax_logs = action?.payload;
    if (fax_logs && fax_logs?.data?.length > 0) {
      return Object.assign({}, state, {
        fax_logs_data: {
          ...fax_logs,
        },
      });
    } else {
      return {
        ...state,
        fax_logs_data: {
          ...fax_logs,
          data: [],
        },
      };
    }
  },
  ["GET_SORTED_FAX_LOGS_STARTED"](state, action) {
    return Object.assign({}, state, {
      loadingFaxLogs: true,
    });
  },
  [GET_SORTED_FAX_LOGS_SUCCEEDED](state, action) {
    const fax_logs = action?.payload;
    return Object.assign({}, state, {
      fax_logs_data: {
        ...fax_logs,
      },
      loadingFaxLogs: false,
    });
  },
  ["SAVE_FAX_TO_DOCUMENT_STARTED"](state, _) {
    return Object.assign({}, state, {
      loadingFaxLogs: true,
    });
  },
  ["SAVE_FAX_TO_DOCUMENT_ENDED"](state, _) {
    return Object.assign({}, state, {
      loadingFaxLogs: false,
    });
  },
  [SAVE_FAX_TO_DOCUMENT_SUCCEEDED](state, action) {
    const { id } = action?.payload || {};
    const fax_logs = state?.fax_logs_data?.data?.filter((fax_log) => fax_log?.id !== id);
    return {
      ...state,
      fax_logs_data: {
        ...state?.fax_logs_data,
        data: fax_logs,
      },
    };
  },
  [DELETE_FAX_LOG_SUCCEEDED](state, action) {
    const { id } = action?.payload || {};
    const fax_logs = state?.fax_logs_data?.data?.filter((fax_log) => fax_log?.id !== id);
    return {
      ...state,
      fax_logs_data: {
        ...state?.fax_logs_data,
        data: fax_logs,
      },
    };
  },
  [ASSIGN_FAX_SUCCEEDED](state, action) {
    const { id } = action?.payload || {};
    const fax_logs = state.fax_logs_data?.data?.filter((fax_log) => fax_log?.id !== id);
    return {
      ...state,
      fax_logs_data: {
        ...state?.fax_logs_data,
        data: fax_logs,
      },
    };
  },
  [EMPTY_FAX_LOGS_SUCCEEDED](state, _) {
    return Object.assign({}, state, {
      fax_logs_data: {
        ...state?.fax_logs_data,
        data: [],
      },
    });
  },
  ["GET_SCAN_LOGS_STARTED"](state, action) {
    return Object.assign({}, state, {
      loadingScanLogs: true,
    });
  },
  ["GET_SCAN_LOGS_ENDED"](state, action) {
    return Object.assign({}, state, {
      loadingScanLogs: false,
    });
  },
  [GET_SCAN_LOGS_SUCCEEDED](state, action) {
    const scan_logs = action?.payload;
    return Object.assign({}, state, {
      scan_logs,
      loadingScanLogs: false,
    });
  },
  ["GET_SORTED_SCAN_LOGS_STARTED"](state, action) {
    return Object.assign({}, state, {
      loadingScanLogs: true,
    });
  },
  [GET_SORTED_SCAN_LOGS_SUCCEEDED](state, action) {
    const scan_logs = action?.payload;
    return Object.assign({}, state, {
      scan_logs,
      loadingScanLogs: false,
    });
  },
  ["SAVE_SCAN_TO_DOCUMENT_STARTED"](state, _) {
    return Object.assign({}, state, {
      loadingScanLogs: true,
    });
  },
  ["SAVE_SCAN_TO_DOCUMENT_ENDED"](state, _) {
    return Object.assign({}, state, {
      loadingScanLogs: false,
    });
  },
  [SAVE_SCAN_TO_DOCUMENT_SUCCEEDED](state, action) {
    const { id } = action?.payload || {};
    const scan_logs = state.scan_logs?.filter((scan_log) => scan_log?.id !== id);
    return {
      ...state,
      scan_logs,
    };
  },
  [EMPTY_SCAN_LOGS_SUCCEEDED](state, _) {
    return {
      ...state,
      scan_logs: [],
    };
  },
  [SET_PATIENT_RECORD_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, { ...action?.payload });
  },
  ["GET_COMMUNICATION_RECORDS_STARTED"](state, action) {
    return Object.assign({}, state, {
      loadingCommunications: true,
    });
  },
  ["GET_COMMUNICATION_RECORDS_ENDED"](state, action) {
    return Object.assign({}, state, {
      loadingCommunications: false,
    });
  },
  [GET_COMMUNICATION_RECORDS_SUCCEEDED](state, action) {
    const communications = action?.payload?.data;
    const count_success = action?.payload?.meta.count_success;
    const count_failure = action?.payload?.meta.count_failure;
    return Object.assign({}, state, {
      communications,
      count_success,
      count_failure,
    });
  },
  [SET_PATIENT_FOR_RECORDS_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPatient: true,
      communications: [],
    });
  },
  [SET_PATIENT_FOR_RECORDS_SUCCEEDED](state, action) {
    const resource = action?.payload;
    return Object.assign({}, state, { resource, loadingPatient: false });
  },
  [GET_INITIAL_PATIENT_DATA_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [GET_INITIAL_PATIENT_DATA_SUCCEEDED](state, action) {
    const resource = action?.payload;
    return Object.assign({}, state, { resource, loadingResource: false });
  },
  [GET_PATIENT_HOSPITAL_STARTED](state, action) {
    return Object.assign({}, state, { loadingResource: true });
  },
  [GET_PATIENT_HOSPITAL_SUCCEEDED](state, action) {
    const resourceHospital = action?.payload;
    return Object.assign({}, state, {
      loadingResource: false,
      resourceHospital,
    });
  },
});

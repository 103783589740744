import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import MainContainer from "./Administrator";
import { sideNavLinks } from "./constant";
import { collapseSideNav, setDataLocalChangeRecord } from "../../store/actions";
import { removeLocal } from "../../helpers/Local";

const Administrator = ({ location }) => {
  const dispatch = useDispatch();
  const [isSideNav, setIsSideNav] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSideNav(false);
    }
  }, []);

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Administrator</title>
        <meta name="title" content="FAACT Vascular | Administrator" />
      </Helmet>
    );
  };

  const openSideNav = () => {
    setIsSideNav(true);
    dispatch(collapseSideNav(false));
  };

  const closeSideNav = () => {
    setIsSideNav(false);
    dispatch(collapseSideNav(true));
  };

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Administrator
    </div>
  );
  const onLinkClick = (link) => {
    if (link && link.includes("records-review")) {
      removeLocal("prams_records_review");
      dispatch(setDataLocalChangeRecord(null));
    }
    if (sideNavLinks.find((v) => v.link === link)) {
      closeSideNav();
    }
  };

  return (
    <div className="clinicWorkflow">
      {renderMetadata()}
      <SubSideNav
        subMenu={sideNavLinks}
        title={getTitle()}
        isSideNav={isSideNav}
        openSideNav={openSideNav}
        closeSideNav={closeSideNav}
        onLinkClick={onLinkClick}
      />
      <MainContainer location={location} isSideNav={isSideNav} />
    </div>
  );
};

export default Administrator;

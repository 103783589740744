import { Checkbox, Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../common/components/DataTable/WhiteHeaderTable";
import ScrollWrapper from "../../common/components/ScrollBar";
import { getPatientDocumentDetails } from "../../store/actions";

import ToastMessage from "../../common/components/Toast";

const PatientChartSideBar = React.forwardRef(({ isShow, resource, onClose }, ref) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [isToastr] = useState(false);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);

  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedIDRows, setCheckedIDRows] = useState([]);
  const [sortOption, setSortOption] = useState({
    date: "",
    document_category: "",
  });

  useEffect(() => {
    if (resource.length) {
      dispatch(getPatientDocumentDetails(resource[0].id));
    }
  }, [resource]);

  const handleRow = (row) => {
    dispatch(getPatientDocumentDetails(row?.id));
  };

  const handleDelete = (id) => {};

  const handleCheckRows = (idx, item) => {
    const checksID = [...checkedIDRows];
    const currentCheckID = checksID.findIndex((check) => check === item?.id);

    const checks = [...checkedRows];
    const currentCheck = checks.findIndex((check) => check === idx);

    if (currentCheck === -1) {
      checks.push(idx);
      checksID.push(item?.id);
    } else {
      checks.splice(currentCheck, 1);
      checksID.splice(currentCheckID, 1);
    }
    setCheckedRows(checks);
    setCheckedIDRows(checksID);
  };

  const emptyRows = useMemo(() => {
    if (!tableRef || !tableRef.current) {
      return [];
    }
    const totalRowsCount = Math.floor((tableRef.current.offsetHeight - 50) / 40);
    const emptyRowsCount = totalRowsCount - resource.length;
    if (emptyRowsCount < 1) {
      return [];
    }
    return new Array(emptyRowsCount).fill({ resource: null });
  }, [resource]);

  const showToast = () => {
    return <ToastMessage type="Document" status={status ? "success" : "failed"} msg={message ? message : "Update Successfully!"} />;
  };

  return (
    <Drawer title="Document Database" placement="right" closable onClose={onClose} mask={false} visible={isShow}>
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              height: "calc(100% - 10px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="resourceInfo d-flex" style={{ flex: 1, width: "100%", flexDirection: "column" }}>
              <div
                ref={tableRef}
                style={{
                  height: "calc(100% - 10px)",
                  minHeight: "25vh",
                  width: "100%",
                }}
              >
                <DataTable
                  isRmBorders={true}
                  title="patient_chart_imagelist"
                  isNormal
                  labels={["Date", "Document Category"]}
                  widths={["35%", "65%"]}
                  fullHeight
                  activeIndex={0}
                  showIcon={false}
                  columns={[
                    { sortable: true, key: "date" },
                    { sortable: true, key: "filename" },
                  ]}
                  handleClickRow={(row) => handleRow(row)}
                  rowData={resource?.map((doc, index) => ({
                    key: `document-list-${index}`,
                    id: doc?.id,
                    date: (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Checkbox
                          checked={checkedRows.includes(index)}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleCheckRows(index, doc);
                          }}
                        />
                        <p style={{ paddingLeft: "1rem" }}>{moment(doc?.document_date, "YYYY/MM/DD hh:mm:ss").format("MM/DD/YYYY")}</p>
                      </div>
                    ),
                    filename: (
                      <div
                        style={{
                          textTransform: "capitalize",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {doc?.filename}
                      </div>
                    ),
                  }))}
                  emptyRows={emptyRows}
                  handleDelete={handleDelete}
                  sorts={[sortOption.date, sortOption.document_category]}
                />
              </div>
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default PatientChartSideBar;

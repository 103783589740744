import AppBar from "@material-ui/core/AppBar";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import {
  getSettingsDcmHeader,
  getSettingsDicomdirView,
  getSettingsFsView,
  getSettingsOverlay,
  setSettingsDcmHeader,
  setSettingsDicomdirView,
  setSettingsFsView,
  setSettingsOverlay,
} from "../functions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formLabel: {
    fontSize: "0.85em",
  },
  radioControl: {
    size: "small",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontSize: "0.95em",
  },
}));

const Settings = ({ onClose }) => {
  const exportAs = getSettingsDcmHeader();
  const overlay = getSettingsOverlay();
  const fsView = getSettingsFsView();
  const dicomdirView = getSettingsDicomdirView();

  const handleChangeExportAs = (event) => {
    setState({ ...state, exportAs: event.target?.value });
    setSettingsDcmHeader(event.target?.value);
  };

  const handleChangeOverlay = (event) => {
    setState({ ...state, overlay: event.target.checked });
    setSettingsOverlay(event.target.checked);
  };

  const handleChangeFsView = (event) => {
    setState({ ...state, fsView: event.target?.value });
    setSettingsFsView(event.target?.value);
  };

  const handleChangeDicomdirView = (event) => {
    setState({ ...state, dicomdirView: event.target?.value });
    setSettingsDicomdirView(event.target?.value);
  };

  const classes = useStyles();

  const [state, setState] = React.useState({
    exportAs: exportAs,
    overlay: overlay,
    fsView: fsView,
    dicomdirView: dicomdirView,
  });

  return (
    <div>
      <Dialog fullScreen open={true} onClose={onClose}>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar variant="dense">
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Settings
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox checked={state.overlay} onChange={handleChangeOverlay} value="overlay" size="small" />}
                label="Show overlay Information"
              />
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" className={classes.formLabel}>
                Open sandboxed file system from:
              </FormLabel>
              <RadioGroup size="small" aria-label="filesystem" name="filesystem" value={state.fsView} onChange={handleChangeFsView}>
                <FormControlLabel value="left" control={<Radio size="small" />} label="left" />
                <FormControlLabel value="right" control={<Radio size="small" />} label="right" />
                <FormControlLabel value="bottom" control={<Radio size="small" />} label="bottom" />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" className={classes.formLabel}>
                Open DICOMDIR panel from:
              </FormLabel>
              <RadioGroup size="small" aria-label="dicomdir" name="dicomdir" value={state.dicomdirView} onChange={handleChangeDicomdirView}>
                <FormControlLabel value="left" control={<Radio size="small" />} label="left" />
                <FormControlLabel value="right" control={<Radio size="small" />} label="right" />
                <FormControlLabel value="bottom" control={<Radio size="small" />} label="bottom" />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend" className={classes.formLabel}>
                Export Dicom header as:
              </FormLabel>
              <RadioGroup aria-label="exportas" name="exportas" value={exportAs} onChange={handleChangeExportAs}>
                <FormControlLabel value="json" control={<Radio size="small" />} label="JSON" />
                <FormControlLabel value="csv" control={<Radio size="small" />} label="CSV" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Settings;

import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "./style.scss";

const ScrollWrapper = ({ children, css, onScrollFrame, type, isEnd, styleProps, disableScroll }) => {
  const [messagesEnd, setMessagesEnd] = useState();
  const handleScrollFrame = (values) => {
    if (onScrollFrame) {
      onScrollFrame(values);
    }
  };

  if (type === "history" && !!messagesEnd) {
    if (isEnd) {
      messagesEnd.scrollToBottom({ behavor: "smooth" });
    }
  }

  if (disableScroll) {
    return <>{children}</>;
  }

  return (
    <Scrollbars
      className={`perfect-scrollbar ${css}`}
      style={styleProps}
      autoHide={true}
      autoHideTimeout={500}
      autoHideDuration={100}
      thumbMinSize={30}
      onScrollFrame={handleScrollFrame}
      ref={(c) => setMessagesEnd(c)}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollWrapper;

import { Icon, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import DayCalendarCheck from "./DayCalendarCheck";
import PusherCheck from "../../../common/components/PusherCheck";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import { getLocal, setLocal } from "../../../helpers/Local";
import {
  chooseSelectedPatientResource,
  getPatientSchedules,
  getProviders,
  getReferralConditions,
  getVhcLocations,
  get_patient_notes,
  patient_insurances_id_insurances,
  setActionActive,
  setDataPatient,
} from "../../../store/actions";

const resources = [
  {
    id: "angio",
    title: "OBL Procedure",
  },
];

function CheckIn(props) {
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.common.vhcLocations);
  const providers = useSelector((state) => state.common.providers);
  const schedules = useSelector((state) => state.patientSchedule.schedules);
  const referralConditions = useSelector((state) => state.common.referralConditions);
  const loadingSchedules = useSelector((state) => state.patientSchedule.loadingSchedules);
  const loadingResource = useSelector((state) => state.patientSchedule.loadingResource);
  const selectedPatientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const resourceCheck = useSelector((state) => state.patientSnapshot.resource);
  const actionActive = useSelector((state) => state.common.actionActive);

  const [isSidebar, setIsSidebar] = useState(false);
  const [pusherCheckBuild, setPusherCheckBuild] = useState([]);
  const [pusherCheckUnfinalized, setPusherCheckUnfinalized] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [schedulesState, setSchedulesState] = useState([]);
  const [isSelectedPatient, setIsSelectedPatient] = useState(false);
  const [filters, setFilters] = useState({
    date: moment(new Date()).format("YYYY-MM-DD"),
    location: "",
    provider_id: "",
  });

  useEffect(() => {
    const { date } = filters;
    const localCheck = JSON.parse(getLocal("oblScheduleParams"));
    const scheduleFilters = {
      date: localCheck && localCheck?.date ? localCheck?.date || date : date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    dispatch(getVhcLocations());
    dispatch(getProviders());
    dispatch(getReferralConditions());
    loadInitialPaymentJs();
    handleEvents(scheduleFilters);
  }, []);

  const loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleEvents = ({ date, location, provider_id }) => {
    setFilters({
      ...filters,
      date,
      location,
      provider_id,
    });
    dispatch(
      getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 1,
        only_us: 0,
        only_angio: 1,
      })
    );
  };

  const onSearch = (searchValue) => {
    setSearchValue(searchValue);
    if (searchValue.length > 0) {
      const searchPatientName = (schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      setSchedulesState(searchPatientName);
    } else {
      setSchedulesState(schedules);
    }
  };

  const updateStatusPusher = (value) => {
    if (value && value?.appointment_id && schedulesState) {
      const indexCheck = (schedulesState || []).findIndex((r) => r?.id === value?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...schedulesState];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: value?.status,
        });
        setSchedulesState([...dataFix]);
      }
    }
  };

  const onSelectResource = async (patient_id, appointment_id) => {
    setLocal("current_patient_id", patient_id);
    setLocal("current_appointment_id", appointment_id);
    dispatch(get_patient_notes(patient_id));
    const result = await dispatch(chooseSelectedPatientResource(patient_id));
    if (result) {
      dispatch(setDataPatient(result));
    }
    dispatch(patient_insurances_id_insurances(patient_id));
    handleSidebar();
  };

  const refreshCalendar = () => {
    const { date, location, provider_id } = filters;
    dispatch(
      getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 1,
        only_us: 0,
        only_angio: 1,
      })
    );
  };

  const getItemBuild = (value) => {
    if (value !== null) {
      if (pusherCheckBuild.length === 0 || !pusherCheckBuild.some((r) => r === value)) {
        setPusherCheckBuild([...pusherCheckBuild, value]);
      }
    }
  };

  const dispatchAppointmentUnfinalized = (value) => {
    if (pusherCheckUnfinalized.length === 0 || !pusherCheckUnfinalized.some((r) => r === value)) {
      setPusherCheckUnfinalized([...pusherCheckUnfinalized, value]);
    }
  };

  const renderSideComponent = () => {
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const { deceased, cancelled_reason } = resourceCheck;
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  const selectAppointment = (id) => {
    setLocal("current_appointment_id", id);
  };

  useEffect(() => {
    if (schedules) {
      setSchedulesState(schedules);
    }
    const isSelectedPatientProps = selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length > 0) {
      setIsSelectedPatient(true);
    } else {
      setIsSelectedPatient(false);
    }
  }, [schedules, selectedPatientResource]);

  useEffect(() => {
    if (actionActive?.status) {
      setIsSidebar(true);
      dispatch(
        setActionActive({
          ...actionActive,
          status: false,
        })
      );
    }
  }, [actionActive]);

  const { first_name, last_name, deceased, cancelled_reason } = selectedPatientResource;

  return (
    <div className={`clinicWorkflow__main-page`}>
      <TopBar title="Check-In Module" isBarcode={isSelectedPatient} sideComponent={renderSideComponent()} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="calendarContainer">
              <DayCalendarCheck
                locations={locations || []}
                providers={providers || []}
                events={schedulesState || []}
                slotProviders={[]}
                resources={resources}
                loading={loadingSchedules || loadingResource}
                pusherCheckBuild={pusherCheckBuild}
                pusherCheckUnfinalized={pusherCheckUnfinalized}
                onSelectAppointment={selectAppointment}
                fetchEvents={handleEvents}
                onSearch={onSearch}
                valueSearch={searchValue}
                onSelectResource={onSelectResource}
                calendarType="check-in"
                isOblModule={true}
              />
            </div>
          </div>
          <ClinicWorkflowSidebar
            ref={sidebarRef}
            sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038" />}
            canCheckIn={true}
            resource={selectedPatientResource}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
            paymentForm={window.SqPaymentForm}
            refreshCalendar={refreshCalendar}
            referralConditions={referralConditions || []}
            isNormal={true}
            sidebarTitle={`PATIENT DASHBOARD - ${first_name} ${last_name} ${
              getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""
            }`}
            className={deceased == "Yes" || (cancelled_reason != "" && cancelled_reason) ? "red" : ""}
          />
          <PusherCheck
            getItemBuild={getItemBuild}
            updateStatus={updateStatusPusher}
            dispatchAppointmentUnfinalized={dispatchAppointmentUnfinalized}
          ></PusherCheck>
        </div>
      </div>
    </div>
  );
}

export default CheckIn;

import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { useWindowSize } from "../../../../common/utils";
import { getInventoryCostHistory, setGlobalLoading, setInventoryData } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import PurchaseListFilter from "./PurchaseListFilter";
import PurchaseListSidebar from "./PurchaseListSidebar";

const filterDataByOption = (data, option) => {
  const { search, unit, purchase_number, user, requestedDate } = option;
  let filteredData = data;
  if (!search && !unit && !purchase_number && !user && (!requestedDate || !moment(requestedDate).isValid())) {
    return filteredData;
  }
  if (search) {
    filteredData = filteredData?.filter((d) => d?.name && d?.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
  }
  if (unit) {
    filteredData = filteredData?.filter((d) => d.unit && d.unit.toLowerCase() === unit.toLowerCase());
  }
  if (user) {
    filteredData = filteredData?.filter((d) => d.added_user && d.added_user === user);
  }
  if (purchase_number) {
    filteredData = filteredData?.filter((d) => d.purchase_number && d.purchase_number >= purchase_number);
  }
  if (requestedDate && moment(requestedDate).isValid()) {
    filteredData = filteredData?.filter((d) => d.requested_date && moment(d.requested_date).diff(moment(requestedDate)) >= 0);
  }
  return filteredData;
};

const PurchaseList = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const purchases = useSelector((state) => state.inventory.purchaseList);
  const purchase = useSelector((state) => state.inventory.purchase);
  const users = useSelector((state) => state.inventory.users);
  const filterOption = useSelector((state) => state.inventory.orderFilter);
  const loading = useSelector((state) => state.inventory.loadingAPI);

  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();

  const tableRows = useMemo(() => {
    if (purchases && purchases.length) {
      return filterDataByOption(purchases, filterOption);
    }
    return [];
  }, [purchases, filterOption]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("purchase", rowData[index]));
    dispatch(getInventoryCostHistory(rowData[index].product_id, "other"));
    handleSidebar();
  };

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const getUserName = (userId) => {
    const findUser = users.find((user) => user?.id === userId);
    if (findUser) {
      return findUser?.name;
    }
    return "";
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <PurchaseListFilter />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="purchase_list"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={["Name", "Purchase Number", "Unit", "Requested Date", "Added User"]}
                widths={["44.3%", "130px", "130px", "calc(35.2% - 260px)", "calc(35.2% - 260px)"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "purchase_number" },
                  { sortable: false, key: "unit" },
                  { sortable: false, key: "requested_date" },
                  { sortable: false, key: "added_user" },
                ]}
                rowData={rowData?.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {row?.type === "narcotic" ? (
                          <Icon type="experiment" theme="twoTone" twoToneColor="#0043CE" />
                        ) : row?.type === "angiographic" ? (
                          <Icon type="tag" theme="twoTone" twoToneColor="#6929C4" />
                        ) : (
                          <Icon type="shop" theme="twoTone" twoToneColor="#9F1853" />
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>{row?.name || ""}</p>
                      </div>
                    ),
                    purchase_number: row?.purchase_number,
                    unit: row?.unit,
                    requested_date: row?.requested_date && moment(row?.requested_date).format("MM/DD/YYYY"),
                    added_user: getUserName(row?.added_user),
                  };
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {purchase && activeIndex >= 0 && (
        <PurchaseListSidebar ref={sidebarRef} resource={StaticResource} isShow={isSidebar} handleCloseSidebar={handleCloseSidebar} />
      )}
    </div>
  );
};

export default PurchaseList;

import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const getScanTokenInsuranceCard = createActionThunk(
    AT.GET_SCAN_TOKEN_INSURANCE_CARD,
    (patient_id) => API.getScanTokenInsuranceCard(patient_id),
);

export const checkScanInsuranceCardResult = createActionThunk(
    AT.CHECK_SCAN_INSURANCE_CARD_RESULT,
    (scanToken) => API.checkScanInsuranceCardResult(scanToken),
);

export const cancelScanInsuranceCard = () => (dispatch) =>
  dispatch({type: AT.CANCEL_SCAN_INSURANCE_CARD});

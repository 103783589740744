import { Drawer, Spin } from "antd";
import { convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import CardWhiteBlock from "../../../../common/components/CardWhite";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { ImageFile, VideoFile } from "../../../../common/components/MediaFiles";
import PusherConnector from "../../../../common/components/PusherScan/pusherConnector";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SlackEditor from "../../../../common/components/SlackEditor";
import { convertToLongNumber } from "../../../../common/utils";
import {
  closeSupportModule,
  createSupportModule,
  deleteSupportModule,
  getAllUserMedia,
  removeUserMedia,
  setActiveSupportTicket,
  updateSupportModule,
  updateSupportModuleHistory,
} from "../../../../store/actions";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "lightgreen" : "white",
  display: "block",
  float: "left",
  cursor: "pointer",
  position: "relative",
  margin: ".25rem",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  border: isDraggingOver ? "2px dotted #0f62fe" : "",
  width: "100%",
  filter: isDraggingOver ? "blur(10px)" : "",
});

const DetailSidebarComponent = React.forwardRef(
  ({ resource, isShow, handleCloseSidebar, currentUserInfo, mediaFileDoubleClicked, onCreatedModule }, ref) => {
    const dispatch = new useDispatch();
    const userMedia = useSelector((state) => state.userMedia);
    const [isNew, setIsNew] = useState(true);
    const [subject, setSubject] = useState("");
    const [comment, setComment] = useState();
    const [history, setHistory] = useState(null);
    const [selectedHistory, setSelectedHistory] = useState(null);
    const [status, setStatus] = useState(0);
    const [isDev, setIsDev] = useState(0);
    const [user, setUser] = useState(null);
    const [resetEditor, setResetEditor] = useState(false);
    const [mediaFiles, setMediaFiles] = useState([]);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const [loadingMediaFiles, setLoadingMediaFiles] = useState(true);
    const [pusherChannel, setPusherChannel] = useState(false);
    const [pusherConnector, setPusherConnnector] = useState();

    const newMessage = async (callback) => {
      const data = await callback;
      if (data?.is_dev == 1 && data?.support_id == resource?.id) {
        setHistory([...history, data]);
      }
    };

    useEffect(() => {
      const pusher = new PusherConnector();
      const channelName = "support-messages";
      const channel = pusher.createChannel(channelName);
      setPusherConnnector(pusher);
      setPusherChannel(channel);
    }, []);

    useEffect(() => {
      if (pusherChannel && pusherChannel.bind && !!resource && pusherConnector) {
        const eventName = "new-message";
        pusherConnector.unbindEvent(pusherChannel, eventName);
        pusherConnector.bindEvent(pusherChannel, eventName, newMessage);
      }
    }, [resource, history, pusherChannel, pusherConnector]);

    useEffect(() => {
      if (!!currentUserInfo?.id) {
        const { role_id } = currentUserInfo;
        setIsDev(role_id === 3 ? 1 : 0);
        dispatch(getAllUserMedia(currentUserInfo?.id));
      }
    }, [currentUserInfo?.id]);

    useEffect(() => {
      if (!!resource) {
        setSubject(resource.subject);
        setComment();
        dispatch(setActiveSupportTicket(resource?.id));
        setHistory(resource.history);
        setStatus(resource.status);
        setIsNew(false);
        setResetEditor(true);
        setUser(resource.user);
      }

      return () => {
        dispatch(setActiveSupportTicket(null));
      };
    }, [resource]);

    useEffect(() => {
      setLoadingMediaFiles(userMedia.loading);
      if (!userMedia.loading) {
        setMediaFiles(userMedia.media);
      }
    }, [userMedia]);

    const handleSubmit = async () => {
      if (currentUserInfo?.id && subject && (comment || attachedFiles.length > 0)) {
        const payload = {
          user_id: currentUserInfo?.id,
          status: 0, // 0 => in progress
          subject: subject,
          is_dev: isDev,
          comment: comment,
          attached_files: JSON.stringify(attachedFiles),
        };
        if (!!selectedHistory) {
          await dispatch(updateSupportModuleHistory(selectedHistory, { comment }));
        } else {
          if (isNew) {
            if (!comment) {
              payload.comment = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
            }
            onCreatedModule();
            await dispatch(createSupportModule(payload));
          } else {
            payload.type = "update";
            if (!comment) {
              payload.comment = JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()));
            }
            await dispatch(updateSupportModule(resource?.id, payload));
          }
        }
        setSelectedHistory(null);
        setAttachedFiles([]);
      } else {
      }
    };

    const handleCreateNewSupportModule = () => {
      setSubject("");
      setComment("");
      setHistory(null);
      setIsNew(true);
      setResetEditor(true);
      setUser(null);
    };

    const handleEditorChange = (editorState) => {
      const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      setComment(data);
      setResetEditor(false);
      setUser(currentUserInfo);
    };
    const handleDragEnd = (result) => {
      const { destination } = result;
      if (!destination) return;
      if (result.destination.droppableId === "droppable_input") {
        const item = JSON.parse(result.draggableId);
        const tempFiles = attachedFiles.filter((e) => e?.id !== item?.id);
        setAttachedFiles([...tempFiles, item]);
      }
    };

    const handleMediaRemoveBtnClicked = (value) => {
      dispatch(removeUserMedia(value));
    };

    const handleRemoveAttachedFile = (value) => {
      const temp = attachedFiles.filter((e) => e?.id !== value);
      setAttachedFiles(temp);
    };

    const handleSelecedHistory = (id) => setSelectedHistory(id);

    const handleRemoveSupportModule = () => {
      dispatch(deleteSupportModule(resource?.id));
      setSubject("");
      setComment("");
      setHistory(null);
      setIsNew(true);
      setResetEditor(true);
      setUser(null);
    };

    const handleCloseSupportModule = () => {
      const payload = {
        status: 1,
        type: "close",
      };

      dispatch(closeSupportModule(resource?.id, payload));
    };

    return (
      <Drawer
        title={!isNew ? "Ticket ID: " + convertToLongNumber(resource?.id) : "New Ticket"}
        placement="right"
        closable
        mask={false}
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          <DragDropContext onDragEnd={handleDragEnd}>
            <ScrollWrapper>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  marginTop: "1.5rem",
                }}
              >
                <div className="resourceInfo d-flex">
                  <EditableFormInput label="Subject" value={subject} handleChange={setSubject} fullWidth />
                </div>
                <div className="resourceInfo d-flex" style={{ marginTop: 0, marginBottom: "0.5rem" }}>
                  <Droppable droppableId="droppable_input">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {snapshot.isDraggingOver && <h1>Test</h1>}

                        <SlackEditor
                          inputBoxTitle="Issue Description"
                          onEditorChange={handleEditorChange}
                          historyData={history}
                          userInfo={user}
                          onSelectedHistory={handleSelecedHistory}
                          onSubmit={handleSubmit}
                          isReset={resetEditor}
                          mediaFiles={attachedFiles}
                          onRemoveBtnClicked={handleRemoveAttachedFile}
                          fileDoubleClicked={mediaFileDoubleClicked}
                        />
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className="resourceInfo d-flex">
                <CardWhiteBlock title={"Media Files"} style={{ marginBottom: 0 }}>
                  <div
                    className="media-files-wrapper"
                    style={{
                      width: "100%",
                      border: "1px solid #d6d6d6",
                      background: "#f4f7fb",
                      padding: ".5rem",
                      position: "relative",
                      display: "inline-block",
                      maxHeight: "400px",
                      minHeight: "200px",
                    }}
                  >
                    {loadingMediaFiles ? (
                      <Spin
                        style={{
                          position: "absolute",
                          left: "48%",
                          top: "47%",
                        }}
                      />
                    ) : (
                      mediaFiles.length > 0 && (
                        <Droppable droppableId="droppable_media">
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                              {mediaFiles.map((element, index) => (
                                <Draggable draggableId={JSON.stringify(element)} index={index} key={element?.id}>
                                  {(provided, snapshot) => {
                                    const fileName = element.url.replace("https://cvaipatientfiles.s3.amazonaws.com/supports/", "");
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        {element.type === "video" ? (
                                          <VideoFile
                                            resource={element}
                                            fileName={fileName}
                                            fileDoubleClicked={mediaFileDoubleClicked}
                                            customStyle="item-video-file"
                                            onRemoveBtnClicked={handleMediaRemoveBtnClicked}
                                          />
                                        ) : (
                                          <ImageFile
                                            resource={element}
                                            fileName={fileName}
                                            fileDoubleClicked={mediaFileDoubleClicked}
                                            customStyle="item-image-file"
                                            onRemoveBtnClicked={handleMediaRemoveBtnClicked}
                                          />
                                        )}
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )
                    )}
                  </div>
                </CardWhiteBlock>
              </div>
              <div
                className="actionContent"
                style={{
                  margin: 0,
                  display: "flex",
                }}
              >
                {currentUserInfo.role_id === 3 && (
                  <button className="common-btn delete-btn" onClick={handleCloseSupportModule} disabled={isNew}>
                    Close Support Case
                  </button>
                )}
                {!isNew && (
                  <>
                    {status == 0 && (
                      <button className="common-btn delete-btn" onClick={handleRemoveSupportModule} disabled={isNew}>
                        Delete Support Case
                      </button>
                    )}
                    <button className="common-btn outlined-btn" onClick={handleCreateNewSupportModule}>
                      New Support Case
                    </button>
                  </>
                )}
              </div>
            </ScrollWrapper>
          </DragDropContext>
        </div>
      </Drawer>
    );
  }
);

export default DetailSidebarComponent;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import { getCommunicationRecords, setPatientRecordData, getInitialPatientData } from "../../store/actions";
import MainContainer from "./PatientRecords";
import "./style.scss";

class PatientRecordsPage extends Component {
  sideNavLinks = [
    {
      link: "/",
      label: "Dashboard",
    },
    {
      link: "/patient-records/clinical",
      label: "Clinical Module",
    },
  ];

  state = {
    isSideNav: true,
  };

  componentDidMount() {
    const { selected, resource, loadingPatient, setPatientRecordData } = this.props;
    if (!loadingPatient && !resource && !selected) {
    }
    if (resource) {
      setPatientRecordData("resource", resource);
    }
    if (selected) {
      setPatientRecordData("loadingCommunications", true);
    }
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Patient Records</title>
        <meta name="title" content="FAACT Vascular | Patient Records" />
      </Helmet>
    );
  }

  openSideNav = () => {
    this.setState({ isSideNav: true });
  };

  closeSideNav = () => {
    this.setState({ isSideNav: false });
  };

  getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Patient Records
    </div>
  );
  onLinkClick = (link) => {
    if (this.sideNavLinks.find((v) => v.link === link)) {
      this.closeSideNav();
    }
  };

  render() {
    const { location } = this.props;
    const { isSideNav } = this.state;
    return (
      <div className="clinicWorkflow patient-records">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
          onLinkClick={this.onLinkClick}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingPatient: state.patientRecords.loadingPatient,
  selected: state.patientRecords.resource,
  resource: state.newPatient.resource,
});

const mapDispatchToProps = {
  getCommunicationRecords,
  setPatientRecordData,
  getInitialPatientData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientRecordsPage);

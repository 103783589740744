import { Drawer } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { getUsers, saveEmployeeInfo } from "../../../../store/actions";

const HoursSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, usersDetail, payPeriodId }, ref) => {
  const [state, setState] = useState({});

  const [isToastr, setIsToastr] = useState(false);
  const [reset, setReset] = useState(true);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [isReset, setIsReset] = useState(false);

  const dispatch = useDispatch();
  const fetchState = () => {
    setState({
      id: usersDetail?.id,
      position: usersDetail.position,
      hours: usersDetail.meta && usersDetail.meta.hours,
      overtime: usersDetail.meta && usersDetail.meta.overtime,
      witholdings: usersDetail.meta && usersDetail.meta.witholdings,
      bonus: usersDetail.meta && usersDetail.meta.bonus,
      exemptions: usersDetail.meta && usersDetail.meta.exemption,
      ovhendo: usersDetail.meta && usersDetail.meta.ovh_endo,
      ovhnonendo: usersDetail.meta && usersDetail.meta.obh_nonendo,
      salary: usersDetail.meta && usersDetail.meta.salary,
      payforpay: usersDetail.meta && usersDetail.meta.pay_for_pay_period,
      ops_provider_id: usersDetail.ops_provider_id,
      shiftstart: usersDetail.meta && moment(usersDetail.meta.shift_start_time).format("kk:mm"),
      shiftend: usersDetail.meta && moment(usersDetail.meta.shift_end_time).format("kk:mm"),
      start: usersDetail.meta && moment(usersDetail.meta.hire_date).format("MM/DD/YYYY"),
      pto: usersDetail.vacation_hours_per_year,
      nonendo_cm: usersDetail.nonendo_cm,
    });
  };

  const saveAll = async (value) => {
    if (Object.keys(usersDetail).length > 0) {
      const saveSuccess = await dispatch(
        saveEmployeeInfo(usersDetail.user_id, {
          position: value.position,
          hours: value.hours,
          overtime: value.overtime,
          witholdings: value.witholdings,
          bonus: value.bonus,
          exemption: value.exemptions,
          ovh_endo: value.ovhendo,
          obh_nonendo: value.ovhnonendo,
          salary: value.salary,
          pay_for_pay_period: value.payforpay,
          vacation_hours: value.pto,
          shift_start_time: value.shiftstart,
          shift_end_time: value.shiftend,
          start_date: value.start,
          ops_provider_id: value.ops_provider_id,
          pay_period_id: payPeriodId,
          nonendo_cm: value.nonendo_cm,
        })
      );
      const getList = await dispatch(getUsers(payPeriodId));
      if (saveSuccess && getList) {
        setIsToastr(true);
        setIsReset(false);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    }
  };

  const handleChange = (type, value) => {
    const newState = {
      ...state,
      [type]: value,
    };
    setState(newState);
    isReset && changeHandler(newState);
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 400),
    []
  );

  useEffect(() => {
    if (usersDetail && usersDetail?.id !== state?.id) {
      fetchState();
      setReset(true);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [usersDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  return (
    <Drawer
      title={usersDetail?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Hours" status="success" msg="Saved Successfully!" />}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                reset={reset}
                label={"Position: Regular"}
                value={state.position}
                handleChange={(value) => {
                  handleChange("position", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Hours"}
                value={state.hours}
                handleChange={(value) => {
                  handleChange("hours", value);
                }}
                unit={"hrs"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Overtime"}
                value={state.overtime}
                handleChange={(value) => {
                  handleChange("overtime", value);
                }}
                unit={"hrs"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Witholdings"}
                prefix={"$"}
                value={state.witholdings}
                handleChange={(value) => {
                  handleChange("witholdings", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Bonus"}
                prefix={"$"}
                value={state.bonus}
                handleChange={(value) => {
                  handleChange("bonus", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Exemptions"}
                value={state.exemptions}
                handleChange={(value) => {
                  handleChange("exemptions", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"%OvH Endo"}
                value={state.ovhendo}
                handleChange={(value) => {
                  handleChange("ovhendo", value);
                }}
                unit={"%"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"%OvH Non-Endo"}
                value={state.ovhnonendo}
                handleChange={(value) => {
                  handleChange("ovhnonendo", value);
                }}
                unit={"%"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Non-Endo CM"}
                value={state.nonendo_cm}
                handleChange={(value) => {
                  handleChange("nonendo_cm", value);
                }}
                prefix={"$"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Salary/Wage"}
                prefix={"$"}
                value={state.salary}
                handleChange={(value) => {
                  handleChange("salary", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"Pay for Pay Period"}
                prefix={"$"}
                value={state.payforpay}
                handleChange={(value) => {
                  handleChange("payforpay", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableMaterialText
                label={"Start Date"}
                value={state.start}
                type={"date"}
                reset={reset}
                handleChange={(value) => {
                  handleChange("start", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                reset={reset}
                label={"PTO Hours"}
                value={state.pto}
                handleChange={(value) => {
                  handleChange("pto", value);
                }}
                unit={"hrs"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Shift Start Time"}
                value={state.shiftstart}
                type={"time"}
                reset={reset}
                handleChange={(value) => {
                  handleChange("shiftstart", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"Shift End Time"}
                reset={reset}
                value={state.shiftend}
                type={"time"}
                handleChange={(value) => {
                  handleChange("shiftend", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                label={"OPS Provider ID"}
                value={state.ops_provider_id}
                reset={reset}
                handleChange={(value) => {
                  handleChange("ops_provider_id", value);
                }}
              />
            </div>
            <hr />
          </div>
          <div className="actionContent" style={{ paddingBottom: "2rem" }} />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default HoursSidebar;

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse } from "antd";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { ArrowDownSVG } from "../../../../common/components/icons/SVGIcon";
import { procedureConsentSendFax, procedureConsentSendEmail } from "../../../../store/actions";

const ConsentFaxingModule = ({ existingPCP, existingProvider }) => {
  const dispatch = useDispatch();
  const selectedDocumentData = useSelector((state) => state.procedureConsent.selectedDocumentData);

  const [faxTo, setFaxTo] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [pcp, setPCP] = useState(undefined);
  const [isOpenFax, setOpenFax] = useState(false);
  const [isOpenEmail, setOpenEmail] = useState(false);

  const toggleOpenFax = (e) => {
    e.stopPropagation();
    setOpenFax(!isOpenFax);
  };
  const toggleOpenEmail = (e) => {
    e.stopPropagation();
    setOpenEmail(!isOpenEmail);
  };

  const sendFax = async () => {
    if (selectedDocumentData) {
      const formData = new FormData();
      formData.append("fax_to", faxTo);
      const consentId = selectedDocumentData?.id;
      await dispatch(procedureConsentSendFax(consentId, formData));
      setFaxTo("");
    }
  };

  const sendEmail = async () => {
    if (selectedDocumentData) {
      const formData = new FormData();
      formData.append("email_to", emailTo);
      const consentId = selectedDocumentData?.id;
      await dispatch(procedureConsentSendEmail(consentId, formData));
      setEmailTo("");
    }
  };

  const openPrint = async () => {
    if (selectedDocumentData) {
      window.open(selectedDocumentData?.fileurl, "_blank");
    }
  };

  return (
    <>
      <Collapse accordion style={{ marginTop: "2.5rem" }}>
        <Collapse.Panel header="Fax" key="fax">
          <div className="resourceInfo d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                position: "relative",
                maxWidth: "400px",
              }}
              onClick={() => setOpenFax(false)}
            >
              <EditableFormInput label={"Fax No"} reset={true} value={faxTo} mask={"phone"} handleChange={setFaxTo} />
              {((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                <div
                  style={{
                    position: "absolute",
                    display: "inline-flex",
                    width: "40px",
                    height: "40px",
                    zIndex: 20,
                    top: "2px",
                    right: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    transform: isOpenFax ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                  className="dropdown-button"
                  onClick={toggleOpenFax}
                >
                  <ArrowDownSVG />
                </div>
              )}
              {isOpenFax && ((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 20,
                    left: 0,
                    right: 0,
                    top: "42px",
                    background: "#fff",
                    border: "1px solid #ddd",
                  }}
                >
                  {existingPCP && existingPCP.fax_number && (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        borderBottom: "1px solid #ddd",
                        padding: "0 14px",
                        fontSize: "14px",
                        color: "#000",
                        lineHeight: "40px",
                        cursor: "pointer",
                        background: pcp === "pcp" ? "#dbe3ee" : "#fff",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setFaxTo(existingPCP.fax_number);
                        if (existingPCP.email) {
                          setEmailTo(existingPCP.email);
                        }
                        setPCP("pcp");
                        setOpenFax(false);
                      }}
                    >
                      PCP
                    </div>
                  )}
                  {existingProvider && existingProvider.fax_number && (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0 14px",
                        fontSize: "14px",
                        color: "#000",
                        lineHeight: "40px",
                        cursor: "pointer",
                        background: pcp === "referring_provider" ? "#dbe3ee" : "#fff",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setFaxTo(existingProvider.fax_number);
                        if (existingProvider.email) {
                          setEmailTo(existingProvider.email);
                        }
                        setPCP("referring_provider");
                        setOpenFax(false);
                      }}
                    >
                      Referring Provider
                    </div>
                  )}
                </div>
              )}
            </div>
            <button onClick={sendFax} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
              Send
            </button>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="E-mail" key="email">
          <div className="resourceInfo d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                position: "relative",
                maxWidth: "400px",
              }}
              onClick={() => setOpenEmail(false)}
            >
              <EditableFormInput reset={true} handleChange={setEmailTo} value={emailTo} label={"E-mail"} />
              {((existingPCP && existingPCP.email) || (existingProvider && existingProvider.email)) && (
                <div
                  style={{
                    position: "absolute",
                    display: "inline-flex",
                    width: "40px",
                    height: "40px",
                    zIndex: 20,
                    top: "2px",
                    right: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    transform: isOpenEmail ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                  className="dropdown-button"
                  onClick={toggleOpenEmail}
                >
                  <ArrowDownSVG />
                </div>
              )}

              {isOpenEmail && ((existingPCP && existingPCP.email) || (existingProvider && existingProvider.email)) && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 20,
                    left: 0,
                    right: 0,
                    top: "42px",
                    background: "#fff",
                    border: "1px solid #ddd",
                  }}
                >
                  {existingPCP && existingPCP.email && (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        borderBottom: "1px solid #ddd",
                        padding: "0 14px",
                        fontSize: "14px",
                        color: "#000",
                        lineHeight: "40px",
                        cursor: "pointer",
                        background: pcp === "pcp" ? "#dbe3ee" : "#fff",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setEmailTo(existingPCP.email);
                        if (existingPCP.fax_number) {
                          setFaxTo(existingPCP.fax_number);
                        }
                        setPCP("pcp");
                        setOpenEmail(false);
                      }}
                    >
                      PCP
                    </div>
                  )}
                  {existingProvider && existingProvider.email && (
                    <div
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "0 14px",
                        fontSize: "14px",
                        color: "#000",
                        lineHeight: "40px",
                        cursor: "pointer",
                        background: pcp === "referring_provider" ? "#dbe3ee" : "#fff",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setEmailTo(existingProvider.email);
                        if (existingProvider.fax_number) {
                          setFaxTo(existingProvider.fax_number);
                        }
                        setPCP("referring_provider");
                        setOpenEmail(false);
                      }}
                    >
                      Referring Provider
                    </div>
                  )}
                </div>
              )}
            </div>
            <button onClick={sendEmail} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
              Send
            </button>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header="Print" key="print">
          <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
            <button className="common-btn" style={{ border: 0 }} onClick={openPrint}>
              Print
            </button>
          </div>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};
export default ConsentFaxingModule;

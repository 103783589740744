import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/DataTable";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { setLocal } from "../../../helpers/Local";
import {
  checkApointment,
  getProcedureCallAttempt,
  getSingleProcedure,
  getUltrasoundPending,
  selectPatient,
  selectProcedure,
  setGlobalLoading,
  setProcedureData,
} from "../../../store/actions";

const filterSuggestion = (suggestions, userInput) => {
  if (!userInput) {
    return suggestions;
  }
  let filteredSuggestions = [];

  filteredSuggestions = suggestions.filter(
    (suggestion) =>
      suggestion?.patient && suggestion?.patient?.name.split(" ").join("").toLowerCase().indexOf(userInput.split(" ").join("").toLowerCase()) > -1
  );

  return filteredSuggestions;
};

const PendingTable = ({ title, search, selectedType, handleSidebar, handleSelect }) => {
  const ref = useRef(null);
  const data = useSelector((state) => state.procedure.pending_ultrasounds);
  const isConfirmed = useSelector((state) => state.procedure.isConfirmed);
  const dispatch = useDispatch();
  const [dataRow, setDataRow] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [sortOption, setSortOption] = useState({
    name: "",
    referral_condition: "",
    referral_date: "",
  });
  const [offLoad, setOffLoad] = useState(true);

  useEffect(() => {
    if (isConfirmed) {
      dispatch(setProcedureData("resource", null));
    }
  }, [isConfirmed]);

  useEffect(() => {
    dispatch(setProcedureData("loadingResource", true));
    dispatch(setProcedureData("loadingPending", true));
    dispatch(
      getUltrasoundPending({
        page: 1,
        per_page: 30,
      })
    );
  }, []);

  useEffect(() => {
    if (data) {
      setDataRow(data);
      if (dataSearch.length == 0) {
        setDataSearch(data);
      }
    } else {
      setDataRow([]);
    }
  }, [data]);

  useEffect(() => {
    if (dataSearch) {
      const fillSearch = filterSuggestion(dataSearch, search);
      setDataRow([...fillSearch]);
    }
  }, [search]);

  const handleRow = async (index, id) => {
    dispatch(setGlobalLoading(true));
    dispatch(setProcedureData("loadingResource", true));
    dispatch(selectPatient(dataRow[index]["patient_id"]));
    dispatch(selectProcedure(dataRow[index]));
    dispatch(getSingleProcedure(dataRow[index].id));
    setLocal("procedure_id", dataRow[index].id);
    dispatch(getProcedureCallAttempt(dataRow[index].id));
    dispatch(checkApointment(false));
    handleSelect("pending");
    dispatch(setGlobalLoading(false));
    handleSidebar();
  };

  const getProcedureName = (row) => {
    if (row?.side) return row?.side + " " + row?.procedure_name;
    else return row?.procedure_name;
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loadingCheck) {
      if (offLoad) {
        await dispatch(setGlobalLoading(true));
        setLoadingCheck(true);
        const listUpdate = await dispatch(
          getUltrasoundPending({
            page: page + 1,
            per_page: 30,
          })
        );
        if (listUpdate && listUpdate.data?.length > 0) {
          setPage(page + 1);
          setLoadingCheck(false);
          setDataRow([...dataRow, ...listUpdate.data]);
          setDataSearch([...dataRow, ...listUpdate.data]);
          await dispatch(setGlobalLoading(false));
        } else {
          setLoadingCheck(false);
          await dispatch(setGlobalLoading(false));
          setOffLoad(false);
        }
      }
    }
  };

  const handleSort = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
    const data = [...dataRow];
    if (name === "referral_date") {
      (data || []).sort((a, b) => {
        if (direction === "asc") {
          return new Date(b?.appointment_date) - new Date(a?.appointment_date);
        } else {
          return new Date(a?.appointment_date) - new Date(b?.appointment_date);
        }
      });
      setDataRow(data || []);
    }
  };

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper css="no-padding no-b-padding x-hidden">
        <div className="tableList">
          <DataTable
            loadMore={loadMore}
            isXHidden={true}
            tableWidth={"100%"}
            title="new_patient_pending"
            isNormal={true}
            handleSort={handleSort}
            sorts={[sortOption?.name, sortOption.referral_condition, sortOption.referral_date]}
            labels={["Patient Name", "Ultrasound", "Appointment Date"]}
            widths={["30%", "40%", "30%"]}
            defaultActive={-1}
            disableDelete={true}
            columns={[
              { sortable: false, key: "name" },
              { sortable: false, key: "referral_condition" },
              { sortable: true, key: "referral_date" },
            ]}
            handleClickRow={(row, index) => {
              handleRow(index);
            }}
            rowData={(dataRow || []).map((row, index) => {
              return {
                name: (
                  <div
                    className="iconField td with-icon"
                    style={{
                      display: "flex",
                      paddingLeft: "0.25rem",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Icon type="notification" theme="twoTone" twoToneColor="#0f62fe" />
                    <p
                      style={{
                        paddingLeft: "0.75rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.patient && row?.patient?.name.toLowerCase()}
                    </p>
                  </div>
                ),
                referral_condition: getProcedureName(row),
                referral_date: row?.appointment_date && moment(row?.appointment_date).format("MM/DD/YYYY hh:mm A"),
              };
            })}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default PendingTable;

import { Icon } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { remove_patient_on_schedules, remove_selected_patient_resource, resetStateResource } from "src/store/actions";
import SubSideNav from "../../common/components/SubSideNav";
import { collapseSideNav } from "../../store/actions";
import MainWorkflow from "./ClinicWorkflow";
import "./style.scss";

function ClinicWorkFlow({ location }) {
  const sideNavLinks = [
    {
      link: "/clinic-workflow/check-in",
      label: "Check-In",
    },
    {
      link: "/clinic-workflow/ultrasound",
      label: "Ultrasound Module",
    },
    {
      link: "/clinic-workflow/provider",
      label: "Provider Module",
    },
  ];

  const [isSideNav, setIsSideNav] = useState(true);
  const dispatch = useDispatch();

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Clinic Workflow</title>
        <meta name="title" content="FAACT Vascular | Clinic Workflow" />
      </Helmet>
    );
  };

  const openSideNav = () => {
    setIsSideNav(true);
    dispatch(collapseSideNav(false));
  };

  const closeSideNav = useCallback(() => {
    setIsSideNav(false);
    dispatch(collapseSideNav(true));
  });

  useEffect(() => {
    if (window.innerWidth <= 768) {
      closeSideNav();
    }
  }, []);

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Clinic Workflow
    </div>
  );

  const onLinkClick = (link) => {
    // remove state reducer when click out
    dispatch(remove_selected_patient_resource());
    dispatch(remove_patient_on_schedules());
    dispatch(resetStateResource());
    closeSideNav();
  };

  return (
    <div className="clinicWorkflow">
      {renderMetadata()}
      <SubSideNav
        subMenu={sideNavLinks}
        title={getTitle()}
        isSideNav={isSideNav}
        openSideNav={openSideNav}
        closeSideNav={closeSideNav}
        onLinkClick={onLinkClick}
      />
      <MainWorkflow location={location} isSideNav={isSideNav} />
    </div>
  );
}

export default ClinicWorkFlow;

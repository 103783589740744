import { Icon } from "antd";
import React from "react";
import "./style.scss";

const FormStepper = ({
  steps,
  conditions,
  currentStep,
  handleStep,
  handleContinue,
  onCloseStep,
  resource,
  compelete_step_communication,
  onGetPending,
  ...restProps
}) => {
  const onStepClick = (stepIndex, currentStep) => () => {
    handleStep(stepIndex);
    if (onCloseStep) {
      if (compelete_step_communication) {
        if (onGetPending) {
          onGetPending();
        }
      }
      onCloseStep(stepIndex, currentStep);
    }
  };
  return (
    <div className="formStepContainer">
      {steps.length > 0 &&
        steps.map((step, index) => {
          return (
            <div key={`step-${step}-${index}`} className={`formStepper ${index <= currentStep ? "active" : ""}`}>
              <div id={`step${index}`} className="formStepper__header" onClick={onStepClick(index, currentStep)}>
                <div className="formStepper__status">
                  {index >= 0 && !conditions && (
                    <span className="formStepper__status-check">
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                    </span>
                  )}
                  {index >= 0 && conditions && !conditions[index] && (
                    <span className="formStepper__status-check">
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                    </span>
                  )}
                  {index >= 0 &&
                    conditions &&
                    conditions[index] &&
                    (conditions[index].step === "attempt" || conditions[index].step === "notes" || conditions[index].step === "finalize") &&
                    !conditions[index].status && (
                      <span className="formStepper__status-check">
                        <Icon type="plus-circle" theme="twoTone" twoToneColor="#F1C21B" />
                      </span>
                    )}
                  {index >= 0 &&
                    conditions &&
                    conditions[index] &&
                    (conditions[index].step === "attempt" || conditions[index].step === "notes" || conditions[index].step === "finalize") &&
                    conditions[index].status && (
                      <span className="formStepper__status-check">
                        <Icon type="plus-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    )}
                  {index >= 0 &&
                    conditions &&
                    conditions[index] &&
                    conditions[index].step !== "attempt" &&
                    conditions[index].step !== "notes" &&
                    conditions[index].step !== "finalize" &&
                    conditions[index].status && (
                      <span className="formStepper__status-check">
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    )}
                  {index >= 0 &&
                    conditions &&
                    conditions[index] &&
                    conditions[index].step !== "attempt" &&
                    conditions[index].step !== "notes" &&
                    conditions[index].step !== "finalize" &&
                    !conditions[index].status && (
                      <span className="formStepper__status-check">
                        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                      </span>
                    )}
                </div>
                <div className="formStepper__title">{step.title}</div>
              </div>

              <div className="formStepper__content">
                {index === currentStep && step.content && (
                  <step.content resource={resource} handleContinue={handleContinue} stepIndex={index} {...restProps} />
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FormStepper;

import { Icon, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useOutsideDetect } from "../../utils";
import DeleteIconButton from "../icons/DeleteIconButton";
import { ExpandSVG, SearchSVG } from "../icons/SVGIcon";
import Loading from "../Loading";
import ScrollWrapper from "../ScrollBar";
import "./style.scss";

const { Option } = Select;
const { Search } = Input;
const selectArray = [
  { value: "ASAP", id: "asap" },
  { value: "Within 2 weeks", id: "2_week" },
  { value: "2 to 4 weeks", id: "2_week_to_4_week" },
  { value: "1 to 3 months", id: "1_month_to_3_month" },
  { value: "3 to 6 months", id: "3_month_to_6_month" },
  { value: "6 to 12 months", id: "6_month_to_12_month" },
  { value: "1 year", id: "1_year" },
];

const SurgicalHistoryAutoCompleteComponent = ({
  rows,
  options,
  query,
  title,
  handleSearch,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  addItem,
  keyLabel,
  keyValue,
  keyType,
  selectItem,
  removeItem,
  disableOptions,
  onclickRow,
  activeIndex,
  isSelectRow,
  handleValueSelect,
  onChangeSelect,
  valueSelected,
  processArray,
  minRows,
  exception,
  loadingTable,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [valueSelectedGlobal, setValueSelectedGlobal] = useState({});
  const [selectRow, setSelectRow] = useState(false);
  const [valueHandleSelect, setValueHandleSelect] = useState([]);
  const [opData, setOpData] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [openResult, setOpenResult] = useState(false);
  const [removeable, setRemoveable] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);

  const data = rows ? rows : [];

  useEffect(() => {
    setOptionData(options);
    if (options && options.length > 0) {
      setActiveSearch(0);
    }
  }, [options]);

  const minRowCount = minRows ? minRows : 4;

  let emptyRows = [];

  useEffect(() => {
    setOpData(options);
  }, [optionData]);

  if (data && data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  useEffect(() => {
    setValueSelectedGlobal(valueSelected);
  }, [valueSelected]);

  useEffect(() => {
    setValueHandleSelect(handleValueSelect);
  }, [handleValueSelect]);

  useEffect(() => {
    setSelectRow(isSelectRow);
  }, [isSelectRow]);

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const handleSelectItem = (row) => {
    setSelected(row[keyLabel]);
    if (keyValue) {
      setSelectedValue(row[keyValue]);
    }
    if (keyType) {
      setSelectedType(row[keyType]);
    }
    setSearch(row[keyLabel]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (selectItem) {
      selectItem(selected, selectedValue, selectedType);
    } else {
      if (addItem) {
        addItem();
      }
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target?.value);
    if (handleSearch) {
      handleSearch(e.target?.value);
    }
  };

  const handleKeyPress = async () => {
    setSelected(opData?.[activeSearch]?.[keyLabel] || "");
    if (keyValue) {
      setSelectedValue(opData[activeSearch][keyValue]);
    }
    if (keyType) {
      setSelectedType(opData[activeSearch][keyType]);
    }
    setSearch(opData?.[activeSearch]?.[keyLabel] || "");
    setOpenResult(false);
    setActiveSearch(-1);
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (opData && opData[activeSearch + 1]) {
        setSelected(opData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      if (opData && opData[activeSearch - 1]) {
        setSelected(opData[activeSearch - 1]);
        setActiveSearch(activeSearch - 1);
      }
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  const selectHandle = (value, index) => {
    setValueSelectedGlobal({
      ...valueSelectedGlobal,
      [index]: value,
    });
    onChangeSelect(value, index);
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div ref={ref} className={`atuoComplete__filter-search ${disableSearchIcon ? "disable-icon" : ""}`}>
          <Search
            id="search_surgical"
            value={search}
            placeholder={placeholder || "Search"}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            onChange={changeSearchInput}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div className="atuoComplete__filter-action">
          <button className="atuoComplete__filter-add" onClick={addSelectedItem}>
            <span id="add_checksurgical">Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div style={{ zIndex: 1000 }} ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!opData.length &&
                  (opData || []).map((row, index) => {
                    return (
                      <div
                        className="atuoComplete__result-item"
                        key={`empty-${title}-${index}`}
                        onClick={() => handleSelectItem(row)}
                        style={{
                          backgroundColor: index == activeSearch ? "#DCE3EE" : "#fff",
                        }}
                      >
                        <p style={{ paddingLeft: "28px", whiteSpace: "pre" }}>{row ? row[keyLabel] : ""}</p>
                      </div>
                    );
                  })}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>

      <div className="atuoComplete__result" style={{ height: `calc(${(2.5 * minRowCount).toString()}rem + 4px)` }}>
        <ScrollWrapper css="no-padding x-hidden">
          <div className="atuoComplete__result-list">
            {loadingTable ? (
              <Loading />
            ) : (
              <React.Fragment>
                {(data || []).map((row, index) => (
                  <div
                    className={`atuoComplete__result-item`}
                    style={{
                      margin: 0,
                      width: "100%",
                      position: "relative",
                      padding: 5,
                      paddingLeft: selectRow ? 15 : 10,
                      paddingRight: "32px",
                      backgroundColor: activeIndex === index ? "#dbe3ee" : "#fff",
                      border: activeIndex === index ? "1px solid #dbe3ee" : "1px solid #dfe3e6",
                      borderBottom: activeIndex === index ? "0px solid #dbe3ee" : "0px solid #dfe3e6",
                    }}
                    key={`value-${title}-${row}`}
                    onClick={() => clickHandle(row, index)}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        marginRight: "0.5rem",
                        cursor: "pointer",
                        width: selectRow && valueHandleSelect && valueHandleSelect[index] !== "clinic" ? "80%" : "100%",
                      }}
                    >
                      <p style={{ marginLeft: selectRow ? 13 : 12 }}>{row || ""}</p>
                    </div>
                    {selectRow && valueHandleSelect && valueHandleSelect[index] !== "clinic" ? (
                      <div
                        style={{
                          display: "flex",
                          width: "20%",
                        }}
                      >
                        <Select
                          suffixIcon={<ExpandSVG />}
                          allowClear={true}
                          value={valueSelectedGlobal && valueSelectedGlobal[index]}
                          placeholder="Urgency"
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            selectHandle(value, index);
                          }}
                        >
                          {selectArray.map((r, index) => {
                            return (
                              <Option style={{ width: "100%" }} key={`${r?.id}-${index}`} value={r?.id}>
                                {r?.value || ""}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    ) : null}
                    {
                      <div
                        className="icon_process"
                        style={{
                          width: 30,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {processArray && processArray[index] ? <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" /> : ""}
                      </div>
                    }
                    <div style={{ position: "absolute", right: "12px" }}>
                      <DeleteIconButton
                        selected={removeable === index}
                        icon={"can"}
                        handleClick={() => {
                          if (removeable === index) {
                            removeItem(row, index);
                            setRemoveable(-1);
                          } else {
                            setRemoveable(index);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </React.Fragment>
            )}

            {emptyRows.map((row, index) => (
              <div className="atuoComplete__result-item empty" key={`empty-${title}-${index}`} />
            ))}
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default SurgicalHistoryAutoCompleteComponent;

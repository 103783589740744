import React, { useEffect, useState, useMemo } from "react";
import { changeTab, add_plan_me, selectPatient, setPatientSnapshotData } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import DeleteIconButton from "../icons/DeleteIconButton";
import ScrollWrapper from "../ScrollBar";
import { getLocal } from "../../../helpers/Local";

const ExtendDataTable = ({
  title,
  data,
  disableHeader,
  options,
  widths,
  minRows,
  onClickRow,
  onClickColumn,
  isActiveBorder = false,
  showDelete = false,
  indexDelete = -1,
  isFixed,
  activeRow,
  disableDelete,
  isBrightBlueColor,
  isBorderOnHover,
  isBorderLeftNone,
  isBorderThick,
  handleDeleteRow = () => {},
}) => {
  const dispatch = useDispatch();
  const [activeIndex, setActive] = useState(-1);
  const patient_id = getLocal("current_patient_id");
  const emptyRows = useMemo(() => {
    const rowCount = minRows ? minRows : 4;
    if (data && data.length) {
      const numberOfRows = data.length > rowCount ? 0 : rowCount - data.length;
      return new Array(numberOfRows).fill("empty");
    }
    return new Array(rowCount).fill("empty");
  }, [data]);
  const handle_action = async (row) => {
    const result = await dispatch(selectPatient(patient_id));
    await dispatch(setPatientSnapshotData("resource", { ...result }));
    if (row["tab"] && row["tab"] == "plan_order") {
      const save_success = await dispatch(
        add_plan_me(row["appointment_id"] ? row["appointment_id"] : "", {
          meaningful_use_id: row["id"] ? row["id"] : "",
        })
      );
      if (save_success && save_success.result && save_success.result == true) {
        await dispatch(
          changeTab({
            tabActive: row["tab"] ? row["tab"] : "",
            accordion: row["accordion"] ? row["accordion"] : "",
            step: row["step"] ? row["step"] : "",
          })
        );
      }
    } else {
      await dispatch(
        changeTab({
          tabActive: row["tab"] ? row["tab"] : "",
          accordion: row["accordion"] ? row["accordion"] : "",
          step: row["step"] ? row["step"] : "",
        })
      );
    }
  };

  useEffect(() => {
    if (activeRow === -1) {
      setActive(-1);
    } else {
      setActive(activeRow);
    }
  }, [activeRow]);

  return (
    <div className="subDataTable" style={{ height: "100%" }}>
      {!disableHeader && (
        <div
          className="subDataTable__title"
          style={{
            padding: "0",
            ...(isBorderLeftNone && { borderLeft: "none", borderRight: "none" }),
            ...(isBorderThick && { borderBottom: "2px solid #dfe3e6" }),
          }}
        >
          {title}
        </div>
      )}
      <div
        style={{
          height: isFixed ? "auto" : "calc(100% - 48px)",
          border: "1px solid #dbe3ee",
          borderTop: 0,
        }}
      >
        {isFixed ? (
          <>
            {(data || []).map((row, index) => (
              <div
                className={`subDataTable__item border ${activeIndex === index ? "active" : ""} ${row.row_color_status ? row.row_color_status : ""} ${
                  isActiveBorder ? (row.row_color_status ? row.row_color_status + "-border" : "blue-border") : ""
                }`}
                key={`item-${row.item}-${index}-${row.key}`}
                onClick={() => {
                  setActive(index);
                  if (onClickRow) {
                    onClickRow(index);
                  }
                }}
                style={{
                  position: "relative",
                  height: row?.extraData ? "5rem" : "2.5rem",
                  paddingTop: row?.extraData ? 3 : 0,
                  alignItems: row?.extraData ? "flex-start" : "center",
                }}
              >
                {(options || []).map((keyOption, index) => (
                  <span
                    key={keyOption}
                    style={{
                      width: "100%",
                      maxWidth: `${widths && widths[index] ? widths[index] : "calc(100% / " + options.length + ")"}`,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingRight: "1px",
                    }}
                    onClick={() => {
                      if (onClickColumn) {
                        onClickColumn(index);
                      }
                    }}
                  >
                    {keyOption == "recommendation" ? (
                      <p
                        onClick={() => {
                          handle_action(row);
                        }}
                        style={isBrightBlueColor ?? { color: "#305AA2" }}
                      >
                        {row[keyOption] || row["name"]}
                      </p>
                    ) : (
                      row[keyOption] || row["name"]
                    )}
                    {row?.extraData && row?.extraData}
                  </span>
                ))}
                {!disableDelete && showDelete && (
                  <DeleteIconButton
                    handleClick={() => {
                      handleDeleteRow && handleDeleteRow(index, row);
                    }}
                    selected={indexDelete === index}
                    icon={"can"}
                    extraStyle={{
                      display: "inline-block",
                      right: "0.75rem",
                      marginRight: 20,
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            ))}
            {(emptyRows || []).map((row, index) => (
              <div className={`subDataTable__item border`} key={`item-${row}-${index}`} />
            ))}
          </>
        ) : (
          <ScrollWrapper css="no-padding x-hidden">
            {(data || []).map((row, index) => (
              <div
                className={`subDataTable__item border ${isBorderOnHover && "falseBorderOnHover"} ${activeIndex === index ? "active" : ""} ${
                  row.row_color_status ? row.row_color_status : ""
                } ${isActiveBorder ? (row.row_color_status ? row.row_color_status + "-border" : "blue-border") : ""}`}
                key={`item-${row.item}-${index}-${row.key}`}
                onClick={() => {
                  setActive(index);
                  if (onClickRow) {
                    onClickRow(index);
                  }
                }}
              >
                {(options || []).map((keyOption, index) => (
                  <span
                    key={keyOption}
                    style={{
                      width: "100%",
                      maxWidth: `${widths && widths[index] ? widths[index] : "calc(100% / " + options.length + ")"}`,
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      paddingRight: "1px",
                    }}
                    onClick={() => {
                      if (onClickColumn) {
                        onClickColumn(index);
                      }
                    }}
                  >
                    {keyOption == "recommendation" ? (
                      <p
                        onClick={() => {
                          handle_action(row);
                        }}
                        style={{ color: "#305AA2" }}
                      >
                        {row[keyOption] || row["name"]}
                      </p>
                    ) : (
                      row[keyOption] || row["name"]
                    )}
                  </span>
                ))}
                {!disableDelete && showDelete && (
                  <DeleteIconButton
                    handleClick={() => {
                      handleDeleteRow && handleDeleteRow(index, row);
                    }}
                    selected={indexDelete === index}
                    icon={"can"}
                    extraStyle={{
                      display: "inline-block",
                      right: "0.75rem",
                      marginRight: 20,
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
            ))}
            {(emptyRows || []).map((row, index) => (
              <div className={`subDataTable__item border`} key={`item-${row}-${index}`} />
            ))}
          </ScrollWrapper>
        )}
      </div>
    </div>
  );
};

export default ExtendDataTable;

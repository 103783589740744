import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import { get_image_list, set_id_image, setGlobalLoading, setGlobalToastr } from "../../../store/actions";
import DataTable from "../../components/DataTable/WhiteHeaderTable";
import { BASE_API } from "../../constants/constants";
import { Upload } from "antd";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import { validateField } from "src/services/validations";
import { edit_patient_image, save_new_patient_image } from "src/services/api.services";

class ImageList extends Component {
  state = {
    isSidebar: false,
    image: "",
    imageList: [],
    oldImageList: [],
    imageUnique: [],
    imageAdds: [],
    imageLoader: 0,
    examData: "Please load an exam first",
    imageId: 0,
    examTop: 50,
    dateFilter: undefined,
    rowActive: null,
    stateData: {},
    link: "",
    provider: undefined,
    type: undefined,
    patient_id: null,
    isCreate: false,
    sort: {
      date: "",
      type: "",
    },
  };

  componentDidMount() {
    this.setState({
      patient_id: getLocal("current_patient_id"),
    });
    if (this.state.imageLoader === 0) {
      this.fetchImageList(true);
      this.setState({
        imageLoader: 1,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.image_list) {
      this.setState({
        imageList: nextProps.image_list,
        oldImageList: nextProps.image_list,
      });
      /**
       * Set the top margins
       */
      this.setState({
        examTop: 50 + nextProps.image_list.length * 42,
      });

      /**
       * Now create a unique list
       */
      const imageUnique = [];
      (nextProps?.image_list || []).map((item) => {
        if (!imageUnique.includes(item.exam_name)) {
          imageUnique.push(item.exam_name);
        }
      });
      this.setState({
        imageUnique: imageUnique,
      });
    }
  }

  loadExamAndViewer = (id, link, index) => {
    this.setState({
      imageId: id,
      link: link,
    });
    this.props.set_id_image(index);
  };

  saveClick = (row) => {
    this.setState({
      rowActive: row,
      imageAdds: [],
    });
  };

  handleSort = (sort) => {
    this.setState({ sort: { date: sort[0], type: sort[1] } });
  };

  /**
   * saveMessage
   */
  saveMessage = (e) => {
    e.preventDefault();
    fetch(BASE_API + "patients/exam", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        patient_id: 1,
        exam: this.state.examData,
        image_id: this.state.imageId,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        this.loadExamAndViewer(this.state.imageId, this.state.link);
        alert("Exam Data saved!");
      })
      .catch((error) => {
        console.log(error, "checkError image list");
      });
    this.setState({
      examData: "Loaded",
    });
  };

  changeFilter = (date) => {
    this.setState({
      dateFilter: date ? date : undefined,
    });
    const param = {
      date: date && moment(date).format("MM/DD/YYYY"),
      exam_name: this.state.type,
      provider_id: this.state.provider,
    };
    this.fetchImageList(false, param);
  };

  handleChangeType = (data) => {
    this.setState({
      type: data,
    });
    const param = {
      date: this.state.dateFilter && moment(this.state.dateFilter).format("MM/DD/YYYY"),
      exam_name: data,
      provider_id: this.state.provider,
    };
    this.fetchImageList(false, param);
  };

  handleChangeProvider = (data) => {
    this.setState({
      provider: data,
    });
    const param = {
      date: this.state.dateFilter && moment(this.state.dateFilter).format("MM/DD/YYYY"),
      exam_name: this.state.type,
      provider_id: data,
    };
    this.fetchImageList(false, param);
  };

  refreshList = () => {
    this.setState({
      imageLoader: 0,
    });
  };
  /**
   * messageOnChange
   * @param data
   */
  messageOnChange = (data) => {
    this.setState({
      examData: data,
    });
  };

  fetchImageList = async (first, param) => {
    const patient_id = getLocal("current_patient_id");
    if (first) {
      await this.props.get_image_list(patient_id, null);
    } else {
      await this.props.get_image_list(patient_id, param);
    }
  };

  saveUpdate = async () => {
    const detail = this?.state?.rowActive?.data || {};
    const { stateData, patient_id, imageAdds } = this.state;

    if (detail?.id) {
      this.props.setGlobalLoading(true);
      const formData = new FormData();
      formData.append("patient_id", patient_id);
      formData.append("exam_id", detail?.id);
      // TODO CUONG
      imageAdds.forEach((file) => {
        formData.append("files[]", file.originFileObj);
      });
      const postEdit = await edit_patient_image(formData);
      if (postEdit) {
        await this.props.get_image_list(patient_id, null);
        this.setState({
          imageAdds: [],
        });
        this.props.setGlobalToastr({
          header: "Edit Exam",
          message: "Edit Exam successfully!",
          type: "success",
        });
      } else {
        this.props.setGlobalToastr({
          header: "Edit Exam",
          message: "Edit Exam failed!",
          type: "failed",
        });
      }
      this.props.setGlobalLoading(false);
    } else {
      return;
    }
  };

  handleSaveNew = async () => {
    const { stateData, patient_id, imageAdds } = this.state;
    if (!stateData?.type || !stateData?.date || !patient_id) {
      return;
    }
    this.props.setGlobalLoading(true);
    const formData = new FormData();
    formData.append("patient_id", patient_id);
    formData.append("exam_name", stateData?.type);
    formData.append("date", stateData?.date);
    // TODO CUONG
    imageAdds.forEach((file) => {
      formData.append("files[]", file.originFileObj);
    });
    const postUpload = await save_new_patient_image(formData);
    if (postUpload) {
      await this.props.get_image_list(patient_id, null);
      this.props.setGlobalToastr({
        header: "Save New Exam",
        message: "Save new Exam successfully!",
        type: "success",
      });
      this.setState({
        imageAdds: [],
        isCreate: false,
      });
    } else {
      this.props.setGlobalToastr({
        header: "Save New Exam",
        message: "Save new Exam failed!",
        type: "failed",
      });
    }
    this.props.setGlobalLoading(false);
  };

  render() {
    const lengthOfEmpty = Math.max(Math.floor((window.innerHeight - 390) / 40), 0);
    const emptyRowLength = this.state.imageList && lengthOfEmpty > this.state.imageList.length ? lengthOfEmpty - this.state.imageList.length : 0;
    const detail = this?.state?.rowActive?.data || {};
    const { stateData } = this.state;

    return (
      <div
        className="resourceInfo d-flex"
        style={{
          width: "100%",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <div
          className="resourceInfo d-flex"
          style={{
            width: "100%",
            marginTop: -60,
            height: "calc(100vh - 350px)",
            minHeight: "25vh",
          }}
        >
          <DataTable
            isShowBodyBorders={true}
            isBorderThick={true}
            isRmBorders={true}
            hideHeaderHeight={true}
            title="patient_chart_imagelist"
            isNormal={true}
            disableDelete={true}
            labels={["Date", "Type"]}
            widths={["35%", "65%"]}
            showIcon={false}
            columns={[
              { sortable: true, key: "date" },
              { sortable: true, key: "type" },
            ]}
            handleSort={(sortOpt) => this.handleSort(sortOpt)}
            handleClickRow={(row, index) => {
              this.props.setResetClickAction && this.props.setResetClickAction(true);
              this.saveClick(row);
              this.loadExamAndViewer(row.data?.id, row.data.image_location, index);
            }}
            rowData={(this.state.imageList || []).map((item, index) => ({
              key: `image-list-${item?.id}-${index}`,
              data: item,
              date: item.date && moment(item.date).format("MM/DD/YYYY"),
              type: item.exam_name,
            }))}
            emptyRows={new Array(emptyRowLength).fill(0)}
            sorts={[this.state.sort.date, this.state.sort.type]}
          />
        </div>
        {/* show detail when click 1 image */}
        {this.props.haveDetail ? (
          <div
            style={{
              width: "100%",
              height: 350,
            }}
          >
            {this.state.isCreate ? (
              <>
                <hr></hr>
                <div className="resourceContent" style={{ position: "relative" }}>
                  <div className="resourceInfo d-flex required-field" style={{ flexDirection: "column", alignItems: "flex-start" }}>
                    <b style={{ marginBottom: 10 }}>Type: </b>
                    <EditableFormInput
                      label={"Type"}
                      handleChange={(value) => {
                        this.setState({
                          stateData: {
                            ...stateData,
                            ["type"]: value,
                          },
                        });
                      }}
                      placeholder="Type"
                      value={stateData?.type}
                      isError={validateField("text", stateData.type)}
                      type={"check"}
                      fullWidth={true}
                      helperText={"Name Type is Required"}
                      enableErrorText={!stateData?.type}
                      required={true}
                      style={{ width: "100%" }}
                      allowClear={true}
                      optionKey={"name"}
                      valueKey={"name"}
                      options={this.props.dataType || []}
                    />
                  </div>
                  <div
                    className="resourceInfo d-flex required-field new-fix-date-border"
                    style={{ flexDirection: "column", alignItems: "flex-start" }}
                  >
                    <b style={{ marginBottom: 10 }}>Date: </b>
                    <EditableFormInput
                      label={"Date"}
                      fullWidth={true}
                      value={stateData?.date}
                      handleChange={(value) => {
                        this.setState({
                          stateData: {
                            ...stateData,
                            ["date"]: value,
                          },
                        });
                      }}
                      required={true}
                      activeBorderDate={true}
                      type={"date"}
                      isError={validateField("date", stateData.date)}
                      enableErrorText={!stateData?.date}
                      helperText={"Date should be MM/DD/YYYY!"}
                    />
                  </div>
                </div>
              </>
            ) : detail?.id ? (
              <>
                <p>
                  <b>Type: </b>
                  <label>{detail?.exam_name} </label>
                </p>
                <p style={{ marginTop: 5 }}>
                  <b>Date: </b>
                  <label>{detail?.date ? moment(detail?.date).format("MM/DD/YYYY") : null}</label>
                </p>
              </>
            ) : null}

            {/* upload */}
            <div
              className="detailContent"
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                {this.state.isCreate || detail?.id ? (
                  <Upload
                    fileList={this.state.imageAdds}
                    onRemove={(file) => {
                      const index = this.state.imageAdds.indexOf(file);
                      const newFileList = this.state.imageAdds.slice();
                      newFileList.splice(index, 1);
                      this.setState({
                        imageAdds: newFileList,
                      });
                    }}
                    onChange={(info) => {
                      let newFileList = [...info.fileList];
                      newFileList = newFileList.map((file) => {
                        if (file.response) {
                          // Component will show file.url as link
                          file.url = file.response.url;
                        }
                        return file;
                      });
                      this.setState({
                        imageAdds: newFileList,
                      });
                    }}
                    multiple={true}
                    className="text-right"
                  >
                    <button className="common-btn outlined-btn">Upload Image</button>
                  </Upload>
                ) : null}
                {this.state.isCreate ? (
                  <>
                    <button
                      className="common-btn outlined-btn"
                      style={{
                        marginLeft: "0.5rem",
                      }}
                      onClick={this.handleSaveNew}
                    >
                      Save
                    </button>
                    <button
                      className="common-btn blue-btn"
                      style={{
                        marginLeft: "0.5rem",
                      }}
                      onClick={() => {
                        this.setState({
                          imageAdds: [],
                          isCreate: false,
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    {this.state.imageAdds && this.state.imageAdds.length > 0 ? (
                      <button
                        className="common-btn outlined-btn"
                        style={{
                          marginLeft: "0.5rem",
                        }}
                        onClick={this.saveUpdate}
                      >
                        Save
                      </button>
                    ) : null}
                    <button
                      className="common-btn blue-btn"
                      style={{
                        marginLeft: "0.5rem",
                      }}
                      onClick={() => {
                        this.setState({
                          imageAdds: [],
                          isCreate: true,
                        });
                      }}
                    >
                      Create New Exam
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  image_list: state.common.image_list,
  providers: state.common.providers,
});

const mapDispatchToProps = {
  get_image_list,
  set_id_image,
  setGlobalLoading,
  setGlobalToastr,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageList);

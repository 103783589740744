import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateField } from "../../../services/validations";
import { searchMedications, selectPatient, setPatientSnapshotData, updateMedicalHistory } from "../../../store/actions";
import { useOutsideDetect } from "../../utils";
import DataTable from "../DataTable/WhiteHeaderTable";
import EditableFormInput from "../EditableText/EditPlanOrder";
import { SearchSVG } from "../icons/SVGIcon";
import ScrollWrapper from "../ScrollBar";
import "./style.scss";

const { Search } = Input;

const initFre = [
  {
    value: "Qid",
    id: "qid",
  },
  {
    value: "Bid",
    id: "bid",
  },
  {
    value: "Qdaily",
    id: "qdaily",
  },
  {
    value: "Qweekly",
    id: "qweekly",
  },
  {
    value: "Tid",
    id: "tid",
  },
  {
    value: "Prn",
    id: "prn",
  },
];
let timer = null;

const MedicationAutoComplete = ({ isResult, placeholder, disableSearchIcon, disableOptions, onclickRow, newRow, minRows, setRow }) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [openResult, setOpenResult] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const dataMedications = useSelector((state) => state.common.dataMedications);
  const resourceCheck = useSelector((state) => state.newPatient.resource);
  const loadingSearchMedications = useSelector((state) => state.common.loadingSearchMedications);

  useEffect(() => {
    if (resourceCheck?.medications && resourceCheck?.medications.length > 0) {
      const dataCheck = resourceCheck?.medications.map((r) => {
        return {
          frequency: r?.frequency,
          drug_name: r?.medication?.drug_name,
          full_medication: r?.full_medication,
          id: r?.medication_id,
          medication_id: r?.medication_id,
        };
      });
      const fixRow = {
        ...newRow,
        medications: dataCheck,
      };
      setData(dataCheck);
    } else {
      setData([]);
    }
  }, [resourceCheck]);

  useEffect(() => {
    if (dataMedications?.length > 0) {
      setActiveSearch(0);
    }
  }, [dataMedications]);

  const minRowCount = minRows ? minRows : 4;
  let emptyRows = [];
  if (data && data.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - data.length).fill({ label: "" });
  }

  const updateApi = async (dataValue) => {
    const dataCheck = (dataValue || []).map((r) => {
      return {
        frequency: r?.frequency,
        medication_id: r?.medication_id || r?.id,
      };
    });
    const fixRow = {
      ...newRow,
      medications: dataCheck,
    };
    await dispatch(setPatientSnapshotData("resource", { ...resourceCheck, ...fixRow }));
    await dispatch(updateMedicalHistory(fixRow, resourceCheck?.id));
    await dispatch(selectPatient(resourceCheck?.id));
    setRow && setRow({ ...resourceCheck, ...fixRow });
  };

  const handleChange = (type, value, index, item) => {
    data[index][type] = value;
    updateApi([...data]);
    setData([...data]);
  };

  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);

  const fetchSearch = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(searchMedications(search));
    }, 500);
  };

  useEffect(() => {
    fetchSearch();
  }, [search]);

  const handleSelectItem = (row) => {
    setSelected({ ...row, frequency: null });
    setSearch(row["full_medication"]);
    setOpenResult(false);
  };

  const addSelectedItem = () => {
    if (!selected) {
      return;
    }
    if (!(data || []).some((r) => r?.id === selected?.id)) {
      updateApi([...data, selected]);
      setData([...data, selected]);
      const index = data.length;
      setActiveIndex(index);
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    setSearch(e.target?.value);
  };

  const handleKeyPress = async () => {
    setSelected(dataMedications[activeSearch]);
    setSearch(dataMedications[activeSearch]["full_medication"]);
    setOpenResult(false);
    setActiveSearch(-1);
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (dataMedications && dataMedications[activeSearch + 1]) {
        setSelected(dataMedications[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      if (dataMedications && dataMedications[activeSearch - 1]) {
        setSelected(dataMedications[activeSearch - 1]);
        setActiveSearch(activeSearch - 1);
      }
    }
  };

  const clickHandle = (row, index) => {
    if (onclickRow) {
      onclickRow(row, index);
    }
  };

  const removeItem = (row, index) => {
    if (data) {
      data.splice(index, 1);
      updateApi([...data]);
      setData([...data]);
    }
  };

  return (
    <div className="atuoComplete">
      <div className="atuoComplete__filter">
        <div ref={ref} className={`atuoComplete__filter-search ${disableSearchIcon ? "disable-icon" : ""}`}>
          <Search
            id="search_medication"
            value={search}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            style={{ width: "100%" }}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={() => setOpenResult(true)}
          />
        </div>
        <div className="atuoComplete__filter-action">
          <button className="atuoComplete__filter-add" onClick={addSelectedItem}>
            <span id="add_checkmedications">Add</span>
            <span>+</span>
          </button>
        </div>
        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoComplete__filter-result">
            <div className="atuoComplete__filter-result-list">
              <ScrollWrapper css="no-padding">
                {!!dataMedications.length &&
                  (dataMedications || []).map((row, index) => (
                    <div
                      className="atuoComplete__result-item"
                      key={`empty-medication-${index}`}
                      onClick={() => handleSelectItem(row)}
                      style={{
                        backgroundColor: index == activeSearch ? "#DCE3EE" : "#fff",
                      }}
                    >
                      <p style={{ paddingLeft: "28px" }}>{row["full_medication"]}</p>
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
      <div
        className="atuoComplete__result fullContainer"
        style={{
          height: `calc((${minRowCount} * 40px) + 50px + 0.5rem)`,
          background: "#fff",
        }}
      >
        <DataTable
          title="medication_autocomplete"
          isNormal={true}
          bordered={true}
          activeIndex={activeIndex}
          noneAllActive={true}
          isFixHeight={true}
          sizeHeightFix={`calc((${minRowCount} * 40px) + 50px)`}
          isNotFirstactive={true}
          labels={["Medication Name", "Frequency"]}
          widths={Array.from({ length: 2 }).map((_, index) => {
            return selectedColumn === index ? "55%" : "45%";
          })}
          columns={[
            { sortable: false, key: "medication", noPadding: true },
            { sortable: false, key: "frequency" },
          ]}
          handleDelete={(row, index) => removeItem(row, index)}
          handleClickRow={(row, index) => clickHandle(row, index)}
          onClickColumn={setSelectedColumn}
          rowData={(data || []).map((item, index) => {
            return {
              frequency: (
                <div
                  style={{
                    display: "flex",
                    width: "calc(100% + 0.75rem)",
                    marginLeft: "-0.75rem",
                  }}
                >
                  <EditableFormInput
                    label={"Frequency"}
                    handleChange={(value) => {
                      handleChange("frequency", value, index, item);
                    }}
                    value={item?.frequency || null}
                    isError={validateField("text", item?.frequency || item?.prescription?.frequency)}
                    fullWidth={true}
                    helperText={"Frequency is Required!"}
                    enableErrorText={true}
                    required={true}
                    type={"check"}
                    style={{ width: "100%" }}
                    allowClear={true}
                    optionKey={"id"}
                    valueKey={"value"}
                    options={initFre}
                  />
                </div>
              ),
              medication: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>{item?.full_medication || ""}</p>
                </div>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default MedicationAutoComplete;

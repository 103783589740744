import React from "react";
import "./style.scss";

const CardBlock = ({
  varient,
  title,
  other,
  children,
  style,
  contentPadding,
  subAction,
  hasTitle = true,
  isHpcclblock,
  isOverFlowHidden,
  fixContent,
  onClick = () => {},
  isOverFlow,
}) => {
  const obj = isOverFlow ? { overflow: isOverFlowHidden ? "hidden" : "scroll" } : { true: true };

  return (
    <div onClick={onClick} className={`cardBlock ${varient || "default"} ${other || ""} ${isHpcclblock && "hp_ccl_block"}`} style={style}>
      {hasTitle && <div className="cardBlock__title">{title}</div>}
      {subAction && <div className="cardBlock__subAction">{subAction}</div>}
      <div className={`cardBlock__content ${fixContent}`} style={{ paddingTop: contentPadding || "1.5rem", ...obj }}>
        {children}
      </div>
    </div>
  );
};

export default CardBlock;

import TextField from "@material-ui/core/TextField";
import React, { useEffect, useMemo, useState } from "react";
import InputMask from "react-input-mask";
import { RedRequiredCircleSVG } from "../icons/SVGIcon";
import "./style.scss";

const numberMask = "999";

const EditableInputWithSingleDiv = ({
  label,
  value,
  handleChange,
  subLabel,
  isError,
  required,
  enableErrorText,
  helperText,
  fullWidth,
  noEditable,
  unit,
  disabled,
  isErrorTextSpacing,
  isHideText,
  isCheckBox,
}) => {
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // check input have
    // step 1 check if above not have
    // step 2 check if is checkbox have but input not have
    // step 3 check if input have but checkbox not have
    // step 4 check all have
    if (!value && !isCheckBox) {
      setEnableHelper(true);
    } else if (!value && isCheckBox) {
      if (isError) {
        setEnableHelper(true);
      } else {
        setEnableHelper(false);
      }
    } else if (value && !isCheckBox) {
      setEnableHelper(false);
    } else if (value && isCheckBox) {
      setEnableHelper(false);
    }
    if (value && value !== inputValue) {
      setInputValue(value);
    } else if (!value) {
      setInputValue("");
    }
  }, [value, isError, isCheckBox]);

  const enableBlur = () => {
    setFocus(false);
  };

  // useEffect(() => {
  //   if (!inputValue) {
  //     setEnableHelper(true);
  //   } else {
  //     setEnableHelper(false);
  //   }
  // }, [inputValue]);

  const handleInputChange = (value) => {
    setInputValue(value);
    if (handleChange) {
      handleChange(value);
    }
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  return (
    <div className={`editableText material ${fullWidth ? "fullWidth" : ""}${isErrorTextSpacing && "isErrorTextSpacing"} `}>
      {noEditable && <TextField variant="outlined" label={lableContent} value={value || ""} style={{ pointerEvents: "none" }} />}

      {!noEditable && (
        <>
          <div
            className={`MuiFormControl-root outlined-root MuiTextField-root ${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""} `}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
          >
            {(focus || !!inputValue) && (
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
                {lableContent}
              </label>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                paddingRight: "14px",
                backgroundColor: `${disabled ? "#d6d6d6" : ""}`,
              }}
            >
              <InputMask
                mask={numberMask}
                value={inputValue}
                onChange={(e) => handleInputChange(e.target?.value)}
                onFocus={() => setFocus(true)}
                onBlur={enableBlur}
                maskPlaceholder={null}
              >
                <TextField className="no-border no-right-padding" style={{ flex: 1 }} placeholder={!focus && !inputValue && subLabel} />
              </InputMask>
              <div className="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary" style={{ fontSize: "0.875rem" }}>
                  {unit}
                </p>
              </div>
            </div>
          </div>
          {!!errorText && (
            <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`} {...(isHideText && { style: { visibility: "hidden" } })}>
              {errorText}
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default EditableInputWithSingleDiv;

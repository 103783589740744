import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import { autoZip } from "../../../../../services/api.services";
import { adminSettingSaveOrgDetail } from "../../../../../store/actions";

function OrganizationDetails({ resource }) {
  const dispatch = useDispatch();
  const [organizationName, setOrganizationName] = useState("");
  const [address1, setAddress1] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [zipAutoData, setZipAutoData] = useState({});
  const [fax, setFax] = useState("");
  const [phone, setPhone] = useState("");
  const [schedulingEmail, setSchedulingEmail] = useState("");

  useEffect(() => {
    const { loading, org_name, org_address1, org_state, org_city, org_zipcode, org_fax_number, org_phone_number, scheduling_email } = resource;
    if (!loading) {
      setOrganizationName(org_name);
      setAddress1(org_address1);
      setState(org_state);
      setCity(org_city);
      setZipCode(org_zipcode);
      setFax(org_fax_number === "(" ? "" : org_fax_number);
      setPhone(org_phone_number === "(" ? "" : org_phone_number);
      setSchedulingEmail(scheduling_email);
    }
  }, [resource]);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          setState(address.state_id);
          setCity(address.place);
        }
      } else {
        setState("");
        setCity("");
      }
    }
  }, [zipAutoData]);

  const handlInputChange = (key, val) => {
    const payload = {
      type: "org",
      key: key,
      value: val,
    };
    if (key === "org_zipcode") {
      dispatch(
        adminSettingSaveOrgDetail({
          type: "org",
          key: "org_state",
          value: state,
        })
      );
      dispatch(
        adminSettingSaveOrgDetail({
          type: "org",
          key: "org_city",
          value: city,
        })
      );
    }
    if ((key === "org_phone_number" && val === "(") || (key === "org_fax_number" && val === "(")) {
      payload.value = "";
    }
    dispatch(adminSettingSaveOrgDetail(payload));
  };

  const handleZipCode = (value) => {
    setZipCode(value);
    let timer;
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const zipData = await autoZip(value);
      if (!zipData) {
        setZipAutoData({ success: false });
      } else {
        setZipAutoData(zipData);
      }
    }, 1000);
  };

  return (
    <div
      className="orgianization__details"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Name of Organization"
        value={organizationName}
        onCustomBlur={() => handlInputChange("org_name", organizationName)}
        handleChange={(val) => setOrganizationName(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Address"
        value={address1}
        onCustomBlur={() => handlInputChange("org_address1", address1)}
        handleChange={(val) => setAddress1(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="State"
        value={state}
        onCustomBlur={() => handlInputChange("org_state", state)}
        handleChange={(val) => setState(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput label="City" value={city} onCustomBlur={() => handlInputChange("org_city", city)} handleChange={(val) => setCity(val)} />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Zip code"
        value={zipCode}
        onCustomBlur={() => handlInputChange("org_zipcode", zipCode)}
        handleChange={(val) => handleZipCode(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Phone Number"
        value={phone}
        mask={"phone"}
        onCustomBlur={() => handlInputChange("org_phone_number", phone)}
        handleChange={(val) => setPhone(val)}
      />
      <div style={{ marginTop: "0.5rem" }} />
      <EditableFormInput
        label="Fax Number"
        value={fax}
        mask={"phone"}
        onCustomBlur={() => handlInputChange("org_fax_number", fax)}
        handleChange={(val) => setFax(val)}
      />
      <EditableFormInput
        label="Scheduling Email"
        value={schedulingEmail}
        onCustomBlur={() => handlInputChange("org_scheduling_email", fax)}
        handleChange={(val) => setSchedulingEmail(val)}
      />
    </div>
  );
}

export default OrganizationDetails;

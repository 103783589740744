import createTypes from "redux-create-action-types";

const Types = createTypes(
  // auth actions
  "LOGIN",
  "LOGOUT",
  "GET_CURRENT_USER_LOGIN",
  // common location, provider actions
  "GET_LOCATIONS",
  "GET_LOCATIONS_STATUS",
  "GET_CLINIC_LOCATIONS",
  "ACTIVE_SUPPORT_TICKET",
  "GET_CLINIC_NURSING_LOCATIONS",
  "GET_VHC_LOCATIONS",
  "GET_DETAIL_LOCATIONS",
  "UPDATE_DETAIL_LOCATIONS",
  "GET_PROCEDURE_LOCATIONS",
  "GET_PROVIDERS",
  "GET_ONLY_PROVIDERS",
  "GET_LIST_TASK",
  "SET_ERROR_HPI",
  "GET_PROVIDERS_SORT_SCHEDULE",
  "ADD_TIMEOFF_REQUESTS",
  "GET_EVALUATIONS_USER",
  "POST_USER",
  "PUT_POLICIES",
  "POST_POLICIES",
  "DELETE_POLICIES",
  "DISABLE_USERS",
  "ENABLE_USERS",
  "GET_POLICIES",
  "GET_INCIDENT_REPORTS_ALL",
  "GET_EVALUATIONS",
  "GET_EVALUATIONS_DETAIL_TARGERT",
  "GET_EVALUATIONS_DETAIL",
  "GET_DETAIL_POLICIES",
  "GET_INCIDENT_REPORTS",
  "GET_INCIDENT_REPORTS_UPDATE",
  "INCIDENT_REPORTS_POST",
  "SET_FIRST_LOADING_ACTIVE",
  "GET_DISEASES",
  "GET_SURGICAL_HISTORIES",
  "GET_PCM",
  "GET_PCM_OVERVIEW",
  "GET_PCM_SUMMARY",
  "GET_PATIENTS_ANALYTICS",
  "GET_PATIENTS_TABLE_ANALYTICS",
  "GET_PATIENT_STATISTICS",
  "GET_PATIENT_REFERRAL_TYPES",
  "GET_PATIENT_REFERRAL_PROVIDERS",
  "GET_PROCEDURES_ANALYTICS",
  "GET_ULTRASOUND_ANALYTICS",
  "GET_CLINIC_ANALYTICS",
  "UPDATE_PCM",
  "GET_DETAIL_PCM",
  "GET_PCM_FOR_USER",
  "GET_PCM_FILTER",
  "GET_PCM_PROVIDER",
  "GET_ULTRASOUNDS",
  "GET_DETAIL_ULTRASOUNDS",
  "DELETE_ULTRASOUND_TYPE",
  "UPDATE_DETAIL_ULTRASOUNDS",
  "GET_PROVIDER_USER",
  "GET_PROCEDURE_DATA",
  "GET_DETAIL_PROCEDURE_DATA",
  "GET_CONDITION_ICD_CODE",
  "COPY_NODE_HPI",
  "DELETE_NOTE_HPI",
  "DELETE_PROCEDURE_DATA",
  "GET_DETAIL_CONDITION_DATA",
  "ADD_PROCEDURE_DATA",
  "UPDATE_DETAIL_PROCEDURE_DATA",
  "POST_PROVIDER_SAVE_SCHEDULE",
  "SAVE_PROCEDURE_CONDITION",
  "TIMEOFF_REQUESTS_ALL",
  "TIMEOFF_REQUESTS",
  "TIMEOFF_DETAIL_REQUESTS",
  "APPROVE_TIMEOFF_REQUESTS",
  "FILTER_LOCATIONS",
  "GET_USERS",
  "GET_PAYPERIODS",
  "GET_DETAIL_USERS",
  "SAVE_DETAIL_USERS",
  "SAVE_EMPLOYEE_INFO",
  "FILTER_PROVIDERS",
  "GET_REFERRAL_CONDITIONS",
  "DELETE_REFERRAL_CONDITIONS",
  "GET_REFERRED_PROVIDERS",
  "GET_PCP_LIST",
  "GET_PCP_FROM_NPE",
  "GET_CALL_RESULTS",
  "GET_CURRENT_USER",
  "GET_CURRENT_PATIENT",
  "GET_CURRENT_PROVIDER",
  "GET_TIME_ENTRIES",
  "SET_DATA_FIELD_CHECK_IN",
  "PUT_PATIENT_DOCUMENT",
  "GET_ASSIGNED_DOCUMENTS",
  "GET_FILTER_PATIENT_DOCUMENT",
  "GET_SORTED_PATIENT_DOCUMENT",
  "CLEAR_PATIENT_DOCUMENT",
  "SET_TOAST_PUT_BAR_CODE",
  "DELETE_FAX_LOG",
  "DELETE_SCAN_LOG",
  "DELETE_PATIENT_DOCUMENT",
  "AUTO_ZIP",
  "ADD_NEW_LOCATION",
  "DELETE_LOCATION",
  "ADD_NEW_PROCEDURE_SCHEDULE_URL",
  "REMOVE_ZIP_AUTO",
  "SORT_PROVIDER_NONE",
  "SORT_PROVIDER_ENDO",
  "EXPORT_PCM",
  "EXPORT_PCM_USER",
  "EXPORT_PCM_OVERVIEW",
  "REMOVE_DATA_PATIENT",
  "SET_CPT_CODE_REVIEW",
  "GET_CPT_CODE_REVIEW",
  "SREARCH_CPT_CODE",
  "GET_IMAGE_LIST",
  "SET_ID_IMAGE",
  "SEARCH_ALLERGY",
  "UNLOCK_FINALIZE",
  "SAVE_TEMP",
  "GET_ULTRASOUND_FINDING_VALUE",
  "SET_ERROR_ULTRASOUND",
  "SAVE_ULTRASOUND_FINDING_VALUE",
  "DETAIL_ULTRASOUND_FINDING_VALUE",
  "GET_ULTRASOUND_RULE_RESULT",
  "SAVE_ULTRASOUND_RULE_RESULT",
  "SET_DETAIL_ULTRASOUND_PROPS",
  "GET_LIST_PATIENTS",
  "SET_PCP_NULL",
  "REMOVE_REVIEW_ULTRASOUND",
  "ACTION_ADD_AND_SELECT",
  "EDIT_PROVIDERS",
  "UPLOAD_DOCUMENT_SETTING",
  "SET_COMMUNICATION_COMPELETE",
  "GET_PROVIDERS_LOCATION",
  "PROCEDURE_CANCEL",
  "PATIENT_CANCEL",
  "DELETE_CALL_ATTEMP",
  "DELETE_PROCEDURE_CALL_ATTEMPT",
  "GET_PHARMACIES",
  "SEARCH_MEDICATIONS",
  "GET_FIELD_MEDICATION",
  "GET_PHARMACY",
  "DELETE_PHARMACY",
  "SAVE_PHARMACY",
  "EDIT_PHARMACY",
  "GET_SINGLE_MEDICATION",
  "GET_LOCATION_PROVIDER",
  "SAVE_TEXT_REVIEW",
  "LOCK_ULTRASOUND",
  "GET_LOCK",
  "RELEASE_LOCK",
  "NOTIFY_LOCK",
  "GET_INTRA_ANSWER",
  "SHOW_BUTTON_BACK",
  "GO_TO_CHARTING",
  "CHANGE_MEDICAL_DECISION",
  "SET_CHECK_REQUIRE_FOR_REVIEW",
  "CHANGE_ACTIVE_ROW",
  "SET_ACTION_GO_NEXT_TAB",
  // New patient Actions
  "SELECT_PATIENT",
  "GET_EVENTS",
  "GET_PENDING",
  "GET_CALLBACK",
  "UPDATE_PATIENT",
  "ADD_PATIENT",
  "ADD_PATIENT_PENDING",
  "VERIFY_INSURANCE",
  "UPDATE_MEDICAL_HISTORY",
  "UPDATE_QUESTIONNAIRE",
  "GET_APPOINTMENTS",
  "FILTER_APPOINTMENTS",
  "ADD_CALL_ATTEMPTS",
  "UPDATE_CALL_ATTEMPTS",
  "SET_CALLBACK_SEARCH",
  "SET_PENDING_SEARCH",
  "SET_STEP_DATA",
  "SAVE_CLINIC_SCHEDULE",
  "SET_COMMON_DATA",
  "REMOVE_HAS_INSURANCE",
  "SAVE_PATIENT_NOTES_NEW",
  "GET_ALL_PATIENT_NOTES_NEW",
  "SAVE_NOTE_AS_PDF",
  "DELETE_PATIENT_NOTES_NEW",
  "SEARCH_INSURANCE_COMPANIES",
  "DETAIL_INSURANCE_COMPANIES",
  "INSURANCE_TYPES",
  "GET_PAYER_ID",
  "PATIENT_NODES_ID_INSURANCES",
  "CHECK_APOINTMENT",
  "SAVE_PATIENT_NODES_INSURANCES",
  "DETAIL_PATIENT_NODES_INSURANCES",
  "EDIT_PATIENT_NODES_INSURANCES",
  "DELETE_PATIENT_NODES_INSURANCES",
  "CHECK_PATIENT_NODES_INSURANCES",
  "RESET_CHECK_PATIENT",
  "DELETE_PROVIDER_SCHEDULE",
  "SAVE_INSURANCE_COMPANIES",
  "EDIT_INSURANCE_COMPANIES",
  "DELETE_INSURANCE_COMPANIES",
  "UPDATE_PATIENT_OPS",
  "SAVE_CHECK_QUESTION",
  "SET_ENTER_INSURANCE_CHECK",
  "SAVE_PATIENT_NOTES_NEW_BILLING",
  "GET_ALL_PATIENT_NOTES_NEW_BILLING",
  "DELETE_PATIENT_NOTES_NEW_BILLING",
  "RESET_SCHEDULES",
  "SET_QUEST_COMPARE",
  "SET_DATA_PATIENT",
  "REMOVE_SELECTED_PATIENT_RESOURCE",
  "SAVE_PARAMS_PLAN",
  "REMOVE_STATE_DELETE_CHART",
  "REMOVE_PLAN_REFERRAL_PROVIDER",
  "RESET_STATE_RESOURCE",
  "SET_CHANNEL_CHECK",
  "SET_TASK_MANAGER",
  "RESET_MEDICATION",
  "SET_ACTIVE_REQUIRE_DIAMETER",
  "SET_ACTIVE_REQUIRE_INDICATION",
  "SET_ACTION_ACTIVE",

  // procedure detail clinic procedure type
  "LIST_CLINIC_PROCEDURE_TYPE",
  "SAVE_CLINIC_PROCEDURE_TYPE",
  "DETAIL_CLINIC_PROCEDURE_TYPE",
  "EDIT_CLINIC_PROCEDURE_TYPE",
  "DELETE_CLINIC_PROCEDURE_TYPE",
  "UPDATE_TEXT_CLINIC_PROCEDURE_TYPE",
  "UPDATE_CPT_CLINIC_PROCEDURE_TYPE",
  "UPDATE_ICD_CLINIC_PROCEDURE_TYPE",
  // procedure detail clinic procedure type variable
  "LIST_CLINIC_PROCEDURE_TYPE_VARIABLE",
  "SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE",
  "DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE",
  "EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE",
  "DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE",

  // Procedure Actions
  "GET_PROCEDURE_PENDING",
  "GET_PROCEDURE_CALLBACK",
  "GET_ULTRASOUND_PENDING",
  "GET_ULTRASOUND_CALLBACK",
  "GET_CLINIC_PENDING",
  "GET_CLINIC_CALLBACK",
  "GET_AVAILABLE_PROCEDURE",
  "SELECT_PROCEDURE",
  "SET_PROCEDURE_DATA",
  "GET_PROCEDURE_EVENTS",
  "GET_PROCEDURE_SCHEDULE",
  "RESCHEDULE_PROCEDURE",
  "RESET_FOLOW",
  "CONFIRM_PROCEDURE",
  "GET_TIME_SLOTS",
  "GET_FOLLOW_UP",
  "SEARCH_PLAN_SUGGESTION",
  "GET_PLAN_PATIENT",
  "UPDATE_PLAN_PATIENT",
  "SAVE_PLAN_PATIENT",
  "SAVE_PROCEDURE_SCHEDULE",
  "SAVE_ULTRASOUND_SCHEDULE",
  "SAVE_PLAN_CLINIC_SCHEDULE",
  "SAVE_PROCEDURE_QUESTION",
  "GET_PATIENT_PROCEDURE",
  "SEARCH_DRUG",
  "ADD_PRE",
  "GET_PRE",
  "ADD_POST_OPERATIVE",
  "GET_POST_OPERATIVE",
  "DELETE_PLAN",
  "GET_SINGLE_PROCEDURE",
  "GET_EXISTING_FOLLOW_UP",
  "UNLOCK_CLINIC_PROCEDURE",
  "LOCK_CLINIC_PROCEDURE",
  "SET_FIRST_LOADED_SNAPSHOT",
  "GET_QUESTIONS_FOR_PROCEDURE",
  "SET_REDUX_PLAN_SIDE_BAR",
  "SAVE_PROCEDURE_CHOICE",
  "GET_PROCEDURE_CALL_ATTEMPT",
  "ADD_PROCEDURE_CALL_ATTEMPT",
  "UPDATE_PROCEDURE_CALL_ATTEMPT",
  "PROCESS_APPOINTMENT_MEDICATION",
  "GET_APPOINTMENT_MEDICATIONS",
  "REFILL_MEDICATION",
  "CREATE_MEDICATION",
  "GET_PRESCRIPTIONS",
  "GET_CALENDAR_PROCEDURE_TYPES",
  "GET_APPOINTMENT_INVENTORY",
  "SET_DATA_LOCAL_CHANGE_RECORD",
  "SAVE_APPOINTMENT_INVENTORY",
  "GET_PLAN_REFERRAL_PROVIDER",
  "SAVE_PLAN_REFERRAL_PROVIDER",
  "MARK_PLAN_PROCESSED",
  "GET_EMPLOYEE_CM",
  "RELEASE_CHARGE",
  "GET_REVIEW_STAT",
  "GET_APPOINTMENT_INSURANCE",

  // Clinic Reminders Actions
  "GET_REMINDERS",
  "GET_FILTER_TYPES",
  "SELECT_REMINDER",
  "SET_REMINDER_DATA",
  "SET_VITAL_OG",

  // Prior Authorization Actions
  "GET_PRIOR_AUTHORIZATION",
  "GET_CT_APPOINTMENTS",
  "SET_PRIOR_DATA",
  "GET_PRIOR_FILTER_TYPES",
  "SELECT_PRIOR",
  "GET_AUTHORIZATION_NUMBERS",
  "SAVE_PROIR_AUTHORIZATION",
  "SAVE_DEFAULT_SAVE_RESOURCE",

  // Patient Records
  "GET_COMMUNICATION_RECORDS",
  "GET_FAX_LOGS",
  "GET_SORTED_FAX_LOGS",
  "EMPTY_FAX_LOGS",
  "GET_SCAN_LOGS",
  "GET_SORTED_SCAN_LOGS",
  "EMPTY_SCAN_LOGS",
  "SEND_FAX",
  "RESEND_FAX",
  "SEND_SCAN",
  "CLEAR_DEATAIL_FAX_LOG",
  "CLEAR_DEATAIL_SCAN_LOG",
  "EMAIL_FAX",
  "PRINT_FAX",
  "UPLOAD_FAX",
  "UPLOAD_FAX_IMAGE",
  "DETAIL_FAX_LOG",
  "UPDATE_FAX_LOG",
  "DETAIL_SCAN_LOG",
  "EMAIL_SCAN",
  "ASSIGN_FAX",
  "PRINT_SCAN",
  "SAVE_FAX_TO_DOCUMENT",
  "SAVE_SCAN_TO_DOCUMENT",
  "FILTER_FAX_LOG",
  "SET_PATIENT_RECORD_DATA",
  "SET_PATIENT_FOR_RECORDS",
  "GET_INITIAL_PATIENT_DATA",
  "SEND_FAX_PATIENT_DOCUMENT",
  "EMAIL_PATIENT_DOCUMENT",
  "EMAIL_PATIENT_DOCUMENT_BULK",
  "FAX_PATIENT_DOCUMENT_BULK",
  "PRINT_PATIENT_DOCUMENT_BULK",
  "PRINT_PATIENT_DOCUMENT",
  "SET_DEFAULT_TOAST",
  "RESET_FAX_SCAN_DOCUMENT_LOG",
  "GET_PATIENT_HOSPITAL",
  "SAVE_IN_PATIENT_CHARTING",
  "UPDATE_IN_PATIENT_CHARTING",
  "SET_PARAMS_CHART",
  "REMOVE_PARAMS_CHART",
  "CHARTING_GENERATE_PDF",
  "RESET_CHARTING_PDF",
  "APPLY_CPT_RULES",
  "RESET_CPT_RULES",
  "RESET_APPOINTMENT_PDF",
  "PATIENT_DOCUMENT_DELETE_MULTIPLE",
  "SAVE_FINALIZE_IN_PATIENT_CHARTING",

  // Dashboard Page
  "REGISTER_NEW_PATIENT",
  "GET_PATIENT_DOCUMENTS",
  "GET_PATIENT_DOCUMENT_DETAILS",
  "GET_PATIENTS_WITH_QUERY",
  "GET_PROVIDER_PATIENTS_WITH_QUERY",
  "SET_PATIENT_HISTORY",
  "SAVE_QUICK_MESSAGE",
  "SET_DASHBORD_PATIENT_DEMOGRAPHICS",
  "SET_DASHBOARD_APPOINTMENTS",
  "SEARCH_PCP",
  "SEARCH_PCP_NPE",

  // Patient Schedule
  "GET_PATIENT_SCHEDULES",
  "SEARCH_PATIENT_SCHEDULES",
  "SET_SELECTED_PATIENT_RESOURCE",
  "CHOOSE_SELECTED_PATIENT_RESOURCE_BY_RANDOM",
  "CHOOSE_SELECTED_PATIENT_RESOURCE",
  "GET_PATIENT_NOTES",
  "ADD_PATIENT_NOTE",
  "UPDATE_PATIENT_NOTE",
  "DELETE_PATIENT_NOTE",
  "SAVE_PATIENT_NOTES",
  "PATIENT_SCHEDULE_VERIFY_INSURANCE",
  "PATIENT_SCHEDULE_CONFIRM_CHECKIN",
  "SET_DATA_TEMPLATE_REVIEW",
  "SET_DATA_COMPLICATION",
  "DELETE_PROCEDURE",
  "SET_COPY_FROM",

  // angio_type
  "GET_ANGIO_TYPE",
  "SAVE_ANGIO_TYPE",
  "DETAIL_ANGIO_TYPE",
  "EDIT_ANGIO_TYPE",
  "DELETE_ANGIO_TYPE",
  "UPDATE_ANGIO_TYPE_TEMPLATE",
  // angio_type_variable
  "GET_ANGIO_TYPE_VARIABLE",
  "SAVE_ANGIO_TYPE_VARIABLE",
  "DETAIL_ANGIO_TYPE_VARIABLE",
  "EDIT_ANGIO_TYPE_VARIABLE",
  "DELETE_ANGIO_TYPE_VARIABLE",
  "SAVE_ANGIO_MEDICATION_VITAL",

  // Scan Driver License
  "GET_SCAN_TOKEN_SCHEDULES",
  "SCAN_DRIVER_LICENSE_MATCHED",
  "SCAN_DRIVER_LICENSE_INCORRECT",

  // Scan Insurance Card
  "GET_SCAN_TOKEN_INSURANCE_CARD",
  "CHECK_SCAN_INSURANCE_CARD_RESULT",
  "CANCEL_SCAN_INSURANCE_CARD",

  // ID Card Data => panamacheng
  "GET_ID_CARD_INFO",

  // Clinic-Workflow/Provder Module
  "CHOOSE_PATIENT_ON_SCHEDULES",
  "REMOVE_PATIENT_ON_SCHEDULES",
  // "GET_PATIENT_SCHEDULES" //already defined in  Patient Schedule
  // "SEARCH_PATIENT_SCHEDULES" //already defined in Patient Schedule
  "UPLOAD_STARTED",
  "UPLOAD_DONE",
  "UPLOAD_REFRESH",
  "SAVE_UPLOADED_PATIENT_DOCUMENT",
  "SEARCH_PATIENT_DOCUMENTS",
  "OPEN_PATIENT_DOCUMENT",
  "GET_PATIENT_DOCUMENT",
  "DELETE_CLINIC_PROVIDER_PATIENT_SNAPSHOT",

  // Admin-Referral Condition
  "ADMIN_REFERRAL_CONDITION_LIST",
  "ADMIN_REFERRAL_CONDITION_GET",
  "ADMIN_REFERRAL_CONDITION_POST",
  "ADMIN_REFERRAL_CONDITION_UPDATE",
  "ADMIN_REFERRAL_CONDITION_CLEAR_STATUS_MESSAGE",
  "ADMIN_REFERRAL_CONDITION_OPEN_NEW_PAGE",
  "SET_ADMIN_REFERRAL_CONDITION",

  // Clinic->Provider Module[page]->HPI
  "HPI_GET_CONDITIONS",
  "HPI_SET_CONDITIONS",
  "HPI_SAVE_CONDITION",
  "LOAD_CONDITION_HPI_UNTRASOUND",
  "RESET_SELECT_DOCUMENT",
  "SET_IS_SAVE_HPI",
  "SET_IS_SAVE_DETAIL",

  // Clinic->Provider Module[page]->OFFICE
  "OFFICE_GET_CONDITIONS",
  "OFFICE_SET_CONDITIONS",
  "OFFICE_SAVE_CONDITION",

  // Inventory workflow
  "GET_INVENTORY_SEARCH",
  "SET_INVENTORY_DATA",
  "GET_ANGIOGRAPHIC_SUPPLIES",
  "GET_INVENTORY_PRODUCTS",
  "GET_INVENTORY_LOCATIONS",
  "GET_INVENTORY_SPECIFIC_LOCATIONS",
  "GET_NARCOTIC_INVENTORIES",
  "ADD_NEW_INVENTORY",
  "UPDATE_INVENTORY_ITEM",
  "GET_INVENTORY_COST_HISTORY",
  "ADD_INVENTORY_COST_HISTORY",
  "ADD_TO_PURCHASE_LIST",
  "UPDATE_ANGIOGRAPHIC_SUPPLY",
  "GET_PURCHASE_LIST",
  "ORDER_PURCHASE_ITEM",
  "GET_RECEIVING_ORDERS",
  "UPDATE_RECEIVING_ORDER",
  "GET_RETURN_CREDIT_ORDERS",
  "UPDATE_NARCOTIC_INVENTORY",
  "DELETE_INVENTORY_ITEM",
  "UPDATE_INVENTORY_NOTES",
  "DELETE_PURCHASE_ITEM",
  "GET_INVENTORY_USERS",
  "INVENTORY_ORDER_ITEM_RECEIVED",
  "INVENTORY_ORDER_CREDIT_RECEIVED",
  "INVENTORY_ORDER_SHIPPED",
  "GET_ALL_ORDERS",
  "DELETE_RECEIVING_ORDER",

  // Clinic->Provider->ROS/Vitals & Physical Examinations
  "PATIENT_EXAMINATION_LOAD_INIT",
  "PATIENT_EXAMINATION_SET_DATA",
  "PATIENT_EXAMINATION_COPY_LAST",
  "PATIENT_EXAMINATION_SAVE",
  "PATIENT_EXAMINATION_SET_NOMAL_CHECK",
  "SAVE_CHIEF_COMPLAINT",
  "SAVE_APPOINTMENT_NOTES",
  "RESET_VITAL_MEDICATION",
  "CLINIC_COPY_LAST",
  "ANGIO_COPY_LAST",
  "UPDATE_COPY_HPI",

  // admin->meaningful
  "GET_MEANINGFUL_STATISTICS",
  "GET_DEFICIENCY_LIST",
  "BULK_FINALIZE",
  "ADD_PLAN_ME",
  "EXPORT_REPORT",

  // Clinic->Provider->Review
  "ULTRASOUND_REVIEW_LOAD_INIT",
  "CLINIC_REVIEW_LOAD_INIT",
  "CHANGE_TAB",
  "EDIT_ICD",
  "SAVE_APPOINTMENT_ICD",

  // Clinic->Provider->PatientSnapshot
  "CLINIC_PATIENT_SNAPSHOT_LOAD_INIT",
  "CLINIC_PATIENT_SNAPSHOT_SAVE_MEDICAL_HISTORY",
  "ANGIO_PATIENT_SNAPSHOT_LOAD_INIT",
  "LOAD_INTRA_OPERATIVE",
  "SAVE_INTRA_OPERATIVE",
  "LOAD_ULTRASOUND_HISTORY_FOR_CONDITION",
  "UPDATE_COPY_INTRA",

  // Clinic->Provider->Plan
  "CLINIC_PLAN_LOAD_INIT",

  // Procedure Workflow->Procedure Module->Consent
  "PROCEDURE_CONSENT_LOAD_INIT",
  "PROCEDURE_CONSENT_OPEN_CREATE",
  "PROCEDURE_CONSENT_SAVE_DOCUMENT",
  "PROCEDURE_CONSENT_CLOSED_CREATE",
  "PROCEDURE_CONSENT_SELECT_DOCUMENT",
  "PROCEDURE_CONSENT_SEARCH",
  "PROCEDURE_CONSENT_SET_SEARCH_DATA",
  "PROCEDURE_CONSENT_GET_SIGNATURE_IMAGE",
  "PROCEDURE_CONSENT_SET_SIGNATURE_IMAGE",
  "PROCEDURE_CONSENT_DELETE",
  "PROCEDURE_CONSENT_SEND_FAX",
  "PROCEDURE_CONSENT_SEND_EMAIL",
  "SAVE_CT_INFO_PLAN",

  // UltraSound
  "SET_ULTRASOUND_DATA",
  "SAVE_NEW_ULTRASOUND",
  "SET_ULTRASOUND_VARIABLE",
  "POST_ULTRASOUND_QUESTION",
  "GET_ULTRASOUND_QUESTION",
  "GET_ULTRASOUND_EVENTS",

  // Quick Search History
  "ADD_SEARCH_HISTORY",
  "GET_SEARCH_HISTORY_LIST",

  // Reset State
  "RESET_ALL_STATES",
  "RESET_PATIENT_STATES",
  "SET_GLOBAL_ERROR_MESSAGE",
  "GET_GLOBAL_MESSAGES",
  "ADD_GLOBAL_MESSAGE",
  "DELETE_GLOBAL_MESSAGE",

  // Admin user
  "ADMIN_USER_GET_SIGNATURE_IMAGE",
  "GET_EMPLOYEE_DOCUMENTS",
  "GET_DETAIL_EMPLOYEE_DOCUMENTS",
  "DELETE_EMPLOYEE_DOCUMENTS",
  "SEND_FAX_EMPLOYEE_DOCUMENTS",
  "EMAIL_FAX_EMPLOYEE_DOCUMENTS",
  "PRINT_FAX_EMPLOYEE_DOCUMENTS",
  "HIPAA_GET_LIST",
  "GET_JOB_STATUS",

  // Patient Snapshot
  "SET_PATIENT_SNAPSHOT_DATA",

  // dicom
  "CLEAR_STORE",
  "LOCALFILE_STORE",
  "DCM_IS_OPEN",
  "DCM_TOOL",
  "ACTIVE_DCM_INDEX",
  "ACTIVE_DCM",
  "DCM_IMAGE",
  "ACTIVE_MEASUREMENTS",
  "LAYOUT",
  "DICOMDIR",
  "FSCURRENTDIR",
  "FSCURRENTLIST",
  "FSZIPPEDFILE",
  "FSREFRESH",
  "SANDBOXEDFILE_STORE",
  "SET_URL_IMAGE",

  // Procedure Module -> Review
  "SET_PROCEDURE_MODULE_REVIEW",
  "ADD_PROCEDURES",
  "LOAD_PROCEDURALS",
  "SAVE_PROCEDURALS",
  "SAVE_INFO_SIDE",
  "SAVE_BULK_APPOINTMENT",

  // Admin Setting
  "ADMIN_SETTING_LOGO_UPLOAD",
  "ADMIN_SETTING_GET_CONFIG",
  "ADMIN_SETTING_SAVE_OCR_INFO",
  "ADMIN_SETTING_SAVE_ORG_DETAIL",
  // administrator => setting => search => primary-providers.
  "GET_PRIMARY_PROVIDERS_DETAIL",
  "UPDATE_PRIMARY_PROVIDER_DETAIL",
  "CREATE_NEW_PRIMARY_PROVIDER",

  // settings
  "GET_OCR_CATEGORY",
  "GET_OCR_WORDS",
  "POST_OCR_WORDS",
  "PUT_OCR_WORDS",
  "DELETE_OCR_WORDS",

  // Procedure Detail
  "SORT_PROCEDURE_VARIABLES",
  "SET_PROCEDURE_DETAILS",
  "ADD_PROCEDURE_DETAILS",
  "GET_PROCEDURE_TYPE",
  "SAVE_PROCEDURE_TYPE",
  "EDIT_PROCEDURE_TYPE",
  "DELETE_PROCEDURE_TYPE",
  "UPDATE_PROCEDURE_TYPE_CPT",
  "REFREST_STATE_TAB",
  "TEXT_TEMPLATE_PROCEDURE",
  "GET_ME_VARIABLE_SELECT",
  "GET_ME_VARIABLE_RULE",
  "SAVE_ME_VARIABLE_RULE",
  "DETAIL_ME_VARIABLE_RULE",
  "DELETE_ME_VARIABLE_RULE",
  "EDIT_ME_VARIABLE_RULE",
  "RESET_NEW_PATIENT",
  "GET_PRIOR_AUTHORIZATION_SETTINGS",
  "SAVE_PRIOR_AUTHORIZATION_SETTINGS",
  "REMOVE_DETAIL_CLINIC",
  "UPDATE_REASON_VISIT",
  "UPDATE_SAME_HPI_SELECTION_REASON",
  "UPDATE_APPOINTMENT_FINALIZED_DATE",
  "SAVE_SAME_HPI_CHECK",

  // angio detaill
  "ANGIO_CONDITION_TYPE",
  "ANGIO_CONDITION_TYPE_CHOICE",
  "ANGIO_CONDITION_TYPE_ICD",
  "ANGIO_CONDITION_TYPE_CPT",

  // ultrasound Detail
  "GET_ULTRASOUND_TYPE_VARIABLE",
  "SAVE_ULTRASOUND_TYPE_VARIABLE",
  "DETAIL_ULTRASOUND_TYPE_VARIABLE",
  "UPDATE_ULTRASOUND_TYPE_VARIABLE",
  "DELETE_ULTRASOUND_TYPE_VARIABLE",
  "ULTRASOUND_UPDATE_CONCLUSION_RULE",
  "ULTRASOUND_UPDATE_RESULT_RULE",
  "ULTRASOUND_UPDATE_CPT_RULE",

  // Condition Detail
  "SORT_CONDITION_VARIABLES",
  "GET_CONDITION_TYPE",
  "SAVE_CONDITION_TYPE",
  "EDIT_CONDITION_TYPE",
  "DELETE_CONDITION_TYPE",
  "UPDATE_CONDITION_TYPE_ICD",
  "UPDATE_CPT_RULE",
  "UPDATE_ICD_RULE",
  "TEXT_TEMPLATE_CONDITION",
  "TEXT_TEMPLATE_ULTRASOUND",
  "UPDATE_PLAN_CONDITION",

  // User medias
  "UPLOAD_USER_MEDIA",
  "GET_ALL_USER_MEDIA",
  "REMOVE_USER_MEDIA",
  "UPDATE_USER_MEDIA",

  // Support Module.
  "GET_ALL_SUPPORT_MODULES",
  "GET_SUPPORT_MODULES",
  "CREATE_NEW_SUPPORT_MODULE",
  "UPDATE_SUPPORT_MODULE",
  "DELETE_SUPPORT_MODULE",
  "CLOSE_SUPPORT_MODULE",
  "UPDATE_SUPPORT_MODULE_HISTORY",

  // Appointment Board
  "GET_APPOINTMENT_BOARD_APPOINTMENTS",
  "CREATE_APPOINTMENT_BOARD_CLINICAL",
  "CREATE_APPOINTMENT_BOARD_PATIENT",

  // Sidebar
  "UPDATE_SIDEBAR_STATE",

  // Sidenav
  "UPDATE_SIDENAV_STATE",

  // Support
  "UPDATE_AUTHOR_READ",
  "UPDATE_SUPPORT_READ",

  // Active Staff
  "GET_ACTIVE_STAFF",

  // Date String
  "SET_OBL_DATE",
  "SET_CLINIC_DATE"
);

export default Types;

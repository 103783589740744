import { Icon } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrimaryProviderDetailPatient } from "src/services/api.services";
import { getLocal } from "../../../helpers/Local";
import {
  getProviderForPlan,
  get_plan_patient,
  removeProviderForPlan,
  savePlanReferralProvider,
  searchPcpNpe,
  setGlobalLoading,
  setGlobalToastr,
  setProcedureData,
} from "../../../store/actions";
import AutoCompleteSearch from "../AutoComplete/AutoCompleteSearch";
import { RedRequiredCircleSVG } from "../icons/SVGIcon";
let timer = null;

const ReferralComponent = ({
  procedure_id_row,
  handleContinue,
  nextSave,
  titleProps,
  display,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  currentPlanId,
  activeStatus,
  is_locked,
  setIsProviderAdding,
  setIsProviderEdit,
  setValueProviderEdit,
  valueProviderEdit,
  setPropsDataRefer,
  propsDataRefer,
  ...restProps
}) => {
  const pcpDataRef = useSelector((state) => state.newPatient.pcpDataRef);
  const plan_referral_provider = useSelector((state) => state.procedure.plan_referral_provider);
  const [dataPCPRef, setDataPCPRef] = useState([]);
  const dispatch = useDispatch();
  const [referralProviderName, setReferralProviderName] = useState("");
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const [textReason, setTextReason] = useState("");
  const [referralNpi, setReferralNpi] = useState("");

  const fetchFirst = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
  };

  useEffect(() => {
    fetchFirst();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  useEffect(() => {
    setDataPCPRef(pcpDataRef);
  }, [pcpDataRef]);

  useEffect(() => {
    if (plan_referral_provider && Object.keys(plan_referral_provider).length > 0) {
      setReferralProviderName(plan_referral_provider.provider_name);
      setTextReason(plan_referral_provider.reason);
    } else {
      setReferralProviderName(null);
      setTextReason(null);
    }
  }, [plan_referral_provider]);

  const callApiDetail = async () => {
    dispatch(removeProviderForPlan());
    if (currentPlanId) {
      await dispatch(setGlobalLoading(true));
      const getDetailSuccess = await dispatch(getProviderForPlan(currentPlanId));
      setReferralNpi(getDetailSuccess?.referral_npi);
      await dispatch(setGlobalLoading(false));
    } else {
    }
  };

  useEffect(() => {
    if (currentPlanId) {
      callApiDetail();
    }
  }, [currentPlanId]);

  useEffect(() => {
    if (!referralNpi && propsDataRefer?.referral) {
      setReferralNpi(propsDataRefer?.referral);
    }
    if (!referralProviderName && propsDataRefer?.referralName) {
      setReferralProviderName(propsDataRefer?.referralName);
    }
    if (!textReason && propsDataRefer?.reason) {
      setTextReason(propsDataRefer?.reason);
    }
  }, [propsDataRefer, textReason, referralNpi]);

  const setFirstFC = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const onHandleClick = () => {
    if (nextSave) {
      handleUpdate();
    }
  };

  const onSelectChoice = async (value, dataFull) => {
    let id = "";
    if (value) {
      dispatch(setGlobalLoading(true));
      const dataProvider = await getPrimaryProviderDetailPatient(dataFull?.npi || dataFull?.NPI);
      dispatch(setGlobalLoading(false));
      if (dataProvider) {
        setValueProviderEdit && setValueProviderEdit(dataProvider);
        const slpitValue = value.split(" - ");
        id = slpitValue[1] || "";
        onChangeData(id, dataProvider);
      } else {
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: "There is no provider",
            type: "failed",
          })
        );
      }
    }
  };

  const onChangeData = (referral_npi, dataFull) => {
    setReferralNpi(referral_npi);
    setPropsDataRefer &&
      setPropsDataRefer({
        ...propsDataRefer,
        referral: referral_npi,
        referralName: dataFull?.full_name || "",
      });
  };

  const fetchSearch = async (value) => {
    setGlobalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const getDetailSuccess = await dispatch(searchPcpNpe(1, value));
      if (getDetailSuccess) {
        setGlobalLoading(false);
      }
    }, 500);
  };

  const onUpdateReason = (e) => {
    setTextReason(e.target.value);
    setPropsDataRefer &&
      setPropsDataRefer({
        ...propsDataRefer,
        reason: e.target.value,
      });
  };

  const setProviderAddForm = () => {
    setIsProviderAdding && setIsProviderAdding(true);
  };

  const setProviderEditForm = (status, valueDetail) => {
    setIsProviderEdit && setIsProviderEdit(status);
  };

  const handleUpdate = async () => {
    dispatch(setGlobalLoading(true));
    const params = {
      referral_npi: referralNpi,
      reason: textReason,
      plan_id: currentPlanId,
    };
    const SaveSuccess = await dispatch(savePlanReferralProvider(currentPlanId, params));
    const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    await dispatch(setProcedureData("loadingSchedule", true));
    if (SaveSuccess && loadList) {
      setFirstFC(-1);
      dispatch(setGlobalLoading(false));
      nextSave(loadList);
    }
  };

  return (
    <>
      <div className="resourceContent">
        <React.Fragment key={`clinic-appointment-${0}`}>
          <div className="resourceInfo d-flex required-field ins_info">
            <AutoCompleteSearch
              options={dataPCPRef || []}
              value={referralProviderName}
              onClear={onSelectChoice}
              handlePopulate={onSelectChoice}
              handleSearch={(val) => fetchSearch(val)}
              title="referring"
              placeholder="Referral Provider"
              actionLabel="Search"
              keyLabel="provider_name_suffix_address"
              optionKey="id"
              backgroundWhite
              isModified
              disableSearchIcon={referralProviderName ? true : false}
              customStyle={referralProviderName ? true : false}
            />
            <p className="alert_message" style={{ marginTop: "14px" }}>
              <span>
                <RedRequiredCircleSVG /> Search using First Name, Last Name, State
              </span>
            </p>
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setProviderAddForm()}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
            {valueProviderEdit?.id && (
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 440,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setProviderEditForm(true, valueProviderEdit)}
              >
                <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            )}
          </div>

          <TextArea
            rows={4}
            className={textReason && textReason.length > 0 ? "" : "require_text_area"}
            placeholder="Reason"
            value={textReason}
            showCount
            onChange={onUpdateReason}
            style={{ borderRadius: "0", marginTop: "14px" }}
          />
        </React.Fragment>
      </div>
      {(activeStatus || (textReason && textReason.length > 0)) && (
        <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
          <button
            disabled={is_locked}
            className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
            onClick={() => {
              onHandleClick();
            }}
          >
            Continue
          </button>
        </div>
      )}
    </>
  );
};

export default ReferralComponent;

import {createReducer} from 'redux-create-reducer';

import AT from '../actionTypes';

const initialState = {
  reminders: null,
  resource: null,
  types: null,
  loadingReminders: false,
  searchForPending: '',
  loadingResource: false,
  errorMessage: '',
};

const GET_REMINDERS_SUCCEEDED = `${AT.GET_REMINDERS}_SUCCEEDED`;
const GET_FILTER_TYPES_SUCCEEDED = `${AT.GET_FILTER_TYPES}_SUCCEEDED`;
const SELECT_REMINDER_SUCCEEDED = `${AT.SELECT_REMINDER}_SUCCEEDED`;
const SET_REMINDER_DATA_SUCCEEDED = `${AT.SET_REMINDER_DATA}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [GET_FILTER_TYPES_SUCCEEDED](state, action) {
    const types = action?.payload;
    return Object.assign({}, state, {types});
  },
  [SET_REMINDER_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, {...action?.payload});
  },
  [GET_REMINDERS_SUCCEEDED](state, action) {
    const reminders = action?.payload;
    return Object.assign({}, state, {reminders, loadingReminders: false});
  },
  [SELECT_REMINDER_SUCCEEDED](state, action) {
    const resource = action?.payload?.data;
    if (resource && ['procedure_date']) {
      resource['procedure_date'] = action?.payload?.procedure_date;
    }
    return Object.assign({}, state, {resource, loadingResource: false});
  },
});

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { Beforeunload } from "react-beforeunload";
import "./index.css";
import "antd/dist/antd.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-alice-carousel/lib/alice-carousel.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Beforeunload
        onBeforeunload={(event) => {
          event.preventDefault();
        }}
      >
        <App />
      </Beforeunload>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <Beforeunload
        onBeforeunload={(event) => {
          event.preventDefault();
        }}
      >
        <App />
      </Beforeunload>
    </Provider>,
    rootElement
  );
}

serviceWorker.unregister();

import React, { useEffect, useState } from 'react';
import EditableText from '../../../../common/components/EditableText/LoginFormInput';

const InputIcdCodes = (props) => {
  const [icdCodes, setIcdCodes] = useState([]);
  const [allCases, setAllCases] = useState([]);
  const [codeInput, setCodeInput] = useState('');
  const [case1Input, setCase1Input] = useState('');
  const [case2Input, setCase2Input] = useState('');
  const [case3Input, setCase3Input] = useState('');
  const [isShowCase2Input, showCase2Input] = useState(false);
  const [isShowCase3Input, showCase3Input] = useState(false);

  useEffect(() => {
    setIcdCodes(props.icdCodes);
  }, [props.icdCodes]);
  useEffect(() => {
    const allCases_ = [];
    for (const question of props.questions) {
      for (const choice of question['choices']) {
        allCases_.push(question['question'] + '->' + choice);
      }
    }
    setAllCases(allCases_);
  }, [props.questions]);
  const addCodeInput = () => {
    if (codeInput === '' || case1Input == '') return;
    const icdCodes_ = [...icdCodes];
    icdCodes_.push({
      icd_code: codeInput,
      case1: case1Input,
      case2: case2Input,
      case3: case3Input,
    });
    setIcdCodes(icdCodes_);
    props.onChangeIcdCodes(icdCodes_);
    setCodeInput('');
    setCase1Input('');
    setCase2Input('');
    setCase3Input('');
  };
  const removeRow = (index) => {
    const icdCodes_ = [...icdCodes];
    icdCodes_.splice(index, 1);
    setIcdCodes(icdCodes_);
  };

  return (
    <div style={{width: '100%'}}>
      <div className="tableList">
        {icdCodes.map((code_, index) => (
          <div className={`tableItem yellow`} key={`icd_codes-${index}`}>
            <div className="td with-icon" style={{width: '30%'}}>
              <p>{code_['icd_code']}</p>
            </div>
            <div className="td with-icon" style={{width: '60%'}}>
              <p>
                {code_['case1']}
                {code_['case2'] != '' && ', ' + code_['case2']}
                {code_['case3'] != '' && ', ' + code_['case3']}
              </p>
            </div>
            <div
              className="td with-icon"
              style={{width: '10%', justifyContent: 'flex-end'}}
            >
              <button
                onClick={() => removeRow(index)}
                style={{
                  backgroundColor: '#e7505a ',
                  color: 'white',
                  fontSize: '0.875rem',
                  height: '25px',
                  border: 'medium none',
                  textAlign: 'center',
                  width: '25px',
                  cursor: 'pointer',
                }}
              >
                -
              </button>
            </div>
          </div>
        ))}
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: '0.5rem',
          marginTop: '1rem',
        }}
      >
        <div style={{flex: 1, paddingRight: '0.5rem'}}>
          <EditableText
            label={'ICD Code'}
            value={codeInput}
            handleChange={setCodeInput}
          />
        </div>
        <div style={{flex: 1, display: 'flex', paddingRight: '0.5rem'}}>
          <EditableText
            label={'Instance 1'}
            value={case1Input}
            handleChange={setCase1Input}
            type={'check'}
            options={allCases.map((case_) => ({value: case_, label: case_}))}
            optionKey={'value'}
            valueKey={'label'}
          />
        </div>
        <button
          onClick={() => showCase2Input(true)}
          className="small-blue-btn medium"
          style={{flex: 0}}
        >
          +
        </button>
      </div>
      {isShowCase2Input && (
        <div style={{width: '100%', display: 'flex', marginBottom: '5px'}}>
          <div style={{flex: 1, paddingRight: '0.5rem', display: 'flex'}}>
            <EditableText
              label={'Instance 2'}
              value={case2Input}
              handleChange={setCase2Input}
              type={'check'}
              options={allCases.map((case_) => ({
                value: case_,
                label: case_,
              }))}
              optionKey={'value'}
              valueKey={'label'}
            />
          </div>
          <button
            onClick={() => showCase3Input(true)}
            className="small-blue-btn medium"
            style={{flex: 0}}
          >
            +
          </button>
        </div>
      )}
      {isShowCase3Input && (
        <div style={{width: '100%', display: 'flex', marginBottom: '5px'}}>
          <div style={{width: '100%'}}>
            <EditableText
              label={'Instance 3'}
              value={case3Input}
              handleChange={setCase3Input}
              type={'check'}
              options={allCases.map((case_) => ({
                value: case_,
                label: case_,
              }))}
              optionKey={'value'}
              valueKey={'label'}
            />
          </div>
        </div>
      )}

      <div
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '10px',
        }}
      >
        <button onClick={addCodeInput} className="common-btn">
          Add
        </button>
      </div>
    </div>
  );
};

export default InputIcdCodes;

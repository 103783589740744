import { Drawer, Icon } from "antd";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal, setLocal } from "../../../helpers/Local";
import { getClinicWorkflowAppointmentsLocalApi } from "../../../services/api.services";
import {
  checkApointment,
  getClinicWorkflowAppointments,
  getExistingFollowUp,
  getFollowUp,
  getProcedureCallAttempt,
  getSingleProcedure,
  getTimeSlots,
  removeDataPatient,
  rescheduleProcedure,
  selectPatient,
  selectProcedure,
  setGlobalLoading,
  setGlobalToastr,
  setProcedureData,
  procedure_cancel,
  chooseSelectedPatientResource,
  setDataPatient,
  setActionActive,
  patient_insurances_id_insurances,
  get_patient_notes,
  remove_patient_on_schedules,
  resetStateResource,
  choosePatientOnSchedules,
  setPatientSnapshotData,
  clinicProviderReviewLoadInit,
  getLock,
  patientExaminationLoadInit,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  getDetailUltrasounds,
  get_cpt_code_review,
  angioPatientSnapshotLoadInit,
  load_procedurals,
  setFirstLoadingActive,
} from "../../../store/actions";
import CheckIdAngio from "../../../helpers/CheckIdAngio";
import DataTable from "../../components/DataTable/DataTable";
import { RedCloseSVG, RedRequiredCircleSVG } from "../../components/icons/SVGIcon";
import ProcedureScheduling from "./ProcedureScheduling";
import UltrasoundScheduling from "./UltrasoundScheduling";
import ClinicScheduling from "./ClinicScheduling";
import EditableMaterialText from "src/common/components/EditableText/MaterialInput";
import ScrollWrapper from "src/common/components/ScrollBar";
import { useHistory } from "react-router-dom";

const Appointment = ({ data, title, display, sidebarIcon }) => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.procedure.patientProcedures);
  const patient_id = getLocal("current_patient_id");

  const fetchAPI = async () => {
    setTableLoads([]);
    await dispatch(setGlobalLoading(true));
    await dispatch(
      getClinicWorkflowAppointments({
        patient_id: patient_id,
        paging: 1,
      })
    );
    await dispatch(setGlobalLoading(false));
  };

  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setRow] = useState(-1);
  const [isSidebar, setIsSidebar] = useState(false);
  const [resource, setResource] = useState(null);
  const [cancelledReason, setCancelledReason] = useState("");
  const [currentAppointmentId, setCurrentAppointmentId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchAPI();
  }, []);

  const sidebarRef = useRef();

  const handleSaveSuccess = () => {
    setRow(-1);
    setIsSidebar(false);
    fetchAPI();
  };

  const cancelledReasonsArr = [
    {
      id: 1,
      value: "Death",
    },
    {
      id: 2,
      value: "Changed Provider",
    },
    {
      id: 3,
      value: "Moved",
    },
    {
      id: 4,
      value: "Not Interested",
    },
    {
      id: 5,
      value: "Only Current Appointment",
    },
    {
      id: 6,
      value: "Other",
    },
  ];

  const handleCancelledReasonChange = async (value) => {
    setCancelledReason(value);
    await dispatch(setGlobalLoading(true));
    await dispatch(
      procedure_cancel(currentAppointmentId, {
        reason: value,
      })
    );
    setRow(-1);
    setIsSidebar(false);
    fetchAPI();
    setCurrentAppointmentId(null);
    setCancelledReason("");
    await dispatch(setGlobalLoading(false));
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        dispatch(setGlobalLoading(true));
        let listUpdate = null;
        listUpdate = await getClinicWorkflowAppointmentsLocalApi({
          patient_id: patient_id,
          paging: page + 1,
          // types: "clinic,ultrasound,angio",
          // appointment_status: "review",
          // paging: page + 1,
          // with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
        });

        if (listUpdate && listUpdate.length > 0) {
          setTableLoads([...tableLoad, ...listUpdate]);
          setPage(page + 1);
          setLoading(false);
          dispatch(setGlobalLoading(false));
        } else {
          for (let i = 0; i < 7; i++) {
            tableLoad.push({});
          }
          setTableLoads([...tableLoad]);
          setLoading(false);
          setOffLoad(false);
          dispatch(setGlobalLoading(false));
        }
      }
    }
  };

  useEffect(() => {
    if (appointments && Array.isArray(appointments)) {
      setTableLoads([...appointments]);
    }
    setLoading(false);
  }, [appointments]);

  const handleCallAPI = async (row, index) => {
    dispatch(setGlobalLoading(true));
    dispatch(setProcedureData("loadingResource", true));
    dispatch(selectPatient(tableLoad?.[index]["patient_id"]));
    dispatch(selectProcedure(tableLoad?.[index]));
    dispatch(getSingleProcedure(tableLoad?.[index]?.id));
    dispatch(checkApointment(false));
    setLocal("procedure_id", tableLoad?.[index]?.id);
    const newOption = {
      prev_date: moment(tableLoad?.[index]?.appointment_date, "YYYY-MM-DD").format("MM/DD/YYYY"),
      existing_appointments: 1,
    };
    await dispatch(rescheduleProcedure(tableLoad?.[index]?.patient_id, tableLoad[index]?.id, { existing_appointments: 1 }));
    if (row?.type === "angio") {
      await dispatch(getFollowUp(tableLoad?.[index]?.patient_id, tableLoad?.[index]?.id, newOption, false, "true"));
    }
    await dispatch(getTimeSlots(tableLoad?.[index]?.patient_id, tableLoad?.[index]?.id, newOption));
    dispatch(getExistingFollowUp(tableLoad?.[index]?.id));
    dispatch(getProcedureCallAttempt(tableLoad?.[index]?.id));
    dispatch(setGlobalLoading(false));
    setIsSidebar(true);
  };

  const handleRow = (row, index) => {
    if (row?.type === "angio") {
      setRow(0);
    }
    if (row?.type === "ultrasound") {
      setRow(1);
    }
    if (row?.type === "clinic") {
      setRow(2);
    }
    setResource(tableLoad[index]);
    handleSidebar(row, index);
    setCurrentAppointmentId(row?.id);
    setCancelledReason(row?.cancelled_reason);
  };

  const handleSidebar = (row, index) => {
    // check status
    if (row?.status != "review" && row?.status != "billed" && row?.status != "cancelled") {
      // check ngay thang
      if (
        row?.type === "clinic" &&
        tableLoad.some(
          (r) =>
            r?.type == "ultrasound" &&
            moment(moment(r?.appointment_date, "YYYY-MM-DD").format("MM/DD/YYYY")).isSame(
              moment(row?.appointment_date, "YYYY-MM-DD").format("MM/DD/YYYY"),
              "day"
            )
        )
      ) {
        dispatch(
          setGlobalToastr({
            header: "Warning",
            message: "Unable to open this appointment because it's cancelled, or billed or not correct type",
            type: "failed",
          })
        );
        setIsSidebar(false);
      } else {
        handleCallAPI(row, index);
      }
    } else {
      dispatch(
        setGlobalToastr({
          header: "Warning",
          message: "Unable to open this appointment because it's cancelled, or billed or not correct type",
          type: "failed",
        })
      );
      setIsSidebar(false);
    }
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toTitleCase = (s) => {
    if (typeof s === "string" && s.length > 0) {
      const words = s.split(" ");
      if (Array.isArray(words) && words.length > 0) {
        if (words.length === 1) {
          const word = words[0];
          const matches = word.charAt(0).match(/\w+/i);
          const lines = word.split("\n");
          if (Array.isArray(lines) && lines.length > 1) {
            return lines
              .map((line) => {
                return toTitleCase(line);
              })
              .join("\n");
          } else if (Array.isArray(matches)) {
            return word
              .split("")
              .map((c, i) => {
                if (i === 0) {
                  return c.toUpperCase();
                }
                return c.toLowerCase();
              })
              .join("");
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)));
          }
        } else {
          return words.map((word) => toTitleCase(word)).join(" ");
        }
      }
    }
    return "";
  };

  const getAppointmentType = (row) => {
    if (row && row.procedure_name) {
      if (row.codes && row.codes.length > 1) {
        return `${row.procedure_name} (${row?.codes})`;
      } else return `${row.procedure_name}`;
    } else {
      if (row.codes && row.codes.length > 1) {
        return toTitleCase(row.type) + ` (` + row.codes + `)`;
      } else return toTitleCase(row.type);
    }
  };

  const getRowChargeDate = (row) => {
    if (row && row.charge_date) {
      return moment(row.charge_date).format("MM/DD/YYYY hh:mm A");
    } else {
      if (row.appointment_status == "billed") {
        return "Billed";
      }
    }
  };

  const handleClick = async (row, index) => {
    dispatch(setGlobalLoading(true));
    const result = await dispatch(chooseSelectedPatientResource(row?.patient_id));
    if (result) {
      await dispatch(setDataPatient(result));
      await dispatch(
        setActionActive({
          status: true,
          value: row?.appointment_date,
        })
      );

      dispatch(setGlobalLoading(false));
      if (row?.type === "clinic") {
        await dispatch(removeDataPatient());
        await dispatch(resetStateResource());
        await dispatch(remove_patient_on_schedules());
        const appointment_id = row?.id;
        const patient_id = row?.patient_id;
        setLocal("current_appointment_id", appointment_id);
        setLocal("current_patient_id", patient_id);
        if (patient_id) {
          const result = await dispatch(choosePatientOnSchedules(patient_id));
          if (result) {
            await dispatch(patient_insurances_id_insurances(patient_id));
            dispatch(setDataPatient(result));
            await dispatch(get_patient_notes(patient_id));
            await dispatch(setPatientSnapshotData("resource", { ...result }));
          }
        }
        // go to review
        if (appointment_id) {
          // await dispatch(clinicProviderReviewLoadInit(appointment_id, 0));
          await dispatch(getLock(appointment_id));
        }
        history.push("/clinic-workflow/provider");
        dispatch(setFirstLoadingActive(true));
      } else if (row?.type === "ultrasound") {
        await dispatch(removeDataPatient());
        await dispatch(resetStateResource());
        await dispatch(remove_patient_on_schedules());
        const patient_id = row?.patient_id;
        const appointment_id = row?.id;
        const current_ultrasound_id = row?.appointment_type_id;
        setLocal("current_patient_id", patient_id);
        setLocal("appointment_ultrasound_id", appointment_id);
        setLocal("current_ultrasound_id", current_ultrasound_id);
        const result = await dispatch(choosePatientOnSchedules(patient_id));
        if (result) {
          dispatch(setDataPatient(result));
        }
        await dispatch(patientExaminationLoadInit(appointment_id));
        await dispatch(clinicProviderReviewLoadInit(appointment_id, 0));
        await dispatch(get_patient_notes(patient_id));
        await dispatch(get_ultrasound_finding_value(appointment_id));
        await dispatch(get_ultrasound_type(current_ultrasound_id));
        await dispatch(getDetailUltrasounds(current_ultrasound_id));
        history.push("/clinic-workflow/ultrasound");
        dispatch(setFirstLoadingActive(true));
      } else if (row?.type === "angio") {
        const patient_id = row?.patient_id;
        const appointment_id = row?.id;
        await setLocal("current_appointment_id", appointment_id);
        const result = await dispatch(choosePatientOnSchedules(patient_id));
        if (result) {
          await dispatch(setDataPatient(result));
          await dispatch(setPatientSnapshotData("resource", { ...result }));
        }
        await dispatch(getLock(appointment_id));
        const appointment_type = await await dispatch(get_cpt_code_review(appointment_id));
        if (appointment_type?.appointment_type_id && CheckIdAngio(appointment_type?.appointment_type_id) == true) {
          await setLocal("checkActiveAppointmentType", "active");
        } else {
          await setLocal("checkActiveAppointmentType", "nonActive");
        }
        history.push("/obl-workflow/obl");
        dispatch(setFirstLoadingActive(true));
        // go to review
        // await dispatch(angioPatientSnapshotLoadInit(appointment_id));
        // await dispatch(load_procedurals(appointment_id));
      }
    } else {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Patient not found!",
          type: "warning",
        })
      );
    }
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer no-padding-right">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                isBorderThick={true}
                title="appointment_table"
                tableWidth={1060}
                labels={["Date and Time", "Appointment Status", "Appointment Type (CPT Code)", "ICD Code", "Location", "Bill Date"]}
                widths={["15%", "17%", "15%", "18%", "15%", "20%"]}
                columns={[
                  { sortable: false, key: "date_time" },
                  { sortable: false, key: "appointment_status" },
                  { sortable: false, key: "cpt_code" },
                  { sortable: false, key: "icd_code" },
                  { sortable: false, key: "location" },
                  { sortable: false, key: "bill_date" },
                ]}
                sorts={["", "", "", "", ""]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row?.id,
                  date_time: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "rgb(15, 98, 254)",
                      }}
                    >
                      {row.appointment_status === "billed" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Billed
                          </span>
                        </div>
                      )}
                      {((row.appointment_status === "scheduled" && row.is_auto_generated !== 1) || row.appointment_status === "review") && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Scheduled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "scheduled" && row.is_auto_generated === 1 && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#EDD15C" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Auto Scheduled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "cancelled" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="question-circle" theme="twoTone" twoToneColor="#6929C4" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Cancelled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "checked-in" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Checked In
                          </span>
                        </div>
                      )}
                      {(row.appointment_status === "no_show" || row.appointment_status === "no-answer") && (
                        <div className="has-tooltip" style={{ height: "14px" }}>
                          {" "}
                          <RedCloseSVG />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            No Show
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "confirmed" && (
                        <div className="has-tooltip">
                          {" "}
                          <RedRequiredCircleSVG />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Confirmed
                          </span>
                        </div>
                      )}
                      {(row.appointment_status === "initial" || !row.appointment_status) && <span style={{ minWidth: "26px" }}></span>}
                      {row.appointment_status === "submitting" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="clock-circle" theme="twoTone" twoToneColor="#EDD15C" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Submitting
                          </span>
                        </div>
                      )}
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.isPropagationStopped();
                          handleClick(row);
                        }}
                        style={{
                          cursor: "pointer",
                          color: "rgb(15, 98, 254)",
                          fontWeight: "bold",
                          paddingLeft: "0.75rem",
                        }}
                      >
                        {row.appointment_date && moment(row.appointment_date).format("MM/DD/YYYY hh:mm A")}
                      </span>
                    </div>
                  ),
                  appointment_status: toTitleCase(row.appointment_status),
                  cpt_code: getAppointmentType(row),
                  icd_code: row.icd_code,
                  location: row.location && row.location?.value,
                  bill_date: getRowChargeDate(row),
                  // su cho check click
                  type: row?.type || null,
                  appointment_date: row?.appointment_date || null,
                  status: row.appointment_status || null,
                }))}
                fetchList={() => {}}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={0}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title={resource?.procedure_name ? `${resource?.procedure_name}` : " "}
        placement="right"
        closable
        onClose={handleCloseSidebar}
        visible={isSidebar}
        destroyOnClose
        key="right"
      >
        <div className="resourceContainer new">
          <ScrollWrapper>
            {/* angio */}
            {activeIndex === 0 ? <ProcedureScheduling handleSaveSuccess={handleSaveSuccess} /> : null}
            {/* ultrasound */}
            {activeIndex === 1 ? <UltrasoundScheduling handleSaveSuccess={handleSaveSuccess} refreshUltrasound={false} /> : null}
            {/* clinic */}
            {activeIndex === 2 ? <ClinicScheduling handleSaveSuccess={handleSaveSuccess} /> : null}

            <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
              <EditableMaterialText
                label={"Cancelled Reason"}
                value={cancelledReason}
                handleChange={(value) => {
                  handleCancelledReasonChange(value);
                }}
                type={"check"}
                optionKey={"value"}
                valueKey={"label"}
                required={true}
                enableErrorText={true}
                options={cancelledReasonsArr || []}
                isError={cancelledReason ? false : true}
                helperText={"Select reason for cancelled"}
              />
            </div>
          </ScrollWrapper>
        </div>
      </Drawer>
    </div>
  );
};

export default Appointment;

import { Tabs } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PDFView from "../../../common/../common/components/PDFView";
import TopBar from "../../../common/components/SubTopBar";
import { emptyScanLogs, setGlobalLoading } from "../../../store/actions";
import { StaticResource } from "../constant";
import ScanningSidebar from "./ScanningSidebar";

const { TabPane } = Tabs;

export const ScanningModule = (props) => {
  const detail_scan_logs = useSelector((state) => state.patientRecords.detail_scan_logs);
  const loadingDetailLogs = useSelector((state) => state.patientRecords.loadingDetailLogs);
  const loadingScanLogs = useSelector((state) => state.patientRecords.loadingScanLogs);
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeTab, setActiveTab] = useState("incoming-scan");
  const [changedTab, setChangedTab] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
    return () => {
      dispatch(emptyScanLogs());
    };
  }, []);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleTab = (activeKey) => {
    setActiveTab(activeKey);
    dispatch(emptyScanLogs());
  };

  useEffect(() => {
    if (loadingDetailLogs) {
      setChangedTab(false);
    }
  }, [loadingDetailLogs]);
  useEffect(() => {
    dispatch(setGlobalLoading(loadingDetailLogs || loadingScanLogs));
  }, [loadingDetailLogs, loadingScanLogs]);

  const enablePDFViewer = useMemo(() => {
    if (loadingDetailLogs || (!loadingDetailLogs && changedTab)) {
      return false;
    }
    if (detail_scan_logs && detail_scan_logs.media_url) {
      return true;
    }
    return false;
  }, [loadingDetailLogs, changedTab, detail_scan_logs]);

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Scanning Module" subStatus="" />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <Tabs className="patientChart" type="card" onChange={handleTab}>
              <TabPane tab="Incoming Scan" key="incoming-scan">
                <PDFView url={detail_scan_logs && detail_scan_logs.media_url} />
              </TabPane>
            </Tabs>
          </div>
          <ScanningSidebar
            ref={sidebarRef}
            resource={StaticResource}
            activeTab={activeTab}
            isShow={isSidebar}
            handleCloseSidebar={handleCloseSidebar}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanningModule;

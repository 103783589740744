import {createReducer} from 'redux-create-reducer';
import AT from '../actionTypes';

const initialState = {
  procedureHistory: [],
  providers: [],
  ultrasoundsCondition: [],
  variable: [],
  supportLocations: [],
  captures: [],
  document: null,
  ultrasounds: [],
  cptCodes: [],
  procedure_type: [],
  loading_procedure_type: false,
  // condition
  loading_condition_type: false,
  condition_type: [],
  conditionVariable: [],
  conditions: [],
  icdCodes: [],
  icdRange: '',
  plans: [],
  // angio_type
  loading_angio_type: false,
  angio_list: [],
  detail_angio_type: {},
  ultrasounds_angio_type: [],
  angio_type: [],
  angioVariable: [],
  cpt_angio_Codes: [],
  providers_angio: [],
  icdCodesAngio: [],
  cptCodesAngio: [],
  angio_type_select_search: [],
  angio_choice: [],
  // angio_type_variable
  // new_patient
  new_patients: [],
  // established patient
  established_patients: [],
  // meaningful_use
  meaningful_uses: [],
  loading_angio_type_variable: false,
  angio_type_variable_list: [],
  detail_angio_type_variable: {},
  // me_variable
  get_me_select_list: [],
  loading_get_me_variable: false,
  // me_variable_rule
  get_me_rules_new_patients: [],
  get_me_rules_established_patients: {},
  get_me_rules_meaningful_uses: {},
  detail_get_me_rule: {},
  // prior_authorization_settings
  insurances: [],
  prior_authorization_settings: [],
  carriers: [],
  // ultrasound
  loading_ultrasound_type: false,
  untrasound_list: [],
  detail_untrasound_list_type: {},
  // ultrasounds_angio_type: [],
  ultrasound_type: [],
  ultrasoundVariable: [],
  cpt_utrasound_Codes: [],
  // clinic
  list_clinic_type: [],
  detail_clinic_type: {},
  loading_clinic_type: false,
  // clinic variable
  list_clinic_type_variable: [],
  detail_clinic_type_variable: {},
  loading_clinic_type_variable: false,
  //
  ultrasounds_clinic_type: [],
  clinicVariable: [],
  cpt_clinic_Codes: [],
  providers_clinic: [],
  icdCodesClinic: [],
  cptCodesClinic: [],
  clinic_type_select_search: [],
  clinic_choice: [],
};
// clinic variable
const LIST_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED = `${AT.LIST_CLINIC_PROCEDURE_TYPE_VARIABLE}_STARTED`;
const LIST_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED = `${AT.LIST_CLINIC_PROCEDURE_TYPE_VARIABLE}_SUCCEEDED`;
const SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED = `${AT.SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE}_STARTED`;
const SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED = `${AT.SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE}_SUCCEEDED`;
const DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED = `${AT.DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE}_STARTED`;
const DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED = `${AT.DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE}_SUCCEEDED`;
const EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED = `${AT.EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE}_STARTED`;
const EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED = `${AT.EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE}_SUCCEEDED`;
const DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED = `${AT.DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE}_STARTED`;
const DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED = `${AT.DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE}_SUCCEEDED`;
// clinic
const LIST_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.LIST_CLINIC_PROCEDURE_TYPE}_STARTED`;
const LIST_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.LIST_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const SAVE_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.SAVE_CLINIC_PROCEDURE_TYPE}_STARTED`;
const SAVE_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.SAVE_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const DETAIL_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.DETAIL_CLINIC_PROCEDURE_TYPE}_STARTED`;
const DETAIL_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.DETAIL_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const EDIT_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.EDIT_CLINIC_PROCEDURE_TYPE}_STARTED`;
const EDIT_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.EDIT_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const DELETE_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.DELETE_CLINIC_PROCEDURE_TYPE}_STARTED`;
const DELETE_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.DELETE_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const UPDATE_TEXT_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.UPDATE_TEXT_CLINIC_PROCEDURE_TYPE}_STARTED`;
const UPDATE_TEXT_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.UPDATE_TEXT_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const UPDATE_CPT_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.UPDATE_CPT_CLINIC_PROCEDURE_TYPE}_STARTED`;
const UPDATE_CPT_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.UPDATE_CPT_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
const UPDATE_ICD_CLINIC_PROCEDURE_TYPE_STARTED = `${AT.UPDATE_ICD_CLINIC_PROCEDURE_TYPE}_STARTED`;
const UPDATE_ICD_CLINIC_PROCEDURE_TYPE_SUCCEEDED = `${AT.UPDATE_ICD_CLINIC_PROCEDURE_TYPE}_SUCCEEDED`;
// utrasound
const GET_ULTRASOUNDS_STARTED = `${AT.GET_ULTRASOUNDS}_STARTED`;
const GET_ULTRASOUNDS_SUCCEEDED = `${AT.GET_ULTRASOUNDS}_SUCCEEDED`;
const GET_DETAIL_ULTRASOUNDS_STARTED = `${AT.GET_DETAIL_ULTRASOUNDS}_STARTED`;
const GET_DETAIL_ULTRASOUNDS_SUCCEEDED = `${AT.GET_DETAIL_ULTRASOUNDS}_SUCCEEDED`;
const TEXT_TEMPLATE_ULTRASOUND_STARTED = `${AT.TEXT_TEMPLATE_ULTRASOUND}_STARTED`;
const TEXT_TEMPLATE_ULTRASOUND_SUCCESS = `${AT.TEXT_TEMPLATE_ULTRASOUND}_SUCCEEDED`;
// prior_authorization_settings
const SAVE_PRIOR_AUTHORIZATION_SETTINGS_STARTED = `${AT.SAVE_PRIOR_AUTHORIZATION_SETTINGS}_STARTED`;
const SAVE_PRIOR_AUTHORIZATION_SETTINGS_SUCCESS = `${AT.SAVE_PRIOR_AUTHORIZATION_SETTINGS}_SUCCEEDED`;
const GET_PRIOR_AUTHORIZATION_SETTINGS_STARTED = `${AT.GET_PRIOR_AUTHORIZATION_SETTINGS}_STARTED`;
const GET_PRIOR_AUTHORIZATION_SETTINGS_SUCCESS = `${AT.GET_PRIOR_AUTHORIZATION_SETTINGS}_SUCCEEDED`;
// me_variable
const GET_ME_VARIABLE_SELECT_STARTED = `${AT.GET_ME_VARIABLE_SELECT}_STARTED`;
const GET_ME_VARIABLE_SELECT_SUCCESS = `${AT.GET_ME_VARIABLE_SELECT}_SUCCEEDED`;
// me_variable_rule
const RESET_NEW_PATIENT_SUCCESS = `${AT.RESET_NEW_PATIENT}_SUCCEEDED`;
const GET_ME_VARIABLE_RULE_STARTED = `${AT.GET_ME_VARIABLE_RULE}_STARTED`;
const GET_ME_VARIABLE_RULE_SUCCESS = `${AT.GET_ME_VARIABLE_RULE}_SUCCEEDED`;
const SAVE_ME_VARIABLE_RULE_STARTED = `${AT.SAVE_ME_VARIABLE_RULE}_STARTED`;
const SAVE_ME_VARIABLE_RULE_SUCCESS = `${AT.SAVE_ME_VARIABLE_RULE}_SUCCEEDED`;
const DETAIL_ME_VARIABLE_RULE_STARTED = `${AT.DETAIL_ME_VARIABLE_RULE}_STARTED`;
const DETAIL_ME_VARIABLE_RULE_SUCCESS = `${AT.DETAIL_ME_VARIABLE_RULE}_SUCCEEDED`;
const DELETE_ME_VARIABLE_RULE_STARTED = `${AT.DELETE_ME_VARIABLE_RULE}_STARTED`;
const DELETE_ME_VARIABLE_RULE_SUCCESS = `${AT.DELETE_ME_VARIABLE_RULE}_SUCCEEDED`;
const EDIT_ME_VARIABLE_RULE_STARTED = `${AT.EDIT_ME_VARIABLE_RULE}_STARTED`;
const EDIT_ME_VARIABLE_RULE_SUCCESS = `${AT.EDIT_ME_VARIABLE_RULE}_SUCCEEDED`;
// procedures

const SORT_PROCEDURE_VARIABLES_STARTED = `${AT.SORT_PROCEDURE_VARIABLES}_STARTED`;
const SORT_PROCEDURE_VARIABLES_SUCCESS = `${AT.SORT_PROCEDURE_VARIABLES}_SUCCEEDED`;

const GET_PROCEDURE_TYPE_STARTED = `${AT.GET_PROCEDURE_TYPE}_STARTED`;
const GET_PROCEDURE_TYPE_SUCCESS = `${AT.GET_PROCEDURE_TYPE}_SUCCEEDED`;

const SAVE_PROCEDURE_TYPE_STARTED = `${AT.SAVE_PROCEDURE_TYPE}_STARTED`;
const SAVE_PROCEDURE_TYPE_SUCCESS = `${AT.SAVE_PROCEDURE_TYPE}_SUCCEEDED`;

const DELETE_PROCEDURE_TYPE_STARTED = `${AT.DELETE_PROCEDURE_TYPE}_STARTED`;
const DELETE_PROCEDURE_TYPE_SUCCESS = `${AT.DELETE_PROCEDURE_TYPE}_SUCCEEDED`;

const UPDATE_PROCEDURE_TYPE_CPT_STARTED = `${AT.UPDATE_PROCEDURE_TYPE_CPT}_STARTED`;
const UPDATE_PROCEDURE_TYPE_CPT_SUCCESS = `${AT.UPDATE_PROCEDURE_TYPE_CPT}_SUCCEEDED`;

const UPDATE_CPT_RULE_STARTED = `${AT.UPDATE_CPT_RULE}_STARTED`;
const UPDATE_CPT_RULE_SUCCESS = `${AT.UPDATE_CPT_RULE}_SUCCEEDED`;
const REFREST_STATE_TAB_SUCCESS = `${AT.REFREST_STATE_TAB}_SUCCEEDED`;

const TEXT_TEMPLATE_PROCEDURE_STARTED = `${AT.TEXT_TEMPLATE_PROCEDURE}_STARTED`;
const TEXT_TEMPLATE_PROCEDURE_SUCCESS = `${AT.TEXT_TEMPLATE_PROCEDURE}_SUCCEEDED`;

// condition

const SORT_CONDITION_VARIABLES_STARTED = `${AT.SORT_CONDITION_VARIABLES}_STARTED`;
const SORT_CONDITION_VARIABLES_SUCCESS = `${AT.SORT_CONDITION_VARIABLES}_SUCCEEDED`;

const GET_CONDITION_TYPE_STARTED = `${AT.GET_CONDITION_TYPE}_STARTED`;
const GET_CONDITION_TYPE_SUCCESS = `${AT.GET_CONDITION_TYPE}_SUCCEEDED`;

const SAVE_CONDITION_TYPE_STARTED = `${AT.SAVE_CONDITION_TYPE}_STARTED`;
const SAVE_CONDITION_TYPE_SUCCESS = `${AT.SAVE_CONDITION_TYPE}_SUCCEEDED`;

const DELETE_CONDITION_TYPE_STARTED = `${AT.DELETE_CONDITION_TYPE}_STARTED`;
const DELETE_CONDITION_TYPE_SUCCESS = `${AT.DELETE_CONDITION_TYPE}_SUCCEEDED`;

const UPDATE_CONDITION_TYPE_ICD_STARTED = `${AT.UPDATE_CONDITION_TYPE_ICD}_STARTED`;
const UPDATE_CONDITION_TYPE_ICD_SUCCESS = `${AT.UPDATE_CONDITION_TYPE_ICD}_SUCCEEDED`;

const UPDATE_ICD_RULE_STARTED = `${AT.UPDATE_ICD_RULE}_STARTED`;
const UPDATE_ICD_RULE_SUCCESS = `${AT.UPDATE_ICD_RULE}_SUCCEEDED`;

const TEXT_TEMPLATE_CONDITION_STARTED = `${AT.TEXT_TEMPLATE_CONDITION}_STARTED`;
const TEXT_TEMPLATE_CONDITION_SUCCESS = `${AT.TEXT_TEMPLATE_CONDITION}_SUCCEEDED`;
const REMOVE_PARAMS_CHART_SUCCEEDED = `${AT.REMOVE_PARAMS_CHART}_SUCCEEDED`;

const UPDATE_PLAN_CONDITION_STARTED = `${AT.UPDATE_PLAN_CONDITION}_STARTED`;
const UPDATE_PLAN_CONDITION_SUCCESS = `${AT.UPDATE_PLAN_CONDITION}_SUCCEEDED`;
// angio_type_choice
const ANGIO_CONDITION_TYPE_STARTED = `${AT.ANGIO_CONDITION_TYPE}_STARTED`;
const ANGIO_CONDITION_TYPE_SUCCESS = `${AT.ANGIO_CONDITION_TYPE}_SUCCEEDED`;
const ANGIO_CONDITION_TYPE_CHOICE_STARTED = `${AT.ANGIO_CONDITION_TYPE_CHOICE}_STARTED`;
const ANGIO_CONDITION_TYPE_CHOICE_SUCCESS = `${AT.ANGIO_CONDITION_TYPE_CHOICE}_SUCCEEDED`;

const ANGIO_CONDITION_TYPE_ICD_STARTED = `${AT.ANGIO_CONDITION_TYPE_ICD}_STARTED`;
const ANGIO_CONDITION_TYPE_ICD_SUCCESS = `${AT.ANGIO_CONDITION_TYPE_ICD}_SUCCEEDED`;
const ANGIO_CONDITION_TYPE_CPT_STARTED = `${AT.ANGIO_CONDITION_TYPE_CPT}_STARTED`;
const ANGIO_CONDITION_TYPE_CPT_SUCCESS = `${AT.ANGIO_CONDITION_TYPE_CPT}_SUCCEEDED`;

// angio_type

const GET_ANGIO_TYPE_STARTED = `${AT.GET_ANGIO_TYPE}_STARTED`;
const GET_ANGIO_TYPE_SUCCESS = `${AT.GET_ANGIO_TYPE}_SUCCEEDED`;
const SAVE_ANGIO_TYPE_STARTED = `${AT.SAVE_ANGIO_TYPE}_STARTED`;
const SAVE_ANGIO_TYPE_SUCCESS = `${AT.SAVE_ANGIO_TYPE}_SUCCEEDED`;
const DETAIL_ANGIO_TYPE_STARTED = `${AT.DETAIL_ANGIO_TYPE}_STARTED`;
const DETAIL_ANGIO_TYPE_SUCCESS = `${AT.DETAIL_ANGIO_TYPE}_SUCCEEDED`;
const EDIT_ANGIO_TYPE_STARTED = `${AT.EDIT_ANGIO_TYPE}_STARTED`;
const EDIT_ANGIO_TYPE_SUCCESS = `${AT.EDIT_ANGIO_TYPE}_SUCCEEDED`;
const DELETE_ANGIO_TYPE_STARTED = `${AT.DELETE_ANGIO_TYPE}_STARTED`;
const DELETE_ANGIO_TYPE_SUCCESS = `${AT.DELETE_ANGIO_TYPE}_SUCCEEDED`;
const UPDATE_ANGIO_TYPE_TEMPLATE_STARTED = `${AT.UPDATE_ANGIO_TYPE_TEMPLATE}_STARTED`;
const UPDATE_ANGIO_TYPE_TEMPLATE_SUCCESS = `${AT.UPDATE_ANGIO_TYPE_TEMPLATE}_SUCCEEDED`;

// angio_type_variable

const GET_ANGIO_TYPE_VARIABLE_STARTED = `${AT.GET_ANGIO_TYPE_VARIABLE}_STARTED`;
const GET_ANGIO_TYPE_VARIABLE_SUCCESS = `${AT.GET_ANGIO_TYPE_VARIABLE}_SUCCEEDED`;
const SAVE_ANGIO_TYPE_VARIABLE_STARTED = `${AT.SAVE_ANGIO_TYPE_VARIABLE}_STARTED`;
const SAVE_ANGIO_TYPE_VARIABLE_SUCCESS = `${AT.SAVE_ANGIO_TYPE_VARIABLE}_SUCCEEDED`;
const DETAIL_ANGIO_TYPE_VARIABLE_STARTED = `${AT.DETAIL_ANGIO_TYPE_VARIABLE}_STARTED`;
const DETAIL_ANGIO_TYPE_VARIABLE_SUCCESS = `${AT.DETAIL_ANGIO_TYPE_VARIABLE}_SUCCEEDED`;
const EDIT_ANGIO_TYPE_VARIABLE_STARTED = `${AT.EDIT_ANGIO_TYPE_VARIABLE}_STARTED`;
const EDIT_ANGIO_TYPE_VARIABLE_SUCCESS = `${AT.EDIT_ANGIO_TYPE_VARIABLE}_SUCCEEDED`;
const DELETE_ANGIO_TYPE_VARIABLE_STARTED = `${AT.DELETE_ANGIO_TYPE_VARIABLE}_STARTED`;
const DELETE_ANGIO_TYPE_VARIABLE_SUCCESS = `${AT.DELETE_ANGIO_TYPE_VARIABLE}_SUCCEEDED`;

// ultrasound type

const GET_ULTRASOUND_TYPE_VARIABLE_STARTED = `${AT.GET_ULTRASOUND_TYPE_VARIABLE}_STARTED`;
const GET_ULTRASOUND_TYPE_VARIABLE_SUCCESS = `${AT.GET_ULTRASOUND_TYPE_VARIABLE}_SUCCEEDED`;
const SAVE_ULTRASOUND_TYPE_VARIABLE_STARTED = `${AT.SAVE_ULTRASOUND_TYPE_VARIABLE}_STARTED`;
const SAVE_ULTRASOUND_TYPE_VARIABLE_SUCCESS = `${AT.SAVE_ULTRASOUND_TYPE_VARIABLE}_SUCCEEDED`;
const DETAIL_ULTRASOUND_TYPE_VARIABLE_STARTED = `${AT.DETAIL_ULTRASOUND_TYPE_VARIABLE}_STARTED`;
const DETAIL_ULTRASOUND_TYPE_VARIABLE_SUCCESS = `${AT.DETAIL_ULTRASOUND_TYPE_VARIABLE}_SUCCEEDED`;
const UPDATE_ULTRASOUND_TYPE_VARIABLE_STARTED = `${AT.UPDATE_ULTRASOUND_TYPE_VARIABLE}_STARTED`;
const UPDATE_ULTRASOUND_TYPE_VARIABLE_SUCCESS = `${AT.UPDATE_ULTRASOUND_TYPE_VARIABLE}_SUCCEEDED`;
const DELETE_ULTRASOUND_TYPE_VARIABLE_STARTED = `${AT.DELETE_ULTRASOUND_TYPE_VARIABLE}_STARTED`;
const DELETE_ULTRASOUND_TYPE_VARIABLE_SUCCESS = `${AT.DELETE_ULTRASOUND_TYPE_VARIABLE}_SUCCEEDED`;
//
const ULTRASOUND_UPDATE_CONCLUSION_RULE_STARTED = `${AT.ULTRASOUND_UPDATE_CONCLUSION_RULE}_STARTED`;
const ULTRASOUND_UPDATE_CONCLUSION_RULE_SUCCESS = `${AT.ULTRASOUND_UPDATE_CONCLUSION_RULE}_SUCCEEDED`;
const ULTRASOUND_UPDATE_RESULT_RULE_STARTED = `${AT.ULTRASOUND_UPDATE_RESULT_RULE}_STARTED`;
const ULTRASOUND_UPDATE_RESULT_RULE_SUCCESS = `${AT.ULTRASOUND_UPDATE_RESULT_RULE}_SUCCEEDED`;
const ULTRASOUND_UPDATE_CPT_RULE_STARTED = `${AT.ULTRASOUND_UPDATE_CPT_RULE}_STARTED`;
const ULTRASOUND_UPDATE_CPT_RULE_SUCCESS = `${AT.ULTRASOUND_UPDATE_CPT_RULE}_SUCCEEDED`;
const REMOVE_DETAIL_CLINIC_SUCCESS = `${AT.REMOVE_DETAIL_CLINIC}_SUCCEEDED`;
const REMOVE_STATE_DELETE_CHART_SUCCESS = `${AT.REMOVE_STATE_DELETE_CHART}_SUCCEEDED`;

export default createReducer(initialState, {
  [REMOVE_STATE_DELETE_CHART_SUCCESS](state, action) {
    return Object.assign({}, state, {
      procedure_type: [],
    });
  },
  // clinic variable
  [REMOVE_DETAIL_CLINIC_SUCCESS](state, action) {
    return Object.assign({}, state, {
      detail_clinic_type: {},
      detail_clinic_type_variable: {},
    });
  },
  [DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type_variable: true});
  },
  [DELETE_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type_variable: false,
    });
  },
  [EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type_variable: true});
  },
  [EDIT_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type_variable: false,
    });
  },
  [DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type_variable: true});
  },
  [DETAIL_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED](state, action) {
    const detail_clinic_type_variable = action?.payload;
    return Object.assign({}, state, {
      loading_clinic_type_variable: false,
      detail_clinic_type_variable,
    });
  },
  [LIST_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type_variable: true});
  },
  [LIST_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED](state, action) {
    const list_clinic_type_variable = action?.payload;
    return Object.assign({}, state, {
      loading_clinic_type_variable: false,
      list_clinic_type_variable,
    });
  },
  [SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type_variable: true});
  },
  [SAVE_CLINIC_PROCEDURE_TYPE_VARIABLE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type_variable: false,
    });
  },
  // clinic
  [UPDATE_ICD_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [UPDATE_ICD_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [UPDATE_CPT_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [UPDATE_CPT_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [UPDATE_TEXT_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [UPDATE_TEXT_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [DELETE_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [DELETE_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [EDIT_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [EDIT_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [DETAIL_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [DETAIL_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    const detail_clinic_type = action?.payload;
    return Object.assign({}, state, {
      loading_clinic_type: false,
      detail_clinic_type,
    });
  },
  [SAVE_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [SAVE_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_clinic_type: false,
    });
  },
  [LIST_CLINIC_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_clinic_type: true});
  },
  [LIST_CLINIC_PROCEDURE_TYPE_SUCCEEDED](state, action) {
    const list_clinic_type = action?.payload;
    return Object.assign({}, state, {
      loading_clinic_type: false,
      list_clinic_type,
    });
  },
  // search angio
  [ANGIO_CONDITION_TYPE_CPT_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [ANGIO_CONDITION_TYPE_CPT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_angio_type_variable: false,
    });
  },
  [ANGIO_CONDITION_TYPE_ICD_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [ANGIO_CONDITION_TYPE_ICD_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_angio_type_variable: false,
    });
  },
  [ANGIO_CONDITION_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [ANGIO_CONDITION_TYPE_SUCCESS](state, action) {
    const angio_type_select_search = action?.payload;
    return Object.assign({}, state, {
      loading_angio_type_variable: false,
      angio_type_select_search,
    });
  },
  [ANGIO_CONDITION_TYPE_CHOICE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [ANGIO_CONDITION_TYPE_CHOICE_SUCCESS](state, action) {
    const angio_choice = action?.payload;
    return Object.assign({}, state, {
      loading_angio_type_variable: false,
      angio_choice,
    });
  },
  // ultrasound type
  [ULTRASOUND_UPDATE_CPT_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [ULTRASOUND_UPDATE_CPT_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [ULTRASOUND_UPDATE_RESULT_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [ULTRASOUND_UPDATE_RESULT_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [ULTRASOUND_UPDATE_CONCLUSION_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [ULTRASOUND_UPDATE_CONCLUSION_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [GET_ULTRASOUND_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [GET_ULTRASOUND_TYPE_VARIABLE_SUCCESS](state, action) {
    const ultrasound_type = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
      ultrasound_type,
    });
  },
  [DETAIL_ULTRASOUND_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [DETAIL_ULTRASOUND_TYPE_VARIABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [SAVE_ULTRASOUND_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [SAVE_ULTRASOUND_TYPE_VARIABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [UPDATE_ULTRASOUND_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [UPDATE_ULTRASOUND_TYPE_VARIABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [DELETE_ULTRASOUND_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [DELETE_ULTRASOUND_TYPE_VARIABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  // utrasound
  [TEXT_TEMPLATE_ULTRASOUND_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [TEXT_TEMPLATE_ULTRASOUND_SUCCESS](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
    });
  },
  [GET_DETAIL_ULTRASOUNDS_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [GET_DETAIL_ULTRASOUNDS_SUCCEEDED](state, action) {
    const detail_untrasound_list_type = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
      detail_untrasound_list_type,
    });
  },
  [GET_ULTRASOUNDS_STARTED](state, action) {
    return Object.assign({}, state, {loading_ultrasound_type: true});
  },
  [GET_ULTRASOUNDS_SUCCEEDED](state, action) {
    const untrasound_list = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_type: false,
      untrasound_list,
    });
  },
  // prior_authorization_settings
  [GET_PRIOR_AUTHORIZATION_SETTINGS_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [GET_PRIOR_AUTHORIZATION_SETTINGS_SUCCESS](state, action) {
    const prior_authorization_settings = action?.payload;
    return Object.assign({}, state, {
      prior_authorization_settings,
      loading_get_me_variable: false,
    });
  },
  [SAVE_PRIOR_AUTHORIZATION_SETTINGS_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [SAVE_PRIOR_AUTHORIZATION_SETTINGS_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: false});
  },
  // me_variable_rule
  [DETAIL_ME_VARIABLE_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [DETAIL_ME_VARIABLE_RULE_SUCCESS](state, action) {
    const detail_get_me_rule = action?.payload;
    return Object.assign({}, state, {
      detail_get_me_rule,
      loading_get_me_variable: false,
    });
  },
  [RESET_NEW_PATIENT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      new_patients: [],
    });
  },
  [EDIT_ME_VARIABLE_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [EDIT_ME_VARIABLE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: false});
  },
  [DELETE_ME_VARIABLE_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [DELETE_ME_VARIABLE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: false});
  },
  [SAVE_ME_VARIABLE_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [SAVE_ME_VARIABLE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: false});
  },
  [GET_ME_VARIABLE_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [GET_ME_VARIABLE_RULE_SUCCESS](state, action) {
    const get_me_rules = action?.payload;
    if (
      get_me_rules &&
      get_me_rules[0] &&
      get_me_rules[0].type == 'new_patient'
    ) {
      return Object.assign({}, state, {
        get_me_rules_new_patients: get_me_rules,
        loading_get_me_variable: false,
      });
    } else if (
      get_me_rules &&
      get_me_rules[0] &&
      get_me_rules[0].type == 'established_patient'
    ) {
      return Object.assign({}, state, {
        get_me_rules_established_patients: get_me_rules,
        loading_get_me_variable: false,
      });
    } else if (
      get_me_rules &&
      get_me_rules[0] &&
      get_me_rules[0].type == 'meaningful_use'
    ) {
      return Object.assign({}, state, {
        get_me_rules_meaningful_uses: get_me_rules,
        loading_get_me_variable: false,
      });
    } else {
      return Object.assign({}, state, {
        loading_get_me_variable: false,
      });
    }
  },
  // me_variable
  [GET_ME_VARIABLE_SELECT_STARTED](state, action) {
    return Object.assign({}, state, {loading_get_me_variable: true});
  },
  [GET_ME_VARIABLE_SELECT_SUCCESS](state, action) {
    const get_me_select_list = action?.payload;
    return Object.assign({}, state, {
      get_me_select_list,
      loading_get_me_variable: false,
    });
  },
  // angio_type_variable
  [DELETE_ANGIO_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [DELETE_ANGIO_TYPE_VARIABLE_SUCCESS](state, action) {
    const detail_angio_type_variable = action?.payload;
    return Object.assign({}, state, {loading_angio_type_variable: false});
  },
  [EDIT_ANGIO_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [EDIT_ANGIO_TYPE_VARIABLE_SUCCESS](state, action) {
    const detail_angio_type_variable = action?.payload;
    return Object.assign({}, state, {loading_angio_type_variable: false});
  },
  [DETAIL_ANGIO_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [DETAIL_ANGIO_TYPE_VARIABLE_SUCCESS](state, action) {
    const detail_angio_type_variable = action?.payload;
    return Object.assign({}, state, {
      detail_angio_type_variable,
      loading_angio_type_variable: false,
    });
  },
  [SAVE_ANGIO_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [SAVE_ANGIO_TYPE_VARIABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: false});
  },
  [GET_ANGIO_TYPE_VARIABLE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type_variable: true});
  },
  [GET_ANGIO_TYPE_VARIABLE_SUCCESS](state, action) {
    const angio_type_variable_list = action?.payload;
    return Object.assign({}, state, {
      angio_type_variable_list,
      loading_angio_type_variable: false,
    });
  },
  // angio_type
  [UPDATE_ANGIO_TYPE_TEMPLATE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [UPDATE_ANGIO_TYPE_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_angio_type: false});
  },
  [DELETE_ANGIO_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [DELETE_ANGIO_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_angio_type: false});
  },
  [EDIT_ANGIO_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [EDIT_ANGIO_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_angio_type: false});
  },
  [DETAIL_ANGIO_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [DETAIL_ANGIO_TYPE_SUCCESS](state, action) {
    const detail_angio_type = action?.payload;
    return Object.assign({}, state, {
      detail_angio_type,
      loading_angio_type: false,
    });
  },
  [SAVE_ANGIO_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [SAVE_ANGIO_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_angio_type: false});
  },
  [GET_ANGIO_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_angio_type: true});
  },
  [GET_ANGIO_TYPE_SUCCESS](state, action) {
    const angio_list = action?.payload;
    return Object.assign({}, state, {angio_list, loading_angio_type: false});
  },
  //
  [UPDATE_PLAN_CONDITION_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [UPDATE_PLAN_CONDITION_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [REMOVE_PARAMS_CHART_SUCCEEDED](state) {
    return Object.assign({}, state, {
      procedure_type: [],
    });
  },
  [TEXT_TEMPLATE_CONDITION_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [TEXT_TEMPLATE_CONDITION_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [UPDATE_ICD_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [UPDATE_ICD_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [UPDATE_CPT_RULE_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [UPDATE_CPT_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_procedure_type: false});
  },
  [UPDATE_CONDITION_TYPE_ICD_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [UPDATE_CONDITION_TYPE_ICD_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [DELETE_CONDITION_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [DELETE_CONDITION_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [SAVE_CONDITION_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [SAVE_CONDITION_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },
  [SORT_CONDITION_VARIABLES_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [SORT_CONDITION_VARIABLES_SUCCESS](state, action) {
    const condition_type = action?.payload;
    return Object.assign({}, state, {
      loading_condition_type: false,
      condition_type,
    });
  },
  [GET_CONDITION_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [GET_CONDITION_TYPE_SUCCESS](state, action) {
    const condition_type = action?.payload;
    return Object.assign({}, state, {
      loading_condition_type: false,
      condition_type,
    });
  },
  //
  [TEXT_TEMPLATE_PROCEDURE_STARTED](state, action) {
    return Object.assign({}, state, {loading_condition_type: true});
  },
  [TEXT_TEMPLATE_PROCEDURE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_condition_type: false});
  },

  [REFREST_STATE_TAB_SUCCESS](state, action) {
    return Object.assign({}, state, {
      procedureHistory: [],
      providers: [],
      conditions: [],
      ultrasoundsCondition: [],
      variable: [],
      conditionVariable: [],
      supportLocations: [],
      captures: [],
      ultrasounds: [],
      cptCodes: [],
      procedure_type: [],
      condition_type: [],
      plans: [],
      angio_list: [],
      detail_angio_type: {},
      providers_angio: [],
      ultrasounds_angio_type: [],
      angio_type_variable_list: [],
      angio_type: [],
      angioVariable: [],
      icdCodesAngio: [],
      cptCodesAngio: [],
      icdCodes: [],
      cpt_angio_Codes: [],
      new_patients: [],
      established_patients: [],
      angio_type_select_search: [],
      angio_choice: [],
      meaningful_uses: [],
      get_me_rules_new_patients: {},
      get_me_rules_established_patients: {},
      get_me_rules_meaningful_uses: {},
    });
  },
  [UPDATE_PROCEDURE_TYPE_CPT_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [UPDATE_PROCEDURE_TYPE_CPT_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_procedure_type: false});
  },
  [DELETE_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [DELETE_PROCEDURE_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_procedure_type: false});
  },
  [SAVE_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [SAVE_PROCEDURE_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {loading_procedure_type: false});
  },
  [SORT_PROCEDURE_VARIABLES_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [SORT_PROCEDURE_VARIABLES_SUCCESS](state, action) {
    const procedure_type = action?.payload;
    return Object.assign({}, state, {
      loading_procedure_type: false,
      procedure_type,
    });
  },
  [GET_PROCEDURE_TYPE_STARTED](state, action) {
    return Object.assign({}, state, {loading_procedure_type: true});
  },
  [GET_PROCEDURE_TYPE_SUCCESS](state, action) {
    const procedure_type = action?.payload;
    return Object.assign({}, state, {
      loading_procedure_type: false,
      procedure_type,
    });
  },

  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SET_PROCEDURE_DETAILS}_SUCCEEDED`](state, action) {
    return {
      ...state,
      ...action?.payload,
    };
  },
  [`${AT.ADD_PROCEDURE_DETAILS}_SUCCEEDED`](state, action) {
    return {
      ...state,
      [action?.payload?.type]: [
        ...state[action?.payload?.type],
        action?.payload?.data,
      ],
    };
  },
});

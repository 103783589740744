import { Checkbox, Icon } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import {
  patientExaminationLoadInit,
  patientExaminationSave,
  patientExaminationSetData,
  setGlobalToastr,
  setSidebarState,
  setVitalOG,
  setCopyFrom,
} from "../../../../store/actions";
import ExaminationROS from "./ExaminationROS";
import ROSSidebar from "./ROSSidebar";

class RosVitals extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: true,
    checkedCopyLast: false,
    saveQueued: false,
    checkSavingFuncId: null,
    ROSsCheck: [],
    first: false,
    firstCheck: false,
  };
  timer = null;

  async componentDidMount() {
    const current_appointment_id = getLocal("current_appointment_id");
    this.props.setSidebarState(true);
    const dataCheck = await this.props.patientExaminationLoadInit(current_appointment_id);
    const dataMock =
      (dataCheck?.vitals || []).map((r) => {
        return {
          ...r,
          extra: "",
        };
      }) || [];
    await this.props.setVitalOG(
      dataMock.map((r) => {
        return r;
      })
    );
    const { patientResource, copy_from_last_visit_ros } = this.props;
    if (patientResource) {
      this.setState({
        ...this.state,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    this.setState({
      first: false,
    });
    if (copy_from_last_visit_ros == 1) {
      this.setState({
        checkedCopyLast: true,
      });
    } else if (copy_from_last_visit_ros == 0) {
      this.setState({
        checkedCopyLast: false,
      });
    } else {
      this.setState({
        checkedCopyLast: null,
      });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.checkAllNormals();
    }, 1000);
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  componentWillReceiveProps(nextProps) {
    const { copy_from_last_visit_ros } = nextProps;
    this.setState({
      checkedCopyLast: copy_from_last_visit_ros == 1 ? true : false,
    });
    this.setState({
      first: false,
    });
  }

  handleSidebar = () => {
    this.setState({ ...this.state, isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ ...this.state, isSidebar: false });
  };

  onChangeData = async (scope, data) => {
    await this.props.patientExaminationSetData(scope, data, true);
    await this.setState({ ...this.state, saveQueued: true });
    this.saveData();
  };

  saveData = async () => {
    const { ROSs, vitals, patientResource, vascuqol6, is_locked } = this.props;
    const patient_id = patientResource?.id;
    if (is_locked == 0 || is_locked == null) {
      const fixArr = (ROSs || []).map((r) => {
        return {
          ...r,
          comments: RemoveN(r?.comments || ""),
        };
      });
      // func props
      if (this.props.setDataRos) {
        this.props.setDataRos({
          patient_id,
          ROSs: fixArr,
          ignore_bp_right: (vitals?.find((r) => r?.type == "ignore_bp_right") || {})?.value,
          ignore_bp_left: (vitals?.find((r) => r?.type == "ignore_bp_left") || {})?.value,
          copy_from_last_visit_ros: this.state.checkedCopyLast ? 1 : 0,
          vitals,
          vascuqol6,
        });
        this.setState({ ...this.state, saveQueued: false });
      }
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
  };

  doCopyLast = (checked) => {
    const { patientResource, is_locked, ROSs } = this.props;
    if (checked) {
      if (is_locked == 0 || is_locked == null) {
        this.setState({ checkedCopyLast: true }, async () => {
          this.props.setCopyFrom(1, "copy_from_last_visit_ros");
          await this.props.RosCoppy(patientResource?.id);
          await this.setState(
            {
              ...this.state,
              saveQueued: true,
            },
            () => {
              this.updateCopyAll(checked, false);
            }
          );
        });
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    } else {
      if (is_locked == 0 || is_locked == null) {
        this.props.setCopyFrom(0, "copy_from_last_visit_ros");
        this.setState(
          {
            checkedCopyLast: false,
          },
          () => {
            const removeRos = (ROSs || []).map((r) => {
              return {
                ...r,
                comments: RemoveN(r?.comments ? r?.comments : r?.default_comments),
                values: (r?.values || []).map((v) => {
                  return {
                    ...v,
                    checked: false,
                  };
                }),
              };
            });
            this.props.patientExaminationSetData("ROSs", removeRos);
          }
        );
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    }
  };

  updateCopyAll = (ros, all) => {
    const { patientResource } = this.props;
    const patient_id = patientResource?.id;
    if (this.props.setDataRos) {
      this.props.setDataRos({
        patient_id,
        copy_from_last_visit_ros: ros ? 1 : 0,
      });
    }
  };

  checkAllNormals = async () => {
    const { ROSs, vitals, is_locked, patientResource } = this.props;
    this.props.patientExaminationSetData("ROSs", ROSs, true);
    const patient_id = patientResource?.id;
    const ROSs_ = [...ROSs];
    const fixArr = (ROSs_ || []).map((r) => {
      return {
        ...r,
        comments: RemoveN(r?.comments ? r?.comments : r?.default_comments),
      };
    });
    this.props.setDataRos &&
      this.props.setDataRos({
        patient_id,
        ROSs: fixArr,
        ignore_bp_right: (vitals?.find((r) => r?.type == "ignore_bp_right") || {})?.value,
        ignore_bp_left: (vitals?.find((r) => r?.type == "ignore_bp_left") || {})?.value,
        copy_from_last_visit_ros: this.state.checkedCopyLast ? 1 : 0,
        vitals,
      });
    await this.props.RosCheckAll("ROSs", fixArr, true);
  };

  render() {
    const { isSidebar } = this.state;
    const { checkedCopyLast } = this.state;
    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <div id="check_box" className="conditionList">
            <Checkbox
              checked={checkedCopyLast}
              onChange={(e) => this.doCopyLast(e.target.checked)}
              disabled={this.props.is_locked || this.props.can_copy_from_previous_visit === 0}
            >
              Copy from previous visit
            </Checkbox>
            <div className="listSection">
              <ExaminationROS type={"ROS"} allCheck={true} onChangeData={(data) => this.onChangeData("ROSs", data)} isLocked={this.props.is_locked} />
            </div>
          </div>
        </div>
        <ROSSidebar
          ref={this.sidebarRef}
          sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
          onChangeData={this.onChangeData}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          isLocked={this.props.is_locked}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  ROSs: state.patientExamination.ROSs,
  vitals: state.patientExamination.vitals,
  can_copy_from_previous_visit: state.patientExamination.can_copy_from_previous_visit,
  copy_from_last_visit_ros: state.patientExamination.copy_from_last_visit_ros,
  vascuqol6: state.patientExamination.vascuqol6,
});

const mapDispatchToProps = {
  patientExaminationLoadInit,
  patientExaminationSetData,
  patientExaminationSave,
  setVitalOG,
  setGlobalToastr,
  setSidebarState,
  setCopyFrom,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosVitals);

import { Icon } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { getLocal } from "../../../../helpers/Local";
import { getEvaluationsTagert, getEvaluationsUser } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import CMESidebar from "./CMESidebar";

const CME = ({ loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const evaluationsTagert = useSelector((state) => state.common.evaluationsTagert);

  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  const authId = getLocal("cvai-current-user");
  const tableRows = evaluationsTagert ? evaluationsTagert : [];

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (row, index) => () => {
    setRow(index);
  };

  const fetchList = () => {
    dispatch(getEvaluationsUser(authId));
    dispatch(getEvaluationsTagert(authId));
  };

  const fetchCurrentUser = () => {
    if (authId) {
      handleSidebar();
    }
  };

  const fetchData = () => {
    fetchList();
    fetchCurrentUser();
  };
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_cme"
                tableWidth={920}
                labels={["CME Date", "Type", "CME Title", "CME Hours", "Uploaded Document"]}
                widths={["24%", "18%", "22%", "18%", "18%"]}
                columns={[
                  { sortable: false, key: "created_at" },
                  { sortable: false, key: "type" },
                  { sortable: false, key: "cme_title" },
                  { sortable: false, key: "hours" },
                  { sortable: false, key: "document" },
                ]}
                sorts={["", "", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  created_at: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <div className="d-flex">
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </div>
                      <p style={{ paddingLeft: "0.75rem" }}>{row?.created_at}</p>
                    </div>
                  ),
                  type: "Variance",
                  cme_title: row?.variance_type,
                  hours: row?.points,
                  document: row?.corective_actions,
                }))}
                fetchList={fetchData}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && evaluationsTagert.length > 0 && (
        <CMESidebar
          ref={sidebarRef}
          currentUserInfo={currentUserInfo}
          evaluationsTagertDetail={evaluationsTagert[activeIndex]}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
      {activeIndex >= 0 && currentUserInfo && evaluationsTagert.length > 0 && (
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={sidebarRef}
          sidebarToggle={toggleSidebar}
          sidebarState={isSidebar}
        />
      )}
    </div>
  );
};

export default CME;

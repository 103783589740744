import React from "react";
import { Children } from "./constant";

const PatientRecordsContainer = ({ location, isSideNav }) => {
  const getActivePath = (path) => {
    const paths = path.split("/");
    if (paths.length) {
      return paths[paths.length - 1];
    }
    return "";
  };

  const activePath = getActivePath(location?.pathname);
  const ActiveContent = Children[activePath];

  return (
    <div className={`clinicWorkflow__main ${isSideNav ? "" : "collapsed"}`}>
      <ActiveContent />
    </div>
  );
};

export default PatientRecordsContainer;

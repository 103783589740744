import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ExtendDataTable from "../../../common/components/SubDataTable/ExtendDataTable";
import { setGlobalLoading } from "../../../store/actions";

const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const getTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <div style={{ ...titleStyle, width: "40%", paddingLeft: 0, borderLeft: 0 }}>Event</div>
      <div style={{ ...titleStyle, width: "30%" }}>Result</div>
      <div style={{ ...titleStyle, width: "30%" }}>Status</div>
    </div>
  );
};

const ResourceSideBar = React.forwardRef(({ isShow, handleCloseSidebar, dataResult, row }, ref) => {
  const loading = useSelector((state) => state.reminders.loadingResource);
  const resource = useSelector((state) => state.reminders.resource);
  const [dataResultTable, setDataResultTable] = useState([]);
  const [procedureDate, setProcedureDate] = useState();

  const dispatch = useDispatch();

  const toPropercase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    if (dataResult) {
      const mapingData = dataResult.map((item) => ({
        item: item.created_at && moment(item.created_at).format("MM/DD/YYYY HH:mm"),
        amount: toPropercase(item.status),
        status: row && row.status_raw,
      }));
      setDataResultTable(mapingData);
    }
    if (row) {
      setProcedureDate(row.procedure_date ? moment(row.procedure_date).format("MM/DD/YYYY HH:mm") : undefined);
    }
  }, [dataResult, row]);

  useEffect(() => {
    if (resource && resource?.procedure_date) {
      setProcedureDate(moment(resource?.procedure_date, "MM/DD/YYYY HH:mm").format("MM/DD/YYYY HH:mm"));
    }
  }, [resource]);

  const changeAppointmentDate = (date, dateString) => {
    setProcedureDate(dateString);
  };

  useEffect(() => {
    if (isShow && resource && Object.keys(resource).length) {
      dispatch(setGlobalLoading(loading));
    }
  }, [loading, isShow, resource]);

  if (!resource || !Object.keys(resource).length) {
    return null;
  }

  return (
    <Drawer
      title={`${row?.procedure} - ${resource?.first_name} ${resource?.last_name}`}
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div className="resourceContent" style={{ paddingLeft: "1.5rem", marginTop: "1.5rem" }}>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"First Name"} value={resource && resource?.first_name} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Last Name"} value={resource && resource?.last_name} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Deceased"} value={resource && resource?.deceased ? "Yes" : "No"} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"DOB"} value={resource && moment(resource?.date_of_birth).format("MM/DD/YYYY")} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Phone No"} value={resource && resource?.phone} mask={"phone"} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Phone No"} value={resource && resource?.secondary_phone} mask={"phone"} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"E-Mail"} value={resource && resource?.email} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"SSN"} value={resource && resource?.ssn} mask={"ssn"} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Address"} value={resource && resource?.address} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Apt"} value={resource && resource?.apt_number} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"State"} value={resource && resource?.state} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"City"} value={resource && resource?.city} noEditable={true} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Zip Code"} value={resource && resource?.zipcode} noEditable={true} />
            </div>
            <hr />
            <div className="resourceInfo d-flex">
              <EditableFormInput label={"Appointment Date"} value={procedureDate} />
            </div>
            <hr />
            <div className="resourceInfo">
              <b>Result </b>
            </div>
            <hr />
            <div className="resourceInfo no-padding">
              <ExtendDataTable
                onClickRow={(index) => {}}
                isActiveBorder={true}
                title={getTitle()}
                data={dataResultTable}
                options={["item", "amount", "status"]}
                widths={["40%", "30%", "30%"]}
                minRows={4}
              />
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ResourceSideBar;

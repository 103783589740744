import { Drawer } from "antd";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useState } from "react";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { printPolicy } from "../../../../services/api.services";

const ManualPoliciesSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, policiesDetail }, ref) => {
  const [reset, setReset] = useState(true);
  const [printLink, setPrintLink] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (policiesDetail) {
      setReset(true);
      let files = [];
      if (policiesDetail?.file !== null) {
        files = JSON.parse(policiesDetail?.file);
      }
      if (files?.length > 0) {
        files.forEach((file, index) => {
          setFiles((prevFile) => [
            ...prevFile,
            {
              name: file?.fileName,
              link: file?.fileUrl,
            },
          ]);
        });
      }
    }
    return () => {
      setFiles([]);
    };
  }, [policiesDetail]);

  const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
  const isSafari = navigator.userAgent.indexOf("Safari") > -1 && !isChrome;

  const makeLi = (value) => {
    if (value && value.length > 0) {
      let result = value;
      const checkTagsUL = result.split("<ul>").length - 1;
      if (checkTagsUL && checkTagsUL > 0) {
        for (let index = 0; index < checkTagsUL; index++) {
          result = result.replace("<ul>", `<ul style="list-style:disc;padding-left:15px;margin-left:35px;" >`);
        }
      }
      const checkTagsOL = result.split("<ol>").length - 1;
      if (checkTagsOL && checkTagsOL > 0) {
        for (let index = 0; index < checkTagsOL; index++) {
          result = result.replace("<ol>", `<ol style="padding-left:15px" >`);
        }
      }
      return result;
    }
    return null;
  };

  const handlePrint = async () => {
    const print = await printPolicy(policiesDetail?.id);
    if (print.data && print.data?.url) {
      const base64 = await pdf2base64(print.data?.url);
      setPrintLink(base64);
    }
  };

  const downloadFile = (url, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);
      const tag = document.createElement("a");
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  };

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
    }
  }, [printLink]);
  const printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf";
    const blob = b64toBlob(b64, contentType);
    const blobUrl = URL.createObjectURL(blob);

    let iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  if (!policiesDetail) {
    return null;
  }

  return (
    <Drawer
      title={`Policy ${policiesDetail?.policy_number} - ${policiesDetail?.policy_name}`}
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className={`resourceContent ${isSafari ? "safari-browser" : "no-safari"}`}
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo ol-ul">
              <b>Purpose: </b>
              <div
                dangerouslySetInnerHTML={{
                  __html: makeLi(policiesDetail?.purpose),
                }}
              />
            </div>
            <div className="resourceInfo ol-ul" style={{ marginTop: "0.5rem" }}>
              <b>Applies to: </b>
              <div
                dangerouslySetInnerHTML={{
                  __html: makeLi(policiesDetail?.apply_to),
                }}
              />
            </div>
            <div className="resourceInfo ol-ul d-flex" style={{ marginBottom: "1rem", marginTop: "0.5rem" }}>
              <div>
                <b>Policy: </b>
                <div
                  dangerouslySetInnerHTML={{
                    __html: makeLi(policiesDetail?.policy),
                  }}
                />
              </div>
            </div>
            <div className="resourceInfo ol-ul d-flex" style={{ marginBottom: "1rem", marginTop: "0.5rem" }}>
              <div>
                <b>Procedures: </b>
                <div
                  dangerouslySetInnerHTML={{
                    __html: makeLi(policiesDetail?.procedures),
                  }}
                />
              </div>
            </div>

            {files?.length > 0 && (
              <div className="resourceInfo" style={{ display: "flex" }}>
                <b style={{ minWidth: "35%" }}>Form Attachment: </b>
                <div
                  style={{
                    marginLeft: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {files?.map(({ link, name }, index) => (
                    <p
                      key={index}
                      onClick={() => downloadFile(link, name)}
                      style={{
                        color: "#005ef6",
                        cursor: "pointer",
                      }}
                    >
                      {name}
                    </p>
                  ))}
                </div>
              </div>
            )}

            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "35%" }}>Approved by: </b>
              <div style={{ marginLeft: "0.5rem" }}>{policiesDetail?.approved_by}</div>
            </div>
            <div className="resourceInfo d-flex">
              <b style={{ minWidth: "35%" }}>Effective date: </b>
              <div style={{ marginLeft: "0.5rem" }}>{"11/1/2015"}</div>
            </div>
          </div>
          <div
            className="actionContent"
            style={{
              marginTop: "2rem",
              paddingRight: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <button onClick={handlePrint}>Print</button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ManualPoliciesSidebar;

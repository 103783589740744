import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import ToastMessage from '../../../../common/components/Toast';

import {
  exportMeaningfulUseReport
} from '../../../../store/actions';

const MeaningfulUseSidebar = React.forwardRef(
    (
        {resource, isShow, handleCloseSidebar, updateState, moveState},
        ref,
    ) => {
      const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
      const report_link = useSelector(
        (state) => state.adminMeaningful.report_link
      );
      const [message, setMessage] = useState(null);
      const [isToastr, setIsToastr] = useState(false);
      const dispatch = useDispatch();

      const checkPrint = async () => {
        window.open(report_link.url, '_blank');
        setMessage('Print Successfully!');
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          setMessage(null);
        }, 2000);
      };

      useEffect(() => {
        if (
          report_link &&
          Object.keys(report_link).length > 0
        ) {
          checkPrint();
        }
      }, [report_link]);

      const exportToExcel = async () => {
        await dispatch(exportMeaningfulUseReport());
      }

      return (
        <Drawer
          title="MEANINGFUL STATISTICS"
          placement="right"
          closable
          className={sideNavStatus ? 'wide' : ''}
          onClose={handleCloseSidebar}
          visible={isShow}
          destroyOnClose
          key="right"
        >
          <div ref={ref} className="resourceContainer new">
            {isToastr && (
              <ToastMessage
              type="Report"
              status={'success'}
              msg={message ? message : 'Update Successfully!'}
              />
            )}
            <ScrollWrapper>
              <div
                className="resourceContent"
                style={{
                  flex: 'unset',
                  paddingLeft: '24px',
                  paddingRight: '20px',
                  marginTop: '1.5rem',
                }}
              >
                <div
                  className="actionContent"
                  style={{marginTop: '2rem', paddingRight: '1rem'}}
                >
                  <button onClick={exportToExcel}>Export to Excel</button>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </Drawer>
      );
    },
);

export default MeaningfulUseSidebar;

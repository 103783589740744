import { DatePicker, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import {
  ExpandSVG,
  SearchSVG
} from '../../../../common/components/icons/SVGIcon';
import {
  getReturnCreditOrders, setInventoryData
} from '../../../../store/actions';

const {Search} = Input;
const {Option} = Select;
const dateFormat = 'MM/DD/YYYY';

const ReturnCreditFilter = ({}) => {
  const filterOption = useSelector((state) => state.inventory.orderFilter);
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const debounce = useDebounce(search, 400);

  useEffect(() => {
    dispatch(getReturnCreditOrders());
    dispatch(setInventoryData('returnCredit', null));
    dispatch(setInventoryData('orderFilter', {}));
  }, []);

  useEffect(() => {
    dispatch(
        setInventoryData('orderFilter', {
          ...filterOption,
          search: debounce,
        }),
    );
  }, [debounce]);

  const changeFilter = (type) => (value) => {
    const newFilter = {...filterOption, [type]: value};
    dispatch(setInventoryData('orderFilter', newFilter));
  };

  const changeFilterDate = (type) => (date, dateString) => {
    const newFilter = {...filterOption, [type]: dateString};
    dispatch(setInventoryData('orderFilter', newFilter));
  };

  const changeSearch = (value) => {
    setSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer" style={{width: '44%'}}>
        <div className="searchField">
          <Search
          className="dependencySearch"
            placeholder="Search"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{width: '100%'}}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer" style={{width: '56%'}}>
        <div
          className="statusFilter"
          style={{
            width: '120px',
            maxWidth: '120px',
            minWidth: '120px',
          }}
        >
          <Select
            placeholder="Numbers..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.purchase_number}
            onChange={changeFilter('purchase_number')}
          >
            <Option value="1">+1</Option>
            <Option value="5">+5</Option>
            <Option value="10">+10</Option>
            <Option value="25">+25</Option>
          </Select>
        </div>
        <div
          className="statusFilter"
          style={{
            width: '120px',
            maxWidth: '120px',
            minWidth: '120px',
          }}
        >
          <Select
            placeholder="Unit..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.unit}
            onChange={changeFilter('unit')}
          >
            <Option value="Case">Case</Option>
            <Option value="Box">Box</Option>
            <Option value="Pack">Pack</Option>
            <Option value="Each">Each</Option>
          </Select>
        </div>
        <div className="statusFilter" style={{flex: 1}}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="Date Requested"
            suffixIcon={<ExpandSVG />}
            style={{
              width: '100%',
            }}
            onChange={changeFilterDate('requestedDate')}
          />
        </div>
        <div className="statusFilter" style={{flex: 1}}>
          <Select
            placeholder="Amount..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.amount}
            style={{
              width: '100%',
            }}
            onChange={changeFilter('amount')}
          >
            <Option value="10">+10</Option>
            <Option value="50">+50</Option>
            <Option value="100">+100</Option>
            <Option value="500">+500</Option>
            <Option value="1000">+1000</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default ReturnCreditFilter;

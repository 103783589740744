const question1 = [
  // table 1,2,3 on qs 1
  {
    finding_name:
      'Table1 Absence of a pulse distal to the identified occlusion',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table1 AND ALL of the following:
      •	Absent or weak arterial signal by Doppler
      •	Ankle-brachial index (ABI) 0.4 or less
      •	ONE or MORE of the following symptoms in the affected extremity:
      `,
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table1 ONE or MORE of the following symptoms in the affected extremity:
      •	Sudden onset of severe pain
      •	Numbness
      •	Coldness
      •	Weakness
      •	Discoloration`,
    Qualifier: 'Yes',
  },
  // table 2
  {
    finding_name: 'Table2 Symptoms',
    Qualifier:
      'Pain at rest while in laying in bed at night with short distance claudication. ',
  },
  {
    finding_name: `Table2 Critical Limb Ischemia`,
    Qualifier: 'Yes',
  },
  // table 3
  {
    finding_name:
      'Table3 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table3 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table3 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question2 = [
  // table 4,5,6 on qs 2
  // table 4
  {
    finding_name:
      'Table4 Vein graft stenosis identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography)',
    Qualifier: 'Yes',
  },
  // table 5
  {
    finding_name: 'Table5 Critical Limb Ischemia',
    Qualifier: 'Yes',
  },
  // table 6
  {
    finding_name:
      'Table6 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table6 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table6 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question3 = [
  // table 7,8,9 on qs 3
  // table 7
  {
    finding_name:
      'Table7 Claudication (cramping pain in the leg caused by exercise such as walking)',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table7 Stenosis identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography)',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table7 AND ONE or MORE of the following: 
      •	Resting ankle-brachial index (ABI) 0.9 or less
      •	ABI greater than 1.4 and toe-brachial index 0.7 or less
      •	Exercise or hyperemic ABI of 0.9 or less
      •	Exercise ABI more than a 20 percent decrease from baseline and delayed recovery
      •	Exercise ABI of at least a 30 mmHg decrease from baseline and delayed recovery
      •	Resting toe pressure of 50 mmHg or less
      •	Transmetatarsal or ankle pulse volume recording (PVR) amplitude of 5 mm or less
      `,
    Qualifier: 'Yes',
  },
  // table 8
  {
    finding_name: 'Table8 Critical Limb Ischemia',
    Qualifier: 'Yes',
  },
  // table 9
  {
    finding_name:
      'Table9 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table9 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table9 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question4 = [
  // table 10,11,12 on qs 4
  // table 10
  {
    finding_name:
      'Table10 Rest pain; OR nonhealing ulcer or wound on the lower leg or foot for at least 2 weeks',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table10 Stenosis identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography)',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table10 AND ONE or MORE of the following: 
      •	Resting ankle-brachial index (ABI) 0.9 or less
      •	ABI greater than 1.4 and toe-brachial index 0.7 or less
      •	Exercise or hyperemic ABI of 0.9 or less
      •	Exercise ABI more than a 20 percent decrease from baseline and delayed recovery
      •	Exercise ABI of at least a 30 mmHg decrease from baseline and delayed recovery
      •	Resting toe pressure of 50 mmHg or less
      •	Transmetatarsal or ankle pulse volume recording (PVR) amplitude of 5 mm or less
      `,
    Qualifier: 'Yes',
  },
  // table 11
  {
    finding_name: 'Table11 Critical Limb Ischemia',
    Qualifier: 'Yes',
  },
  // table 12
  {
    finding_name:
      'Table12 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table12 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table12 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question5_6_res = [
  // table 13,14,15 on qs question5_6_res
  // table 13
  {
    finding_name:
      'Table13 Rest pain (Ischemia rest pain is pain that occurs in the toes or in the area of the metatarsal heads.  Occasionally, it occurs in the foot proximal to the metatarsal heads.  Elevation of the limb above the horizontal aggravates the pain and pendency, to some degree at least brings relief.',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table13 Occlusion or stenosis of at least 50 percent identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography)',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table13 ONE OR MORE of the following:
      •	Resting ankle-brachial index (ABI) 0.9 or less
      •	ABI greater than 1.4 and toe-brachial index 0.7 or less
      •	Exercise or hyperemic ABI of 0.9 or less
      •	Exercise ABI more than a 20 percent decrease from baseline and delayed recovery
      •	Exercise ABI of at least a 30 mmHg decrease from baseline and delayed recovery
      •	Resting toe pressure of 50 mmHg or less
      •	Transmetatarsal or ankle pulse volume recording (PVR) amplitude of 5 mm or less
      •	Transcutaneous PO2 or 30 mmHg or less`,
    Qualifier: 'Yes',
  },
  // table 14
  {
    finding_name: 'Table14 Critical Limb Ischemia',
    Qualifier: 'Yes',
  },
  // table 15
  {
    finding_name:
      'Table15 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table15 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table15 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question5_6_wound = [
  // table 16,17,18 on qs 5_6_wound
  // table 13
  {
    finding_name:
      'Table16 Nonhealing ulcer or wound of the lower leg or foot for at least two weeks',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table16 Occlusion or stenosis of at least 50 percent identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography)',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table16 ONE OR MORE of the following:
      •	Resting ankle-brachial index (ABI) 0.9 or less
      •	ABI greater than 1.4 and toe-brachial index 0.7 or less
      •	Exercise or hyperemic ABI of 0.9 or less
      •	Exercise ABI more than a 20 percent decrease from baseline and delayed recovery
      •	Exercise ABI of at least a 30 mmHg decrease from baseline and delayed recovery
      •	Resting toe pressure of 50 mmHg or less
      •	Transmetatarsal or ankle pulse volume recording (PVR) amplitude of 5 mm or less
      •	Transcutaneous PO2 or 30 mmHg or less`,
    Qualifier: 'Yes',
  },
  // table 17
  {
    finding_name: 'Table17 Critical Limb Ischemia',
    Qualifier: 'Yes',
  },
  // table 18
  {
    finding_name:
      'Table18 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table18 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table18 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const question7_8_9 = [
  // table 19,20,21 on qs question7_8_9
  // table 19
  {
    finding_name:
      'Table19 Pain of the extremities that interferes with mobility related activities of daily living',
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table19 ONE OR MORE of the following:
      •	Resting ankle-brachial index (ABI) 0.9 or less
      •	ABI greater than 1.4 and toe-brachial index 0.7 or less
      • Exercise or hyperemic ABI of 0.9 or less
      •	Exercise ABI more than a 20 percent decrease from baseline and delayed recovery
      •	Exercise ABI of at least a 30 mmHg decrease from baseline and delayed recovery
      •	Resting toe pressure of 50 mmHg or less
      •	Transmetatarsal or ankle pulse volume recording (PVR) amplitude of 5 mm or less`,
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table19 Occlusion or stenosis of at least 50 percent identified by imaging (such as duplex ultrasound, Doppler, CTA, MRA or angiography) AND ONE of the following:
      •	Common iliac arterial lesion
      •	External iliac arterial lesion
      •	Femoral arterial lesion
      • Popliteal arterial lesion
      `,
    Qualifier: 'Yes',
  },
  {
    finding_name: `Table19 Continued symptoms or findings after treatment with ALL of the following:
      •	Supervised exercise sessions of 30 to 60 minutes three times per week for a minimum of 3 months (12 weeks)
      •	Smoking cessation or reduction for at least 12 weeks OR nonsmoker
      •	Cilostazol or pentoxifylline for at least 12 weeks (unless contraindicated or not tolerated)
      •	Cholesterol management for at least 12 weeks (unless contraindicated or not tolerated)
      `,
    Qualifier: 'Yes',
  },
  // table 20
  {
    finding_name: 'Table20 Symptoms',
    Qualifier:
      'Pain at rest while in laying in bed at night with short distance claudication.',
  },
  {
    finding_name: 'Table20 Activity Limiting Disease',
    Qualifier: 'Yes',
  },
  {
    finding_name: 'Table20 Antiplatelet therapy',
    Qualifier: 'Yes',
  },
  {
    finding_name: 'Table20 Statins',
    Qualifier: 'Yes',
  },
  {
    finding_name: 'Table20 Exercise program',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table20 Smoking cessation including planning, counseling or behavior modification and pharmacotherapy if needed',
    Qualifier: 'Yes',
  },
  // table 21
  {
    finding_name:
      'Table21 Documentation that an invasive intervention is planned',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table21 Documentation that a prior non-invasive study was completed and indicates further study is needed by angiography for the planned intervention',
    Qualifier: 'Yes',
  },
  {
    finding_name:
      'Table21 Documentation of one of the following conditions: arterial embolism, acute or chronic ischemia, peripheral vascular disease (includes claudication), or aneurysm.',
    Qualifier: 'Yes',
  },
];

const valueFind = [
  // table 1,2,3 on qs 1
  ...question1,
  // table 4,5,6 on qs 2
  ...question2,
  // table 7,8,9 on qs 3
  ...question3,
  // table 10,11,12 on qs 4
  ...question4,
  // table 13,14,15 on qs question5_6_res
  ...question5_6_res,
  // table 16,17,18 on qs 5_6_wound
  ...question5_6_wound,
  // table 19,20,21 on qs question7_8_9
  ...question7_8_9,
];

export default valueFind;

import { Drawer, message, Upload } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import EditFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { BarcodeSVG } from "../../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../../common/components/ScrollBar";

const { Dragger } = Upload;

const draggerProps = {
  name: "file",
  multiple: false,
  action: "",
  onChange(info) {
    const { status } = info?.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info?.file?.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
    }
  },
};

const InvoicesSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const purchase = useSelector((state) => state.inventory.receivingOrder);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [paidAmount, setPaidAmount] = useState();
  const [netBalance, setNetBalance] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [contactPerson, setContactPerson] = useState();

  const totalAmount = useMemo(() => {
    if (purchase) {
      return (purchase.cost * purchase.purchase_number).toFixed(2);
    }
    return 0;
  }, [purchase]);

  if (!purchase) {
    return null;
  }

  return (
    <Drawer
      title={purchase?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Contact Person"} value={contactPerson} onChange={setContactPerson} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Contact Phone"} value={contactPhone} mask={"phone"} onChange={setContactPhone} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Items"} value={purchase.purchase_number} unit={purchase.unit} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Total"} value={totalAmount} prefix={"$"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Amount Paid"} value={paidAmount && paidAmount.toFixed(2)} prefix={"$"} onChange={setPaidAmount} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Net Balance"} value={netBalance && netBalance.toFixed(2)} prefix={"$"} onChange={setNetBalance} />
            </div>
            <hr />
            <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
              <b>Image of Invoice</b>
              <Dragger {...draggerProps} className="barcode-uploader" showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <BarcodeSVG />
                </p>
              </Dragger>
            </div>
            <hr />
          </div>
          <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
            <button>Confirm Amount Paid</button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default InvoicesSidebar;

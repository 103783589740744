import { createActionThunk } from "redux-thunk-actions";
import AT from "../actionTypes";
import * as API from "../../services/api.services";

export const HPIGetConditions = createActionThunk(AT.HPI_GET_CONDITIONS, (procedure_id) => API.HPIGetConditions(procedure_id));

export const HPISetConditions = (conditions) => (dispatch) =>
  dispatch({
    type: AT.HPI_SET_CONDITIONS,
    payload: {
      conditions,
    },
  });

export const HPISaveCondition = createActionThunk(AT.HPI_SAVE_CONDITION, (patient_id, procedure_id, data, copy_hpi_from_previous_appointment) =>
  API.HPISaveCondition(patient_id, procedure_id, data, copy_hpi_from_previous_appointment)
);

export const update_copy_hpi = createActionThunk(AT.UPDATE_COPY_HPI, (status) => ({ status }));

export const loadUltrasoundHistoryForConditionHPI = createActionThunk(
  AT.LOAD_CONDITION_HPI_UNTRASOUND,
  (patient_id, condition_value, appointment_id) => API.loadUltrasoundHistoryForCondition(patient_id, condition_value, appointment_id)
);

export const setIsSaveHPI = createActionThunk(AT.SET_IS_SAVE_HPI, (status) => status);

export const setIsSaveDetail = createActionThunk(AT.SET_IS_SAVE_DETAIL, (status) => status);

export const saveSameHpiCheck = (has_same_hpi) => (dispatch) =>
  dispatch({
    type: AT.SAVE_SAME_HPI_CHECK,
    payload: {
      has_same_hpi,
    },
  });

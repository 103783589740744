import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEvents, getOnlyProviders, getProcedureLocations, setGlobalLoading } from "../../store/actions";
import ClinicalCalendar from "./components/ClinicalCalendar";

const ProcedureCalendar = (props) => {
  const events = useSelector((state) => state.clinicEvents.events);
  const activeOpenings = useSelector((state) => state.clinicEvents.activeOpenings);
  const closedDates = useSelector((state) => state.clinicEvents.closedDates);
  const openingTime = useSelector((state) => state.clinicEvents.openingTime);
  const closingTime = useSelector((state) => state.clinicEvents.closingTime);
  const locations = useSelector((state) => state.common.procedureLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  const fetchTime = async (value) => {
    dispatch(setGlobalLoading(true));
    if (!value) {
      await dispatch(getProcedureLocations("clinic"));
      await dispatch(getOnlyProviders());
    }
    const date = moment(new Date()).format("MM/DD/YYYY");
    await dispatch(getEvents(value ? value : { date }));
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchTime();
  }, []);

  const handleEvents = async (date, location, provider) => {
    fetchTime({ date, location, provider });
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick) {
      props.onHandleClick(event);
    }
  };

  return (
    <div className="calendarContainer" style={{ position: "relative", left: 10, right: 10 }}>
      <ClinicalCalendar
        activeID={props?.activeID}
        onClickEvent={onClickEvent}
        eventData={events || []}
        fetchEvents={handleEvents}
        locations={locations || []}
        providers={providers || []}
        activeOpenings={activeOpenings || []}
        closedDates={closedDates || []}
        openingTime={openingTime || ""}
        closingTime={closingTime || ""}
      />
    </div>
  );
};

export default ProcedureCalendar;

import { Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import { get_filter_patient_document, setSidebarState } from "../../../store/actions";
import VideoImageViewer from "../PatientChart/VideoImageViewer";
import { StaticResource } from "../PatientChart/constant";
import EducationSideBar from "./sidebar";

const { TabPane } = Tabs;

const Education = (props) => {
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const dispatch = useDispatch();
  const current_patient_id = getLocal("current_patient_id");
  const [isSidebar, setIsSidebar] = useState(true);

  useEffect(() => {
    dispatch(setSidebarState(true));
    let params = { patient_id: current_patient_id };
    params = {
      ...params,
      category: "",
      provider_id: "",
      created_at: "",
    };
    dispatch(get_filter_patient_document(params));

    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    } else if (patientResource) {
      setIsSidebar(true);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const sidebarRef = useRef();
  return (
    <div
      style={{
        display: !props.display ? "flex" : props.display === "flex" ? "flex" : "none",
      }}
      className="clinicWorkflow__main-section"
    >
      <div className="eventsContainer has-sub-header">
        <Tabs className="patientChart" type="card">
          <TabPane tab="Images" key="image-viewer-tab">
            <VideoImageViewer />
          </TabPane>
        </Tabs>
      </div>
      <EducationSideBar sidebarIcon={props.sidebarIcon} ref={sidebarRef} resource={StaticResource} isShow={isSidebar} />
    </div>
  );
};

export default Education;

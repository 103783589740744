import { createActionThunk } from "redux-thunk-actions";

import AT from "../actionTypes";

import * as API from "../../services/api.services";

export const setPendingSearch = createActionThunk(AT.SET_PENDING_SEARCH, (search) => search);

export const setCallbackSearch = createActionThunk(AT.SET_CALLBACK_SEARCH, (search) => search);

export const setStepData = createActionThunk(AT.SET_STEP_DATA, (type, newData) => ({ [type]: newData }));

export const getEvents = createActionThunk(AT.GET_EVENTS, ({ date, location, provider }) => API.getClinicEvents({ date, location, provider }));
export const getPending = createActionThunk(AT.GET_PENDING, ({ referral_date, skip, take, appointment_type }) =>
  API.getPatientPending({ referral_date, skip, take, appointment_type })
);
export const getCallback = createActionThunk(AT.GET_CALLBACK, ({ referral_date }) => API.getPatientCallback({ referral_date }));
export const selectPatient = createActionThunk(AT.SELECT_PATIENT, (id) => API.getPatient({ id }));

export const setDataPatient = createActionThunk(AT.SET_DATA_PATIENT, (params) => ({ params }));

export const setQuestCompare = createActionThunk(AT.SET_QUEST_COMPARE, (value) => value);

export const search_pcp = createActionThunk(AT.SEARCH_PCP, (page, search) => API.search_pcp(page, search));

export const searchPcpNpe = createActionThunk(AT.SEARCH_PCP_NPE, (page, search) => API.getPCPFromNPE(page, search));

export const addpatientPending = createActionThunk(AT.ADD_PATIENT_PENDING, (patient) => patient);
// Sidebar Stepper
export const updatePatient = createActionThunk(AT.UPDATE_PATIENT, (params) => API.editDemographicsOfPatient(params));
export const addPatient = createActionThunk(AT.ADD_PATIENT, (params) => API.addNewPatient(params));
export const verifyInsurance = createActionThunk(AT.VERIFY_INSURANCE, (id, params) => API.verifyInsuranceDetail(id, params));
export const updateMedicalHistory = createActionThunk(AT.UPDATE_MEDICAL_HISTORY, (params, id) => API.updateMedicalHistory(params, id));
export const updateQuestionnaire = createActionThunk(AT.UPDATE_QUESTIONNAIRE, (params, id) => API.updateQuestionnaire(params, id));
export const getAppointments = createActionThunk(AT.GET_APPOINTMENTS, (id) => API.getAppointmentSchedule(id));
export const filterAppointments = createActionThunk(
  AT.FILTER_APPOINTMENTS,
  (id, location, provider, period, prev, idxOfSchedule, action = "", custom, load_next) =>
    API.filterAppointmentSchedule(id, location, provider, period, prev, { idxOfSchedule, action }, custom, load_next)
);

export const resetSchedules = createActionThunk(AT.RESET_SCHEDULES, () => {});

export const removeHasInsurance = createActionThunk(AT.REMOVE_HAS_INSURANCE, () => {});

export const addCallAttempts = createActionThunk(AT.ADD_CALL_ATTEMPTS, (params) => API.addCallAttempt(params));
export const updateCallAttempts = createActionThunk(AT.UPDATE_CALL_ATTEMPTS, (params) => API.updateCallAttempts(params));
export const saveClinicSchedule = createActionThunk(AT.SAVE_CLINIC_SCHEDULE, (id, params) => API.saveClinicSchedule(id, params));
// note patient
export const save_patient_notes = createActionThunk(AT.SAVE_PATIENT_NOTES_NEW, (params) => API.save_patient_notes(params));

export const get_patient_notes = createActionThunk(AT.GET_ALL_PATIENT_NOTES_NEW, (id, ulID) => API.get_patient_notes(id, ulID));

export const save_note_as_pdf = createActionThunk(AT.SAVE_NOTE_AS_PDF, (params) => API.save_note_as_pdf(params));

export const delete_patient_notes = createActionThunk(AT.DELETE_PATIENT_NOTES_NEW, (id) => API.delete_patient_notes(id));

// billing note
export const save_patient_notes_billing = createActionThunk(AT.SAVE_PATIENT_NOTES_NEW_BILLING, (params) => API.save_patient_notes_billing(params));

export const get_patient_notes_billing = createActionThunk(AT.GET_ALL_PATIENT_NOTES_NEW_BILLING, (id) => API.get_patient_notes_billing(id));

export const delete_patient_notes_billing = createActionThunk(AT.DELETE_PATIENT_NOTES_NEW_BILLING, (id) => API.delete_patient_notes_billing(id));

// insurance_companies

export const search_insurance_companies = createActionThunk(AT.SEARCH_INSURANCE_COMPANIES, (query) => API.search_insurance_companies(query));

export const detail_insurance_companies = createActionThunk(AT.DETAIL_INSURANCE_COMPANIES, (id) => API.detail_insurance_companies(id));

export const save_insurance_companies = createActionThunk(AT.SAVE_INSURANCE_COMPANIES, (params) => API.save_insurance_companies(params));

export const edit_insurance_companies = createActionThunk(AT.EDIT_INSURANCE_COMPANIES, (id, params) => API.edit_insurance_companies(id, params));

export const delete_insurance_companies = createActionThunk(AT.DELETE_INSURANCE_COMPANIES, (id) => API.delete_insurance_companies(id));

export const insurance_types = createActionThunk(AT.INSURANCE_TYPES, () => API.insurance_types());

// patient_insurances

export const patient_insurances_id_insurances = createActionThunk(AT.PATIENT_NODES_ID_INSURANCES, (patient_id) =>
  API.patient_insurances_id_insurances(patient_id)
);

export const save_patient_insurances = createActionThunk(AT.SAVE_PATIENT_NODES_INSURANCES, (params) => API.save_patient_insurances(params));

export const detail_patient_insurances = createActionThunk(AT.DETAIL_PATIENT_NODES_INSURANCES, (id) => API.detail_patient_insurances(id));

export const edit_patient_insurances = createActionThunk(AT.EDIT_PATIENT_NODES_INSURANCES, (id, params) => API.edit_patient_insurances(id, params));

export const delete_patient_insurances = createActionThunk(AT.DELETE_PATIENT_NODES_INSURANCES, (id) => API.delete_patient_insurances(id));

export const check_patient_insurances = createActionThunk(AT.CHECK_PATIENT_NODES_INSURANCES, (id) => API.check_patient_insurances(id));

export const reset_check_patient = createActionThunk(AT.RESET_CHECK_PATIENT, () => {});

export const saveCheckQuestion = createActionThunk(AT.SAVE_CHECK_QUESTION, (newData) => newData);

export const update_patient_ops = createActionThunk(AT.UPDATE_PATIENT_OPS, (id) => API.update_patient_ops(id));

export const payer_id_types = createActionThunk(AT.GET_PAYER_ID, () => API.payer_id_types());

export const setEnterInsuranceCheck = createActionThunk(AT.SET_ENTER_INSURANCE_CHECK, () => {});

export const getPrescriptions = createActionThunk(AT.GET_PRESCRIPTIONS, (patient_id, page) => API.getPrescriptions(patient_id, page));

import QuickRegister from './components/QuickRegister';

export const Children = {
  'quick-register': QuickRegister,
  'processing-referral': QuickRegister,
  'clinic-confirmation': QuickRegister,
  'clinic-appointment': QuickRegister,
  'obl-confirmation': QuickRegister,
  'obl-appointment': QuickRegister,
  'surgery-confirmation': QuickRegister,
};

export const TabHeaders = [{label: 'All Content', width: 'auto'}];

import React, {useState, useMemo} from 'react';
import './style.scss';

const SubDataTable = ({
  title,
  data,
  disableHeader,
  half,
  variant,
  options,
  firstOption,
  secondOption,
  minRows,
  widths,
  handleRowClick,
}) => {
  const [activeIndex, setActive] = useState(-1);

  const handleActive = (index) => {
    setActive(index);
    if (handleRowClick) {
      handleRowClick(index);
    }
  };

  const emptyRows = useMemo(() => {
    const rowCount = minRows ? minRows : 4;
    if (data && data.length) {
      const numberOfRows = data.length > rowCount ? 0 : rowCount - data.length;
      return new Array(numberOfRows).fill('empty');
    }
    return new Array(rowCount).fill('empty');
  }, [data]);
  return (
    <div className="subDataTable">
      {!disableHeader && <div className="subDataTable__title">{title}</div>}
      {((options && data) || []).map((row, index) => (
        <div
          className={`subDataTable__item ${variant ? variant : ''} ${
            half ? 'half' : ''
          } ${activeIndex === index ? 'active' : ''}`}
          key={`row-${index}`}
          onClick={() => handleActive(index)}
        >
          {options?.map((option, index) => (
            <span key={`column-${index}`} style={{width: widths[index]}}>
              {row[option]}
            </span>
          ))}
        </div>
      ))}
      {((!options && data) || []).map((row, index) => (
        <div
          className={`subDataTable__item ${variant ? variant : ''} ${
            half ? 'half' : ''
          } ${activeIndex === index ? 'active' : ''}`}
          key={`item-${row.item}-${index}`}
          onClick={() => handleActive(index)}
        >
          <span>{!!firstOption ? row[firstOption] : row.item}</span>
          <span className={`text-capitalize`} style={{marginRight: 10}}>
            {!!secondOption ? row[secondOption] : row.amount}
          </span>
        </div>
      ))}
      {(emptyRows || []).map((row, index) => (
        <div className={`subDataTable__item`} key={`item-${row}-${index}`} />
      ))}
    </div>
  );
};

export default SubDataTable;

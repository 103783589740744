import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import {
  getFollowUp,
  getTimeSlots,
  get_plan_patient,
  rescheduleProcedure,
  saveProcedureSchedule,
  setGlobalLoading,
  setProcedureData,
} from "../../../store/actions";
import { ExpandSVG, LeftCharvenSVG } from "../icons/SVGIcon";

const { Option } = Select;

const getValue = (options, target, key) => {
  let opt = [];
  if (options && key && target) {
    opt = (options || []).find((option) => option[key].toString() === target.toString());
  }
  if (opt) {
    return opt?.value;
  }
  return "";
};

let timer = null;

const AngioComponent = ({
  procedure_id_row,
  checkUltra,
  nextSave,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  changeSetProcessHandle,
  currentPlanId,
  activeStatus,
  is_locked,
}) => {
  const locations = useSelector((state) => state.procedure.locations);
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const providers = useSelector((state) => state.procedure.providers);
  const clincLocations = useSelector((state) => state.procedure.clincLocations);
  const followupProviders = useSelector((state) => state.procedure.followupProviders);
  const data = useSelector((state) => state.patientSchedule);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const planDetails = useSelector((state) => state.procedure.planDetails);
  const timeSlotsCheck = useSelector((state) => state.procedure.timeSlots);
  const followupSlotsCheck = useSelector((state) => state.procedure.followupSlots);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const loadingSecond = useSelector((state) => state.procedure.loadingFollowUp);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);
  const secondClinic = useSelector((state) => state.procedure.followSlot);
  //
  const [appointment_date_check, setAppointment_date] = useState(null);
  const [followup_appointment_check, setFollowup_appointment] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [lock, setLock] = useState(false);
  const [followupSlots, setFollowupSlots] = useState([]);
  //
  const [checkDetail, setCheckDetail] = useState({});
  const [endarterectomy, setEndarterectomy] = useState([]);
  const [follow_Up, setFollow_Up] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setFollowupSlots(followupSlotsCheck);
    fetchFollowup(followupSlotsCheck);
  }, [followupSlotsCheck, typePlanId]);

  useEffect(() => {
    setTimeSlots(timeSlotsCheck);
    fetchTime(timeSlotsCheck);
  }, [timeSlotsCheck, typePlanId]);

  const removeState = () => {
    dispatch(setProcedureData("timeSlot", -1));
    setTimeOption({
      location: undefined,
      provider: undefined,
      period: undefined,
      prev_date: undefined,
    });
    setFollowOption({
      location: undefined,
      provider: undefined,
      period: undefined,
      prev_date: undefined,
    });
    dispatch(setProcedureData("followSlot", -1));
    setAppointment_date(null);
    setFollowup_appointment(null);
  };

  const fetchFrist = async () => {
    removeState();
    await setCheckDetail({});
    if (plans && plans.length > 0) {
      const check = plans.find((r) => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter((r) => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0]);
        } else {
          await setCheckDetail(null);
        }
      }
    }
  };

  const fetchTime = async (timeSlotsCheck) => {
    (timeSlotsCheck || []).map(async (slot, index) => {
      if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.angio?.appointment_date).format("MM/DD/YYYY")) {
        dispatch(setProcedureData("timeSlot", index));
      } else {
        dispatch(setProcedureData("timeSlot", -1));
      }
    });
  };

  const fetchFollowup = async (followupSlotsCheck) => {
    (followupSlotsCheck || []).map((slot, index) => {
      if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.followup_appointment?.appointment_date).format("MM/DD/YYYY")) {
        dispatch(setProcedureData("followSlot", index));
      } else {
        dispatch(setProcedureData("followSlot", -1));
      }
    });
  };

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      if (checkDetail?.angio?.appointment_date) {
        const value = moment(checkDetail?.angio?.appointment_date).format("MM/DD/YYYY");
        setAppointment_date(value || null);
      } else {
        setAppointment_date(null);
      }
      if (checkDetail?.followup_appointment?.appointment_date) {
        const value = moment(checkDetail?.followup_appointment?.appointment_date).format("MM/DD/YYYY");
        setFollowup_appointment(value || null);
      } else {
        setFollowup_appointment(null);
      }
    } else {
      setAppointment_date(null);
      setFollowup_appointment(null);
      setFollowupSlots([]);
      dispatch(setProcedureData("timeSlot", -1));
      dispatch(setProcedureData("followSlot", -1));
    }
  }, [checkDetail, typePlanId, procedure_id_row, data]);

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      const newOption = {
        location: "",
        provider: "",
        period: checkDetail?.angio?.appointment_date ? moment(checkDetail?.angio?.appointment_date).format("MM/DD/YYYY") : "",
        prev_date: "",
      };
      dispatch(getFollowUp(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, newOption, false, false));
    }
  }, [checkDetail]);

  useEffect(() => {
    fetchFrist();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });
  const [followOption, setFollowOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (endarterectomy && !endarterectomy.some((r) => r.start === checkTimeSlots.start)) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }

    if (type === "follow") {
      if (followupSlots && followupSlots[0]) {
        const checkFollowupSlots = followupSlots[0];
        if (checkFollowupSlots) {
          if (follow_Up && !follow_Up.some((r) => r.start === checkFollowupSlots.start)) {
            setFollow_Up([...follow_Up, checkFollowupSlots]);
          }
        }
      }
    }
  };

  const callApiDetail = async () => {
    await dispatch(setGlobalLoading(true));
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (procedure_id_row) {
        await dispatch(rescheduleProcedure(current_patient_id, procedure_id_row, {}, false));
        await dispatch(setGlobalLoading(false));
      }
    }, 100);
  };

  useEffect(() => {
    if (procedure_id_row && procedure_id_row != 0) {
      callApiDetail();
    }
  }, [procedure_id_row]);

  const title = useMemo(() => {
    if (planDetails && planDetails.procedure) {
      return planDetails.procedure;
    } else {
      if (planDetails && planDetails.ultrasound && planDetails.ultrasound.us_test) {
        return planDetails.ultrasound.us_test?.name;
      }
    }
  }, [planDetails]);

  const setFiirst = (index) => {
    if (!is_locked) {
      dispatch(setProcedureData("timeSlot", index));
    }
  };
  const setSecond = async (index) => {
    if (!is_locked) {
      await dispatch(setProcedureData("followSlot", index));
    }
  };

  const onSaveSchedule = async (firstClinicCheck, timeSlotsCheck, secondClinicCheck, followupSlotsCheck) => {
    const timeSlot =
      timeSlotsCheck && firstClinicCheck && firstClinicCheck > -1
        ? timeSlotsCheck[firstClinicCheck]
        : timeSlotsCheck && timeSlotsCheck[0]
        ? timeSlotsCheck[0]
        : "";
    const followup =
      followupSlotsCheck && secondClinicCheck && secondClinicCheck > -1
        ? followupSlotsCheck[secondClinicCheck]
        : followupSlotsCheck && followupSlotsCheck[0]
        ? followupSlotsCheck[0]
        : "";
    if (timeSlot && followup) {
      dispatch(setGlobalLoading(true));
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("hh:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.provider,
        followup_date: moment(followup.start).format("MM/DD/YYYY"),
        followup_time: moment(followup.start).format("hh:mm A"),
        followup_location: followup.location,
        followup_provider: followup.provider,
        followup_start_slot: followup?.start_slot,
        followup_doctor: followup.doctor,
        doc_slot: followup.start_slot,
        plan_id: currentPlanId,
      };
      const loadingSuccess = await dispatch(setProcedureData("loadingSchedule", true));
      const SaveSuccess = await dispatch(saveProcedureSchedule(procedure_id_row, params));
      const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      if (loadingSuccess && SaveSuccess) {
        if (changeSetProcessHandle) {
          changeSetProcessHandle(activeRow, typePlanId, params, "Angio");
          if (nextSave) {
            nextSave(loadList);
            setFiirst(-1);
            setSecond(-1);
            setAppointment_date(null);
            setFollowup_appointment(null);
          }
        }
      }
      dispatch(setGlobalLoading(false));
    }
  };

  const onLoadCheck = async (firstClinicCheck, timeSlotsCheck, secondClinicCheck, followupSlotsCheck) => {
    if (firstClinicCheck != -1 && secondClinicCheck != -1) {
      setLock(false);
    } else {
      setLock(true);
    }
  };

  useEffect(() => {
    onLoadCheck(firstClinic, timeSlots, secondClinic, followupSlots);
  }, [firstClinic, secondClinic, timeSlots, followupSlots]);

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
        ? moment(timeSlots[0].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...timeOption, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(current_patient_id, procedure_id_row, newOption));
    setFiirst(-1);
  };

  const handleChangeFollow = (type) => (value) => {
    const prev_date =
      secondClinic !== -1
        ? moment(followupSlots[secondClinic].start).format("MM/DD/YYYY")
        : followupSlots[0]
        ? moment(followupSlots[0].start).format("MM/DD/YYYY")
        : timeSlots[firstClinic]
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...followOption, prev_date, [type]: value };
    setFollowOption(newOption);
    dispatch(setProcedureData("loadingFollowUp", true));
    dispatch(getFollowUp(current_patient_id, procedure_id_row, newOption));
    setSecond(-1);
  };

  const selectTimeSlot = (row, index) => {
    if (!is_locked) {
      setFollow_Up([]);
      setFiirst(index);
      setSecond(-1);
      setFollowup_appointment(null);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(
        getFollowUp(
          current_patient_id,
          procedure_id_row,
          {
            ...followOption,
            prev_date: moment(row.start).format("MM/DD/YYYY"),
          },
          false,
          "true"
        )
      );
    }
  };

  const handleTimeSlot = (value, index) => {
    const mixSlot = [...timeSlots];
    mixSlot[index]["start_slot"] = value;
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    if (timeSlots[index].start) {
      const value = moment(timeSlots[index].start).format("MM/DD/YYYY");
      setAppointment_date(value || "");
    }
    setTimeSlots(mixSlot);
  };

  const handleTimeSlotFollow = (value, index) => {
    const mixSlot = [...followupSlots];
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    mixSlot[index]["start_slot"] = value;
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    if (followupSlots[index].start) {
      const value = moment(followupSlots[index].start).format("MM/DD/YYYY");
      setFollowup_appointment(value || "");
    }
    setFollowupSlots(mixSlot);
  };

  const onHandleClick = () => {
    onSaveSchedule(firstClinic, timeSlots, secondClinic, followupSlots);
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, option, false, false));
      setFiirst(-1);
    }
    if (followupSlots && followupSlots.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(followupSlots[followupSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, newOption, false, false));
      setSecond(-1);
    }
  };

  const previous = (type) => {
    if (endarterectomy && endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(endarterectomy[endarterectomy.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, option));
      setFiirst(-1);
      const stateCheck = endarterectomy;
      stateCheck.splice(endarterectomy.length - 1, 1);
      setEndarterectomy(stateCheck);
    }
    if (follow_Up && follow_Up.length > 0 && type === "follow") {
      const newOption = {
        ...followOption,
        prev_date: moment(follow_Up[follow_Up.length - 1].start).format("MM/DD/YYYY"),
      };
      setFollowOption(newOption);
      dispatch(setProcedureData("loadingFollowUp", true));
      dispatch(getFollowUp(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, newOption));
      setSecond(-1);
      const stateCheck = follow_Up;
      if (stateCheck) {
        stateCheck.splice(follow_Up.length - 1, 1);
        setFollow_Up(stateCheck);
      }
    }
  };

  const checkActive = (slot) => {
    if (appointment_date_check && moment(slot.start).format("MM/DD/YYYY") === moment(appointment_date_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };

  const checkFollowActive = (slot) => {
    if (followup_appointment_check && moment(slot.start).format("MM/DD/YYYY") === moment(followup_appointment_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };
  return (
    <>
      <div className="resourceContent">
        <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
          <b>{`${titlePlan}`}</b>
        </div>

        <div style={{ position: "relative", marginBottom: "4px" }}>
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                disabled={is_locked}
                placeholder="Locations"
                value={timeOption.location}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("location")}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              Date
              <div>
                <button
                  disabled={loading || loadingFirst || is_locked}
                  className="more-dates backward"
                  style={{
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    previous("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
                <button
                  disabled={loading || loadingFirst || is_locked}
                  className="more-dates forward"
                  style={{
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    handleMoreDates("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
              </div>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                disabled={is_locked}
                placeholder="Time"
                value={timeOption.period}
                suffixIcon={<span />}
                onChange={handleChangeSlots("period")}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </div>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                disabled={is_locked}
                placeholder="Provider"
                value={timeOption.provider}
                suffixIcon={<span />}
                allowClear={false}
                mod="multiple"
                onChange={handleChangeSlots("provider")}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
              >
                {(providers || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {!loadingFirst &&
            (timeSlots || []).map((slot, index) => (
              <React.Fragment key={`time-slots-${index}`}>
                <div
                  className={`resourceInfo between t-row 
                ${checkActive(slot) === "active" && firstClinic !== index && firstClinic == -1 ? "active" : ""} ${
                    firstClinic === index ? "active" : ""
                  }`}
                  style={{
                    alignItems: "center",
                    borderLeft: "1px solid #dfe3e6",
                    borderRight: "1px solid #dfe3e6",
                  }}
                  onClick={() => selectTimeSlot(slot, index)}
                >
                  <div className="w-25">{getValue(locations, slot.location, "id")}</div>
                  <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                  <div
                    className="w-25 time-select"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: 0,
                      marginTop: 0,
                    }}
                  >
                    <Select
                      disabled={is_locked}
                      placeholder="Timer check all"
                      value={slot?.start_slot}
                      suffixIcon={<ExpandSVG />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleTimeSlot(value, index);
                      }}
                    >
                      {(slot?.slots || []).map((time, index) => (
                        <Option key={`time-${time}-${index}`} value={time}>
                          {moment()
                            .hours(8)
                            .minutes(0)
                            .add(time * 15, "minutes")
                            .format("hh:mm A")}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="w-25">{getValue(providers, slot.provider, "id")}</div>
                </div>
                <hr className="small-block" />
              </React.Fragment>
            ))}
          {!loadingFirst && Array.isArray(timeSlots) && timeSlots.length == 0 ? (
            <div style={{ textAlign: "center", marginTop: 10 }}>There are no available appointments for time period selected</div>
          ) : null}
          {loadingFirst && (
            <React.Fragment key={`time-slots-1`}>
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
              <hr className="small-block" />
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
              <hr className="small-block" />
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
            </React.Fragment>
          )}
        </div>

        <div style={{ marginTop: "1.5rem" }}>
          <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
            <b>{`${titlePlan} Follow-Up`}</b>
          </div>

          <div style={{ position: "relative", marginBottom: "4px" }}>
            <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  value={followOption.location}
                  placeholder="Location"
                  suffixIcon={<span />}
                  allowClear={false}
                  onChange={handleChangeFollow("location")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(clincLocations || []).map((loc, index) => (
                    <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                      {loc?.value}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                Date
                <div>
                  <button
                    disabled={loading || loadingSecond || is_locked}
                    className="more-dates backward"
                    style={{
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      previous("follow");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                  <button
                    disabled={loading || loadingSecond || is_locked}
                    className="more-dates forward"
                    style={{
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      handleMoreDates("follow");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                </div>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  value={followOption.period}
                  placeholder="Time"
                  onChange={handleChangeFollow("period")}
                  suffixIcon={<span />}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Provider"
                  value={followOption.provider}
                  suffixIcon={<span />}
                  allowClear={false}
                  onChange={handleChangeFollow("provider")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(followupProviders || []).map((provider, index) => (
                    <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                      {provider?.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {!loadingSecond &&
              (followupSlots || []).map((slot, index) => (
                <React.Fragment key={`time-slots-${index}`}>
                  <div
                    className={`resourceInfo between t-row 
                  ${checkFollowActive(slot) === "active" && secondClinic !== index && secondClinic == -1 ? "active" : ""} ${
                      secondClinic === index ? "active" : ""
                    }`}
                    style={{
                      alignItems: "center",
                      borderLeft: "1px solid #dfe3e6",
                      borderRight: "1px solid #dfe3e6",
                    }}
                    onClick={() => setSecond(index)}
                  >
                    <div className="w-25">{getValue(clincLocations, slot.location, "id")}</div>
                    <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                    <div
                      className="w-25 time-select"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: 0,
                        marginTop: 0,
                      }}
                    >
                      <Select
                        disabled={is_locked}
                        placeholder="Timer check all"
                        value={slot?.start_slot}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        onChange={(value) => {
                          handleTimeSlotFollow(value, index);
                        }}
                      >
                        {(slot?.slots || []).map((time, index) => (
                          <Option key={`time-${time}-${index}`} value={time}>
                            {moment()
                              .hours(8)
                              .minutes(0)
                              .add(time * 15, "minutes")
                              .format("hh:mm A")}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="w-25">{getValue(followupProviders, slot.doctor, "id")}</div>
                  </div>
                  <hr className="small-block" />
                </React.Fragment>
              ))}
          </div>
          {loadingSecond && (
            <React.Fragment key={`time-slots-2`}>
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
              <hr className="small-block" />
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
              <hr className="small-block" />
              <div
                className={`resourceInfo between t-row`}
                style={{
                  alignItems: "center",
                  borderLeft: "1px solid #dfe3e6",
                  borderRight: "1px solid #dfe3e6",
                }}
              >
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
                <div className="w-25"></div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {activeStatus || !lock || (firstClinic != -1 && secondClinic != -1) ? (
        <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
          <button
            disabled={is_locked}
            className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
            onClick={() => {
              onHandleClick();
            }}
          >
            Continue
          </button>
        </div>
      ) : null}
    </>
  );
};

export default AngioComponent;

import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/DataTable";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { getCapitalizeString, useWindowSize } from "../../../common/utils";
import { setLocal } from "../../../helpers/Local";
import {
  filterLocations,
  filterProviders, getCallback, patient_insurances_id_insurances, selectPatient, setGlobalLoading, setStepData
} from "../../../store/actions";

let currentDate = moment().format('l');
let currentHours = moment().format('h');

const CallbackTable = React.memo(
  ({
    title,
    search,
    selectedType,
    handleSidebar,
    handleTableSelect,
    getCallBackCount,
  }) => {
    const ref = useRef(null);
    const [activeIndex, setRow] = useState(-1);
    const [offset, setOffset] = useState(0);
    const [initialSize, setSize] = useState({
      width: undefined,
      height: undefined,
    });

    const data = useSelector((state) => state.patientCallback.callback);

    const dispatch = useDispatch();

    useEffect(() => {
      if (ref) {
        const initialSize = {
          width: ref.current.clientWidth,
          height: ref.current.clientHeight,
        };
        setSize(initialSize);
      }
    }, [ref]);

    useEffect(() => {
      if (!data) {
        dispatch(setStepData('loadingCallback', true));
        dispatch(getCallback({}));
      }
    }, [data]);

    const filterSuggestion = (suggestions, userInput) => {
      let count = 0;

      if (!userInput) {
        suggestions.map((obj) => {
          const callAttemptDate = moment(obj?.message_datetime).format('l');

          if (
            callAttemptDate === currentDate &&
            currentHours > 12 &&
            currentHours < 24
          ) {
            count++;
          }
        });
        getCallBackCount(count);
        return suggestions;
      }
      let filteredSuggestions = [];

      filteredSuggestions = suggestions.filter(
        (suggestion) =>
          (suggestion?.first_name &&
            suggestion?.first_name
              .toLowerCase()
              .indexOf(userInput.split(' ').join('').toLowerCase()) > -1) ||
          (suggestion?.last_name &&
            suggestion?.last_name
              .toLowerCase()
              .indexOf(userInput.split(' ').join('').toLowerCase()) > -1) ||
          (suggestion?.first_name + suggestion?.last_name)
            .toLowerCase()
            .indexOf(userInput.split(' ').join('').toLowerCase()) > -1 ||
          (suggestion?.last_name + suggestion?.first_name)
            .toLowerCase()
            .indexOf(userInput.split(' ').join('').toLowerCase()) > -1,
      );

      suggestions.map((obj) => {
        const callAttemptDate = new Date(obj?.message_datetime).getDate();

        if (
          callAttemptDate === currentDate &&
          currentHours > 12 &&
          currentHours < 24
        ) {
          count++;
        }
      });
      getCallBackCount(count);

      return filteredSuggestions;
    };

    const tableRows = useMemo(() => {
      if (data) {
        setOffset(0);
        return filterSuggestion(data, search);
      }
      return [];
    }, [data, search]);

    const rowData = useMemo(() => {
      if (!tableRows || !tableRows.length) {
        return [];
      }
      if (tableRows && tableRows.length && offset <= tableRows.length) {
        const count = Math.min(30, tableRows.length - offset);
        return tableRows.slice(0, offset + count);
      }
    }, [offset, tableRows]);

    let emptyRows = [];
    const size = useWindowSize(ref);

    const handleRow = async (index) => {
      dispatch(setGlobalLoading(true));
      setRow(index);
      const patient_id = rowData[index].patient_id || rowData[index].id;
      await setLocal("current_patient_id", patient_id);
      dispatch(setStepData("loadingResource", true));
      await dispatch(selectPatient(patient_id));
      dispatch(filterLocations("", "clinic"));
      dispatch(filterProviders(patient_id));
      dispatch(patient_insurances_id_insurances(patient_id));
      handleTableSelect('call-back', index);
      dispatch(setGlobalLoading(false));
      handleSidebar();
    };

    let rowCount = 0;
    if (!size.height && !initialSize.height) {
      rowCount = tableRows.length;
    }
    if (size.height) {
      rowCount = Math.round(size.height / (16 * 2.5));
    } else {
      rowCount = Math.round(initialSize.height / (16 * 2.5));
    }

    if (rowCount > tableRows.length) {
      emptyRows = new Array(rowCount + 1 - tableRows.length).fill({});
    }

    const onScroll = (values) => {
      if (
        values.scrollTop > rowData?.length * 30 &&
        offset < tableRows.length
      ) {
        const count = Math.min(30, tableRows.length - offset);
        setOffset(offset + count);
      }
    };

    return (
      <div ref={ref} className="tableContainer">
        <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
          <div className="tableList">
            <DataTable
              isXHidden={true}
              title="new_patient_call_back"
              isNormal={true}
              labels={['Patient Name', 'Problem', 'Referral Date']}
              widths={['34%', '38%', '28%']}
              defaultActive={-1}
              disableDelete={true}
              columns={[
                { sortable: false, key: 'name' },
                { sortable: false, key: 'referral_condition' },
                { sortable: false, key: 'referral_date' },
              ]}
              handleClickRow={(row, index) => {
                handleRow(index);
              }}
              rowData={(rowData || []).map((row, index) => {
                return {
                  name: (
                    <div
                      className="iconField td with-icon"
                      style={{
                        display: 'flex',
                        paddingLeft: '0.25rem',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <Icon
                        type="phone"
                        theme="twoTone"
                        twoToneColor={
                          row?.has_intraday_call_attempt === 1 ?
                            '#198038' :
                            '#F1C21B'
                        }
                      />
                      <p
                        style={{ paddingLeft: '0.75rem' }}
                      >{`${getCapitalizeString(
                        row?.first_name,
                      )} ${getCapitalizeString(row?.last_name)}`}</p>
                    </div>
                  ),
                  referral_condition: row?.referral_condition,
                  referral_date:
                    row?.referral_date &&
                    moment(row?.referral_date).format('MM/DD/YYYY'),
                };
              })}
            />
          </div>
        </ScrollWrapper>
      </div>
    );
  },
);

export default CallbackTable;

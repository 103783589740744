import {createReducer} from 'redux-create-reducer';
import AT from '../actionTypes';

const initialState = {
  signatureImage: undefined,
  toaster: false,
  loadingEmployeeDocument: false,
  detailEmployeeDocuments: {},
  employeeDocuments: [],
  print_fax: {},
  loading_job_status:false,
  data_job_status:null,
};

const GET_EMPLOYEE_DOCUMENTS_STARTED = `${AT.GET_EMPLOYEE_DOCUMENTS}_STARTED`;
const GET_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.GET_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;

const GET_DETAIL_EMPLOYEE_DOCUMENTS_STARTED = `${AT.GET_DETAIL_EMPLOYEE_DOCUMENTS}_STARTED`;
const GET_DETAIL_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.GET_DETAIL_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;

const DELETE_EMPLOYEE_DOCUMENTS_STARTED = `${AT.DELETE_EMPLOYEE_DOCUMENTS}_STARTED`;
const DELETE_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.DELETE_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;

const SEND_FAX_EMPLOYEE_DOCUMENTS_STARTED = `${AT.SEND_FAX_EMPLOYEE_DOCUMENTS}_STARTED`;
const SEND_FAX_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.SEND_FAX_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;

const EMAIL_FAX_EMPLOYEE_DOCUMENTS_STARTED = `${AT.EMAIL_FAX_EMPLOYEE_DOCUMENTS}_STARTED`;
const EMAIL_FAX_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.EMAIL_FAX_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;

const PRINT_FAX_EMPLOYEE_DOCUMENTS_STARTED = `${AT.PRINT_FAX_EMPLOYEE_DOCUMENTS}_STARTED`;
const PRINT_FAX_EMPLOYEE_DOCUMENTS_SUCCESS = `${AT.PRINT_FAX_EMPLOYEE_DOCUMENTS}_SUCCEEDED`;
const SET_DEFAULT_TOAST_SUCCEEDED = `${AT.SET_DEFAULT_TOAST}_SUCCEEDED`;
const RESET_FAX_SCAN_DOCUMENT_LOG_SUCCEEDED = `${AT.RESET_FAX_SCAN_DOCUMENT_LOG}_SUCCEEDED`;

const GET_JOB_STATUS_STARTED = `${AT.GET_JOB_STATUS}_STARTED`;
const GET_JOB_STATUS_SUCCESS = `${AT.GET_JOB_STATUS}_SUCCEEDED`;

export default createReducer(initialState, {
  [GET_JOB_STATUS_STARTED](state) {
    return Object.assign({}, state, {
      loading_job_status: true,
    });
  },
  [GET_JOB_STATUS_SUCCESS](state, action) {
    const data_job_status = action?.payload;
    return Object.assign({}, state, {
      loading_job_status: false,
      data_job_status,
    });
  },
  [RESET_FAX_SCAN_DOCUMENT_LOG_SUCCEEDED](state) {
    return Object.assign({}, state, {
      print_fax: {},
      loadingEmployeeDocument: false,
      isToast: false,
    });
  },
  [SET_DEFAULT_TOAST_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: false,
    });
  },
  [GET_DETAIL_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      detailEmployeeDocuments: {},
    });
  },
  [GET_DETAIL_EMPLOYEE_DOCUMENTS_SUCCESS](state, action) {
    const detailEmployeeDocuments = action?.payload;
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      detailEmployeeDocuments,
    });
  },
  [PRINT_FAX_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      toaster: false,
      print_fax: {},
    });
  },
  [PRINT_FAX_EMPLOYEE_DOCUMENTS_SUCCESS](state, action) {
    const print_fax = action?.payload;
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: true,
      print_fax,
    });
  },
  [EMAIL_FAX_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      toaster: false,
    });
  },
  [EMAIL_FAX_EMPLOYEE_DOCUMENTS_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: true,
    });
  },
  [SEND_FAX_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      toaster: false,
    });
  },
  [SEND_FAX_EMPLOYEE_DOCUMENTS_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: true,
    });
  },
  [DELETE_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      toaster: false,
    });
  },
  [DELETE_EMPLOYEE_DOCUMENTS_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: true,
    });
  },
  [DELETE_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      toaster: false,
    });
  },
  [DELETE_EMPLOYEE_DOCUMENTS_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      toaster: true,
    });
  },
  [GET_EMPLOYEE_DOCUMENTS_STARTED](state) {
    return Object.assign({}, state, {
      loadingEmployeeDocument: true,
      employeeDocuments: [],
    });
  },
  [GET_EMPLOYEE_DOCUMENTS_SUCCESS](state, action) {
    const employeeDocuments = action?.payload;
    return Object.assign({}, state, {
      loadingEmployeeDocument: false,
      employeeDocuments,
    });
  },
  [`${AT.ADMIN_USER_GET_SIGNATURE_IMAGE}_SUCCEEDED`](state, action) {
    const signatureImage = action?.payload?.signature_image ?? null;
    return {
      ...state,
      signatureImage,
    };
  },
});

import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { exportPCM } from "../../../../store/actions";

const ProviderReportSidebar = React.forwardRef(({ resource, userId, startDate, endDate, isShow, handleCloseSidebar, titleName }, ref) => {
  const dispatch = useDispatch();
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [dataDetail, setDataDetail] = useState({});

  const sendExport = async () => {
    if (resource) {
      const result = await dispatch(exportPCM(userId, startDate, endDate));
      window.open(result.url, "_self");
    }
  };

  useEffect(() => {
    if (resource) {
      setDataDetail(resource);
    }
  }, [resource]);

  if (!resource || !Object.keys(resource).length) {
    return null;
  }

  return (
    <Drawer
      title={startDate && endDate ? `${titleName} (${startDate} - ${endDate})` : titleName || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"Total Payment"} value={dataDetail.total_pay} />
            </div>
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"Contribution Margin"} value={dataDetail.total_cm} />
            </div>
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"Practice Margin"} value={dataDetail.practice_margin} />
            </div>
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"% CM"} value={dataDetail.percent_cm} unit={"%"} />
            </div>
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"% PM"} value={dataDetail.practice_margin_percent} unit={"%"} />
            </div>
            <div className="resourceInfo d-flex">
              <LoginFormInput label={"Total Paid Out"} value={""} prefix={"$"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput label="Accounts Receivables" value={dataDetail.account_receivables} />
            </div>
            <hr />
            <div
              className="actionContent"
              style={{
                marginTop: "2rem",
                paddingRight: "1rem",
                flexWrap: "wrap",
              }}
            >
              <button onClick={sendExport} className="common-btn" style={{ marginTop: "0.5rem" }}>
                Export to Excel
              </button>
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ProviderReportSidebar;

import { Icon } from "antd";
import React from "react";

export default function TableCustom({ dataCompliance, dataLabel }) {
  return (
    <div className="content" style={{ border: "1px solid #e6e5e8", width: "100%" }}>
      {/* header */}
      <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: "1px solid #e6e5e8" }}>
        {(dataLabel || []).map((rowLabel, indexLabel) => {
          return (
            <div
              key={`label-${indexLabel}`}
              style={{
                width: rowLabel?.width || 0,
                borderLeft: indexLabel > 0 ? "1px solid #e6e5e8" : "",
                minHeight: 42,
                padding: 5,
                paddingLeft: "0.75rem",
                backgroundColor: "#f5f8fd",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontWeight: 800,
              }}
            >
              {rowLabel?.["name"] || ""}
            </div>
          );
        })}
      </div>
      {/* content */}
      <div style={{ width: "100%" }}>
        {(dataCompliance || []).map((rowTable, indexTable) => (
          <div key={`content-${indexTable}`} style={{ width: "100%" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", borderBottom: indexTable > 0 ? "1px solid #e6e5e8" : "none" }}>
              {/* map col  */}
              {Object.keys(rowTable).map((rowKey, indexKey) => {
                return (
                  <div
                    key={`item-${indexKey}`}
                    style={{
                      width: dataLabel?.[indexKey]?.["width"] || 0,
                      minHeight: 42,
                      padding: 5,
                      paddingLeft: "0.75rem",
                      backgroundColor: "#fff",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    {rowTable?.[rowKey] && typeof rowTable?.[rowKey] === "string" && rowKey != "compliance" ? (
                      rowTable?.[rowKey].split("\n").map((i, key) => {
                        // make space multiline have \n
                        return (
                          <p style={{ width: "100%", minHeight: 12 }} key={`multiline-${key}`}>
                            {i.includes("<b>") ? <b>{i.replace(/<\/?b>/g, "")}</b> : `${i.charAt(0).toUpperCase() + i.slice(1)}`}
                          </p>
                        );
                      })
                    ) : rowTable?.[rowKey] && typeof rowTable?.[rowKey] === "string" && rowKey == "compliance" ? (
                      rowTable?.[rowKey].split("\n").map((i, key) => {
                        return (
                          <div style={{ width: "100%", minHeight: 12, display: "flex", gap: 10, marginBottom: 4 }} key={`multiline-${key}`}>
                            <div style={{ marginTop: 3 }}>
                              <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                            </div>
                            <p> {i.includes("<b>") ? <b>{i.replace(/<\/?b>/g, "")}</b> : `${i.charAt(0).toUpperCase() + i.slice(1)}`}</p>
                          </div>
                        );
                      })
                    ) : rowKey == "compliance" && Array.isArray(rowTable?.[rowKey]) && rowTable?.[rowKey].length > 0 ? (
                      rowTable?.[rowKey].map((i, key) => {
                        return (
                          <div style={{ width: "100%", minHeight: 12, display: "flex", gap: 10, marginBottom: 4 }} key={`multiline-${key}`}>
                            <div style={{ marginTop: 3 }}>
                              <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                            </div>
                            <p>{i}</p>
                          </div>
                        );
                      })
                    ) : rowTable?.[rowKey] && rowTable?.[rowKey].length > 0 ? (
                      rowTable?.[rowKey]
                    ) : (
                      <div style={{ width: "100%", minHeight: 12, display: "flex", gap: 10, marginBottom: 4 }}>
                        <div style={{ marginTop: 3 }}>
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                        </div>
                        <p>Compliant</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const adminReferralConditionList = createActionThunk(
    AT.ADMIN_REFERRAL_CONDITION_LIST,
    () => API.getReferralCondition(),
);

export const adminReferralConditionGet = createActionThunk(
    AT.ADMIN_REFERRAL_CONDITION_GET,
    (id) => API.getConditionDetailData(id),
);

export const adminReferralConditionPost = createActionThunk(
    AT.ADMIN_REFERRAL_CONDITION_POST,
    (data) => API.postReferralCondition(data),
);

export const adminReferralConditionUpdate = createActionThunk(
    AT.ADMIN_REFERRAL_CONDITION_UPDATE,
    (id, data) => API.updateReferralCondition(id, data),
);

export const adminReferralConditionClearStatusMessage = () => (dispatch) =>
  dispatch({type: AT.ADMIN_REFERRAL_CONDITION_CLEAR_STATUS_MESSAGE});

export const adminReferralConditionOpenNewPage = () => (dispatch) =>
  dispatch({type: AT.ADMIN_REFERRAL_CONDITION_OPEN_NEW_PAGE});

export const setAdminReferralCondition = createActionThunk(
    AT.SET_ADMIN_REFERRAL_CONDITION,
    (type, data) => ({[type]: data}),
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Icon} from 'antd';
import SubSideNav from '../../common/components/SubSideNav';
import MainContainer from './InventoryWorkflow';
import {collapseSideNav} from '../../store/actions';

class InventoryWorkflow extends Component {
  sideNavLinks = [
    {
      link: '/inventory-workflow/inventory',
      label: 'Inventory Module',
    },
  ];

  state = {
    isSideNav: true,
  };

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.closeSideNav();
    }
  }

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | Inventory Workflow</title>
        <meta name="title" content="FAACT Vascular | Inventory Workflow" />
      </Helmet>
    );
  }

  openSideNav = () => {
    this.setState({isSideNav: true});
    this.props.collapseSideNav(false);
  };

  closeSideNav = () => {
    this.setState({isSideNav: false});
    this.props.collapseSideNav(true);
  };

  getTitle = () => (
    <div className="sub-title">
      <Icon type="apartment" />
      Inventory Workflow
    </div>
  );

  onLinkClick = (link) => {
    if (this.sideNavLinks.find(v=>v.link === link)) {
      this.closeSideNav();
    }
  }; 


  render() {
    const {location} = this.props;
    const {isSideNav} = this.state;
    return (
      <div className="clinicWorkflow">
        {this.renderMetadata()}
        <SubSideNav
          subMenu={this.sideNavLinks}
          title={this.getTitle()}
          isSideNav={isSideNav}
          openSideNav={this.openSideNav}
          closeSideNav={this.closeSideNav}
          onLinkClick={this.onLinkClick}
        />
        <MainContainer location={location} isSideNav={isSideNav} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  collapseSideNav,
};

export default connect(null, mapDispatchToProps)(InventoryWorkflow);

import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const adminSettingLogoUpload = createActionThunk(
    AT.ADMIN_SETTING_LOGO_UPLOAD,
    (payload) => API.adminSettingLogoUpload(payload),
);

export const adminSettingGetConfig = createActionThunk(
    AT.ADMIN_SETTING_GET_CONFIG,
    () => API.adminSettingGetConfig(),
);

export const adminSettingSaveOCRInfo = createActionThunk(
    AT.ADMIN_SETTING_SAVE_OCR_INFO,
    (payload) => API.adminSettingSaveOCRInfo(payload),
);

export const getPrimaryProviderDetails = createActionThunk(
    AT.GET_PRIMARY_PROVIDERS_DETAIL,
    (query) => API.getPrimaryProviderDetails(query),
);

export const updatePrimaryProviderDetail = createActionThunk(
    AT.UPDATE_PRIMARY_PROVIDER_DETAIL,
    (id, payload) => API.updatePrimaryProviderDetail(id, payload),
);

export const createNewPrivateProvider = createActionThunk(
    AT.CREATE_NEW_PRIMARY_PROVIDER,
    (payload) => API.createNewPrivateProvider(payload),
);

export const adminSettingSaveOrgDetail = createActionThunk(
    AT.ADMIN_SETTING_SAVE_ORG_DETAIL,
    (payload) => API.adminSettingSaveOrgDetail(payload),
);

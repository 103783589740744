import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import CardBlock from "../../common/components/Card";
import { FemaleSVG, MaleSVG } from "../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../common/components/ScrollBar";
import SubTopBar from "../../common/components/SubTopBar";
import { NewPatientProcessingTabHeaders } from "../../common/tabs";
import SnapshotSideBar from "../../common/views/PatientSnapshot/SnapSidebar";
import {
  changeTab,
  getCurrentUserInfo,
  getLocations,
  getOnlyProviders,
  getPharmacies,
  getReferralConditions,
  getReferredProviders,
  insurance_types,
  patient_insurances_id_insurances,
  search_insurance_companies,
  selectPatient,
  setGlobalLoading,
  setPatientSnapshotData,
  setStepData,
} from "../../store/actions";
import CalendarContainer from "./CalendarContainer";
import CallbackFilter from "./components/CallbackFilter";
import CallbackTable from "./components/CallBackTable";
import NewPatientSideBar from "./components/NewPatientSideBar";
import PendingFilter from "./components/PendingFilter";
import PendingTable from "./components/PendingTable";
import RegistrationAnalytics from "./RegistrationAnalytics";
import "./style.scss";

class NewPatientBinder extends Component {
  state = {
    isAdmin: false,
    isSidebar: false,
    selectedType: "call-back",
    active: false,
    activeID: null,
    selected: -1,
    stepProps: 0,
    settup: -1,
    callbackCount: 0,
    pendingCount: 0,
    pendingHasDocsCount: 0,
    search: {
      callback: "",
      pending: "",
    },
    activeTab: 0,
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  async componentDidMount() {
    const { providers, getOnlyProviders, setStepData, setPatientSnapshotData } = this.props;
    if (!providers) {
      getOnlyProviders();
    }
    setStepData("resource", null);
    setPatientSnapshotData("resource", {});
  }

  componentWillReceiveProps(props) {
    this.setState({ isAdmin: props?.currentUserInfo?.is_admin ? true : false });
  }

  componentWillUpdate(props) {
    if (this.props.action_add_and_select !== props.action_add_and_select) {
      if (!props.action_add_and_select) {
        this.setState({ isSidebar: false });
      }
    }
    if (this.props.currentUserInfo !== props.currentUserInfo) {
      this.setState({ isAdmin: this.props.currentUserInfo.is_admin ? true : false });
    }
  }

  fetchSelect = (type, selected) => {
    this.setState(
      {
        stepProps: -1,
      },
      () => {
        setTimeout(() => {
          this.setState({
            stepProps: 0,
          });
        }, 0);
      }
    );
    this.setState({ selectedType: type, selected });
  };

  handleSearch = (type) => (value) => {
    this.setState({ search: { ...this.state.search, [type]: value } });
  };

  handleAddPatient = () => {
    this.setState({ selected: -1 });
    this.handleSidebar();
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true, active: false, activeID: null });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  renderMetadata() {
    return (
      <Helmet>
        <title>FAACT Vascular | New Patient</title>
        <meta name="title" content="FAACT Vascular | New Patient" />
      </Helmet>
    );
  }

  getStatus = (status, status1, status2, status3) => {
    return (
      <div className="statusHealth">
        <Icon type="mail" theme="twoTone" twoToneColor="#0f62fe" />
        {status}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="mail" theme="twoTone" twoToneColor="#d02670" />
        {status1}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#F1C21B" />
        {status2}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="phone" theme="twoTone" twoToneColor="#198038" />
        {status3}
      </div>
    );
  };

  onHandleClick = async (event) => {
    // check
    this.setState(
      {
        settup: 0,
      },
      () => {
        setTimeout(() => {
          this.setState({
            settup: -1,
          });
        }, 0);
      }
    );
    this.props.setGlobalLoading(true);
    await this.props.changeTab({
      tabActive: null,
      accordion: null,
      step: 0,
    });
    const result = await this.props.selectPatient(event.patient_id);
    await this.props.setPatientSnapshotData("resource", { ...result });
    await this.props.patient_insurances_id_insurances(event.patient_id);
    this.setState({
      isSidebar: true,
      active: true,
      activeID: event?.id,
    });
    setTimeout(() => {
      this.props.setGlobalLoading(false);
    }, 800);
  };

  getPendingCount = (pendingCount) => {
    this.setState({
      pendingCount,
    });
  };

  getPendingHasDocsCount = (pendingHasDocsCount) => {
    this.setState({
      pendingHasDocsCount,
    });
  };

  getCallBackCount = (callbackCount) => {
    this.setState({
      callbackCount,
    });
  };

  renderSideComponent = () => {
    const { resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <h3
              style={{
                color: "#152934",
                fontWeight: 400,
                fontSize: "1.125rem",
                lineHeight: "24px",
                whiteSpace: "nowrap",
                textAlign: "right",
                margin: 0,
                position: "relative",
                left: 1.5,
              }}
            >
              {fullName} {age}
            </h3>
            {allerrgies.length === 0 && (
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                }}
              >
                <span
                  style={{
                    color: "#da1e28",
                    fontSize: "0.625rem",
                    fontWeight: 600,
                  }}
                >
                  {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
                </span>
              </div>
            )}
            {allerrgies.length > 0 && allerrgies.length < 3 && (
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy) => allergy)}
              </div>
            )}
            {allerrgies.length >= 3 && (
              <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
                <div
                  style={{
                    textAlign: "right",
                    whiteSpace: "nowrap",
                    lineHeight: "10px",
                    color: "black",
                    fontSize: "0.625rem",
                    fontWeight: 600,
                  }}
                >
                  {allerrgies.map((allergy) => allergy)}
                </div>
              </Tooltip>
            )}
          </div>

          {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
            <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
          ) : (
            <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
          )}
        </div>
      </div>
    );
  };

  render() {
    const { isAdmin, selected, isSidebar, selectedType, search, active, activeTab, pendingCount, pendingHasDocsCount } = this.state;
    const { callback, resource } = this.props;
    const pendingLengh = pendingCount;
    const pendingHaveDocs = pendingHasDocsCount;
    const callbackLength =
      callback && callback.length > 0 ? callback.filter((r) => !r.has_intraday_call_attempt || r.has_intraday_call_attempt === 0).length : 0;
    const callbackToCall =
      callback && callback.length > 0 ? callback.filter((r) => r.has_intraday_call_attempt === true || r.has_intraday_call_attempt === 1).length : 0;
    const isResource = !resource || !Object.keys(resource).length;
    return (
      <div className="newPatientPage mainPage">
        {this.renderMetadata()}
        <SubTopBar
          title="New Patient Processing"
          noLeftSide={true}
          subStatus={this.getStatus(pendingLengh, pendingHaveDocs, callbackLength, callbackToCall)}
          isBarcode={!isResource}
          sideComponent={this.renderSideComponent()}
        />
        <div className={`clinicWorkflow__main-container`}>
          <div className="clinicWorkflow__main-subTitle tabContainer" style={{ paddingLeft: "5%" }}>
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {NewPatientProcessingTabHeaders.map((tab, index) => {
                  return (
                    <div
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item 
                          ${activeTab === index ? "active" : ""}
                          ${tab.label === "Analytics" && !isAdmin ? "disabled" : ""}
                        `}
                      style={{ width: tab.width, marginLeft: index !== 0 ? "3rem" : "" }}
                      onClick={() => {
                        if (tab.label === "Analytics" && !isAdmin) {
                          return;
                        }
                        this.setState({ activeTab: index });
                      }}
                    >
                      {tab.label}
                    </div>
                  );
                })}
              </Tabs>
            </div>
          </div>
          {active ? (
            <SnapshotSideBar
              isShowGoto={false}
              ref={this.sidebarRef}
              patientResource={this.props.patientResource}
              isShow={isSidebar}
              settup={this.state.settup}
              isScan={true}
              handleCloseSidebar={this.handleCloseSidebar}
              notPermanent
            />
          ) : (
            <NewPatientSideBar
              ref={this.sidebarRef}
              stepProps={this.state.stepProps}
              selected={selected}
              isShow={isSidebar}
              handleCloseSidebar={this.handleCloseSidebar}
            />
          )}

          <div className="main multi-main">
            <ScrollWrapper css="no-padding x-hidden">
              <div
                className={`mainContainer ${isResource || !isSidebar ? "no-sidebar" : ""}`}
                style={{
                  paddingTop: "0",
                  paddingRight: "calc(5% - 3rem)",
                  paddingBottom: "0",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  alignItems: "stretch",
                }}
              >
                {activeTab === 0 && (
                  <div className="mainTableContainer">
                    <CardBlock
                      title="New Patient Pending"
                      varient="half"
                      other="dashboard"
                      subAction={
                        <button className="text-btn" onClick={this.handleAddPatient}>
                          Add Patient +
                        </button>
                      }
                    >
                      <PendingFilter searchValue={search.pending} handleSearch={this.handleSearch("pending")} />
                      <div className="tableSection">
                        <PendingTable
                          search={search.pending}
                          title="pending"
                          selectedType={selectedType}
                          handleSidebar={this.handleSidebar}
                          handleTableSelect={this.fetchSelect}
                          getPendingCount={this.getPendingCount}
                          getPendingHasDocsCount={this.getPendingHasDocsCount}
                        />
                      </div>
                    </CardBlock>

                    <CardBlock title="New Patient Call Back" varient="half" other="dashboard">
                      <CallbackFilter searchValue={search.callback} handleSearch={this.handleSearch("callback")} />
                      <div className="tableSection">
                        <CallbackTable
                          search={search.callback}
                          title="callback"
                          selectedType={selectedType}
                          handleSidebar={this.handleSidebar}
                          handleTableSelect={this.fetchSelect}
                          getCallBackCount={this.getCallBackCount}
                        />
                      </div>
                    </CardBlock>
                  </div>
                )}

                {activeTab === 1 && <CalendarContainer activeID={this.state.activeID} onHandleClick={this.onHandleClick} />}

                {activeTab === 2 && <RegistrationAnalytics />}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.common,
  pending: state.patientPending.pending,
  callback: state.patientCallback.callback,
  resource: state.newPatient.resource,
  action_add_and_select: state.common.action_add_and_select,
  patientResource: state.clinicProvider.patientResource,
  providers: state.common.only_providers,
  resourceCheck: state.patientSnapshot.resource,
  currentUserInfo: state.common.currentUserInfo,
});

const mapDispatchToProps = {
  getLocations,
  getOnlyProviders,
  getReferralConditions,
  getReferredProviders,
  setStepData,
  search_insurance_companies,
  insurance_types,
  patient_insurances_id_insurances,
  selectPatient,
  setPatientSnapshotData,
  changeTab,
  setGlobalLoading,
  getPharmacies,
  getCurrentUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPatientBinder);

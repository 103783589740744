import {createReducer} from 'redux-create-reducer';
import moment from 'moment';

import AT from '../actionTypes';

const initialState = {
  resource: {},
  schedules: [],
  events: null,
  activeOpenings: null,
  closedDates: null,
  openingTime: null,
  closingTime: null,
  loadingEvents: false,
  loadingSchedules: true,
  addLoading: false,
  loadingGetQuestion: false,
  addSuccess: false,
  questionnaireData: [],
  selectedPatientResource: {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    phone: '',
    secondary_phone: '',
    zipcode: '',
    email: '',
    ssn: '',
    address: '',
    apt_number: '',
    state: '',
    city: '',
    medical_id: '',
    policy_number: '',
    notes: [],
  },
};

// Get patient schedules
const GET_ULTRASOUND_QUESTION_STARTED = `${AT.GET_ULTRASOUND_QUESTION}_STARTED`;
const GET_ULTRASOUND_QUESTION_SUCCEEDED = `${AT.GET_ULTRASOUND_QUESTION}_SUCCEEDED`;
const POST_ULTRASOUND_QUESTION_STARTED = `${AT.POST_ULTRASOUND_QUESTION}_STARTED`;
const POST_ULTRASOUND_QUESTION_SUCCEEDED = `${AT.POST_ULTRASOUND_QUESTION}_SUCCEEDED`;

export default createReducer(initialState, {
  //
  [GET_ULTRASOUND_QUESTION_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetQuestion: true,
    });
  },
  [GET_ULTRASOUND_QUESTION_SUCCEEDED](state, action) {
    const questionnaireData = action?.payload;
    return Object.assign({}, state, {
      loadingGetQuestion: false,
      questionnaireData,
    });
  },
  [POST_ULTRASOUND_QUESTION_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetQuestion: true,
    });
  },
  [POST_ULTRASOUND_QUESTION_SUCCEEDED](state, action) {
    const questionnaireData = action?.payload;
    return Object.assign({}, state, {
      loadingGetQuestion: false,
      questionnaireData,
    });
  },
  //
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SET_ULTRASOUND_DATA}_SUCCEEDED`](state, action) {
    return {...state, ...action?.payload};
  },
  [`${AT.SAVE_NEW_ULTRASOUND}_STARTED`](state, action) {
    return {
      ...state,
      addLoading: true,
    };
  },
  [`${AT.SAVE_NEW_ULTRASOUND}_SUCCESS`](state, action) {
    return {
      ...state,
      addLoading: false,
      addSuccess: true,
    };
  },
  [`${AT.GET_ULTRASOUND_EVENTS}_SUCCEEDED`](state, action) {
    const events = action?.payload;
    return Object.assign({}, state, {
      events,
      loadingEvents: false,
    });
  },
  [`${AT.GET_PATIENT_SCHEDULES}_STARTED`](state, action) {
    return {
      ...state,
      loadingSchedules: true,
    };
  },
  [`${AT.GET_PATIENT_SCHEDULES}_SUCCEEDED`](state, action) {
    (action?.payload?.elements || []).map((element) => {
      const newStart = moment(element.start, 'YYYY-MM-DD HH:mm:ss');
      const newEnd = moment(element.end, 'YYYY-MM-DD HH:mm:ss');
      element.start = newStart.toDate();
      element.end = newEnd.toDate();
      return element;
    });
    return {
      ...state,
      schedules: action?.payload?.elements,
      loadingSchedules: false,
    };
  },
  [`${AT.SEARCH_PATIENT_SCHEDULES}`](state, action) {
    const newSchedules = [];
    const {searchValue} = action?.payload || {};
    for (const schedule of state.schedules) {
      if (searchValue === '' || schedule.title.includes(searchValue)) {
        schedule.isHighlight = true;
      } else {
        schedule.isHighlight = false;
      }
      newSchedules.push(schedule);
    }

    return {
      ...state,
      schedules: newSchedules,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      schedules: [],
      loadingSchedules: true,
      addLoading: false,
      addSuccess: false,
      selectedPatientResource: {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        phone: '',
        secondary_phone: '',
        zipcode: '',
        email: '',
        ssn: '',
        address: '',
        apt_number: '',
        state: '',
        city: '',
        medical_id: '',
        policy_number: '',
        notes: [],
      },
    };
  },
});

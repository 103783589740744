import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Timeline from 'react-visjs-timeline';
import './SnapChart.scss';

const SnapChart = (props) => {
  const options = {
    width: '100%',
    height: '200px',
    showMajorLabels: true,
    showCurrentTime: true,
    zoomMin: 500000000,
    zoomMax: 50000000000,
    type: 'point',
    format: {
      minorLabels: {
        minute: 'h:mma',
        hour: 'ha',
      },
    },
  };
  const [items, setItems] = useState([]);
  const [initDelaying, setInitDelaying] = useState(true);

  useEffect(() => {
    const items_ = [];

    Object.keys(props.items).map((key) => {
      props.items[key]['nodes'].map((node, index2) => {
        let className = 'red';
        if (node.type === 'procedure') className = 'yellow';
        if (props.selectedDate === moment(node.date).format('MM/DD/YYYY')) {
          className += ' selected';
        }
        items_.push({
          start: moment(node.date).toDate(),
          end: moment(node.date).toDate(),
          content: node.index + '',
          className: className,
          id: node.index + '',
        });
      });
    });
    setItems(items_);
  }, [props.items, props.selectedDate]);
  useEffect(() => {
    setTimeout(() => {
      setInitDelaying(false);
    }, 500);
  }, []);

  const onSelect = (e) => {
    const selectedIndex = e.items[0] || '';
    Object.keys(props.items).forEach((key) => {
      const selected = props.items[key]['nodes'].find(
          (node) => node.index.toString() === selectedIndex,
      );

      if (selected) {
        props.onSelect(moment(selected.date).format('MM/DD/YYYY'));
      }
    });
  };

  return (
    <div
      className="snapChart-wrapper"
      style={{opacity: initDelaying ? 0 : 1}}
    >
      <Timeline
        options={options}
        items={items}
        selectHandler={onSelect}
        editable={true}
      />
    </div>
  );
};

export default SnapChart;

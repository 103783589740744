import { Collapse, DatePicker, Drawer, Select } from "antd";
import moment from "moment";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../../../common/components/EditableText/MaterialInput";
import { ExpandSVG, PalMessageSVG } from "../../../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../../../common/components/ScrollBar";
import ToastMessage from "../../../../../common/components/Toast";
import { getLocal } from "../../../../../helpers/Local";
import { validateField, validateSteps } from "../../../../../services/validations";
import {
  delete_employee_documents,
  email_fax_employee_documents,
  getProviders,
  get_detail_employee_documents,
  get_employee_documents,
  print_fax_employee_documents,
  reset_fax_scan_document_log,
  send_fax_employee_documents,
  setGlobalLoading,
  set_default_toast,
} from "../../../../../store/actions";

const dateFormat = "MM/DD/YYYY";

const { Option } = Select;
const { Panel } = Collapse;

const PatientChartSideBar = React.forwardRef(({ activeTab, resource, isShow, handleCloseSidebar }, ref) => {
  const documents = useSelector((state) => state.adminUser.employeeDocuments);
  const selectedDocument = useSelector((state) => state.adminUser.detailEmployeeDocuments);
  const print_patient_document = useSelector((state) => state.adminUser.print_fax);
  const [isToastr, setIsToastr] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const curnrent_user_id = getLocal("cvai-current-user");
  const loading_print = useSelector((state) => state.adminUser.loadingEmployeeDocument);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const isToast = useSelector((state) => state.patientRecords.isToast);
  const providers = useSelector((state) => state.common.providers);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();
  const [faxValue, setFaxValue] = useState(undefined);
  const [emailValue, setEmailValue] = useState(undefined);
  const [printLink, setPrintLink] = useState("");
  const [documentFilters, setFilters] = useState({
    date: undefined,
    category: undefined,
    provider_id: undefined,
  });
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);

  const [enableError, setEnableError] = useState(false);
  const [enableErrorEmail, setEnableErrorEmail] = useState(false);

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  useEffect(() => {
    if (selectedDocument) {
      setReset(true);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const onOpenDocument = (document) => {
    setActiveId(document && document?.id);
    if (document && document?.id && document?.id !== activeId) {
      setEmailValue(undefined);
      setFaxValue(undefined);
      dispatch(get_detail_employee_documents(document?.id));
    }
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_patient_document.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  const deleteApiDocument = () => {
    if (selectedDocument && selectedDocument?.id) {
      if (!confirmDelete) {
        setConfirmDelete(true);
        return;
      }

      dispatch(delete_employee_documents(selectedDocument?.id));
      const user_id = curnrent_user_id;
      let { category, provider_id, date } = documentFilters;
      if (!category) category = "";
      if (!provider_id) provider_id = "";
      if (!date) date = "";
      const params = { user_id, date, category, provider_id };
      dispatch(get_employee_documents(params));
      setConfirmDelete(false);
      setMessage("Delete Document Successfully!");
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const onChangeDateFilter = (date) => {
    setFilters({
      ...documentFilters,
      date,
    });
    const user_id = curnrent_user_id;
    let { category, provider_id } = documentFilters;
    if (!category) category = "";
    if (!provider_id) provider_id = "";
    date = date ? date.format("MM/DD/YYYY").toString() : "";
    const params = { user_id, date, category, provider_id };
    dispatch(get_employee_documents(params));
  };

  const onChangeCategoryFilter = (category) => {
    setFilters({
      ...documentFilters,
      category,
    });
    const user_id = curnrent_user_id;
    let { date, provider_id } = documentFilters;
    if (!category) category = "";
    if (!provider_id) provider_id = "";
    date = date ? date.format("MM/DD/YYYY").toString() : "";
    const params = { user_id, date, category, provider_id };
    dispatch(get_employee_documents(params));
  };

  const emptyRows = useMemo(() => {
    if (documents && documents.length <= 4) {
      return new Array(4 - documents.length).fill(0);
    }
    if (documents && documents.length > 4) {
      return [];
    }
    return new Array(4).fill(0);
  }, [documents]);

  const onChangeProviderFilter = (provider_id) => {
    setFilters({
      ...documentFilters,
      provider_id,
    });
    const user_id = curnrent_user_id;
    let { date, category } = documentFilters;
    if (!category) category = "";
    if (!provider_id) provider_id = "";
    date = date ? date.format("MM/DD/YYYY").toString() : "";
    const params = { user_id, date, category, provider_id };
    dispatch(get_employee_documents(params));
  };

  const sendFax = async () => {
    if (selectedDocument && selectedDocument?.id) {
      const data = {
        fax_to: faxValue,
      };
      if (!validateSteps(data, [{ value: "fax_to", type: "default" }])) {
        setEnableError(true);
        return;
      } else {
        setEnableError(false);
        const formData = new FormData();
        formData.append("fax_to", faxValue);
        formData.append("user_id", curnrent_user_id);
        await dispatch(send_fax_employee_documents(selectedDocument?.id, formData));
        setMessage("Send Fax Successfully!");
        setFaxValue("");
      }
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };
  const sendEmail = async () => {
    if (selectedDocument && selectedDocument?.id) {
      const data = {
        email: emailValue,
      };
      if (!validateSteps(data, [{ value: "email", type: "email" }])) {
        setEnableErrorEmail(true);
        return;
      } else {
        setEnableErrorEmail(false);
        const formData = new FormData();
        formData.append("email_address", emailValue);
        await dispatch(email_fax_employee_documents(selectedDocument?.id, formData));
        setMessage("Send Email Successfully!");
        setEmailValue("");
      }
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };
  const sendPrint = async () => {
    if (selectedDocument && selectedDocument?.id) {
      await dispatch(print_fax_employee_documents(selectedDocument?.id));
    } else {
      setMessage("Please Click Document You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isToast) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [isToast]);

  useEffect(() => {
    if (print_patient_document && Object.keys(print_patient_document).length > 0) {
      checkPrint();
    }
  }, [print_patient_document]);

  const showToast = () => {
    return <ToastMessage type="Document" status={status ? "success" : "failed"} msg={message ? message : "Update Successfully!"} />;
  };

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  const printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf";
    const blob = b64toBlob(b64, contentType);
    const blobUrl = URL.createObjectURL(blob);

    let iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        // setTimeout(iframe.contentWindow.print, 500);
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    if (loading_print) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [loading_print]);

  return (
    <Drawer
      title={activeTab === "documentation" ? "Document Database" : activeTab === "image-viewer-tab" ? "Images Database" : "Laboratory Database"}
      className={sideNavStatus ? "wide" : ""}
      placement="right"
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}

        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              height: activeTab === "laboratory" ? "calc(100% - 70px)" : "auto",
              display: activeTab === "laboratory" ? "flex" : "block",
              flexDirection: "column",
            }}
          >
            {activeTab === "documentation" && (
              <div className="resourceInfo d-flex" style={{ alignItems: "flex-end" }}>
                <DatePicker
                  format={dateFormat}
                  placeholder="Filter by Date..."
                  suffixIcon={<ExpandSVG />}
                  style={{
                    width: "calc(33% - 4px)",
                    minWidth: "calc(33% - 4px)",
                    marginRight: "4px",
                  }}
                  value={documentFilters.date}
                  onChange={(date) => onChangeDateFilter(date)}
                />
                <Select
                  placeholder="Document Category..."
                  suffixIcon={<ExpandSVG />}
                  allowClear={true}
                  style={{
                    width: "calc(42% - 8px)",
                    maxWidth: "calc(42% - 8px)",
                  }}
                  value={documentFilters.category}
                  onChange={(category) => onChangeCategoryFilter(category)}
                >
                  <Option value="Laboratory">Laboratory</Option>
                  <Option value="New Patient Pack">Referral Packet</Option>
                  <Option value="operative_reports">Operative Reports</Option>
                  <Option value="consultation_notes">Consultation Notes</Option>
                  <Option value="clinical_data">Clinical Data</Option>
                  <Option value="imaging_reports">Imaging Reports</Option>
                  <Option value="images">Images</Option>
                </Select>
                <Select
                  placeholder="Provider..."
                  suffixIcon={<ExpandSVG />}
                  allowClear={true}
                  style={{
                    width: "calc(25% - 4px)",
                    maxWidth: "calc(25% - 4px)",
                  }}
                  value={documentFilters.provider_id}
                  onChange={(provider_id) => onChangeProviderFilter(provider_id)}
                >
                  {providers.map((provider, index) => (
                    <Option value={provider?.id} key={`provider-option-${index}`}>
                      {provider?.value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            <div className="resourceInfo d-flex">
              {activeTab === "documentation" && (
                <div className="tableList" style={{ height: "calc(100vh - 610px)", minHeight: "25vh" }}>
                  <ScrollWrapper css="no-padding x-hidden">
                    {documents.map((item, index) => (
                      <div
                        className={`tableItem yellow ${selectedDocument && item?.id === selectedDocument?.id ? "active" : ""}`}
                        onClick={() => onOpenDocument(item)}
                        key={`resultTable-${index}`}
                      >
                        <div className="dateField" style={{ width: "33%", minWidth: "33%" }}>
                          {item.document_date ? (
                            <p style={{ paddingLeft: "1rem" }}>{moment(item.document_date, "YYYY/MM/DD hh:mm:ss").format("MM/DD/YYYY")}</p>
                          ) : (
                            <p style={{ paddingLeft: "1rem" }}>{moment(item.created_at, "YYYY/MM/DD hh:mm:ss").format("MM/DD/YYYY")}</p>
                          )}
                        </div>
                        <div
                          className="linkField"
                          style={{
                            width: "42%",
                            minWidth: "42%",
                            paddingLeft: "1rem",
                            cursor: "pointer",
                          }}
                        >
                          <p>
                            {item.category === "hipaa_form"
                              ? "HIPAA and Privacy Policy Acknowledgement"
                              : item.category
                              ? item.category
                              : item.filename}
                          </p>
                        </div>
                        <div
                          className="authorField"
                          style={{
                            width: "25%",
                            minWidth: "25%",
                            paddingLeft: "1rem",
                          }}
                        >
                          <p>
                            {item.provider && item.provider?.value}
                            {item.category === "hipaa_form" && "General Office"}
                          </p>
                        </div>
                      </div>
                    ))}
                    {emptyRows.map((item, index) => (
                      <div className="tableItem" key={`chart-empty-table-${index}`}>
                        <div className="tableItemField" style={{ width: "30%", minWidth: "30%" }} />
                        <div className="tableItemField" style={{ width: "35%" }} />
                        <div className="tableItemField" style={{ width: "35%", paddingRight: "10px" }} />
                      </div>
                    ))}
                  </ScrollWrapper>
                </div>
              )}
            </div>

            <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end", marginTop: "0.5rem" }}>
              {!confirmDelete && (
                <button className="common-btn outlined-btn" onClick={deleteApiDocument}>
                  Delete
                </button>
              )}
              {confirmDelete && (
                <>
                  <button className="common-btn outlined-btn" onClick={() => setConfirmDelete(false)}>
                    Back
                  </button>
                  <button className="common-btn delete-btn" style={{ marginLeft: "0.5rem" }} onClick={deleteApiDocument}>
                    Confirm Delete ?
                  </button>
                </>
              )}
            </div>

            <Collapse accordion style={{ marginTop: "2.5rem" }}>
              <Panel header="Fax" key="fax">
                <div
                  className="resourceInfo d-flex"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <EditableFormInput
                      isErrorTextSpacing={true}
                      handleChange={(value) => {
                        setFaxValue(value);
                      }}
                      value={faxValue}
                      label={"Fax No"}
                      mask={"phone"}
                      required={true}
                      enableErrorText={enableError}
                      isError={validateField("fax_to", faxValue)}
                      helperText={"Fax No is Required!"}
                    />
                  </div>
                  <button onClick={sendFax} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="E-Mail" key="e-mail">
                <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
                  <div style={{ maxWidth: "275px", flex: 1 }}>
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      reset={reset}
                      handleChange={(value) => {
                        setEmailValue(value);
                      }}
                      value={emailValue}
                      label={"E-Mail"}
                      required={true}
                      enableErrorText={enableErrorEmail}
                      isError={validateField("email", emailValue)}
                      helperText={"E-Mail is Required!"}
                    />
                  </div>
                  <button className="common-btn blue-btn" onClick={sendEmail} style={{ marginLeft: "8px", border: 0 }}>
                    Send
                  </button>
                </div>
              </Panel>
              <Panel header="Print" key="print">
                <div className="resourceInfo d-flex" style={{ alignItems: "center", justifyContent: "flex-end" }} onClick={sendPrint}>
                  <button className="common-btn" style={{ border: 0 }}>
                    Print
                  </button>
                </div>
              </Panel>
            </Collapse>

            {activeTab === "laboratory" && (
              <React.Fragment>
                <div
                  className="resourceInfo d-flex"
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PalMessageSVG />
                </div>

                <Collapse accordion style={{ marginTop: "2.5rem" }}>
                  <Panel header="Fax" key="fax">
                    <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
                      <div style={{ maxWidth: "210px" }}>
                        <EditableFormInput
                          isErrorTextSpacing={true}
                          handleChange={(value) => {
                            setFaxValue(value);
                          }}
                          value={faxValue}
                          label={"Fax No"}
                          mask={"phone"}
                          required={true}
                          enableErrorText={enableError}
                          isError={validateField("fax_to", faxValue)}
                          helperText={"Fax No is Required!"}
                        />
                      </div>
                      <button onClick={sendFax} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
                        Send
                      </button>
                    </div>
                  </Panel>
                  <Panel header="E-Mail" key="e-mail">
                    <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
                      <div style={{ maxWidth: "275px", flex: 1 }}>
                        <EditableMaterialText
                          isErrorTextSpacing={true}
                          reset={reset}
                          handleChange={(value) => {
                            setEmailValue(value);
                          }}
                          value={emailValue}
                          label={"E-Mail"}
                          required={true}
                          enableErrorText={enableErrorEmail}
                          isError={validateField("email", emailValue)}
                          helperText={"E-Mail is Required!"}
                        />
                      </div>
                      <button className="common-btn blue-btn" onClick={sendEmail} style={{ marginLeft: "8px", border: 0 }}>
                        Send
                      </button>
                    </div>
                  </Panel>
                  <Panel header="Print" key="print">
                    <div className="resourceInfo d-flex" style={{ alignItems: "center" }} onClick={sendPrint}>
                      <button className="common-btn" style={{ border: 0 }}>
                        Print
                      </button>
                    </div>
                  </Panel>
                </Collapse>
              </React.Fragment>
            )}
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default PatientChartSideBar;

import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PDFView from "../../../../common/../common/components/PDFView";
import { procedureConsentLoadInit, setSidebarState } from "../../../../store/actions";
import ConsentSideBar from "./ConsenSidebar";
import ConsentPreCreateDocument from "./ConsentPreCreateDocument";

function Consent() {
  const [isSidebar, setIsSidebar] = useState(true);
  const [isOpenedCreatePage, setIsOpenedCreatePage] = useState(false);
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);

  const selectedDocumentData = useSelector((state) => state.procedureConsent.selectedDocumentData);
  const patientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);

  useEffect(() => {
    const patient_id = patientResource?.id;
    dispatch(procedureConsentLoadInit(patient_id));
    dispatch(setSidebarState(isSidebar));
    return () => {
      dispatch(setSidebarState(false));
    };
  }, [isSidebar, patientResource]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
    dispatch(setSidebarState(false));
  };

  const openCreatePage = () => {
    setIsOpenedCreatePage(true);
  };

  const closeCreatePage = () => {
    setIsOpenedCreatePage(false);
  };

  return (
    <div className={`clinicWorkflow__main-section`}>
      <div className="eventsContainer has-sub-header">
        <div style={{ width: "100%", height: "100%", paddingTop: "1rem" }}>
          <PDFView style={{ height: "100%" }} url={selectedDocumentData && selectedDocumentData?.fileurl} />
        </div>
      </div>
      {isSidebar && (
        <ConsentSideBar
          sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />}
          ref={sidebarRef}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          openCreatePage={openCreatePage}
        />
      )}
      {isOpenedCreatePage && <ConsentPreCreateDocument closeCreatePage={closeCreatePage} />}
    </div>
  );
}

export default Consent;

import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import {
  procedureConsentDelete,
  procedureConsentGetSignatureImage,
  procedureConsentOpenCreate,
  procedureConsentSearch,
} from "../../../../store/actions";

const ConsentCreateButtonSection = ({ openCreatePage }) => {
  const dispatch = useDispatch();
  const [procedure_data, setProcedureData] = useState(undefined);
  const [provider_id, setProviderId] = useState("");
  const [is_surrogate, setIsSurrogate] = useState(false);
  const [surrogator_name, setSurrogatorName] = useState("");
  const [relation_to_patient, setRelationToPatient] = useState("");
  const [isCreateDirectly, setIsCreateDirectly] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [provider_data, setProviderData] = useState(undefined);

  const searchData = useSelector((state) => state.procedureConsent.searchData);
  const consentDocuments = useSelector((state) => state.procedureConsent.consentDocuments);
  const patientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const schedules = useSelector((state) => state.patientSchedule.schedules);
  const newDocumentData = useSelector((state) => state.procedureConsent.newDocumentData);
  const signatureImage = useSelector((state) => state.procedureConsent.signature_image);
  const selectedDocumentData = useSelector((state) => state.procedureConsent.selectedDocumentData);

  useEffect(() => {
    for (const schedule of schedules) {
      if (schedule["resourceId"] === "procedure" && patientResource?.id == schedule["patient_id"]) {
        setProcedureData({
          title: schedule["procedure_title"],
          description: schedule["procedure_description"],
        });
        setProviderId(schedule["provider_id"]);
        setProviderData({
          id: schedule["provider_id"],
          name: schedule["provider_name"],
        });
        dispatch(procedureConsentGetSignatureImage(schedule["provider_id"]));
      }
    }

    dispatch(procedureConsentOpenCreate(undefined));
  }, [schedules]);
  const [documentsCount, setDocumentsCount] = useState(0);
  useEffect(() => {
    const documentsCount_ = consentDocuments.length;
    if (documentsCount != documentsCount_) {
      dispatch(procedureConsentOpenCreate(undefined));
    }
    setDocumentsCount(documentsCount_);
  }, [consentDocuments]);
  useEffect(() => {
    const reloadConsents = () => {
      const patient_id = patientResource?.id;
      dispatch(procedureConsentSearch(patient_id, searchData));
    };
    const reloadConsentsInterval = setInterval(reloadConsents, 3000);
    return () => {
      clearInterval(reloadConsentsInterval);
    };
  });

  const onCreateConsent = () => {
    // validation
    if (!procedure_data) {
      alert("Please select procedure");
      return;
    }
    if (provider_id === "") {
      alert("Please select provider");
      return;
    }
    if (is_surrogate) {
      if (surrogator_name === "") {
        alert("Surrogator name & relationship to patient should not be empty.");
        return;
      }
      if (relation_to_patient === "") {
        alert("Surrogator name & relationship to patient should not be empty.");
        return;
      }
    }
    if (!signatureImage) {
      alert("Please input provider's signature first.");
      return;
    }
    // set initial data
    const patient_id = patientResource?.id;
    const patient_data = {
      first_name: patientResource?.first_name,
      last_name: patientResource?.last_name,
      date_of_birth: patientResource?.date_of_birth,
    };
    const surrogate_data = {
      surrogator_name,
      relation_to_patient,
    };

    const newDocumentData_ = {
      patient_id,
      patient_data,
      procedure_data,
      provider_id,
      provider_data,
      is_signed: true,
      is_surrogate,
      surrogate_data,
    };
    dispatch(procedureConsentOpenCreate(newDocumentData_));
    if (isCreateDirectly) {
      openCreatePage();
    }
  };

  const onDeleteConsent = () => {
    if (selectedDocumentData) {
      const consent_id = selectedDocumentData?.id;
      dispatch(procedureConsentDelete(consent_id));
    }
    setIsConfirmDelete(false);
  };

  return (
    <>
      <div className="resourceInfo">
        <div className="d-flex" style={{ justifyContent: "flex-end" }}>
          {!isConfirmDelete && (
            <button
              onClick={() => {
                selectedDocumentData && setIsConfirmDelete(true);
              }}
              className="common-btn outlined-btn"
              style={{ marginRight: "10px" }}
            >
              Delete
            </button>
          )}
          {isConfirmDelete && (
            <>
              <button onClick={() => setIsConfirmDelete(false)} className="common-btn outlined-btn" style={{ marginRight: "8px" }}>
                Back
              </button>
              <button onClick={onDeleteConsent} className="common-btn delete-btn" style={{ marginRight: "10px" }}>
                Confirm Delete?
              </button>
            </>
          )}
          <button onClick={onCreateConsent} className="common-btn">
            New App Consent
          </button>
        </div>
        {is_surrogate && (
          <>
            <div
              className="d-flex"
              style={{
                marginTop: "30px",
                paddingBottom: "0.5rem",
                borderBottom: "1px solid #d9d9d9",
              }}
            >
              <EditableFormInput label={"Name of Substitute Decision Maker"} value={surrogator_name} handleChange={setSurrogatorName} />
            </div>
            <div className="d-flex" style={{ marginTop: "0.5rem" }}>
              <EditableFormInput label={"Relationship to Patient"} value={relation_to_patient} handleChange={setRelationToPatient} />
            </div>
          </>
        )}
        {newDocumentData && (
          <div className="resourceInfo" style={{ marginTop: "30px", minHeight: "220px" }}>
            <QRCode
              value={JSON.stringify({
                consent_data: newDocumentData,
                type: "consent-sign",
              })}
              style={{
                width: "calc(100% - 300px)",
                marginLeft: "150px",
                height: "calc(100% - 300px)",
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "16px",
                marginTop: "20px",
              }}
            >
              Place camera centered over the above QR code to read.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ConsentCreateButtonSection;

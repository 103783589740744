import { Input } from 'antd';
import React from 'react';
import { SearchSVG } from '../../../common/components/icons/SVGIcon';

const {Search} = Input;

const PendingFilters = ({handleFilter, handleSearch}) => {

  const changeSearch = (value) => {
    handleSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{width: '100%'}}
            suffix={<SearchSVG />}
          />
        </div>
      </div>
    </div>
  );
};

export default PendingFilters;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validateField } from "../../../../services/validations";
import ToastMessage from "../../../../common/components/Toast";
import LoginFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { createNewPrivateProvider, registerNewPatient, autoZip } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { Icon } from "antd";
import EditableInput from "../../../../common/components/EditableText/EditableFormInput";

let timer;
const AddUserDetails = ({ closeAddForm, check, typeCheck }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [suite, setSuite] = useState("");
  const [state, setState] = useState("");
  const [login, setLogin] = useState("");
  const [city, setCity] = useState("");
  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [password, setPassword] = useState("");
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const zipAutoData = useSelector((state) => state.common.zipAuto);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsFirst(false);
  }, []);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && !isFirst) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          setState(address.state_id);
          setCity(address.place);
        }
      } else {
        setState(undefined);
        setCity(undefined);
      }
    }
  }, [zipAutoData]);

  const checkZipAuto = (value) => {
    clearTimeout(timer);
    setZipCode(value);
    timer = setTimeout(() => {
      dispatch(autoZip(value));
    }, 1000);
  };

  const saveUserPost = async () => {
    if (typeCheck == "referring") {
      const params = {
        // username: login,
        // password: password,
        first_name: firstName,
        last_name: lastName,
        // email_address: email,
        phone_number: phone,
        fax_number: fax,
        address: address,
        state: state,
        suite: suite,
        city: city,
        zipcode: zipcode,
        status: 1,
      };
      const checkPostUser = await dispatch(createNewPrivateProvider(params));
      if (checkPostUser) {
        setIsToastr(true);
        if (check) {
          check(checkPostUser);
        }
        setTimeout(() => {
          setIsToastr(false);
          if (closeAddForm) {
            closeAddForm();
          }
        }, 2000);
      }
    } else {
      const params = {
        date_of_birth: date,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        gender: gender,
        address: address,
        state: state,
        suite: suite,
        city: city,
        zipcode: zipcode,
        status: 1,
      };
      const checkPostUser = await dispatch(registerNewPatient(params));
      if (checkPostUser) {
        setIsToastr(true);
        if (check && checkPostUser["data"]) {
          check(
            checkPostUser && {
              ...checkPostUser["data"],
              full_name: `${checkPostUser["data"]["first_name"]} ${checkPostUser["data"]["last_name"]}`,
            }
          );
        }
        setTimeout(() => {
          setIsToastr(false);
          if (closeAddForm) {
            closeAddForm();
          }
        }, 2000);
      }
    }
  };

  const addNewUser = () => {
    if (typeCheck == "referring") {
      if (validateField("text", firstName) || validateField("text", lastName) || validateField("text", login) || validateField("text", password)) {
        setEnableError(true);
        return;
      }

      // Todo: Integrate API
      setEnableError(false);
      saveUserPost();
    } else {
      if (
        validateField("text", firstName) ||
        validateField("text", lastName) ||
        validateField("text", login) ||
        validateField("text", password) ||
        validateField("text", zipcode) ||
        validateField("text", phone) ||
        validateField("text", date) ||
        validateField("text", gender)
      ) {
        setEnableError(true);
        return;
      }

      // Todo: Integrate API
      setEnableError(false);
      saveUserPost();
    }
  };

  const cancelForm = () => {
    setEnableError(false);
    if (closeAddForm) {
      closeAddForm();
    }
  };

  return (
    <React.Fragment>
      <div
        className="resourceContent"
        style={{
          flex: "unset",
          paddingLeft: "24px",
          paddingRight: "20px",
          marginTop: "1.5rem",
        }}
      >
        {isToastr && <ToastMessage type="Ultrasounds" status="success" msg="Saved Successfully!" />}
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"First Name"}
            value={firstName}
            handleChange={(value) => {
              setFirstName(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", firstName)}
            helperText={"Fisrt Name is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Last Name"}
            value={lastName}
            handleChange={(value) => {
              setLastName(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", lastName)}
            helperText={"Last Name is Required!"}
          />
        </div>
      </div>
      <div
        className="resourceContent"
        style={{
          flex: "unset",
          paddingLeft: "24px",
          paddingRight: "20px",
        }}
      >
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Address"}
            value={address}
            handleChange={(value) => {
              setAddress(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", address)}
            helperText={"Address is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Phone"}
            value={phone}
            handleChange={(value) => {
              setPhone(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", phone)}
            helperText={"Phone is Required!"}
          />
        </div>
        {typeCheck == "referring" ? (
          <div className="resourceInfo d-flex align-items-end required-field">
            <EditableInput
              label={"Fax"}
              value={fax}
              handleChange={(value) => {
                setFax(value);
              }}
              enableErrorText={enableError}
              required={true}
              isError={validateField("text", fax)}
              helperText={"Fax is Required!"}
            />
          </div>
        ) : (
          <>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                label={"DOB"}
                value={date}
                handleChange={(value) => {
                  setDate(value);
                }}
                type={"date"}
                enableErrorText={enableError}
                required={true}
                isError={validateField("text", date)}
                helperText={"Date Of Birth is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                label={"Gender"}
                handleChange={(value) => {
                  setGender(value);
                }}
                value={gender}
                required={true}
                isError={validateField("text", gender)}
                enableErrorText={enableError}
                helperText={"Gender is Required!"}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                ]}
              />
            </div>
          </>
        )}
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Email"}
            value={email}
            handleChange={(value) => {
              setEmail(value);
            }}
            enableErrorText={enableError}
            isError={validateField("email", email)}
            helperText={"Email is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Suite"}
            isError={false}
            value={suite}
            handleChange={(value) => {
              setSuite(value);
            }}
            enableErrorText={enableError}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"State"}
            value={state}
            handleChange={(value) => {
              setState(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", state)}
            helperText={"State is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"City"}
            value={city}
            handleChange={(value) => {
              setCity(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", city)}
            helperText={"City is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <EditableInput
            label={"Zipcode"}
            value={zipcode}
            handleChange={(value) => checkZipAuto(value)}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", zipcode)}
            helperText={"Zipcode is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Login"}
            value={login}
            handleChange={(value) => {
              setLogin(value);
            }}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", login)}
            helperText={"Login is Required!"}
          />
        </div>
        <div className="resourceInfo d-flex align-items-end required-field">
          <LoginFormInput
            label={"Password"}
            value={password}
            handleChange={(value) => {
              setPassword(value);
            }}
            type={"password"}
            enableErrorText={enableError}
            required={true}
            isError={validateField("text", password)}
            helperText={"Password is Required!"}
          />
          <span
            style={{
              width: 18,
              marginLeft: 8,
              height: "2.5rem",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
          </span>
        </div>
        <hr />
      </div>
      <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem", flexWrap: "wrap" }}>
        <button className="outlined-btn" style={{ marginTop: "0.5rem" }} onClick={cancelForm}>
          Back
        </button>
        <button onClick={addNewUser} style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
          Save
        </button>
      </div>
    </React.Fragment>
  );
};

export default AddUserDetails;

import { Drawer } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ConsentCreateButtonSection from "./ConsentCreateButtonSection";
import ConsentDocumentList from "./ConsentDocumentList";
import ConsentFaxingModule from "./ConsentFaxingModule";

const ConsenSideBar = React.forwardRef(({ sidebarIcon, isShow, handleCloseSidebar, openCreatePage }, ref) => {
  const patientResource = useSelector((state) => state.patientSchedule.selectedPatientResource);
  const pcpList = useSelector((state) => state.common.pcpList);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const existingProvider = useMemo(() => {
    let provider = {};
    let tempPCP = [];
    if (pcpList?.active_pcp) {
      tempPCP = pcpList?.active_pcp;
    } else if (pcpList?.length > 0) {
      tempPCP = pcpList;
    }
    if (Array.isArray(tempPCP)) {
      provider = (tempPCP || []).find((rp) => patientResource && rp?.id === patientResource?.referral_provider) || {};
    }
    return provider;
  }, [pcpList]);

  const existingPCP = useMemo(() => {
    let provider = {};
    let tempPCP = [];
    if (pcpList?.active_pcp) {
      tempPCP = pcpList?.active_pcp;
    } else if (pcpList?.length > 0) {
      tempPCP = pcpList;
    }

    provider = tempPCP.find((cp) => patientResource && cp?.id === patientResource?.primary_care_provider_id) || {};
    return provider;
  }, [pcpList]);

  return (
    <Drawer
      title="Consent Database"
      placement="right"
      className={sideNavStatus ? "wide" : ""}
      variant="permanent"
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              height: "200px",
              display: "block",
              paddingLeft: "24px",
            }}
          >
            <div className="resourceInfo">
              <ConsentDocumentList />
            </div>
            <ConsentCreateButtonSection openCreatePage={openCreatePage} />
            <ConsentFaxingModule existingProvider={existingProvider} existingPCP={existingPCP} />
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ConsenSideBar;

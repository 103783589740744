import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  locations: [],
  vhcLocations: [],
  procedureLocations: null,
  providers: null,
  only_providers: null,
  ultrasounds: [],
  filteredLocations: null,
  filteredProviders: null,
  referralConditions: null,
  referredProviders: null,
  detailLocations: {},
  detailUltrasounds: {},
  detailProcedureData: {},
  procedureData: [],
  detailConditionData: {},
  globalLoading: false,
  newLoading: false,
  globalMessage: {},
  loadingDetailConditionData: false,
  loadingDetailProcedureData: false,
  providerUser: null,
  users: [],
  payperiods: [],
  activeStaff: [],
  usersDetail: {},
  loadingDetailUser: false,
  pcpList: null,
  callResult: null,
  diseases: [],
  loadingDiseases: false,
  updatingProcedureData: false,
  isUpdatedProcedureData: false,
  addProcedureData: false,
  isAddProcedureData: false,
  timeoff_requests: [],
  loadingTimeoff: false,
  timeoff_detail_requests: {},
  policies: [],
  policiesDetail: {},
  incident_reports: [],
  timeoff_requests_all: [],
  incident_reports_all: [],
  evaluations: [],
  evaluationsTagert: [],
  evaluationsDetail: {},
  evaluationsUser: [],
  currentUserInfo: {},
  timeEntries: [],
  userLoginInfo: {},
  pcm: [],
  pcm_overview: [],
  pcm_summary: null,
  patients_analytics: null,
  patientStatistics: null,
  patients_table_analytics: null,
  patientReferralTypes: null,
  patientReferralProviders: null,
  procedures_analytics: null,
  ultrasound_analytics: [],
  clinic_analytics: null,
  pcmFilter: [],
  pcmDetail: {},
  pcmUser: {},
  pcmProvider: [],
  demographics: {},
  insurance: {},
  HIPAA: {},
  notes: {},
  loadingPutBarCode: false,
  toastPutBarCode: false,
  loading_delete_procedure: false,
  loadingGetPatientDocument: false,
  patientDocumentsData: {},
  assignedDocuments: [],
  detailPatientDocument: {},
  globalErrorMessage: {},
  loadingDeleteFax: false,
  toastDeleteFax: false,
  loadingDeleteScan: false,
  toastDeleteScan: false,
  loadingDeletePatientDocument: false,
  toastDeletePatientDocument: false,
  loadingZip: false,
  loadingAddLocation: false,
  toastAddLocation: false,
  zipAuto: {},
  sortProviderNone: [],
  loadingSortNone: false,
  loadingSortEndo: false,
  dataSortProviderEndo: [],
  loadingSearchCPT: false,
  loading_user: false,
  loading_staff: false,
  searchCPT: [],
  image_list: [],
  CPT: [],
  loadingCPT: false,
  toasterCPT: false,
  loading_image: false,
  id_image: 0,
  loadingAllergy: false,
  allergies: [],
  appointment_pdf: {},
  loading_ultrasound_value: false,
  ultrasound_value: {},
  detail_ultrasound_value: {},
  detail_ultrasound_result: {},
  detail_ultrasound_props: {},
  loading_get_patients: false,
  list_patients: [],
  loading_get_pcp: false,
  loading_save_schedule: false,
  loading_get_detail_location: false,
  action_add_and_select: false,
  compelete_step_communication: false,
  loading_provider_locaiton: false,
  provider_location: [],
  clinicNursingLocations: [],
  checkApointment: false,
  is_locked: null,
  activeReview: false,
  activeSupportTicket: null,
  loadingSurgicalHistories: false,
  surgicalHistories: [],
  loadingLocationStatus: false,
  locationStatusActive: [],
  locationStatusInActive: [],
  pharmacies: [],
  loadingPharmacies: false,
  loadingSearchMedications: false,
  dataMedications: [],
  loadingGetFieldMedication: false,
  dataListMedication: [],
  pharmacy: {},
  medication: {},
  lockUltrasound: false,
  sideNavStatus: false,
  editLock: {},
  loadingLock: false,
  params_plan: {},
  loadAgain: false,
  loadingIntraAnswer: false,
  intraAnswers: [],
  firstLoadingActive: false,
  channelCheck: null,
  showBack: false,
  isGoToCharting: false,
  appointmentIdGoToCharting: null,
  planSideBarData: null,
  dataTaskManager: null,
  dataTasks: null,
  errorHPI: false,
  dataLocalChangeRecord: null,
  condition_icd_code: null,
  checkRequireForReview: false,
  activeHandleChange: false,
  actionGoNextTab: false,
  activeRequireDiameter: false,
  activeRequireIndication: false,
  actionActive: {
    status: false,
    value: "",
  },
  errorUltrasound: false,
};
const SET_ACTIVE_REQUIRE_DIAMETER_SUCCEEDED = `${AT.SET_ACTIVE_REQUIRE_DIAMETER}_SUCCEEDED`;
const SET_ACTIVE_REQUIRE_INDICATION_SUCCEEDED = `${AT.SET_ACTIVE_REQUIRE_INDICATION}_SUCCEEDED`;
const ACTION_ADD_AND_SELECT_SUCCEEDED = `${AT.ACTION_ADD_AND_SELECT}_SUCCEEDED`;
// ultrasound_finding_value
const SET_DETAIL_ULTRASOUND_PROPS_SUCCEEDED = `${AT.SET_DETAIL_ULTRASOUND_PROPS}_SUCCEEDED`;
const GET_ULTRASOUND_RULE_RESULT_STARTED = `${AT.GET_ULTRASOUND_RULE_RESULT}_STARTED`;
const GET_ULTRASOUND_RULE_RESULT_SUCCEEDED = `${AT.GET_ULTRASOUND_RULE_RESULT}_SUCCEEDED`;
const SAVE_ULTRASOUND_RULE_RESULT_STARTED = `${AT.SAVE_ULTRASOUND_RULE_RESULT}_STARTED`;
const SAVE_ULTRASOUND_RULE_RESULT_SUCCEEDED = `${AT.SAVE_ULTRASOUND_RULE_RESULT}_SUCCEEDED`;
const GET_ULTRASOUND_FINDING_VALUE_STARTED = `${AT.GET_ULTRASOUND_FINDING_VALUE}_STARTED`;
const GET_ULTRASOUND_FINDING_VALUE_SUCCEEDED = `${AT.GET_ULTRASOUND_FINDING_VALUE}_SUCCEEDED`;
const SAVE_ULTRASOUND_FINDING_VALUE_STARTED = `${AT.SAVE_ULTRASOUND_FINDING_VALUE}_STARTED`;
const SAVE_ULTRASOUND_FINDING_VALUE_SUCCEEDED = `${AT.SAVE_ULTRASOUND_FINDING_VALUE}_SUCCEEDED`;
const DETAIL_ULTRASOUND_FINDING_VALUE_STARTED = `${AT.DETAIL_ULTRASOUND_FINDING_VALUE}_STARTED`;
const DETAIL_ULTRASOUND_FINDING_VALUE_SUCCEEDED = `${AT.DETAIL_ULTRASOUND_FINDING_VALUE}_SUCCEEDED`;
//
const SET_COMMON_DATA_SUCCEEDED = `${AT.SET_COMMON_DATA}_SUCCEEDED`;
const GET_LOCATIONS_SUCCEEDED = `${AT.GET_LOCATIONS}_SUCCEEDED`;
const GET_CLINIC_LOCATIONS_SUCCEEDED = `${AT.GET_CLINIC_LOCATIONS}_SUCCEEDED`;
const GET_VHC_LOCATIONS_SUCCEEDED = `${AT.GET_VHC_LOCATIONS}_SUCCEEDED`;
const GET_CLINIC_NURSING_LOCATIONS_SUCCEEDED = `${AT.GET_CLINIC_NURSING_LOCATIONS}_SUCCEEDED`;
const GET_DETAIL_LOCATIONS_SUCCEEDED = `${AT.GET_DETAIL_LOCATIONS}_SUCCEEDED`;
const GET_DETAIL_LOCATIONS_STARTED = `${AT.GET_DETAIL_LOCATIONS}_STARTED`;

const GET_PROCEDURE_LOCATIONS_SUCCEEDED = `${AT.GET_PROCEDURE_LOCATIONS}_SUCCEEDED`;
const GET_PROVIDERS_SUCCEEDED = `${AT.GET_PROVIDERS}_SUCCEEDED`;
const GET_ONLY_PROVIDERS_SUCCEEDED = `${AT.GET_ONLY_PROVIDERS}_SUCCEEDED`;
const GET_PROVIDERS_SORT_SCHEDULE_SUCCEEDED = `${AT.GET_PROVIDERS_SORT_SCHEDULE}_SUCCEEDED`;
const GET_ULTRASOUNDS_SUCCEEDED = `${AT.GET_ULTRASOUNDS}_SUCCEEDED`;
const GET_PROVIDER_USER_SUCCEEDED = `${AT.GET_PROVIDER_USER}_SUCCEEDED`;
const FILTER_LOCATIONS_SUCCEEDED = `${AT.FILTER_LOCATIONS}_SUCCEEDED`;
const FILTER_PROVIDERS_SUCCEEDED = `${AT.FILTER_PROVIDERS}_SUCCEEDED`;
const GET_REFERRAL_CONDITIONS_SUCCEEDED = `${AT.GET_REFERRAL_CONDITIONS}_SUCCEEDED`;
const GET_REFERRED_PROVIDERS_SUCCEEDED = `${AT.GET_REFERRED_PROVIDERS}_SUCCEEDED`;
const GET_PCP_LIST_STARTED = `${AT.GET_PCP_LIST}_STARTED`;
const GET_PCP_LIST_SUCCEEDED = `${AT.GET_PCP_LIST}_SUCCEEDED`;
const GET_PCP_FROM_NPE_STARTED = `${AT.GET_PCP_FROM_NPE}_STARTED`;
const GET_PCP_FROM_NPE_SUCCEEDED = `${AT.GET_PCP_FROM_NPE}_SUCCEEDED`;
const GET_CALL_RESULTS_SUCCEEDED = `${AT.GET_CALL_RESULTS}_SUCCEEDED`;
const GET_PROCEDURE_DATA_SUCCEEDED = `${AT.GET_PROCEDURE_DATA}_SUCCEEDED`;
const GET_DETAIL_PROCEDURE_DATA_STARTED = `${AT.GET_DETAIL_PROCEDURE_DATA}_STARTED`;
const GET_DETAIL_PROCEDURE_DATA_SUCCEEDED = `${AT.GET_DETAIL_PROCEDURE_DATA}_SUCCEEDED`;
const GET_DETAIL_CONDITION_DATA_STARTED = `${AT.GET_DETAIL_CONDITION_DATA}_STARTED`;
const GET_DETAIL_CONDITION_DATA_SUCCEEDED = `${AT.GET_DETAIL_CONDITION_DATA}_SUCCEEDED`;
const GET_DETAIL_ULTRASOUNDS_SUCCEEDED = `${AT.GET_DETAIL_ULTRASOUNDS}_SUCCEEDED`;
const DELETE_ULTRASOUND_TYPE_STARTED = `${AT.DELETE_ULTRASOUND_TYPE}_STARTED`;
const DELETE_ULTRASOUND_TYPE_SUCCEEDED = `${AT.DELETE_ULTRASOUND_TYPE}_SUCCEEDED`;
const DELETE_LOCATION_STARTED = `${AT.DELETE_LOCATION}_STARTED`;
const DELETE_LOCATION_SUCCEEDED = `${AT.DELETE_LOCATION}_SUCCEEDED`;
const GET_DISEASES_SUCCEEDED = `${AT.GET_DISEASES}_SUCCEEDED`;
const GET_DISEASES_STARTED = `${AT.GET_DISEASES}_STARTED`;
const GET_SURGICAL_HISTORIES_STARTED = `${AT.GET_SURGICAL_HISTORIES}_STARTED`;
const GET_SURGICAL_HISTORIES_SUCCEEDED = `${AT.GET_SURGICAL_HISTORIES}_SUCCEEDED`;
//
const POST_PROVIDER_SAVE_SCHEDULE_STARTED = `${AT.POST_PROVIDER_SAVE_SCHEDULE}_STARTED`;
const POST_PROVIDER_SAVE_SCHEDULE_SUCCEEDED = `${AT.POST_PROVIDER_SAVE_SCHEDULE}_SUCCEEDED`;
//

const ADD_PROCEDURE_DATA_STARTED = `${AT.ADD_PROCEDURE_DATA}_STARTED`;
const ADD_PROCEDURE_DATA_SUCCEEDED = `${AT.ADD_PROCEDURE_DATA}_SUCCEEDED`;
const UPDATE_DETAIL_PROCEDURE_DATA_STARTED = `${AT.UPDATE_DETAIL_PROCEDURE_DATA}_STARTED`;
const UPDATE_DETAIL_PROCEDURE_DATA_SUCCEEDED = `${AT.UPDATE_DETAIL_PROCEDURE_DATA}_SUCCEEDED`;
const DELETE_PROCEDURE_DATA_STARTED = `${AT.DELETE_PROCEDURE_DATA}_STARTED`;
const DELETE_PROCEDURE_DATA_SUCCEEDED = `${AT.DELETE_PROCEDURE_DATA}_SUCCEEDED`;
const GET_USERS_SUCCESS = `${AT.GET_USERS}_SUCCEEDED`;
const GET_USERS_STARTED = `${AT.GET_USERS}_STARTED`;
const GET_PAYPERIODS_SUCCESS = `${AT.GET_PAYPERIODS}_SUCCEEDED`;
const GET_ACTIVE_STAFF_SUCCESS = `${AT.GET_ACTIVE_STAFF}_SUCCEEDED`;
const GET_ACTIVE_STAFF_STARTED = `${AT.GET_ACTIVE_STAFF}_STARTED`;

const GET_DETAIL_USERS_SUCCESS = `${AT.GET_DETAIL_USERS}_SUCCEEDED`;
const GET_DETAIL_USERS_STARTED = `${AT.GET_DETAIL_USERS}_STARTED`;
const TIMEOFF_REQUESTS_SUCCESS = `${AT.TIMEOFF_REQUESTS}_SUCCEEDED`;
const TIMEOFF_DETAIL_REQUESTS_SUCCESS = `${AT.TIMEOFF_DETAIL_REQUESTS}_SUCCEEDED`;
const GET_POLICIES_SUCCESS = `${AT.GET_POLICIES}_SUCCEEDED`;
const GET_DETAIL_POLICIES_SUCCESS = `${AT.GET_DETAIL_POLICIES}_SUCCEEDED`;
const GET_INCIDENT_REPORTS_SUCCESS = `${AT.GET_INCIDENT_REPORTS}_SUCCEEDED`;
const TIMEOFF_REQUESTS_ALL_SUCCESS = `${AT.TIMEOFF_REQUESTS_ALL}_SUCCEEDED`;
const GET_INCIDENT_REPORTS_ALL_SUCCESS = `${AT.GET_INCIDENT_REPORTS_ALL}_SUCCEEDED`;
const GET_EVALUATIONS_SUCCESS = `${AT.GET_EVALUATIONS}_SUCCEEDED`;
const GET_EVALUATIONS_DETAIL_SUCCESS = `${AT.GET_EVALUATIONS_DETAIL}_SUCCEEDED`;
const GET_EVALUATIONS_DETAIL_TARGERT_SUCCESS = `${AT.GET_EVALUATIONS_DETAIL_TARGERT}_SUCCEEDED`;
const GET_EVALUATIONS_USER_SUCCESS = `${AT.GET_EVALUATIONS_USER}_SUCCEEDED`;
const GET_CURRENT_USER_SUCCESS = `${AT.GET_CURRENT_USER}_SUCCEEDED`;
const GET_CURRENT_PATIENT_SUCCESS = `${AT.GET_CURRENT_PATIENT}_SUCCEEDED`;
const GET_CURRENT_PROVIDER_SUCCESS = `${AT.GET_CURRENT_PROVIDER}_SUCCEEDED`;
const GET_TIME_ENTRIES_SUCCESS = `${AT.GET_TIME_ENTRIES}_SUCCEEDED`;
const GET_CURRENT_USER_LOGIN_SUCCESS = `${AT.GET_CURRENT_USER_LOGIN}_SUCCEEDED`;
const GET_PCM_SUCCESS = `${AT.GET_PCM}_SUCCEEDED`;
const GET_PCM_OVERVIEW_SUCCESS = `${AT.GET_PCM_OVERVIEW}_SUCCEEDED`;
const GET_PCM_SUMMARY_SUCCESS = `${AT.GET_PCM_SUMMARY}_SUCCEEDED`;
const GET_PATIENTS_ANALYTICS_SUCCESS = `${AT.GET_PATIENTS_ANALYTICS}_SUCCEEDED`;
const GET_PATIENTS_TABLE_ANALYTICS_SUCCESS = `${AT.GET_PATIENTS_TABLE_ANALYTICS}_SUCCEEDED`;
const GET_PATIENT_STATISTICS_SUCCESS = `${AT.GET_PATIENT_STATISTICS}_SUCCEEDED`;
const GET_PATIENT_REFERRAL_TYPES_SUCCESS = `${AT.GET_PATIENT_REFERRAL_TYPES}_SUCCEEDED`;
const GET_PATIENT_REFERRAL_PROVIDERS_SUCCESS = `${AT.GET_PATIENT_REFERRAL_PROVIDERS}_SUCCEEDED`;
const GET_PROCEDURES_ANALYTICS_SUCCESS = `${AT.GET_PROCEDURES_ANALYTICS}_SUCCEEDED`;
const GET_ULTRASOUND_ANALYTICS_SUCCESS = `${AT.GET_ULTRASOUND_ANALYTICS}_SUCCEEDED`;
const GET_CLINIC_ANALYTICS_SUCCESS = `${AT.GET_CLINIC_ANALYTICS}_SUCCEEDED`;
const GET_DETAIL_PCM_SUCCESS = `${AT.GET_DETAIL_PCM}_SUCCEEDED`;
const GET_PCM_FOR_USER_SUCCESS = `${AT.GET_PCM_FOR_USER}_SUCCEEDED`;
const GET_PCM_PROVIDER_SUCCESS = `${AT.GET_PCM_PROVIDER}_SUCCEEDED`;
const GET_PCM_FILTER_SUCCESS = `${AT.GET_PCM_FILTER}_SUCCEEDED`;
const SET_DATA_FIELD_CHECK_IN_SUCCESS = `${AT.SET_DATA_FIELD_CHECK_IN}_SUCCEEDED`;
const PUT_PATIENT_DOCUMENT_STARTED = `${AT.PUT_PATIENT_DOCUMENT}_STARTED`;
const PUT_PATIENT_DOCUMENT_SUCCESS = `${AT.PUT_PATIENT_DOCUMENT}_SUCCEEDED`;
const GET_ASSIGNED_DOCUMENTS_STARTED = `${AT.GET_ASSIGNED_DOCUMENTS}_STARTED`;
const GET_ASSIGNED_DOCUMENTS_SUCCESS = `${AT.GET_ASSIGNED_DOCUMENTS}_SUCCEEDED`;
const GET_FILTER_PATIENT_DOCUMENT_STARTED = `${AT.GET_FILTER_PATIENT_DOCUMENT}_STARTED`;
const GET_FILTER_PATIENT_DOCUMENT_SUCCESS = `${AT.GET_FILTER_PATIENT_DOCUMENT}_SUCCEEDED`;
const GET_SORTED_PATIENT_DOCUMENT_STARTED = `${AT.GET_SORTED_PATIENT_DOCUMENT}_STARTED`;
const GET_SORTED_PATIENT_DOCUMENT_SUCCESS = `${AT.GET_SORTED_PATIENT_DOCUMENT}_SUCCEEDED`;
const CLEAR_PATIENT_DOCUMENT_SUCCESS = `${AT.CLEAR_PATIENT_DOCUMENT}_SUCCEEDED`;
const GET_PATIENT_DOCUMENT_STARTED = `${AT.GET_PATIENT_DOCUMENT}_STARTED`;
const GET_PATIENT_DOCUMENT_SUCCESS = `${AT.GET_PATIENT_DOCUMENT}_SUCCEEDED`;
const SET_TOAST_PUT_BAR_CODE_SUCCESS = `${AT.SET_TOAST_PUT_BAR_CODE}_SUCCEEDED`;
const DELETE_SCAN_LOG_STARTED = `${AT.DELETE_SCAN_LOG}_STARTED`;
const DELETE_SCAN_LOG_SUCCESS = `${AT.DELETE_SCAN_LOG}_SUCCEEDED`;
const DELETE_FAX_LOG_STARTED = `${AT.DELETE_FAX_LOG}_STARTED`;
const DELETE_FAX_LOG_SUCCESS = `${AT.DELETE_FAX_LOG}_SUCCEEDED`;
const DELETE_PATIENT_DOCUMENT_STARTED = `${AT.DELETE_PATIENT_DOCUMENT}_STARTED`;
const DELETE_PATIENT_DOCUMENT_SUCCESS = `${AT.DELETE_PATIENT_DOCUMENT}_SUCCEEDED`;
const AUTO_ZIP_STARTED = `${AT.AUTO_ZIP}_STARTED`;
const AUTO_ZIP_SUCCESS = `${AT.AUTO_ZIP}_SUCCEEDED`;
const ADD_NEW_LOCATION_STARTED = `${AT.ADD_NEW_LOCATION}_STARTED`;
const ADD_NEW_LOCATION_SUCCESS = `${AT.ADD_NEW_LOCATION}_SUCCEEDED`;
const REMOVE_ZIP_AUTO_SUCCESS = `${AT.REMOVE_ZIP_AUTO}_SUCCEEDED`;
const SET_PCP_NULL_STARTED = `${AT.SET_PCP_NULL}_SUCCEEDED`;

const SORT_PROVIDER_NONE_STARTED = `${AT.SORT_PROVIDER_NONE}_STARTED`;
const SORT_PROVIDER_NONE_SUCCESS = `${AT.SORT_PROVIDER_NONE}_SUCCEEDED`;
const SORT_PROVIDER_ENDO_STARTED = `${AT.SORT_PROVIDER_ENDO}_STARTED`;
const SORT_PROVIDER_ENDO_SUCCESS = `${AT.SORT_PROVIDER_ENDO}_SUCCEEDED`;

const EXPORT_PCM_STARTED = `${AT.EXPORT_PCM}_STARTED`;
const EXPORT_PCM_SUCCESS = `${AT.EXPORT_PCM}_SUCCEEDED`;
const EXPORT_PCM_USER_STARTED = `${AT.EXPORT_PCM_USER}_STARTED`;
const EXPORT_PCM_USER_SUCCESS = `${AT.EXPORT_PCM_USER}_SUCCEEDED`;
const EXPORT_PCM_OVERVIEW_STARTED = `${AT.EXPORT_PCM_OVERVIEW}_STARTED`;
const EXPORT_PCM_OVERVIEW_SUCCESS = `${AT.EXPORT_PCM_OVERVIEW}_SUCCEEDED`;
// CPT code
const SREARCH_CPT_CODE_STARTED = `${AT.SREARCH_CPT_CODE}_STARTED`;
const SREARCH_CPT_CODE_SUCCESS = `${AT.SREARCH_CPT_CODE}_SUCCEEDED`;
const SET_CPT_CODE_REVIEW_STARTED = `${AT.SET_CPT_CODE_REVIEW}_STARTED`;
const SET_CPT_CODE_REVIEW_SUCCESS = `${AT.SET_CPT_CODE_REVIEW}_SUCCEEDED`;
const GET_CPT_CODE_REVIEW_STARTED = `${AT.GET_CPT_CODE_REVIEW}_STARTED`;
const GET_CPT_CODE_REVIEW_SUCCESS = `${AT.GET_CPT_CODE_REVIEW}_SUCCEEDED`;
// GET_IMAGE_LIST
const GET_IMAGE_LIST_STARTED = `${AT.GET_IMAGE_LIST}_STARTED`;
const GET_IMAGE_LIST_SUCCESS = `${AT.GET_IMAGE_LIST}_SUCCEEDED`;
const SET_ID_IMAGE_SUCCESS = `${AT.SET_ID_IMAGE}_SUCCEEDED`;
// Search Allergy
const SEARCH_ALLERGY_STARTED = `${AT.SEARCH_ALLERGY}_STARTED`;
const SEARCH_ALLERGY_SUCCESS = `${AT.SEARCH_ALLERGY}_SUCCEEDED`;
const REMOVE_PARAMS_CHART_SUCCEEDED = `${AT.REMOVE_PARAMS_CHART}_SUCCEEDED`;
const RESET_APPOINTMENT_PDF_SUCCEEDED = `${AT.RESET_APPOINTMENT_PDF}_SUCCEEDED`;
// Save temp
const SAVE_TEMP_STARTED = `${AT.SAVE_TEMP}_STARTED`;
const SAVE_TEMP_SUCCEEDED = `${AT.SAVE_TEMP}_SUCCEEDED`;
// list patient
const GET_LIST_PATIENTS_STARTED = `${AT.GET_LIST_PATIENTS}_STARTED`;
const GET_LIST_PATIENTS_SUCCEEDED = `${AT.GET_LIST_PATIENTS}_SUCCEEDED`;

const SET_GLOBAL_LOADING_SUCCEEDED = `SET_GLOBAL_LOADING_SUCCEEDED`;
const SET_NEW_LOADING_SUCCEEDED = `SET_NEW_LOADING_SUCCEEDED`;
//
const DELETE_PROCEDURE_STARTED = `${AT.DELETE_PROCEDURE}_STARTED`;
const DELETE_PROCEDURE_SUCCEEDED = `${AT.DELETE_PROCEDURE}_SUCCEEDED`;

const CHECK_APOINTMENT_SUCCEEDED = `${AT.CHECK_APOINTMENT}_SUCCEEDED`;
const REMOVE_REVIEW_ULTRASOUND_SUCCEEDED = `${AT.REMOVE_REVIEW_ULTRASOUND}_SUCCEEDED`;
//
const SET_COMMUNICATION_COMPELETE_SUCCEEDED = `${AT.SET_COMMUNICATION_COMPELETE}_SUCCEEDED`;

const GET_PROVIDERS_LOCATION_STARTED = `${AT.GET_PROVIDERS_LOCATION}_STARTED`;
const GET_PROVIDERS_LOCATION_SUCCEEDED = `${AT.GET_PROVIDERS_LOCATION}_SUCCEEDED`;

const SET_GLOBAL_TOASTR_SUCCEEDED = `SET_GLOBAL_TOASTR_SUCCEEDED`;
const SET_ACTIVE_REVIEW_CHANGE_SUCCEEDED = `SET_ACTIVE_REVIEW_CHANGE_SUCCEEDED`;

const SET_ACTIVE_SUPPORT_TICKET = `${AT.ACTIVE_SUPPORT_TICKET}_SUCCEEDED`;
//
const GET_LOCATIONS_STATUS_STARTED = `${AT.GET_LOCATIONS_STATUS}_STARTED`;
const GET_LOCATIONS_STATUS_SUCCEEDED = `${AT.GET_LOCATIONS_STATUS}_SUCCEEDED`;

const GET_PHARMACIES_STARTED = `${AT.GET_PHARMACIES}_STARTED`;
const GET_PHARMACIES_SUCCEEDED = `${AT.GET_PHARMACIES}_SUCCEEDED`;
const GET_PHARMACY_STARTED = `${AT.GET_PHARMACY}_STARTED`;
const GET_PHARMACY_SUCCEEDED = `${AT.GET_PHARMACY}_SUCCEEDED`;
const SAVE_PHARMACY_STARTED = `${AT.SAVE_PHARMACY}_STARTED`;
const SAVE_PHARMACY_SUCCEEDED = `${AT.SAVE_PHARMACY}_SUCCEEDED`;
const EDIT_PHARMACY_STARTED = `${AT.EDIT_PHARMACY}_STARTED`;
const EDIT_PHARMACY_SUCCEEDED = `${AT.EDIT_PHARMACY}_SUCCEEDED`;
const DELETE_PHARMACY_STARTED = `${AT.DELETE_PHARMACY}_STARTED`;
const DELETE_PHARMACY_SUCCEEDED = `${AT.DELETE_PHARMACY}_SUCCEEDED`;

const SEARCH_MEDICATIONS_STARTED = `${AT.SEARCH_MEDICATIONS}_STARTED`;
const SEARCH_MEDICATIONS_SUCCEEDED = `${AT.SEARCH_MEDICATIONS}_SUCCEEDED`;
const GET_FIELD_MEDICATION_STARTED = `${AT.GET_FIELD_MEDICATION}_STARTED`;
const GET_FIELD_MEDICATION_SUCCEEDED = `${AT.GET_FIELD_MEDICATION}_SUCCEEDED`;
const GET_SINGLE_MEDICATION_STARTED = `${AT.GET_SINGLE_MEDICATION}_STARTED`;
const GET_SINGLE_MEDICATION_SUCCEEDED = `${AT.GET_SINGLE_MEDICATION}_SUCCEEDED`;
const RESET_SELECT_DOCUMENT_SUCCEEDED = `${AT.RESET_SELECT_DOCUMENT}_SUCCEEDED`;
const LOCK_ULTRASOUND_SUCCEEDED = `${AT.LOCK_ULTRASOUND}_SUCCEEDED`;

const UPDATE_SIDENAV_STATE_SUCCEEDED = `${AT.UPDATE_SIDENAV_STATE}_SUCCEEDED`;
const SAVE_PARAMS_PLAN_SUCCEEDED = `${AT.SAVE_PARAMS_PLAN}_SUCCEEDED`;

const GET_LOCK_STARTED = `${AT.GET_LOCK}_STARTED`;
const GET_LOCK_SUCCEEDED = `${AT.GET_LOCK}_SUCCEEDED`;
const RELEASE_LOCK_STARTED = `${AT.RELEASE_LOCK}_STARTED`;
const RELEASE_LOCK_SUCCEEDED = `${AT.RELEASE_LOCK}_SUCCEEDED`;

const GET_INTRA_ANSWER_STARTED = `${AT.GET_INTRA_ANSWER}_STARTED`;
const GET_INTRA_ANSWER_SUCCEEDED = `${AT.GET_INTRA_ANSWER}_SUCCEEDED`;
const SET_FIRST_LOADING_ACTIVE_SUCCEEDED = `${AT.SET_FIRST_LOADING_ACTIVE}_SUCCEEDED`;
const SHOW_BUTTON_BACK_SUCCEEDED = `${AT.SHOW_BUTTON_BACK}_SUCCEEDED`;
const SET_CHANNEL_CHECK_SUCCEEDED = `${AT.SET_CHANNEL_CHECK}_SUCCEEDED`;
const GO_TO_CHARTING_SUCCEEDED = `${AT.GO_TO_CHARTING}_SUCCEEDED`;

const SET_REDUX_PLAN_SIDE_BAR_STARTED = `${AT.SET_REDUX_PLAN_SIDE_BAR}_STARTED`;
const SET_REDUX_PLAN_SIDE_BAR_SUCCEEDED = `${AT.SET_REDUX_PLAN_SIDE_BAR}_SUCCEEDED`;
const SET_TASK_MANAGER_SUCCEEDED = `${AT.SET_TASK_MANAGER}_SUCCEEDED`;
const GET_LIST_TASK_SUCCEEDED = `${AT.GET_LIST_TASK}_SUCCEEDED`;
const SET_ERROR_HPI_SUCCEEDED = `${AT.SET_ERROR_HPI}_SUCCEEDED`;
const SET_DATA_LOCAL_CHANGE_RECORD = `${AT.SET_DATA_LOCAL_CHANGE_RECORD}_SUCCEEDED`;
const RESET_MEDICATION_SUCCEEDED = `${AT.RESET_MEDICATION}_SUCCEEDED`;
const GET_CONDITION_ICD_CODE_SUCCEEDED = `${AT.GET_CONDITION_ICD_CODE}_SUCCEEDED`;
const SET_CHECK_REQUIRE_FOR_REVIEW_SUCCEEDED = `${AT.SET_CHECK_REQUIRE_FOR_REVIEW}_SUCCEEDED`;
const CHANGE_ACTIVE_ROW_SUCCEEDED = `${AT.CHANGE_ACTIVE_ROW}_SUCCEEDED`;
const SET_ACTION_GO_NEXT_TAB_SUCCEEDED = `${AT.SET_ACTION_GO_NEXT_TAB}_SUCCEEDED`;

const SET_ACTION_ACTIVE_SUCCEEDED = `${AT.SET_ACTION_ACTIVE}_SUCCEEDED`;
const SET_ERROR_ULTRASOUND_SUCCEEDED = `${AT.SET_ERROR_ULTRASOUND}_SUCCEEDED`;

export default createReducer(initialState, {
  [SET_ERROR_ULTRASOUND_SUCCEEDED](state, action) {
    const errorUltrasound = action?.payload;
    return Object.assign({}, state, {
      errorUltrasound: errorUltrasound || false,
    });
  },

  [SET_ACTION_ACTIVE_SUCCEEDED](state, action) {
    const actionActive = action?.payload?.status;
    return Object.assign({}, state, {
      actionActive,
    });
  },
  [SET_ACTIVE_REQUIRE_DIAMETER_SUCCEEDED](state, action) {
    const activeRequireDiameter = action?.payload?.status || false;
    return Object.assign({}, state, {
      activeRequireDiameter,
    });
  },
  [SET_ACTIVE_REQUIRE_INDICATION_SUCCEEDED](state, action) {
    const activeRequireIndication = action?.payload?.status || false;
    return Object.assign({}, state, {
      activeRequireIndication,
    });
  },
  [SET_ACTION_GO_NEXT_TAB_SUCCEEDED](state, action) {
    const actionGoNextTab = action?.payload || false;
    return Object.assign({}, state, {
      actionGoNextTab,
    });
  },
  [CHANGE_ACTIVE_ROW_SUCCEEDED](state, action) {
    const activeHandleChange = action?.payload || false;
    return Object.assign({}, state, {
      activeHandleChange,
    });
  },
  [SET_CHECK_REQUIRE_FOR_REVIEW_SUCCEEDED](state, action) {
    const checkRequireForReview = action?.payload || false;
    return Object.assign({}, state, {
      checkRequireForReview,
    });
  },
  [GET_CONDITION_ICD_CODE_SUCCEEDED](state, action) {
    const condition_icd_code = action?.payload || [];
    return Object.assign({}, state, {
      condition_icd_code,
    });
  },
  [SET_DATA_LOCAL_CHANGE_RECORD](state, action) {
    const dataLocalChangeRecord = action?.payload?.params || [];
    return Object.assign({}, state, {
      dataLocalChangeRecord,
    });
  },
  [SET_ERROR_HPI_SUCCEEDED](state, action) {
    const errorHPI = action?.payload;
    return Object.assign({}, state, {
      errorHPI: errorHPI || false,
    });
  },
  [GET_LIST_TASK_SUCCEEDED](state, action) {
    const dataTasks = action?.payload;
    return Object.assign({}, state, {
      dataTasks: dataTasks || null,
    });
  },

  [SET_TASK_MANAGER_SUCCEEDED](state, action) {
    const data = action?.payload;
    return Object.assign({}, state, {
      dataTaskManager: data || null,
    });
  },

  [SET_REDUX_PLAN_SIDE_BAR_STARTED](state) {
    return Object.assign({}, state, {
      planSideBarData: null,
    });
  },
  [SET_REDUX_PLAN_SIDE_BAR_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      planSideBarData: params,
    });
  },

  [GO_TO_CHARTING_SUCCEEDED](state, action) {
    const payload = action?.payload.value;
    return Object.assign({}, state, {
      isGoToCharting: payload.isGoToCharting,
      appointmentIdGoToCharting: payload.appointmentIdGoToCharting,
    });
  },

  [SET_CHANNEL_CHECK_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      channelCheck: params || null,
    });
  },

  [SHOW_BUTTON_BACK_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      showBack: params,
    });
  },

  [SET_FIRST_LOADING_ACTIVE_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      firstLoadingActive: params,
    });
  },

  [GET_INTRA_ANSWER_STARTED](state) {
    return Object.assign({}, state, {
      loadingIntraAnswer: true,
    });
  },
  [GET_INTRA_ANSWER_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      loadingIntraAnswer: false,
      intraAnswers: params,
    });
  },
  [RELEASE_LOCK_STARTED](state) {
    return Object.assign({}, state, {
      loadAgain: true,
    });
  },
  [RELEASE_LOCK_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadAgain: false,
    });
  },
  [SAVE_PARAMS_PLAN_SUCCEEDED](state, action) {
    const params = action?.payload;
    return Object.assign({}, state, {
      params_plan: params,
    });
  },
  [LOCK_ULTRASOUND_SUCCEEDED](state, action) {
    const lockUltrasound = action?.payload;
    return Object.assign({}, state, {
      lockUltrasound,
    });
  },
  [RESET_SELECT_DOCUMENT_SUCCEEDED](state) {
    return Object.assign({}, state, {
      patientDocumentsData: {},
    });
  },
  [GET_FIELD_MEDICATION_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetFieldMedication: true,
    });
  },
  [GET_FIELD_MEDICATION_SUCCEEDED](state, action) {
    const dataListMedication = action?.payload;
    return Object.assign({}, state, {
      loadingGetFieldMedication: false,
      dataListMedication,
    });
  },
  [SEARCH_MEDICATIONS_STARTED](state) {
    return Object.assign({}, state, {
      loadingSearchMedications: true,
    });
  },
  [SEARCH_MEDICATIONS_SUCCEEDED](state, action) {
    const dataMedications = action?.payload;
    return Object.assign({}, state, {
      loadingSearchMedications: false,
      dataMedications,
    });
  },
  [GET_LOCATIONS_STATUS_STARTED](state) {
    return Object.assign({}, state, {
      loadingLocationStatus: true,
    });
  },
  [GET_LOCATIONS_STATUS_SUCCEEDED](state, action) {
    const locationStatus = action?.payload;
    let data = {};
    if (locationStatus && locationStatus.some((item) => item.status == "active")) {
      data = {
        locationStatusActive: locationStatus,
        locationStatusInActive: state.locationStatusInActive,
      };
    } else if (locationStatus && locationStatus.some((item) => item.status == "inactive")) {
      data = {
        locationStatusActive: state.locationStatusActive,
        locationStatusInActive: locationStatus,
      };
    }
    return Object.assign({}, state, {
      loadingLocationStatus: false,
      ...data,
    });
  },
  [SET_ACTIVE_REVIEW_CHANGE_SUCCEEDED](state, action) {
    const activeReview = action?.payload;
    return Object.assign({}, state, {
      activeReview,
    });
  },
  [REMOVE_REVIEW_ULTRASOUND_SUCCEEDED](state) {
    return Object.assign({}, state, {
      CPT: [],
    });
  },
  [CHECK_APOINTMENT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      checkApointment: action?.payload,
    });
  },
  [GET_PROVIDERS_LOCATION_STARTED](state) {
    return Object.assign({}, state, {
      loading_provider_locaiton: true,
    });
  },
  [GET_PROVIDERS_LOCATION_SUCCEEDED](state, action) {
    const provider_location = action?.payload;
    return Object.assign({}, state, {
      loading_provider_locaiton: false,
      provider_location,
    });
  },
  [SET_COMMUNICATION_COMPELETE_SUCCEEDED](state, action) {
    const compelete_step_communication = action?.payload;
    return Object.assign({}, state, {
      compelete_step_communication,
    });
  },
  [ACTION_ADD_AND_SELECT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      action_add_and_select: !state.action_add_and_select,
    });
  },
  [DELETE_PROCEDURE_STARTED](state, action) {
    return Object.assign({}, state, {
      loading_delete_procedure: true,
    });
  },
  [DELETE_PROCEDURE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loading_delete_procedure: false,
    });
  },
  [POST_PROVIDER_SAVE_SCHEDULE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_save_schedule: false,
    });
  },
  [POST_PROVIDER_SAVE_SCHEDULE_STARTED](state) {
    return Object.assign({}, state, {
      loading_save_schedule: true,
    });
  },

  [SET_GLOBAL_LOADING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      globalLoading: action?.payload && action?.payload?.loading,
    });
  },

  [SET_NEW_LOADING_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      newLoading: action?.payload || false,
    });
  },

  [SET_GLOBAL_TOASTR_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      globalMessage: action?.payload,
    });
  },
  // list patients
  [GET_LIST_PATIENTS_SUCCEEDED](state, action) {
    const list_patients = action?.payload;
    return Object.assign({}, state, {
      loading_get_patients: false,
      list_patients,
    });
  },
  [GET_LIST_PATIENTS_STARTED](state) {
    return Object.assign({}, state, {
      loading_get_patients: true,
    });
  },
  // ultrasound_finding_value
  [SET_DETAIL_ULTRASOUND_PROPS_SUCCEEDED](state, action) {
    const detail_ultrasound_props = action?.payload?.detail_ultrasound_props;
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
      detail_ultrasound_props,
    });
  },
  [SAVE_ULTRASOUND_RULE_RESULT_STARTED](state) {
    return Object.assign({}, state, {
      loading_ultrasound_value: true,
    });
  },
  [SAVE_ULTRASOUND_RULE_RESULT_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
    });
  },
  [GET_ULTRASOUND_RULE_RESULT_STARTED](state) {
    return Object.assign({}, state, {
      loading_ultrasound_value: true,
    });
  },
  [GET_ULTRASOUND_RULE_RESULT_SUCCEEDED](state, action) {
    const detail_ultrasound_result = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
      detail_ultrasound_result,
    });
  },
  [DETAIL_ULTRASOUND_FINDING_VALUE_STARTED](state) {
    return Object.assign({}, state, {
      loading_ultrasound_value: true,
    });
  },
  [DETAIL_ULTRASOUND_FINDING_VALUE_SUCCEEDED](state, action) {
    const detail_ultrasound_value = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
      detail_ultrasound_value,
    });
  },
  [SAVE_ULTRASOUND_FINDING_VALUE_STARTED](state) {
    return Object.assign({}, state, {
      loading_ultrasound_value: true,
    });
  },
  [SAVE_ULTRASOUND_FINDING_VALUE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
    });
  },
  [GET_ULTRASOUND_FINDING_VALUE_STARTED](state) {
    return Object.assign({}, state, {
      loading_ultrasound_value: true,
    });
  },
  [GET_ULTRASOUND_FINDING_VALUE_SUCCEEDED](state, action) {
    const ultrasound_value = action?.payload;
    return Object.assign({}, state, {
      loading_ultrasound_value: false,
      ultrasound_value,
    });
  },
  //
  [REMOVE_PARAMS_CHART_SUCCEEDED](state) {
    return Object.assign({}, state, {
      detailProcedureData: {},
      condition_icd_code: null,
    });
  },
  [`${AT.REFREST_STATE_TAB}_SUCCEEDED`](state, action) {
    return Object.assign({}, state, {
      locations: [],
      providers: [],
      ultrasounds: [],
      detailLocations: {},
      detailUltrasounds: {},
      detailProcedureData: {},
      searchCPT: [],
      allergies: [],
    });
  },
  [SEARCH_ALLERGY_STARTED](state) {
    return Object.assign({}, state, {
      loadingAllergy: true,
    });
  },
  [SEARCH_ALLERGY_SUCCESS](state, action) {
    const allergies = action?.payload;
    return Object.assign({}, state, {
      loadingAllergy: false,
      allergies,
    });
  },
  [SET_ID_IMAGE_SUCCESS](state, action) {
    const id_image = action?.payload;
    return Object.assign({}, state, {
      id_image,
    });
  },
  [GET_IMAGE_LIST_STARTED](state) {
    return Object.assign({}, state, {
      loading_image: true,
    });
  },
  [GET_IMAGE_LIST_SUCCESS](state, action) {
    const image_list = action?.payload;
    return Object.assign({}, state, {
      loading_image: false,
      image_list,
    });
  },
  [GET_CPT_CODE_REVIEW_STARTED](state) {
    return Object.assign({}, state, {
      loadingCPT: true,
    });
  },
  [GET_CPT_CODE_REVIEW_SUCCESS](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      loadingCPT: false,
      CPT,
      is_locked: CPT?.is_locked === 1 ? true : false,
    });
  },
  [SET_CPT_CODE_REVIEW_STARTED](state) {
    return Object.assign({}, state, {
      toasterCPT: false,
      loadingCPT: true,
      loadingResource: false,
    });
  },
  [SET_CPT_CODE_REVIEW_SUCCESS](state, action) {
    const appointment_pdf = action?.payload;
    return Object.assign({}, state, {
      toasterCPT: true,
      loadingCPT: false,
      appointment_pdf,
    });
  },
  [RESET_APPOINTMENT_PDF_SUCCEEDED](state, action) {
    return Object.assign({}, state, { appointment_pdf: {} });
  },
  [SREARCH_CPT_CODE_STARTED](state) {
    return Object.assign({}, state, {
      loadingSearchCPT: true,
    });
  },
  [SET_ACTIVE_SUPPORT_TICKET](state, action) {
    return Object.assign({}, state, {
      activeSupportTicket: action?.payload,
    });
  },
  [SREARCH_CPT_CODE_SUCCESS](state, action) {
    const searchCPT = action?.payload;
    return Object.assign({}, state, {
      searchCPT,
      loadingSearchCPT: false,
    });
  },
  [SORT_PROVIDER_ENDO_STARTED](state) {
    return Object.assign({}, state, {
      loadingSortEndo: true,
    });
  },
  [SORT_PROVIDER_ENDO_SUCCESS](state, action) {
    const dataSortProviderEndo = action?.payload;
    return Object.assign({}, state, {
      loadingSortEndo: false,
      dataSortProviderEndo: dataSortProviderEndo.endovasculars,
    });
  },
  [SORT_PROVIDER_NONE_STARTED](state) {
    return Object.assign({}, state, {
      loadingSortNone: true,
    });
  },
  [SORT_PROVIDER_NONE_SUCCESS](state, action) {
    const dataSortProviderNone = action?.payload;
    return Object.assign({}, state, {
      loadingSortNone: false,
      dataSortProviderNone: dataSortProviderNone.nonendovasculars,
    });
  },
  [ADD_NEW_LOCATION_STARTED](state) {
    return Object.assign({}, state, {
      loadingAddLocation: true,
      toastAddLocation: false,
    });
  },
  [REMOVE_ZIP_AUTO_SUCCESS](state) {
    return Object.assign({}, state, {
      zipAuto: {},
    });
  },
  [ADD_NEW_LOCATION_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingAddLocation: false,
      toastAddLocation: true,
    });
  },
  [AUTO_ZIP_STARTED](state) {
    return Object.assign({}, state, {
      loadingZip: true,
    });
  },
  [AUTO_ZIP_SUCCESS](state, action) {
    const zipAuto = action?.payload;
    return {
      ...state,
      loadingZip: false,
      zipAuto: zipAuto
        ? zipAuto
        : {
            success: false,
          },
    };
  },

  [DELETE_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      loadingDeletePatientDocument: true,
      toastDeletePatientDocument: false,
    });
  },
  [DELETE_PATIENT_DOCUMENT_SUCCESS](state, action) {
    const { id } = action?.payload || {};
    const patientDocuments = state?.patientDocumentsData?.data?.filter((doc) => {
      return doc?.id !== id;
    });
    return {
      ...state,
      patientDocumentsData: {
        ...state.patientDocumentsdata?.patientDocuments,
        data: [...patientDocuments],
      },
      loadingDeletePatientDocument: false,
      toastDeletePatientDocument: true,
    };
  },
  [DELETE_FAX_LOG_STARTED](state) {
    return Object.assign({}, state, {
      loadingDeleteFax: true,
      toastDeleteFax: false,
    });
  },
  [DELETE_FAX_LOG_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingDeleteFax: false,
      toastDeleteFax: true,
    });
  },
  [DELETE_SCAN_LOG_STARTED](state) {
    return Object.assign({}, state, {
      loadingDeleteScan: true,
      toastDeleteScan: false,
    });
  },
  [DELETE_SCAN_LOG_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingDeleteScan: false,
      toastDeleteScan: true,
    });
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.SET_GLOBAL_ERROR_MESSAGE}_SUCCEEDED`](state, action) {
    return Object.assign({}, state, {
      globalErrorMessage: action?.payload,
    });
  },
  [PUT_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      loadingPutBarCode: true,
      toastPutBarCode: false,
    });
  },
  [SET_TOAST_PUT_BAR_CODE_SUCCESS](state) {
    return Object.assign({}, state, {
      toastPutBarCode: false,
    });
  },
  [PUT_PATIENT_DOCUMENT_SUCCESS](state) {
    return Object.assign({}, state, {
      loadingPutBarCode: false,
      toastPutBarCode: true,
    });
  },
  [GET_PATIENT_DOCUMENT_SUCCESS](state, action) {
    const detailPatientDocument = action?.payload;
    return Object.assign({}, state, {
      loadingGetPatientDocument: false,
      detailPatientDocument,
    });
  },
  [GET_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetPatientDocument: true,
    });
  },
  [GET_FILTER_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetPatientDocument: true,
    });
  },
  [GET_FILTER_PATIENT_DOCUMENT_SUCCESS](state, action) {
    const patientDocuments = action?.payload;
    if (state?.patientDocumentsData?.data?.length > 0) {
      return {
        ...state,
        patientDocumentsData: {
          ...patientDocuments,
          data: [...state?.patientDocumentsData?.data, ...patientDocuments?.data],
        },
        loadingGetPatientDocument: false,
      };
    } else {
      return {
        ...state,
        patientDocumentsData: {
          ...patientDocuments,
        },
        loadingGetPatientDocument: false,
      };
    }
  },
  [GET_SORTED_PATIENT_DOCUMENT_STARTED](state) {
    return Object.assign({}, state, {
      loadingGetPatientDocument: true,
    });
  },
  [GET_SORTED_PATIENT_DOCUMENT_SUCCESS](state, action) {
    const patientDocuments = action?.payload;
    return {
      ...state,
      patientDocumentsData: {
        ...patientDocuments,
      },
      loadingGetPatientDocument: false,
    };
  },
  [CLEAR_PATIENT_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      patientDocumentsData: {},
      detailPatientDocument: {},
    };
  },
  [GET_ASSIGNED_DOCUMENTS_STARTED](state) {
    return {
      ...state,
      assignedDocuments: [],
    };
  },
  [GET_ASSIGNED_DOCUMENTS_SUCCESS](state, action) {
    const assignedDocuments = action?.payload;
    return {
      ...state,
      assignedDocuments,
    };
  },
  [SET_DATA_FIELD_CHECK_IN_SUCCESS](state, action) {
    if (action?.payload) {
      const keyName = action?.payload;
      if (Object.keys(keyName)[0] === "demographics") {
        return Object.assign({}, state, {
          demographics: { ...action?.payload?.demographics },
        });
      } else if (Object.keys(keyName)[0] === "insurance") {
        return Object.assign({}, state, {
          insurance: { ...action?.payload?.insurance },
        });
      } else if (Object.keys(keyName)[0] === "notes") {
        return Object.assign({}, state, {
          notes: { ...action?.payload?.notes },
        });
      } else if (Object.keys(keyName)[0] === "HIPAA") {
        return Object.assign({}, state, {
          HIPAA: { ...action?.payload["HIPAA"] },
        });
      }
    }
    return Object.assign({}, state);
  },
  [SET_COMMON_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, { ...action?.payload });
  },
  [GET_PCM_FILTER_SUCCESS](state, action) {
    const pcmFilter = action?.payload;
    return Object.assign({}, state, { pcmFilter });
  },
  [GET_PCM_PROVIDER_SUCCESS](state, action) {
    const pcmProvider = action?.payload;
    return Object.assign({}, state, { pcmProvider });
  },
  [GET_PCM_FOR_USER_SUCCESS](state, action) {
    const pcmUser = action?.payload;
    return Object.assign({}, state, { pcmUser });
  },
  [GET_DETAIL_PCM_SUCCESS](state, action) {
    const pcmDetail = action?.payload;
    return Object.assign({}, state, { pcmDetail });
  },
  [GET_PCM_SUCCESS](state, action) {
    const pcm = action?.payload;
    return Object.assign({}, state, { pcm });
  },
  [GET_PCM_OVERVIEW_SUCCESS](state, action) {
    const pcm_overview = action?.payload;
    return Object.assign({}, state, { pcm_overview });
  },
  [GET_PCM_SUMMARY_SUCCESS](state, action) {
    const pcm_summary = action?.payload;
    return Object.assign({}, state, { pcm_summary });
  },
  [GET_PATIENTS_ANALYTICS_SUCCESS](state, action) {
    const patients_analytics = action?.payload;
    return { ...state, patients_analytics };
  },
  [GET_PATIENT_STATISTICS_SUCCESS](state, action) {
    const patientStatistics = action?.payload;
    return { ...state, patientStatistics };
  },
  [GET_PATIENT_REFERRAL_TYPES_SUCCESS](state, action) {
    const patientReferralTypes = action?.payload;
    return { ...state, patientReferralTypes };
  },
  [GET_PATIENT_REFERRAL_PROVIDERS_SUCCESS](state, action) {
    const patientReferralProviders = action?.payload;
    return { ...state, patientReferralProviders };
  },
  [GET_PATIENTS_TABLE_ANALYTICS_SUCCESS](state, action) {
    const patients_table_analytics = action?.payload;
    return { ...state, patients_table_analytics };
  },
  [GET_PROCEDURES_ANALYTICS_SUCCESS](state, action) {
    const procedures_analytics = action?.payload;
    return { ...state, procedures_analytics };
  },
  [GET_ULTRASOUND_ANALYTICS_SUCCESS](state, action) {
    const ultrasound_analytics = action?.payload;
    return { ...state, ultrasound_analytics };
  },
  [GET_CLINIC_ANALYTICS_SUCCESS](state, action) {
    const clinic_analytics = action?.payload;
    return { ...state, clinic_analytics };
  },
  [GET_CURRENT_USER_LOGIN_SUCCESS](state, action) {
    const userLoginInfo = action?.payload;
    return Object.assign({}, state, { userLoginInfo });
  },
  [GET_EVALUATIONS_USER_SUCCESS](state, action) {
    const evaluationsUser = action?.payload;
    return Object.assign({}, state, { evaluationsUser });
  },
  [GET_EVALUATIONS_DETAIL_TARGERT_SUCCESS](state, action) {
    const evaluationsTagert = action?.payload;
    return Object.assign({}, state, { evaluationsTagert });
  },
  [GET_EVALUATIONS_DETAIL_SUCCESS](state, action) {
    const evaluationsDetail = action?.payload;
    return Object.assign({}, state, { evaluationsDetail });
  },
  [GET_EVALUATIONS_SUCCESS](state, action) {
    const evaluations = action?.payload;
    return Object.assign({}, state, { evaluations });
  },
  [GET_INCIDENT_REPORTS_ALL_SUCCESS](state, action) {
    const incident_reports_all = action?.payload;
    return Object.assign({}, state, { incident_reports_all });
  },
  [TIMEOFF_REQUESTS_ALL_SUCCESS](state, action) {
    const timeoff_requests_all = action?.payload;
    return Object.assign({}, state, { timeoff_requests_all });
  },
  [GET_INCIDENT_REPORTS_SUCCESS](state, action) {
    const incident_reports = action?.payload;
    return Object.assign({}, state, { incident_reports });
  },
  [GET_DETAIL_POLICIES_SUCCESS](state, action) {
    const policiesDetail = action?.payload;
    return Object.assign({}, state, { policiesDetail });
  },
  [GET_POLICIES_SUCCESS](state, action) {
    const policies = action?.payload;
    return Object.assign({}, state, { policies });
  },
  [TIMEOFF_DETAIL_REQUESTS_SUCCESS](state, action) {
    const timeoff_detail_requests = action?.payload;
    return Object.assign({}, state, { timeoff_detail_requests });
  },
  ["TIMEOFF_REQUESTS_STARTED"](state, action) {
    return Object.assign({}, state, { loadingTimeoff: true });
  },
  ["TIMEOFF_REQUESTS_ENDED"](state, action) {
    return Object.assign({}, state, { loadingTimeoff: false });
  },
  [TIMEOFF_REQUESTS_SUCCESS](state, action) {
    const timeoff_requests = action?.payload;
    return Object.assign({}, state, { timeoff_requests });
  },
  [TIMEOFF_REQUESTS_SUCCESS](state, action) {
    const timeoff_requests = action?.payload;
    return Object.assign({}, state, { timeoff_requests });
  },
  [GET_DETAIL_USERS_STARTED](state, action) {
    const usersDetail = action?.payload;
    return Object.assign({}, state, { usersDetail, loadingDetailUser: true });
  },
  [GET_DETAIL_USERS_SUCCESS](state, action) {
    const usersDetail = action?.payload;
    return Object.assign({}, state, { usersDetail, loadingDetailUser: false });
  },
  [GET_CURRENT_USER_SUCCESS](state, action) {
    const currentUserInfo = action?.payload;
    return Object.assign({}, state, { currentUserInfo });
  },
  [GET_CURRENT_PATIENT_SUCCESS](state, action) {
    const currentUserInfo = action?.payload;
    return Object.assign({}, state, { currentUserInfo });
  },
  [GET_CURRENT_PROVIDER_SUCCESS](state, action) {
    const currentUserInfo = action?.payload;
    return Object.assign({}, state, { currentUserInfo });
  },
  [GET_TIME_ENTRIES_SUCCESS](state, action) {
    const timeEntries = action?.payload;
    return {
      ...state,
      timeEntries,
    };
  },
  [GET_USERS_STARTED](state, action) {
    return Object.assign({}, state, { loading_user: true });
  },
  [GET_USERS_SUCCESS](state, action) {
    const users = action?.payload;
    return Object.assign({}, state, { users, loading_user: false });
  },
  [GET_PAYPERIODS_SUCCESS](state, action) {
    const payperiods = action?.payload;
    return {
      ...state,
      payperiods,
    };
  },
  [GET_ACTIVE_STAFF_STARTED](state, action) {
    return Object.assign({}, state, { loading_staff: true });
  },
  [GET_ACTIVE_STAFF_SUCCESS](state, action) {
    const activeStaff = action?.payload?.active_users;
    return Object.assign({}, state, { activeStaff, loading_staff: false });
  },
  [GET_DISEASES_STARTED](state) {
    return Object.assign({}, state, { loadingDiseases: true });
  },
  [GET_DISEASES_SUCCEEDED](state, action) {
    const diseases = action?.payload?.data;
    return Object.assign({}, state, { diseases, loadingDiseases: false });
  },
  [GET_SURGICAL_HISTORIES_STARTED](state) {
    return Object.assign({}, state, { loadingSurgicalHistories: true });
  },
  [GET_SURGICAL_HISTORIES_SUCCEEDED](state, action) {
    const surgicalHistories = action?.payload?.data;
    return Object.assign({}, state, {
      surgicalHistories,
      loadingSurgicalHistories: false,
    });
  },
  [GET_DETAIL_ULTRASOUNDS_SUCCEEDED](state, action) {
    const detailUltrasounds = action?.payload;
    return Object.assign({}, state, { detailUltrasounds });
  },
  [DELETE_ULTRASOUND_TYPE_STARTED](state, action) {
    return Object.assign({}, state, { loadingAPI: true });
  },
  [DELETE_ULTRASOUND_TYPE_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },
  [DELETE_LOCATION_STARTED](state, action) {
    return Object.assign({}, state, { loadingAPI: true });
  },
  [DELETE_LOCATION_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },
  [GET_DETAIL_LOCATIONS_STARTED](state, action) {
    return Object.assign({}, state, { loading_get_detail_location: true });
  },
  [GET_DETAIL_LOCATIONS_SUCCEEDED](state, action) {
    const detailLocations = action?.payload;
    return Object.assign({}, state, {
      detailLocations,
      loading_get_detail_location: false,
    });
  },
  [GET_DETAIL_PROCEDURE_DATA_STARTED](state, action) {
    return Object.assign({}, state, { loadingDetailProcedureData: true });
  },
  [GET_DETAIL_PROCEDURE_DATA_SUCCEEDED](state, action) {
    const detailProcedureData = action?.payload;
    if (detailProcedureData?.error) {
      return Object.assign({}, state, {
        detailProcedureData: {},
        loadingDetailProcedureData: false,
      });
    }
    return Object.assign({}, state, {
      detailProcedureData,
      loadingDetailProcedureData: false,
    });
  },
  [ADD_PROCEDURE_DATA_STARTED](state, action) {
    return Object.assign({}, state, {
      addProcedureData: true,
      isAddProcedureData: false,
    });
  },
  [ADD_PROCEDURE_DATA_SUCCEEDED](state, action) {
    const { error } = action?.payload || {};
    return Object.assign({}, state, {
      addProcedureData: false,
      isAddProcedureData: error ? false : true,
    });
  },
  [UPDATE_DETAIL_PROCEDURE_DATA_STARTED](state, action) {
    return Object.assign({}, state, {
      updatingProcedureData: true,
      isUpdatedProcedureData: false,
    });
  },
  [UPDATE_DETAIL_PROCEDURE_DATA_SUCCEEDED](state, action) {
    const { error } = action?.payload || {};
    return Object.assign({}, state, {
      updatingProcedureData: false,
      isUpdatedProcedureData: error ? false : true,
    });
  },
  [GET_DETAIL_CONDITION_DATA_STARTED](state, action) {
    return Object.assign({}, state, { loadingDetailConditionData: true });
  },
  [GET_DETAIL_CONDITION_DATA_SUCCEEDED](state, action) {
    const detailConditionData = action?.payload;
    return Object.assign({}, state, {
      detailConditionData,
      loadingDetailConditionData: false,
    });
  },
  [DELETE_PROCEDURE_DATA_STARTED](state, action) {
    return Object.assign({}, state, { loadingAPI: true });
  },
  [DELETE_PROCEDURE_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingAPI: false,
      isDeleted: true,
    });
  },
  [GET_LOCATIONS_SUCCEEDED](state, action) {
    const locations = action?.payload;
    return Object.assign({}, state, { locations });
  },
  [GET_CLINIC_LOCATIONS_SUCCEEDED](state, action) {
    const clinicLocations = action?.payload;
    return Object.assign({}, state, { clinicLocations });
  },
  [GET_CLINIC_NURSING_LOCATIONS_SUCCEEDED](state, action) {
    const clinicNursingLocations = action?.payload;
    return Object.assign({}, state, { clinicNursingLocations });
  },
  [GET_VHC_LOCATIONS_SUCCEEDED](state, action) {
    const vhcLocations = action?.payload;
    return Object.assign({}, state, { vhcLocations });
  },
  [GET_PROCEDURE_DATA_SUCCEEDED](state, action) {
    const procedureData = action?.payload;
    return Object.assign({}, state, { procedureData });
  },
  [GET_PROCEDURE_LOCATIONS_SUCCEEDED](state, action) {
    const procedureLocations = action?.payload;
    return Object.assign({}, state, { procedureLocations });
  },
  [GET_PROVIDER_USER_SUCCEEDED](state, action) {
    const providerUser = action?.payload;
    return Object.assign({}, state, { providerUser });
  },
  [GET_PROVIDERS_SUCCEEDED](state, action) {
    const providers = action?.payload;
    return Object.assign({}, state, { providers });
  },
  [GET_ONLY_PROVIDERS_SUCCEEDED](state, action) {
    const only_providers = action?.payload;
    return Object.assign({}, state, { only_providers });
  },
  [GET_PROVIDERS_SORT_SCHEDULE_SUCCEEDED](state, action) {
    const providers = action?.payload;
    return Object.assign({}, state, { providers });
  },
  [GET_ULTRASOUNDS_SUCCEEDED](state, action) {
    const ultrasounds = action?.payload;
    return Object.assign({}, state, { ultrasounds });
  },
  [FILTER_LOCATIONS_SUCCEEDED](state, action) {
    const filteredLocations = action?.payload;
    return Object.assign({}, state, { filteredLocations });
  },
  [FILTER_PROVIDERS_SUCCEEDED](state, action) {
    const filteredProviders = action?.payload;
    return Object.assign({}, state, { filteredProviders });
  },
  [GET_REFERRAL_CONDITIONS_SUCCEEDED](state, action) {
    const referralConditions = action?.payload;
    return Object.assign({}, state, { referralConditions });
  },
  [GET_REFERRED_PROVIDERS_SUCCEEDED](state, action) {
    const referredProviders = action?.payload;
    return Object.assign({}, state, { referredProviders });
  },
  [GET_PCP_LIST_STARTED](state, action) {
    return Object.assign({}, state, { loading_get_pcp: true });
  },
  [SET_PCP_NULL_STARTED](state, action) {
    return Object.assign({}, state, { pcpList: [], list_patients: [] });
  },
  [GET_PCP_LIST_SUCCEEDED](state, action) {
    const pcpList = action?.payload;
    return Object.assign({}, state, { loading_get_pcp: false, pcpList });
  },
  [GET_PCP_FROM_NPE_STARTED](state, action) {
    return Object.assign({}, state, { loading_get_pcp: true });
  },
  [GET_PCP_FROM_NPE_SUCCEEDED](state, action) {
    const pcpList = action?.payload?.results;
    return Object.assign({}, state, { loading_get_pcp: false, pcpList });
  },
  [GET_CALL_RESULTS_SUCCEEDED](state, action) {
    const callResult = action?.payload;
    return Object.assign({}, state, { callResult });
  },
  [EXPORT_PCM_STARTED](state) {
    return Object.assign({}, state, {
      export_pcm: {},
    });
  },
  [EXPORT_PCM_SUCCESS](state, action) {
    const export_pcm = action?.payload;
    return Object.assign({}, state, {
      export_pcm,
    });
  },
  [EXPORT_PCM_OVERVIEW_STARTED](state) {
    return Object.assign({}, state, {
      export_pcm_overview: {},
    });
  },
  [EXPORT_PCM_OVERVIEW_SUCCESS](state, action) {
    const export_pcm_overview = action?.payload;
    return Object.assign({}, state, {
      export_pcm_overview,
    });
  },
  [EXPORT_PCM_USER_STARTED](state) {
    return Object.assign({}, state, {
      export_pcm_user: {},
    });
  },
  [EXPORT_PCM_USER_SUCCESS](state, action) {
    const export_pcm_user = action?.payload;
    return Object.assign({}, state, {
      export_pcm_user,
    });
  },
  [SAVE_TEMP_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingCPT: true,
    });
  },
  [SAVE_TEMP_SUCCEEDED](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      loadingCPT: false,
      CPT,
      is_locked: CPT?.is_locked === 1 ? true : false,
    });
  },
  [GET_PHARMACIES_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [GET_PHARMACIES_SUCCEEDED](state, action) {
    const pharmacies = action?.payload;
    return Object.assign({}, state, {
      pharmacies,
      loadingPharmacies: false,
    });
  },
  [DELETE_PHARMACY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [DELETE_PHARMACY_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: false,
    });
  },
  [EDIT_PHARMACY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [EDIT_PHARMACY_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: false,
    });
  },
  [SAVE_PHARMACY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [SAVE_PHARMACY_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: false,
    });
  },
  [GET_PHARMACY_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [GET_PHARMACY_SUCCEEDED](state, action) {
    const pharmacy = action?.payload;
    return Object.assign({}, state, {
      pharmacy,
      loadingPharmacies: false,
    });
  },
  [GET_SINGLE_MEDICATION_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingPharmacies: true,
    });
  },
  [GET_SINGLE_MEDICATION_SUCCEEDED](state, action) {
    const medication = action?.payload;
    return Object.assign({}, state, {
      medication,
      loadingPharmacies: false,
    });
  },
  // Sidenav
  [UPDATE_SIDENAV_STATE_SUCCEEDED](state, action) {
    const sideNavStatus = action?.payload;
    return {
      ...state,
      sideNavStatus,
    };
  },
  [GET_LOCK_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingLock: true,
    });
  },
  [GET_LOCK_SUCCEEDED](state, action) {
    const editLock = action?.payload?.data || {};
    return Object.assign({}, state, {
      editLock,
      loadingLock: false,
    });
  },
  [RESET_MEDICATION_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      medication: {},
    });
  },
});

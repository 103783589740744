import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getEvaluations, getEvaluationsDetail, getUsers } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import CMESidebar from "./CMESidebar";
import EvaluationsFilter from "./EvaluationsFilter";

const CME = () => {
  const evaluations = useSelector((state) => state.common.evaluations);

  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [date, setDate] = useState(undefined);
  const [employee, setEmployee] = useState(undefined);

  const dispatch = useDispatch();

  const tableRows = evaluations ? evaluations : [];

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => {
    if (index != activeIndex) {
      setRow(index);
      dispatch(getEvaluationsDetail(id));
      handleSidebar();
    }
  };

  const fetchList = async () => {
    await dispatch(getEvaluations(date, employee));
    await dispatch(getUsers());
  };

  const changeDate = (value) => {
    const dateValue = value ? moment(value).format("MM/DD/YYYY") : null;
    setDate(date);
    dispatch(getEvaluations(dateValue, employee));
  };
  const changeEmployee = (value) => {
    const employeeValue = value ? value : null;
    setEmployee(employeeValue);
    dispatch(getEvaluations(date, employeeValue));
  };

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <EvaluationsFilter
            changeDate={(value) => {
              changeDate(value);
            }}
            changeEmployee={(value) => {
              changeEmployee(value);
            }}
          />
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="administrator_employee_cme"
                tableWidth={920}
                labels={["Employee Name", "CME Title", "CME Hours", "Uploaded Document"]}
                widths={["31%", "23%", "23%", "23%"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "cme_title" },
                  { sortable: false, key: "hours" },
                  { sortable: false, key: "document" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  name: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      <p style={{ paddingLeft: "0.75rem" }}>{row.user && row.user?.name}</p>
                    </div>
                  ),
                  cme_title: row.user && row.user.position,
                  hours: row.points,
                  document: row.work_score,
                }))}
                fetchList={fetchList}
                handleClickRow={(row, index) => handleRow(index, row?.id)}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {evaluations && (
        <CMESidebar
          ref={sidebarRef}
          evaluationsDetail={evaluations[activeIndex]}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default CME;

import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../common/components/Card";
import DataTable from "../../common/components/DataTable/WhiteHeaderTable";
import EditableFormInput from "../../common/components/EditableText/EditableFormInput";
import { getOnlyProviders, getProceduresAnalytics, setGlobalLoading } from "../../store/actions";
const dateFormat = "MM/DD/YYYY";

const ProcedureAnalytics = () => {
  const dispatch = useDispatch();
  const procedures_analytics = useSelector((state) => state.common.procedures_analytics);
  const only_providers = useSelector((state) => state.common.only_providers);

  const chartSelectOptions = [
    {
      id: 1,
      label: "Hospital",
      value: "hospital",
    },
    {
      id: 2,
      label: "OBL",
      value: "obl",
    },
  ];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tableData] = useState([]);
  const [referralsProviderHospital, setReferralsProviderHospital] = useState([]);
  const [referralsProviderAngio, setReferralsProviderAngio] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const [oblData, setOblData] = useState({
    labels: [],
    datasets: [],
  });
  const [procedureData, setProcedureData] = useState([]);
  const [chart, setChart] = useState("hospital");
  const [selectedProvider, setSelectedProvider] = useState(0);

  const fetchApi = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getOnlyProviders());
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    if (!only_providers) {
      fetchApi();
    }
  }, []);

  const getMillionsValue = (value) => {
    let newValue = value;
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + value).length / 3);
      let shortValue = "";
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  };

  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    defaults: {
      elements: {
        point: {
          backgroundColor: "#000000",
          borderColor: "#000000",
        },
      },
    },
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        position: "right",
        display: false,
        labels: {
          color: "#152935",
          family: '"IBM plex sans", "Helvetica Neue", sans-serif',
        },
      },
    },
    scales: {
      y: {
        min: 0,
        stacked: true,
        ticks: {
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#152935",
          callback: function (value, index, values) {
            return getMillionsValue(value);
          },
        },
      },
      x: {
        ticks: {
          padding: 10,
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#000",
        },
      },
    },
  };

  const fetchEvent = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getProceduresAnalytics(startDate, endDate, selectedProvider));
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchEvent();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!procedures_analytics) return;

    const {
      labels,
      obl_finalized_procedures,
      referrals_provider_hospital,
      obl_confirmed_but_no_show_data,
      obl_not_confirmed,
      referrals_provider_angio,
      obl_checkedin_procedures,
      hospital_finalized_procedures,
      hospital_not_finalized,
    } = procedures_analytics;
    setReferralsProviderHospital(referrals_provider_hospital);
    setReferralsProviderAngio(referrals_provider_angio);
    const newData = {};
    newData.labels = hospital_finalized_procedures?.map((data) => {
      return data?.procedure_date;
    });

    const newOblData = {};
    newOblData.labels = obl_finalized_procedures?.map((data) => {
      return data?.procedure_date;
    });

    if (chart == "hospital") {
      setProcedureData([
        {
          procedure: "Total hospital appointments that are finalized",
          total: labels?.hospital_finalized_label,
        },
        {
          procedure: "Total hospital appointments that are not finalized",
          total: labels?.hospital_not_finalized_label,
        },
      ]);
    } else {
      setProcedureData([
        {
          procedure: "Total OBL appointments that are finalized",
          total: labels?.obl_finalized_label,
        },
        {
          procedure: "Total OBL appointments that are checked-in",
          total: labels?.obl_checkedin_label,
        },
        {
          procedure: "Total OBL appointments that are confirmed but no show",
          total: labels?.obl_confirmed_but_no_show_label,
        },
        {
          procedure: "Total OBL appointments that are not confirmed",
          total: labels?.obl_not_confirmed_label,
        },
      ]);
    }

    newOblData.datasets = [
      {
        label: "OBL Finalized",
        data: obl_finalized_procedures?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(25,128,56,0.75)",
        borderColor: "rgba(25,128,56,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "OBL Checked in",
        data: obl_checkedin_procedures?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(130,207,255,0.75)",
        borderColor: "rgba(130,207,255,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "OBL Confirmed but No Show",
        data: obl_confirmed_but_no_show_data?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,231,163,0.75)",
        borderColor: "rgba(255,231,163,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "OBL Not Confirmed",
        data: obl_not_confirmed?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,175,210,0.75)",
        borderColor: "rgba(255,175,210,1)",
        pointBackgroundColor: "#000000",
      },
    ];

    newData.datasets = [
      {
        label: "Hospital Billed",
        data: hospital_finalized_procedures?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(25,128,56,0.75)",
        borderColor: "rgba(25,128,56,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "Hospital Not Billed",
        data: hospital_not_finalized?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,175,210,0.75)",
        borderColor: "rgba(255,175,210,1)",
        pointBackgroundColor: "#000000",
      },
    ];

    setData(newData);
    setOblData(newOblData);
  }, [procedures_analytics, chart]);

  const minRowCount = 8;
  let emptyRows = [];
  if (tableData?.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - tableData?.length).fill({ label: "" });
  }

  const changeChart = (value) => {
    if (value == 2) setChart("obl");
    else setChart("hospital");

    const { labels } = procedures_analytics;

    if (value === "obl") {
      setProcedureData([
        {
          procedure: "Total OBL appointments that are finalized",
          total: labels?.obl_finalized_label,
        },
        {
          procedure: "Total OBL appointments that are checked-in",
          total: labels?.obl_checkedin_label,
        },
        {
          procedure: "Total OBL appointments that are confirmed but no show",
          total: labels?.obl_confirmed_but_no_show_label,
        },
        {
          procedure: "Total OBL appointments that are not confirmed",
          total: labels?.obl_not_confirmed_label,
        },
      ]);
    } else if (value === "hospital") {
      setProcedureData([
        {
          procedure: "Total hospital appointments that are finalized",
          total: labels?.hospital_finalized_label,
        },
        {
          procedure: "Total hospital appointments that are not finalized",
          total: labels?.hospital_not_finalized_label,
        },
      ]);
    }
  };

  const changeProvider = (value) => {
    setSelectedProvider(value);
    dispatch(getProceduresAnalytics(startDate, endDate, value));
  };

  return (
    <React.Fragment>
      <div className="cardBlock" style={{ height: "calc(65% - 20px)", width: "100%" }}>
        <div className="filterContainer" style={{ padding: 0, paddingRight: "35px", paddingLeft: "35px" }}>
          <div className="cardBlock__title">Procedural Volume</div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <EditableFormInput
              rmBorder={true}
              label={"Provider"}
              value={selectedProvider}
              handleChange={changeProvider}
              fullWidth={true}
              type={"check"}
              tabIndex={3}
              optionKey={"id"}
              valueKey={"value"}
              options={only_providers || []}
            />
          </div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <EditableFormInput
              label={"Select Chart"}
              rmBorder={true}
              value={chart}
              handleChange={changeChart}
              fullWidth={true}
              type={"check"}
              tabIndex={3}
              optionKey={"id"}
              valueKey={"value"}
              options={chartSelectOptions}
            />
          </div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <DatePicker
              format={dateFormat}
              placeholder="Start Date"
              value={startDate}
              onChange={(val) => {
                setStartDate(val);
              }}
            />
          </div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <DatePicker
              format={dateFormat}
              placeholder="End Date"
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
              }}
            />
          </div>
        </div>
        <div style={{ height: "90%", overflow: "scroll" }}>
          {chart == "hospital" && (
            <>
              <Line data={data} options={chartOptions} height={75} />
            </>
          )}

          {chart == "obl" && (
            <>
              <Line data={oblData} options={chartOptions} height={75} />
            </>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "calc(35% - 10px)",
          display: "flex",
        }}
      >
        <CardBlock title="Procedure Breakdown" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="procedure-breakdown"
            labels={["Procedures", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "procedure" },
              { sortable: false, key: "total" },
            ]}
            rowData={procedureData?.map((item, index) => {
              return {
                procedure: <b>{item.procedure}</b>,
                total: item.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
        <CardBlock title="Provider Hospital Volume" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="provider-hospital-volume"
            labels={["Provider", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "value" },
              { sortable: false, key: "total" },
            ]}
            rowData={referralsProviderHospital.map((provider, index) => {
              return {
                id: index,
                value: provider?.value,
                total: provider.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
        <CardBlock title="Provider OBL Volume" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="provider-obl-volume"
            labels={["Provider", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "value" },
              { sortable: false, key: "total" },
            ]}
            rowData={referralsProviderAngio.map((provider, index) => {
              return {
                id: index,
                value: provider?.value,
                total: provider.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
      </div>
    </React.Fragment>
  );
};

export default ProcedureAnalytics;

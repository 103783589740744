import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import Loading from "../../../../common/components/Loading";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { saveProcedureCondition, setGlobalLoading } from "../../../../store/actions/common.js";

const SchedulesSidebar = React.forwardRef(
  ({ isShow, handleCloseSidebar, conditions, userDetail, procedureData, selectedProcedureData, selectedConditions }, ref) => {
    //
    const [inputCondition, setInputCondition] = useState("");
    const [loading, setLoading] = useState(false);
    const [listResultCondition, setListResultCondition] = useState([]);
    const [listSlectedCondition, setListSlectedCondition] = useState([]);
    const [isLoadingCondition, setIsLoadingCondition] = useState(false);
    const [isResultCondition, setIsResultCondition] = useState(false);
    const [isToastr, setIsToastr] = useState(false);
    //
    const [inputProcedure, setInputProcedure] = useState("");
    const [listResultProcedure, setListResultProcedure] = useState([]);
    const [listSlectedProcedure, setListSlectedProcedure] = useState([]);
    const [isLoadingProcedure, setIsLoadingProcedure] = useState(false);
    const [isResultProcedure, setIsResultProcedure] = useState(false);

    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const [reset, setReset] = useState(false);

    const dispatch = useDispatch();

    const handleSearchCondition = (search) => {
      setInputCondition(search);
      setIsLoadingCondition(true);
      setIsResultCondition(true);
      const fillter =
        conditions && conditions.filter((location) => location?.value.toLowerCase().includes(search.toLowerCase()) && search.length > 0);
      if (search.length === 0) {
        setIsLoadingCondition(false);
        setIsResultCondition(false);
      } else {
        setTimeout(() => {
          setListResultCondition(fillter);
          setIsLoadingCondition(false);
          setIsResultCondition(true);
        }, 600);
      }
    };

    const selectItemCondition = (item) => {
      if (item.length > 0) {
        setListSlectedCondition([...listSlectedCondition, item]);
        setInputCondition("");
        setIsResultCondition(false);
      }
    };

    const removeRowCondition = (index) => {
      setIsLoadingCondition(true);
      const tasks = [...listSlectedCondition];
      const idDex = tasks.findIndex((r) => r === index);
      tasks.splice(idDex, 1);
      setListSlectedCondition(tasks);
      setTimeout(() => {
        setIsLoadingCondition(false);
      }, 0);
    };
    //
    const handleSearchProcedure = (search) => {
      setInputProcedure(search);
      setIsLoadingProcedure(true);
      setIsResultProcedure(true);
      const fillter =
        procedureData &&
        (procedureData || []).filter((location) => location?.value.toLowerCase().includes(search.toLowerCase()) && search.length > 0);
      if (search.length === 0) {
        setIsLoadingProcedure(false);
        setIsResultProcedure(false);
      } else {
        setTimeout(() => {
          setListResultProcedure(fillter);
          setIsLoadingProcedure(false);
          setIsResultProcedure(true);
        }, 600);
      }
    };

    const selectItemProcedure = (item) => {
      if (item.length > 0) {
        setInputProcedure("");
        setIsResultProcedure(false);
        setListSlectedProcedure([...listSlectedProcedure, item]);
      }
    };

    const removeRowProcedure = (index) => {
      setIsLoadingProcedure(true);
      const tasks = [...listSlectedProcedure];
      const idDex = tasks.findIndex((r) => r === index);
      tasks.splice(idDex, 1);
      setListSlectedProcedure(tasks);
      setTimeout(() => {
        setIsLoadingProcedure(false);
      }, 0);
    };

    const saveAll = async () => {
      setLoading(true);
      const DataConditon = [];
      for (const key in listSlectedCondition) {
        const checked = conditions.find((r) => listSlectedCondition && r?.value === listSlectedCondition[key]);
        DataConditon.push(checked && checked?.id);
      }
      const DatProcedure = [];
      for (const key in listSlectedProcedure) {
        const checked = procedureData.find((r) => listSlectedProcedure && r?.value === listSlectedProcedure[key]);
        DatProcedure.push(checked && checked?.id);
      }
      const saveAllSuccess = await dispatch(
        saveProcedureCondition(userDetail?.id, {
          procedures: DatProcedure,
          conditions: DataConditon,
        })
      );
      if (saveAllSuccess) {
        setLoading(false);
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        setLoading(false);
      }
    };

    useEffect(() => {
      setListSlectedProcedure(selectedProcedureData);
      setListSlectedCondition(selectedConditions);
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }, [selectedProcedureData, selectedConditions]);

    useEffect(() => {
      if (isShow && userDetail && Object.keys(userDetail).length) {
        dispatch(setGlobalLoading(loading));
      }
    }, [loading, isShow, userDetail]);

    useEffect(() => {
      if (reset) {
        saveAll();
      }
    }, [listSlectedCondition, listSlectedProcedure]);

    if (!userDetail || !Object.keys(userDetail).length) {
      return null;
    }

    const renderTitle = () => {
      return <span className="text-uppercase">{userDetail?.value}</span>;
    };

    return (
      <Drawer
        title={userDetail?.value || " "}
        placement="right"
        closable
        className={sideNavStatus ? "wide" : ""}
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
      >
        {loading && <Loading />}
        <div ref={ref} className="resourceContainer new">
          {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}

          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingRight: "16px",
                paddingLeft: "24px",
              }}
            >
              <div className="resourceInfo d-flex">
                <b>Disease Conditions Treated</b>
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem", paddingRight: 0 }}>
                <AutoCompleteComponent
                  title="Disease-Conditions"
                  placeholder="Search Disease Conditions"
                  keyLabel={"value"}
                  disableSearchIcon={false}
                  loading={isLoadingCondition}
                  isResult={isResultCondition}
                  handleSearch={handleSearchCondition}
                  query={inputCondition}
                  selectItem={selectItemCondition}
                  removeItem={removeRowCondition}
                  rows={listSlectedCondition}
                  options={listResultCondition}
                />
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "1rem" }}>
                <b>Procedures Performed</b>
              </div>
              <div className="resourceInfo d-flex" style={{ marginTop: "0.5rem", paddingRight: 0 }}>
                <AutoCompleteComponent
                  title="Procedures-Performed"
                  placeholder="Search Procedures Perfromed"
                  keyLabel={"value"}
                  disableSearchIcon={false}
                  loading={isLoadingProcedure}
                  isResult={isResultProcedure}
                  handleSearch={handleSearchProcedure}
                  query={inputProcedure}
                  selectItem={selectItemProcedure}
                  removeItem={removeRowProcedure}
                  rows={listSlectedProcedure}
                  options={listResultProcedure}
                />
              </div>
              <hr />
            </div>
            <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }} />
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default SchedulesSidebar;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../common/components/Card";
import {
  addProcedureDetails,
  edit_clinic_procedure_type_variable,
  list_clinic_procedure_type_variable,
  save_clinic_procedure_type_variable,
  setProcedureDetails,
  update_cpt_clinic_procedure_type,
  update_icd_clinic_procedure_type,
} from "../../../store/actions";
import "../style.scss";
import ClinicSearch from "./ClinicSearch";
import CPTCodeLogicBlock from "./cptCodeLogic";
import IndicationCode from "./IndicationCode";
import SupportLocationBlock from "./LocationsAndSupport";
import ProviderBlock from "./ProcedureProviders";
import DocumentationBlock from "./TemplateDocumentation";
import VariableCaptureBlock from "./VariableCapture";

const ClinicDetail = (props) => {
  const clinicVariable = useSelector((state) => state.procedureDetail.clinicVariable);
  const cptCodesClinic = useSelector((state) => state.procedureDetail.cptCodesClinic);
  const icdCodesClinic = useSelector((state) => state.procedureDetail.icdCodesClinic);
  const detail_clinic_type = useSelector((state) => state.procedureDetail.detail_clinic_type);
  const list_clinic_type_variable = useSelector((state) => state.procedureDetail.list_clinic_type_variable);
  const dispatch = useDispatch();

  const [activeCard, setActiveCard] = useState("");
  const [resource, setResource] = useState(null);

  const handleAddNewData = async (type, data) => {
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        save_clinic_procedure_type_variable({
          question: "",
          clinic_procedure_type_id: detail_clinic_type?.id,
          answers: [],
        })
      );
      await dispatch(list_clinic_procedure_type_variable(detail_clinic_type?.id));
    }
  };

  const updateICD = async () => {
    const arrayADD = (icdCodesClinic || []).map((r) => {
      return {
        disease_id: r?.disease_id,
        clinic_procedure_type_id: detail_clinic_type?.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                icd_code: { icd_code: "", icd_name: "" },
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      disease_id: 1,
      clinic_procedure_type_id: detail_clinic_type?.id,
      condition_serialize: [
        {
          icd_code: { icd_code: "", icd_name: "" },
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(setProcedureDetails("icdCodesClinic", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      update_icd_clinic_procedure_type(detail_clinic_type?.id, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  const updateCPT = async () => {
    const arrayADD = (cptCodesClinic || []).map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        clinic_procedure_type_id: detail_clinic_type?.id,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                cpt_code: { code: "", short_desc: "" },
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
              },
            ],
      };
    });
    const param = {
      cpt_code_id: 1,
      clinic_procedure_type_id: detail_clinic_type?.id,
      condition_serialize: [
        {
          cpt_code: { code: "", short_desc: "" },
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
        },
      ],
    };
    await dispatch(setProcedureDetails("cptCodesClinic", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      update_cpt_clinic_procedure_type(detail_clinic_type?.id, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  const update = async () => {
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      const index = clinicVariable && clinicVariable.length;
      if (index > 0) {
        clinicVariable[`${index - 1}`]["answer"] = [
          ...clinicVariable[`${index - 1}`]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(setProcedureDetails("clinicVariable", [...clinicVariable]));
        // call api save answer
        await dispatch(
          edit_clinic_procedure_type_variable(list_clinic_type_variable && list_clinic_type_variable[list_clinic_type_variable.length - 1]["id"], {
            variable_name: list_clinic_type_variable && list_clinic_type_variable[list_clinic_type_variable.length - 1]["variable_name"],
            question: clinicVariable && clinicVariable.length > 0 && clinicVariable[clinicVariable.length - 1]["quest"],
            clinic_procedure_type_id: detail_clinic_type?.id,
            answers: clinicVariable && clinicVariable.length > 0 && clinicVariable[clinicVariable.length - 1]["answer"],
          })
        );
      }
      await dispatch(list_clinic_procedure_type_variable(detail_clinic_type?.id));
    }
  };

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Search Clinic Details" varient="quarter" other="" contentPadding="1rem">
            <ClinicSearch setResource={setResource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Clinic Detail Providers" varient="quarter" other="" contentPadding="1rem">
            <ProviderBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Clinic Location" varient="quarter" other="" contentPadding="1rem">
            <SupportLocationBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock title="Template Documentation" varient="quarter" other="" contentPadding="1rem">
            <DocumentationBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            title="Variable Capture"
            onClick={() => {
              setActiveCard("variable_capture");
            }}
            varient={activeCard == "" || activeCard == "variable_capture" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={detail_clinic_type && Object.keys(detail_clinic_type).length <= 0}
                  className="text-btn"
                  onClick={() => {
                    handleAddNewData("clinicVariable", {
                      quest: "",
                      answer: [],
                    });
                  }}
                >
                  Add Specification +
                </button>
                <button
                  disabled={detail_clinic_type && Object.keys(detail_clinic_type).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={update}
                >
                  Add Answer +
                </button>
              </div>
            }
          >
            <VariableCaptureBlock resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            title="ICD Code List"
            onClick={() => {
              setActiveCard("icd_code_list");
            }}
            varient={activeCard == "icd_code_list" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={detail_clinic_type && Object.keys(detail_clinic_type).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updateICD}
                >
                  Add +
                </button>
              </div>
            }
          >
            <IndicationCode resource={resource} />
          </CardBlock>
          {/*  */}
          <CardBlock
            isOverFlow={true}
            title="CPT Code Logic"
            onClick={() => {
              setActiveCard("cpt_code_logic");
            }}
            varient={activeCard == "cpt_code_logic" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={detail_clinic_type && Object.keys(detail_clinic_type).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updateCPT}
                >
                  Add +
                </button>
              </div>
            }
          >
            <CPTCodeLogicBlock resource={resource} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ClinicDetail;

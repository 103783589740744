import {createReducer} from 'redux-create-reducer';
import moment from 'moment';
import AT from '../actionTypes';

const initialState = {
  consentDocuments: [],
  allProcedures: [],
  allProviders: [],
  newDocumentData: undefined,
  saving: '',
  selectedDocumentData: undefined,
  signature_image: undefined,
  searchData: {
    date: undefined,
    dateValue: undefined,
    procedure: undefined,
    provider_id: undefined,
  },
};

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.PROCEDURE_CONSENT_LOAD_INIT}_SUCCEEDED`](state, action) {
    const {consentDocuments, allProcedures, allProviders} =
      action?.payload || {};

    return {
      ...state,
      consentDocuments,
      allProcedures,
      allProviders,
    };
  },
  [`${AT.PROCEDURE_CONSENT_OPEN_CREATE}`](state, action) {
    const newDocumentData = action?.payload;

    return {
      ...state,
      newDocumentData,
    };
  },
  [`${AT.PROCEDURE_CONSENT_SAVE_DOCUMENT}_STARTED`](state, action) {
    return {
      ...state,
      saving: 'now',
    };
  },
  [`${AT.PROCEDURE_CONSENT_SAVE_DOCUMENT}_SUCCEEDED`](state, action) {
    return {
      ...state,
      saving: 'done',
    };
  },
  [`${AT.PROCEDURE_CONSENT_CLOSED_CREATE}`](state, action) {
    return {
      ...state,
      saving: '',
    };
  },
  [`${AT.PROCEDURE_CONSENT_SELECT_DOCUMENT}`](state, action) {
    const {selectedDocumentData} = action?.payload || {};

    return {
      ...state,
      selectedDocumentData,
    };
  },
  [`${AT.PROCEDURE_CONSENT_SET_SEARCH_DATA}`](state, action) {
    const {searchData} = action?.payload || {};
    return {
      ...state,
      searchData,
    };
  },
  [`${AT.PROCEDURE_CONSENT_SEARCH}_SUCCEEDED`](state, action) {
    const {consentDocuments} = action?.payload || {};
    return {
      ...state,
      consentDocuments,
    };
  },
  [`${AT.PROCEDURE_CONSENT_GET_SIGNATURE_IMAGE}_SUCCEEDED`](state, action) {
    const {signature_image} = action?.payload || {};

    return {
      ...state,
      signature_image,
    };
  },
  [`${AT.PROCEDURE_CONSENT_SET_SIGNATURE_IMAGE}_SUCCEEDED`](state, action) {
    const {signature_image} = action?.payload || {};

    return {
      ...state,
      signature_image,
    };
  },
  [`${AT.PROCEDURE_CONSENT_DELETE}_SUCCEEDED`](state, action) {
    return {
      ...state,
      selectedDocumentData: undefined,
    };
  },
});

import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useWindowSize, useOutsideDetect, getFullNameInCapitalize } from "../../../common/utils";
import { getProviderPatientsByQuery, getPatientSearchHistory, setPatientSnapshotData, selectPatient } from "../../../store/actions";
import { setLocal } from "../../../helpers/Local";

const { Search } = Input;

const PatientSearch = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const history = useHistory();
  const patients = useSelector((state) => state.dashboard.patients);
  const searchHistory = useSelector((state) => state.dashboard.searchHistory);
  const addedSearchHistory = useSelector((state) => state.dashboard.addedSearchHistory);
  const dispatch = useDispatch();
  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const headerLabels = ["Patient Name", "DOB", "PCP", "Last Accessed"];

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(getPatientSearchHistory());
  }, []);

  useEffect(() => {
    if (addedSearchHistory) {
      dispatch(getPatientSearchHistory());
    }
  }, [addedSearchHistory]);

  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(getProviderPatientsByQuery(search));
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  const tableRows = useMemo(() => {
    if (patients && patients.length > 0) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  if (rowCount > (searchHistory ? searchHistory.length : 0)) {
    emptyRows = new Array(rowCount - (searchHistory ? searchHistory.length : 0)).fill({});
  }

  const handleSearch = (e) => {
    setSearch(e.target?.value);
    setOpen(true);
  };

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    await setLocal("current_patient_id", row?.patient_id);
    history.push("/referral-patient-records");
  };

  const handleSearchSelect = (row, index) => {
    setLocal("current_patient_id", row?.id);
    setActiveSearch(index);
    setActiveSearchRecords(row);
    setSearch(`${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`);
    setOpen(false);
    handleAddHistory(row);
  };

  const handleAddHistory = async (searchRow = null) => {
    if (activeSearchRow) {
      setActiveRow(0);
      setActiveSearchRecords(null);
      history.push("/referral-patient-records");
    } else if (searchRow) {
      setActiveRow(0);
      setActiveSearchRecords(null);
      history.push("/referral-patient-records");
    }
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      const check = await dispatch(selectPatient(activeSearchRow?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      setActiveRow(0);
      setActiveSearchRecords(null);
      setOpen(false);
      history.push("/referral-patient-records");
    }
  };

  const onKeyDown = (type) => {
    if (activeSearch && rowData) {
      if (type == "down") {
        if (rowData && rowData[activeSearch + 1]) {
          setLocal("current_patient_id", rowData?.[activeSearch + 1]?.id);
          setActiveSearchRecords(rowData[activeSearch + 1]);
          setActiveSearch(activeSearch + 1);
        }
      } else {
        setActiveSearch(activeSearch - 1);
        setActiveSearchRecords(rowData[activeSearch - 1]);
        setLocal("current_patient_id", rowData?.[activeSearch - 1]?.id);
      }
    }
  };

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div ref={ref} className="patientSearch__filter-search">
          <Search
            value={search}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            placeholder="Search Patient"
            onChange={(value) => {
              handleSearch(value);
            }}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
            onFocus={() => setOpen(true)}
          />
        </div>
      </div>
      {openPanel && search && rowCount !== NaN && rowData?.length > 0 && tableRows.length > 0 && (
        <div
          ref={ref1}
          className="fullContainer patientSearch__result"
          style={
            rowData?.length <= rowCount - 1
              ? {
                  height: "auto",
                  maxHeight: "unset",
                }
              : {}
          }
        >
          <div
            className="tableSection"
            style={{
              height: rowData?.length <= rowCount - 1 ? "auto" : "100%",
              position: "relative",
            }}
          >
            <div className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll} disableScroll={rowData?.length <= rowCount - 1}>
                <div className="tableList">
                  {rowData?.map((row, index) => (
                    <div
                      className={`tableItem ${index === activeSearch ? "active" : ""}`}
                      key={`resultTable-${index}`}
                      onClick={() => handleSearchSelect(row, index)}
                    >
                      <div className="td with-icon" style={{ width: "100%" }}>
                        <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                          row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                        }`}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      )}
      <div className="fullContainer" style={{ padding: "10px 0 0 0", position: "relative" }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div ref={ref2} className="tableContainer">
            <div className="tableHeader">
              {headerLabels.map((label, index) => (
                <div className="head-cell" key={index}>
                  <span>{label}</span>
                </div>
              ))}
            </div>
            <ScrollWrapper css="no-padding-table x-hidden-table">
              <div className="tableList" style={{ minWidth: "560px" }}>
                {(searchHistory || []).map((row, index) => (
                  <div
                    className={`tableItem ${index === activeRow ? "active" : ""}`}
                    key={`resultTable-${index}`}
                    onClick={() => handleSelect(row, index)}
                  >
                    <div className="td with-icon" style={{ width: "30%" }}>
                      {row?.gender && row?.gender.toLowerCase() === "male" ? <MaleSVG /> : <FemaleSVG />}
                      <p>{`${row?.patient_name || ""}`}</p>
                    </div>
                    <div className="td" style={{ width: "20%" }}>
                      <p>{row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="td" style={{ width: "20%", textTransform: "capitalize" }}>
                      <p>{getFullNameInCapitalize(row?.referral_provider)}</p>
                    </div>
                    <div className="td" style={{ width: "30%" }}>
                      <p>{row?.date && moment(row?.date).format("MM/DD/YYYY HH:mm A")}</p>
                    </div>
                  </div>
                ))}
                {(emptyRows || []).map((row, index) => (
                  <div className={`tableItem`} key={`empty-resultTable-${index}`}>
                    <div className="td with-icon" style={{ width: "30%" }} />
                    <div className="td" style={{ width: "20%" }} />
                    <div className="td" style={{ width: "30%" }} />
                    <div className="td" style={{ width: "20%" }} />
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSearch;

import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { setLocal } from "../../../helpers/Local";
import {
  delete_clinic_procedure_type,
  detail_clinic_procedure_type,
  edit_clinic_procedure_type,
  list_clinic_procedure_type,
  list_clinic_procedure_type_variable,
  removeDetailClinic,
  save_clinic_procedure_type,
  setGlobalLoading,
} from "../../../store/actions";

const { Search } = Input;
let timer = null;

const ClinicSearch = ({ setResource }) => {
  const dispatch = useDispatch();
  const [clinicID, setClinicId] = useState(0);
  const loading = useSelector((state) => state.procedureDetail.loading_clinic_type);
  const list_clinic_type = useSelector((state) => state.procedureDetail.list_clinic_type);

  const [activeRow, setActiveRow] = useState(-1);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [dataRow, setDataRow] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [search, setSearch] = useState("");
  const [headerTitle, setHeaderTitle] = useState("");
  const emptyRows = [];

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    if (clinicID !== row?.id) {
      setClinicId(row?.id);
      setResource(row?.id);
      setLocal("id_clinic_type", row?.id);
      await dispatch(detail_clinic_procedure_type(row?.id));
      await dispatch(list_clinic_procedure_type_variable(row?.id));
    }
  };

  const handleAddAngio = async () => {
    if (!search.trim()) {
      return;
    }
    setLoadingLocal(true);
    const param = {
      name: search,
      locations: "[]",
      providers: "[]",
      ultrasounds: "[]",
    };
    const addsuccess = await dispatch(save_clinic_procedure_type(param));
    if (addsuccess) {
      setSearch("");
      setLoadingLocal(false);
      dispatch(list_clinic_procedure_type());
    }
  };

  const handleSearch = (e) => {
    const value = e.target?.value;
    setSearch(value);
    setActiveRow(-1);
  };

  useEffect(() => {
    const arrayFilter = [...list_clinic_type].reverse();
    const arraySucces = arrayFilter.filter((item) => {
      return item?.name.toLowerCase().includes(search.toLowerCase());
    });
    setDataRow([...arraySucces]);
  }, [list_clinic_type, search]);

  const fetchAPI = async () => {
    setLoadingLocal(true);
    const loadSuccess = await dispatch(list_clinic_procedure_type());
    if (loadSuccess) {
      setLoadingLocal(false);
    }
  };

  const handleDelete = async (row, index) => {
    if (activeDelete !== row?.id) {
      setActiveDelete(row?.id);
      return;
    }
    if (index === activeRow) {
      setActiveRow(-1);
    }
    if (clinicID === activeDelete) {
      setClinicId(-1);
      setResource(-1);
      setLocal("id_angio_type", 0);
    }
    const deleteSuccess = await dispatch(delete_clinic_procedure_type(row?.id));
    const loadSuccess = await dispatch(list_clinic_procedure_type());
    if (deleteSuccess && loadSuccess) {
      setActiveDelete(-1);
      setActiveRow(-1);
      dispatch(removeDetailClinic());
    }
  };

  const onChangeDetail = async (e, row) => {
    setHeaderTitle(e.target?.value);
    clearTimeout(timer);
    const params = {
      name: e.target?.value,
    };
    timer = setTimeout(async () => {
      await dispatch(edit_clinic_procedure_type(row?.id, params));
      await dispatch(list_clinic_procedure_type());
    }, 500);
  };

  useEffect(() => {
    fetchAPI();
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingLocal || loading));
  }, [loading, loadingLocal]);

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search value={search} placeholder="Search Clinic Details" onChange={handleSearch} suffix={<SearchSVG />} />
        </div>
        <div className="patientSearch__filter-action">
          <button className="patientSearch__filter-add" onClick={handleAddAngio}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="fullContainer" style={{ position: "relative" }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div className="tableContainer">
            <ScrollWrapper css="no-padding-table">
              <div className="tableList">
                {(dataRow || []).map((row, index) => (
                  <div
                    className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                    key={`resultTable-${index}`}
                    onClick={() => {
                      handleSelect(row, index);
                    }}
                    style={{ paddingRight: "10px" }}
                  >
                    {activeRow === index ? (
                      <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                        <Input
                          value={headerTitle}
                          autoFocus={true}
                          onChange={(e) => {
                            onChangeDetail(e, row);
                          }}
                          onFocus={() => {
                            setHeaderTitle(row?.name);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                        <p>{row?.name}</p>
                      </div>
                    )}
                    {row?.is_default === 1 ? null : (
                      <DeleteIcon icon="can" handleClick={() => handleDelete(row, index)} selected={activeDelete === row?.id} />
                    )}
                  </div>
                ))}
                {(emptyRows || []).map((row, index) => (
                  <div className={`tableItem`} key={`empty-resultTable-${index}`}>
                    <div className="td" style={{ width: "50%" }} />
                    <div className="td" style={{ width: "50%" }} />
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicSearch;

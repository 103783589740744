import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import * as _ from "lodash";
import * as dates from "date-arithmetic";
import { Select, DatePicker } from "antd";

import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import { getOpeningAppointments, eventStyleGetter, getNewDate, snakeToTitle } from "../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { getProvidersLocation } from "../../../store/actions";

const { Option } = Select;

const localizer = momentLocalizer(moment);
const start = dates.startOf(new Date(), "day");
const min = dates.add(start, 7, "hours");
const max = dates.add(min, 14, "hours");

export class CustomToolbar extends React.PureComponent {
  state = {
    view: "week",
  };

  onChangeDate = (date, dateString) => {
    this.props.handleDate(date, dateString);
  };

  navigate = (action) => () => {
    const { onView } = this.props;
    this.setState({ view: action });
    onView(action);
  };

  render() {
    const {
      label,
      selectedDate,
      selectedLocation,
      selectedProvider,
      handleProvider,
      handleLocation,
      locations,
      providers,
      view,
    } = this.props;
    const dateFormat = "MM/DD/YYYY";

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button">Prev</button>
        </span>
        <span className="rbc-toolbar-filter">
          <div className="rbc-dropdown-Filter">
            <DatePicker
              value={selectedDate ? moment(selectedDate, dateFormat) : undefined}
              format={dateFormat}
              placeholder="Filter by Date"
              suffixIcon={<ExpandSVG />}
              onChange={this.onChangeDate}
            />
          </div>
          <div className="rbc-dropdown-Filter location">
            <Select
              placeholder="Filter by Location"
              suffixIcon={<ExpandSVG />}
              value={selectedLocation}
              onChange={handleLocation}
              allowClear={true}
            >
              {(locations || []).map((location, index) => (
                <Option key={`location-${location?.id}-${index}`} value={location?.id}>
                  {location?.value}
                </Option>
              ))}
            </Select>
          </div>
          <div className="rbc-dropdown-Filter provider">
            <Select
              placeholder="Filter by Provider"
              suffixIcon={<ExpandSVG />}
              value={selectedProvider}
              onChange={handleProvider}
              allowClear={true}
            >
              {(providers || []).map((provider, index) => (
                <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                  {provider?.value}
                </Option>
              ))}
            </Select>
          </div>
        </span>
        <span className="rbc-btn-group">
          <button className={`${view === "month" && "rbc-active"}`} type="button" onClick={this.navigate("month")}>
            Monthly
          </button>
          <button className={`${view === "week" && "rbc-active"}`} type="button" onClick={this.navigate("week")}>
            Weekly
          </button>
          <button className={`${view === "day" && "rbc-active"}`} type="button" onClick={this.navigate("day")}>
            Daily
          </button>
        </span>
      </div>
    );
  }
}

const ClinicalCalendar = ({
  eventData,
  locations,
  providers,
  fetchEvents,
  activeOpenings,
  closedDates,
  openingTime,
  closingTime,
  onClickEvent,
  activeID,
}) => {
  const [view, setView] = useState("week");
  const [date, setDate] = useState(getNewDate(moment(new Date())));
  const dispatch = useDispatch();
  const provider_location = useSelector((state) => state.common.provider_location);
  const [dateString, setDateString] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [provider, setProvider] = useState();
  const [active, setActive] = useState(false);
  const [location, setLocation] = useState();

  const onViewChange = (v) => {
    setView(v);
  };

  const handleDate = (newDate, newDateString) => {
    if (newDate == null || newDateString == null) {
      setDate(null);
      setDateString(null);
    } else {
      setDate(getNewDate(newDate));
      setDateString(newDateString);
      fetchEvents(newDateString, location, provider);
    }
  };

  const handleProvider = (value) => {
    const newProvider = value ? value : "";
    setProvider(value);
    fetchEvents(dateString, location, newProvider);
  };

  const handleLocation = async (value) => {
    if (value) {
      setActive(true);
    } else {
      setActive(false);
    }
    await dispatch(getProvidersLocation(value));
    const newLocation = value ? value : "";
    setLocation(value);
    fetchEvents(dateString, newLocation, provider);
  };

  const events = getOpeningAppointments(
    view,
    date,
    eventData,
    activeOpenings,
    closedDates,
    openingTime,
    closingTime,
    activeID,
  );
  const tooltip =
    view === "month" ? null : (event) => event.title + (event.status ? " (" + snakeToTitle(event.status) + ")" : "");

  return (
    <Calendar
      localizer={localizer}
      // scrollToTime={new Date().setHours(0, 55)}
      events={events}
      timeslots={1}
      step={15}
      min={min}
      max={max}
      date={date}
      defaultView={view}
      getNow={() => date}
      tooltipAccessor={tooltip}
      onNavigate={() => console.log("navigate")}
      eventPropGetter={eventStyleGetter}
      onView={onViewChange}
      onSelectEvent={(event) => onClickEvent(event)}
      components={{
        toolbar: (props, index) => (
          <CustomToolbar
            {...props}
            index={index}
            fetchEvents={fetchEvents}
            handleDate={handleDate}
            selectedDate={dateString}
            handleProvider={handleProvider}
            handleLocation={handleLocation}
            selectedLocation={location}
            selectedProvider={provider}
            locations={locations}
            providers={!active ? providers : provider_location}
          />
        ),
        event: ({ event }) => {
          const { referral_condition, title } = event;
          return (
            <div
              style={{
                backgroundColor: event?.id == activeID ? "rgb(0 94 246)" : null,
                width: "100%",
                height: "100%",
              }}
            >
              <p style={{ fontWeight: "bold" }}>{title}</p>
              <p>{referral_condition}</p>
            </div>
          );
        },
      }}
    />
  );
};

export default ClinicalCalendar;

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import MainContainer from "./Billing";
import { collapseSideNav } from "../../store/actions";

function Billing({ location }) {
  const sideNavLinks = [
    {
      link: "/billing/summary",
      label: "Summary",
    },
    {
      link: "/billing/overview",
      label: "Overview",
    },
    {
      link: "/billing/endovasculars",
      label: "Endovascular",
    },
    {
      link: "/billing/nonEndovascular",
      label: "Non-Endovascular",
    },
    {
      link: "/billing/providerReport",
      label: "Provider Report",
    },
  ];

  const [isSideNav, setIsSideNav] = useState(true);

  const dispatch = useDispatch();

  const renderMetadata = () => {
    return (
      <Helmet>
        <title>FAACT Vascular | Billing</title>
        <meta name="title" content="FAACT Vascular | Billing" />
      </Helmet>
    );
  };

  const openSideNav = () => {
    setIsSideNav(true);
    dispatch(collapseSideNav(false));
  };
  const closeSideNav = useCallback(() => {
    setIsSideNav(false);
    dispatch(collapseSideNav(true));
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      closeSideNav();
    }
  }, []);

  const getTitle = () => (
    <div className="sub-title">
      <Icon type="dollar" />
      Billing
    </div>
  );

  const onLinkClick = (link) => {
    if (sideNavLinks.find((v) => v.link === link)) {
      closeSideNav();
    }
  };

  return (
    <div className="clinicWorkflow">
      {renderMetadata()}
      <SubSideNav
        subMenu={sideNavLinks}
        title={getTitle()}
        isSideNav={isSideNav}
        openSideNav={openSideNav}
        closeSideNav={closeSideNav}
        onLinkClick={onLinkClick}
      />
      <MainContainer location={location} isSideNav={isSideNav} />
    </div>
  );
}

export default Billing;

import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowDownSVG } from '../icons/SVGIcon';
import SearchPatient from './SearchPatient';

import './style.scss';

const useOutsideDetect = (ref1, handleClick) => {
  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      handleClick(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const HeaderMenu = ({shouldDisplay}) => {
  const manageDropdown = useRef(null);
  const [openManage, setManage] = useState(false);
  useOutsideDetect(manageDropdown, setManage);
  const handleManage = () => {
    if (!openManage) {
      setManage(true);
    }
  };

  return (
    <ul
      style={{display: shouldDisplay ? '' : 'none'}}
      className="headerMenuContainer"
    >
      <SearchPatient />
      <li className="headerListItem">
        <NavLink to="/about">About</NavLink>
      </li>
      <li className="headerListItem">
        <NavLink to="/support/details">Support</NavLink>
      </li>
      <li className="headerListItem">
        <NavLink to="/employee-workflow/deficiency">Deficiency</NavLink>
      </li>
      <li className="headerListItem">
        <ul className={`headerDropdownMenu ${openManage ? 'open' : ''}`}>
          <li
            className={`dropdownText headerListItem ${
              openManage ? 'open' : ''
            }`}
            onClick={handleManage}
          >
            Processes
            <ArrowDownSVG />
          </li>
          <li>
            <ul ref={manageDropdown} className="dropdownList">
              <li className="dropdownItem">
                <NavLink
                  to="processes/quick-register"
                  onClick={() => setManage(false)}
                >
                  Quick Register
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/processing-referral"
                  onClick={() => setManage(false)}
                >
                  Processing Referral
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/clinic-confirmation"
                  onClick={() => setManage(false)}
                >
                  Clinic Confirmation
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/clinic-appointment"
                  onClick={() => setManage(false)}
                >
                  Clinic Appointment
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/obl-confirmation"
                  onClick={() => setManage(false)}
                >
                  OBL Confirmation
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/obl-appointment"
                  onClick={() => setManage(false)}
                >
                  OBL Appointment
                </NavLink>
              </li>
              <li className="dropdownItem">
                <NavLink
                  to="processes/surgery-confirmation"
                  onClick={() => setManage(false)}
                >
                  Surgery Confirmation
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default HeaderMenu;

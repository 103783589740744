import { Checkbox, Icon } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import {
  patientExaminationSave,
  patientExaminationSetData,
  setCopyFrom,
  setGlobalLoading,
  setNewLoading,
  setGlobalToastr,
  patientExaminationLoadInit,
  HPIGetConditions,
  clinicProviderReviewLoadInit,
} from "../../../../store/actions";
import { setSidebarState } from "../../../../store/actions/sidebar";
import ExaminationROS from "./ExaminationROS";
import PhysicalExaminationSidebar from "./PhysicalExaminationSidebar";
class PhysicalExamination extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: true,
    checkedCopyLast: false,
    saveQueued: false,
    checkSavingFuncId: null,
    checkedAllNormalsP: null,
    firstCheck: false,
    twoCheck: false,
  };
  timer = null;

  async componentDidMount() {
    this.props.setSidebarState(true);
    await this.props.setNewLoading(true);
    const procedure_id = getLocal("current_appointment_id");
    await this.props.patientExaminationLoadInit(procedure_id);
    await this.props.HPIGetConditions(procedure_id);
    if (!this.props.noClinic) {
      // use for clinic not for OBL
      await this.props.clinicProviderReviewLoadInit(procedure_id, 0);
    }

    const { patientResource, copy_from_last_visit_exam, can_copy_from_previous_visit } = this.props;
    if (patientResource) {
      this.setState({
        ...this.state,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    if (copy_from_last_visit_exam == 1) {
      this.setState({
        checkedCopyLast: true,
      });
    } else if (copy_from_last_visit_exam == 0) {
      this.setState({
        checkedCopyLast: false,
      });
    } else {
      this.setState({
        checkedCopyLast: null,
      });
    }
    //if this is a total new visit, check all normals

    this.checkAllNormals();

    await this.props.setNewLoading(false);
  }

  componentWillReceiveProps(nextProps) {
    const { copy_from_last_visit_exam } = nextProps;
    this.setState({
      checkedCopyLast: copy_from_last_visit_exam == 1 ? true : false,
    });
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ ...this.state, isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ ...this.state, isSidebar: false });
  };

  onChangeData = async (scope, data) => {
    await this.props.setGlobalLoading(true);
    await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    this.setState({
      firstCheck: true,
    });
    if (this.state.firstCheck) {
      this.saveData();
    }
    await this.props.setGlobalLoading(false);
  };

  onChangeDataVes = async (scope, data, noTimer) => {
    await this.props.setGlobalLoading(true);
    await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    if (noTimer) {
      this.saveData();
    } else {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.saveData();
      }, 1000);
    }
    await this.props.setGlobalLoading(false);
  };

  saveData = async () => {
    const { physical_examinations, vessel_states, is_locked } = this.props;
    const { patientResource } = this.props;
    const patient_id = patientResource?.id;
    if (is_locked == 0 || is_locked == null) {
      const fixArr = (physical_examinations || []).map((r) => {
        return {
          ...r,
          comments: RemoveN(r?.comments || ""),
        };
      });
      if (this.props.setDataPhysical) {
        this.props.setDataPhysical({
          patient_id,
          physical_examinations: fixArr,
          copy_from_last_visit_exam: this.state.checkedCopyLast ? 1 : 0,
          vessel_states: vessel_states ? vessel_states : {},
        });
      }
    }
    this.setState({ ...this.state, saveQueued: false });
  };

  doCopyLast = async (checked) => {
    const { patientResource, is_locked, physical_examinations, vessel_states } = this.props;
    await this.props.setGlobalLoading(true);
    if (checked) {
      if (is_locked == 0 || is_locked == null) {
        this.setState({ checkedCopyLast: true }, async () => {
          this.props.setCopyFrom(1, "copy_from_last_visit_exam");
          await this.props.PhysicalCoppy(patientResource?.id);
          await this.setState({
            ...this.state,
            saveQueued: true,
          });
          await this.props.setGlobalLoading(false);
        });
      } else {
        await this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
        await this.props.setGlobalLoading(false);
      }
    } else {
      if (is_locked == 0 || is_locked == null) {
        this.props.setCopyFrom(0, "copy_from_last_visit_exam");
        const removePhy = (physical_examinations || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments ? r?.comments : r?.default_comments),
            values: (r?.values || []).map((v) => {
              return {
                ...v,
                checked: false,
              };
            }),
          };
        });
        const valueState = "2";
        let valueVessel = {
          "Right Carotid": valueState,
          "Left Carotid": valueState,
          "Right Radial": valueState,
          "Left Radial": valueState,
          "Right Ulnar": valueState,
          "Left Ulnar": valueState,
          "Right Femoral": valueState,
          "Left Femoral": valueState,
          "Right Popliteal": valueState,
          "Left Popliteal": valueState,
          "Right Dorsalis Pedis": valueState,
          "Left Dorsalis Pedis": valueState,
          "Right Posterior Tibial": valueState,
          "Left Posterior Tibial": valueState,
        };
        const vesselStates_ = { ...vessel_states };
        for (const key in vesselStates_) {
          if (vesselStates_.hasOwnProperty(key)) {
            vesselStates_[key] = "2";
          }
        }
        valueVessel = vesselStates_;

        this.setState({ checkedCopyLast: false }, async () => {
          await this.props.patientExaminationSetData("physical_examinations", removePhy);
          this.onChangeDataVes("vessel_states", valueVessel, true);
          await this.props.setGlobalLoading(false);
        });
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
        await this.props.setGlobalLoading(false);
      }
    }
  };

  checkAllNormals = async () => {
    const { physical_examinations, vessel_states } = this.props;
    if (vessel_states && Object.values(vessel_states).some((r) => r != null)) {
    } else {
      const valueState = "2";
      let valueVessel = {
        "Right Carotid": valueState,
        "Left Carotid": valueState,
        "Right Radial": valueState,
        "Left Radial": valueState,
        "Right Ulnar": valueState,
        "Left Ulnar": valueState,
        "Right Femoral": valueState,
        "Left Femoral": valueState,
        "Right Popliteal": valueState,
        "Left Popliteal": valueState,
        "Right Dorsalis Pedis": valueState,
        "Left Dorsalis Pedis": valueState,
        "Right Posterior Tibial": valueState,
        "Left Posterior Tibial": valueState,
      };
      if (vessel_states && Object.keys(vessel_states).length >= 14 && !Array.isArray(vessel_states)) {
        this.props.patientExaminationSetData("physical_examinations", physical_examinations, true);
        const vesselStates_ = { ...vessel_states };
        for (const key in vesselStates_) {
          if (vesselStates_.hasOwnProperty(key)) {
            vesselStates_[key] = "2";
          }
        }
        valueVessel = vesselStates_;
        this.onChangeDataVes("vessel_states", valueVessel);
        const fixArr = (physical_examinations || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments ? r?.comments : r?.default_comments),
          };
        });
        await this.props.PhysicalCheckAll("physical_examinations", fixArr, true);
      }
    }
  };

  render() {
    const { isSidebar } = this.state;
    const { checkedCopyLast } = this.state;
    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <div className="conditionList">
            <Checkbox
              checked={checkedCopyLast}
              onChange={(e) => this.doCopyLast(e.target.checked)}
              disabled={this.props.is_locked || this.props.can_copy_from_previous_visit === 0}
            >
              Copy from previous visit
            </Checkbox>
            <div className="listSection">
              <ExaminationROS
                allCheck={true}
                type={"physical_examination"}
                onChangeData={(data) => {
                  this.onChangeData("physical_examinations", data);
                }}
                isLocked={this.props.is_locked}
              />
            </div>
          </div>
        </div>
        {isSidebar && (
          <PhysicalExaminationSidebar
            noClinic={this.props?.noClinic}
            ref={this.sidebarRef}
            isShow={isSidebar}
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
            checkedAllNormalsP={true}
            handleCloseSidebar={this.handleCloseSidebar}
            onChangeDataVes={(data) => {
              this.onChangeDataVes("vessel_states", data);
            }}
            isLocked={this.props.is_locked}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  physical_examinations: state.patientExamination.physical_examinations || [],
  vessel_states: state.patientExamination.vessel_states,
  can_copy_from_previous_visit: state.patientExamination.can_copy_from_previous_visit,
  copy_from_last_visit_exam: state.patientExamination.copy_from_last_visit_exam,
});

const mapDispatchToProps = {
  patientExaminationSetData,
  patientExaminationSave,
  setGlobalToastr,
  setSidebarState,
  setGlobalLoading,
  setCopyFrom,
  patientExaminationLoadInit,
  HPIGetConditions,
  clinicProviderReviewLoadInit,
  setNewLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalExamination);

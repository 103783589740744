import {AtomicBlockUtils, EditorState} from 'draft-js';

function createTable(editorState, params, row, valueHeader2) {
  const contentState = editorState.getCurrentContent();
  const caption = 'Table1';
  let cell = {};
  let ArrayHeader = [];
  let arrayRow = [];
  let arrayLabel = {};

  arrayRow = row.filter((r) => r.finding_name.includes('Table1'));
  arrayRow = arrayRow.map((r) => {
    return {
      label: r.finding_name.replace('Table1 ', ''),
      [`${params.headers[0]}`]: r[`${params.headers[0]}`],
      [`${params.headers[1]}`]: r[`${params.headers[1]}`],
      [`${params.headers[2]}`]: r[`${params.headers[2]}`],
      [`${params.headers[3]}`]: r[`${params.headers[3]}`],
      [`${params.headers[4]}`]: r[`${params.headers[4]}`],
      [`${params.headers[5]}`]: r[`${params.headers[5]}`],
      [`${params.headers[6]}`]: r[`${params.headers[6]}`],
      [`${params.headers[7]}`]: r[`${params.headers[7]}`],
    };
  });

  ArrayHeader = [...params?.headers];
  for (let i = 0; i < arrayRow.length; i += 1) {
    arrayLabel = {
      ...arrayLabel,
      [i + 1]: [
        arrayRow[i]['label'],
        arrayRow[i][`${params.headers[0]}`],
        arrayRow[i][`${params.headers[1]}`],
        arrayRow[i][`${params.headers[2]}`],
        arrayRow[i][`${params.headers[3]}`],
        arrayRow[i][`${params.headers[4]}`],
        arrayRow[i][`${params.headers[5]}`],
        arrayRow[i][`${params.headers[6]}`],
        arrayRow[i][`${params.headers[7]}`],
      ],
    };
  }

  if (params) {
    cell = {
      [0]: ArrayHeader,
      ...arrayLabel,
    };
  } else {
    cell = Object.fromEntries(
        Array.from(
            {length: arrayRow.length},
            // eslint-disable-next-line no-shadow
            (_, i) => [
              i,
              Array.from(
                  {length: ArrayHeader.length},
                  (_, j) => `cell-${i},${j}`,
              ),
            ],
        ),
    );
  }

  const contentStateWithEntity = contentState.createEntity(
      'TABLE',
      'IMMUTABLE',
      {
        row: arrayRow.length,
        column: ArrayHeader.length,
        caption,
        cell,
      },
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });

  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
}

export default createTable;

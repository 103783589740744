import { Tabs } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PDFView from "../../common/components/PDFView";
import ScrollWrapper from "../../common/components/ScrollBar";
import TopBar from "../../common/components/SubTopBar";
import { getLocal } from "../../helpers/Local";
import PatientSidebar from "./PatientSidebar";
import "./style.scss";

const { TabPane } = Tabs;

const ReferralPatients = ({ history }) => {
  const loadingDocument = useSelector(state => state.common.loadingGetPatientDocument);
  const documentDetails = useSelector(state => state.common.detailPatientDocument);
  const [isAuth] = useState(getLocal("cvai-auth-token") ? true : false);
  const [isSideBar, setIsSidebar] = useState(true);

  const closeSidebar = () => {
    setIsSidebar(false);
  };

  const enablePDFViewer = useMemo(() => {
    if (loadingDocument) {
      return false;
    }
    if (documentDetails && documentDetails.file_path) {
      return true;
    }
    return false;
  }, [documentDetails, loadingDocument]);

  if (!isAuth) {
    history.push('/login');
    return null;
  }

  return (
    <div className="clinicWorkflow patient-dashboard">
      <div className="clinicWorkflow__main">
        <ScrollWrapper css="no-padding x-hidden">
          <div className="clinicWorkflow__main-page">
            <TopBar title="Patient Records" noLeftSide />
            <div className="clinicWorkflow__main-container">
              <div
                className="clinicWorkflow__main-subTitle tabContainer"
                style={{
                  paddingLeft: '5%',
                }}
              >
                <div className="tabHeaders" style={{ height: '29px' }}>
                  <ScrollWrapper css="no-padding tab-header-scroll">
                    <div style={{ display: "flex" }}>
                      <div
                        className="tabHeaders-item active"
                        style={{ minWidth: 90.4 }}
                      >
                        Documentation
                      </div>
                    </div>
                  </ScrollWrapper>
                </div>
              </div>
              <div className={`clinicWorkflow__main-section 
                    ${isSideBar ? "sidebar" : ""} 
                  `}
              >
                <div className="eventsContainer has-sub-header">
                  <Tabs
                    className="patientChart"
                    type="card"
                    activeTab="documentation"
                    onTabClick={() => setIsSidebar(true)}
                  >
                    <TabPane tab="Documentation" key="documentation">
                      <PDFView url={enablePDFViewer && documentDetails.file_path} />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </ScrollWrapper>
        <PatientSidebar isShow={isSideBar} onClose={closeSidebar} />
      </div>
    </div>
  );
};

export default ReferralPatients;

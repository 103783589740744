import { Drawer, message, Upload } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditFormInput from "../../../../common/components/EditableText/EditableFormInput";
import { BarcodeSVG } from "../../../../common/components/icons/FaxSVG";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { creditReceivingItem, getReturnCreditOrders, receivedReceivingItem, setInventoryData, shippedReceivingItem } from "../../../../store/actions";

const { Dragger } = Upload;

const draggerProps = {
  name: "file",
  multiple: false,
  action: "",
  onChange(info) {
    const { status } = info?.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info?.file?.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
    }
  },
};

const categories = {
  angiographic: "Angiographic Supply",
  narcotic: "Narcotic Inventory",
  other: "Other Inventory",
};

const ReturnCreditSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const order = useSelector((state) => state.inventory.returnCredit);
  const updated = useSelector((state) => state.inventory.isUpdated);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updated) {
      dispatch(getReturnCreditOrders());
      dispatch(setInventoryData("isUpdated", false));
    }
  }, [updated]);

  const onReceived = () => {
    if (order) {
      dispatch(receivedReceivingItem(order?.id, { received: true }));
      dispatch(setInventoryData("returnCredit", null));
    }
  };

  const onCreditReceived = () => {
    if (order) {
      dispatch(creditReceivingItem(order?.id, { credit_received: true }));
      dispatch(setInventoryData("returnCredit", null));
    }
  };

  const onShipped = () => {
    if (order) {
      dispatch(shippedReceivingItem(order?.id, { shipped: true }));
    }
  };

  const cost_per_item = useMemo(() => {
    if (order && order.cost) {
      const quantity = order.quantity ? order.quantity : 1;
      return (order.cost / quantity).toFixed(2);
    }
    return null;
  }, [order]);

  if (!order) {
    return null;
  }

  return (
    <Drawer
      title={order?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Product No"} value={order.product_number} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Code No"} value={order.code_number} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Category"} value={categories[order.type]} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Brand"} value={order.brand} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"PAR Level"} value={order.par_level} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Bin Rate"} value={order.bin_rate} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Location"} value={order.location} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Specific Location"} value={order.specific_location} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Supplier E-Mail"} value={order.supplier_email} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Supplier Fax"} value={order.supplier_fax} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Quantity per unit"} value={order.quantity} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Unit"} value={order.unit} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Cost"} value={order.cost && parseFloat(order.cost).toFixed(2)} prefix={"$"} />
            </div>
            <div className="resourceInfo d-flex">
              <EditFormInput label={"Cost/Item"} value={cost_per_item} prefix={"$"} />
            </div>
            <hr />
            <div className="resourceInfo d-flex" style={{ alignItems: "center" }}>
              <b>Image of Return/Packing Slip</b>
              <Dragger {...draggerProps} className="barcode-uploader" showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <BarcodeSVG />
                </p>
              </Dragger>
            </div>
            <hr />
          </div>
          <div
            className="actionContent"
            style={{
              marginTop: "1.5rem",
              paddingRight: "1rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button className="outlined-btn" style={{ marginTop: "0.5rem" }} onClick={onShipped}>
              Shipped
            </button>
            <button style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} onClick={onCreditReceived}>
              Credit Received
            </button>
            <button style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} onClick={onReceived}>
              Item Received
            </button>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ReturnCreditSidebar;

import { DatePicker, Input, Select } from "antd";
import * as dates from "date-arithmetic";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";

import { ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import { getNewDate, snakeToTitle } from "../../../common/utils";
import { getLocal, setLocal } from "../../../helpers/Local";
import {
  get_ultrasound_question,
  lock_ultrasound,
  removeReviewUltrasound,
  setDetailUltrasoundProps,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../store/actions";

const { Option } = Select;
const { Search } = Input;

const localizer = momentLocalizer(moment);
const start = dates.startOf(new Date(), "day");
const min = dates.add(start, 6, "hours");
const max = dates.add(min, 14, "hours");

const allViews = Object.keys(Views).map((k) => Views[k]);
let timer = null;

const CustomToolbar = (props) => {
  const [view, setView] = useState("month");
  const [search, setSearch] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSearch(props?.valueSearch);
  }, [props?.valueSearch]);

  const onSearchValue = (searchInput) => {
    if (props.onSearch) {
      setLoading(true);
      const valueSearchState = searchInput.target?.value;
      clearTimeout(timer);
      setSearch(valueSearchState);
      timer = setTimeout(() => {
        setLoading(false);
        props.onSearch(valueSearchState);
      }, 1000);
    }
  };

  const dateFormat = "MM/DD/YYYY";
  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-title">Itinerary</span>
      {!props.disableFilter && (
        <span className="rbc-toolbar-filter" style={{ flex: 0 }}>
          <div className="rbc-dropdown-Filter">
            <DatePicker
              id={`picker_date_ultrasound`}
              format={dateFormat}
              value={props.selectedDate ? moment(props.selectedDate, dateFormat) : undefined}
              onChange={props.handleDate}
              placeholder="Date"
              suffixIcon={<ExpandSVG />}
            />
          </div>
          <div className="rbc-dropdown-Filter">
            <Select
              placeholder="Location"
              value={props.selectedLocation}
              onChange={props.handleLocation}
              suffixIcon={<ExpandSVG />}
              allowClear={true}
            >
              <Option value="">All</Option>
              {(props.locations || []).map((location, index) => (
                <Option key={`location-${location?.id}-${index}`} value={location?.id}>
                  {location?.value}
                </Option>
              ))}
            </Select>
          </div>
          <div className="rbc-dropdown-Filter">
            <Select
              placeholder="Provider"
              value={props.selectedProvider}
              onChange={props.handleProvider}
              suffixIcon={<ExpandSVG />}
              allowClear={true}
            >
              <Option value="">All</Option>
              {(props.providers || []).map((provider, index) => (
                <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                  {provider?.value}
                </Option>
              ))}
            </Select>
          </div>
        </span>
      )}
      <span className="rbc-toolbar-search" style={{ flex: 1 }}>
        <div className="rbc-toolbar-searchField day-calendar">
          <Search
            placeholder="Search"
            value={search}
            onChange={onSearchValue}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </span>
    </div>
  );
};

const DayCalendarUltrasound = ({
  locations,
  providers,
  events,
  resources,
  slotProviders,
  fetchEvents,
  onSearch,
  onSelectResource,
  valueSearch,
  calendarType,
  loading,
  isClinicWorkFlow,
}) => {
  const [date, setDate] = useState(getNewDate(moment(new Date())));
  const ultrasounds_map = useSelector((state) => state.common.ultrasounds);
  const clinicDateString = useSelector((state) => state.datestring.clinicDateString);

  const [dateString, setDateString] = useState(moment(new Date()).format("MM/DD/YYYY"));

  const getLocalCheck = JSON.parse(getLocal("scheduleParams"));

  const [provider, setProvider] = useState();
  const [location, setLocation] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const handleDate = (newDate, newDateString) => {
    if (newDate == null || newDateString == null) {
      setDate(null);
      setDateString(null);

      setLocal(
        "scheduleParams",
        JSON.stringify({
          date: null,
          location: getLocalCheck?.location || "",
          provider_id: getLocalCheck?.provider_id || "",
        }),
      );
    } else {
      setDate(getNewDate(newDate));
      setDateString(newDateString);
      let paramLocation = "";
      let paramProvider = "";
      if (location != undefined) paramLocation = location;
      if (provider != undefined) paramProvider = provider;

      setLocal(
        "scheduleParams",
        JSON.stringify({
          date: newDateString,
          location: getLocalCheck?.location || "",
          provider_id: getLocalCheck?.provider_id || "",
        }),
      );

      fetchEvents({
        date: newDateString,
        location: getLocalCheck?.location || "",
        provider_id: getLocalCheck?.provider_id || "",
      });
    }
  };

  const handleProvider = (value) => {
    const newProvider = value ? value : "";
    setProvider(value);

    let paramLocation = "";
    let paramProvider = "";
    if (location != undefined) paramLocation = location;
    if (newProvider != undefined) paramProvider = newProvider;

    setLocal(
      "scheduleParams",
      JSON.stringify({
        date: isClinicWorkFlow ? getLocalCheck?.date : dateString,
        location: getLocalCheck?.location || "",
        provider_id: value || "",
      }),
    );

    fetchEvents({
      date: isClinicWorkFlow ? getLocalCheck?.date : dateString,
      location: getLocalCheck?.location || "",
      provider_id: value || "",
    });
  };

  const handleLocation = (value) => {
    const newLocation = value ? value : "";

    setLocation(value);

    let paramLocation = "";
    let paramProvider = "";
    if (newLocation != undefined) paramLocation = newLocation;
    if (provider != undefined) paramProvider = provider;

    setLocal(
      "scheduleParams",
      JSON.stringify({
        date: isClinicWorkFlow ? getLocalCheck?.date : dateString,
        location: value || "",
        provider_id: getLocalCheck?.provider_id || "",
      }),
    );

    fetchEvents({
      date: isClinicWorkFlow ? getLocalCheck?.date : dateString,
      location: value || "",
      provider_id: getLocalCheck?.provider_id || "",
    });
  };

  const onSelectEvent = (event) => {
    // api get questionnaire
    if (event.status == "require-prior-authorization") {
      dispatch(lock_ultrasound(false));
      dispatch(
        setGlobalToastr({
          header: "Ultrasound",
          message: "This appointment requires prior authorization. Please input authorization before finalizing it",
          type: "failed",
        }),
      );
    } else {
      dispatch(lock_ultrasound(false));
    }
    if (event?.id) {
      dispatch(get_ultrasound_question(event?.id));
    }
    //
    dispatch(removeReviewUltrasound());
    let id_ultrasounds = 0;
    if (ultrasounds_map) {
      id_ultrasounds = ultrasounds_map.filter((r) => {
        if (r?.name === event.content) {
          return { id: r?.id };
        }
      });
    }
    dispatch(setDetailUltrasoundProps("detail_ultrasound_props", {}));
    if (id_ultrasounds && id_ultrasounds[0] && id_ultrasounds[0]["id"]) {
      setLocal("current_ultrasound_id", id_ultrasounds[0]["id"]);
    }
    setLocal("current_patient_id", event?.id);
    if (calendarType === "check-in") {
      if (event.resourceId === "us" || event.oldResourceId === "us") {
        if (event.status && event.status === "confirmed") {
        } else {
          onSelectResource(event.patient_id, event.content, event);
        }
      }
    } else if (calendarType === "clinic-schedule") {
      onSelectResource(event.patient_id, event.content, event);
    }
  };

  const resourceSlots = useMemo(() => {
    if (!location) {
      return resources;
    }
    if (slotProviders && slotProviders.length > 0) {
      const newResources = slotProviders
        .filter((prov) => prov.user && prov.user.is_technician > 0)
        .map((prov) => ({
          id: `us-${prov?.id}`,
          title: `Ultrasound - ${prov?.value}`,
        }));
      const providerIds = slotProviders
        .filter((prov) => prov.user && prov.user.is_technician > 0)
        .map((prov) => prov?.id);
      const noProvider = events.find((ev) => ev.resourceId === "us" && !providerIds.includes(ev.provider_id));
      return !noProvider ? newResources : [...resources, ...newResources];
    }
    return resources;
  }, [location, events, resources, slotProviders]);

  const eventData = useMemo(() => {
    if (!location) {
      return events;
    }
    if (slotProviders && slotProviders.length > 0) {
      const existingProviders = slotProviders.filter((prov) => prov.user && prov.user.is_technician > 0);
      return events.map((ev) => ({
        ...ev,
        oldResourceId: ev.resourceId,
        resourceId:
          !!ev.provider_name && !!existingProviders.find((prov) => prov?.id === ev.provider_id)
            ? `${ev.resourceId}-${ev.provider_id}`
            : ev.resourceId,
      }));
    }
    return events;
  }, [location, events, slotProviders]);
  const tooltip = (event) => event.title + (event.status ? " (" + snakeToTitle(event.status) + ")" : "");

  return (
    <Calendar
      localizer={localizer}
      // scrollToTime={new Date().setHours(1, 55)}
      views={allViews}
      events={eventData}
      resources={resourceSlots}
      timeslots={1}
      date={new Date(isClinicWorkFlow && getLocalCheck?.date ? getLocalCheck?.date : dateString)}
      tooltipAccessor={tooltip}
      onNavigate={() => {}}
      defaultDate={new Date(2020, 2, 11)}
      defaultView={"day"}
      step={15}
      min={min}
      max={max}
      components={{
        toolbar: (props) => (
          <CustomToolbar
            {...props}
            fetchEvents={fetchEvents}
            handleDate={handleDate}
            selectedDate={isClinicWorkFlow && getLocalCheck?.date ? getLocalCheck?.date : dateString}
            selectedLocation={isClinicWorkFlow ? getLocalCheck?.location : location}
            selectedProvider={isClinicWorkFlow ? getLocalCheck?.provider_id : provider}
            handleProvider={handleProvider}
            handleLocation={handleLocation}
            locations={locations}
            valueSearch={valueSearch}
            providers={providers}
            onSearch={onSearch}
          />
        ),
        event: ({ event }) => {
          const { patientName, content } = event;
          return (
            <div>
              <p style={{ fontWeight: "bold" }}>{patientName}</p>
              <p>{content}</p>
            </div>
          );
        },
      }}
      eventPropGetter={(event, start, end, isSelected) => {
        let style = {};
        if (event.status && event.status === "confirmed") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#26C281",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.status && event.status === "checked-in") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#007D79",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.status && event.status === "scheduled") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#864df8",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.status && event.status === "cancelled") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#cd2f71",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.status && event.status === "billed") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#277e3b",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.status && event.status === "data_saved") {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#e6e428",
            borderLeftColor: "#ffffff",
          };
        }
        if (event.isHighlight) {
          style = {
            color: "white",
            paddingLeft: "0px",
            marginLeft: "0px",
            borderColor: "#e43a45",
            borderLeftColor: "#ffffff",
          };
        }

        return {
          style,
          className: event.status && event.status,
        };
      }}
      onSelectEvent={(event) => onSelectEvent(event)}
    />
  );
};

export default DayCalendarUltrasound;

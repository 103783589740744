import React, { useState } from "react";
import { CloseIcon } from "../../../common/components/icons/SVGIcon";
import Stepper from "../../../common/components/Stepper";
import FirstStepContent from "./FirstStep";
import SecondStepContent from "./SecondStep";

const HospitalSideBar = ({ resource, isShow, handleCloseSidebar }) => {
  const [step, setStep] = useState(0);
  const handleSubmit = () => {
    if (step === 0) {
      setStep(1);
    } else {
      // Todo: handleSubmit
    }
  };
  const handlePrev = () => {
    setStep(0);
  };

  return (
    <div className={`resourceContainer ${isShow ? "show" : ""}`}>
      <div className="headerContainer">
        <button onClick={handleCloseSidebar}>
          <CloseIcon />
        </button>
      </div>
      <div className="title">
        {resource.icon && resource.icon}
        {`${resource.firstname} ${resource.lastname} (DOB ${resource.dob})`}
      </div>
      {step === 0 && <FirstStepContent resource={resource} />}
      {step === 1 && <SecondStepContent resource={resource} />}
      <div className="actionContent">
        {step === 1 && (
          <button style={{ marginRight: "1rem" }} onClick={handlePrev}>
            Prev
          </button>
        )}
        <button onClick={handleSubmit}>{step === 0 ? "Next" : "Save"}</button>
      </div>
      <Stepper steps={["Main", "Final"]} currentStep={step} handleStep={setStep} />
    </div>
  );
};

export default HospitalSideBar;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableText from "../../../../common/components/EditableText";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import {
  getDetailProcedureData,
  getLocations,
  getProcedureData,
  getProviders,
  getUltrasounds,
  setCommonData,
  setGlobalLoading,
} from "../../../../store/actions";
import { StaticResource } from "../../constant";
import OldProcedureConditionSidebar from "./OldProcedureConditionSidebar";

const OldProcedureCondition = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newProcedures, setNewProcedures] = useState([]);

  //
  const [activeIndex, setRow] = useState(-1);
  const [providerId, setProviderId] = useState(0);

  const procedureData = useSelector((state) => state.common.procedureData);
  const detailProcedureData = useSelector((state) => state.common.detailProcedureData);

  const loadingDetailProcedureData = useSelector((state) => state.common.loadingDetailProcedureData);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(loadingDetailProcedureData);
  }, [loadingDetailProcedureData]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => () => {
    setRow(index);
    if (providerId !== id) {
      setProviderId(id);
      dispatch(getDetailProcedureData(id));
      handleSidebar();
    }
  };

  const fetchData = () => {
    dispatch(getProviders());
    dispatch(getLocations());
    dispatch(getProcedureData());
    dispatch(getUltrasounds());
    dispatch(setCommonData("detailProcedureData", {}));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableHeader">
            <div className="tableItem">
              <div className="th" style={{ width: "50%", marginLeft: "2rem" }}>
                <b>Name</b>
              </div>
              <div className="th" style={{ width: "50%" }}>
                <b>Type</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div className="tableContainer">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {/* New Empty Row for adding */}
                  {newProcedures.map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "50%", marginLeft: "2rem" }}>
                        <EditableText label={row?.name} />
                      </div>
                      <div className="td" style={{ width: "50%" }}>
                        <EditableText label={row.type} />
                      </div>
                    </div>
                  ))}

                  {(procedureData || []).map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? "active" : ""}`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: "50%", marginLeft: "2rem" }}>
                        <p>{row?.value}</p>
                      </div>
                      <div className="td" style={{ width: "50%" }}>
                        <p>{row.type}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
      {detailProcedureData && activeIndex >= 0 && (
        <OldProcedureConditionSidebar ref={sidebarRef} resource={StaticResource} isShow={isSidebar} handleCloseSidebar={handleCloseSidebar} />
      )}
      {detailProcedureData && activeIndex >= 0 && (
        <SidebarExtension
          startingWidth="calc(28.3% - 20px + 0.3rem)"
          sidebarReference={sidebarRef}
          sidebarToggle={toggleSidebar}
          sidebarState={isSidebar}
        />
      )}
    </div>
  );
};

export default OldProcedureCondition;

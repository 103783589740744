const data = [
  {
    question: `Because of the poor circulation in my legs, the range of activities that I would have liked to do in the past two weeks has been`,
    value: undefined,
    dataChoices: ["Severely limited", "Moderately limited", "Very slightly limited", "Not limited at all"],
    valueChoices: [1, 2, 3, 4],
    key: "question-0",
  },
  {
    question: `During the past two weeks, my legs felt tired or weak`,
    value: undefined,
    dataChoices: ["All of the time", "Some of the time", "A little of the time", "None of the time"],
    valueChoices: [1, 2, 3, 4],
    key: "question-1",
  },
  {
    question: `During the past two weeks, because of the poor circulation in my legs, my ability to walk has been`,
    value: undefined,
    dataChoices: ["Totally limited", "Very limited", "A little limited", "Not at all limited"],
    valueChoices: [1, 2, 3, 4],
    key: "question-2",
  },
  {
    question: `During the past two weeks, I have been concerned about having poor circulation in my legs`,
    value: undefined,
    dataChoices: ["All of the time", "Some of the time", "A little of the time", "None of the time"],
    valueChoices: [1, 2, 3, 4],
    key: "question-3",
  },
  {
    question: `During the past two weeks, because of the poor circulation in my legs, my ability to participate in social activities has been`,
    value: undefined,
    dataChoices: ["Totally limited", "Very limited", "A little limited", "Not at all limited"],
    valueChoices: [1, 2, 3, 4],
    key: "question-4",
  },
  {
    question: `During the past two weeks, when I have had pain in the leg (or foot) it has given me`,
    value: undefined,
    dataChoices: [
      "Great deal of discomfort or distress",
      "Moderate amount of discomfort or distress",
      "Very little discomfort or distress",
      "No discomfort or distress",
    ],
    valueChoices: [1, 2, 3, 4],
    key: "question-5",
  },
];

export default data;

import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BoldButton, ItalicButton, OrderedListButton, UnderlineButton, UnorderedListButton } from "draft-js-buttons";
import createMentionPlugin from "draft-js-mention-plugin";
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import draftToHtml from "draftjs-to-html";
import { text_template_condition } from "../../../store/actions";
import ScrollWrapper from "../../../common/components/ScrollBar";

const mentionPlugin = createMentionPlugin({
  mentionTrigger: "[",
});

const toolbarPlugin = createToolbarPlugin();
const toolbarPlugin2 = createToolbarPlugin();

let timer = null;

const TemplateDocumentation = ({ resource }) => {
  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [checkResoure, setCheckResoure] = useState(null);
  const [footerEditorState, setFooterEditor] = useState(EditorState.createEmpty());
  const [answerCheck, setAnswerCheck] = useState([]);
  const [variableCheck, setVariableCheck] = useState([]);
  const [valueEditorJS, setValueEditorJS] = useState("");
  const [valueFooterJS, setValueFooterJS] = useState("");
  const [content, setContent] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [focusEditor, setFocusEditor] = useState(false);
  const [focusFooterEditor, setFocusFooterEditor] = useState(false);
  const [selectVariable, setSelectVariable] = useState("");
  const dispatch = useDispatch();

  const conditionVariable = useSelector((state) => state.procedureDetail.conditionVariable);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);

  useEffect(() => {
    setCheckResoure(resource);
  }, [resource]);

  const onChange = (editorState) => {
    setEditor(editorState);
    const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const newContentState = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setValueEditorJS(data);
    setContent(newContentState);
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0 && focusEditor) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          text_template_condition(selectedReferralCondition?.id, {
            text_template: data,
            footer_template: valueFooterJS,
          })
        );
      }, 1000);
    }
  };

  const onChangeFooter = (footerState) => {
    if (resource === null || resource === undefined) {
      return;
    }
    setFooterEditor(footerState);
    const data = JSON.stringify(convertToRaw(footerState.getCurrentContent()));
    setValueFooterJS(data);
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0 && focusFooterEditor) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          text_template_condition(selectedReferralCondition?.id, {
            text_template: valueEditorJS,
            footer_template: data,
          })
        );
      }, 1000);
    }
  };

  useEffect(() => {
    if (conditionVariable) {
      const variableMap = (conditionVariable || []).map((r) => {
        return {
          link: r?.id,
          name: `[${r.variable_name}]`,
          check: r.variable_name,
        };
      });
      setVariableCheck([...variableMap]);
    }
  }, [conditionVariable]);

  useEffect(() => {
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      if (selectedReferralCondition.footer_template && selectedReferralCondition.footer_template.length > 0) {
        try {
          const contentStateFooter = convertFromRaw(JSON.parse(selectedReferralCondition.footer_template));
          const editorStateFooter = EditorState.createWithContent(contentStateFooter);
          setFooterEditor(editorStateFooter);
        } catch (error) {
          setFooterEditor(EditorState.createEmpty());
        }
      } else {
        setFooterEditor(EditorState.createEmpty());
      }
      if (selectedReferralCondition.text_template && selectedReferralCondition.text_template.length > 0) {
        try {
          const contentState = convertFromRaw(JSON.parse(selectedReferralCondition.text_template));
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        } catch (error) {
          setEditor(EditorState.createEmpty());
        }
      } else {
        setEditor(EditorState.createEmpty());
      }
    }
  }, [selectedReferralCondition]);

  useEffect(() => {
    if (selectVariable && conditionVariable) {
      const answerCheck = (conditionVariable || []).find((r) => r.variable_name == selectVariable);
      if (answerCheck) {
        const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
          return { link: `${item?.id} - ${index}`, name: `${item.answer}]` };
        });
        setAnswerCheck([...allAnswerCheck]);
      }
    }
  }, [selectVariable]);

  useEffect(() => {
    if (content && focusEditor) {
      const hrefContent = content;
      let checkitemLI = null;
      if (hrefContent && hrefContent.length > 0) {
        const [itemCash, ...itemCashes] = hrefContent && hrefContent.split("<li>");
        checkitemLI = (itemCashes || []).map((item) => {
          return item.split("</li>")[0];
        });
      } else {
        checkitemLI = null;
      }
      let checkitemALi = null;
      if (checkitemLI && checkitemLI.length > 0) {
        const charCheck = checkitemLI[checkitemLI.length - 1];
        const [itemA, ...itemCheckA] = charCheck && charCheck.split(`-&gt;`);
        checkitemALi = (itemCheckA || []).map((item) => {
          return item.split("</li>")[0];
        });
        if (
          (checkitemALi && checkitemALi[checkitemALi.length - 1] && checkitemALi[checkitemALi.length - 1] === "&nbsp;&nbsp;") ||
          (checkitemALi && checkitemALi[checkitemALi.length - 1] && checkitemALi[checkitemALi.length - 1] === " [") ||
          (checkitemALi && checkitemALi[checkitemALi.length - 1] && checkitemALi[checkitemALi.length - 1] === " [&nbsp;")
        ) {
          setIsChange(true);
        } else {
          setIsChange(false);
        }
      }
      // check in <p></p>
      let checkitemP = null;
      if (hrefContent && hrefContent.length > 0) {
        const [itemCash, ...itemCashes] = hrefContent && hrefContent.split("<p>");
        checkitemP = (itemCashes || []).map((item) => {
          return item.split("</p>")[0];
        });
      } else {
        checkitemP = null;
      }
      let checkitemA = null;
      if (checkitemP && checkitemP.length > 0) {
        const charCheck = checkitemP[checkitemP.length - 1];
        const [itemA, ...itemCheckA] = charCheck && charCheck.split(`-&gt;`);
        checkitemA = (itemCheckA || []).map((item) => {
          return item.split("</a>")[0];
        });
        if (
          (checkitemA && checkitemA[checkitemA.length - 1] && checkitemA[checkitemA.length - 1] === "&nbsp;&nbsp;") ||
          (checkitemA && checkitemA[checkitemA.length - 1] && checkitemA[checkitemA.length - 1] === " [") ||
          (checkitemA && checkitemA[checkitemA.length - 1] && checkitemA[checkitemA.length - 1] === " [[") ||
          (checkitemA && checkitemA[checkitemA.length - 1] && checkitemA[checkitemA.length - 1] === " [&nbsp;")
        ) {
          setIsChange(true);
        } else {
          setIsChange(false);
        }
      }
    }
  }, [content]);

  const onSearchChange = ({ value }) => {
    //default
  };

  const onAddMention = (value) => {
    setSelectVariable(value?.check);
  };

  const { MentionSuggestions } = mentionPlugin;
  const { Toolbar } = toolbarPlugin;
  const Toolbar2 = toolbarPlugin2?.Toolbar;
  const plugins = [mentionPlugin, toolbarPlugin2];
  const pluginsFooter = [toolbarPlugin];

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div style={{ height: "calc(100% - 40px)", width: "100%", marginTop: -10 }}>
        <div className="resourceInfo d-flex" style={{ marginBottom: "4px" }}>
          <b>HPI</b>
        </div>
        <div className="custom-toolbar-editor">
          <Toolbar2>
            {(externalProps) => (
              <div className="editor-custom-toolbar">
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            )}
          </Toolbar2>
        </div>
        <div
          style={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "calc(50% - 26px)",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            {checkResoure !== null && checkResoure !== undefined ? (
              <Editor
                onFocus={() => {
                  setFocusEditor(true);
                }}
                onBlur={() => {
                  setFocusEditor(false);
                }}
                editorState={editorState}
                onChange={onChange}
                plugins={plugins}
                placeholder="HPI"
              />
            ) : null}
          </ScrollWrapper>
          <div className="mention-suggestion">
            <MentionSuggestions onSearchChange={onSearchChange} suggestions={isChange ? answerCheck : variableCheck} onAddMention={onAddMention} />
          </div>
        </div>
        <div className="resourceInfo d-flex" style={{ marginTop: "8px", marginBottom: "4px" }}>
          <b>FOOTER</b>
        </div>
        <div className="custom-toolbar-editor">
          <Toolbar>
            {(externalProps) => (
              <div className="editor-custom-toolbar">
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            )}
          </Toolbar>
        </div>
        <div
          style={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "calc(50% - 40px)",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <ScrollWrapper css="no-padding x-hidden">
            {checkResoure !== null && checkResoure !== undefined ? (
              <Editor
                onFocus={() => {
                  setFocusFooterEditor(true);
                }}
                onBlur={() => {
                  setFocusFooterEditor(false);
                }}
                editorState={footerEditorState}
                onChange={onChangeFooter}
                plugins={pluginsFooter}
                placeholder="Footer"
              />
            ) : null}
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default TemplateDocumentation;

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { YellowAlertSVG } from "../../../../common/components/icons/SVGIcon";
import { getPatientsByQuery, getPCMDetail, getPCMFilter, getPCMProvider, setGlobalLoading } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import EndovascularFilter from "./EndovascularFilter";
import EndovascularSidebar from "./EndovascularSidebar";
let timer;

const Endovascular = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [userProvider, setUserProvider] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const dispatch = useDispatch();
  const patients = useSelector((state) => state.dashboard.patients);
  const pcm = useSelector((state) => state.common.pcm);
  const pcmFilter = useSelector((state) => state.common.pcmFilter);
  const pcmDetail = useSelector((state) => state.common.pcmDetail);

  const ScheduleRow = useSelector((state) => state.common.pcmProvider);

  const [sortOption, setSortOption] = useState({});

  const sortList = async () => {
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }

    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        getPCMFilter({
          page: 1,
          type: "endo",
          column: sortColumn,
        })
      );
    } else {
      await dispatch(
        getPCMFilter({
          user_id: userProvider,
          startDate,
          page: 1,
          type: "endo",
          search: search,
          column: sortColumn,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setSearch(value);
    setLoading(true);
    timer = setTimeout(() => {
      setTableLoads([]);
      setForFilter(true);
      setPage(1);
      setLoading(false);
      dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "endo",
          startDate,
          page: 1,
          search: value,
        })
      );
    }, 1000);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index) => {
    if (activeIndex !== index) {
      setRow(index);
      setLoading(true);
      const detailSuccess = await dispatch(getPCMDetail(row?.id));
      if (detailSuccess) {
        setLoading(false);
      }
      handleSidebar();
    }
  };

  const fetchList = async () => {
    setLoading(true);
    const listProvider = await dispatch(getPCMProvider());
    if (listProvider) {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    await dispatch(getPatientsByQuery(search));
    setLoading(false);
  };

  useEffect(() => {
    if (search) {
      handleSearch();
    }
  }, [search]);

  useEffect(() => {
    if (pcmDetail && Object.keys(pcmDetail).length > 0) {
      const indexEdit = tableLoad.findIndex((r) => r?.id === pcmDetail?.id);
      const listItems = [...tableLoad];
      listItems.splice(indexEdit, 1, { ...pcmDetail });
      setTableLoads([...listItems]);
    }
  }, [pcmDetail]);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad && !forFilter) {
        setLoading(true);
        const listPCM = await getPCMFilter({
          type: "endo",
          page: page + 1,
          column: sortColumn,
        });
        if (listPCM) {
          if (pcm && pcm.endovascular && pcm.endovascular.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      } else if (offLoad && forFilter) {
        setLoading(true);
        const listPCMFilter = await dispatch(
          getPCMFilter({
            user_id: userProvider,
            type: "endo",
            startDate,
            page: page + 1,
            search: search,
            column: sortColumn,
          })
        );
        if (listPCMFilter) {
          if (pcmFilter && pcmFilter.endovascular && pcmFilter.endovascular.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    const date = moment(value).format("MM/DD/YYYY");
    setStartDate(date);
  };

  const onChangeProvider = (value) => {
    setUserProvider(value);
  };

  const onChangeSlary = async (value) => {
    setLoading(true);
    setTableLoads([]);
    const filterSuccess = await dispatch(
      getPCMFilter({
        user_id: null,
        type: "endo",
        startDate,
        page: 1,
        search: search,
        netMin: value[0],
        netMax: value[1],
      })
    );
    if (filterSuccess) {
      setLoading(false);
    }
  };

  const callApiFilter = async (type) => {
    if (type === "full") {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "endo",
          startDate: startDate,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    } else {
      setLoading(true);
      const filterSuccess = await dispatch(
        getPCMFilter({
          user_id: userProvider,
          type: "endo",
          startDate: startDate,
          page: 1,
          search: search,
        })
      );
      if (filterSuccess) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (startDate && !userProvider) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    } else if (userProvider && startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("full");
      setForFilter(true);
    } else if (userProvider && !startDate) {
      setPage(1);
      setTableLoads([]);
      callApiFilter("user");
      setForFilter(true);
    }
  }, [userProvider, startDate]);

  const updateState = (value, data) => {
    const tasks = tableLoad;
    setTableLoads(
      tasks.map((r) => {
        if (r?.id === value?.id) {
          return { ...r, direct_cost: data?.direct };
        } else {
          return r;
        }
      })
    );
  };

  const moveState = (id, data) => {
    const tasks = tableLoad;
    setTableLoads(
      tasks.filter((r) => {
        if (r?.id !== id) {
          return r;
        }
      })
    );
    setRow(-1);
  };

  useEffect(() => {
    if (pcm && pcm.endovascular && pcm.endovascular.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...pcm.endovascular]);
      } else {
        setTableLoads([...pcm.endovascular]);
      }
    }
  }, [pcm]);

  useEffect(() => {
    if (pcmFilter && pcmFilter.endovascular) {
      setTableLoads([...tableLoad, ...pcmFilter.endovascular]);
    }
  }, [pcmFilter]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer">
        <div className="fullContainer pt-2">
          <EndovascularFilter
            autoCompleteOptions={patients}
            onChangeDate={onChangeDate}
            hideAssignment={true}
            onChangeProvider={onChangeProvider}
            onChangeSlary={onChangeSlary}
            providers={ScheduleRow || []}
            valueSearch={(value) => valueSearch(value)}
          />
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                isBorderThick={true}
                title="pcm_endovascular"
                tableWidth={"1950"}
                labels={[
                  "Patient Name",
                  "DOB",
                  "DOS",
                  "Proceduralist",
                  "Fee",
                  "Allowed Amount",
                  "Total Paid",
                  "Insurance Balance",
                  "Patient Balance",
                  "Net",
                  "Direct Cost",
                  "CM",
                ]}
                widths={["12%", "9%", "9%", "9%", "9%", "9%", "9%", "7%", "7%", "7%", "7%", "7%"]}
                columns={[
                  { sortable: true, key: "patient_name" },
                  { sortable: false, key: "patient_dob" },
                  { sortable: true, key: "dos" },
                  { sortable: false, key: "proceduralist" },
                  { sortable: true, key: "fee" },
                  { sortable: true, key: "allowed_amount" },
                  { srotable: true, key: "insurance_amount" },
                  { sortable: true, key: "insurance_balance" },
                  { sortable: true, key: "patient_balance" },
                  { sortable: true, key: "net" },
                  { sortable: true, key: "direct_cost" },
                  { sortable: true, key: "contribution_margin" },
                ]}
                sorts={[
                  sortOption.patient_name,
                  "",
                  sortOption.dos,
                  "",
                  sortOption.fee,
                  sortOption.allowed_amount,
                  "",
                  sortOption.insurance_balance,
                  sortOption.patient_balance,
                  sortOption.net,
                  sortOption.direct_cost,
                  sortOption.contribution_margin,
                ]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row?.id,
                  patient_name: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row.icon_status === "green" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            {"0 Net balance"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "yellow" && <YellowAlertSVG />}
                      {row.icon_status === "red" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            {"Net balance > 0"}
                          </span>
                        </div>
                      )}
                      {row.icon_status === "red_x" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            {"<70% and < 365 days"}
                          </span>
                        </div>
                      )}
                      {!row.icon_status && <span style={{ minWidth: "26px" }}></span>}
                      <p style={{ paddingLeft: "0.75rem" }}>{row.patient_name}</p>
                    </div>
                  ),
                  patient_dob: row.patient_dob && moment(row.patient_dob).format("MM/DD/YYYY"),
                  dos: row.date_of_procedure && moment(row.date_of_procedure).format("MM/DD/YYYY"),
                  proceduralist: row.provider_name,
                  fee: row.fee,
                  allowed_amount: row.allowed_amount,
                  total_payment: row.payment,
                  net: row.net,
                  ros: row.location,
                  direct_cost: row.direct_cost,
                  contribution_margin: row.contribution_amount,
                  insurance_balance: row.insurance_balance,
                  patient_balance: row.patient_balance,
                  insurance_amount: row.insurance_amount,
                }))}
                handleSort={(value, item) => {
                  handleSortOption(value, item);
                }}
                fetchList={fetchList}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>

      {pcmDetail && activeIndex >= 0 && (
        <EndovascularSidebar
          ref={sidebarRef}
          updateState={(value, data) => {
            updateState(value, data);
          }}
          moveState={(value, data) => {
            moveState(value, data);
          }}
          detailPCM={activeIndex >= 0 && pcmDetail ? pcmDetail : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Endovascular;

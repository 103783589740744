import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import { getQuestionsForProcedure, get_plan_patient, saveProcedureChoice, setGlobalLoading, setProcedureData } from "../../../store/actions";

const GeneralComponent = ({
  procedure_id_row,
  handleContinue,
  nextSave,
  titleProps,
  display,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  currentPlanId,
  activeStatus,
  is_locked,
  ...restProps
}) => {
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const questions = useSelector((state) => state.procedure.questions);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const fetchFirst = async () => {
    await dispatch(setProcedureData("timeSlot", -1));
  };

  useEffect(() => {
    fetchFirst();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  useEffect(() => {
    setData(questions);
  }, [questions]);

  const callApiDetail = async () => {
    if (procedure_id_row) {
      await dispatch(setGlobalLoading(true));
      const getDetailSuccess = await dispatch(getQuestionsForProcedure(procedure_id_row));
      if (getDetailSuccess) {
        await dispatch(setGlobalLoading(false));
      }
    }
  };

  useEffect(() => {
    if (procedure_id_row) {
      callApiDetail();
    }
  }, [procedure_id_row]);

  const setFirstFC = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const onHandleClick = () => {
    if (nextSave) {
      nextSave();
      setFirstFC(-1);
    }
  };

  const onSelectChoice = (questionIndex, checked_choice) => {
    const data_ = [...data];
    if (data_[questionIndex]["checked_choice"] != checked_choice) {
      data_[questionIndex]["checked_choice"] = checked_choice;
    } else if (data_[questionIndex]["checked_choice"] == checked_choice) {
      data[questionIndex]["checked_choice"] = "";
    }
    setData(data_);
    onChangeData(data_);
  };

  const onChangeData = (questions) => {
    setTimeout(async () => {
      dispatch(setGlobalLoading(true));
      const params = {
        questions: questions,
        plan_id: currentPlanId,
      };
      await dispatch(saveProcedureChoice(procedure_id_row, params));
      await dispatch(setProcedureData("loadingSchedule", true));
      await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      dispatch(setGlobalLoading(false));
    }, 500);
  };

  return (
    <>
      <div className="resourceContent">
        <React.Fragment key={`clinic-appointment-${0}`}>
          {(data || []).map((row, index) => {
            return (
              <div className="content" key={`content-${index}`}>
                <div className="hpiList">
                  <HPIConditionItem
                    is_locked={is_locked}
                    key={`questionList-${index}`}
                    data={row}
                    onSelectChoice={(checked_choice) => onSelectChoice(index, checked_choice)}
                  />
                </div>
              </div>
            );
          })}
        </React.Fragment>
      </div>
      {activeStatus && (
        <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
          <button
            disabled={is_locked}
            className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
            onClick={() => {
              onHandleClick();
            }}
          >
            Continue
          </button>
        </div>
      )}
    </>
  );
};

export default GeneralComponent;

const HPIConditionItem = ({ data, onSelectChoice, is_locked }) => {
  const [question, setQuestion] = useState("");
  const [checkedChoice, setCheckedChoice] = useState(null);
  const [change, setChange] = useState(null);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    let question = "";
    let choices = [];
    let checked_choice = "";
    question = data?.question;
    choices = data?.choices;
    checked_choice = data?.checked_choice;
    if (data) {
      (data.choices || []).map((r) => {
        if (r.option == data.checked_choice) {
        } else if (r.option == "" || data.checked_choice == "") {
        }
      });
    }
    setQuestion(question);
    setChoices(choices);
    setCheckedChoice(checked_choice);
  }, [data, checkedChoice, change]);

  return (
    <div
      className={`HPIItem`}
      style={{
        alignItems: "center",
      }}
    >
      <div className="HPIItem__label">{question}</div>
      <div className={`HPIItem__options`} style={{ justifyContent: "flex-end" }}>
        {choices &&
          choices.map((choice, index) => (
            <button
              disabled={is_locked}
              key={`question-choice-${index}`}
              className={`HPIItem__option ${choice.option == data?.checked_choice ? "active" : "non-active"}`}
              onClick={() => {
                if (!is_locked) {
                  setChange(Math.random());
                  setCheckedChoice(choice.option);
                  onSelectChoice(choice.option);
                }
              }}
            >
              {choice.option}
            </button>
          ))}
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import { getPatientsAnalytics, setGlobalLoading } from "../../../store/actions";

const PatientChart = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  const patients_analytics = useSelector((state) => state.common.patients_analytics);

  const getMillionsValue = (value) => {
    let newValue = value;
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + value).length / 3);
      let shortValue = "";
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  };

  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    defaults: {
      elements: {
        point: {
          backgroundColor: "#000000",
          borderColor: "#000000",
        },
      },
    },
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        position: "right",
        display: false,
        labels: {
          color: "#152935",
          family: '"IBM plex sans", "Helvetica Neue", sans-serif',
        },
      },
    },
    scales: {
      y: {
        min: 0,
        stacked: true,
        ticks: {
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#152935",
          callback: function (value, index, values) {
            return getMillionsValue(value);
          },
        },
      },
      x: {
        ticks: {
          padding: 10,
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#000",
        },
      },
    },
  };

  const fetchApi = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(getPatientsAnalytics(startDate, endDate));
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchApi();
  }, [startDate, endDate]);

  useEffect(() => {
    if (!patients_analytics) return;

    const { referrals, scheduled_referrals, unscheduled_referrals } = patients_analytics || {};

    const newData = {};
    newData.labels = referrals?.map((data) => {
      return data?.registration_date;
    });

    newData.datasets = [
      {
        label: "Scheduled Referrals",
        data: scheduled_referrals?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(25,128,56,0.75)",
        borderColor: "rgba(25,128,56,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "Non Scheduled Referrals",
        data: unscheduled_referrals?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,231,163,0.75)",
        borderColor: "rgba(255,231,163,1)",
        pointBackgroundColor: "#000000",
      },
    ];

    setData(newData);
  }, [patients_analytics]);

  return (
    <div style={{ height: "90%" }}>
      <Line data={data} options={chartOptions} height={75} />
    </div>
  );
};

export default PatientChart;

import { Icon } from "antd";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getLocal, setLocal } from "../../../helpers/Local";
import { changeActiveRow, notify_lock, releaseLock, remove_selected_patient_resource, resetStateResource } from "../../../store/actions";
import "./style.scss";

const ToastMessage = ({ type, msg, status, width, isStatic, link, exlink }) => {
  const current_appointment_id = getLocal("current_appointment_id");
  const dispatch = useDispatch();

  const varient = useMemo(() => {
    if (status === "success") {
      return "blue-box";
    } else if (status === "failed") {
      return "red-box";
    }
    return "default";
  }, [status]);

  const notifyToUser = async (link) => {
    if (link == "Accept") {
      await dispatch(releaseLock(current_appointment_id, {}));
      await setLocal("current_appointment_id", null);
      dispatch(remove_selected_patient_resource());
      dispatch(resetStateResource());
      dispatch(changeActiveRow(true));
    } else {
      await dispatch(notify_lock(current_appointment_id));
    }
  };

  const notifyExLink = async (exlink) => {
    await dispatch(releaseLock(current_appointment_id, { force: true }));
  };

  return (
    <div className={`ToastMessage ${varient} ${isStatic ? "static" : ""}`} style={{ width: width ? width : 400 }}>
      <div className="ToastMessage__container">
        {status !== "failed" && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
        {status === "failed" && <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />}
        <div className="ToastMessage__infoSection">
          <div className="ToastMessage__infoSection-title">{type}</div>
          <div className="ToastMessage__infoSection-msg">{msg}</div>
          {link && (
            <a
              onClick={() => {
                notifyToUser(link);
              }}
            >
              {link}
            </a>
          )}
          <br></br>
          {exlink && (
            <a
              onClick={() => {
                notifyExLink(exlink);
              }}
            >
              {exlink}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToastMessage;

import { Icon } from "antd";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import { RedCloseSVG } from "../../../common/components/icons/SVGIcon";
import { printPacket, sendFaxPCP, sendFaxRP, sendLetter, send_email } from "../../../services/api.services";
import { setGlobalLoading, set_communication_compelete } from "../../../store/actions";

const FinalStepComponent = ({ ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const dispatch = useDispatch();

  const [check, setCheck] = useState(-1);

  useEffect(() => {
    if (check !== -1) {
      const { checkAction } = restProps;
      if (checkAction) {
        checkAction(check);
      }
    }
  }, [check]);

  const [fax_rp_status, setRPStatus] = useState(0);
  const [fax_pcp_status, setPCPStatus] = useState(0);
  const [print_packet_status, setPrintStatus] = useState(0);
  const [mail_status, setMailStatus] = useState(0);
  const [email_status, setEMailStatus] = useState(0);

  const [communicationOption, setCommunicationOption] = useState();

  const [lastCommsOptions, setLastCommsOptions] = useState({
    fax_rp: 0,
    fax_pcp: 0,
    mail: 0,
    email: 0,
    print_packet: 0,
  });

  const [message, setMessage] = useState("");
  const [printLink, setPrintLink] = useState("");

  const handleCheckOption = async (val) => {
    setCommunicationOption(val);
    const { customResource } = restProps;
    const patientId = customResource && customResource.patient ? customResource.patient?.id : resource?.id;
    await dispatch(setGlobalLoading(true));

    const options = {
      fax_rp: 0,
      fax_pcp: 0,
      mail: 0,
      email: 0,
      print_packet: 0,
    };

    dispatch(set_communication_compelete(true));

    val.forEach(async (option) => {
      if (lastCommsOptions[option?.value] === 1) {
        options[option?.value] = 1;
        return;
      }
      switch (option?.value) {
        case "fax_rp":
          try {
            const faxResult = await sendFaxRP(patientId);
            if (typeof faxResult?.status != null && faxResult?.status === "success") {
              setRPStatus(1);
              setMessage(faxResult?.message);
              setCheck(true);
            } else {
              setRPStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setRPStatus(2);
            dispatch(setGlobalLoading(false));
          }
          break;

        case "fax_pcp":
          try {
            const pcpResult = await sendFaxPCP(patientId);
            if (pcpResult?.status === "success") {
              setPCPStatus(1);
              setMessage(pcpResult?.message);
              setCheck(true);
            } else {
              setPCPStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setPCPStatus(2);
            dispatch(setGlobalLoading(false));
          }
          break;

        case "mail":
          try {
            const letter = await sendLetter(patientId);
            if (letter?.status === "success") {
              setMailStatus(1);
              setMessage(letter?.message);
              setCheck(true);
            } else {
              setMailStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setMailStatus(2);
            dispatch(setGlobalLoading(false));
          }
          break;

        case "email":
          try {
            const email = await send_email(patientId);
            if (email?.status === "success") {
              setEMailStatus(1);
              setMessage(email?.message);
              setCheck(true);
            } else {
              setEMailStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setEMailStatus(2);
            dispatch(setGlobalLoading(false));
          }
          break;

        case "print_packet":
          try {
            const print = await printPacket(patientId);
            if (print.url) {
              const base64 = await pdf2base64(print.url);
              setPrintLink(base64);
              setPrintStatus(1);
              setCheck(true);
            } else {
              setPrintStatus(2);
            }
            dispatch(setGlobalLoading(false));
          } catch (error) {
            setPrintStatus(2);
            dispatch(setGlobalLoading(false));
          }
          break;

        default:
          break;
      }
      options[option?.value] = 1;
    });

    setLastCommsOptions(options);
  };

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
    }
  }, [printLink]);

  const printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf";
    const blob = b64toBlob(b64, contentType);
    const blobUrl = URL.createObjectURL(blob);

    let iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);

  const showToast = () => {
    return <ToastMessage type="Success" status={"success"} msg={message ? message : "Sent successfully"} />;
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {message && showToast()}
      <div className="resourceInfo" style={{ display: "flex", alignItems: "center" }}>
        <EditableFormInput
          id="communication"
          disableSearch={true}
          disableMultipleCheckAll={true}
          label={"Communication"}
          type={"check"}
          options={[
            {
              value: "fax_rp",
              label: "Fax Documents to Referring Provider",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {fax_rp_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {fax_rp_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "fax_pcp",
              label: "Fax Documents to Primary Care Provider",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {fax_pcp_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {fax_pcp_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "mail",
              label: "Auto-Mail Patient Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  id="auto-mail"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {mail_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {mail_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "email",
              label: "E-Mail Patient Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {email_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {email_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
            {
              value: "print_packet",
              label: "Print New Patient Packet Materials",
              suffix: (
                <span
                  className="normal-icon"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignContent: "center",
                    width: "1rem",
                    minWidth: "5rem",
                    paddingLeft: "3rem",
                    lineHeight: "100px",
                    minHeight: "34px",
                  }}
                >
                  {print_packet_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                  {print_packet_status === 2 && <RedCloseSVG />}
                </span>
              ),
            },
          ]}
          multiple
          optionKey={"value"}
          valueKey={"label"}
          handleChange={(val) => handleCheckOption(val)}
          value={communicationOption}
        />
      </div>
    </div>
  );
};

export default FinalStepComponent;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import { getReferralConditions, updateDetailUltrasounds, getDetailUltrasounds } from "../../../store/actions";
import ToastMessage from "../../../common/components/Toast";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";

const DiseaseCondition = ({ resource }) => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const detail_untrasound_list_type = useSelector((state) => state.procedureDetail.detail_untrasound_list_type);
  const referralConditions = useSelector((state) => state.common.referralConditions);

  const [dataCondition, setDataCondition] = useState([]);
  const [dataConditionID, setDataConditionID] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (detail_untrasound_list_type && detail_untrasound_list_type.conditions) {
      setDataCondition(detail_untrasound_list_type.conditions);
      setDataConditionID((detail_untrasound_list_type.conditions || []).map((r) => r?.id));
    } else {
      setDataCondition([]);
      setDataConditionID([]);
    }
  }, [detail_untrasound_list_type]);

  useEffect(() => {
    dispatch(getReferralConditions());
  }, []);

  useEffect(() => {
    if (referralConditions) {
      setDataSearch(referralConditions);
    }
  }, [referralConditions]);

  useEffect(() => {
    if (search) {
      const fillterArray = referralConditions.filter((r) => r?.value && r?.value.toUpperCase().includes(search.toUpperCase()));
      setDataSearch(fillterArray);
    }
  }, [search]);

  const addCondition = async (value) => {
    if (
      !detail_untrasound_list_type?.conditions?.some((el) => {
        return el?.value === value?.value;
      })
    ) {
      if (detail_untrasound_list_type && detail_untrasound_list_type?.id) {
        const checkID = detail_untrasound_list_type.conditions.map((r) => {
          return r?.id;
        });
        const params = {
          conditions: [...checkID, value?.id],
        };
        await dispatch(updateDetailUltrasounds(detail_untrasound_list_type?.id, params));
        await dispatch(getDetailUltrasounds(detail_untrasound_list_type?.id));
      }
    } else {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const removeProvider = async (index) => {
    if (detail_untrasound_list_type && detail_untrasound_list_type?.id) {
      dataConditionID.splice(index, 1);
      const params = {
        conditions: [...dataConditionID],
      };
      await dispatch(updateDetailUltrasounds(detail_untrasound_list_type?.id, params));
      await dispatch(getDetailUltrasounds(detail_untrasound_list_type?.id));
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Disease Condition Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="Procedure-Provider-Search"
          options={dataSearch}
          handlePopulate={(value) => addCondition(value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Conditions"
          keyLabel="value"
          actionLabel="Add"
        />
      </div>
      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="condition"
          labels={["Condition"]}
          widths={["100%"]}
          fullHeight={true}
          parentHeight={tableRef && tableRef.current && tableRef.current.clientHeight}
          columns={[{ sortable: true, key: "condition" }]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(dataCondition || []).map((item, index) => {
            return {
              condition: item?.value,
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default DiseaseCondition;

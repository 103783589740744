/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notes from "../../../common/components/Notes";
import { getLocal } from "../../../helpers/Local";
import { validateSteps } from "../../../services/validations";
import { getOnlyProviders, patientScheduleConfirmCheckin, savePatientNotes, setPatientSnapshotData } from "../../../store/actions";
import FormStepper from "../../components/FormStepper";
import { SummaryEmptySVG } from "../../components/icons/SVGIcon";
import ScrollWrapper from "../../components/ScrollBar";
import PharmacyAddForm from "../../components/PharmacyAddForm";
import PharmacyEditForm from "../../components/PharmacyEditForm";
import ProviderAddForm from "../../components/ProviderAddForm";
import ProviderEditForm from "../../components/ProviderEditForm";
import { newPatientOptions } from "../../constants/initialData";
import AddNewPatient from "../PatientSnapshot/AddNewPatient";
import Demographics from "../PatientSnapshot/Demographics";
import InsuranceDetail from "../PatientSnapshot/InsuranceDetail";
import MedicalHistory from "../PatientSnapshot/MedicalHistory";
import BillingAndCharges from "./BillingAndCharges";
import HIPDocumentation from "./HIPDocumentation";

const initialConditionsOne = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "medical", status: true },
  { step: "HIPAA", status: true },
  { step: "notes", status: false },
];

const initialConditionsTwo = [
  { step: "demographics", status: true },
  { step: "insurance", status: true },
  { step: "HIPAA", status: true },
  { step: "billing", status: true },
  { step: "notes", status: false },
];

const formStepsOne = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Insurance Detail",
    content: InsuranceDetail,
  },
  {
    title: "Medical History",
    content: MedicalHistory,
  },
  {
    title: "HIPAA Documentation",
    content: HIPDocumentation,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

const formStepsTwo = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Insurance Detail",
    content: InsuranceDetail,
  },
  {
    title: "HIPAA Documentation",
    content: HIPDocumentation,
  },
  {
    title: "Billing and Charges",
    content: BillingAndCharges,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

const ResourceContainer = React.forwardRef(
  (
    {
      resource,
      isShow,
      handleCloseSidebar,
      paymentForm,
      refreshCalendar,
      showConfirm,
      hideMedication,
      isNormal,
      sidebarIcon,
      formSteps,
      initialConditions,
      sidebarTitle,
      hasSubHeader,
      detail_ultrasound_result,
      isFinding,
      className,
      ...restProps
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [isAddForm, setIsAddForm] = useState(false);
    const [isQuestionaireCompleted, setIsQuestionaireCompleted] = useState(false);
    const [isProviderAdding, setIsProviderAdding] = useState(false);
    const [isProviderEdit, setIsProviderEdit] = useState(false);
    const [isPharmacyEdit, setIsPharmacyEdit] = useState(false);
    const [valuePharmacyEdit, setValuePharmacyEdit] = useState(null);
    const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
    const [valueProviderEdit, setValueProviderEdit] = useState(null);
    const [conditions, setConditions] = useState(hideMedication ? initialConditionsTwo : initialConditionsOne);
    const questionnaireData = useSelector((state) => state.ultrasound.questionnaireData);
    const patient_all_insurances_id = useSelector((state) => state.newPatient.patient_all_insurances_id);
    const stepData = useSelector((state) => ({
      demographics: resource,
      insurance: state.newPatient.insurance,
      medical: {
        ...state.newPatient.medical,
        previous_medical_history: resource?.icd_codes || [],
      },
    }));
    const detail_ultrasound_props = useSelector((state) => state.common.detail_ultrasound_props);
    const patientsNote = useSelector((state) => state.newPatient.patientsNote);
    const locations = useSelector((state) => state.common.locations);
    const providers = useSelector((state) => state.common.only_providers);
    const enterInsuranceCheck = useSelector((state) => state.newPatient.enterInsuranceCheck);
    useEffect(() => {
      if (!providers) {
        dispatch(getOnlyProviders());
      }
    }, []);
    const [isOver62, setIsOver62] = useState(false);
    const [isFluSeason, setIsFluSeason] = useState(false);
    const filteredLocations = useSelector((state) => state.common.filteredLocations);
    const filteredProviders = useSelector((state) => state.common.filteredProviders);
    const referralConditions = useSelector((state) => state.common.referralConditions);
    const referredProviders = useSelector((state) => state.common.referredProviders);
    const pcpList = useSelector((state) => state.common.pcpList);
    const scanCompareResult = useSelector((state) => state.scanDriverLicense.scanCompareResult);
    const allNotes = useSelector((state) => state.patientSchedule.allNotes);
    const allNotesDeleted = useSelector((state) => state.patientSchedule.allNotesDeleted);
    const isInsuranceVerified = useSelector((state) => state.patientSchedule.isInsuranceVerified);
    const resourceCheck = useSelector((state) => state.patientSnapshot.resource);
    const documents = useSelector((state) => state.hipaa.documents);
    const defaultFormSteps = hideMedication ? formStepsTwo : formStepsOne;
    const procedure_id = getLocal("current_appointment_id");

    useEffect(() => {
      if (resource) {
        dispatch(setPatientSnapshotData("resource", { ...resource }));
        let a = moment();
        let b = moment(resource?.date_of_birth);
        if (a.diff(b, "years") >= 62) {
          setIsOver62(true);
        }
        if (Number(moment().format("MM")) < 10 && Number(moment().format("MM")) > 2) {
          setIsFluSeason(false);
        } else {
          setIsFluSeason(true);
        }
      }
    }, [resource]);

    useEffect(() => {
      setIsProviderAdding(false);
      setIsProviderEdit(false);
      setValueProviderEdit(null);
      setIsPharmacyEdit(false);
      setValuePharmacyEdit(null);
    }, [resource]);

    const onSubmit = () => {
      dispatch(savePatientNotes(resource?.id, allNotes, allNotesDeleted));
      dispatch(patientScheduleConfirmCheckin(procedure_id));
      setTimeout(() => {
        refreshCalendar();
      }, 800);
    };

    if (scanCompareResult === "matched") {
      let newConditions = [...conditions];
      if (!newConditions[0].status) {
        newConditions[0].status = true;
        setConditions(newConditions);
      }
    }
    if (isInsuranceVerified) {
      let newConditions = [...conditions];
      if (!newConditions[1].status) {
        newConditions[1].status = true;
        setConditions(newConditions);
      }
    }

    useEffect(() => {
      if (resource && !initialConditions && !isFinding) {
        const newConditions = [...conditions];
        for (let i = 0; i < 4; i++) {
          const validate = validateSteps(stepData[conditions[i].step], newPatientOptions[conditions[i].step]);
          newConditions[i].status = validate;
        }
        if (resourceCheck) {
          if (
            resourceCheck["alcoholic_drink_pw"] != "" &&
            ((resourceCheck["allergies"] && resourceCheck["allergies"].length > 0) || resourceCheck["no_drug_allergies"] == true) &&
            resourceCheck["amputation"] != -1 &&
            (isFluSeason ? stepData["medical"]["flu_vaccination"] != -1 : stepData["medical"]["flu_vaccination"] != null) &&
            (isOver62
              ? stepData["medical"]["pneumococcal"] != -1
              : stepData["medical"]["pneumococcal"] != null ||
                stepData["medical"]["pneumococcal"] == -1 ||
                stepData["medical"]["pneumococcal"] == null) &&
            resourceCheck["aortic_disease"] != -1 &&
            ((resourceCheck["medications"] && resourceCheck["medications"].length > 0) || resourceCheck["no_medication"] == true) &&
            resourceCheck["icd_codes"] &&
            resourceCheck["icd_codes"].length > 0 &&
            resourceCheck["previous_smoking_ppd"] != "" &&
            resourceCheck["previous_surgical_history"] &&
            resourceCheck["previous_surgical_history"].length > 0 &&
            resourceCheck["smoking_ppd"] != "" &&
            resourceCheck["years_of_smoking"] != ""
          ) {
            newConditions[2].status = true;
          } else {
            newConditions[2].status = false;
          }
        }
        if (
          patient_all_insurances_id &&
          patient_all_insurances_id.some(
            (variable) => variable.is_verified && variable.is_verified === 1 && variable.is_eligible && variable.is_eligible === 1
          )
        ) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (enterInsuranceCheck) {
          newConditions[1].status = true;
        }
        if (documents && documents.length > 0) {
          newConditions[3].status = true;
        } else {
          newConditions[3].status = false;
        }
        if (newConditions[4]) {
          if (patientsNote && patientsNote.length > 0) {
            newConditions[4].status = true;
          } else {
            newConditions[4].status = false;
          }
        }
        setConditions([...newConditions]);
      } else if (resource && initialConditions && !isFinding) {
        if (initialConditions && initialConditions[0] && initialConditions[0]["step"] != "clinical") {
          const newConditions = [...initialConditions];
          const validate = validateSteps(stepData["demographics"], newPatientOptions["demographics"]);
          newConditions[0].status = validate;
          if (resourceCheck) {
            if (
              resourceCheck["alcoholic_drink_pw"] != "" &&
              ((resourceCheck["allergies"] && resourceCheck["allergies"].length > 0) || resourceCheck["no_drug_allergies"] == true) &&
              resourceCheck["amputation"] != -1 &&
              (isFluSeason ? stepData["medical"]["flu_vaccination"] != -1 : stepData["medical"]["flu_vaccination"] != null) &&
              (isOver62
                ? stepData["medical"]["pneumococcal"] != -1
                : stepData["medical"]["pneumococcal"] != null ||
                  stepData["medical"]["pneumococcal"] == -1 ||
                  stepData["medical"]["pneumococcal"] == null) &&
              resourceCheck["aortic_disease"] != -1 &&
              ((resourceCheck["medications"] && resourceCheck["medications"].length > 0) || resourceCheck["no_medication"] == true) &&
              resourceCheck["icd_codes"] &&
              resourceCheck["icd_codes"].length > 0 &&
              resourceCheck["previous_smoking_ppd"] != "" &&
              resourceCheck["previous_surgical_history"] &&
              resourceCheck["previous_surgical_history"].length > 0 &&
              resourceCheck["smoking_ppd"] != "" &&
              resourceCheck["years_of_smoking"] != ""
            ) {
              newConditions[1].status = true;
            } else {
              newConditions[1].status = false;
            }
          }
          if (newConditions[2]) {
            if (patientsNote && patientsNote.length > 0) {
              newConditions[2].status = true;
            } else {
              newConditions[2].status = false;
            }
          }
          setConditions([...newConditions]);
        }
      }
      if (isFinding) {
        const newConditions = [...initialConditions];
        if (detail_ultrasound_props?.disease_id) {
          newConditions[0].status = true;
        } else {
          newConditions[0].status = false;
        }
        if (isQuestionaireCompleted) {
          if (initialConditions?.length === 3) {
            newConditions[1].status = true;
          }
        } else {
          if (initialConditions?.length === 3) {
            newConditions[1].status = false;
          }
        }
        if (
          detail_ultrasound_props?.left_addendum &&
          detail_ultrasound_props?.left_addendum.length &&
          detail_ultrasound_props?.left_addendum.length > 0 &&
          detail_ultrasound_props?.right_addendum &&
          detail_ultrasound_props?.right_addendum.length &&
          detail_ultrasound_props?.right_addendum.length > 0
        ) {
          if (initialConditions?.length === 3) {
            newConditions[2].status = true;
          }
          if (initialConditions?.length === 2) {
            newConditions[1].status = true;
          }
        } else {
          if (initialConditions?.length === 3) {
            newConditions[2].status = false;
          }
          if (initialConditions?.length === 2) {
            newConditions[1].status = false;
          }
        }
        if (newConditions[3]) {
          if (patientsNote && patientsNote.length > 0) {
            newConditions[3].status = true;
          } else {
            newConditions[3].status = false;
          }
        }
        setConditions([...newConditions]);
      }
    }, [
      isFinding,
      detail_ultrasound_props,
      resource,
      resourceCheck,
      step,
      isQuestionaireCompleted,
      detail_ultrasound_result,
      initialConditions,
      isOver62,
      isFluSeason,
      enterInsuranceCheck,
      patient_all_insurances_id,
      patientsNote,
    ]);

    useEffect(() => {
      if (questionnaireData && questionnaireData.length > 0) {
        let data = {};
        questionnaireData.map((r) => {
          data = { ...data, [r.key]: r?.value };
        });
        if (
          data["rightResting"] &&
          data["leftResting"] &&
          data["rightExercise"] &&
          data["leftExercise"] &&
          data["rightToePressure"] &&
          data["leftToePressure"] &&
          data[`question-0`] &&
          data[`question-1`] &&
          data[`question-2`] &&
          data[`question-3`] &&
          data[`question-4`]
        ) {
          setIsQuestionaireCompleted(true);
        } else {
          setIsQuestionaireCompleted(false);
        }
      }
    }, [questionnaireData]);

    const isSubmitable = useMemo(() => {
      if (!conditions || !conditions.length) {
        return false;
      }
      return conditions.every((condition) => condition.status);
    }, [conditions]);

    const setProviderAddForm = (value) => {
      setIsProviderAdding(value);
    };

    const setProviderEditForm = (status, valueDetail) => {
      setIsProviderEdit(status);
      setValueProviderEdit(valueDetail);
    };

    const setPharmacyAddForm = (value) => {
      setIsPharmacyAdding(value);
    };

    const setPharmacyEditForm = (status, valueDetail) => {
      setIsPharmacyEdit(status);
      setValuePharmacyEdit(valueDetail);
    };

    const formStepsProviderAdd = [
      {
        title: "Healthcare Provider Location",
        isConfirmed: false,
        content: ProviderAddForm,
      },
    ];

    const formStepsProviderEdit = [
      {
        title: "Healthcare Provider Location Edit",
        isConfirmed: false,
        content: ProviderEditForm,
      },
    ];

    const formStepsPharmacyAdd = [
      {
        title: "Pharmacy Information",
        isConfirmed: false,
        content: PharmacyAddForm,
      },
    ];

    const formStepsPharmacyEdit = [
      {
        title: "Pharmacy Information Edit",
        isConfirmed: false,
        content: PharmacyEditForm,
      },
    ];

    if (!resource || !Object.keys(resource).length) {
      return (
        <Drawer
          title={isNormal ? (sidebarTitle ? sidebarTitle.toUpperCase() : "PATIENT DASHBOARD") : "ADD NEW PATIENT"}
          placement="right"
          closable
          onClose={handleCloseSidebar}
          visible={isShow}
          destroyOnClose
          key="right"
          className={className}
        >
          <div className="resourceContainer new">
            <ScrollWrapper css="x-hidden">
              {!isAddForm && (
                <div
                  className="emptyContentIcon"
                  style={{
                    width: "100%",
                    height: "calc(100% - 160px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SummaryEmptySVG />
                </div>
              )}
              {!isAddForm && (
                <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
                  <button onClick={() => setIsAddForm(true)}>Add New Patient</button>
                </div>
              )}
              {isAddForm && (
                <AddNewPatient
                  handleClose={() => setIsAddForm(false)}
                  referralConditions={referralConditions}
                  pcpList={pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList}
                  providers={providers}
                />
              )}
            </ScrollWrapper>
          </div>
        </Drawer>
      );
    }

    return (
      <Drawer
        title={isNormal ? (sidebarTitle ? sidebarTitle.toUpperCase() : "PATIENT DASHBOARD") : isAddForm ? "NEW PATIENT" : " "}
        placement="right"
        closable
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
        className={className}
      >
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper css="x-hidden">
            {resource ? (
              <>
                <FormStepper
                  {...restProps}
                  steps={
                    isProviderAdding
                      ? formStepsProviderAdd
                      : isPharmacyAdding
                      ? formStepsPharmacyAdd
                      : isPharmacyEdit
                      ? formStepsPharmacyEdit
                      : isProviderEdit
                      ? formStepsProviderEdit
                      : defaultFormSteps
                      ? defaultFormSteps
                      : formSteps
                  }
                  conditions={initialConditions || conditions}
                  currentStep={step}
                  handleStep={setStep}
                  resource={resource}
                  paymentForm={paymentForm}
                  isScan={true}
                  setOpenAddForm={setIsAddForm}
                  isAddForm={isAddForm}
                  locations={locations}
                  providers={providers}
                  filteredLocations={filteredLocations}
                  filteredProviders={filteredProviders}
                  referredProviders={referredProviders}
                  referralConditions={referralConditions}
                  refreshCalendar={refreshCalendar}
                  checkQuestionaire={(value) => setIsQuestionaireCompleted(value)}
                  pcpList={pcpList && pcpList.active_pcp ? pcpList.active_pcp : pcpList}
                  sidebarTitle={sidebarTitle}
                  setProviderEditForm={setProviderEditForm}
                  setProviderAddForm={setProviderAddForm}
                  setPharmacyAddForm={setPharmacyAddForm}
                  valueProviderEdit={valueProviderEdit}
                  isPharmacyEdit={isPharmacyEdit}
                  valuePharmacyEdit={valuePharmacyEdit}
                  setPharmacyEditForm={setPharmacyEditForm}
                />

                {resource?.first_name !== "" && showConfirm && (
                  <div
                    style={{
                      marginTop: "0.5rem",
                      paddingRight: "16px",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    <button className={"common-btn submit-btn"} style={{ border: 0 }} disabled={!isSubmitable} onClick={onSubmit}>
                      Confirm Check-In
                    </button>
                  </div>
                )}

                <div style={{ width: "100%", paddingBottom: "2rem" }} />
              </>
            ) : null}
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default ResourceContainer;

import { Input, Tabs } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { SearchSVG } from "../../../../common/components/icons/SVGIcon";
import { StaticResource } from "../../constant";

import { getDetailUsers, setGlobalLoading } from "../../../../store/actions";
import ActiveTable from "./Active";
import ActiveSidebar from "./ActiveSidebar";
import InActiveTable from "./InActive";
import InactiveSidebar from "./InActiveSidebar";

const { TabPane } = Tabs;
const { Search } = Input;

const EmployeeComponent = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [activeData, setData] = useState(null);
  const [search, setSearch] = useState(undefined);
  const [activeTab, setActiveTab] = useState("active");

  const dispatch = useDispatch();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, data) => async () => {
    setRow(index);
    dispatch(setGlobalLoading(true));
    await dispatch(getDetailUsers(data?.id));
    dispatch(setGlobalLoading(false));
    setData(data);
    handleSidebar();
  };

  const onSearchValue = (searchInput) => {
    const valueSearchState = searchInput.target?.value;
    setSearch(valueSearchState);
  };

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    setData(null);
    setRow(-1);
  };
  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            right: "32px",
            maxWidth: "440px",
            width: "calc(100% - 240px)",
            top: "16px",
            zIndex: 20,
          }}
        >
          <div className="rightSearchField" style={{ width: "100%" }}>
            <Search
              className="dependencySearch"
              placeholder="Search Employee"
              value={search}
              onChange={onSearchValue}
              style={{ width: "100%" }}
              suffix={<SearchSVG />}
            />
          </div>
        </div>

        <Tabs className="patientChart" type="card" onChange={handleChangeTab}>
          <TabPane tab="Active" key="active">
            <ActiveTable handleRow={handleRow} loading={loading} activeIndex={activeIndex} title={"activeEmployee"} searchValue={search} />
          </TabPane>
          <TabPane tab="Inactive" key="in-active">
            <InActiveTable handleRow={handleRow} loading={loading} activeIndex={activeIndex} title={"inactiveEmployee"} searchValue={search} />
          </TabPane>
        </Tabs>
      </div>

      {activeData && activeIndex >= 0 && activeTab === "active" && (
        <ActiveSidebar
          ref={sidebarRef}
          usersDetail={activeData ? activeData : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}

      {activeData && activeIndex >= 0 && activeTab !== "active" && (
        <InactiveSidebar
          ref={sidebarRef}
          usersDetail={activeData ? activeData : undefined}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default EmployeeComponent;

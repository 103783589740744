import { Select } from 'antd';
import React from 'react';
import { ExpandSVG } from '../../../common/components/icons/SVGIcon';
import YesNo from '../../../common/components/YesNo';

const {Option} = Select;

const SecondStepComponent = ({resource}) => {
  return (
    <div className="resourceContent">
      <div className="resourceInfo">
        <b>LLE Diagnostic Angiogram</b>
      </div>
      <div className="resourceInfo between flex-end">
        <Select
          placeholder="Location/Custom Preference"
          suffixIcon={<ExpandSVG />}
          allowClear={true}
        >
          <Option value="location1">location 1</Option>
          <Option value="location2">location 2</Option>
        </Select>
        <Select
          placeholder="Provider Preference"
          suffixIcon={<ExpandSVG />}
          allowClear={true}
        >
          <Option value="provider1">provider 1</Option>
          <Option value="provider2">provider 2</Option>
        </Select>
      </div>

      <div className="resourceInfo between mt-2">
        <div className="w-25">
          <b>Location</b>
        </div>
        <div className="w-25">
          <b>Date</b>
        </div>
        <div className="w-25">
          <b>Time</b>
        </div>
        <div className="w-25">
          <b>Provider</b>
        </div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">Covenant</div>
        <div className="w-25">7/9/2019</div>
        <div className="w-25">10:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">Bay McLaren</div>
        <div className="w-25">7/13/2019</div>
        <div className="w-25">9:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">St Marys</div>
        <div className="w-25">8/9/2019</div>
        <div className="w-25">1:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block mb-10" />

      <div className="resourceInfo between half">
        <div>Is patient taking Metformin? </div>
        <YesNo />
      </div>
      <div className="resourceInfo between half">
        <div>Is patient on Eliquis or Xarelto? </div>
        <YesNo />
      </div>
      <div className="resourceInfo between half">
        <div>Is patient on Coumadin? </div>
        <YesNo />
      </div>
      <div className="resourceInfo between half">
        <div>Is patient on Plavix? </div>
        <YesNo />
      </div>
      <div className="resourceInfo between half">
        <div>Has patient received coronary stent within the past 1 year </div>
        <YesNo />
      </div>
      <hr />

      <div className="resourceInfo">
        <b>LLE Diagnostic Angiogram Follow-Up</b>
      </div>
      <div className="resourceInfo between flex-end">
        <Select
          placeholder="Location/Custom Preference"
          suffixIcon={<ExpandSVG />}
          allowClear={true}
        >
          <Option value="location1">location 1</Option>
          <Option value="location2">location 2</Option>
        </Select>
        <Select
          placeholder="Provider Preference"
          suffixIcon={<ExpandSVG />}
          allowClear={true}
        >
          <Option value="provider1">provider 1</Option>
          <Option value="provider2">provider 2</Option>
        </Select>
      </div>

      <div className="resourceInfo between mt-2">
        <div className="w-25">
          <b>Location</b>
        </div>
        <div className="w-25">
          <b>Date</b>
        </div>
        <div className="w-25">
          <b>Time</b>
        </div>
        <div className="w-25">
          <b>Provider</b>
        </div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">Alma</div>
        <div className="w-25">7/9/2019</div>
        <div className="w-25">10:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">Alma</div>
        <div className="w-25">7/13/2019</div>
        <div className="w-25">9:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block" />
      <div className="resourceInfo between t-row">
        <div className="w-25">Alma</div>
        <div className="w-25">8/9/2019</div>
        <div className="w-25">1:15 AM</div>
        <div className="w-25">Haqqani</div>
      </div>
      <hr className="small-block mb-10" />
    </div>
  );
};

export default SecondStepComponent;

import { Drawer, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { BASE_API } from "../../../../common/constants/constants";
import { getLocal } from "../../../../helpers/Local";
import { validateField } from "../../../../services/validations";
import {
  add_timeoff_requests,
  approve_timeoff_requests,
  setGlobalLoading,
  setGlobalToastr,
  timeoff_requests,
  timeoff_requests_all,
} from "../../../../store/actions";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import moment from "moment";

const VacationRequestSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar, timeDetail, timeRemaining }, ref) => {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [state, setState] = useState({});
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [uploaded, setUploaded] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const users = useSelector((state) => state.common.users);
  const draggerProps = {
    name: "file",
    multiple: true,
    action: BASE_API + "timeoff_requests/" + timeDetail?.id + "/upload",
  };
  const dispatch = useDispatch();
  const authId = getLocal("cvai-current-user");

  const fetchList = () => {
    const IdEmploy = JSON.parse(localStorage.getItem("IdEmploy"));
    dispatch(timeoff_requests(IdEmploy));
    dispatch(timeoff_requests_all());
  };

  const rejectClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("proof_path", image.originFileObj);
    formData.append("start_time", state.startTime);
    formData.append("end_time", state.endTime);
    formData.append("amount_of_hours", state.amountHours);
    formData.append("user_id", timeDetail.user_id);
    formData.append("is_approved", 0);
    formData.append("approved_user_id", authId);
    formData.append("reason", state.reason);
    formData.set("_method", "PUT");
    const checkSaveAll = await dispatch(approve_timeoff_requests(timeDetail?.id, formData));
    if (checkSaveAll) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const approveClick = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("proof_path", image.originFileObj);
    formData.append("start_time", state.startTime);
    formData.append("end_time", state.endTime);
    formData.append("amount_of_hours", state.amountHours);
    formData.append("user_id", timeDetail.user_id);
    formData.append("is_approved", 1);
    formData.append("approved_user_id", authId);
    formData.append("reason", state.reason);
    formData.set("_method", "PUT");
    const checkSaveAll = await dispatch(approve_timeoff_requests(timeDetail?.id, formData));
    if (checkSaveAll) {
      fetchList();
      setLoading(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isShow && timeDetail) {
      dispatch(setGlobalLoading(loading));
    }
  }, [loading, isShow, timeDetail]);

  const onChangeUpload = (info) => {
    const { status } = info?.file;
    if (info?.file || info?.fileList) {
      setImage(info?.file || info?.fileList);
    }
    if (status !== "uploading") {
    }
    if (status === "uploading") {
      if (uploaded) {
        setUploaded(false);
      }
    }
    if (status === "done") {
      message.success(`${info?.file?.name} file uploaded successfully.`);
      setUploaded(true);
    } else if (status === "error") {
      message.error(`${info?.file?.name} file upload failed.`);
      setUploaded(true);
    }
  };

  const fetchStage = () => {
    if (timeDetail && Object.keys(timeDetail).length > 0) {
      setState({
        startTime: timeDetail?.start_time,
        endTime: timeDetail?.end_time,
        amountHours: timeDetail?.amount_of_hours,
        reason: timeDetail?.reason,
        vacation_hours_per_year: timeDetail && timeDetail.user && timeDetail.user.vacation_hours_per_year,
        vacation_hours_per_year_timeRemaining: timeDetail && timeDetail.user && timeDetail.user.vacation_hours_per_year - timeRemaining,
      });
    }
  };

  const handleChangeState = (value, key) => {
    setState((row) => {
      return {
        ...row,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (timeDetail) {
      fetchStage();
      setReset(true);
    }
  }, [timeDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  const onSaveNew = async () => {
    const endDay = moment(state.endDate).format("MM/DD/YYYY");
    const startDay = moment(state.startDate).format("MM/DD/YYYY");

    if (!state.reason) {
      alert("Reason is required!");
    } else {
      setLoading(true);
      const checkSuccess = await dispatch(
        add_timeoff_requests({
          user_id: state.user,
          start_time: startDay,
          end_time: endDay,
          amount_of_hours: state.allHours,
          reason: state.reason,
          approved_user_id: authId,
          is_approved: false,
        })
      );
      if (checkSuccess) {
        setLoading(false);
        dispatch(
          setGlobalToastr({
            header: "New Request!",
            message: "New request successfully!",
            type: "success",
          })
        );
        dispatch(timeoff_requests_all());
        handleCloseSidebar();
        setIsAddNew(false);
      }
    }
  };

  //

  const changeStartDate = (value) => {
    if (value) {
      const startMoment = moment(value);
      const endMoment = moment(state.endDate);

      if (state.endDate && startMoment.isSameOrBefore(endMoment)) {
        handleChangeState(value, "startDate");
        const duration = moment.duration(endMoment.diff(startMoment));
        const days = duration.asDays();
        if (Number(days) > 0) {
          handleChangeState(Number(days) * 8, "allDate");
          handleChangeState(Number(days) * 8, "allHours");
        } else {
          handleChangeState(8, "allDate");
          handleChangeState(8, "allHours");
        }
        handleChangeState(null, "endTime");
      } else {
        alert("Start date must be less than end date");
      }
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      const endMoment = moment(value);
      const startMoment = moment(state.startDate);

      if (state.startDate && endMoment.isSameOrAfter(startMoment)) {
        handleChangeState(value, "endDate");
        const duration = moment.duration(endMoment.diff(startMoment));
        const days = duration.asDays();
        if (Number(days) > 0) {
          handleChangeState(Number(days) * 8, "allDate");
          handleChangeState(Number(days) * 8, "allHours");
        } else {
          handleChangeState(8, "allDate");
          handleChangeState(8, "allHours");
        }
        handleChangeState(null, "endTime");
      } else {
        alert("End date must be greater than start date");
      }
    }
  };

  return (
    <Drawer
      title={timeDetail?.user?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}

        <ScrollWrapper>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              marginTop: "1.5rem",
            }}
          >
            {isAddNew ? (
              <React.Fragment>
                <div className="resourceInfo d-flex required-field">
                  <EditableFormInput label={"Start Date"} value={state?.startDate} type="date" handleChange={changeStartDate} />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableFormInput label={"End Date"} value={state?.endDate} type="date" handleChange={changeEndDate} />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableFormInput label={"Hours"} value={state.allHours} unit="hrs" />
                </div>

                <div className="resourceInfo d-flex required-field">
                  <EditableMaterialText
                    reset={reset}
                    label={"Reason"}
                    value={state.reason}
                    type={"check"}
                    optionKey={"value"}
                    handleChange={(value) => {
                      handleChangeState(value, "reason");
                    }}
                    valueKey={"label"}
                    options={[
                      { value: "vacation", label: "Vacation" },
                      { value: "illness", label: "Illness" },
                      { value: "bereavement", label: "Bereavement" },
                      { value: "education", label: "Education" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableFormInput label={"Number of Hours"} value={state.vacation_hours_per_year_timeRemaining} />
                </div>

                <div className="resourceInfo d-flex">
                  <EditableMaterialText
                    reset={reset}
                    label={"User"}
                    value={state.user}
                    type={"check"}
                    optionKey={"id"}
                    handleChange={(value) => {
                      handleChangeState(value, "user");
                    }}
                    valueKey={"name"}
                    options={users?.active_users || []}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="resourceInfo d-flex">
                  <EditableMaterialText
                    reset={reset}
                    label={"Position"}
                    noEditable={true}
                    value={timeDetail && timeDetail.user && timeDetail.user.position}
                  />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableMaterialText
                    reset={reset}
                    label={"Start Date / Time"}
                    required={true}
                    isError={validateField("text", state?.startTime)}
                    enableErrorText={enableError}
                    helperText={"Start Time Is Require!"}
                    value={state?.startTime}
                  />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableMaterialText
                    reset={reset}
                    handleChange={(value) => {
                      handleChangeState(value, "endTime");
                    }}
                    label={"End Date / Time"}
                    value={state?.endTime}
                  />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableMaterialText
                    reset={reset}
                    handleChange={(value) => {
                      handleChangeState(value, "amountHours");
                    }}
                    label={"Hours"}
                    value={state.amountHours}
                  />
                </div>
                <div className="resourceInfo d-flex required-field">
                  <EditableMaterialText
                    reset={reset}
                    label={"Reason"}
                    value={state.reason}
                    type={"check"}
                    optionKey={"value"}
                    handleChange={(value) => {
                      handleChangeState(value, "reason");
                    }}
                    valueKey={"label"}
                    options={[
                      { value: "vacation", label: "Vacation" },
                      { value: "illness", label: "Illness" },
                      { value: "bereavement", label: "Bereavement" },
                      { value: "education", label: "Education" },
                      { value: "other", label: "Other" },
                    ]}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <EditableMaterialText reset={reset} label={"Number of Hours"} noEditable={true} value={state.vacation_hours_per_year} />
                </div>
                <div className="resourceInfo d-flex">
                  <EditableMaterialText
                    reset={reset}
                    label={"Vacation Hours Remaining"}
                    noEditable={true}
                    value={state.vacation_hours_per_year_timeRemaining}
                  />
                </div>
              </React.Fragment>
            )}

            <hr />
            <div className="detailContent" style={{ display: "flex" }}>
              <div className="detailInfo">Documentation for absence (physician note, bereavement note or other docmentation.</div>
              <Upload onChange={onChangeUpload} className="barcode-uploader" {...draggerProps}>
                <button
                  className="common-btn outlined-btn"
                  style={{
                    marginTop: "6px",
                    marginLeft: "8px",
                    height: "40px",
                    lineHeight: 1,
                  }}
                >
                  Upload Image
                </button>
              </Upload>
            </div>

            <hr />
          </div>
          {isAddNew ? (
            <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
              <button onClick={onSaveNew} className="outlined-btn" style={{ marginRight: "0.5rem" }}>
                Save
              </button>
              <button
                onClick={() => {
                  setIsAddNew(false);
                  fetchStage();
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div className="actionContent" style={{ marginTop: "2rem", paddingRight: "1rem" }}>
              <button
                onClick={() => {
                  setIsAddNew(true);
                  setState({
                    startTime: moment("09:00", "HH:mm"),
                    endTime: moment("17:00", "HH:mm"),
                    allHours: 8,
                    startDate: moment(),
                    endDate: moment(),
                    vacation_hours_per_year_timeRemaining: timeDetail && timeDetail.user && timeDetail.user.vacation_hours_per_year - timeRemaining,
                  });
                }}
                className="outlined-btn"
                style={{ marginRight: "0.5rem" }}
              >
                Add New
              </button>
              <button onClick={rejectClick} className="outlined-btn" style={{ marginRight: "0.5rem" }}>
                Reject
              </button>
              <button onClick={approveClick}>Approve</button>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default VacationRequestSidebar;

import React, { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../../utils";
import DeleteIconButton from "../icons/DeleteIconButton";
import { SortingArrowIcon } from "../icons/SortingIcon";
import ScrollWrapper from "../ScrollBar";

import "./style.scss";
import { Checkbox, Popover } from "antd";

const DataTable = ({
  labels,
  widths,
  tableWidth,
  rowData,
  columns,
  sorts,
  title,
  hideHeader,
  allActive,
  defaultActive,
  handleClickRow,
  onFirstColClick,
  handleSort,
  disableDelete,
  handleDelete,
  loadMore,
  fetchList,
  disableIcon,
  isXHidden,
  loading,
  activeIndex,
  handleClickView,
  handleReleaseCharge,
  underline,
  bold,
  isChangeColor,
  isPatientReminder,
  isBorderThick,
  isHideTableCss,
  setCheckedState,
  checkedState,
}) => {
  const tableRef = useRef(null);
  const [sortOptions, setSortOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(defaultActive || 0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    setActiveRow(activeIndex);
  }, [activeIndex]);

  const size = useWindowSize(tableRef);

  let emptyRows = [];

  useEffect(() => {
    if (sorts) {
      setSortOptions(sorts);
    }
  }, [sorts]);

  useEffect(() => {
    if (rowData?.length <= 0 && fetchList) {
      fetchList();
    } else {
      // fetchDetail();
    }
    if (tableRef) {
      const initialSize = {
        width: tableRef.current.clientWidth,
        height: tableRef.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [tableRef]);

  const handleSortOption = (index, sort) => {
    const newSorts = [...sortOptions];
    newSorts[index] = sort;
    setSortOptions(newSorts);

    if (handleSort) {
      handleSort(columns[index].key, sort);
    }
  };

  const handleRow = (index, row) => {
    setActiveRow(index);

    if (handleClickRow) {
      handleClickRow(row, index);
    }
  };

  const handleCol = (index, row) => {
    if (onFirstColClick) {
      onFirstColClick(row, index);
    }
  };

  const handleDeleteRow = (index, row) => {
    if (activeDelete !== index) {
      setActiveDelete(index);
      return;
    }
    if (handleDelete) {
      handleDelete(row, index);
    }
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = rowData?.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > rowData?.length) {
    emptyRows = new Array(rowCount - rowData?.length).fill({});
  }

  const onLoadMore = (values) => {
    if (loadMore) {
      loadMore(values);
    }
  };

  const renderHeader = (label, index, checkbox) => {
    return (
      <div
        className={`th sortable ${!sortOptions[index] ? "hide-icon" : ""}`}
        style={{
          width: disableIcon ? `${widths[index]}` : index === 0 ? `calc(${widths[index]} - 3rem` : widths[index] || `calc(100% / ${labels.length})`,
          minWidth: disableIcon
            ? `${widths[index]}`
            : index === 0
            ? `calc(${widths[index]} - 3rem`
            : widths[index] || `calc(100% / ${labels.length})`,
          display: hideHeader ? "none" : "flex",
          alignItems: "center",
          height: "50px",
          marginLeft: disableIcon ? 0 : index === 0 ? "3rem" : 0,
          ...(index === 0 && isPatientReminder && { borderLeft: "none" }),
        }}
        key={`${label}-${title}-head-${index}`}
      >
        <div
          className="primaryDataTable-header__label"
          style={{
            width: "100%",
            minWidth: "20%",
            borderBottom: "none",
            paddingLeft: "0.75rem",
            color: "rgba(0, 0, 0, 0.65)",
          }}
        >
          {label}
        </div>

        {checkbox ? (
          <Checkbox
            checked={checkedState?.[index] || false}
            onClick={() => {
              setCheckedState(!checkedState?.[index], index, label);
            }}
          ></Checkbox>
        ) : null}
        {columns && columns[index] && columns[index].sortable && !checkbox && (
          <SortingArrowIcon
            sort={sortOptions[index]}
            handleSortASC={() => handleSortOption(index, "asc")}
            handleSortDESC={() => handleSortOption(index, "desc")}
          />
        )}
      </div>
    );
  };

  return (
    <div className="primaryDataTable" ref={tableRef}>
      <ScrollWrapper
        onScrollFrame={onLoadMore}
        css={`
          ${isHideTableCss ? "" : "no-padding-table"} ${isXHidden ? "x-hidden-table" : ""}
        `}
      >
        {!hideHeader && (
          <div
            className="primaryDataTable-header sortableHeader"
            style={{
              minWidth: tableWidth ? `${tableWidth}px` : "768px",
              minHeight: "50px",
              ...(isBorderThick && { borderBottom: "2px solid #dfe3e6" }),
            }}
          >
            {labels &&
              labels.length > 0 &&
              labels.map((label, index) => {
                if (columns?.[index]?.hover) {
                  return (
                    <Popover placement="bottom" content={columns?.[index]?.hover}>
                      {renderHeader(label, index, columns?.[index]?.checkbox)}
                    </Popover>
                  );
                }
                return renderHeader(label, index, columns?.[index]?.checkbox);
              })}
          </div>
        )}
        <div className="primaryDataTable-body" style={{ minWidth: tableWidth ? `${tableWidth}px` : "768px" }}>
          <div className="tableList" style={{ height: "100%", minHeight: "15vh" }}>
            {rowData &&
              rowData?.length > 0 &&
              rowData?.map((row, index) => (
                <div
                  className={`tableItem yellow ${activeRow === index ? "active" : ""} ${allActive ? "active" : ""}`}
                  key={`${title}-body-${index}-${row?.key}`}
                  onClick={() => handleRow(index, row)}
                >
                  {columns &&
                    columns.length > 0 &&
                    columns.map((col, idx) => (
                      <div
                        className="tableItemField"
                        style={{
                          width: widths[idx] || `calc(100% / ${labels.length})`,
                          minWidth: widths[idx] || `calc(100% / ${labels.length})`,
                        }}
                        onClick={() => idx === 0 && handleCol(index, row)}
                        key={`${title}-col-${index}-${idx}`}
                      >
                        <div
                          className={
                            idx === labels.length - 1
                              ? !disableDelete
                                ? "last-value with-delete p"
                                : "last-value p"
                              : !labels || !labels.length
                              ? "pr-0 p"
                              : "p"
                          }
                          {...(isChangeColor && { style: { color: "black" } })}
                        >
                          {row[col.key] && row[col.key] == "view_appoiment" ? (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                e.isPropagationStopped();
                                handleClickView && handleClickView(row, index);
                              }}
                            >
                              <p
                                style={{
                                  cursor: "pointer",
                                  color: "#0F62FE",
                                  textDecoration: underline ? "underline" : "none",
                                  fontWeight: bold ? "bold" : "",
                                }}
                              >
                                View
                              </p>
                            </div>
                          ) : (
                            <>
                              {row[col.key] && row[col.key] == "release_charge" ? (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.isPropagationStopped();
                                    handleReleaseCharge && handleReleaseCharge(row, index);
                                  }}
                                >
                                  <p
                                    style={{
                                      cursor: "pointer",
                                      color: "#0F62FE",
                                      textDecoration: underline ? "underline" : "none",
                                      fontWeight: bold ? "bold" : "",
                                    }}
                                  >
                                    Release Charge
                                  </p>
                                </div>
                              ) : (
                                row[col.key]
                              )}
                            </>
                          )}
                        </div>
                        {!disableDelete && idx === labels.length - 1 && !row?.disableDelete && (
                          <DeleteIconButton
                            handleClick={() => handleDeleteRow(index, row)}
                            selected={activeDelete === index}
                            icon={"can"}
                            extraStyle={{
                              position: "absolute",
                              display: "inline-block",
                              right: "0.75rem",
                              top: "10px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </div>
                    ))}
                </div>
              ))}
            {(emptyRows || []).map((item, index) => (
              <div className="tableItem" key={`chart-empty-table-${index}`}>
                <div className="tableItemField" style={{ width: "100%", minWidth: "100%" }} />
              </div>
            ))}
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default DataTable;

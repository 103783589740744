import { DatePicker, Input, Select } from "antd";
import * as _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import { ExpandSVG, SearchSVG } from "../../../../common/components/icons/SVGIcon";
import { getAllReceivingOrders, setInventoryData } from "../../../../store/actions";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const HistoryFilter = ({}) => {
  const histories = useSelector((state) => state.inventory.histories);
  const users = useSelector((state) => state.inventory.users);
  const filterOption = useSelector((state) => state.inventory.orderFilter);

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const debounce = useDebounce(search, 400);

  useEffect(() => {
    dispatch(getAllReceivingOrders());
    dispatch(setInventoryData("history", null));
    dispatch(setInventoryData("orderFilter", {}));
  }, []);

  useEffect(() => {
    dispatch(
      setInventoryData("orderFilter", {
        ...filterOption,
        search: debounce,
      })
    );
  }, [debounce]);

  const addedUsers = useMemo(() => {
    if (histories && histories.length && users.length) {
      const purchase_users = _.uniq(histories.map((p) => p.added_user).filter((usr) => !!usr));
      return users.filter((user) => purchase_users.includes(user?.id));
    }
    return [];
  }, [histories, users]);

  const changeFilter = (type) => (value) => {
    const newFilter = { ...filterOption, [type]: value };
    dispatch(setInventoryData("orderFilter", newFilter));
  };

  const changeFilterDate = (type) => (date, dateString) => {
    const newFilter = { ...filterOption, [type]: dateString };
    dispatch(setInventoryData("orderFilter", newFilter));
  };

  const changeSearch = (value) => {
    setSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer" style={{ width: "44%" }}>
        <div className="searchField">
          <Search
            className="dependencySearch"
            placeholder="Search"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer" style={{ width: "56%" }}>
        <div
          className="statusFilter"
          style={{
            width: "120px",
            maxWidth: "120px",
            minWidth: "120px",
          }}
        >
          <Select
            placeholder="Numbers..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            value={filterOption.purchase_number}
            onChange={changeFilter("purchase_number")}
          >
            <Option value="1">+1</Option>
            <Option value="5">+5</Option>
            <Option value="10">+10</Option>
            <Option value="25">+25</Option>
          </Select>
        </div>
        <div
          className="statusFilter"
          style={{
            width: "120px",
            maxWidth: "120px",
            minWidth: "120px",
          }}
        >
          <Select placeholder="Unit..." suffixIcon={<ExpandSVG />} allowClear={true} value={filterOption.unit} onChange={changeFilter("unit")}>
            <Option value="Case">Case</Option>
            <Option value="Box">Box</Option>
            <Option value="Pack">Pack</Option>
            <Option value="Each">Each</Option>
          </Select>
        </div>
        <div className="statusFilter" style={{ flex: 1 }}>
          <DatePicker
            dateFormat={dateFormat}
            placeholder="Date Requested"
            suffixIcon={<ExpandSVG />}
            style={{
              width: "100%",
            }}
            onChange={changeFilterDate("requestedDate")}
          />
        </div>
        <div className="statusFilter" style={{ flex: 1 }}>
          <Select placeholder="User..." suffixIcon={<ExpandSVG />} allowClear={true} value={filterOption.user} onChange={changeFilter("user")}>
            {(addedUsers || []).map((user, index) => (
              <Option key={`user-${user?.id}-${index}`} value={user?.id}>
                {user?.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default HistoryFilter;

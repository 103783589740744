import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal, removeLocal } from "../../helpers/Local";
import { getCurrentUserInfo, setChannelCheck, setGlobalToastr, setTaskManager } from "../../store/actions";
import PusherConnector from "./PusherScan/pusherConnector";

export default function PusherCheck({ setIndex, getItemBuild, dispatchAppointmentUnfinalized, updateStatus }) {
  const [pusherChannel, setPusherChannel] = useState(false);
  const [pusherConnector, setPusherConnnector] = useState();
  const dispatch = useDispatch();
  const authId = getLocal("cvai-current-user");
  const apId = getLocal("current_appointment_id");

  // use to check pusher just connect 1 time
  const channelCheck = useSelector((state) => state.common.channelCheck);

  useEffect(() => {
    if (!channelCheck) {
      const pusher = new PusherConnector();
      const channelName = "cvai";
      const channel = pusher.createChannel(channelName);
      setPusherConnnector(pusher);
      setPusherChannel(channel);
    }
  }, [channelCheck]);

  useEffect(() => {
    if (pusherChannel && pusherConnector) {
      dispatch(setChannelCheck(true));
      pusherConnector.unbindEvent(pusherChannel, "notify_lock");
      pusherConnector.bindEvent(pusherChannel, "notify_lock", async (callback) => {
        const data = await callback;
        if (data && data?.receiver == authId) {
          dispatch(
            setGlobalToastr({
              header: "New Support Message",
              message: data?.sender_name + " would like to work on this appointment",
              type: "warning",
              link: "Accept",
            })
          );
        }
      });

      pusherConnector.unbindEvent(pusherChannel, "lock_release");
      pusherConnector.bindEvent(pusherChannel, "lock_release", async (callback) => {
        const data = await callback;
        if (data) {
          if (data?.appointment_id == apId && data?.user_id != authId) {
            dispatch(
              setGlobalToastr({
                header: "New Support Message",
                message: "Your request for chart access has been approved",
                type: "success",
              })
            );
          }
          if (data?.appointment_id == apId && data?.user_id == authId) {
            setIndex && setIndex();
            dispatch(
              setGlobalToastr({
                header: "New Support Message",
                message: data?.forcer_name + " took over the patient chart",
                type: "success",
              })
            );
          }
        }
      });

      pusherConnector.unbindEvent(pusherChannel, "appointment_billed");
      pusherConnector.bindEvent(pusherChannel, "appointment_billed", async (callback) => {
        const data = await callback;

        if (data && getItemBuild) {
          getItemBuild(data?.appointment_id || data);
        }
      });

      pusherConnector.unbindEvent(pusherChannel, "status_change");
      pusherConnector.bindEvent(pusherChannel, "status_change", async (callback) => {
        const data = await callback;

        if (data && updateStatus) {
          updateStatus(data);
        }
      });

      pusherConnector.unbindEvent(pusherChannel, "appointment_unfinalized");
      pusherConnector.bindEvent(pusherChannel, "appointment_unfinalized", async (callback) => {
        const data = await callback;

        if (data && dispatchAppointmentUnfinalized) {
          dispatchAppointmentUnfinalized(data?.appointment_id || data);
        }
      });

      //
      pusherConnector.unbindEvent(pusherChannel, "user_update");
      pusherConnector.bindEvent(pusherChannel, "user_update", async (callback) => {
        const data = await callback;

        if (data && data?.user_id === authId) {
          if (data?.status == 1) {
            dispatch(getCurrentUserInfo(authId));
          } else {
            removeLocal("cvai-auth-token");
            removeLocal("cvai-token-type");
            removeLocal("cvai-token-expires");
            window.open("/login", "_self");
          }
        }
      });
      //
      pusherConnector.unbindEvent(pusherChannel, "task_created");
      pusherConnector.bindEvent(pusherChannel, "task_created", async (callback) => {
        const data = await callback;

        if (data && data?.patient_name) {
          dispatch(setTaskManager(data));
        }
      });
    }
  }, [pusherChannel, pusherConnector, apId, authId]);

  return null;
}

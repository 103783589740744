import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ScrollWrapper from '../../../common/components/ScrollBar';
import { useWindowSize } from '../../../common/utils';
import {
  getClinicCallback,
  selectProcedure,
  setProcedureData,
  getProcedureCallAttempt,
  setGlobalLoading,
} from '../../../store/actions';
import { Icon } from 'antd';
import DataTable from '../../../common/components/DataTable/DataTable';

const filterSuggestion = (suggestions, userInput) => {
  if (!userInput) {
    return suggestions;
  }
  let filteredSuggestions = [];

  filteredSuggestions = suggestions.filter(
    (suggestion) =>
      suggestion.patient_name &&
      suggestion.patient_name
        .split(' ')
        .join('')
        .toLowerCase()
        .indexOf(userInput.split(' ').join('').toLowerCase()) > -1,
  );

  return filteredSuggestions;
};

const CallbackTable = ({ search, handleSidebar, handleSelect }) => {
  const ref = useRef(null);
  const loading = useSelector((state) => state.procedure.loadingCallback);
  const data = useSelector((state) => state.procedure.callback_clinics);

  const dispatch = useDispatch();

  const [offset, setOffset] = useState(0);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (!data && !loading) {
      dispatch(setProcedureData('loadingCallback', true));
      dispatch(getClinicCallback({}));
    }
  }, [data]);

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const tableRows = useMemo(() => {
    if (data) {
      setOffset(0);
      return filterSuggestion(data, search);
    }
    return [];
  }, [data, search]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = async (index) => {
    dispatch(setGlobalLoading(true));
    dispatch(setProcedureData('loadingResource', true));
    dispatch(selectProcedure(rowData[index]));
    dispatch(getProcedureCallAttempt(rowData[index].id));
    handleSelect('call-back');
    dispatch(setGlobalLoading(false));
    handleSidebar();
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount + 1 - tableRows.length).fill({});
  }

  const onScroll = (values) => {
    if (values.scrollTop > rowData.length * 30 && offset < tableRows.length) {
      const count = Math.min(30, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const getProcedureName = (row) => {
    if (row.side) return row.side + ' ' + row.procedure_name;
    else return row.procedure_name;
  };

  return (
    <div ref={ref} className="tableContainer">
      <ScrollWrapper
        css="no-padding no-b-padding x-hidden"
        onScrollFrame={onScroll}
      >
        <div className="tableList">
          <DataTable
            isXHidden
            isNormal
            disableDelete
            tableWidth={'100%'}
            title="new_patient_pending"
            labels={['Patient Name', 'Clinic', 'Appointment Date']}
            widths={['30%', '40%', '30%']}
            defaultActive={-1}
            columns={[
              { sortable: false, key: 'name' },
              { sortable: false, key: 'referral_condition' },
              { sortable: false, key: 'referral_date' },
            ]}
            handleClickRow={(_, index) => {
              handleRow(index);
            }}
            rowData={(rowData || []).map((row, index) => {
              return {
                name: (
                  <div
                    className="iconField td with-icon"
                    style={{
                      display: 'flex',
                      paddingLeft: '0.25rem',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      type="phone"
                      theme="twoTone"
                      twoToneColor={
                        row.has_intraday_call_attempt === 1 ?
                          '#198038' :
                          '#F1C21B'
                      }
                    />
                    <p
                      style={{
                        paddingLeft: '0.75rem',
                        textTransform: 'capitalize',
                      }}
                    >
                      {row.patient && row.patient?.name.toLowerCase()}
                    </p>
                  </div>
                ),
                referral_condition: getProcedureName(row),
                referral_date:
                  row.appointment_date &&
                  moment(row.appointment_date).format('MM/DD/YYYY hh:mm A'),
              };
            })}
          />
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default CallbackTable;

import { Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailLocations, setGlobalLoading } from "../../../../store/actions";
import ActiveTable from "./Active";
import DetailsSidebar from "./DetailsSidebar";
import InActiveTable from "./InActive";

const { TabPane } = Tabs;

const Details = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [locations, setLocations] = useState(null);
  const detailLocations = useSelector((state) => state.common.detailLocations);
  const loadingLocationStatus = useSelector((state) => state.common.loadingLocationStatus);
  useEffect(() => {
    if (detailLocations) {
      setLocations(detailLocations);
    } else {
      setLocations(null);
    }
  }, [detailLocations]);
  const loading_get_detail_location = useSelector((state) => state.common.loading_get_detail_location);
  const [locationId, setLocationId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGlobalLoading(loadingLocationStatus || loading_get_detail_location));
  }, [loadingLocationStatus || loading_get_detail_location]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, data) => async () => {
    setRow(index);
    if (locationId !== data?.id) {
      setLocationId(data?.id);
      await dispatch(getDetailLocations(data?.id));
      handleSidebar();
    }
  };

  const makeChangeHandle = () => {
    setRow(-1);
    setLocationId(0);
    setLocations(null);
  };

  const handleChangeTab = (tab) => {
    setRow(-1);
    setLocationId(0);
    setLocations(null);
  };

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header" style={{ position: "relative" }}>
        <Tabs className="patientChart" type="card" onChange={handleChangeTab}>
          <TabPane tab="Active" key="active">
            <ActiveTable handleRow={handleRow} activeIndex={activeIndex} title={"activeEmployee"} />
          </TabPane>
          <TabPane tab="Inactive" key="in-active">
            <InActiveTable handleRow={handleRow} activeIndex={activeIndex} title={"inactiveEmployee"} />
          </TabPane>
        </Tabs>
      </div>
      {locations && Object.keys(locations).length > 0 && activeIndex >= 0 && (
        <DetailsSidebar
          ref={sidebarRef}
          resource={activeIndex >= 0 && locations ? locations : {}}
          isShow={isSidebar}
          makeChangeHandle={makeChangeHandle}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Details;

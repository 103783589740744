import React from "react";
import CommonDocumentUpload from "../../../../../common/components/DocumentUpload/CommonDocumentUpload";

const DocumentTemplates = ({ resource }) => {
  return (
    <div
      className="documentTemplates"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ marginTop: "0.5rem" }}>
        <CommonDocumentUpload title="New Patient Documents" data={resource && resource.new_patient_document} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <CommonDocumentUpload title="Hospital Documents" data={resource && resource.in_patient_document} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <CommonDocumentUpload title="Out-Patient Documents" data={resource && resource.out_patient_document} />
      </div>
    </div>
  );
};

export default DocumentTemplates;

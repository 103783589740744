import React from "react";
import { Spin, Icon } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { getScanningToken, scanDriverLicenseMatched, scanDriverLicenseIncorrect, setDataFieldCheckIn } from "../../../store/actions";
import { setIntervalAsync } from "set-interval-async/fixed";
import { clearIntervalAsync } from "set-interval-async";
import { BASE_API, BASE_PATH } from "../../../common/constants/constants";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";

var QRCode = require("qrcode.react");
let timer = null;

class DemographicsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedScanID: false,
      scannedData: null,
      allFields: {},
    };
  }

  getScannedData = async (token) => {
    const scannedData = await fetch(`${BASE_API}getidinfo?scan_token=${token}`);
    const data = await scannedData?.json();
    this.setState({ scannedData: data });
  };

  componentDidMount = async () => {
    const { resource } = this.props;
    if (resource) {
      const { first_name, last_name, zipcode, date_of_birth, phone, phone_number, email, ssn, address, apt_number, state, city, referral_condition } =
        resource;

      const params = {
        first_name,
        last_name,
        date_of_birth,
        phone_1: phone,
        phone_2: phone_number,
        email,
        ssn,
        address,
        apt: apt_number,
        state,
        city,
        zipcode,
        referral_condition,
      };
      this.props.getScanningToken("id", resource?.id);
      this.props.setDataFieldCheckIn("demographics", params);
      this.setState({ allFields: params });
    }
  };

  onClickedScanID = () => {
    this.setState({ clickedScanID: true });
    timer = setIntervalAsync(async () => {
      await this.getScannedData(this.props.scanToken);
    }, 3000);
  };

  onClickedCancel = async () => {
    this.setState({ clickedScanID: false });
    await clearIntervalAsync(timer);
  };

  handleInput = (type) => (value) => {
    const updates = { ...this.state.allFields, [type]: value };
    this.props.setDataFieldCheckIn("demographics", updates);
    this.setState({ allFields: updates });
  };

  render() {
    const { clickedScanID, scannedData } = this.state;
    const { resource, scanToken, referralConditions } = this.props;

    if (scannedData != null && scannedData?.status === "true") {
      clearIntervalAsync(timer);
    }
    if (!Object.keys(resource).length) {
      const antIcon = <Icon type="loading" spin />;
      return <Spin indicator={antIcon} />;
    }

    let scanIdArea = null;
    let alertScan = null;

    const { loadingScanToken } = this.props;

    if (clickedScanID) {
      if (loadingScanToken) {
        scanIdArea = null;
      } else {
        if (scannedData != null && scannedData?.status === "false") {
          scanIdArea = <QRCode value={scanToken} />;
        } else if (scannedData != null && scannedData?.status === "true" && !clickedScanID) {
          scanIdArea = <QRCode value={scanToken} />;
        } else if (scannedData != null && scannedData?.status === "true") {
          scanIdArea = <img src={BASE_PATH + scannedData?.result.image} alt="driver card" style={{ width: "80%", margin: "auto" }} />;

          if (
            !scannedData?.result.address.includes(resource?.address) ||
            !scannedData?.result.dateOfBirth.includes(resource?.date_of_birth) ||
            !scannedData?.result.firstName.includes(resource?.first_name) ||
            !scannedData?.result.lastName.includes(resource?.last_name)
          ) {
            alertScan = <span className="alert alert-danger">Driver License is not matching</span>;
            this.props.scanDriverLicenseIncorrect();
          } else {
            this.props.scanDriverLicenseMatched();
          }
        }
      }
    } else {
      scanIdArea = <img src={"/images/drivers-license-ph.png"} alt="driver card" style={{ width: "80%", margin: "auto" }} />;
    }
    const { allFields } = this.state;
    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"First Name"} handleChange={this.handleInput("first_name")} value={allFields["first_name"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"Last Name"} handleChange={this.handleInput("last_name")} value={allFields["last_name"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText
            label={"DOB"}
            value={allFields["date_of_birth"] ? moment(allFields["date_of_birth"]).format("MM/DD/YYYY") : undefined}
            handleChange={this.handleInput("date_of_birth")}
            mask={"date"}
            reset={true}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText
            label={"Phone No"}
            value={allFields["phone_1"]}
            handleChange={this.handleInput("phone_1")}
            mask={"phone"}
            reset={true}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText
            label={"Phone No"}
            value={allFields["phone_2"]}
            handleChange={this.handleInput("phone_2")}
            mask={"phone"}
            reset={true}
          />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"E-Mail"} handleChange={this.handleInput("email")} value={allFields["email"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"SSN"} value={allFields["ssn"]} mask={"ssn"} handleChange={this.handleInput("ssn")} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"Address"} handleChange={this.handleInput("address")} value={allFields["address"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"Apt"} handleChange={this.handleInput("apt")} value={allFields["apt"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"State"} handleChange={this.handleInput("state")} value={allFields["state"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"City"} handleChange={this.handleInput("city")} value={allFields["city"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex">
          <EditableMaterialText label={"Zip Code"} handleChange={this.handleInput("zipcode")} value={allFields["zipcode"]} reset={true} />
        </div>
        <div className="resourceInfo d-flex required-field">
          <EditableMaterialText
            label={"Diagnosis"}
            value={allFields["referral_condition"]}
            reset={true}
            required={true}
            type={"check"}
            optionKey={"value"}
            valueKey={"value"}
            handleChange={this.handleInput("referral_condition")}
            options={referralConditions || []}
            enableErrorText={true}
            helperText={"Diagnosis is required"}
          />
        </div>
        <hr />
        <div className="resourceInfo center">{scanIdArea}</div>
        <hr />
        {resource?.first_name != "" && (
          <div className="actionContent" style={{ paddingRight: 0 }}>
            {alertScan}
            <button className="common-btn outlined-btn">Delete</button>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  scanToken: state.scanDriverLicense.scanToken,
  demographics: state.common.demographics,
  loadingScanToken: state.scanDriverLicense.loadingScanToken,
});

const mapDispatchToProps = {
  getScanningToken,
  scanDriverLicenseMatched,
  scanDriverLicenseIncorrect,
  setDataFieldCheckIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(DemographicsComponent);

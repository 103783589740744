import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ScrollWrapper from '../../common/components/ScrollBar';
import { useWindowSize } from '../../common/utils';

const ClinicScheduleTable = ({ appointments }) => {
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  const tableRows = appointments;

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index, id) => () => {
    setRow(index);
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  return (
    <div className="clinicWorkflow__main-section" style={{ height: '100%' }}>
      <div
        className="eventsContainer"
        style={{ width: '100%', height: '100%' }}
      >
        <div className="fullContainer pt-2">
          <div className="tableHeader">
            <div className="tableItem">
              <div
                className="th"
                style={{ width: 'calc(20% - 3rem)', marginLeft: '3rem' }}
              >
                <b>Appointment Time</b>
              </div>
              <div className="th" style={{ width: '15%' }}>
                <b>Patient Name</b>
              </div>
              <div className="th" style={{ width: '25%' }}>
                <b>Procedure</b>
              </div>
              <div className="th" style={{ width: '15%' }}>
                <b>Contrast Allergy</b>
              </div>
              <div className="th" style={{ width: '25%' }}>
                <b>Status</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer double">
              <ScrollWrapper css="no-padding x-hidden">
                <div className="tableList">
                  {tableRows.map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? 'active' : ''
                        }`}
                      key={`location-schedule-${index}`}
                      onClick={handleRow(index, row?.id)}
                    >
                      <div className="td with-icon" style={{ width: '20%' }}>
                        <div className="iconField">
                          {row?.patient?.gender?.toLowerCase() === 'male'
                            ?
                            <div style={{ width: "26px", height: "26px", display: "flex", alignItems: "center", marginRight: "10px" }} >
                              <img src={require('../../common/components/icons/male.svg')} alt="maleSvgIcon" style={{ width: '100%' }} />
                            </div>
                            :
                            <div style={{ width: "26px", height: "26px", display: "flex", alignItems: "center", marginRight: "10px" }} >
                              <img src={require('../../common/components/icons/female.svg')} alt="femaleSvgIcon" style={{ width: '100%' }} />
                            </div>

                          }
                        </div>
                        <p>{moment(row?.appointment_date).format('hh:mm A')}</p>
                      </div>
                      <div className="td" style={{ width: '15%' }}>
                        <p>
                          {row?.patient?.first_name} {row?.patient?.last_name}
                        </p>
                      </div>
                      <div className="td" style={{ width: '25%' }}>
                        <p>{row?.procedure_name}</p>
                      </div>
                      <div className="td" style={{ width: '15%' }}>
                        <p>TEST</p>
                      </div>
                      <div className="td" style={{ width: '25%' }}>
                        {!row?.display_status ? (
                          ''
                        ) : (
                          <p>
                            {row?.display_status} at {row?.display_status_time}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div
                      className={`tableItem empty`}
                      key={`schedule-empty-${index}`}
                    >
                      <div className="td" style={{ width: '20%' }} />
                      <div className="td" style={{ width: '15%' }} />
                      <div className="td" style={{ width: '25%' }} />
                      <div className="td" style={{ width: '15%' }} />
                      <div className="td" style={{ width: '25%' }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicScheduleTable;

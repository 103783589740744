import { createReducer } from "redux-create-reducer";

import AT from "../actionTypes";

const initialState = {
  priors: null,
  ct_appointments: [],
  resource: null,
  types: null,
  loadingPriors: false,
  search: "",
  loadingResource: false,
  errorMessage: "",
  authNumbers: null,
  savedResoruces: false,
};

const GET_PRIOR_AUTHORIZATION_SUCCEEDED = `${AT.GET_PRIOR_AUTHORIZATION}_SUCCEEDED`;
const GET_CT_APPOINTMENTS_SUCCEEDED = `${AT.GET_CT_APPOINTMENTS}_SUCCEEDED`;
const GET_PRIOR_FILTER_TYPES_SUCCEEDED = `${AT.GET_PRIOR_FILTER_TYPES}_SUCCEEDED`;

const SELECT_PRIOR_STARTED = `${AT.SELECT_PRIOR}_STARTED`;
const SELECT_PRIOR_SUCCEEDED = `${AT.SELECT_PRIOR}_SUCCEEDED`;
const SET_PRIOR_DATA_SUCCEEDED = `${AT.SET_PRIOR_DATA}_SUCCEEDED`;
const GET_AUTHORIZATION_NUMBERS_SUCCEEDED = `${AT.GET_AUTHORIZATION_NUMBERS}_SUCCEEDED`;
const SAVE_PROIR_AUTHORIZATION_SUCCEEDED = `${AT.SAVE_PROIR_AUTHORIZATION}_SUCCEEDED`;
const SAVE_DEFAULT_SAVE_RESOURCE_SUCCEEDED = `${AT.SAVE_DEFAULT_SAVE_RESOURCE}_SUCCEEDED`;

export default createReducer(initialState, {
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [SAVE_DEFAULT_SAVE_RESOURCE_SUCCEEDED](state) {
    return Object.assign({}, state, {
      loadingResource: false,
      savedResoruces: false,
      loadingPriors: false,
    });
  },
  [GET_PRIOR_FILTER_TYPES_SUCCEEDED](state, action) {
    const types = action?.payload;
    return Object.assign({}, state, { types });
  },
  [GET_AUTHORIZATION_NUMBERS_SUCCEEDED](state, action) {
    const authNumbers = action?.payload;
    return Object.assign({}, state, { authNumbers });
  },
  [SET_PRIOR_DATA_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      ...action?.payload,
      resource: state.resource,
    });
  },
  [GET_PRIOR_AUTHORIZATION_SUCCEEDED](state, action) {
    const priors = action?.payload || [];
    return Object.assign({}, state, { priors, loadingPriors: false });
  },
  [GET_CT_APPOINTMENTS_SUCCEEDED](state, action) {
    const ct_appointments = action?.payload || [];
    return Object.assign({}, state, { ct_appointments, loadingPriors: false });
  },
  [SELECT_PRIOR_STARTED](state, action) {
    return Object.assign({}, state, {
      loadingResource: true,
      savedResoruces: false,
    });
  },
  [SELECT_PRIOR_SUCCEEDED](state, action) {
    const resource = action?.payload;
    return Object.assign({}, state, { resource, loadingResource: false });
  },
  [SAVE_PROIR_AUTHORIZATION_SUCCEEDED](state, action) {
    return Object.assign({}, state, {
      loadingResource: false,
      savedResoruces: true,
    });
  },
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'antd';
import DataTable from '../../../../common/components/DataTable/DataTable';
import { get_list_patients, setGlobalLoading } from '../../../../store/actions';

let timer = null;

const InActivePatient = ({
  activeIndex,
  title,
  handleRow,
  deleteListPatientIn,
  propsListTableIn,
  search,
}) => {

  const list_patients = useSelector((state) => state.common.list_patients);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const loading_get_patients = useSelector(
    (state) => state.common.loading_get_patients,
  );

  useEffect(() => {
    if (
      tableRows &&
      tableRows.length > 0 &&
      deleteListPatientIn &&
      Object.keys(deleteListPatientIn).length > 0
    ) {
      if (deleteListPatientIn?.id) {
        const index = tableRows.findIndex(
          (r) => r?.id == deleteListPatientIn?.id,
        );
        tableRows.splice(index, 1);
        setTableRows([...tableRows]);
      }
    }
  }, [deleteListPatientIn]);

  useEffect(() => {
    setPage(1);
    setTableRows([]);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(get_list_patients(1, 0, search));
    }, 500);
  }, [search]);

  useEffect(() => {
    if (
      tableRows &&
      tableRows.length > 0 &&
      propsListTableIn &&
      Object.keys(propsListTableIn).length > 0
    ) {
      if (propsListTableIn?.id) {
        const index = tableRows.findIndex((r) => r?.id == propsListTableIn?.id);
        tableRows.splice(index, 1, {
          ...propsListTableIn,
          first_name: propsListTableIn?.first_name,
          last_name: propsListTableIn?.last_name,
          name: `${propsListTableIn?.first_name} ${propsListTableIn?.last_name}`,
          full_name: `${propsListTableIn?.first_name} ${propsListTableIn?.last_name}`,
        });
        setTableRows([...tableRows]);
      }
    }
  }, [propsListTableIn]);

  const fetchApi = async () => {
    await dispatch(get_list_patients(page, 0));
  };

  useEffect(() => {
    if (!loading_get_patients) {
      setTableRows([...tableRows, ...list_patients]);
    }
  }, [list_patients]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_get_patients));
  }, [loading_get_patients]);

  const onScroll = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading_get_patients) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(get_list_patients(page + 1, 0, search));
        if (list) {
          if (list_patients && list_patients.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  return (
    <div className="fullContainer pt-2">
      <DataTable
        title="administrator_user_inactive_patient"
        tableWidth={960}
        labels={['Provider Name', 'Login', 'Last Access Date and Time']}
        widths={['40%', '30%', '30%']}
        columns={[
          { sortable: false, key: 'provider_name' },
          { sortable: false, key: 'login' },
          { sortable: false, key: 'last' },
        ]}
        sorts={['', '', '', '']}
        rowData={(tableRows || []).map((row, index) => ({
          id: row?.id,
          full_name: row?.name || row?.full_name,
          name: row?.name || row?.full_name,
          first_name: row?.first_name,
          last_name: row?.last_name,
          address: row?.address,
          phone_number: row?.phone,
          gender: row?.gender,
          date_of_birth: row?.date_of_birth,
          status: row?.status,
          zipcode: row?.zipcode,
          state: row?.state,
          suite: row?.suite,
          city: row?.city,
          email_address: row?.email,
          provider_name: (
            <div
              style={{
                display: 'flex',
                paddingLeft: '0.25rem',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {!row?.status ? (
                <div className="d-flex">
                  <Icon
                    type="question-circle"
                    theme="twoTone"
                    twoToneColor="#DA1E28"
                  />
                </div>
              ) : null}
              {row?.status ? (
                <div className="d-flex">
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#24A148"
                  />
                </div>
              ) : null}
              <p style={{ paddingLeft: '0.75rem' }}>{row?.name}</p>
            </div>
          ),
          login: row?.username,
          pass: '',
          last: row?.last_login,
        }))}
        loadMore={onScroll}
        fetchList={fetchApi}
        handleClickRow={(row, index) => handleRow(index, row)()}
        defaultActive={-1}
        disableDelete={true}
      />
    </div>
  );
};

export default InActivePatient;

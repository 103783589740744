import React from 'react';
import EditableText from '../../../common/components/EditableText';

const FirstStepComponent = ({resource}) => {
  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <b>First Name: </b>
        <EditableText label={resource.firstname} />
      </div>
      <div className="resourceInfo d-flex">
        <b>Last Name: </b>
        <EditableText label={resource.lastname} />
      </div>
      <div className="resourceInfo d-flex">
        <b>DOB: </b>
        <EditableText label={resource.dob} type="date" />
      </div>
      <div className="resourceInfo d-flex">
        <b>Primary Phone Number: </b>
        <EditableText label={resource.primaryPhone} />
      </div>
      <div className="resourceInfo d-flex">
        <b>Secondary Phone Number: </b>
        <EditableText label={resource.secondaryPhone} />
      </div>
      <div className="resourceInfo d-flex">
        <b>E-Mail: </b>
        <EditableText label={resource.email} />
      </div>
      <div className="resourceInfo d-flex">
        <b>SSN: </b>
        <EditableText label={resource.ssn} />
      </div>
      <div className="resourceInfo d-flex">
        <b>Address: </b>
        <EditableText label={resource.streetAddress} />
      </div>
      <div className="resourceInfo d-flex">
        <b>Apt/Suite/Bldg: </b>
        <EditableText
          label={`${resource.apt} ${resource.suite} ${resource.bldg}`}
        />
      </div>
      <div className="resourceInfo d-flex">
        <b>State: </b>
        <EditableText label={resource.state} />
      </div>
      <div className="resourceInfo d-flex">
        <b>City: </b>
        <EditableText label={resource.city} />
      </div>
      <div className="resourceInfo d-flex">
        <b>Zip Code: </b>
        <EditableText label={resource.zipCode} />
      </div>
      <hr />
      <div className="resourceInfo">
        <b>Clinic Date: </b>
        {resource.clinicDate}
      </div>
      <hr />
      <div className="resourceInfo between">
        <div className="w-65">
          <b>Order </b>
        </div>
        <div className="w-35">
          <b>Timeframe</b>
        </div>
      </div>
      <hr />
      <div className="resourceInfo between">
        <div className="w-65">LLE Diagnostic Angiogram</div>
        <div className="w-35">Next Available</div>
      </div>
      <div className="resourceInfo between">
        <div className="w-65">RLE Diagnostic Angiogram</div>
        <div className="w-35">Next Available</div>
      </div>
      <div className="resourceInfo between">
        <div className="w-65">Left Carotid Endarterectom</div>
        <div className="w-35">ASAP</div>
      </div>
      <div className="resourceInfo between">
        <div className="w-65">F/U 6 Months</div>
        <div className="w-35">Next Available</div>
      </div>
      <div className="resourceInfo between">
        <div className="w-65">Carotid US</div>
        <div className="w-35">ASAP</div>
      </div>
    </div>
  );
};

export default FirstStepComponent;

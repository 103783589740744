import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Icon } from "antd";
import SubSideNav from "../../common/components/SubSideNav";
import MainContainer from "./SupportDetail";
import { useSelector } from "react-redux";

const SupportPage = ({ location, history }) => {
  const [sideNavLinks, setSideNavLinks] = useState([]);
  const userPermissions = useSelector((state) => state.common.currentUserInfo);
  const [isSideNav, setIsSideNav] = useState(true);

  const openSideNav = () => setIsSideNav(true);
  const closeSideNav = () => setIsSideNav(false);
  const getTitle = () => (
    <div className="sub-title">
      <Icon type="alert" />
      Support
    </div>
  );

  useEffect(() => {
    // TODO: Add check in auth for support team
    if (userPermissions && userPermissions.is_support === 1) {
      setSideNavLinks([
        {
          link: "/support/details",
          label: "Your Tickets",
        },
        {
          link: "/support/all-tickets",
          label: "All Tickets",
        },
      ]);
    } else {
      setSideNavLinks([
        {
          link: "/support/details",
          label: "Tickets",
        },
      ]);
    }

    if (window.innerWidth <= 768) {
      closeSideNav();
    }
  }, [userPermissions]);

  const onLinkClick = (link) => {
    // if(link === "/support/details") return
    if (sideNavLinks.find((v) => v.link === link)) {
      closeSideNav();
    }
  };

  return (
    <div className="clinicWorkflow">
      <Helmet>
        <title>Support | FAACT Vascular</title>
        <meta name="title" content="Support | FAACT Vascular" />
      </Helmet>
      <SubSideNav
        subMenu={sideNavLinks}
        title={getTitle()}
        isSideNav={isSideNav}
        openSideNav={openSideNav}
        closeSideNav={closeSideNav}
        onLinkClick={onLinkClick}
      />
      <MainContainer location={location} isSideNav={isSideNav} />
    </div>
  );
};

export default SupportPage;
